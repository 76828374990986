import Language, { LanguageDto } from '../models/Language';
import BaseEntityService from './BaseEntityService';

export default class LanguageService extends BaseEntityService<Language, LanguageDto> {
	protected entityName: string = 'languages';
	public entityTableName: string = 'ad_language';

	map = (data: LanguageDto) => {
		return new Language(data);
	};

	reverseMap(data: Language): LanguageDto {
		throw new Error('Method not implemented.');
	}
}
