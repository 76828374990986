import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import EntitySuggestion from '../entity-suggestion/EntitySuggestion';
import { useAsyncFn } from 'react-use';
import { EncounterDiagnosis, Paging } from '../../models';
import useService from '../../hooks/useService';

type DiagnosisTableRowProps = {
	field?: EncounterDiagnosis;
	fieldPrefix: string;
	id: string;
	primaryGroupName?: string;
	remove?: (index: number) => void;
	isAddRow?: boolean;
	index?: number;
	readOnly?: boolean;
};

const DiagnosisTableRow = ({
	field,
	fieldPrefix,
	id,
	primaryGroupName = 'primary',
	remove,
	isAddRow = false,
	index = 0,
	readOnly,
}: DiagnosisTableRowProps) => {
	const { t } = useTranslation();
	const { register } = useFormContext();
	const { codedDiagnosisService } = useService();

	const [{ value: codedDiagnosisOptions, loading: areLoadingCodedDiagnosis }, onSearchCodedDiagnosis] = useAsyncFn(
		async (query: string) =>
			codedDiagnosisService
				.get(Paging.ALL.page, Paging.ALL.size, undefined, codedDiagnosisService.getDefaultSearchFilter(query))
				.then((response) => {
					return response.results;
				}),
		[codedDiagnosisService],
	);

	return (
		<tr>
			<td className="align-middle text-center">
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.uuid`)} defaultValue={field?.uuid} />}

				{!isAddRow && (
					<Form.Check value={index} type="radio" {...register(primaryGroupName)} defaultChecked={index === 0} />
				)}
			</td>
			<td>
				<EntitySuggestion
					name={`${fieldPrefix}.codedDiagnosis`}
					id={id}
					isLoading={areLoadingCodedDiagnosis}
					emptyLabel={t(uiText.codedDiagnosis.NOT_FOUND)}
					labelKey={'name'}
					placeholder={readOnly ? '' : t(uiText.visit.form.patient.SEARCH_CODED_DIAGNOSIS)}
					promptText={t(uiText.visit.form.patient.SEARCHING)}
					searchText={t(uiText.visit.form.patient.SEARCHING)}
					options={areLoadingCodedDiagnosis ? [] : codedDiagnosisOptions || []}
					onSearch={onSearchCodedDiagnosis}
					disabled={readOnly}
					newFieldName={`${fieldPrefix}.uncodedDiagnosis`}
					newSelectionPrefix={t(uiText.visit.form.NON_CODED_DIAGNOSIS_SELECTION_PREFIX)}
					modifyNewSelection={(value) => value + t(uiText.visit.form.NON_CODED_DIAGNOSIS_SELECTION_SUFFIX)}
					unmodifyNewSelection={(value) => value.replace(t(uiText.visit.form.NON_CODED_DIAGNOSIS_SELECTION_SUFFIX), '')}
					defaultValue={(field?.uncodedDiagnosis || field?.codedDiagnosis) as any}
				/>
			</td>
			{!readOnly && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							type="button"
							aria-label={t(uiText.visit.form.patient.DELETE_DIAGNOSIS)}
							className="btn p-0 w-100"
							tabIndex={-1}
							id={`${fieldPrefix}.delete`}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default DiagnosisTableRow;
