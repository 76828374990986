import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import EncounterDiagnosis, { EncounterDiagnosisDto } from './EncounterDiagnosis';
import Observation, { ObservationDto } from './Observation';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import EncounterDiagnostic, { EncounterDiagnosticDto } from './EncounterDiagnostic';

export interface EncounterDB extends BaseMetadataDB {}

export interface EncounterDto extends BaseMetadataDto {
	encounterType?: ReferenceListDto | null;
	observations?: ObservationDto[] | null;
	encounterDiagnoses?: EncounterDiagnosisDto[] | null;
	encounterDiagnostics?: EncounterDiagnosticDto[] | null;
	encounterDate?: number | null;
}

export default class Encounter extends BaseMetadata {
	encounterType?: ReferenceList;
	observations: Observation[];
	encounterDiagnoses: EncounterDiagnosis[];
	encounterDiagnostics: EncounterDiagnostic[];
	encounterDate?: Date;

	constructor(props: Partial<EncounterDto | Encounter> = {}) {
		props = cloneDeep(props);
		super(props);

		this.encounterType = props.encounterType ? new ReferenceList(props.encounterType) : undefined;
		this.observations = (props.observations || []).map((observation) => new Observation(observation));
		this.encounterDiagnoses = (props.encounterDiagnoses || []).map(
			(encounterDiagnosis) => new EncounterDiagnosis(encounterDiagnosis),
		);
		this.encounterDiagnostics = (props.encounterDiagnostics || []).map(
			(encounterDiagnostic) => new EncounterDiagnostic(encounterDiagnostic),
		);

		this.encounterDate = (props.encounterDate && new Date(props.encounterDate)) || new Date();
	}
}
