import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { UseFieldArrayReturn } from 'react-hook-form';

type ReorderButtonsProps = { isLast: boolean; index: number } & Pick<UseFieldArrayReturn, 'move'>;

/**
 * This is the component to allow re-ordering of lists. It is kept in this file because it's not intended to be reused
 * (though this one might be re-used).
 * @param props The props for this component
 */
const ReorderButtons = ({ index, isLast, move }: ReorderButtonsProps) => (
	<div className="d-flex justify-content-center">
		<Button
			variant="reorder"
			className="mx-0 px-1 py-0"
			tabIndex={-1}
			type="button"
			disabled={index === 0}
			onClick={() => move(index, index - 1)}
		>
			<FontAwesomeIcon icon={['far', 'caret-square-up']} className="border-0" />
		</Button>
		<Button
			variant="reorder"
			className="mx-0 px-1 py-0"
			tabIndex={-1}
			type="button"
			disabled={isLast}
			onClick={() => move(index, index + 1)}
		>
			<FontAwesomeIcon icon={['far', 'caret-square-down']} className="border-0" />
		</Button>
	</div>
);

export default ReorderButtons;
