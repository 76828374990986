import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';

export interface VoidedReasonDB extends BaseEntityDB {
	lineNo: number;
	'ad_window.ad_window_uu': string;
	'bh_voided_reason.uuid': string;
	docStatus: string;
}

export interface VoidedReasonDto extends BaseEntityDto {
	lineNo?: number;
}

/**
 * VoidedReason class
 * @property {number} lineNo
 */
export default class VoidedReason extends BaseEntity {
	lineNo: number;

	constructor(entity: Partial<VoidedReason | VoidedReasonDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.lineNo = entity.lineNo || 0;
	}
}
