import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { ReceiveProductLinesFormValues } from './ProductLineItemTable';

type ReceiveProductLineItemTableFooterProps = { shouldDisplayTheDeleteColumn: boolean };

const ReceiveProductLineItemTableFooter = ({
	shouldDisplayTheDeleteColumn,
}: ReceiveProductLineItemTableFooterProps) => {
	const { t } = useTranslation();
	const orderLines = useWatch<ReceiveProductLinesFormValues, 'C_OrderLines'>({ name: 'C_OrderLines' });
	const addOrderLine = useWatch<ReceiveProductLinesFormValues, 'addNewOrderLine'>({
		name: 'addNewOrderLine',
	});

	return (
		<tr>
			<td colSpan={3} className="align-middle">
				<span className="float-end me-2">
					<strong>{t(uiText.receiveProduct.TOTAL)}</strong>
				</span>
			</td>
			<td>
				<FormatNumberInput
					className="fw-bold"
					value={[...orderLines, addOrderLine].reduce(
						(runningTotal, orderLine) => runningTotal + (orderLine.QtyEntered || 0) * (orderLine.PriceEntered || 0),
						0,
					)}
					readOnly={true}
					tabIndex={-1}
				/>
			</td>
			<td colSpan={shouldDisplayTheDeleteColumn ? 2 : 1} className="d-none">
				&nbsp;
			</td>
		</tr>
	);
};

export default ReceiveProductLineItemTableFooter;
