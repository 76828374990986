import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { DocumentActionValue } from './DocAction';
import { DocumentStatusValue } from './DocumentStatus';

export interface DocumentTypeDB extends BaseEntityDB {
	docbasetype: string;
	issotrx: boolean;
	docsubtypeinv: string;
	docsubtypeso: string;
	isshipconfirm: boolean;
	ispickqaconfirm: boolean;
}

export interface DocumentTypeDto extends BaseEntityDto {
	printName?: string;
	documentBaseType?: string;
	isSalesTransaction?: boolean;
	documentSalesSubType?: string;
	hasProforma?: boolean;
	isDocumentNumberControlled?: boolean;
	hasCharges?: boolean;
	documentNote?: string;
	isDefault?: boolean;
	documentCopies?: number;
	isDefaultCounterDocument?: boolean;
	isShipmentConfirm?: boolean;
	isPickQAConfirm?: boolean;
	isInTransit?: boolean;
	isSplitWhenDifference?: boolean;
	isCreateCounter?: boolean;
	isIndexed?: boolean;
	isOverwriteSequenceOnComplete?: boolean;
	isOverwriteDateOnComplete?: boolean;
	isPrepareSplitDocument?: boolean;
	isChargeOrProductMandatory?: boolean;
	documentInvoiceSubType?: string;
}

export const documentBaseType = {
	AdReferenceId: 183,
	GLJournal: 'GLJ',
	GLDocument: 'GLD',
	APInvoice: 'API',
	APPayment: 'APP',
	ARInvoice: 'ARI',
	ARReceipt: 'ARR',
	SalesOrder: 'SOO',
	ARProFormaInvoice: 'ARF',
	MaterialDelivery: 'MMS',
	MaterialReceipt: 'MMR',
	MaterialMovement: 'MMM',
	PurchaseOrder: 'POO',
	PurchaseRequisition: 'POR',
	MaterialPhysicalInventory: 'MMI',
	APCreditMemo: 'APC',
	ARCreditMemo: 'ARC',
	BankStatement: 'CMB',
	CashJournal: 'CMC',
	PaymentAllocation: 'CMA',
	MaterialProduction: 'MMP',
	MatchInvoice: 'MXI',
	MatchPO: 'MXP',
	ProjectIssue: 'PJI',
	MaintenanceOrder: 'MOF',
	ManufacturingOrder: 'MOP',
	QualityOrder: 'MQO',
	Payroll: 'HRP',
	DistributionOrder: 'DOO',
	ManufacturingCostCollector: 'MCC',
	FixedAssetsAddition: 'FAA',
	FixedAssetsDisposal: 'FAD',
	FixedAssetsDepreciation: 'FDP',
} as const;

export type DocumentBaseType = (typeof documentBaseType)[keyof typeof documentBaseType];

export type DocumentStatusActionMap = {
	[P in DocumentBaseType]: {
		[Status in DocumentStatusValue]: DocumentActionValue[];
	};
};

export const documentSubTypeInventory = {
	AdReferenceId: 200068,
	PhysicalInventory: 'PI',
	InternalUseInventory: 'IU',
	CostAdjustment: 'CA',
};

export type DocumentSubTypeInventory = (typeof documentSubTypeInventory)[keyof typeof documentSubTypeInventory];

export const documentSubTypeSalesOrder = {
	AdReferenceId: 148,
	OnCreditOrder: 'WI',
	POSOrder: 'WR',
	WarehouseOrder: 'WP',
	StandardOrder: 'SO',
	Proposal: 'ON',
	Quotation: 'OB',
	ReturnMaterial: 'RM',
	PrepayOrder: 'PR',
};

export type DocumentSubTypeSalesOrder = (typeof documentSubTypeSalesOrder)[keyof typeof documentSubTypeSalesOrder];

export default class DocumentType extends BaseEntity {
	printName?: string;
	documentBaseType?: string;
	isSalesTransaction?: boolean;
	documentSalesSubType?: string;
	hasProforma?: boolean;
	isDocumentNumberControlled?: boolean;
	hasCharges?: boolean;
	documentNote?: string;
	isDefault?: boolean;
	documentCopies?: number;
	isDefaultCounterDocument?: boolean;
	isShipmentConfirm?: boolean;
	isPickQAConfirm?: boolean;
	isInTransit?: boolean;
	isSplitWhenDifference?: boolean;
	isCreateCounter?: boolean;
	isIndexed?: boolean;
	isOverwriteSequenceOnComplete?: boolean;
	isOverwriteDateOnComplete?: boolean;
	isPrepareSplitDocument?: boolean;
	isChargeOrProductMandatory?: boolean;
	documentInvoiceSubType?: string;

	constructor(props?: DeepPartial<DocumentType | DocumentTypeDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.printName = props.printName;
		this.documentBaseType = props.documentBaseType;
		this.isSalesTransaction = props.isSalesTransaction;
		this.documentSalesSubType = props.documentSalesSubType;
		this.hasProforma = props.hasProforma;
		this.isDocumentNumberControlled = props.isDocumentNumberControlled;
		this.hasCharges = props.hasCharges;
		this.documentNote = props.documentNote;
		this.isDefault = props.isDefault;
		this.documentCopies = props.documentCopies;
		this.isDefaultCounterDocument = props.isDefaultCounterDocument;
		this.isShipmentConfirm = props.isShipmentConfirm;
		this.isPickQAConfirm = props.isPickQAConfirm;
		this.isInTransit = props.isInTransit;
		this.isSplitWhenDifference = props.isSplitWhenDifference;
		this.isCreateCounter = props.isCreateCounter;
		this.isIndexed = props.isIndexed;
		this.isOverwriteSequenceOnComplete = props.isOverwriteSequenceOnComplete;
		this.isOverwriteDateOnComplete = props.isOverwriteDateOnComplete;
		this.isPrepareSplitDocument = props.isPrepareSplitDocument;
		this.isChargeOrProductMandatory = props.isChargeOrProductMandatory;
		this.documentInvoiceSubType = props.documentInvoiceSubType;
	}
}
