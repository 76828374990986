import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import useService from '../../hooks/useService';
import DBFilter from '../../models/DBFilter';
import { VoidedReasonDB } from '../../models/VoidedReason';
import { PageUuid } from '../../services/AuthService';
import { SEARCH } from '../../utils/Constants';
import { uiText } from '../../utils/Language';

export const fieldNames = {
	LINENO: 'lineNo',
	WINDOW_UUID: 'ad_window.ad_window_uu',
	VOIDED_REASON_UUID: 'bh_voided_reason.uuid',
	DOC_STATUS: 'docStatus',
} as const;

type VoidedReasonModalFormFields = { voidedReason: { uuid: string } };

type VoidedReasonModalProps = {
	windowUuid: PageUuid;
	onHandleClose: () => void;
	onVoidSubmit: SubmitHandler<VoidedReasonModalFormFields>;
};

/**
 * @deprecated Use VoidedReasonGraphQLModal instead
 */
const VoidedReasonModal = ({ windowUuid, onHandleClose, onVoidSubmit }: VoidedReasonModalProps) => {
	const { t } = useTranslation();
	const { voidedReasonService } = useService();
	const formData = {
		voidedReason: {
			uuid: '',
		},
	};
	const formMethods = useForm<VoidedReasonModalFormFields>({
		defaultValues: formData,
	});

	const { value: voidedReasons } = useAsync(
		async () =>
			(
				await voidedReasonService.get(
					SEARCH.DEFAULT_PAGE_NUMBER,
					SEARCH.DEFAULT_PAGE_SIZE,
					[{ id: fieldNames.LINENO, desc: false }],
					DBFilter<VoidedReasonDB>().property(fieldNames.WINDOW_UUID).equals(windowUuid),
				)
			).results,
		[voidedReasonService, windowUuid],
	);

	return (
		<Modal show={true}>
			<Form onSubmit={formMethods.handleSubmit(onVoidSubmit)} className="order-form">
				<Modal.Header>{t(uiText.visit.prompt.VOIDED_REASON_MESSAGE)}</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="voidedReason">
						<Form.Select
							required
							aria-label={t(uiText.voidedReason.VOIDED_REASON_TYPE)}
							{...formMethods.register('voidedReason.uuid')}
						>
							<option value=""></option>
							{voidedReasons &&
								voidedReasons.map(function (voidedReason, index) {
									return (
										<option key={index} value={voidedReason.uuid}>
											{voidedReason.name}
										</option>
									);
								})}
						</Form.Select>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="button"
						variant="danger"
						onClick={() => {
							onHandleClose();
						}}
					>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button type="submit" variant="success" className="ms-auto">
						{t(uiText.modal.button.VOID)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default VoidedReasonModal;
