import axios from 'axios';
import { BaseMetadata, BaseMetadataDto } from '../models';
import { configs } from '../utils/Configs';
import BaseEntityService from './BaseEntityService';

export default class DashboardService extends BaseEntityService<BaseMetadata, BaseMetadataDto> {
	entityTableName: string = 'bh_dashboard';
	entityName = 'dashboards';

	map(data: BaseMetadataDto) {
		return new BaseMetadata(data);
	}

	reverseMap(data: BaseMetadata): BaseMetadataDto {
		throw new Error('Method not implemented.');
	}

	async data(clientUuid: string, query: string) {
		return (await axios.post<any[]>(`${configs.apiUrl}/${this.entityName}/data/${clientUuid}`, query)).data;
	}
}
