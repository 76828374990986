import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';

export const chargeTypeDefaultNames = {
	EXPENSE_CATEGORY: 'Default Expense Category - DO NOT CHANGE',
	NON_PATIENT_PAYMENTS: 'Non-Patient Payment - DO NOT CHANGE',
	ONE_OFFS: 'One-offs - DO NOT CHANGE',
} as const;

export interface ChargeTypeDto extends BaseEntityDto {}

export interface ChargeTypeDB extends BaseEntityDB {}

/**
 * The class holding list values associated with a charge type
 */
export default class ChargeType extends BaseEntity {}
