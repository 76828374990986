import { useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Controller, UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import {
	C_ChargeForExpenseLineFragmentDoc,
	C_ChargeForExpenseLinesDocument,
	C_ChargeForExpenseLinesQuery,
} from '../../graphql/__generated__/graphql';
import { ChargeDB, chargeExpenseCategoryFilter, DBFilter, Paging } from '../../models';
import { SEARCH } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { getExpenseCategoryDisplay } from '../../utils/ModelUtils';
import EntityLookupGraphQL from '../entity-lookup/EntityLookupGraphQL';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { ExpenseFormFields } from './ExpenseForm';
import { ExpenseFormLineFields } from './ExpenseLineItemTable';

type ExpenseLineItemTableRowProps = {
	field?: ExpenseFormLineFields['C_InvoiceLines'][0];
	index?: number;
	remove?: UseFieldArrayReturn<ExpenseFormLineFields, 'C_InvoiceLines'>['remove'];
	shouldDisplayTheDeleteColumn: boolean;
	isAddRow?: boolean;
	isDataReadOnly?: boolean;
	allExpenseCharges: C_ChargeForExpenseLinesQuery['C_ChargeGet']['Results'];
};

export const chargeSortOrder = JSON.stringify([['name', 'asc']]);

const ExpenseLineItemTableRow = ({
	field,
	index,
	remove,
	shouldDisplayTheDeleteColumn,
	isAddRow,
	isDataReadOnly,
	allExpenseCharges,
}: ExpenseLineItemTableRowProps) => {
	const { t } = useTranslation();
	const { register } = useFormContext<ExpenseFormFields>();
	const graphqlClient = useApolloClient();

	const fieldPrefix = isAddRow ? 'addInvoiceLine' : `C_InvoiceLines.${index}`;
	const [{ value: expenseCategoryOptions, loading: areLoadingCharges }, onSearchExpenseCategories] = useAsyncFn(
		async (query: string = '') =>
			query === ''
				? allExpenseCharges
				: (
						await graphqlClient.query({
							query: C_ChargeForExpenseLinesDocument,
							variables: {
								Page: Paging.ALL.page,
								Size: Paging.ALL.size,
								Sort: chargeSortOrder,
								Filter: chargeExpenseCategoryFilter()
									.or(DBFilter<ChargeDB>().property('name').contains(query))
									.or(DBFilter<ChargeDB>().property('description').contains(query))
									.toString(),
							},
							fetchPolicy: 'network-only',
						})
					).data.C_ChargeGet.Results,
		[graphqlClient, allExpenseCharges],
		{ value: allExpenseCharges, loading: false },
	);

	return (
		<tr>
			<td>
				{!isAddRow && (
					<input type="hidden" {...register(`${fieldPrefix}.UU` as 'C_InvoiceLines.0.UU')} defaultValue={field?.UU} />
				)}
				<EntityLookupGraphQL<ExpenseFormLineFields, 'C_InvoiceLines.0.C_Charge'>
					name={`${fieldPrefix}.C_Charge` as 'C_InvoiceLines.0.C_Charge'}
					rules={{ required: !isAddRow }}
					defaultValue={field?.C_Charge}
					isLoading={areLoadingCharges}
					id={`${fieldPrefix}.C_Charge`}
					delay={SEARCH.INPUT_DELAY}
					inputProps={{ 'aria-label': t(uiText.expense.lineItem.header.CATEGORY) }}
					emptyLabel={t(uiText.expense.lineItem.search.EMPTY)}
					ignoreDiacritics={true}
					labelKey={(expenseCategory) => {
						const cachedExpenseCategory = graphqlClient.readFragment({
							id: expenseCategory.UU,
							fragment: C_ChargeForExpenseLineFragmentDoc,
						});
						if (cachedExpenseCategory) {
							return getExpenseCategoryDisplay(cachedExpenseCategory);
						}
						return '';
					}}
					minLength={0}
					placeholder={t(uiText.expense.category.PLACEHOLDER)}
					promptText={t(uiText.expense.lineItem.search.PROMPT_TEXT)}
					searchText={t(uiText.expense.lineItem.search.SEARCH_TEXT)}
					options={expenseCategoryOptions || []}
					onSearch={onSearchExpenseCategories}
					disabled={isDataReadOnly}
					onInputChange={(text) => {
						if (text === '') {
							onSearchExpenseCategories('');
						}
					}}
					onFocus={(event) => {
						if ((event?.target as any)?.value === '') {
							onSearchExpenseCategories('');
						}
					}}
				/>
			</td>
			<td>
				<Form.Control
					aria-label={t(uiText.expense.lineItem.header.DESCRIPTION)}
					{...register(`${fieldPrefix}.Description` as 'C_InvoiceLines.0.Description')}
				/>
			</td>
			<td>
				<Controller<ExpenseFormLineFields, 'C_InvoiceLines.0.Price'>
					name={`${fieldPrefix}.Price` as 'C_InvoiceLines.0.Price'}
					rules={{ validate: (value) => isAddRow || (value || 0) > 0 }}
					render={({ field }) => <FormatNumberInput aria-label={t(uiText.expense.lineItem.header.AMOUNT)} {...field} />}
				/>
			</td>
			{shouldDisplayTheDeleteColumn && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							aria-label={t(uiText.expense.button.DELETE)}
							className="btn p-0 w-100"
							tabIndex={-1}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default ExpenseLineItemTableRow;
