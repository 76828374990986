import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

let isInitialized = false;

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en-US',
		debug: true,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
			format: (value, format) => {
				if (!value) {
					return value;
				}
				switch (format) {
					case 'uppercase':
						return value.toUpperCase();
					case 'lowercase':
						return value.toLowerCase();
					case 'capitalize':
						const lowerValue = value.toLowerCase();
						return lowerValue[0].toUpperCase() + lowerValue.substring(1);
					default:
						return value;
				}
			},
		},
		backend: {
			queryStringParams: { v: window._env_.REACT_APP_VERSION },
		},
	})
	.then(() => {
		isInitialized = true;
	});

// Update the title displayed in the browser when changing language
i18n.on('languageChanged', async () => {
	if (isInitialized) {
		document.title = i18n.t('common.companyAndMotto');
	}
});

export default i18n;
