import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Charge, { ChargeDto } from './Charge';
import User, { UserDto } from './User';
import OrderLine, { OrderLineDto } from './OrderLine';
import Locator, { LocatorDto } from './Locator';
import Product, { ProductDto } from './Product';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';

export interface InOutLineDB extends BaseMetadataDB {}

export interface InOutLineDto extends BaseMetadataDto {
	line?: string;
	description?: string;
	orderLine?: OrderLineDto | null;
	locator?: LocatorDto | null;
	product?: ProductDto | null;
	movementQuantity?: number;
	isInvoiced?: boolean;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	isDescription?: boolean;
	confirmedQuantity?: number;
	pickedQuantity?: number;
	scrappedQuantity?: number;
	targetQuantity?: number;
	processed?: boolean;
	quantityEntered?: number;
	charge?: ChargeDto | null;
	user1?: UserDto | null;
	user2?: UserDto | null;
	quantityOverReceipt?: number;
}

export default class InOutLine extends BaseMetadata {
	line: string;
	description: string;
	orderLine: OrderLine;
	locator: Locator;
	product: Product;
	movementQuantity: number;
	isInvoiced: boolean;
	attributeSetInstance: AttributeSetInstance;
	isDescription: boolean;
	confirmedQuantity: number;
	pickedQuantity: number;
	scrappedQuantity: number;
	targetQuantity: number;
	processed: boolean;
	quantityEntered: number;
	charge: Charge;
	user1: User;
	user2: User;
	quantityOverReceipt: number;

	constructor(props?: DeepPartial<InOutLine | InOutLineDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.line = props.line || '';
		this.description = props.description || '';

		this.orderLine = new OrderLine('orderLine' in props && props.orderLine ? props.orderLine : {});
		this.locator = new Locator('locator' in props && props.locator ? props.locator : {});
		this.product = new Product('product' in props && props.product ? props.product : {});
		this.movementQuantity = props.movementQuantity || 0;
		this.isInvoiced = props.isInvoiced === true;
		this.attributeSetInstance = new AttributeSetInstance(
			'attributeSetInstance' in props && props.attributeSetInstance ? props.attributeSetInstance : {},
		);
		this.isDescription = props.isDescription === true;
		this.confirmedQuantity = props.confirmedQuantity || 0;
		this.pickedQuantity = props.pickedQuantity || 0;
		this.scrappedQuantity = props.scrappedQuantity || 0;
		this.targetQuantity = props.targetQuantity || 0;
		this.processed = props.processed === true;
		this.quantityEntered = props.quantityEntered || 0;
		this.charge = new Charge('charge' in props && props.charge ? props.charge : {});
		this.user1 = new User('user1' in props && props.user1 ? props.user1 : {});
		this.user2 = new User('user2' in props && props.user2 ? props.user2 : {});
		this.quantityOverReceipt = props.quantityOverReceipt || 0;
	}
}
