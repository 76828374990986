import axios from 'axios';
import { isArray } from 'lodash';
import Product, { ProductDB, ProductDto } from '../models/Product';
import Response from '../models/Response';
import { StorageOnHandDto } from '../models/StorageOnHand';
import { configs } from '../utils/Configs';
import BaseEntityService from './BaseEntityService';

export default class ProductService extends BaseEntityService<Product, ProductDto, ProductDB> {
	public entityTableName: string = 'm_product';
	entityName = 'products';

	/**
	 * Search products/services
	 *
	 * @param query
	 */
	searchProductServices(query: string): Promise<Response<Product>> {
		return axios
			.get<Response<ProductDto>>(`${configs.apiUrl}/${this.entityName}/search/items?value=${query}`)
			.then(({ data }) => {
				let mappedData: Product[] = [];
				if (data && isArray(data.results)) {
					mappedData = data.results.map(this.map);
				}
				return new Response<Product>({ ...data, results: mappedData });
			});
	}

	map(data: ProductDto) {
		return new Product(data);
	}

	reverseMap(data: Product): ProductDto {
		return {
			...data,
			storageOnHandList: (data.storageOnHandList || []).map(
				(storageOnHand): StorageOnHandDto => ({
					...storageOnHand,
					attributeSetInstance: {
						...storageOnHand.attributeSetInstance,
						guaranteeDate: storageOnHand.attributeSetInstance.guaranteeDate?.getTime(),
						serialNumber: storageOnHand.attributeSetInstance.serialNumber || null,
						lot: storageOnHand.attributeSetInstance.lot || null,
					},
					product: undefined,
				}),
			),
			attributeSet: data.attributeSet
				? {
						...data.attributeSet,
						serialNumberControl: data.attributeSet.serialNumberControl
							? {
									...data.attributeSet.serialNumberControl,
									startNumber: 0,
									incrementNumber: 0,
									currentNext: 0,
									prefix: null,
									suffix: null,
								}
							: null,
						mandatoryType: null,
						serialNumberEndCharacterOverwrite: data.attributeSet.serialNumberEndCharacterOverwrite || null,
						serialNumberStartCharacterOverwrite: data.attributeSet.serialNumberStartCharacterOverwrite || null,
						lotStartCharacterOverwrite: data.attributeSet.lotStartCharacterOverwrite || null,
						lotEndCharacterOverwrite: data.attributeSet.lotEndCharacterOverwrite || null,
						type: data.attributeSet.type || null,
					}
				: null,
		};
	}
}
