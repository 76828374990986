import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Report, { ReportDto } from './Report';
import { TreeNodeMMDB } from './TreeNodeMM';
import Window, { WindowDto } from './Window';

export const menuUuid = {
	REPORTS_DROPDOWN: '35ce7d6a-cf7d-4962-a748-75e27d0121bf',
} as const;

export interface MenuDB extends BaseEntityDB {
	ad_menu_uu: string;
	'ad_treenodemm::ad_menu_id->node_id': TreeNodeMMDB;
}

export interface MenuDto extends BaseEntityDto {
	iconClassName: string | null;
	window: WindowDto | null;
	process: ReportDto | null;
	subMenus: MenuDto[] | null;
	sequenceNumber: number | null;
}

export default class Menu extends BaseEntity {
	iconClassName: string;
	window?: Window;
	process?: Report;
	subMenus: Menu[];
	sequenceNumber: number;

	get className(): IconName | undefined {
		return (this.iconClassName && (this.iconClassName.split(' ')[1].replace(/fa-/g, '') as IconName)) || undefined;
	}

	get brand(): IconPrefix {
		return this.iconClassName.split(' ')[0].replace('r', '') as IconPrefix;
	}

	constructor(props?: Partial<Menu | MenuDto>) {
		props = cloneDeep(props || {});
		super(props);
		this.sequenceNumber = props.sequenceNumber || 0;
		this.iconClassName = props.iconClassName || '';
		this.window = props.window ? new Window(props.window) : undefined;
		this.process = props.process ? new Report(props.process) : undefined;
		this.subMenus = (props.subMenus || []).map((menu) => new Menu(menu));
	}
}
