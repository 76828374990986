import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import { useAsync, useAsyncFn } from 'react-use';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useService from '../../hooks/useService';
import useStateWithReset from '../../hooks/useStateWithReset';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import {
	BusinessPartner,
	BusinessPartnerDB,
	businessPartnerGroupName,
	DocAction,
	DocumentStatus,
	OPEN_BALANCE_INVOICE,
	PAYMENT_TRAIL,
	ProcessType,
} from '../../models';
import DBFilter, { Filter } from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { pageUuid } from '../../services/AuthService';
import { ReportTypes } from '../../services/ReportService';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { SERVICE_DEBT_PAGE, VISITS_PAGE } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { getAgeDisplay } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import BHTable from '../BHTable/BHTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReportWindow from '../Reports/ReportWindow';
import PaymentForm from '../ServiceDebt/PaymentForm';
import { setVisitStateForStartingNewVisitWithPatientSelected } from '../Visit/Visits';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import PatientForm from './PatientForm';
import WaiveOpenBalance from './WaiveOpenBalance';

const PaymentServiceOperations = {
	PAY_OPEN_BALANCE: 'payOpenBalance',
} as const;
export type PatientLocationState = { fromSave: boolean; data?: BusinessPartner };

export const setPatientStateForStartingNewPatient = (patient?: BusinessPartner): PatientLocationState => {
	return { fromSave: true, data: patient };
};

const availableFilters = {
	[uiText.patient.filter.INACTIVE]: DBFilter<BusinessPartnerDB>().property('isactive').equals(false),
	[uiText.patient.filter.ACTIVE]: IS_ACTIVE,
	dateLastVisit: [
		uiText.patient.filter.ALL,
		uiText.patient.filter.TODAY,
		uiText.patient.filter.YESTERDAY,
		uiText.patient.filter.LAST_7_DAYS,
		uiText.patient.filter.LAST_30_DAYS,
	],
} as const;

const PatientList = () => {
	const { t } = useTranslation();
	const { disableWrite } = useActionPrivileges(pageUuid.PATIENTS);
	const { businessPartnerService, processTypeService, reportService } = useService();
	const { state } = useLocation<PatientLocationState | undefined>();
	const history = useHistory();

	const [searchText, setSearchText] = useState('');
	const { willTrigger: willTriggerFilters, triggerUpdate: triggerFilterRefresh } = useTriggerUpdate();
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<BusinessPartner, BusinessPartnerDB>(
		{
			fetch: useCallback((...props) => businessPartnerService.get(...props), [businessPartnerService]),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.patient.error.CANNOT_LOAD));
					}
					exception({ description: `Patient fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			viewState: state?.fromSave ? (state.data ? ListPageState.ADD_EDIT : ListPageState.ADD_EDIT) : undefined,
			selectedUuid: state?.fromSave ? state.data?.uuid : undefined,
			fetchDataInitially: false,
		},
	);

	// Filter states
	const [inactiveFilter, setInactiveFilter, { reset: resetInactiveFilter }] = useStateWithReset(
		uiText.patient.filter.ACTIVE,
	);
	const [dateLastVisitFilter, setDateLastVisitFilter, { reset: resetDateLastVisitFilter }] = useStateWithReset(
		uiText.patient.filter.ALL,
	);

	// Handle searching and filtering
	useEffect(() => {
		const getDateLastVisitFilter = (): Filter<BusinessPartnerDB> | undefined => {
			const visitFilter = DBFilter<BusinessPartnerDB>().nested('bh_visit::patient_id').property('bh_visitdate');
			switch (dateLastVisitFilter) {
				case uiText.patient.filter.TODAY:
					return visitFilter.equals(new Date(formatDate(new Date()))).up();
				case uiText.patient.filter.YESTERDAY:
					const yesterday = new Date();
					yesterday.setDate(yesterday.getDate() - 1);
					return visitFilter.equals(new Date(formatDate(yesterday))).up();
				case uiText.patient.filter.LAST_7_DAYS:
					const sevenDaysAgo = new Date();
					sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
					return visitFilter
						.isLessThanOrEqualTo(new Date(formatDate(new Date())))
						.property('bh_visitdate')
						.isGreaterThanOrEqualTo(new Date(formatDate(sevenDaysAgo)))
						.up();
				case uiText.patient.filter.LAST_30_DAYS:
					const thirtyDaysAgo = new Date();
					thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
					return visitFilter
						.isLessThanOrEqualTo(new Date(formatDate(new Date())))
						.property('bh_visitdate')
						.isGreaterThanOrEqualTo(new Date(formatDate(thirtyDaysAgo)))
						.up();
				default:
					break;
			}
		};

		let defaultFilter = DBFilter<BusinessPartnerDB>()
			.and(
				DBFilter<BusinessPartnerDB>()
					.nested('c_bp_group')
					.property('name')
					.equals(businessPartnerGroupName.PATIENTS)
					.up(),
			)
			.and(availableFilters[inactiveFilter] as Filter<BusinessPartnerDB>);
		if (searchText) {
			defaultFilter.or(businessPartnerService.getPatientSearchFilter(searchText));
		}

		let dateFilter = getDateLastVisitFilter();
		if (dateFilter) {
			defaultFilter = defaultFilter.and(dateFilter);
		}
		onFilterUpdate(defaultFilter);
	}, [searchText, onFilterUpdate, inactiveFilter, dateLastVisitFilter, businessPartnerService, willTriggerFilters]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetInactiveFilter();
		resetDateLastVisitFilter();
		triggerFilterRefresh();
		reset(false);
	});

	const [paymentBusinessPartner, setPaymentBusinessPartner] = useState<BusinessPartner | undefined>();
	const [showWaiveOpenBalanceModal, setShowWaiveOpenBalanceModal] = useState(false);
	const [patientToWaiveBalanceFor, setPatientToWaiveBalanceFor] = useState<BusinessPartner | undefined>(undefined);

	const { value: canViewPaymentTrail = false } = useAsync(
		async () => reportService.doesUserHaveAccessToReport(PAYMENT_TRAIL),
		[reportService],
	);
	const { value: canViewOpenBalanceInvoice = false } = useAsync(
		async () => reportService.doesUserHaveAccessToReport(OPEN_BALANCE_INVOICE),
		[reportService],
	);

	const [{ loading: isLoadingReport, value: reportResponse }, generateReport] = useAsyncFn(
		async (reportUuid: string, patientUuid: string) =>
			new Blob([await reportService.generateReportWithGivenParameterValue(reportUuid, patientUuid, ReportTypes.PDF)], {
				type: 'application/pdf',
			}),
		[reportService],
	);

	const onPaymentServiceOperationClick = (
		e: React.MouseEvent<any>,
		paymentServiceOperation: string,
		patient: BusinessPartner,
	) => {
		e.stopPropagation();
		if (paymentServiceOperation === PAYMENT_TRAIL || paymentServiceOperation === OPEN_BALANCE_INVOICE) {
			generateReport(paymentServiceOperation, patient.uuid);
		} else if (paymentServiceOperation === PaymentServiceOperations.PAY_OPEN_BALANCE) {
			setViewState(ListPageState.CUSTOM);
			setPaymentBusinessPartner(patient);
		}
	};

	const onNewVisitOperationClick = (e: React.MouseEvent<any>, patient: BusinessPartner) => {
		e.stopPropagation();
		history.push({
			pathname: VISITS_PAGE,
			state: setVisitStateForStartingNewVisitWithPatientSelected(patient),
		});
	};

	const canCreatePayments = !useActionPrivileges(pageUuid.PAYMENTS).disableWrite;
	const canCreateVisits = !useActionPrivileges(pageUuid.VISITS).disableWrite;
	const { value: canWaiveDebt = false } = useAsync(
		async () =>
			(
				await processTypeService.getAvailableActionsByProcessTypeAndStatus(
					ProcessType.CUSTOMER_INVOICE,
					DocumentStatus.DRAFTED,
				)
			).includes(DocAction.COMPLETE) && canCreatePayments,
		[canCreatePayments, processTypeService],
	);

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.patient.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="activeFilter" className="mt-0_5">
									<Form.Check
										checked={inactiveFilter === uiText.patient.filter.INACTIVE}
										onChange={(e) =>
											setInactiveFilter(
												e.target.checked ? uiText.patient.filter.INACTIVE : uiText.patient.filter.ACTIVE,
											)
										}
										label={t(uiText.patient.filter.SHOW_INACTIVE)}
									/>
								</Form.Group>
								<Form.Group controlId="lastVisitDateFilter">
									<Form.Label column>{t(uiText.patient.filter.DATE_LAST_VISIT)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={dateLastVisitFilter}
										onChange={(e) => setDateLastVisitFilter(e.target.value)}
									>
										{availableFilters.dateLastVisit.map((filter) => (
											<option key={filter} value={filter}>
												{t(filter)}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							{reportResponse ? <ReportWindow response={reportResponse} /> : null}
							{showWaiveOpenBalanceModal && patientToWaiveBalanceFor && (
								<WaiveOpenBalance
									patient={patientToWaiveBalanceFor}
									onClose={() => {
										setShowWaiveOpenBalanceModal(false);
										setViewState(ListPageState.LIST);
										refresh();
									}}
								/>
							)}
							<BHTable<BusinessPartner, BusinessPartnerDB>
								data={data}
								columns={[
									{
										id: 'created',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.created.LABEL)}</div>,
										accessor: (d) => d.created,
									},
									{
										id: 'BH_PatientID',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.patient.patientNumber.BANDA_ABBREVIATED)}</div>
										),
										accessor: (d) => d.patientNumber,
										disableSortBy: true,
									},
									{
										id: 'BH_Local_PatientID',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.patient.patientNumber.LOCAL_ABBREVIATED)}</div>
										),
										accessor: (d) => d.localPatientNumber,
										disableSortBy: true,
									},
									{
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.name.LABEL)}</div>,
										accessor: 'name',
									},
									{
										id: 'age',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.age.LABEL)}</div>,
										accessor: (d) => getAgeDisplay(d),
									},
									{
										id: 'gender',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.gender.LABEL)}</div>,
										accessor: (d) => d.gender,
									},
									{
										id: 'bh_phone',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.phone.LABEL)}</div>,
										accessor: (d) => d.phone,
										disableSortBy: true,
									},
									{
										id: 'totalopenbalance',
										Header: () => <div className={'React-table-header'}>{t(uiText.patient.OPEN_BALANCE)}</div>,
										accessor: (d) => (
											<div className="d-flex justify-content-center">{formatNumber(d.totalOpenBalance)}</div>
										),
									},
									{
										id: 'payOpenBalance',
										Header: '',
										Cell: (row: CellProps<BusinessPartner>) =>
											([
												canCreatePayments,
												canViewPaymentTrail,
												canCreateVisits,
												canWaiveDebt,
												canViewOpenBalanceInvoice,
											].some((condition) => condition) && (
												<BHDropdownButton
													title={t(uiText.patient.button.ACTION)}
													icon="ellipsis-h"
													variant="bh-light"
													className="py-0"
												>
													{canWaiveDebt && (
														<Dropdown.Item
															onClick={() => {
																setShowWaiveOpenBalanceModal(true);
																setPatientToWaiveBalanceFor(row.cell.row.original);
															}}
															disabled={row.cell.row.original.totalOpenBalance === 0}
														>
															<FontAwesomeIcon icon="eraser" className="me-2 fa-fw" />
															{t(uiText.patient.button.WAIVE_OPEN_BALANCE)}
														</Dropdown.Item>
													)}
													{canCreatePayments && (
														<Dropdown.Item
															onClick={(e) =>
																onPaymentServiceOperationClick(
																	e,
																	PaymentServiceOperations.PAY_OPEN_BALANCE,
																	row.cell.row.original,
																)
															}
															disabled={row.cell.row.original.totalOpenBalance === 0}
														>
															<FontAwesomeIcon icon="money-bill-alt" className="me-2 fa-fw" />
															{t(uiText.patient.button.PAY_OPEN_BALANCE)}
														</Dropdown.Item>
													)}
													{canViewPaymentTrail && (
														<Dropdown.Item
															onClick={(e) => onPaymentServiceOperationClick(e, PAYMENT_TRAIL, row.cell.row.original)}
														>
															<FontAwesomeIcon icon="book-medical" className="me-2 fa-fw" />
															{t(uiText.patient.button.VIEW_PAYMENT_TRAIL)}
														</Dropdown.Item>
													)}
													{canViewOpenBalanceInvoice && (
														<Dropdown.Item
															onClick={(e) =>
																onPaymentServiceOperationClick(e, OPEN_BALANCE_INVOICE, row.cell.row.original)
															}
															disabled={row.cell.row.original.totalOpenBalance === 0}
														>
															<FontAwesomeIcon icon="file-invoice" className="me-2 fa-fw" />
															{t(uiText.patient.button.OPEN_BALANCE_INVOICE)}
														</Dropdown.Item>
													)}
													{canCreateVisits && (
														<Dropdown.Item onClick={(e) => onNewVisitOperationClick(e, row.cell.row.original)}>
															<FontAwesomeIcon icon="file" className="me-2 fa-fw" />
															{t(uiText.patient.button.START_VISIT)}
														</Dropdown.Item>
													)}
												</BHDropdownButton>
											)) ||
											null,
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading || isLoadingReport} title={t(uiText.patient.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : viewState === ListPageState.CUSTOM ? (
				<PaymentForm
					businessPartner={paymentBusinessPartner}
					onFinish={() => {
						history.push(SERVICE_DEBT_PAGE);
						setPaymentBusinessPartner(undefined);
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			) : (
				<PatientForm
					uuid={selectedUuid}
					onFinish={(refreshData) => {
						if (!refreshData && state && 'fromSave' in state) {
							state.fromSave = false;
						}
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			)}
		</Layout>
	);
};

export default PatientList;
