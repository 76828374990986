import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Col, ListGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAsync, useLocation } from 'react-use';
import logo from '../../assets/images/b-logo.png';
import MenuContext from '../../contexts/MenuContext';
import UserContext from '../../contexts/UserContext';
import useService from '../../hooks/useService';
import { Menu, menuUuid } from '../../models';
import { pageUuid } from '../../services/AuthService';
import { HOME_PAGE } from '../../utils/Constants';
import { siteMap } from '../App/App';
import './MainMenu.scss';

const getPath = (menu: Menu) => siteMap[menu.window?.uuid || '']?.path || HOME_PAGE;

const getAllChildrenUuids = (menu: Menu): string[] => {
	if (menu.subMenus.length) {
		return menu.subMenus.reduce(
			(menuUuidList, childMenu) => [...menuUuidList, ...getAllChildrenUuids(childMenu)],
			[] as string[],
		);
	}
	return [menu.uuid];
};

const MenuItem = ({
	menu,
	canViewReportsMenu,
	nested = false,
	onPageSelected,
}: {
	menu: Menu;
	canViewReportsMenu: boolean;
	nested?: boolean;
	onPageSelected: (allSelections: string[]) => void;
}) => {
	const { organization } = useContext(UserContext);
	const {
		activeMenuUuid = '',
		selectedMenuUuids = [],
		setSelectedMenuUuids = () => {},
		activeMenuUuidFamily = [],
	} = useContext(MenuContext) || {};
	const allChildrenMenuUuids = useMemo(() => getAllChildrenUuids(menu), [menu]);
	const [hidden, setHidden] = useState<boolean>(!activeMenuUuidFamily.includes(menu.uuid));

	// If this isn't active, or it's an item needing more than one warehouse and they only have one, or it
	// doesn't have children and doesn't have a window it links to, return nothing
	if (
		!menu.isActive ||
		(menu.window?.uuid === pageUuid.TRANSFER_INVENTORY && organization.warehouses.length <= 1) ||
		(!menu.subMenus.length && !menu.window) ||
		(menu.window?.uuid === pageUuid.REPORTS && !canViewReportsMenu)
	) {
		return <></>;
	}
	// If it has no children or none of it's children are supposed to be shown, just show a link
	if (!menu.subMenus.length) {
		return (
			<ListGroup.Item
				as={NavLink}
				key={menu.uuid}
				to={getPath(menu)}
				className={`${nested ? 'border-0 ps-5' : ''} ${activeMenuUuid === menu.uuid ? 'active' : ''}`}
				action
				activeClassName={''}
				onClick={() => onPageSelected([menu.uuid])}
			>
				{menu.className && <FontAwesomeIcon icon={[menu.brand, menu.className]} className="me-2 fa-fw" />}
				<span>{menu.name}</span>
			</ListGroup.Item>
		);
	}
	return (
		<ListGroup.Item
			className={`border-top-0 p-0 ${allChildrenMenuUuids.includes(activeMenuUuid) ? 'active' : ''}`}
			action
			as="div"
		>
			<div role="heading" aria-level={nested ? 1 : 0}>
				<div
					className={`accordion-button py-2_5 cursor-pointer shadow-none pe-4 ${
						allChildrenMenuUuids.includes(activeMenuUuid) ? 'bh-accordion-selected' : ''
					} ${selectedMenuUuids.includes(menu.uuid) ? '' : 'collapsed'}`}
					onClick={() => {
						// If this menu is part of the active family, we do nothing
						if (activeMenuUuidFamily.includes(menu.uuid)) {
							return;
						}
						// Add/remove it as necessary
						if (selectedMenuUuids.includes(menu.uuid)) {
							setSelectedMenuUuids([...activeMenuUuidFamily]);
						} else {
							setSelectedMenuUuids([...activeMenuUuidFamily, menu.uuid]);
						}
					}}
					role="button"
					aria-expanded={selectedMenuUuids.includes(menu.uuid) ? 'true' : 'false'}
					aria-controls={`${menu.uuid}Region`}
					aria-disabled={activeMenuUuidFamily.includes(menu.uuid) || undefined}
					id={`${menu.uuid}Accordion`}
				>
					{menu.className && <FontAwesomeIcon icon={[menu.brand, menu.className]} className="me-2 fa-fw" />}
					<span>{menu.name}</span>
				</div>
			</div>
			<div
				role="region"
				id={`${menu.uuid}Region`}
				aria-labelledby={`${menu.uuid}Accordion`}
				hidden={!selectedMenuUuids.includes(menu.uuid) && hidden}
			>
				<Accordion.Collapse
					eventKey={menu.uuid}
					className="p-0"
					onExited={() => setHidden(true)}
					onEntered={() => setHidden(false)}
				>
					<>
						{sortBy(menu.subMenus, 'sequenceNumber').map((childMenu) => (
							<MenuItem
								menu={childMenu}
								canViewReportsMenu={canViewReportsMenu}
								key={childMenu.uuid}
								nested={true}
								onPageSelected={(childSelections) => onPageSelected([...childSelections, menu.uuid])}
							/>
						))}
					</>
				</Accordion.Collapse>
			</div>
		</ListGroup.Item>
	);
};

const MainMenu = () => {
	const { role } = useContext(UserContext);
	const { menuService } = useService();
	const [activeMenuUuid, setActiveMenuUuid] = useState('');
	const [activeMenuUuidFamily, setActiveMenuUuidFamily] = useState<string[]>([]);
	const [selectedMenuUuids, setSelectedMenuUuids] = useState<string[]>([]);
	const haveSetInitialState = useRef(false);
	const { pathname } = useLocation();

	const { value: menus } = useAsync(() => menuService.get(true), [menuService, role.uuid]);
	// The report menu item is special (we need to see if the user has any reports they can run and display it, if so)
	const { value: canViewReportsMenu = false } = useAsync(
		async () => (await menuService.getByRootId(menuUuid.REPORTS_DROPDOWN, true)).length > 0,
		[menuService, role.uuid],
	);
	const selectedMenuFamily = useMemo<Menu[]>(() => {
		if (!menus?.length || pathname === '/') {
			return [];
		}
		const getSelectedMenuUuidFamily = (menusToCheck: Menu[]): Menu[] => {
			// If any of these menus match the location, we found it!
			const foundMenu = menusToCheck.find((menu) => getPath(menu) === pathname);
			if (foundMenu) {
				return [foundMenu];
			}
			// Cycle through each menu and check it's children
			return (
				menusToCheck
					.filter((menu) => menu.subMenus.length > 0)
					.map((menu) => {
						const selectedMenuUuidFamily = getSelectedMenuUuidFamily(menu.subMenus);
						if (selectedMenuUuidFamily.length) {
							return [...selectedMenuUuidFamily, menu];
						}
						return [];
					})
					.filter((selectedMenuFamily) => selectedMenuFamily.length > 0)[0] || []
			);
		};
		return getSelectedMenuUuidFamily(menus);
	}, [menus, pathname]);

	// Once the menus load, set the active/open menu, if any
	useEffect(() => {
		// We'll exit out if we're on the initial page or we've initialized the page, have menus selected, and the current path
		// is in the selection
		if (
			!selectedMenuFamily.length ||
			(haveSetInitialState.current &&
				pathname === getPath(selectedMenuFamily[0]) &&
				selectedMenuFamily[0].uuid === activeMenuUuid)
		) {
			return;
		}
		haveSetInitialState.current = true;
		const selectedMenuFamilyUuids = selectedMenuFamily.map((menu) => menu.uuid);
		setActiveMenuUuid(selectedMenuFamilyUuids[0] || '');
		setSelectedMenuUuids(selectedMenuFamilyUuids);
		setActiveMenuUuidFamily(selectedMenuFamilyUuids);
	}, [selectedMenuFamily, pathname, activeMenuUuid]);

	return (
		<MenuContext.Provider value={{ activeMenuUuid, selectedMenuUuids, setSelectedMenuUuids, activeMenuUuidFamily }}>
			<Col className="px-0 shadow print__d-none menu-wrapper min-vh-100 bg-white">
				<div className="position-sticky top-0 vh-100">
					<div className="position-absolute">
						<div className="menu-logo w-100 d-flex justify-content-center py-1">
							<img src={logo} alt="BandaHealth Logo" />
						</div>
						<Accordion flush as={ListGroup} variant="flush" activeKey={selectedMenuUuids}>
							{sortBy(menus, 'sequenceNumber').map((menu) => (
								<MenuItem
									menu={menu}
									canViewReportsMenu={canViewReportsMenu}
									key={menu.uuid}
									onPageSelected={(allSelections) => {
										setActiveMenuUuid(allSelections[0]);
										setSelectedMenuUuids(allSelections);
										setActiveMenuUuidFamily(allSelections);
									}}
								/>
							))}
						</Accordion>
					</div>
				</div>
			</Col>
		</MenuContext.Provider>
	);
};

export default MainMenu;
