import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Reference from './Reference';

export interface ColumnDto extends BaseEntityDto {
	reference?: BaseEntityDto;
	referenceValue?: BaseEntityDto;
	sequenceNumber: number;
	defaultValue?: string;
	mandatory?: boolean;
	placeholder?: string;
	columnName?: string | null;
}

export interface ColumnDB extends BaseEntityDB {}

export default class Column extends BaseEntity {
	reference?: Reference;
	referenceValue?: Reference;
	sequenceNumber: number;
	defaultValue?: string;
	mandatory?: boolean;
	placeholder?: string;

	constructor(props: Partial<ColumnDto | Column> = {}) {
		props = cloneDeep(props);
		super(props);

		this.reference = new Reference(('reference' in props && props.reference) || {});
		this.referenceValue = new Reference(('referenceValue' in props && props.reference) || {});
		this.sequenceNumber = props.sequenceNumber || 0;
		this.defaultValue = props.defaultValue || '';
		this.mandatory = props.mandatory;
		this.placeholder = props.placeholder || '';
	}
}
