import VoidedReason, { VoidedReasonDB, VoidedReasonDto } from '../models/VoidedReason';
import BaseEntityService from './BaseEntityService';

export default class VoidedReasonService extends BaseEntityService<VoidedReason, VoidedReasonDto, VoidedReasonDB> {
	public entityTableName: string = 'bh_voided_reason';
	protected entityName = `voided-reasons`;

	map(data: VoidedReasonDto): VoidedReason {
		return new VoidedReason(data);
	}

	reverseMap(data: VoidedReason): VoidedReasonDto {
		throw new Error('Method not implemented.');
	}
}
