import { useEffect, useState } from 'react';
import { DocumentActionValue } from '../models/DocAction';
import DocumentStatus, { DocumentStatusValue } from '../models/DocumentStatus';
import { ProcessTypeValue } from '../models/ProcessType';
import useService from './useService';

/**
 * This provides document action information, such as whether a user can VOID and what the VOID action is, for a given entity
 * based on the process type this entity falls under. This component is looking for a property with the name `data` that has a `docStatus` property.
 * @param processType The process type to get document action information for
 * @param entitiesDocumentStatus An optional entity status to get data for (DRAFTED will be used by default)
 * @deprecated Use useGraphQLDocumentActionInformation instead
 */
export default function useDocumentActionInformation(
	processType: ProcessTypeValue,
	entitiesDocumentStatus?: DocumentStatusValue | string,
) {
	const [canVoidDocument, setCanVoidDocument] = useState(false);
	const [voidDocumentAction, setVoidDocumentAction] = useState<DocumentActionValue | undefined>();
	const documentStatus = (entitiesDocumentStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
	const { processTypeService } = useService();

	useEffect(() => {
		// Define an async function since an effect's function can't be async
		const determineDocumentActions = async () => {
			setCanVoidDocument(await processTypeService.canVoidDocument(processType, documentStatus));
			setVoidDocumentAction(await processTypeService.getVoidAction(processType, documentStatus));
		};
		determineDocumentActions();
	}, [documentStatus, processType, processTypeService]);

	return {
		canVoidDocument,
		voidDocumentAction,
	} as const;
}
