import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Charge, { ChargeDto } from './Charge';
import DBFilter from './DBFilter';
import ReferenceList, { ReferenceListDto } from './ReferenceList';

export const businessPartnerGroupName = {
	INSURANCE_CAPTITATION: 'Capitation Insurance - DO NOT CHANGE',
	OTC: 'OTC Patient',
	PATIENTS: 'Patients - DO NOT CHANGE',
	STANDARD: 'Standard',
} as const;

export const businessPartnerGroupSubType = { Insurance: 'I', Waiver: 'W', Donation: 'D' } as const;

export interface BusinessPartnerGroupDB extends BaseEntityDB {
	bh_subtype: string;
}

export interface BusinessPartnerGroupDto extends BaseEntityDto {
	subType?: ReferenceListDto | null;
	associatedCustomerReceivablesCharge?: ChargeDto | null;
}

export class BusinessPartnerGroup extends BaseEntity {
	subType: ReferenceList;
	associatedCustomerReceivablesCharge: Charge;

	constructor(props?: DeepPartial<BusinessPartnerGroup | BusinessPartnerGroupDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.subType = new ReferenceList(props.subType || {});
		this.associatedCustomerReceivablesCharge = new Charge(props.associatedCustomerReceivablesCharge || {});
	}
}

export const businessPartnerGroupInsurerAndDonorFilter = () =>
	DBFilter<BusinessPartnerGroupDB>()
		.property('bh_subtype')
		.isIn([businessPartnerGroupSubType.Donation, businessPartnerGroupSubType.Insurance]);
