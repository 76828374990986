import { useApolloClient } from '@apollo/client';
import { DocumentStatusActionMapDocument } from '../graphql/__generated__/graphql';
import { DocumentBaseType, DocumentStatusActionMap } from '../models';
import DocAction from '../models/DocAction';
import DocumentStatus, { DocumentStatusValue } from '../models/DocumentStatus';
import useSuspenseAsync from './useSuspenseAsync';

const voidDocumentActions = [DocAction.VOID, DocAction.REVERSE_CORRECT, DocAction.REVERSE_ACCRUAL] as const;

/**
 * SUSPENDS - This provides document action information, such as whether a user can VOID and what the VOID action is, for a given entity
 * based on the process type this entity falls under. This component is looking for a property with the name `data` that has a `docStatus` property.
 * @param documentBaseType The process type to get document action information for
 * @param entitiesDocumentStatus An optional entity status to get data for (DRAFTED will be used by default)
 */
export default function useSuspenseGraphQLDocumentActionInformation(
	documentBaseType: DocumentBaseType,
	entitiesDocumentStatus?: DocumentStatusValue | string,
) {
	const graphqlClient = useApolloClient();
	const { data: documentStatusActionMap } = useSuspenseAsync(
		'documentStatusActionMap',
		async () =>
			(await graphqlClient.query({ query: DocumentStatusActionMapDocument, fetchPolicy: 'cache-first' })).data
				.DocumentStatusActionMap as unknown as DocumentStatusActionMap,
	);

	const documentStatus = (entitiesDocumentStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
	const voidAction = documentStatusActionMap?.[documentBaseType][documentStatus]?.filter((availableDocumentAction) =>
		(voidDocumentActions as unknown as string[]).includes(availableDocumentAction),
	)[0];

	return {
		canVoidDocument: !!voidAction,
		voidDocumentAction: voidAction,
	} as const;
}
