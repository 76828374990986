import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { FieldError, FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/UserContext';
import { OrderLine, Product } from '../../models';
import Nullable from '../../types/Nullable';
import { uiText } from '../../utils/Language';
import ProductLineItemTableFooter from './ProductLineItemTableFooter';
import ProductLineItemTableRow, { ADD_ORDER_LINE_ROW_NAME } from './ProductLineItemTableRow';
import { NonModelFormField, SubmitAction } from './VisitForm';

type ProductLineItemTableProps = {
	readOnly: boolean;
};

const ProductLineItemTable = ({ readOnly }: ProductLineItemTableProps) => {
	const { t } = useTranslation();
	const {
		register,
		setFocus,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<FieldValues>();
	const { fields, append, remove } = useFieldArray<FieldValues, 'orders.0.orderLines', 'uuid'>({
		name: 'orders.0.orderLines',
		keyName: 'uuid',
	});
	const addNewOrderLine = useWatch({ name: ADD_ORDER_LINE_ROW_NAME }) || {};
	const shouldDisplayTheDeleteColumn = !readOnly;
	const shouldFocusOnLastField = useRef(false);
	const { warehouse } = useContext(UserContext);
	const locator = useMemo(() => warehouse?.locators.find((locator) => locator.isDefault), [warehouse]);

	const { remove: removeProductStorageOnHand } = useFieldArray<
		FieldValues,
		`addNewOrderLine.product.storageOnHandList`,
		'uuid'
	>({
		name: `${ADD_ORDER_LINE_ROW_NAME}.product.storageOnHandList`,
		keyName: 'uuid',
	});

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewOrderLine?.product?.uuid && !addNewOrderLine?.product?.isNew) {
			append(
				new OrderLine({
					...addNewOrderLine,
					quantity: addNewOrderLine.quantity || 1,
					price:
						addNewOrderLine.product.sellPrice || addNewOrderLine.product.sellingPrice || addNewOrderLine.product.price,
				}),
			);
			// TODO: Remove this when RHF only stores a few things for an entity, not the entire thing
			// Since the storage on hand is being carried over from selection to selection, remove it
			removeProductStorageOnHand();
			// Reset the other fields
			const newOrderLine: Nullable<OrderLine> = new OrderLine({ product: new Product() });
			newOrderLine.price = null;
			newOrderLine.quantity = null;
			setValue(`${ADD_ORDER_LINE_ROW_NAME}`, newOrderLine);
			shouldFocusOnLastField.current = true;
		}
	}, [addNewOrderLine, fields.length, append, setValue, removeProductStorageOnHand]);
	// If there are fields and we should focus on the last one, do so
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField.current) {
			setFocus(`orders.0.orderLines.${fields.length - 1}.instructions`);
			shouldFocusOnLastField.current = false;
		}
	}, [fields.length, setFocus, shouldFocusOnLastField]);
	useEffect(() => {
		setValue('orderLineCount', fields.length);
	}, [fields.length, setValue]);

	return (
		<div className="table-responsive">
			<input
				type="hidden"
				{...register('orderLineCount', {
					valueAsNumber: true,
					validate: (value: number) =>
						getValues(NonModelFormField.SUBMIT_EVENT) === SubmitAction.COMPLETE ? value > 0 : true,
				})}
				defaultValue={fields.length}
			/>
			<table className="table bh-table--form">
				<thead>
					<tr>
						<th className="data-type-autocomplete">{t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE)}</th>
						<th className="data-type-text">{t(uiText.visit.form.product.table.INSTRUCTIONS)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.EXISTING_QUANTITY)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.QUANTITY)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.UNIT_SELL_PRICE)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.TOTAL)}</th>
						{shouldDisplayTheDeleteColumn ? <th>{t(uiText.visit.form.product.DELETE)}</th> : null}
					</tr>
				</thead>
				<tbody>
					{((fields || []) as unknown as OrderLine[]).map((orderLine, index) => (
						<ProductLineItemTableRow
							key={orderLine.uuid}
							field={orderLine}
							index={index}
							remove={remove}
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isDataReadOnly={readOnly}
							locator={locator}
						/>
					))}
				</tbody>
				<tbody>
					{!readOnly && (
						<ProductLineItemTableRow
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isAddRow={true}
							locator={locator}
						/>
					)}
				</tbody>
				<tbody>
					<ProductLineItemTableFooter shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn} />
				</tbody>
			</table>
			{(errors.orders?.[0]?.orderLines || []).some(
				(orderLineError: { quantity: FieldError }) => !!orderLineError.quantity,
			) && <div className="text-danger">{t(uiText.order.error.PRODUCT_MISSING_QUANTITY_GENERIC)}</div>}
			{(errors.orders?.[0]?.orderLines || []).some(
				(orderLineError: { sellingTooMuch: FieldError }) => !!orderLineError.sellingTooMuch,
			) && <div className="text-danger">{t(uiText.visit.prompt.PRODUCT_LINE_QUANTITY_EXCEEDS_INVENTORY)}</div>}
			{errors.orderLineCount && <div className="text-danger">{t(uiText.order.error.MISSING_PRODUCT)}</div>}
		</div>
	);
};

export default ProductLineItemTable;
