import Encounter, { EncounterDto } from '../models/Encounter';
import BaseEntityService from './BaseEntityService';

export default class EncounterService extends BaseEntityService<Encounter, EncounterDto> {
	public entityTableName: string = 'bh_encounter';
	protected entityName = 'encounters';

	map(data: EncounterDto): Encounter {
		return new Encounter(data);
	}

	reverseMap(data: Encounter): EncounterDto {
		return {
			...data,
			encounterDate: data.encounterDate?.getTime(),
		};
	}
}
