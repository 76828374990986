import BaseEntityService from './BaseEntityService';
import Organization, { OrganizationDB, OrganizationDto } from '../models/Organization';

export default class OrganizationService extends BaseEntityService<Organization, OrganizationDto, OrganizationDB> {
	public entityTableName: string = 'ad_org';
	protected entityName = 'organizations';

	map(data: OrganizationDto): Organization {
		return new Organization(data);
	}

	reverseMap(data: Organization): OrganizationDto {
		return data;
	}
}
