import BaseEntityService from './BaseEntityService';
import DBFilter, { Filter } from '../models/DBFilter';
import Concept, { ConceptDB, ConceptDto, ConceptClass } from '../models/Concept';
import { ConceptMappingMapType } from '../models/ConceptMapping';
import { ConceptExtraKey } from '../models/ConceptExtra';

export default class ConceptService extends BaseEntityService<Concept, ConceptDto, ConceptDB> {
	public entityTableName: string = 'bh_concept';
	entityName = 'concepts';

	map(data: ConceptDto) {
		return new Concept(data);
	}

	reverseMap(data: Concept): ConceptDto {
		return {
			...data,
		};
	}

	getAllTestsFilter(): Filter<ConceptDB> {
		return DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.TEST);
	}

	getDefaultSearchFilter(query: string): Filter<ConceptDB> {
		return DBFilter<ConceptDB>().property('bh_display_name').contains(query);
	}

	getTestSearchFilter(query: string): Filter<ConceptDB> {
		return (
			DBFilter<ConceptDB>()
				.property('bh_concept_class')
				.equals(ConceptClass.TEST)
				// search in display name (which can be scientific name)
				.or(DBFilter<ConceptDB>().property('bh_display_name').contains(query))
				// search in test local name (by looking for extra attribute attached to mapping to parent)
				.or(
					DBFilter<ConceptDB>()
						.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
						.property('bh_map_type')
						.equals(ConceptMappingMapType.CONCEPT_SET)
						.nested('bh_concept_extra')
						.property('bh_key')
						.equals(ConceptExtraKey.LOCAL_NAME)
						.property('bh_value')
						.contains(query)
						.up()
						.up(),
				)
				// search in panel display name
				.or(
					DBFilter<ConceptDB>()
						.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
						.property('bh_map_type')
						.equals(ConceptMappingMapType.CONCEPT_SET)
						.nested('bh_concept')
						.property('bh_concept_class')
						.equals(ConceptClass.LAB_SET)
						.property('bh_display_name')
						.contains(query)
						.up()
						.up(),
				)
				// search in panel local name
				.or(
					DBFilter<ConceptDB>()
						.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
						.property('bh_map_type')
						.equals(ConceptMappingMapType.CONCEPT_SET)
						.nested('bh_concept')
						.property('bh_concept_class')
						.equals(ConceptClass.LAB_SET)
						.nested('bh_concept_extra')
						.property('bh_key')
						.equals(ConceptExtraKey.LOCAL_NAME)
						.property('bh_value')
						.contains(query)
						.up()
						.up()
						.up(),
				)
		);
	}

	getTestsAndPanelsSearchFilter(query?: string): Filter<ConceptDB> {
		let filter = DBFilter<ConceptDB>()
			.property('isactive')
			.equals(true)
			// Concept class of Test or Panel
			.and(
				DBFilter<ConceptDB>()
					.or(DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.TEST))
					.or(DBFilter<ConceptDB>().property('bh_concept_class').equals(ConceptClass.LAB_SET)),
			);

		if (query) {
			filter = filter
				// Use the given search query to look through a variety of places
				.and(
					DBFilter<ConceptDB>()
						.or(DBFilter<ConceptDB>().property('bh_display_name').contains(query))
						// Check for a local_name extra attached to the concept
						.or(
							DBFilter<ConceptDB>()
								.nested('bh_concept_extra')
								.property('bh_key')
								.equals(ConceptExtraKey.LOCAL_NAME)
								.property('bh_value')
								.contains(query)
								.up(),
						)
						// Check for a local_name extra attached to the parent mapping (which is normal for tests controlled by CIEL)
						.or(
							DBFilter<ConceptDB>()
								.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
								.property('bh_map_type')
								.equals(ConceptMappingMapType.CONCEPT_SET)
								.nested('bh_concept_extra')
								.property('bh_key')
								.equals(ConceptExtraKey.LOCAL_NAME)
								.property('bh_value')
								.contains(query)
								.up()
								.up(),
						),
				);
		}

		return filter;
	}

	getTestsWithinPanelFilter(panelUuid: string): Filter<ConceptDB> {
		return DBFilter<ConceptDB>()
			.property('isactive')
			.equals(true)
			.property('bh_concept_class')
			.equals(ConceptClass.TEST)
			.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
			.property('bh_map_type')
			.equals(ConceptMappingMapType.CONCEPT_SET)
			.nested('bh_concept')
			.property('bh_concept_class')
			.equals(ConceptClass.LAB_SET)
			.property('bh_concept_uu')
			.equals(panelUuid)
			.up()
			.up();
	}

	getTestQAndAFilter(testUuid: string): Filter<ConceptDB> {
		return DBFilter<ConceptDB>()
			.property('isactive')
			.equals(true)
			.property('bh_concept_class')
			.isIn([ConceptClass.FINDING, ConceptClass.MISC])
			.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
			.property('bh_map_type')
			.equals(ConceptMappingMapType.Q_AND_A)
			.nested('bh_concept')
			.property('bh_concept_uu')
			.equals(testUuid)
			.up()
			.up();
	}
}
