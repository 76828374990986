/**
 * React routes don't allow for page reloads i.e if you are on a page and click on the same page url, it won't reload.
 *
 * This component provides a workaround to the problem. Prefix 'refresh' with the url that needs to be reloaded.
 *
 * See (for issue and solution) - https://github.com/ReactTraining/react-router/issues/4056
 *
 * @param path
 * @returns {*}
 * @constructor
 */

import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

const Refresh = ({ path = '/' }) => (
	<Route
		path={path}
		component={({ history, location, match }: RouteComponentProps) => {
			history.replace({
				...location,
				pathname: location.pathname.substring(match.path.length),
			});
			return null;
		}}
	/>
);

export default Refresh;
