import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';

export interface BaseEntityDB extends BaseMetadataDB {
	name: string;
	description: string;
}

export interface BaseEntityDto extends BaseMetadataDto {
	description?: string | null;
	name?: string | null;
	value?: string | null;
}

export default abstract class BaseEntity extends BaseMetadata {
	description: string;
	name: string;
	value: string;

	constructor(props?: Partial<BaseEntity | BaseEntityDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.description = props.description || '';
		this.name = props.name || '';
		this.value = props.value || '';
	}
}
