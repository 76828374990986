import { Sort, Filter, Response } from '../models';
import ChargeType, { ChargeTypeDB, ChargeTypeDto } from '../models/ChargeType';
import { CHARGE_TYPES_URI } from '../utils/Constants';
import BaseEntityService from './BaseEntityService';

export default class ChargeTypeService extends BaseEntityService<ChargeType, ChargeTypeDto, ChargeTypeDB> {
	public entityTableName: string = 'm_ChargeType';
	protected entityName = CHARGE_TYPES_URI;

	async get(
		page?: number | undefined,
		size?: number | undefined,
		sorted?: Sort<ChargeTypeDB>[] | undefined,
		filter?: Filter<ChargeTypeDB> | undefined,
	): Promise<Response<ChargeType>> {
		let filterString;
		// If there was a filter, we can cache this
		if (filter) {
			// Based on this filter, see if we've fetched this value before; if so, return it
			filterString = 'chargeType_' + JSON.stringify(filter);
			if (localStorage.getItem(filterString)) {
				return new Response<ChargeType>({ results: [new ChargeType(JSON.parse(localStorage.getItem(filterString)!))] });
			}
		}
		// We haven't gotten it before, so fetch it
		const response = await super.get(page, size, sorted, filter);
		// If something was returned, we'll cache it
		if (response.results.length === 1 && filterString) {
			localStorage.setItem(filterString, JSON.stringify(response.results[0]));
		}
		return response;
	}

	map(data: ChargeTypeDto): ChargeType {
		return new ChargeType(data);
	}

	reverseMap(data: ChargeType): ChargeTypeDto {
		throw new Error('Method not implemented.');
	}
}
