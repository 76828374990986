import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import useService from '../../hooks/useService';
import {
	BusinessPartner,
	BusinessPartnerDB,
	businessPartnerGroupInsurerAndDonorFilter,
	businessPartnerGroupSubType,
	Charge,
	ChargeDB,
	chargeSubType,
} from '../../models';
import DBFilter, { Filter } from '../../models/DBFilter';
import Paging from '../../models/Paging';
import PaymentInformation from '../../models/PaymentInformation';
import ReferenceList, { sortPaymentTypes } from '../../models/ReferenceList';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import PaymentInformationRow from './PaymentInformationRow';
import PaymentLineItemTableFooter from './PaymentLineItemTableFooter';

type PaymentLineItemTableProps = {
	readOnly: boolean;
	isVisitFinished: boolean;
};

export const ADD_PAYMENT_ROW_NAME = 'addPaymentInformation';

const PaymentLineItemTable = ({ readOnly, isVisitFinished }: PaymentLineItemTableProps) => {
	const { t } = useTranslation();
	const { businessPartnerService, chargeService, referenceListService } = useService();
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();
	const addPaymentInformationUuid: string = useWatch({ name: `${ADD_PAYMENT_ROW_NAME}.uuid` });
	const { fields, append, remove } = useFieldArray<FieldValues, 'paymentInformationList', 'uuid'>({
		name: 'paymentInformationList',
		keyName: 'uuid',
	});
	const patient: BusinessPartner = useWatch({ name: 'patient' }) || {};
	const [chargeUpdateCount, setChargeUpdateCount] = useState(1);
	const shouldDisplayTheDeleteColumn = !readOnly;

	const { value: paymentTypes, loading: areLoadingPaymentTypes } = useAsync(
		async () => sortPaymentTypes(await referenceListService.getTenderTypes()),
		[referenceListService],
	);
	const { value: allSubTypes } = useAsync(
		async () => referenceListService.getNonPatientPaymentSubTypes(),
		[referenceListService],
	);
	const { value: insuranceAndDonorList } = useAsync(
		async () =>
			sortBy(
				(
					await businessPartnerService.get(
						Paging.ALL.page,
						Paging.ALL.size,
						undefined,
						DBFilter<BusinessPartnerDB>()
							.nested('c_bp_group')
							.and(businessPartnerGroupInsurerAndDonorFilter())
							.up()
							.and(IS_ACTIVE as unknown as Filter<BusinessPartnerDB>),
					)
				).results,
				'name',
			),
		[businessPartnerService],
	);
	const { value: waiver } = useAsync(
		async () =>
			sortBy(
				(
					await chargeService.get(
						Paging.ALL.page,
						Paging.ALL.size,
						undefined,
						DBFilter<ChargeDB>()
							.and(IS_ACTIVE as unknown as Filter<ChargeDB>)
							.property('bh_subtype')
							.equals(chargeSubType.Waiver),
					)
				).results,
				'created',
			)[0],
		[chargeService],
	);
	// We need a way to trigger charge refreshes, so the chargeUpdateCount is here to help that (the condition should
	// always evaluate to true)
	const { value: patientCharges } = useAsync(async () => {
		if (chargeUpdateCount > 0 && !isVisitFinished && patient.uuid) {
			return businessPartnerService.getPayerInformationList(patient.uuid);
		}
		return undefined;
	}, [patient.uuid, businessPartnerService, chargeUpdateCount, isVisitFinished]);

	const subTypes = useMemo(
		() =>
			allSubTypes?.filter(
				(subType) =>
					subType.value === businessPartnerGroupSubType.Waiver ||
					insuranceAndDonorList?.some(
						(businessPartner) => businessPartner.businessPartnerGroup?.subType.uuid === subType.uuid,
					),
			),

		[allSubTypes, insuranceAndDonorList],
	);

	// This handles adding a new payment if the user selected a type
	useEffect(() => {
		if (addPaymentInformationUuid && waiver) {
			// Add a new row
			let paymentTypeOrSubType = paymentTypes?.find((paymentType) => paymentType.uuid === addPaymentInformationUuid);
			let isPayment = true;
			let isWaiver = false;
			let payer = new BusinessPartner({ uuid: '' });
			let charge = new Charge({ uuid: '' });
			// If we didn't find it in the sub types, check the payment types
			if (!paymentTypeOrSubType) {
				isPayment = false;
				paymentTypeOrSubType = subTypes?.find((subType) => subType.uuid === addPaymentInformationUuid);
				// If we still don't have a match, it must be a waiver
				if (paymentTypeOrSubType?.value === businessPartnerGroupSubType.Waiver) {
					charge = waiver;
					isWaiver = true;
				} else {
					// We have an insurance/donor sub type! If there's only one payer, auto-select it
					const payersForSubType = insuranceAndDonorList?.filter(
						(businessPartner) => businessPartner.businessPartnerGroup?.subType.uuid === paymentTypeOrSubType?.uuid,
					);
					if (payersForSubType?.length === 1) {
						payer = payersForSubType[0];
						charge = payer.businessPartnerGroup!.associatedCustomerReceivablesCharge;
					}
				}
			}
			append(
				new PaymentInformation({
					amount: getValues(`${ADD_PAYMENT_ROW_NAME}.amount`),
					description: getValues(`${ADD_PAYMENT_ROW_NAME}.description`),
					paymentType: new ReferenceList({ ...paymentTypeOrSubType, value: undefined }),
					payer,
					charge,
					isPayment: isPayment ? 'true' : 'false',
					isWaiver: isWaiver ? 'true' : 'false',
				}),
			);
			setValue(`${ADD_PAYMENT_ROW_NAME}.uuid`, '');
			setValue(`${ADD_PAYMENT_ROW_NAME}.amount`, '');
			setValue(`${ADD_PAYMENT_ROW_NAME}.description`, '');
		}
	}, [addPaymentInformationUuid, append, setValue, subTypes, paymentTypes, getValues, insuranceAndDonorList, waiver]);

	return (
		<fieldset disabled={readOnly}>
			<table className="bh-table--form">
				<thead>
					<tr>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.TYPE)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.payment.table.AMOUNT_PAID)}</th>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.DESCRIPTION)}</th>
						{shouldDisplayTheDeleteColumn ? (
							<th className="data-type-action print__d-none">{t(uiText.visit.button.DELETE)}</th>
						) : null}
					</tr>
				</thead>
				<tbody>
					{(fields as unknown as PaymentInformation[]).map((field, index) => (
						<PaymentInformationRow
							key={field.uuid}
							field={field}
							index={index}
							remove={remove}
							subTypes={subTypes}
							paymentTypes={paymentTypes}
							insuranceAndDonorList={insuranceAndDonorList}
							waiver={waiver}
							patientPayerInformationList={patientCharges}
							onChargesUpdated={() => setChargeUpdateCount(chargeUpdateCount + 1)}
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isVisitFinished={isVisitFinished}
						/>
					))}
				</tbody>
				{readOnly && !fields.length && (
					<tbody>
						<tr>
							<td>
								<Form.Select aria-label={t(uiText.visit.form.payment.table.TYPE)} defaultValue={''}>
									<option value=""></option>
								</Form.Select>
							</td>
							<td>
								<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} />
							</td>

							<td>
								<Form.Control defaultValue={''} />
							</td>
						</tr>
					</tbody>
				)}
				{!readOnly && (
					<tbody>
						<tr>
							<td>
								<DynamicSelect
									aria-label={t(uiText.visit.form.payment.table.TYPE)}
									{...register(`${ADD_PAYMENT_ROW_NAME}.uuid`)}
									defaultValue={''}
									isLoading={areLoadingPaymentTypes || !subTypes?.length}
								>
									<option value=""></option>
									{paymentTypes?.map((paymentType) => (
										<option key={paymentType.uuid} value={paymentType.uuid}>
											{paymentType.name}
										</option>
									))}
									{subTypes?.map((subType) => (
										<option key={subType.uuid} value={subType.uuid}>
											{subType.name}
										</option>
									))}
								</DynamicSelect>
							</td>
							<td>
								<Controller
									name={`${ADD_PAYMENT_ROW_NAME}.amount`}
									render={({ field }) => (
										<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
									)}
								/>
							</td>

							<td>
								<Form.Control {...register(`${ADD_PAYMENT_ROW_NAME}.description`)} defaultValue={''} />
							</td>
						</tr>
					</tbody>
				)}
				<tfoot>
					<PaymentLineItemTableFooter shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn} />
				</tfoot>
			</table>
			{(errors.paymentInformationList as PaymentInformation[] | undefined)?.some(
				(paymentInformation) => !!paymentInformation.amount,
			) && <div className="text-danger">{t(uiText.payment.error.MISSING_AMOUNT)}</div>}
			{(errors.paymentInformationList as PaymentInformation[] | undefined)?.some(
				(paymentInformation) => !!paymentInformation.payer?.uuid,
			) && <div className="text-danger">{t(uiText.payment.error.MISSING_INSURER_OR_DONOR)}</div>}
		</fieldset>
	);
};

export default PaymentLineItemTable;
