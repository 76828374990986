import { useApolloClient, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import UserContext from '../../contexts/UserContext';
import {
	Ad_UserForManagingDocument,
	Ad_UserForManagingQuery,
	Ad_UserSaveOnListOrFormDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import { OrganizationUuid, roleUuid } from '../../models';
import DBFilter from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import User, { UserDB } from '../../models/User';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { formatDate, formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ResetPasswordModal from '../Modal/ResetPasswordModal';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import ManageUserForm from './ManageUserForm';

let counter = 0;

type UserLocationState = { fromSave: boolean; data: User };
const ManageUsers = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { state } = useLocation<UserLocationState | undefined>();
	const { canWrite } = useActionPrivileges(pageUuid.USERS);
	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<Ad_UserForManagingQuery['AD_UserGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(await graphqlClient.query({ query: Ad_UserForManagingDocument, variables, fetchPolicy: 'network-only' }))
						.data.AD_UserGet,
				[graphqlClient],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.manageUsers.error.COULD_NOT_LOAD));
					}
					exception({ description: `User fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			viewState: state?.fromSave ? ListPageState.ADD_EDIT : undefined,
		},
	);

	const [resetPasswordModal, setResetPasswordModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<Ad_UserForManagingQuery['AD_UserGet']['Results'][0]>();
	const { client } = useContext(UserContext);

	// Handle searching and filtering
	useEffect(() => {
		let filter = DBFilter<UserDB>()
			.nested('ad_user_roles.ad_role')
			.property('ad_role_uu')
			.isNotNull()
			.up()
			.nested('ad_org')
			.property('ad_org_uu')
			.doesNotEqual(OrganizationUuid.All)
			.up()
			.nested('ad_client')
			.property('ad_client_uu')
			.equals(client.uuid)
			.up();
		if (searchText) {
			filter.and(DBFilter<UserDB>().property('name').contains(searchText));
		}
		onFilterUpdate(filter.toString());
	}, [searchText, onFilterUpdate, client]);

	const toggleUserStatus = (user: Ad_UserForManagingQuery['AD_UserGet']['Results'][0]) => {
		saveUser({ variables: { AD_User: { UU: user.UU, IsActive: !user.IsActive } } });
	};

	const onResetPasswordClick = (
		e: React.MouseEvent<any>,
		user: Ad_UserForManagingQuery['AD_UserGet']['Results'][0],
	) => {
		e.stopPropagation();
		setSelectedUser(user);
		setResetPasswordModal(true);
	};

	const [saveUser, { loading: areSavingUsers, error }] = useMutation(Ad_UserSaveOnListOrFormDocument);

	useUpdateEffect(() => {
		if (counter > 10) {
			return;
		}
		counter++;
		if (error) {
			exception({ description: `User update error: ${error}` });
			toast.error(t(uiText.manageUsers.toast.UPDATE_FAIL));
		} else if (!areSavingUsers) {
			refresh();
			toast.success(t(uiText.manageUsers.toast.UPDATE_SUCCESS));
		}
	}, [error, areSavingUsers, refresh, t]);

	useRefreshOnRepeatedRoute(() => {
		reset();
	});

	return (
		<>
			{resetPasswordModal && selectedUser && (
				<ResetPasswordModal
					username={selectedUser.Name}
					onHandleClose={() => {
						setResetPasswordModal(false);
					}}
					onHandleSubmit={({ resetPassword }) => {
						saveUser({ variables: { AD_User: { UU: selectedUser.UU, Password: resetPassword, IsExpired: true } } });
						setResetPasswordModal(false);
					}}
				/>
			)}

			<Layout>
				{viewState === ListPageState.LIST ? (
					<>
						<Layout.Header>
							<Layout.Title
								title={t(uiText.manageUsers.title.LIST)}
								showRefreshIcon
								onRefresh={() => {
									refresh({ resetPage: true });
								}}
								areRefreshing={areRefreshing}
							/>
							<Layout.Menu />
						</Layout.Header>
						<Layout.Body>
							<WorkspaceMenu>
								<WorkspaceMenu.Search onSearch={setSearchText} />
								{canWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
							</WorkspaceMenu>
							<Row className="bg-white ms-0">
								<BHGraphQLTable<Ad_UserForManagingQuery['AD_UserGet']['Results'][0]>
									data={data}
									columns={[
										{
											id: 'created',
											Header: () => (
												<div className={'React-table-header'}>{t(uiText.manageUsers.tableHeaders.DATE_CREATED)}</div>
											),
											accessor: (user) => formatDate(new Date(user.Created)),
										},
										{
											Header: () => (
												<div className={'React-table-header'}>{t(uiText.manageUsers.tableHeaders.USERNAME)}</div>
											),
											accessor: 'Name',
										},
										{
											id: 'role',
											Header: () => (
												<div className={'React-table-header'}>{t(uiText.manageUsers.tableHeaders.ROLE)}</div>
											),
											accessor: (user) =>
												[
													...new Set(
														user.AD_User_Roles?.flatMap((userRole) =>
															userRole.AD_Role.AD_Role_IncludedList?.filter(
																(includedRole) =>
																	includedRole.Included_Role.Name &&
																	includedRole.Included_Role.UU !== roleUuid.MUST_HAVES,
															).map((includedRole) => includedRole.Included_Role.Name),
														),
													),
												]
													.sort()
													.join(', '),
											disableSortBy: true,
										},
										{
											id: 'dateLastLogin',
											Header: () => (
												<div className={'React-table-header'}>{t(uiText.manageUsers.tableHeaders.LAST_LOGIN)}</div>
											),
											accessor: (user) => (user.DateLastLogin ? formatDateAndTime(new Date(user.DateLastLogin)) : ''),
										},
										{
											id: 'isactive',
											Header: () => (
												<div className={'React-table-header'}>{t(uiText.manageUsers.tableHeaders.STATUS)}</div>
											),
											accessor: (user) => (
												<div>
													{user.IsActive ? t(uiText.manageUsers.button.ACTIVE) : t(uiText.manageUsers.button.INACTIVE)}
												</div>
											),
										},
										{
											id: 'action',
											Header: () => (
												<div className={'React-table-header'}>{t(uiText.manageUsers.tableHeaders.ACTION)}</div>
											),

											Cell: (row: CellProps<Ad_UserForManagingQuery['AD_UserGet']['Results'][0]>) => (
												<BHDropdownButton
													title={t(uiText.manageUsers.tableHeaders.ACTION)}
													icon="ellipsis-h"
													variant="bh-light"
													className="py-0"
												>
													<Dropdown.Item onClick={() => toggleUserStatus(row.cell.row.original)}>
														<FontAwesomeIcon
															icon={row.cell.row.original.IsActive ? 'toggle-on' : 'toggle-off'}
															className="me-2 fa-fw"
														/>
														{row.cell.row.original.IsActive
															? t(uiText.manageUsers.button.DEACTIVATE)
															: t(uiText.manageUsers.button.ACTIVATE)}
													</Dropdown.Item>
													<Dropdown.Item onClick={(e) => onResetPasswordClick(e, row.cell.row.original)}>
														<FontAwesomeIcon icon="eye" className="me-2 fa-fw" />
														{t(uiText.manageUsers.RESET_PASSWORD)}
													</Dropdown.Item>
												</BHDropdownButton>
											),
											disableSortBy: true,
										},
									]}
									defaultPageSize={pageSize}
									pages={pages}
									page={page}
									pageSizeOptions={pageSizeOptions}
									LoadingComponent={() => {
										return <LoadSpinner show={isLoading} title={t(uiText.manageUsers.LOADING)} />;
									}}
									onFetchData={onTableUpdate}
									sortBy={sorted}
									totalRecordCount={totalRecordCount}
									viewState={viewState}
									getTrGroupProps={rowProperties}
								/>
							</Row>
						</Layout.Body>
					</>
				) : (
					<ManageUserForm
						uuid={selectedUuid}
						onFinish={(refreshData?: boolean) => {
							setViewState(ListPageState.LIST);
							if (refreshData) {
								refresh();
							}
						}}
					/>
				)}
			</Layout>
		</>
	);
};

export default ManageUsers;
