import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Locator, { LocatorDto } from './Locator';
import Product, { ProductDB, ProductDto } from './Product';

export interface MovementLineDB extends BaseEntityDB {
	m_product: ProductDB;
}

export interface MovementLineDto extends BaseEntityDto {
	movementQuantity?: number;
	product?: ProductDto;
	movementUuid?: string;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	locator?: LocatorDto | null;
	locatorTo?: LocatorDto | null;
}

export default class MovementLine extends BaseEntity {
	movementUuid: string;
	movementQuantity: number;
	product: Product;
	attributeSetInstance: AttributeSetInstance;
	locator: Locator;
	locatorTo: Locator;

	constructor(props?: DeepPartial<MovementLine | MovementLineDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.movementUuid = props.movementUuid || '';
		this.movementQuantity = props.movementQuantity || 0;
		this.product = new Product(props.product);
		this.attributeSetInstance = new AttributeSetInstance(props.attributeSetInstance || undefined);
		this.locator = new Locator('locator' in props && props.locator ? props.locator : {});
		this.locatorTo = new Locator('locatorTo' in props && props.locatorTo ? props.locatorTo : {});
	}
}
