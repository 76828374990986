import { endOfDay, startOfDay } from 'date-fns';

const dateTimeFormat = new Intl.DateTimeFormat('en', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: 'numeric',
	minute: 'numeric',
	hour12: true,
});

export function formatDate(date?: Date): string {
	const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date || new Date());
	return `${year}-${month}-${day}`;
}

export function formatDateAndTime(date?: Date): string {
	const [
		{ value: month },
		,
		{ value: day },
		,
		{ value: year },
		,
		{ value: hour },
		,
		{ value: minute },
		,
		{ value: dayPeriod },
	] = dateTimeFormat.formatToParts(date || new Date());
	return `${year}-${month}-${day} ${hour}:${minute} ${dayPeriod}`;
}

export function getTodayWithoutTime() {
	return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
}

export function middleOfDay(date: Date | number) {
	return new Date((endOfDay(date).getTime() - startOfDay(date).getTime()) / 2 + startOfDay(date).getTime());
}

export function middleOfToday() {
	return middleOfDay(new Date());
}

// Get the numbers of days into the year this is
export function getDaysIntoYear(date: Date) {
	return (
		(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
		24 /
		60 /
		60 /
		1000
	);
}
