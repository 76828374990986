import CodedDiagnosis, { CodedDiagnosisDB, CodedDiagnosisDto } from '../models/CodedDiagnosis';
import DBFilter, { Filter } from '../models/DBFilter';
import BaseEntityService from './BaseEntityService';

export default class CodedDiagnosisService extends BaseEntityService<
	CodedDiagnosis,
	CodedDiagnosisDto,
	CodedDiagnosisDB
> {
	public entityTableName: string = 'bh_coded_diagnosis';
	entityName = 'coded-diagnoses';

	map(data: CodedDiagnosisDto) {
		return new CodedDiagnosis(data);
	}

	reverseMap(data: CodedDiagnosis): CodedDiagnosisDto {
		throw new Error('Method not implemented.');
	}

	getDefaultSearchFilter(query: string): Filter<CodedDiagnosisDB> {
		return (
			DBFilter<CodedDiagnosisDB>()
				.property('isactive')
				.equals(true)
				.or(
					DBFilter<CodedDiagnosisDB>()
						.nested('bh_coded_diagnosis_mapping')
						.or(DBFilter<CodedDiagnosisDB['bh_coded_diagnosis_mapping']>().property('bh_concept_code').contains(query))
						.or(
							DBFilter<CodedDiagnosisDB['bh_coded_diagnosis_mapping']>()
								.property('bh_concept_name_resolved')
								.contains(query),
						)
						.up(),
				)
				.or(DBFilter<CodedDiagnosisDB>().property('bh_cielname').contains(query))
				.or(DBFilter<CodedDiagnosisDB>().property('bh_icd10who').contains(query))
				.or(DBFilter<CodedDiagnosisDB>().property('bh_synonyms').contains(query))
				// .or(DBFilter<CodedDiagnosisDB>().property('bh_ciel_id').contains(parseInt(query, 10)))
				.or(DBFilter<CodedDiagnosisDB>().property('bh_searchterms').contains(query))
		);
	}
}
