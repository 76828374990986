import axios from 'axios';
import { isArray } from 'lodash';
import DBFilter, { Filter } from '../models/DBFilter';
import { ALL_ORGANISATIONS } from '../models/Reference';
import Response from '../models/Response';
import Sort from '../models/Sort';
import User, { UserDB, UserDto } from '../models/User';
import { configs } from '../utils/Configs';
import { createPagingParameters } from '../utils/Pagination';
import BaseEntityService from './BaseEntityService';

export default class UserService extends BaseEntityService<User, UserDto, UserDB> {
	public entityTableName: string = 'ad_user';
	protected entityName = 'users';
	cliniciansUrl = `${configs.apiUrl}/users/clinicians`;
	nonAdminUrl = `${configs.apiUrl}/users/non-admins`;

	/**
	 * Get Clinicians
	 * @param page
	 * @param size
	 * @param sorted
	 * @returns {Promise<Response| any>}
	 */
	async getClinicians(page?: number, size?: number, sorted?: Sort[]): Promise<Response<User>> {
		const url = `${this.cliniciansUrl}${createPagingParameters(page, size, sorted)}`;
		return axios.get<Response<User>>(url).then(({ data }) => {
			let users: User[] = [];
			if (data && isArray(data.results)) {
				users = data.results.map(this.map);
			}
			return new Response({ ...data, results: users });
		});
	}

	/**
	 * Get Clinicians
	 * @param page
	 * @param size
	 * @param sorted
	 * @param {DBFilter} filter
	 * @returns {Promise<Response>}
	 */
	async getNonAdminUsers(
		page?: number,
		size?: number,
		sorted?: Sort<UserDB>[],
		filter?: Filter<UserDB>,
	): Promise<Response<User>> {
		const url = `${this.nonAdminUrl}${createPagingParameters(page, size, sorted, filter)}`;
		return axios.get<Response<User>>(url).then(({ data }) => {
			let users: User[] = [];
			if (data && isArray(data.results)) {
				users = data.results.map(this.map);
			}
			return new Response({ ...data, results: users });
		});
	}

	map(data: UserDto) {
		return new User(data);
	}

	reverseMap(data: User): UserDto {
		return { ...data };
	}

	async search(query: string, page?: number, size?: number, sorted?: Sort<UserDB>[]): Promise<Response<User>> {
		return this.get(
			page,
			size,
			sorted,
			DBFilter<UserDB>()
				.or(DBFilter<UserDB>().property('name').contains(query))
				.and(DBFilter<UserDB>().nested('ad_user_roles.ad_role').property('ad_role_uu').isNotNull().up())
				.and(DBFilter<UserDB>().nested('ad_org').property('ad_org_uu').doesNotEqual(ALL_ORGANISATIONS).up()),
		);
	}
}
