import { useCallback, useEffect, useRef, useState } from 'react';
import { TableRowProps, UseRowSelectState, UseTableRowProps } from 'react-table';
import { BHTableProps } from '../components/BHTable/BHTable';
import BaseMetadata, { BaseMetadataDB } from '../models/BaseMetadata';
import { Filter } from '../models/DBFilter';
import ListPageState from '../models/ListPageState';
import Response from '../models/Response';
import Sort from '../models/Sort';
import useTriggerUpdate from './useTriggerUpdate';

const emptyFunction = () => {};

/**
 * A hook to contain all functionality common to list views, such as fetching data for a table, handling table row clicks, and answering sort/filter requests
 *
 * @template T The data type that the list page handles
 * @template P The type, if any, containing the properties and types of DB columns
 * @param getMethods Any object with a fetch property method that will fetch data for the list view, and an optional onError property for a callback if the method fails
 * @param getSingleMethods Any object with a fetch property method that will fetch a single piece of data,
 * and an optional onError property for a callback if the method fails
 * @returns Many properties for the list view. The `data` property holds the list of data to display on the list view. The `isLoading` property shows whether the view is
 * loading or not. `onFilterUpdate` is a function to call when the filter has been updated, if any. `refresh` is a function that will
 * refresh the current results. `selectedData` is a single entity that has been selected, if any. `tableProps` is an object of available properties
 * to pass to a paginated table component. `viewState` is a string containing the current state, pulled from ListPageState.js.
 * @deprecated use useGraphQLListPageFunctionality
 */
export default function useListPageFunctionality<T extends BaseMetadata, P extends BaseMetadataDB = BaseMetadataDB>(
	{
		fetch: getMethod,
		onError: getMethodErrorCallback = emptyFunction,
		refreshSuccessCallback = emptyFunction,
	}: {
		fetch: (page: number, size: number, sorted: Sort<P>[], filter: Filter<P>) => Promise<Response<T>>;
		onError?: (error?: any) => void;
		refreshSuccessCallback?: () => void;
	},
	initialState?: Partial<
		Pick<BHTableProps<T, P>, 'pages' | 'page' | 'pageSize' | 'pageSizeOptions' | 'totalRecordCount'>
	> & {
		sorted?: Sort<P>[];
		viewState?: ListPageState;
		selectedUuid?: string;
		fetchDataInitially?: boolean;
	},
) {
	const initialSorted = useRef(JSON.stringify(initialState?.sorted || []));
	const canFetchData = useRef(initialState?.fetchDataInitially === undefined ? false : initialState.fetchDataInitially);

	const { willTrigger, triggerUpdate } = useTriggerUpdate();
	const [listData, setListData] = useState<T[]>([]);
	const [filter, setFilter] = useState({} as Filter<P>);
	const [isLoading, setIsLoading] = useState(false);
	const [pages, setPages] = useState(initialState?.pages || 0);
	const [page, setPage] = useState(initialState?.page || 0);
	const [pageSize, setPageSize] = useState(initialState?.pageSize || 15);
	const [pageSizeOptions] = useState(initialState?.pageSizeOptions || [5, 10, 15]);
	const [selectedUuid, setSelectedUuid] = useState<string | undefined>(initialState?.selectedUuid);
	const [sorted, setSorted] = useState(initialSorted.current);
	const [totalRecordCount, setTotalRecordCount] = useState(initialState?.totalRecordCount || 0);
	const [viewState, setViewState] = useState(initialState?.viewState || ListPageState.LIST);
	const lastFetchState = useRef<
		| {
				page: number;
				sorted: string;
				filter: string;
		  }
		| undefined
	>();
	const areRefreshing = useRef(false);
	const wasPageReturnedDifferentThanWhatPageWasRequested = useRef(false);

	// Have a non-tracked version of view state (for effects that don't need to re-run if it changes)
	const lastViewState = useRef(viewState);
	useEffect(() => {
		lastViewState.current = viewState;
	}, [viewState]);

	// Ensure updates and things like that don't happen after the component is unmounted
	const isMounted = useRef(true);
	useEffect(
		() => () => {
			isMounted.current = false;
		},
		[],
	);

	// If any of the page fetch properties update, go fetch new data
	useEffect(() => {
		const fetchData = async () => {
			// If the component has been unmounted or isn't on the list view, cancel what's going on
			if (
				!isMounted.current ||
				lastViewState.current !== ListPageState.LIST ||
				wasPageReturnedDifferentThanWhatPageWasRequested.current
			) {
				wasPageReturnedDifferentThanWhatPageWasRequested.current = false;
				return;
			}
			setIsLoading(true);
			const stringFilter = JSON.stringify(filter || {});
			lastFetchState.current = { page, sorted, filter: stringFilter };
			try {
				const response = await getMethod(page, pageSize, JSON.parse(sorted), filter);
				// If the component has been unmounted or the filter is now different, do nothing with these results
				if (
					!isMounted.current ||
					stringFilter !== lastFetchState.current.filter ||
					sorted !== lastFetchState.current.sorted ||
					page !== lastFetchState.current.page
				) {
					return;
				}
				wasPageReturnedDifferentThanWhatPageWasRequested.current = page !== response.pagingInfo.page;
				setPage(response.pagingInfo.page);
				setListData(response.results);
				setPages(response.pagingInfo.totalPages);
				setTotalRecordCount(response.pagingInfo.totalRecordCount);
				setSorted(lastFetchState.current.sorted);

				if (areRefreshing.current) {
					refreshSuccessCallback();
				}
			} catch (error) {
				getMethodErrorCallback(error);
			}
			setIsLoading(false);
			areRefreshing.current = false;
		};
		if (canFetchData.current) {
			fetchData();
		}
		canFetchData.current = true;
	}, [
		sorted,
		page,
		pageSize,
		filter,
		willTrigger,
		getMethod,
		isMounted,
		getMethodErrorCallback,
		refreshSuccessCallback,
	]);

	// If we get updated to be on the list, de-select any previously selected data
	useEffect(() => {
		if (viewState === ListPageState.LIST) {
			setSelectedUuid(undefined);
		}
	}, [viewState]);

	const onTableUpdate = useCallback(
		({
			sorted: updatedSorted,
			page: updatedPage,
			pageSize: updatedPageSize,
		}: {
			sorted?: Sort<P>[];
			page: number;
			pageSize: number;
		}) => {
			setSorted(JSON.stringify(updatedSorted || []));
			setPage(updatedPage);
			setPageSize(updatedPageSize);
		},
		[],
	);

	const tableRowProperties = (
		_state?: UseRowSelectState<T>,
		rowInfo?: UseTableRowProps<T>,
	): Partial<TableRowProps> & React.HTMLProps<HTMLTableRowElement> => {
		if (!rowInfo) {
			return {};
		}
		return {
			onClick: () => {
				setSelectedUuid(rowInfo.original.uuid);
				setViewState(ListPageState.ADD_EDIT);
			},
			style: {
				cursor: 'pointer',
			},
		};
	};

	return {
		areRefreshing: areRefreshing.current && isLoading,
		data: listData,
		isLoading,
		onFilterUpdate: setFilter,
		refresh: ({ resetSorting = false, resetPage = false } = {}) => {
			areRefreshing.current = true;
			if (resetSorting) {
				setSorted(initialSorted.current);
			}
			if (resetPage) {
				setPage(0);
			}
			triggerUpdate();
		},
		reset: (refreshData?: boolean) => {
			setSorted(initialSorted.current);
			setPage(0);
			// Keep the trigger update (so that we can make a data-fetch call), and just manage whether the request actually sends
			// with the "if" statement below
			triggerUpdate();
			if (refreshData === false) {
				// This is to make sure that data doesn't go get fetched since it's not wanted
				canFetchData.current = false;
			}
		},
		selectedUuid,
		tableProps: {
			onTableUpdate,
			page,
			pages,
			pageSize,
			pageSizeOptions,
			rowProperties: tableRowProperties,
			sorted: JSON.parse(sorted) as Sort<P>[],
			totalRecordCount,
		},
		viewState: [viewState, setViewState],
	} as const;
}
