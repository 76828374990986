import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { cloneDeep } from 'lodash';

export interface ImageDB extends BaseEntityDB {}

export interface ImageDto extends BaseEntityDto {
	entityType: string;
	imageUrl: string;
	binaryData: string | null;
}

export default class Image extends BaseEntity {
	entityType: string;
	imageUrl: string;
	binaryData: string;

	constructor(props: Partial<ImageDto | Image> = {}) {
		props = cloneDeep(props);
		super(props);

		this.entityType = props.name || '';
		this.imageUrl = props.imageUrl || '';
		this.binaryData = props.binaryData || '';
	}
}
