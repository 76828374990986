export const OK = 'OK';
export const SAVE_REMEMBER_ME = 'saveRememberMe';
export const SAVE_USERNAME = 'saveUsername';
export const SAVE_PASSWORD = 'savePassword';
export const LANGUAGE_LOCALE = 'languageLocale';

// uri
export const LOGIN_URI = '/login';
export const PROCESS_URI = '/process';
export const VISIT_QUEUE_URI = '/queue';
export const OPEN_DRAFTS_URI = '/open-drafts';
export const OPEN_DRAFTS_COUNT_URI = '/open-drafts/count';
export const MOVEMENTS_URI = 'movements';
export const CHARGE_TYPES_URI = 'charge-types';

// page paths
export const ROOT_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const HOME_PAGE = '/home';
export const VISITS_PAGE = '/visits';
export const EXPENSE_CATEGORIES_PAGE = '/expense-categories';
export const PATIENTS_PAGE = '/patients';
export const PRODUCTS_PAGE = '/products';
export const RECEIVE_PRODUCTS_PAGE = '/receive-products';
export const SERVICES_PAGE = '/services';
export const EXPENSES_PAGE = '/expenses';
export const TRACK_INCOME_PAGE = '/track-income';
export const ORGANIZATION_PAGE = '/organization';
export const OTC_PAGE = '/otc';
export const SUPPLIERS_PAGE = '/suppliers';
export const TOS_PAGE = '/tos';
export const STOCKTAKE_PAGE = '/stock-take';
export const SESSION_TIMEOUT_PAGE = '/session-timeout';
export const REPORTS_PAGE = '/reports';
export const SERVICE_DEBT_PAGE = '/service-debt';
export const CODED_DIAGNOSES_PAGE = '/coded-diagnoses';
export const REFRESH_PAGE = '/refresh';
export const INSURERS_AND_DONORS = '/insurers-and-donors';
export const MANAGE_USERS_PAGE = '/manage-users';
export const TRANSFER_INVENTORY = '/transfer-inventory';
export const DIAGNOSTICS_PAGE = '/diagnostics';
export const BANDA_HEALTH_TERMS_OF_SERVICE_PAGE = 'https://www.bandahealth.org/bandahealthsolutions-termsofservice/';
export const BANDA_HEALTH_PRIVACY_POLICY_PAGE = 'https://www.bandahealth.org/bandahealthsolutions-privacypolicy/';
export const BANDA_HEALTH_HOME_PAGE = 'https://www.bandahealth.org/';
export const BANDA_HEALTH_CONTACT_PAGE = 'https://www.bandahealth.org/contact/';
export const BANDA_HEALTH_HOW_TO_VIDEOS_PAGE = 'https://www.youtube.com/channel/UCIfk08Ojb9gi3fAKKxHTlbg/videos/';
// properties
export const PATIENT_TYPES = 'patientTypes';
export const PRODUCT_CATEGORY_TYPES = 'productCategoryTypes';
export const PRODUCT_CATEGORIES = 'productCategories';
export const REFERRALS = 'referrals';
export const PROCESS_STAGE_LIST = 'processStageList';

export const SEARCH = {
	INPUT_DELAY: 300,
	DEFAULT_PAGE_NUMBER: 0,
	DEFAULT_PAGE_SIZE: 15,
} as const;

export const DEFAULT_PAGE_SIZE = 15;

// for: eslint jsx - a11y / anchor - is - valid;
export const ANCHOR_CURRENT_PAGE = '#';

export const DEFAULT_DEPLOYMENT_CHECK_DELAY = 300000; // 5 minutes in milliseconds

export const debounceTimeouts = {
	SUBMIT_HANDLER: 300, // milliseconds
} as const;
