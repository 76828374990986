import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';

const emptyFunction = () => {};
let currentModalsPromiseResolve: (value: any | PromiseLike<any>) => void = emptyFunction;
let currentModalsPromiseReject: (value: any | PromiseLike<any>) => void = emptyFunction;
let modalContainer: HTMLElement | undefined;
let modalElement: ReactElement<any>;

/**
 * This function takes JSX to render on the screen, typically a modal
 * @param {React.FunctionComponentElement<any> | React.FunctionComponentElement<any>[]} modalElement The Modal to render
 * @returns {Promise<Boolean>}
 */
async function BandaModal<T>(modalElementArg: ReactElement<any>): Promise<T> {
	// Close any other modal that may be opened
	closeModal();

	modalElement = modalElementArg;

	// Create the new modal in the form of a promise
	return new Promise<T>((resolve, reject) => {
		currentModalsPromiseResolve = resolve;
		currentModalsPromiseReject = reject;
		modalContainer = document.createElement('div');
		modalContainer.classList.add('d-none');
		document.body.appendChild(modalContainer);
		renderModal(true);
	});
}

const renderModal = (show: boolean) => {
	if (!modalContainer || !modalElement) {
		return;
	}
	ReactDOM.render(React.cloneElement(modalElement, { show }), modalContainer);
};

/**
 * This is internal and called to reset the module's state
 */
const resetState = () => {
	currentModalsPromiseResolve = emptyFunction;
	currentModalsPromiseReject = emptyFunction;
	modalContainer = undefined;
};

/**
 * This method has to be called by whomever is using this module to open a component - otherwise the modal won't disappear!
 * @param {*} valueToResolveWith The value to resolve the promise with, if any
 * @param {boolean} shouldReject Whether the promise should be rejected instead of resolved
 */
export const closeModal = (valueToResolveWith?: any, shouldReject?: boolean) => {
	renderModal(false);
	if (currentModalsPromiseResolve && !shouldReject) {
		currentModalsPromiseResolve(valueToResolveWith);
	} else if (currentModalsPromiseReject && shouldReject) {
		currentModalsPromiseReject(valueToResolveWith);
	}
	if (modalContainer) {
		try {
			ReactDOM.unmountComponentAtNode(modalContainer);
			document.body.removeChild(modalContainer);
		} catch {}
	}
	resetState();
};

export default BandaModal;
