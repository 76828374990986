import Payment, { PaymentDB, PaymentDto } from '../models/Payment';
import DocumentService from './DocumentService';

export default class PaymentService extends DocumentService<Payment, PaymentDto, PaymentDB> {
	public entityTableName: string = 'c_payment';
	entityName = 'payments';

	map(data: PaymentDto) {
		return new Payment(data);
	}

	reverseMap(data: Payment): PaymentDto {
		return {
			...data,
			transactionDate: data.transactionDate?.toISOString() || '',
			businessPartner: { ...data.businessPartner, dateOfBirth: '', lastVisitDate: '' },
			payAmount: data.payAmount || 0,
			documentType: data.documentType ? { uuid: data.documentType.uuid } : undefined,
		};
	}
}
