import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Dropdown, DropdownButtonProps } from 'react-bootstrap';

type BHDropdownButtonProps = DropdownButtonProps & { icon: FontAwesomeIconProps['icon'] };

const BHDropdownButton = ({
	icon,
	title,
	children,
	bsPrefix,
	size,
	disabled,
	variant,
	href,
	id,
	onClick,
	className,
	...dropdownButtonProps
}: BHDropdownButtonProps) => {
	onClick =
		onClick ||
		((e) => {
			e.stopPropagation();
		});
	return (
		<Dropdown {...dropdownButtonProps} onClick={onClick}>
			<Dropdown.Toggle
				id={id}
				href={href}
				size={size}
				variant={variant}
				disabled={disabled}
				childBsPrefix={bsPrefix}
				className={className}
			>
				<FontAwesomeIcon icon={icon} className="me-2" />
				{title}
			</Dropdown.Toggle>
			<Dropdown.Menu>{children}</Dropdown.Menu>
		</Dropdown>
	);
};

export default BHDropdownButton;
