import axios from 'axios';
import { DocumentActionValue, WarehouseDto } from '../models';
import DBFilter, { Filter } from '../models/DBFilter';
import { PaymentDto } from '../models/Payment';
import Response from '../models/Response';
import Sort from '../models/Sort';
import Visit, { VisitDB, VisitDto } from '../models/Visit';
import { configs } from '../utils/Configs';
import { OPEN_DRAFTS_COUNT_URI, OPEN_DRAFTS_URI, PROCESS_URI, VISIT_QUEUE_URI } from '../utils/Constants';
import { createPagingParameters } from '../utils/Pagination';
import BaseEntityService from './BaseEntityService';

export default class VisitService extends BaseEntityService<Visit, VisitDto, VisitDB> {
	public entityTableName: string = 'c_order';
	entityName = 'visits';
	queueUrl = `${configs.apiUrl}/${this.entityName}${VISIT_QUEUE_URI}`;
	getListOpenDraftsUrl = `${configs.apiUrl}/${this.entityName}${OPEN_DRAFTS_URI}`;
	getOpenDraftsCountUrl = `${configs.apiUrl}/${this.entityName}${OPEN_DRAFTS_COUNT_URI}`;

	/**
	 * Retrieve visit queue
	 */
	getVisitQueue(page: number, size: number, sorted: Sort<VisitDB>[]): Promise<Response<VisitDto>> {
		const url = `${this.queueUrl}${createPagingParameters(page, size, sorted)}`;
		return axios.get<Response<VisitDto>>(url).then(({ data }) => data);
	}

	/**
	 * Retrieve open drafts
	 */
	getListOpenDrafts(page: number, size: number, sorted: Sort<VisitDB>[]): Promise<Response<Visit>> {
		const url = `${this.getListOpenDraftsUrl}${createPagingParameters(page, size, sorted)}`;
		return axios
			.get<Response<VisitDto>>(url)
			.then(({ data }) => new Response({ ...data, results: (data?.results || []).map(this.map) }));
	}

	/**
	 * Retrieve count of open drafts
	 */
	getCountOpenDrafts(): Promise<number> {
		return axios.get<number>(`${this.getOpenDraftsCountUrl}`).then(({ data }) => data);
	}

	map(data: VisitDto): Visit {
		return new Visit(data);
	}

	reverseMap(data: Visit): VisitDto {
		return {
			...data,
			patient: {
				...data.patient,
				dateOfBirth: undefined,
				lastVisitDate: data.patient?.lastVisitDate || '',
			},
			visitDate: data.visitDate.getTime(),
			description: data.description || '',
			orders: data.orders?.map((order) => ({
				...order,
				documentTypeTarget: { uuid: order.documentTypeTarget?.uuid },
				warehouse: { uuid: order.warehouse.uuid } as WarehouseDto,
				orderLines: order.orderLines?.map((orderLine) => ({
					...orderLine,
					product: { ...orderLine.product, storageOnHandList: [], attributeSet: null },
					attributeSetInstance: orderLine.attributeSetInstance
						? {
								...orderLine.attributeSetInstance,
								guaranteeDate: orderLine.attributeSetInstance.guaranteeDate?.getTime(),
								serialNumber: orderLine.attributeSetInstance.serialNumber || null,
								lot: orderLine.attributeSetInstance.lot || null,
							}
						: null,
					charge: { ...orderLine.charge, chargeInformationList: [] },
					price: orderLine.price || 0,
				})),
				dateOrdered: order.dateOrdered.getTime(),
				dateAccount: order.dateAccount.getTime(),
				description: order.description || '',
				businessPartner: order.businessPartner ? { uuid: order.businessPartner.uuid } : null,
			})),
			invoices: data.invoices?.map((invoice) => ({
				...invoice,
				invoiceLines: invoice.invoiceLines?.map((invoiceLine) => ({
					...invoiceLine,
					product: { ...invoiceLine.product, storageOnHandList: [], attributeSet: null },
					attributeSetInstance: invoiceLine.attributeSetInstance
						? {
								...invoiceLine.attributeSetInstance,
								guaranteeDate: invoiceLine.attributeSetInstance.guaranteeDate?.getTime(),
								serialNumber: invoiceLine.attributeSetInstance.serialNumber || null,
								lot: invoiceLine.attributeSetInstance.lot || null,
							}
						: null,
					charge: { ...invoiceLine.charge, account: null, subType: null },
					price: invoiceLine.price || 0,
					orderLine: invoiceLine.orderLine ? { uuid: invoiceLine.orderLine.uuid } : null,
					lineNetAmount: invoiceLine.lineNetAmount,
				})),
				dateInvoiced: invoice.dateInvoiced?.toISOString(),
				description: invoice.description || '',
				businessPartner: invoice.businessPartner ? { uuid: invoice.businessPartner.uuid } : null,
				order: invoice.order ? { uuid: invoice.order.uuid } : null,
				documentTypeTarget: { uuid: invoice.documentTypeTarget?.uuid },
			})),
			payments: (data.payments || []).map(
				(payment): PaymentDto => ({
					...payment,
					transactionDate: payment.transactionDate?.toISOString() || '',
					businessPartner: { uuid: payment.businessPartner.uuid },
					payAmount: payment.payAmount || 0,
					documentType: { uuid: payment.documentType?.uuid },
				}),
			),
			encounters: (data.encounters || []).map((encounter) => ({
				...encounter,
				encounterDate: encounter.encounterDate?.getTime(),
				observations: (encounter.observations || []).map((observation) => ({
					...observation,
					field: { uuid: observation.field.uuid },
				})),
			})),
		};
	}

	getDefaultSearchFilter(query: string): Filter<VisitDB> {
		return DBFilter<VisitDB>()
			.or(DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('name').contains(query).up())
			.or(DBFilter<VisitDB>().property('bh_patientid').equals(query));
	}

	// TODO: Remove these endpoints when order processing is separate from a visit
	/**
	 * Process a given entity, such as completing it or voiding it
	 * @param uuid The uuid of the entity to process
	 * @param documentAction The process action to be executed
	 * @returns The entity after it's been processed
	 */
	async process(uuid: string, documentAction: DocumentActionValue) {
		return axios
			.post<VisitDto>(`${configs.apiUrl}/${this.entityName}/${uuid}/process/${documentAction}`)
			.then(({ data }) => this.map(data));
	}

	/**
	 * A sugar method to first save the entity to process, then process the entity
	 * @param data The entity to save and process
	 * @param documentAction The process action to be executed
	 * @returns The saved and processed entity
	 */
	async saveAndProcess(data: Visit, documentAction: DocumentActionValue) {
		return axios
			.post<VisitDto>(`${configs.apiUrl}/${this.entityName}${PROCESS_URI}/${documentAction}`, this.reverseMap(data))
			.then(({ data }) => this.map(data));
	}
}
