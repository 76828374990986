import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { ANCHOR_CURRENT_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';

const SessionTimeoutPanel = () => {
	const { t } = useTranslation();
	const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false);

	const redirectLogin = () => {
		setShouldRedirectToLogin(true);
	};

	return shouldRedirectToLogin ? (
		<Redirect to="/login" />
	) : (
		<>
			<Row>
				<Col className="m-2">
					<h5>Banda Health</h5>
				</Col>
			</Row>
			<Row>
				<Col className="ms-2">
					<Trans i18nKey={uiText.session.TIMEOUT} t={t}>
						Session timeout. Kindly
						<a href={ANCHOR_CURRENT_PAGE} onClick={redirectLogin}>
							login
						</a>
						again. Thanks!
					</Trans>
				</Col>
			</Row>
		</>
	);
};

export default SessionTimeoutPanel;
