import React, { ReactNode } from 'react';
import { Row } from 'react-bootstrap';
import WorkspaceMenuFilters from './WorkspaceMenuFilters';
import WorkspaceMenuNewButton from './WorkspaceMenuNewButton';
import WorkspaceMenuSearch from './WorkspaceMenuSearch';

const WorkspaceMenu = ({ className, children }: { className?: string; children: ReactNode }) => (
	<Row className={`${className ? className : ''}`}>{children}</Row>
);

export default Object.assign(WorkspaceMenu, {
	Search: WorkspaceMenuSearch,
	Filters: WorkspaceMenuFilters,
	NewButton: WorkspaceMenuNewButton,
});
