import { StorageOnHandDto } from '../models';
import Transaction, { TransactionDB, TransactionDto } from '../models/Transaction';
import BaseEntityService from './BaseEntityService';

export default class TransactionService extends BaseEntityService<Transaction, TransactionDto, TransactionDB> {
	public entityTableName: string = 'AD_Transaction';
	protected entityName = 'transactions';

	map(data: TransactionDto): Transaction {
		return new Transaction(data);
	}

	reverseMap(data: Transaction): TransactionDto {
		return {
			...data,
			attributeSetInstance: {
				...data.attributeSetInstance,
				guaranteeDate: data.attributeSetInstance.guaranteeDate?.getTime(),
			},
			product: {
				...data.product,
				storageOnHandList: (data.product.storageOnHandList || []).map(
					(storageOnHand): StorageOnHandDto => ({
						...storageOnHand,
						attributeSetInstance: {
							...storageOnHand.attributeSetInstance,
							guaranteeDate: storageOnHand.attributeSetInstance.guaranteeDate?.getTime(),
						},
						product: undefined,
					}),
				),
			},
			inventoryLine: {
				...data.inventoryLine,
				product: undefined,
				attributeSetInstance: {
					...data.inventoryLine.attributeSetInstance,
					guaranteeDate: data.inventoryLine.attributeSetInstance.guaranteeDate?.getTime(),
				},
			},
			movementLine: {
				...data.movementLine,
				product: undefined,
				attributeSetInstance: {
					...data.movementLine.attributeSetInstance,
					guaranteeDate: data.movementLine.attributeSetInstance.guaranteeDate?.getTime(),
				},
			},
			inOutLine: {
				...data.inOutLine,
				product: undefined,
				attributeSetInstance: {
					...data.inOutLine.attributeSetInstance,
					guaranteeDate: data.inOutLine.attributeSetInstance.guaranteeDate?.getTime(),
				},
				orderLine: {
					...data.inOutLine.orderLine,
					product: undefined,
					attributeSetInstance: {
						...data.inOutLine.orderLine.attributeSetInstance,
						guaranteeDate: data.inOutLine.orderLine.attributeSetInstance?.guaranteeDate?.getTime(),
					},
				},
			},
		};
	}
}
