import { cloneDeep } from 'lodash';
import PagingInfo from './PagingInfo';

export default class Response<T> {
	results: T[];
	pagingInfo: PagingInfo;

	constructor(props?: Partial<Response<T>>) {
		props = cloneDeep(props || {});

		this.results = props.results || [];
		this.pagingInfo = new PagingInfo(props.pagingInfo);
	}
}
