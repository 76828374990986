import { useApolloClient } from '@apollo/client';
import { useContext, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDeepCompareEffect } from 'react-use';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import { ChangeAccessDocument } from '../../graphql/__generated__/graphql';
import useService from '../../hooks/useService';
import ChangeAccess from '../../models/ChangeAccess';
import Client from '../../models/Client';
import { convertLocaleToBackend } from '../../models/Language';
import Organization from '../../models/Organization';
import { exception } from '../../utils/analytics';
import { transformAuthenticationDataToUserContextData } from '../../utils/AuthenticationUtil';
import { OK } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import CustomPrompt from '../CustomPrompt/CustomPrompt';

type ChangeAccessModalProperties = {
	onHandleClose: () => void;
};

type ChangeAccessModalForm = {
	clientUuid: string;
	organizationUuid: string;
	roleUuid: string;
	warehouseUuid: string;
};

const ChangeAccessModal = ({ onHandleClose }: ChangeAccessModalProperties) => {
	const { t, i18n } = useTranslation();
	const graphqlClient = useApolloClient();
	const { availableClients: clients, organization, update, client, role, warehouse, user } = useContext(UserContext);

	const { authService } = useService();

	const { register, handleSubmit, setValue, control } = useForm<ChangeAccessModalForm>({
		defaultValues: {
			clientUuid: client.uuid,
			organizationUuid: organization.uuid,
			roleUuid: role.uuid,
			warehouseUuid: warehouse.uuid,
		},
	});

	const context = useContext(NavBlockingContext);
	const [roles, setRoles] = useState(organization.roles);
	const [organizations, setOrganizations] = useState(client.organizations);
	const [warehouses, setWarehouses] = useState(organization.warehouses);
	const defaultClientUuid = client.uuid;

	const clientUuid = useWatch({ control, name: 'clientUuid' });
	const organizationUuid = useWatch({ control, name: 'organizationUuid' });
	const roleUuid = useWatch({ control, name: 'roleUuid' });
	const warehouseUuid = useWatch({ control, name: 'warehouseUuid' });

	const defaultOrganizationUuid = organization.uuid;
	const foundClient = clients.find((client: Client) => client.uuid === clientUuid);
	useDeepCompareEffect(() => {
		if (foundClient) {
			setOrganizations(foundClient.organizations);

			// set default value
			if (clientUuid === defaultClientUuid) {
				setValue('organizationUuid', defaultOrganizationUuid);
			} else if (foundClient.organizations.length === 1) {
				setValue('organizationUuid', foundClient.organizations[0].uuid);
			}
		}
	}, [clientUuid, foundClient, defaultClientUuid, setValue, defaultOrganizationUuid]);

	const defaultRoleUuid = role.uuid;
	const defaultWarehouseUuid = warehouse.uuid;
	const foundOrganization = organizations.find((organization: Organization) => organization.uuid === organizationUuid);
	useDeepCompareEffect(() => {
		if (
			foundClient?.organizations.some((organization) => organization.uuid === foundOrganization?.uuid) &&
			foundOrganization
		) {
			// reset roles and warehouses
			setValue('roleUuid', '');
			setValue('warehouseUuid', '');

			// load roles and warehouses.
			if (foundOrganization) {
				setRoles(foundOrganization.roles);
				// set default values
				if (clientUuid === defaultClientUuid) {
					setValue('roleUuid', defaultRoleUuid);
				} else if (foundOrganization.roles.length === 1) {
					setValue('roleUuid', foundOrganization.roles[0].uuid);
				}

				setWarehouses(foundOrganization.warehouses);
				// set default values
				if (clientUuid === defaultClientUuid) {
					setValue('warehouseUuid', defaultWarehouseUuid);
				} else if (foundOrganization.warehouses.length === 1) {
					setValue('warehouseUuid', foundOrganization.warehouses[0].uuid);
				}
			}
		}
	}, [foundClient, foundOrganization, setValue, clientUuid, defaultRoleUuid, defaultWarehouseUuid, defaultClientUuid]);

	// Ensure the selects update after the form is repainted
	useLayoutEffect(() => {
		setValue('clientUuid', clientUuid);
		setValue('organizationUuid', organizationUuid);
		setValue('roleUuid', roleUuid);
		setValue('warehouseUuid', warehouseUuid);
	}, [setValue, clientUuid, organizationUuid, roleUuid, warehouseUuid]);

	const onHandleSubmit = async (data: ChangeAccessModalForm) => {
		if (context?.isBlocking) {
			onHandleClose();
			const userWantsToProceed = await CustomPrompt(t(uiText.notificationContainer.prompt.PROMPT_MESSAGE));
			if (!userWantsToProceed) {
				return;
			}
		}

		const changeAccess: ChangeAccess = {
			language: convertLocaleToBackend(i18n.language),
			username: user.name,
			...data,
		};

		try {
			const [{ data: response }] = await Promise.all([
				authService.changeAccess(changeAccess),
				graphqlClient.mutate({
					mutation: ChangeAccessDocument,
					variables: {
						Access: {
							AD_Client_UU: changeAccess.clientUuid,
							AD_Role_UU: changeAccess.roleUuid,
							AD_Org_UU: changeAccess.organizationUuid,
							M_Warehouse_UU: changeAccess.warehouseUuid,
						},
					},
				}),
			]);
			if (response.status === OK) {
				// invalidate the cache
				localStorage.clear();
				await graphqlClient.cache.reset();

				const { user: _user, ...contextUpdateData } = transformAuthenticationDataToUserContextData(clients, response);
				update({ ...contextUpdateData, user });

				// refresh current page.. better ideas are welcome :-)
				onHandleClose();
				window.location.reload();
			}
		} catch (error) {
			console.error(`Failed with ${error}`);
			exception({ description: `Change Access error: ${error}` });
			toast.error(t(uiText.login.error.ERROR_OCCURRED) + error);
		}
	};

	return (
		<Modal show={true}>
			<Form onSubmit={handleSubmit(onHandleSubmit)}>
				<Modal.Header>{t(uiText.changeAccess.TITLE)}</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className={`my-2 ${clients.length > 1 ? '' : 'd-none'}`} controlId="client">
						<Form.Label column>{t(uiText.changeAccess.CLIENT)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('clientUuid', { required: true })}>
								<option></option>
								{clients.map((client) => (
									<option key={client.uuid} value={client.uuid}>
										{client.name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group
						as={Row}
						className={`my-2 ${clients.length > 1 || organizations.length > 1 ? '' : 'd-none'}`}
						controlId="organization"
					>
						<Form.Label column>{t(uiText.changeAccess.ORGANIZATION)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('organizationUuid', { required: true })}>
								<option></option>
								{organizations.map((organization) => (
									<option key={organization.uuid} value={organization.uuid}>
										{organization.name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group
						as={Row}
						className={`my-2 ${clients.length > 1 || organizations.length > 1 || roles.length > 1 ? '' : 'd-none'}`}
						controlId="role"
					>
						<Form.Label column>{t(uiText.changeAccess.ROLE)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('roleUuid', { required: true })}>
								<option></option>
								{roles.map((role) => (
									<option key={role.uuid} value={role.uuid}>
										{role.name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="my-2" controlId="storeroom">
						<Form.Label column>{t(uiText.changeAccess.STOREROOM)}</Form.Label>
						<Col xs={8}>
							<Form.Select {...register('warehouseUuid', { required: true })}>
								<option></option>
								{warehouses.map((warehouse) => (
									<option key={warehouse.uuid} value={warehouse.uuid}>
										{warehouse.name}
									</option>
								))}
							</Form.Select>
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" type="button" onClick={onHandleClose}>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button variant="success" type="submit" className="ms-auto">
						{t(uiText.modal.button.CHANGE)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ChangeAccessModal;
