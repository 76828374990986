import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { RoleIncludedDB } from './RoleIncluded';

export const roleUuid = {
	CASHIER_REGISTRATION_ADVANCED: 'ee008abc-2c16-4230-b48c-b1f5577ea270',
	CASHIER_REGISTRATION_BASIC: '09eb7fc8-9cc5-44b0-9d14-15258a066038',
	CASHIER_REGISTRATION_BASIC_PLUS: 'c0e72e44-9cc9-4a0a-b5cd-6cc923678c1a',
	CLINICIAN_NURSE_ADVANCED: 'c54253cf-c86b-4aaa-b472-ed8880635c62',
	CLINICIAN_NURSE_BASIC: '98617c31-55ff-48f9-bd44-253ef323d960',
	INVENTORY_PHARMACY_ADVANCED: 'ec17fee0-a53a-4dbb-b946-423ce14880eb',
	INVENTORY_PHARMACY_BASIC: 'a1618fd6-e1ab-4e41-a08d-854229cd5971',
	LAB_RADIOLOGY: '097feff0-3aa6-41fe-bf76-936b03859846',
	MUST_HAVES: 'baec9412-d994-4313-815c-31332357863a',
} as const;

export interface RoleDto extends BaseEntityDto {
	includedRoles?: null | RoleDto[];
	isMasterRole?: null | boolean;
}

export interface RoleDB extends BaseEntityDB {
	ad_role_uu: string;
	isMasterRole: boolean;
	'ad_role_included.ad_role::included_role_id': RoleDB;
	ad_role_included: RoleIncludedDB;
}

/**
 * The class holding information about roles
 */
export default class Role extends BaseEntity {
	includedRoles: Role[];
	isMasterRole: boolean;

	constructor(props?: Partial<Role | RoleDto>) {
		props = cloneDeep(props || {});
		super(props);
		this.includedRoles = props?.includedRoles?.map((role) => new Role(role)) || [];
		this.isMasterRole = props.isMasterRole === true;
	}
}
