import EncounterTypeWindow, { EncounterTypeWindowDto } from '../models/EncounterTypeWindow';
import { configs } from '../utils/Configs';
import NewMetadataService from './NewMetadataService';

export default class EncounterTypeWindowService extends NewMetadataService<
	EncounterTypeWindow,
	EncounterTypeWindowDto
> {
	getUrl = `${configs.apiUrl}/encounter-type-windows`;
	localStorageKey = 'encounterTypeWindows';

	map(data: EncounterTypeWindowDto): EncounterTypeWindow {
		return new EncounterTypeWindow(data);
	}

	reverseMap(data: EncounterTypeWindow): EncounterTypeWindowDto {
		return { ...data, window: { uuid: data.window?.uuid || '' } };
	}
}
