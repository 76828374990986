import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import MovementLine, { MovementLineDto } from './MovementLine';
import Locator, { LocatorDB, LocatorDto } from './Locator';
import Product, { ProductDB, ProductDto } from './Product';
import InventoryLine, { InventoryLineDto } from './InventoryLine';
import InOutLine, { InOutLineDto } from './InOutLine';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';
import User, { UserDto } from './User';

export interface TransactionDB extends BaseMetadataDB {
	m_product: ProductDB;
	m_locator: LocatorDB;
}

export interface TransactionDto extends BaseMetadataDto {
	locator?: LocatorDto | null;
	product?: ProductDto | null;
	movementDate: Date;
	movementQuantity: number;
	inventoryLine?: InventoryLineDto | null;
	movementLine?: MovementLineDto | null;
	inOutLine?: InOutLineDto | null;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	movementType: string;
	user: UserDto;
}

export default class Transaction extends BaseMetadata {
	locator: Locator;
	product: Product;
	movementDate: Date;
	movementQuantity: number;
	inventoryLine: InventoryLine;
	movementLine: MovementLine;
	inOutLine: InOutLine;
	attributeSetInstance: AttributeSetInstance;
	movementType: string;
	user: User;

	constructor(props?: Partial<Transaction | TransactionDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.locator = new Locator('locator' in props && props.locator ? props.locator : {});
		this.product = new Product('product' in props && props.product ? props.product : {});
		this.movementDate =
			((typeof props.movementDate === 'string' ||
				props.movementDate instanceof Date ||
				typeof props.movementDate === 'number') &&
				new Date(props.movementDate)) ||
			new Date();
		this.movementQuantity = props.movementQuantity || 0;
		this.inventoryLine = new InventoryLine('inventoryLine' in props && props.inventoryLine ? props.inventoryLine : {});
		this.movementLine = new MovementLine('movementLine' in props && props.movementLine ? props.movementLine : {});
		this.inOutLine = new InOutLine('inOutLine' in props && props.inOutLine ? props.inOutLine : {});
		this.attributeSetInstance = new AttributeSetInstance(
			'attributeSetInstance' in props && props.attributeSetInstance ? props.attributeSetInstance : {},
		);
		this.movementType = props.movementType || '';
		this.user = new User('user' in props && props.user ? props.user : {});
	}
}
