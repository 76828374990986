import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';

export interface ServiceDB extends BaseEntityDB {
	isActive: boolean;
	BH_SellPrice: string;
}

export interface ServiceDto extends BaseEntityDto {
	sellingPrice: number;
	productCategoryUuid: string;
}

export default class Service extends BaseEntity {
	sellingPrice?: number;
	productCategoryUuid: string;

	constructor(props?: DeepPartial<Service> | ServiceDto) {
		props = cloneDeep(props || {});
		super(props);
		this.sellingPrice = props.sellingPrice;
		this.productCategoryUuid = props.productCategoryUuid || '';
	}
}
