import i18n from 'i18next';

// This will give six decimal points worth of precision
const decimalMultiplier = 1000000;

/**
 * This function allows two numbers with decimals (though works without decimals, too) to be subtracted
 * and maintain the right precision. This is to correct the problem in JS where 0.3 - 0.2 !== 0.1
 * @param {number} value1 The first value
 * @param {number} value2 The second value to subtract from the first
 * @returns The difference between value1 and value 2 (value1 - value2)
 */
export function precisionSubtract(value1?: number | null, value2?: number | null): number {
	return (decimalMultiplier * (value1 || 0) - decimalMultiplier * (value2 || 0)) / decimalMultiplier;
}

/**
 * This function allows two numbers with decimals (though works without decimals, too) to be added
 * and maintain the right precision. This is to correct the problem in JS where 0.1 + 0.2 !== 0.3
 * @param {number} value1 The first value
 * @param {number} value2 The second value to add to the first
 * @returns The sum of value1 and value 2 (value1 + value2)
 */
export function precisionAdd(value1?: number | null, value2?: number | null): number {
	return (decimalMultiplier * (value1 || 0) + decimalMultiplier * (value2 || 0)) / decimalMultiplier;
}

export function formatNumber(number?: number | null) {
	return new Intl.NumberFormat(i18n.language, {
		maximumFractionDigits: 6,
		minimumFractionDigits: 0,
	}).format(number || 0);
}
