export default class PagingInfo {
	page: number;
	pageSize: number;
	totalRecordCount: number;
	hasMoreResults: boolean;
	totalPages: number;

	constructor(props: Partial<PagingInfo> = {}) {
		this.page = props.page || 0;
		this.pageSize = props.pageSize || 0;
		this.totalRecordCount = props.totalRecordCount || 0;
		this.hasMoreResults = props.hasMoreResults === true;
		this.totalPages = props.totalPages || 0;
	}
}
