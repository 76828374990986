import { BaseEntityDB, Client, ClientDto } from '../models';
import BaseEntityService from './BaseEntityService';

export default class ClientService extends BaseEntityService<Client, ClientDto, BaseEntityDB> {
	entityTableName: string = 'ad_client';
	entityName = 'clients';

	map(data: ClientDto) {
		return new Client(data);
	}

	reverseMap(data: Client): ClientDto {
		throw new Error('Method not implemented.');
	}
}
