import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import PayerInformationFieldValue, { PayerInformationFieldValueDto } from './PayerInformationFieldValue';
import ReferenceList, { ReferenceListDto } from './ReferenceList';

export interface PayerInformationFieldDB extends BaseEntityDB {
	shouldFillFromPatient: boolean;
	lineNumber: number;
	dataType: ReferenceListDto;
}

export interface PayerInformationFieldDto extends BaseEntityDto {
	shouldFillFromPatient: boolean;
	lineNumber: number;
	dataType: ReferenceListDto;
	values: PayerInformationFieldValueDto[];
}

export default class PayerInformationField extends BaseEntity {
	shouldFillFromPatient: boolean;
	lineNumber: number;
	dataType: ReferenceList;
	values: PayerInformationFieldValue[];

	/**
	 * Create a new instance of this class
	 * @param {PayerInformationField} entity The entity to map to this class
	 */
	constructor(entity: DeepPartial<PayerInformationField | PayerInformationFieldDto> = {}) {
		super(entity);

		this.shouldFillFromPatient = entity.shouldFillFromPatient === true;
		this.lineNumber = entity.lineNumber || 0;
		this.dataType = new ReferenceList(entity.dataType);
		this.values = (entity.values || []).map((value) => new PayerInformationFieldValue(value));
	}
}
