/// <reference types="../../types/react-table-config" />
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
	HeaderGroup,
	TableOptions,
	TableRowProps,
	usePagination,
	UseRowSelectState,
	useSortBy,
	useTable,
	UseTableRowProps,
} from 'react-table';
import { useDeepCompareEffect } from 'react-use';
import { BaseMetadataDB, Sort } from '../../models';
import { uiText } from '../../utils/Language';

export const BHTableTestIDs = {
	PAGE_SIZE: 'pageSize',
} as const;

export type BHTableFetchParameters<P> = { sorted?: Sort<P>[]; page: number; pageSize: number };

export type BHTableProps<T extends object, P extends BaseMetadataDB> = {
	onFetchData: (tableParameters: BHTableFetchParameters<P>) => void;
	data: T[];
	columns: { id?: Extract<keyof P, string> | string }[];
	pageSize?: number;
	defaultPageSize?: number;
	page: number;
	pages?: number;
	getTrGroupProps?: (
		state?: UseRowSelectState<T>,
		rowInfo?: UseTableRowProps<T>,
	) => Partial<TableRowProps> & React.HTMLProps<HTMLTableRowElement>;
	onPageChange?: (currentPage: number) => void;
	sortBy?: Sort<P>[];
	LoadingComponent?: () => JSX.Element;
	defaultSortDesc?: boolean;
	pageSizeOptions?: number[];
	numberOfPagesToShow?: number;
	totalRecordCount: number;
	className?: string;
	paginationClassName?: string;
} & TableOptions<T>;

/**
 * This is a wrapper for ReactTable with some customization to allow for Banda Health's own display style
 * and needs
 * @param {*} param The params for this component
 * @deprecated use BHGraphQLTable
 */
const BHTable = <T extends object, P extends BaseMetadataDB>({
	onFetchData,
	data,
	columns,
	pageSize: initialPageSize,
	defaultPageSize,
	pages: externalPageCount = 0,
	page: externalPage,
	getTrGroupProps = () => ({}),
	onPageChange,
	sortBy: externalSortBy = [],
	LoadingComponent = () => <></>,
	defaultSortDesc = false,
	pageSizeOptions = [5, 10, 15],
	numberOfPagesToShow = 5,
	totalRecordCount,
	className,
	paginationClassName,
}: BHTableProps<T, P>) => {
	const { t } = useTranslation();
	// This needs to be odd
	numberOfPagesToShow = Math.abs(Math.floor(numberOfPagesToShow / 2)) * 2 + 1;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setSortBy,
		state: { pageIndex: page, pageSize },
	} = useTable(
		{
			data,
			columns,
			manualSortBy: true,
			disableMultiSort: true,
			manualPagination: true,
			initialState: {
				pageIndex: externalPage,
				pageSize: initialPageSize || defaultPageSize || 5,
				sortBy: externalSortBy,
			},
			pageCount: externalPageCount,
		},
		useSortBy,
		usePagination,
	);

	const [sorted, setSorted] = useState(externalSortBy);
	const activeButtonRefs = useRef<string[]>([]);

	const updateRef = (node: HTMLButtonElement, paginationPageIndex: number) => {
		activeButtonRefs.current[paginationPageIndex] = node.id;
	};

	const externalPageUpdate = useRef(false);
	const internalPageUpdate = useRef(false);
	useEffect(() => {
		if (page !== externalPage) {
			// If something happened in the table, alert the outside world to get new data
			if (internalPageUpdate.current) {
				onPageChange?.(page);
			} else {
				// If the outside world changed, update the tables internal state
				externalPageUpdate.current = true;
				gotoPage(externalPage);
			}
		}
		internalPageUpdate.current = false;
	}, [onPageChange, gotoPage, page, externalPage]);
	useEffect(() => {
		if (!externalPageUpdate.current) {
			onFetchData({ sorted, page: page, pageSize });
		}
		externalPageUpdate.current = false;
	}, [onFetchData, page, pageSize, sorted]);

	// Make sure table kept up-to-date if sorting changes externally
	useDeepCompareEffect(() => {
		setSortBy(externalSortBy);
	}, [setSortBy, externalSortBy]);

	/**
	 * Set the sorting of the  column based on input parameters (the sorting is performed outside this component)
	 * @param {*} column The column clicked for sorting
	 */
	const sortColumn = (column: HeaderGroup<T> & { id: Extract<keyof P, string> }) => {
		if (!column.canSort) {
			return;
		}
		let sortDescending = false;
		// If the column is already sorted, just toggle it
		// Else if not sorted and we want to sort descending by default, do so
		if (column.isSorted) {
			sortDescending = !column.isSortedDesc;
		} else if (!column.isSorted && defaultSortDesc) {
			sortDescending = true;
		}
		// Trigger the "sort by" function so the column instance variable is correctly updated
		column.toggleSortBy(sortDescending);
		setSorted([
			{
				id: column.id,
				desc: sortDescending,
			},
		]);
	};

	/**
	 * Creates the "go to page N" buttons in the pagination section
	 */
	const createPaginationPageNavigationJsx = () => {
		const numberOfPagesOnEitherSideOfCurrent = (numberOfPagesToShow - 1) / 2;
		let startPage = page + 1 - numberOfPagesOnEitherSideOfCurrent;
		let endPage = page + 1 + numberOfPagesOnEitherSideOfCurrent;
		// If the end page would be past the total number of pages, make it the total number of pages
		if (endPage > pageCount) {
			const endPageDecrease = endPage - pageCount;
			endPage = pageCount;
			startPage -= endPageDecrease;
		}
		// If the start page is less than 1, set it to 1
		if (startPage < 1) {
			const startPageIncrease = 1 - startPage;
			startPage = 1;
			endPage += startPageIncrease;
		}
		// If, at this point, the end page is greater than the last, just set it to the last
		if (endPage > pageCount) {
			endPage = pageCount;
		}
		// For each page, generate a button that, on click, navigates to that page
		const pages: JSX.Element[] = [];
		for (let paginationPageIndex = startPage; paginationPageIndex <= endPage; paginationPageIndex++) {
			pages.push(
				<Button
					variant="light"
					// className={`btn bh-btn-navigate bh-btn-page ${buttonActive} ms-1`}
					//Apply a ref only if the state's id button is the same as this node
					ref={(node: HTMLButtonElement | null) => {
						if (node != null) {
							updateRef(node, paginationPageIndex);
						}
					}}
					className={`btn bh-btn-navigate bh-btn-page  ${
						page === paginationPageIndex - 1 ? 'bh-btn-page--active' : null
					} ms-1`}
					id={paginationPageIndex.toString()}
					key={paginationPageIndex}
					onClick={() => {
						internalPageUpdate.current = true;
						gotoPage(paginationPageIndex - 1);
					}}
				>
					{paginationPageIndex}
				</Button>,
			);
		}
		return pages;
	};

	/**
	 * Get the text to show which results are being viewed by the user
	 */
	const getResultsRangeDisplay = () => {
		const start = page * pageSize + 1;
		let end = (page + 1) * pageSize;
		if (end > totalRecordCount) {
			end = totalRecordCount;
		}
		return t(uiText.table.RESULTS, { start, end, total: totalRecordCount });
	};

	return (
		<>
			{LoadingComponent()}
			<Table hover {...getTableProps()} className={className ? className : 'bh-table--list'}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									className=""
									{...column.getHeaderProps(column.getSortByToggleProps())}
									onClick={(e) => {
										e.preventDefault();
										sortColumn(column as HeaderGroup<T> & { id: Extract<keyof P, string> });
									}}
								>
									<span className=" float-start me-2">{column.render('Header')}</span>
									{column.isSorted ? (
										column.isSortedDesc ? (
											<FontAwesomeIcon icon="arrow-down" />
										) : (
											<FontAwesomeIcon icon="arrow-up" />
										)
									) : null}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.length > 0 ? null : (
						<tr>
							<td colSpan={columns.length} className="text-center">
								{t(uiText.table.NO_RECORDS)}
							</td>
						</tr>
					)}
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...{ ...row.getRowProps(), ...getTrGroupProps(undefined, row) }}>
								{row.cells.map((cell) => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			<Row className={paginationClassName ? paginationClassName : 'm-0 px-0 py-1'}>
				<Col xs="auto">
					<Button
						variant="light"
						className="bh-btn-navigate me-1"
						onClick={() => {
							internalPageUpdate.current = true;
							gotoPage(0);
						}}
						disabled={!canPreviousPage}
					>
						<FontAwesomeIcon icon={faAngleDoubleLeft} />
					</Button>
					<Button
						variant="light"
						className="bh-btn-navigate me-1"
						onClick={() => {
							internalPageUpdate.current = true;
							previousPage();
						}}
						disabled={!canPreviousPage}
					>
						<FontAwesomeIcon icon={faAngleLeft} />
					</Button>
					{createPaginationPageNavigationJsx()}
					<Button
						variant="light"
						className="bh-btn-navigate ms-1"
						onClick={() => {
							internalPageUpdate.current = true;
							nextPage();
						}}
						disabled={!canNextPage}
					>
						<FontAwesomeIcon icon={faAngleRight} />
					</Button>
					<Button
						variant="light"
						className="bh-btn-navigate mx-1"
						onClick={() => {
							internalPageUpdate.current = true;
							gotoPage(pageCount - 1);
						}}
						disabled={!canNextPage}
					>
						<FontAwesomeIcon icon={faAngleDoubleRight} />
					</Button>
				</Col>
				<Col xs="auto" className="ms-auto px-0">
					<Form.Select
						className="dropdown dropdown-toggle btn-light py-0 w-auto"
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
						data-testid={BHTableTestIDs.PAGE_SIZE}
					>
						{pageSizeOptions.map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</Form.Select>
				</Col>
				<Col xs="auto" aria-label="labelTotalRecordCount" className="pe-3">
					{getResultsRangeDisplay()}
				</Col>
			</Row>
		</>
	);
};

export default BHTable;
