import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Fragment } from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	C_BPartnerDisplayForReceiveProductsFragmentDoc,
	C_BPartnerSearchForReceiveProductsDocument,
	C_OrderForReceiveProductEditingQuery,
} from '../../graphql/__generated__/graphql';
import { getBusinessPartnerDefaultSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import EntityLookupGraphQL from '../entity-lookup/EntityLookupGraphQL';

export type RecieveProductSupplierInputFormValues = { C_BPartner: { UU: string } };
export const convertToSupplierInputFormValues: (
	initialData: C_OrderForReceiveProductEditingQuery['C_Order'],
) => RecieveProductSupplierInputFormValues = (initialData) => {
	if (!initialData) {
		return { C_BPartner: { UU: '' } };
	}
	return {
		C_BPartner: { UU: initialData.C_BPartner.UU },
	};
};

const SupplierInput = ({ isDataReadOnly }: { isDataReadOnly: boolean }) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const [onSearchVendor, { data: vendorOptions, loading: areLoadingVendors }] = useLazyQuery(
		C_BPartnerSearchForReceiveProductsDocument,
		{ fetchPolicy: 'network-only' },
	);
	const {
		formState: { errors },
	} = useFormContext<RecieveProductSupplierInputFormValues>();

	return (
		<Card className="bh-card">
			<Form.Group as={Fragment} controlId="C_BPartner">
				<Card.Header className="h5">
					<Form.Label column className="fw-bold h5 p-0">
						{t(uiText.supplier.DETAILS)}
					</Form.Label>
				</Card.Header>
				<Card.Body>
					<Col xs={9}>
						<EntityLookupGraphQL<RecieveProductSupplierInputFormValues, 'C_BPartner'>
							name="C_BPartner"
							rules={{ required: true }}
							isLoading={areLoadingVendors}
							id="C_BPartner"
							emptyLabel={t(uiText.supplier.search.EMPTY)}
							labelKey={(data) =>
								graphqlClient.readFragment({
									id: data.UU,
									fragment: C_BPartnerDisplayForReceiveProductsFragmentDoc,
								})?.Name || ''
							}
							placeholder={t(uiText.supplier.search.PLACEHOLDER)}
							promptText={t(uiText.supplier.search.SEARCHING)}
							searchText={t(uiText.supplier.search.SEARCHING)}
							options={vendorOptions?.C_BPartnerGet.Results || []}
							onSearch={(query) =>
								onSearchVendor({
									variables: {
										Filter: getBusinessPartnerDefaultSearchFilter(query).property('isVendor').equals(true).toString(),
									},
								})
							}
							disabled={isDataReadOnly}
							inputProps={{ 'aria-label': t(uiText.supplier.LABEL) }}
						/>
						{errors.C_BPartner?.UU && <span className="text-danger">{t(uiText.order.error.MISSING_SUPPLIER)}</span>}
					</Col>
				</Card.Body>
			</Form.Group>
		</Card>
	);
};

export default SupplierInput;
