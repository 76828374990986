import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Layout from '../Layout/Layout';
import { ComponentCompiler } from './DashboardComponentCreator';

const areaChartData = `
const chartdata = [
	{
		date: 'Jan 22',
		SemiAnalysis: 2890,
		'The Pragmatic Engineer': 2338,
	},
	{
		date: 'Feb 22',
		SemiAnalysis: 2756,
		'The Pragmatic Engineer': 2103,
	},
	{
		date: 'Mar 22',
		SemiAnalysis: 3322,
		'The Pragmatic Engineer': 2194,
	},
	{
		date: 'Apr 22',
		SemiAnalysis: 3470,
		'The Pragmatic Engineer': 2108,
	},
	{
		date: 'May 22',
		SemiAnalysis: 3475,
		'The Pragmatic Engineer': 1812,
	},
	{
		date: 'Jun 22',
		SemiAnalysis: 3129,
		'The Pragmatic Engineer': 1726,
	},
];
`;

const areaChartCode = `
<Card>
  <Title>Newsletter revenue over time (USD)</Title>
  <AreaChart
    className="h-72 mt-4"
    data={chartdata}
    index="date"
    categories={["SemiAnalysis", "The Pragmatic Engineer"]}
    colors={["indigo", "cyan"]}
  />
</Card>
`;

const pieChartData = `
const cities = [
  {
    name: "New York",
    sales: 9800,
  },
  {
    name: "London",
    sales: 4567,
  },
  {
    name: "Hong Kong",
    sales: 3908,
  },
  {
    name: "San Francisco",
    sales: 2400,
  },
  {
    name: "Singapore",
    sales: 1908,
  },
  {
    name: "Zurich",
    sales: 1398,
  },
];
`;

const donutChartCode = `
<Card>
  <Title>Sales</Title>
  <DonutChart
    className="mt-6"
    data={cities}
    category="sales"
    index="name"
    colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
  />
</Card>
`;

const pieChartCode = `
<Card>
  <Title>Sales</Title>
  <DonutChart
    className="mt-6"
    data={cities}
    category="sales"
    index="name"
		variant="pie"
    colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
  />
</Card>
`;

const progressBarCode = `
<Card>
  <Flex alignItems="start">
    <div>
      <Text>Sales</Text>
      <Metric>$ 12,699</Metric>
    </div>
    <BadgeDelta deltaType="moderateIncrease">13.2%</BadgeDelta>
  </Flex>
  <Flex className="mt-4">
    <Text className="truncate">68% ($ 149,940)</Text>
    <Text>$ 220,500</Text>
  </Flex>
  <ProgressBar value={15.9} className="mt-2" />
</Card>
`;

const barChartData = `
const chartdata = [
  {
    name: "Amphibians",
    "Number of threatened species": 2488,
  },
  {
    name: "Birds",
    "Number of threatened species": 1445,
  },
  {
    name: "Crustaceans",
    "Number of threatened species": 743,
  },
];
`;

const barChartCode = `
<Card>
  <Title>Number of species threatened with extinction (2021)</Title>
  <Subtitle>
    The IUCN Red List has assessed only a small share of the total known species in the world.
  </Subtitle>
  <BarChart
    className="mt-6"
    data={chartdata}
    index="name"
    categories={["Number of threatened species"]}
    colors={["blue"]}
    yAxisWidth={48}
  />
</Card>
`;

const tableData = `
const salesPeople = [
  {
    name: "Peter Doe",
    leads: 45,
    sales: "1,000,000",
    quota: "1,200,000",
    variance: "low",
    region: "Region A",
    status: "overperforming",
  },
  {
    name: "Lena Whitehouse",
    leads: 35,
    sales: "900,000",
    quota: "1,000,000",
    variance: "low",
    region: "Region B",
    status: "average",
  },
  {
    name: "Phil Less",
    leads: 52,
    sales: "930,000",
    quota: "1,000,000",
    variance: "medium",
    region: "Region C",
    status: "underperforming",
  },
  {
    name: "John Camper",
    leads: 22,
    sales: "390,000",
    quota: "250,000",
    variance: "low",
    region: "Region A",
    status: "overperforming",
  },
  {
    name: "Max Balmoore",
    leads: 49,
    sales: "860,000",
    quota: "750,000",
    variance: "low",
    region: "Region B",
    status: "overperforming",
  },
];

const deltaTypes = {
  average: "unchanged",
  overperforming: "moderateIncrease",
  underperforming: "moderateDecrease",
};
`;

const tableCode = `
function SalesPeopleTable() {
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedNames, setSelectedNames] = useState([]);

  const isSalesPersonSelected = (salesPerson) =>
    (salesPerson.status === selectedStatus || selectedStatus === "all") &&
    (selectedNames.includes(salesPerson.name) || selectedNames.length === 0);

  return (
    <Card>
      <div>
        <Flex className="space-x-0.5" justifyContent="start" alignItems="center">
          <Title> Performance History </Title>
					<Icon
						icon={InformationCircleIcon}
						variant="simple"
						tooltip="Shows daily increase or decrease of particular domain"
					/>
        </Flex>
      </div>
      <div className="flex space-x-2">
        <MultiSelect
          className="max-w-full sm:max-w-xs"
          onValueChange={setSelectedNames}
          placeholder="Select Salespeople..."
        >
          {salesPeople.map((item) => (
            <MultiSelectItem key={item.name} value={item.name}>
              {item.name}
            </MultiSelectItem>
          ))}
        </MultiSelect>
        <Select
          className="max-w-full sm:max-w-xs"
          defaultValue="all"
          onValueChange={setSelectedStatus}
        >
          <SelectItem value="all">All Performances</SelectItem>
          <SelectItem value="overperforming">Overperforming</SelectItem>
          <SelectItem value="average">Average</SelectItem>
          <SelectItem value="underperforming">Underperforming</SelectItem>
        </Select>
      </div>
      <Table className="mt-6">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell className="text-right">Leads</TableHeaderCell>
            <TableHeaderCell className="text-right">Sales ($)</TableHeaderCell>
            <TableHeaderCell className="text-right">Quota ($)</TableHeaderCell>
            <TableHeaderCell className="text-right">Variance</TableHeaderCell>
            <TableHeaderCell className="text-right">Region</TableHeaderCell>
            <TableHeaderCell className="text-right">Status</TableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {salesPeople
            .filter((item) => isSalesPersonSelected(item))
            .map((item) => (
              <TableRow key={item.name}>
                <TableCell>{item.name}</TableCell>
                <TableCell className="text-right">{item.leads}</TableCell>
                <TableCell className="text-right">{item.sales}</TableCell>
                <TableCell className="text-right">{item.quota}</TableCell>
                <TableCell className="text-right">{item.variance}</TableCell>
                <TableCell className="text-right">{item.region}</TableCell>
                <TableCell className="text-right">
                  <BadgeDelta deltaType={deltaTypes[item.status]} size="xs">
                    {item.status}
                  </BadgeDelta>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}
`;

const lineChartData = `
const chartdata = [
  {
    year: 1970,
    "Export Growth Rate": 2.04,
    "Import Growth Rate": 1.53,
  },
  {
    year: 1971,
    "Export Growth Rate": 1.96,
    "Import Growth Rate": 1.58,
  },
  {
    year: 1972,
    "Export Growth Rate": 1.96,
    "Import Growth Rate": 1.61,
  },
  {
    year: 1973,
    "Export Growth Rate": 1.93,
    "Import Growth Rate": 1.61,
  },
  {
    year: 1974,
    "Export Growth Rate": 1.88,
    "Import Growth Rate": 1.67,
  },
];
`;

const lineChartCode = `
<Card>
  <Title>Export/Import Growth Rates (1970 to 2021)</Title>
  <LineChart
    className="mt-6"
    data={chartdata}
    index="year"
    categories={["Export Growth Rate", "Import Growth Rate"]}
    colors={["emerald", "gray"]}
    yAxisWidth={40}
  />
</Card>
`;

const barListData = `
const data = [
  {
    name: "Twitter",
    value: 456,
    href: "https://twitter.com/tremorlabs",
    icon: function TwitterIcon() {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2.5 fill-blue-500"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
        </svg>
      );
    },
  },
  {
    name: "Google",
    value: 351,
    href: "https://google.com",
    icon: function GoogleIcon() {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2.5 fill-slate-500"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M3.064 7.51A9.996 9.996 0 0 1 12 2c2.695 0 4.959.99 6.69 2.605l-2.867 2.868C14.786 6.482 13.468 5.977 12 5.977c-2.605 0-4.81 1.76-5.595 4.123-.2.6-.314 1.24-.314 1.9 0 .66.114 1.3.314 1.9.786 2.364 2.99 4.123 5.595 4.123 1.345 0 2.49-.355 3.386-.955a4.6 4.6 0 0 0 1.996-3.018H12v-3.868h9.418c.118.654.182 1.336.182 2.045 0 3.046-1.09 5.61-2.982 7.35C16.964 21.105 14.7 22 12 22A9.996 9.996 0 0 1 2 12c0-1.614.386-3.14 1.064-4.49z" />
        </svg>
      );
    },
  },
  {
    name: "GitHub",
    value: 271,
    href: "https://github.com/tremorlabs/tremor",
    icon: function GitHubIcon() {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2.5 fill-slate-900"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 2C6.475 2 2 6.475 2 12a9.994 9.994 0 0 0 6.838 9.488c.5.087.687-.213.687-.476 0-.237-.013-1.024-.013-1.862-2.512.463-3.162-.612-3.362-1.175-.113-.288-.6-1.175-1.025-1.413-.35-.187-.85-.65-.013-.662.788-.013 1.35.725 1.538 1.025.9 1.512 2.338 1.087 2.912.825.088-.65.35-1.087.638-1.337-2.225-.25-4.55-1.113-4.55-4.938 0-1.088.387-1.987 1.025-2.688-.1-.25-.45-1.275.1-2.65 0 0 .837-.262 2.75 1.026a9.28 9.28 0 0 1 2.5-.338c.85 0 1.7.112 2.5.337 1.912-1.3 2.75-1.024 2.75-1.024.55 1.375.2 2.4.1 2.65.637.7 1.025 1.587 1.025 2.687 0 3.838-2.337 4.688-4.562 4.938.362.312.675.912.675 1.85 0 1.337-.013 2.412-.013 2.75 0 .262.188.574.688.474A10.016 10.016 0 0 0 22 12c0-5.525-4.475-10-10-10z" />
        </svg>
      );
    },
  },
  {
    name: "Reddit",
    value: 191,
    href: "https://reddit.com",
    icon: function RedditIcon() {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2.5 fill-orange-500"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm6.67-10a1.46 1.46 0 0 0-2.47-1 7.12 7.12 0 0 0-3.85-1.23L13 6.65l2.14.45a1 1 0 1 0 .13-.61L12.82 6a.31.31 0 0 0-.37.24l-.74 3.47a7.14 7.14 0 0 0-3.9 1.23 1.46 1.46 0 1 0-1.61 2.39 2.87 2.87 0 0 0 0 .44c0 2.24 2.61 4.06 5.83 4.06s5.83-1.82 5.83-4.06a2.87 2.87 0 0 0 0-.44 1.46 1.46 0 0 0 .81-1.33zm-10 1a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm5.81 2.75a3.84 3.84 0 0 1-2.47.77 3.84 3.84 0 0 1-2.47-.77.27.27 0 0 1 .38-.38A3.27 3.27 0 0 0 12 16a3.28 3.28 0 0 0 2.09-.61.28.28 0 1 1 .39.4v-.04zm-.18-1.71a1 1 0 1 1 1-1 1 1 0 0 1-1.01 1.04l.01-.04z" />
        </svg>
      );
    },
  },
  {
    name: "Youtube",
    value: 91,
    href: "https://www.youtube.com/@tremorlabs3079",
    icon: function YouTubeIcon() {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2.5 fill-red-500"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" />
        </svg>
      );
    },
  },
];
`;

const barListCode = `
  <Card>
    <Title>Website Analytics</Title>
    <Flex className="mt-4">
      <Text>
        <Bold>Source</Bold>
      </Text>
      <Text>
        <Bold>Visits</Bold>
      </Text>
    </Flex>
    <BarList data={data} className="mt-2" />
  </Card>
`;

const calloutCode = `
  <>
    <Card className="mb-4">
      <Text>Current wind speed: Turbine 1</Text>
      <Metric>18.4knts</Metric>
      <Callout
        className="mt-4"
        title="Critical speed limit reached"
        icon={ExclamationTriangleIcon}
        color="rose"
      >
        Turbine reached critical speed. Immediately reduce turbine speed.
      </Callout>
    </Card>

    <Card>
      <Text>Current wind speed: Turbine 2</Text>
      <Metric>7.2knts</Metric>
      <Callout className="mt-4" title="No critical system data" icon={CheckCircleIcon} color="teal">
        All systems are currently within their default operating ranges.
      </Callout>
    </Card>
  </>
`;

const markedBarCode = `
  <Card>
    <Flex>
      <Text>$ 9,012 &bull; 45%</Text>
      <Text>$ 20,000</Text>
    </Flex>
    <MarkerBar value={45} minValue={25} maxValue={65} color="yellow" className="mt-4" />
  </Card>
`;

const deltaBarCode = `
<Card>
  <Flex>
    <Text>Product A</Text>
    <Flex alignItems="baseline" justifyContent="end" className="space-x-1">
      <Text>+$9,000</Text>
      <Text>(+45%)</Text>
    </Flex>
  </Flex>
  <DeltaBar value={45} isIncreasePositive={false} className="mt-3" />
</Card>
`;

const categoryBarCode = `
  <Card>
    <Flex>
      <Text>Rating Product A</Text>
      <Text>62%</Text>
    </Flex>
    <CategoryBar
      values={[40, 30, 20, 10]}
      colors={["emerald", "yellow", "orange", "rose"]}
      markerValue={62}
      className="mt-3"
    />
  </Card>
`;

const progressBarExampleCode = `
  <Card>
    <Flex>
      <Text>$ 9,012 &bull; 45%</Text>
      <Text>$ 20,000</Text>
    </Flex>
    <ProgressBar value={45} color="teal" className="mt-3" />
  </Card>
`;

const statusData = `
const data = [
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "rose", tooltip: "Downtime" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "gray", tooltip: "Maintenance" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "emerald", tooltip: "Operational" },
  { color: "yellow", tooltip: "Degraded" },
  { color: "emerald", tooltip: "Operational" },
];
`;

const statusCode = `
  <Card>
    <Title>Status</Title>
    <Text>Lena&apos;s Webshop &bull; May 2022</Text>
    <Flex justifyContent="end" className="mt-4">
      <Text>Uptime 92%</Text>
    </Flex>
    <Tracker data={data} className="mt-2" />
  </Card>
`;

export default function DashboardTestIndividual() {
	const [areaChartIframeRef, setAreaChartIframeRef] = useState<HTMLIFrameElement | null>();
	const [donutChartIframeRef, setDonutChartIframeRef] = useState<HTMLIFrameElement | null>();
	const [progressBarIframeRef, setProgressBarIframeRef] = useState<HTMLIFrameElement | null>();
	const [barChartIframeRef, setBarChartIframeRef] = useState<HTMLIFrameElement | null>();
	const [tableIframeRef, setTableIframeRef] = useState<HTMLIFrameElement | null>();
	const [lineChartIframeRef, setLineChartIframeRef] = useState<HTMLIFrameElement | null>();
	const [barListIframeRef, setBarListChartIframeRef] = useState<HTMLIFrameElement | null>();
	const [calloutIframeRef, setCalloutIframeRef] = useState<HTMLIFrameElement | null>();
	const [markerBarIframeRef, setMarkerBarIframeRef] = useState<HTMLIFrameElement | null>();
	const [deltaBarIframeRef, setDeltaBarIframeRef] = useState<HTMLIFrameElement | null>();
	const [categoryBarIframeRef, setCategoryBarIframeRef] = useState<HTMLIFrameElement | null>();
	const [progressBarExampleIframeRef, setProgressBarExampleIframeRef] = useState<HTMLIFrameElement | null>();
	const [statusIframeRef, setStatusIframeRef] = useState<HTMLIFrameElement | null>();
	const [pieChartIframeRef, setPieChartIframeRef] = useState<HTMLIFrameElement | null>();

	return (
		<Layout>
			<Layout.Header>
				<Layout.Title title="Dashboard Test"></Layout.Title>
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<Row className="clearfix pt-2">
					<Col xs={12}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Area Chart"
							ref={setAreaChartIframeRef}
							onLoad={() => {
								(areaChartIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									data: areaChartData,
									code: areaChartCode,
								});
								setTimeout(() => {
									areaChartIframeRef!.height = areaChartIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={4}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Donut Chart"
							ref={setDonutChartIframeRef}
							onLoad={() => {
								(donutChartIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									data: pieChartData,
									code: donutChartCode,
								});
								setTimeout(() => {
									donutChartIframeRef!.height =
										donutChartIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={4}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Progress Bar Chart"
							ref={setProgressBarIframeRef}
							onLoad={() => {
								(progressBarIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: progressBarCode,
								});
								setTimeout(() => {
									progressBarIframeRef!.height =
										progressBarIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Progress Bar Example Chart"
							ref={setProgressBarExampleIframeRef}
							onLoad={() => {
								(progressBarExampleIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: progressBarExampleCode,
								});
								setTimeout(() => {
									progressBarExampleIframeRef!.height =
										progressBarExampleIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Status"
							ref={setStatusIframeRef}
							onLoad={() => {
								(statusIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: statusCode,
									data: statusData,
								});
								setTimeout(() => {
									statusIframeRef!.height = statusIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={4}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Bar Chart"
							ref={setBarChartIframeRef}
							onLoad={() => {
								(barChartIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: barChartCode,
									data: barChartData,
								});
								setTimeout(() => {
									barChartIframeRef!.height = barChartIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={12}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Table"
							ref={setTableIframeRef}
							onLoad={() => {
								(tableIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: tableCode,
									data: tableData,
									componentName: 'SalesPeopleTable',
								});
								setTimeout(() => {
									tableIframeRef!.height = tableIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={6}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Line Chart"
							ref={setLineChartIframeRef}
							onLoad={() => {
								(lineChartIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: lineChartCode,
									data: lineChartData,
								});
								setTimeout(() => {
									lineChartIframeRef!.height = lineChartIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={6}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Bar List Chart"
							ref={setBarListChartIframeRef}
							onLoad={() => {
								(barListIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: barListCode,
									data: barListData,
								});
								setTimeout(() => {
									barListIframeRef!.height = barListIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={3}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Callout"
							ref={setCalloutIframeRef}
							onLoad={() => {
								(calloutIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: calloutCode,
								});
								setTimeout(() => {
									calloutIframeRef!.height = calloutIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={3}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Marker Bar Chart"
							ref={setMarkerBarIframeRef}
							onLoad={() => {
								(markerBarIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: markedBarCode,
								});
								setTimeout(() => {
									markerBarIframeRef!.height = markerBarIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Delta Chart"
							ref={setDeltaBarIframeRef}
							onLoad={() => {
								(deltaBarIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: deltaBarCode,
								});
								setTimeout(() => {
									deltaBarIframeRef!.height = deltaBarIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Category Chart"
							ref={setCategoryBarIframeRef}
							onLoad={() => {
								(categoryBarIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									code: categoryBarCode,
								});
								setTimeout(() => {
									categoryBarIframeRef!.height =
										categoryBarIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
					<Col xs={6}>
						<iframe
							className="w-100"
							src="/dashboard-component-v1.html"
							title="Pie Chart"
							ref={setPieChartIframeRef}
							onLoad={() => {
								(pieChartIframeRef?.contentWindow as ComponentCompiler | undefined)?.compile({
									data: pieChartData,
									code: pieChartCode,
								});
								setTimeout(() => {
									pieChartIframeRef!.height = pieChartIframeRef!.contentWindow!.document.body.scrollHeight.toString();
								}, 0);
							}}
						/>
					</Col>
				</Row>
			</Layout.Body>
		</Layout>
	);
}
