import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsyncFn, useAsyncRetry } from 'react-use';
import useService from '../../hooks/useService';
import {
	BusinessPartner,
	ChargeDB,
	chargeDefaultNames,
	chargeOneOffsFilter,
	DBFilter,
	DocAction,
	documentBaseType,
	Invoice,
	InvoiceLine,
	referenceListUuids,
} from '../../models';
import { exception } from '../../utils/analytics';
import { debounceTimeouts } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

type WaiveOpenBalanceFormFields = {
	amountToWaive: number;
	waiveAll: boolean;
};

const fetchCustomerInvoiceDocumentTypeArguments = [documentBaseType.ARInvoice, null, true, false, false] as const;

function WaiveOpenBalance({ patient, onClose }: { patient: BusinessPartner; onClose: () => void }) {
	const { t } = useTranslation();
	const { chargeService, documentTypeService, invoiceService, referenceListService } = useService();
	const patientsOpenBalance = patient.totalOpenBalance;

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		formState: { errors, submitCount },
	} = useForm<WaiveOpenBalanceFormFields>({ defaultValues: { waiveAll: true, amountToWaive: patientsOpenBalance } });

	const badDebtWriteOffChargeFetch = useMemo(
		() =>
			chargeService
				.get(
					0,
					10,
					undefined,
					DBFilter<ChargeDB>().property('name').equals(chargeDefaultNames.BAD_DEBT).and(chargeOneOffsFilter()),
				)
				.then(({ results }) => results[0]),
		[chargeService],
	);
	const badDebtPaymentTypeFetch = useMemo(
		async () =>
			(await referenceListService.getPaymentTypes()).find(
				(referenceList) => referenceList.uuid === referenceListUuids.CASH_DRAWER,
			),
		[referenceListService],
	);
	const { value: customerInvoiceDocumentType, retry: fetchVendorInvoiceDocumentType } = useAsyncRetry(
		async () => documentTypeService.getDocumentBaseType(...fetchCustomerInvoiceDocumentTypeArguments),
		[documentTypeService],
	);

	const [{ loading }, saveWaivedDebt] = useAsyncFn(
		async (formData) => {
			let customerInvoiceDocumentTypeToUse = customerInvoiceDocumentType;
			if (!customerInvoiceDocumentTypeToUse) {
				try {
					customerInvoiceDocumentTypeToUse = await documentTypeService.getDocumentBaseType(
						...fetchCustomerInvoiceDocumentTypeArguments,
					);
					fetchVendorInvoiceDocumentType();
					if (!customerInvoiceDocumentTypeToUse) {
						throw new Error(`reload of document type didn't work`);
					}
				} catch (error) {
					exception({ description: 'Could not load A/R invoice document type: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}

			let amountToWaive = formData.waiveAll
				? patientsOpenBalance
				: formData.amountToWaive > patientsOpenBalance
					? patientsOpenBalance
					: formData.amountToWaive;

			// Create the invoice
			const invoice = new Invoice({
				businessPartner: patient,
				paymentRule: (await badDebtPaymentTypeFetch)?.value,
				isSalesOrderTransaction: true,
				documentTypeTarget: customerInvoiceDocumentTypeToUse,
			});

			// Create the invoice line, assign it the charge, and give it a negative value equal
			// to what the user entered
			invoice.invoiceLines.push(
				new InvoiceLine({ charge: await badDebtWriteOffChargeFetch, quantity: 1, price: -amountToWaive }),
			);

			// Complete the invoice
			await invoiceService.saveAndProcess(invoice, DocAction.COMPLETE);
			onClose();
		},
		[customerInvoiceDocumentType, documentTypeService, invoiceService],
	);
	const debouncedSubmit = useMemo(() => debounce(saveWaivedDebt, debounceTimeouts.SUBMIT_HANDLER), [saveWaivedDebt]);
	const shouldWaiveAll = watch('waiveAll');
	const amountEnteredToWaive = watch('amountToWaive');

	useEffect(() => {
		if (shouldWaiveAll) {
			setValue('amountToWaive', patientsOpenBalance);
		}
	}, [shouldWaiveAll, patientsOpenBalance, setValue]);
	useEffect(() => {
		if (amountEnteredToWaive > patientsOpenBalance) {
			setValue('amountToWaive', patientsOpenBalance, { shouldValidate: submitCount > 0 });
		}
	}, [amountEnteredToWaive, patientsOpenBalance, setValue, submitCount]);

	return (
		<>
			{loading ? <LoadSpinner title={t(uiText.patient.PROCESSING)} /> : null}
			<Modal show={!loading}>
				<Form onSubmit={handleSubmit(debouncedSubmit)}>
					<Modal.Header>{t(uiText.patient.button.WAIVE_OPEN_BALANCE)}</Modal.Header>
					<Modal.Body>
						<Row className="gy-3">
							<Col xs={12}>
								{t(uiText.patient.LABEL)}: {patient.name}, {t(uiText.patient.OPEN_BALANCE)}: {patient.totalOpenBalance}
							</Col>
							<Col xs={6} className="d-flex align-items-center">
								<Form.Check id="waiveAll" label={t(uiText.patient.WAIVE_ALL)} {...register('waiveAll')} />
							</Col>
							<Col xs={6} className="d-flex align-items-center">
								<Controller
									name="amountToWaive"
									control={control}
									rules={{ max: patientsOpenBalance, min: 0 }}
									render={({ field }) => (
										<FormatNumberInput
											aria-label={t(uiText.patient.AMOUNT_TO_WAIVE)}
											disabled={shouldWaiveAll}
											{...field}
										/>
									)}
								/>
							</Col>
							{errors.amountToWaive && (
								<Col xs="auto">
									<span className="text-danger">{t(uiText.patient.error.INCORRECT_WAIVE_AMOUNT)}</span>
								</Col>
							)}
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={onClose}>
							{t(uiText.patient.button.CANCEL)}
						</Button>
						<Button type="submit" variant="success" className="ms-auto">
							{t(uiText.patient.button.WAIVE)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
}

export default WaiveOpenBalance;
