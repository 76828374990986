import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import ConceptExtra, { ConceptExtraDB, ConceptExtraDto, ConceptExtraKey } from './ConceptExtra';
import ConceptMapping, { ConceptMappingDB, ConceptMappingDto } from './ConceptMapping';
import ConceptName, { ConceptNameDto } from './ConceptName';
import ClientConcept, { ClientConceptDB, ClientConceptDto } from './ClientConcept';
import { getConceptLocalName } from '../utils/ModelUtils';

export enum ConceptClass {
	LAB_SET = 'LabSet',
	TEST = 'Test',
	FINDING = 'Finding',
	MISC = 'Misc',
}

export enum ConceptDataType {
	NUMERIC = 'Numeric',
	TEXT = 'Text',
	CODED = 'Coded',
}

export interface ConceptDB extends BaseMetadataDB {
	bh_concept_uu: string;
	bh_display_name: string;
	bh_concept_class: string;
	bh_concept_extra: ConceptExtraDB;
	bh_concept_mapping: ConceptMappingDB; // child mappings (from this concept to another one)
	'bh_concept_mapping::bh_oclid->bh_to_concept_code': ConceptMappingDB; // parent mappings (from another concept to this one)
	bh_client_concept: ClientConceptDB;
}

export interface ConceptDto extends BaseMetadataDto {
	conceptClass?: string;
	conceptType?: string;
	displayLocale?: string;
	displayName?: string;
	externalId?: string;
	oclId?: string;
	owner?: string;
	source?: string;
	dataType?: string;
	description?: string;
	conceptExtras?: ConceptExtraDto[];
	toConceptMappings?: ConceptMappingDto[];
	fromConceptMappings?: ConceptDto[];
	conceptNames?: ConceptNameDto[];
	clientConcepts?: ClientConceptDto[];
	name?: string;
	minimumValue?: number;
	maximumValue?: number;
}

export default class Concept extends BaseMetadata {
	conceptClass: string;
	conceptType: string;
	displayLocale: string;
	displayName: string;
	externalId: string;
	oclId: string;
	owner: string;
	source: string;
	dataType: string;
	description?: string;
	conceptExtras: ConceptExtra[];
	toConceptMappings: ConceptMapping[];
	fromConceptMappings: Concept[];
	conceptNames: ConceptName[];
	clientConcepts: ClientConcept[];
	name: string;
	minimumValue?: number;
	maximumValue?: number;

	constructor(props?: Partial<Concept | ConceptDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.conceptClass = props.conceptClass || '';
		this.conceptType = props.conceptType || '';
		this.displayLocale = props.displayLocale || '';
		this.displayName = props.displayName || '';
		this.externalId = props.externalId || '';
		this.oclId = props.oclId || '';
		this.owner = props.owner || '';
		this.source = props.source || '';
		this.dataType = props.dataType || '';
		this.description = props.description || undefined;
		this.conceptExtras = (props.conceptExtras || []).map((conceptExtra) => new ConceptExtra(conceptExtra));
		this.toConceptMappings = (props.toConceptMappings || []).map(
			(conceptMapping) => new ConceptMapping(conceptMapping),
		);
		this.fromConceptMappings = (props.fromConceptMappings || []).map((concept) => new Concept(concept));
		this.conceptNames = (props.conceptNames || []).map((conceptName) => new ConceptName(conceptName));
		this.clientConcepts = (props.clientConcepts || []).map((clientConcept) => new ClientConcept(clientConcept));

		this.name =
			props.name || (this.conceptClass === ConceptClass.LAB_SET ? 'Panel - ' : '') + getConceptLocalName(this);
		if (props.minimumValue !== undefined) {
			this.minimumValue = props.minimumValue;
		} else {
			const minValue = this.conceptExtras.find(
				(conceptExtra) => conceptExtra.key === ConceptExtraKey.LOW_ABSOLUTE,
			)?.value;
			this.minimumValue = minValue !== undefined ? Number(minValue) : undefined;
		}
		if (props.maximumValue !== undefined) {
			this.maximumValue = props.maximumValue;
		} else {
			const maxValue = this.conceptExtras.find(
				(conceptExtra) => conceptExtra.key === ConceptExtraKey.HI_ABSOLUTE,
			)?.value;
			this.maximumValue = maxValue !== undefined ? Number(maxValue) : undefined;
		}
	}
}
