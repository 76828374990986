import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';

interface CodedDiagnosisMappingDB extends BaseMetadataDB {
	bh_concept_code: string;
	bh_concept_name_resolved: string;
}

export interface CodedDiagnosisDB extends BaseMetadataDB {
	bh_cielname: string;
	bh_icd10who: string;
	bh_synonyms: string;
	bh_searchterms: string;
	bh_coded_diagnosis_mapping: CodedDiagnosisMappingDB;
}

export interface CodedDiagnosisDto extends BaseMetadataDto {
	cielName?: string;
	cielId?: number;
	conceptClass?: string;
	icd10?: string;
	synomedCT?: number;
	synomedNP?: number;
	synonyms?: string;
	description?: string;
	moh705aLessThan5?: string;
	moh705bGreaterThan5?: string;
	searchTerms?: string;
}

export default class CodedDiagnosis extends BaseMetadata {
	cielName: string;
	cielId?: number;
	conceptClass: string;
	icd10: string;
	synomedCT?: number;
	synomedNP?: number;
	synonyms: string;
	description: string;
	moh705aLessThan5: string;
	moh705bGreaterThan5: string;
	searchTerms: string;
	name: string;

	constructor(props?: Partial<CodedDiagnosis | CodedDiagnosisDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.cielName = props.cielName || '';
		this.cielId = props.cielId;
		this.conceptClass = props.conceptClass || '';
		this.icd10 = props.icd10 || '';
		this.synomedCT = props.synomedCT;
		this.synomedNP = props.synomedNP;
		this.synonyms = props.synonyms || '';
		this.description = props.description || '';
		this.moh705aLessThan5 = props.moh705aLessThan5 || '';
		this.moh705bGreaterThan5 = props.moh705bGreaterThan5 || '';
		this.searchTerms = props.searchTerms || '';

		this.name = this.cielName + (this.icd10 ? `, ICD ${this.icd10}` : '');
	}

	getDropdownDisplay() {
		return this.name;
	}

	condenseCodedDiagnosisRequestInVisit() {
		return { uuid: this.uuid };
	}
}
