import React from 'react';
import { ToastContainer } from 'react-toastify';
import './Layout.scss';
import LayoutBody from './LayoutBody';
import LayoutHeader from './LayoutHeader';
import LayoutMenu from './LayoutMenu';
import LayoutNotification from './LayoutNotification';
import LayoutTitle from './LayoutTitle';

const Layout = ({ children }: { children: React.ReactNode }) => (
	<>
		<ToastContainer hideProgressBar closeOnClick autoClose={5000} position="top-right" />
		{children}
	</>
);

export default Object.assign(Layout, {
	Body: LayoutBody,
	Header: LayoutHeader,
	Menu: LayoutMenu,
	Notification: LayoutNotification,
	Title: LayoutTitle,
});
