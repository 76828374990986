import { createContext } from 'react';

export interface MenuContextInterface {
	activeMenuUuid: string;
	selectedMenuUuids: string[];
	setSelectedMenuUuids: (selectedMenuUuids: string[]) => void;
	activeMenuUuidFamily: string[];
}

const MenuContext = createContext<MenuContextInterface | null>(null);

export default MenuContext;
