import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Concept, { ConceptDto } from './Concept';

export enum EncounterDiagnosticStatusCode {
	PENDING = 'P',
	COMPLETE = 'C',
}

export interface EncounterDiagnosticDB extends BaseMetadataDB {}

export interface EncounterDiagnosticDto extends BaseMetadataDto {
	concept?: ConceptDto | null;
	status?: string;
	value?: string;
	lineNo?: number;
}

export default class EncounterDiagnostic extends BaseMetadata {
	concept?: Concept;
	status: string;
	value: string;
	lineNo: number;

	constructor(props?: Partial<EncounterDiagnostic | EncounterDiagnosticDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.concept = props.concept ? new Concept(props.concept) : undefined;
		this.status = props.status || '';
		this.value = props.value || '';
		this.lineNo = props.lineNo || 0;
	}
}
