import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { TableRowProps, UseRowSelectState, UseTableRowProps } from 'react-table';
import { toast } from 'react-toastify';
import { useAsync, useAsyncFn } from 'react-use';
import InventoryTransactionContext from '../../contexts/InventoryTransactionContext';
import { ProductRefreshContext } from '../../contexts/ProductRefreshContext';
import UserContext from '../../contexts/UserContext';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useService from '../../hooks/useService';
import useStateWithReset from '../../hooks/useStateWithReset';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import { DBFilter, Filter, ListPageState, Sort, StorageOnHand, StorageOnHandDB } from '../../models';
import { CLEAN_EXPIRED_STOCK } from '../../models/Report';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import BHTable, { BHTableProps } from '../BHTable/BHTable';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import StockUpdateModal from '../StockTake/StockUpdateModal';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';

const availableFilters = {
	[uiText.inventory.filter.ALL]: IS_ACTIVE as unknown as Filter<StorageOnHandDB>,
	[uiText.inventory.filter.WITH_STOCK]: DBFilter<StorageOnHandDB>()
		.property('qtyonhand')
		.isGreaterThan(0)
		.property('isactive')
		.equals(true)
		.and(
			DBFilter<StorageOnHandDB>()
				.or(
					DBFilter<StorageOnHandDB>()
						.nested('m_attributesetinstance')
						.property('guaranteeDate')
						.isGreaterThanOrEqualTo(new Date())
						.up(),
				)
				.or(DBFilter<StorageOnHandDB>().nested('m_attributesetinstance').property('guaranteeDate').isNull().up()),
		),
	[uiText.inventory.filter.EXPIRED_PRODUCTS]: DBFilter<StorageOnHandDB>()
		.nested('m_attributesetinstance')
		.property('guaranteeDate')
		.isLessThanOrEqualTo(new Date())
		.up()
		.property('isactive')
		.equals(true),
} as const;

type InventoryListProps = {
	showSearch?: boolean;
	showCleanUpButton?: boolean;
	productUuid?: string;
	className?: string;
	paginationClassName?: string;
} & Pick<BHTableProps<StorageOnHand, StorageOnHandDB>, 'columns'>;

const InventoryList = ({
	showSearch,
	showCleanUpButton,
	columns,
	productUuid,
	className,
	paginationClassName,
}: InventoryListProps) => {
	const { t } = useTranslation();
	const { storageOnHandService, warehouseService, reportService } = useService();

	const [viewModal, setViewModal] = useState(false);

	const [searchText, setSearchText] = useState('');
	const { willTrigger: willTriggerFilters, triggerUpdate: triggerFilterRefresh } = useTriggerUpdate();
	const {
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		areRefreshing,
		reset,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, sorted, totalRecordCount, rowProperties },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<StorageOnHand, StorageOnHandDB>({
		fetch: useCallback(
			(page?: number, size?: number, sorted?: Sort<StorageOnHandDB>[], filter?: Filter<StorageOnHandDB>) =>
				storageOnHandService.get(
					page,
					size,
					sorted &&
						sorted.map((sorting) =>
							sorting.id === 'shelflife' ? { ...sorting, id: 'm_attributesetinstance.guaranteedate' } : sorting,
						),
					filter,
				),
			[storageOnHandService],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.inventory.loading.FAIL));
				}
				exception({ description: `Inventory fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});
	const [selectedData, setSelectedData] = useState<StorageOnHand | undefined>();

	const { organization } = useContext(UserContext);
	const { triggerUpdate, willTrigger } = useContext(ProductRefreshContext);

	// Filter state
	const [listFilter, setListFilter, { reset: resetListFilter }] = useStateWithReset<keyof typeof availableFilters>(
		uiText.inventory.filter.WITH_STOCK,
	);
	const [warehouseFilter, setWarehouseFilter, { reset: resetWarehouseFilter }] = useStateWithReset('');

	const { value: warehouses = [] } = useAsync(
		async () => (await warehouseService.getWarehouseByOrg(organization.uuid)).results,
		[warehouseService, organization],
	);

	// Handle searching and filtering and refreshes
	useEffect(() => {
		let defaultFilter = DBFilter<StorageOnHandDB>().and(availableFilters[listFilter]);
		if (searchText) {
			defaultFilter.and(DBFilter<StorageOnHandDB>().nested('m_product').property('name').contains(searchText).up());
		}
		if (productUuid) {
			defaultFilter.and(
				DBFilter<StorageOnHandDB>().nested('m_product').property('m_product_uu').equals(productUuid).up(),
			);
		}

		if (warehouseFilter) {
			// We don't allow filtering of DB IDs
			defaultFilter.and(
				DBFilter<StorageOnHandDB>()
					.nested('m_locator')
					.nested('m_warehouse')
					.property('M_Warehouse_UU')
					.equals(warehouseFilter)
					.up()
					.up(),
			);
		}

		onFilterUpdate(defaultFilter);
	}, [searchText, onFilterUpdate, listFilter, warehouseFilter, willTriggerFilters, willTrigger, productUuid]);

	useRefreshOnRepeatedRoute(() => {
		resetListFilter();
		resetWarehouseFilter();
		triggerFilterRefresh();
		reset(false);
	});

	const [{ loading: isStockBeingCleaned }, clean] = useAsyncFn(
		async () =>
			reportService
				.runProcess(CLEAN_EXPIRED_STOCK)
				.then(() => {
					toast.success(t(uiText.inventory.STOCK_CLEANED));
				})
				.catch((exceptionMessage) => {
					toast.error(t(uiText.inventory.ERROR_CLEANING));
					exception(t(uiText.inventory.ERROR_CLEANING + exceptionMessage));
				})
				.finally(() => {
					setViewModal(false);
					refresh();
				}),
		[reportService],
	);

	const { disableWrite } = useActionPrivileges(pageUuid.INVENTORY);

	const tableRowProperties = (
		_state?: UseRowSelectState<StorageOnHand>,
		rowInfo?: UseTableRowProps<StorageOnHand>,
	): Partial<TableRowProps> & React.HTMLProps<HTMLTableRowElement> => {
		if (!rowInfo) {
			return {};
		}
		return {
			...rowProperties,
			onClick: (e) => {
				setSelectedData(rowInfo.original);
				rowProperties(_state, rowInfo).onClick?.(e);
			},
		};
	};

	return (
		<InventoryTransactionContext.Provider value={{ refresh, areRefreshing }}>
			{isStockBeingCleaned && <LoadSpinner title={t(uiText.inventory.PROCESSING)} />}
			<WorkspaceMenu>
				{showSearch && <WorkspaceMenu.Search onSearch={setSearchText} />}
				<WorkspaceMenu.Filters>
					<Form.Group controlId="itemsFilter">
						<Form.Label column>{t(uiText.inventory.filter.SHOW_ITEMS)}</Form.Label>
						<Form.Select
							className="ms-2 w-auto d-inline-block"
							value={listFilter}
							onChange={(e) => setListFilter(e.target.value)}
						>
							{Object.entries(availableFilters).map(([filter]) => (
								<option key={filter} value={filter}>
									{t(filter)}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					{warehouses.length > 1 && (
						<Form.Group controlId="storeroomFilter">
							<Form.Label column>{t(uiText.inventory.filter.SHOW_STOREROOMS)}</Form.Label>
							<Form.Select
								className="ms-2 w-auto d-inline-block"
								value={warehouseFilter}
								onChange={(e) => setWarehouseFilter(e.target.value)}
							>
								<option value="">{t(uiText.inventory.filter.ALL)}</option>
								{warehouses.map((warehouse) => (
									<option key={warehouse.uuid} value={warehouse.uuid}>
										{warehouse.name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					)}
				</WorkspaceMenu.Filters>
				<Col xs="auto" className="my-2 ms-auto flex-shrink-0">
					{!disableWrite && showCleanUpButton && (
						<BasicButton
							name={uiText.inventory.button.CLEAN}
							text={t(uiText.inventory.button.CLEAN)}
							active={true}
							icon="trash"
							onClick={() => setViewModal(true)}
							variant="danger"
						/>
					)}
				</Col>
			</WorkspaceMenu>
			<Row className="bg-white ms-0">
				{!disableWrite && viewState === ListPageState.ADD_EDIT && selectedData && (
					<StockUpdateModal
						selectedData={selectedData}
						onFinish={(refreshData: boolean) => {
							setViewState(ListPageState.LIST);
							setSelectedData(undefined);
							if (refreshData) {
								refresh();
								triggerUpdate();
							}
						}}
					/>
				)}

				<BHTable<StorageOnHand, StorageOnHandDB>
					data={data}
					columns={columns}
					defaultPageSize={pageSize}
					pages={pages}
					page={page}
					pageSizeOptions={pageSizeOptions}
					LoadingComponent={() => {
						return <LoadSpinner show={isLoading} title={t(uiText.inventory.loading.LOADING)} />;
					}}
					onFetchData={onTableUpdate}
					getTrGroupProps={tableRowProperties}
					sortBy={sorted}
					totalRecordCount={totalRecordCount}
					className={className}
					paginationClassName={paginationClassName}
				/>
			</Row>
			<Modal show={viewModal}>
				<Modal.Header>{t(uiText.inventory.button.CLEAN)}</Modal.Header>
				<Modal.Body>{t(uiText.inventory.CLEAN_CONFIRMATION_MESSAGE)}</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={() => setViewModal(false)}>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button variant="success" className="ms-auto" data-dismiss="modal" onClick={clean}>
						{t(uiText.modal.button.CONFIRM)}
					</Button>
				</Modal.Footer>
			</Modal>
		</InventoryTransactionContext.Provider>
	);
};

export default InventoryList;
