import { cloneDeep } from 'lodash';
import { formatDate, formatDateAndTime } from '../utils/DateUtil';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import BusinessPartner, { BusinessPartnerDB, BusinessPartnerDto } from './BusinessPartner';
import Invoice, { InvoiceDto } from './Invoice';
import Order, { OrderDB, OrderDto } from './Order';
import Payment, { PaymentDto } from './Payment';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import User, { UserDB, UserDto } from './User';
import VoidedReason, { VoidedReasonDto } from './VoidedReason';
import Encounter, { EncounterDto } from './Encounter';

export interface VisitDB extends BaseEntityDB {
	bh_visitdate: Date;
	bh_patienttype: string;
	bh_process_stage: string;
	bh_visit_uu: string;
	bh_patientid: string;
	'c_bpartner::patient_id': BusinessPartnerDB;
	'ad_user::bh_clinician_user_id': UserDB;
	c_order: OrderDB;
	'$date(bh_visitdate)': Date;
	documentno: string;
}

export interface VisitDto extends BaseEntityDto {
	newVisit?: boolean;
	patientType?: ReferenceListDto;
	referral?: ReferenceListDto;
	status?: string;
	patient: BusinessPartnerDto;
	clinician?: UserDto;
	processStage?: ReferenceListDto;
	referredFromTo?: string;
	visitDate?: number;
	encounters?: EncounterDto[];
	orders?: OrderDto[];
	invoices?: InvoiceDto[];
	payments?: PaymentDto[];
	voidedReason?: VoidedReasonDto;
	documentNumber?: string | null;
}

/**
 * The Visit model for visit data
 * @property {Date} visitDate The date the visit is for
 */
export default class Visit extends BaseEntity {
	newVisit?: boolean;
	patientType: ReferenceList;
	referral: ReferenceList;
	status?: string;
	patient: BusinessPartner;
	clinician: User;
	processStage: ReferenceList;
	referredFromTo?: string;
	visitDate: Date;
	encounters: Encounter[];
	orders: Order[];
	invoices: Invoice[];
	payments: Payment[];
	voidedReason: VoidedReason;
	documentNumber?: string;

	/**
	 * The visit date, but formatted
	 */
	get visitDateFormatted() {
		return formatDate(this.visitDate);
	}
	get visitDateAndTimeFormatted() {
		return formatDateAndTime(this.visitDate);
	}

	/**
	 * The constructor for this class
	 * @param {Visit} props Properties to assign to this model
	 */
	constructor(props: Partial<Visit | VisitDto> = {}) {
		props = cloneDeep(props);
		super(props);

		this.newVisit = props.newVisit;
		this.patientType = new ReferenceList(props.patientType || {});
		this.referral = new ReferenceList(props.referral || {});
		this.status = props.status;
		this.patient = new BusinessPartner(props.patient || {});
		this.clinician = new User(props.clinician || {});
		this.processStage = new ReferenceList(props.processStage || {});
		this.referredFromTo = props.referredFromTo;
		this.visitDate = (props.visitDate && new Date(props.visitDate)) || new Date();
		this.encounters = (props.encounters || []).map((encounter) => new Encounter(encounter));
		this.orders = (props.orders || []).map((order) => new Order(order));
		this.invoices = (props.invoices || []).map((invoice) => new Invoice(invoice));
		this.payments = (props.payments || []).map((payment) => new Payment(payment));
		this.voidedReason = !props.voidedReason ? new VoidedReason({ uuid: '' }) : new VoidedReason(props.voidedReason);
		this.documentNumber = props.documentNumber || undefined;
	}
}
