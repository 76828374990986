import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';
import BaseMetadata, { BaseMetadataDto } from './BaseMetadata';
import BusinessPartnerSpecificPayerInformation, {
	BusinessPartnerSpecificPayerInformationDto,
} from './BusinessPartnerSpecificPayerInformation';
import Charge, { ChargeDto } from './Charge';
import OrderLine, { OrderLineDto } from './OrderLine';
import Product, { ProductDto } from './Product';

export interface InvoiceLineDto extends BaseMetadataDto {
	invoiceId?: number | null;
	description?: string;
	price: number;
	quantity: number;
	lineNetAmount?: number;
	product?: ProductDto;
	charge?: ChargeDto | null;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	orderLine?: OrderLineDto | null;
	businessPartnerSpecificPayerInformationList?: BusinessPartnerSpecificPayerInformationDto[] | null;
}

export default class InvoiceLine extends BaseMetadata {
	price?: number;
	quantity: number;
	product?: Product;
	description: string;
	charge?: Charge;
	attributeSetInstance?: AttributeSetInstance;
	orderLine?: OrderLine;
	businessPartnerSpecificPayerInformationList: BusinessPartnerSpecificPayerInformation[];

	get lineNetAmount() {
		return this.quantity * (this.price || 0) || 0;
	}

	constructor(props?: DeepPartial<InvoiceLine | InvoiceLineDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.price = props.price;
		this.quantity = props.quantity || 0;
		this.product = props.product ? new Product(props.product) : undefined;
		this.description = props.description || '';
		this.charge = props.charge ? new Charge(props.charge) : undefined;
		this.attributeSetInstance = props.attributeSetInstance
			? new AttributeSetInstance(props.attributeSetInstance)
			: undefined;
		this.orderLine = props.orderLine ? new OrderLine(props.orderLine) : undefined;
		this.businessPartnerSpecificPayerInformationList = (props.businessPartnerSpecificPayerInformationList || []).map(
			(businessPartnerSpecificPayerInformation) =>
				new BusinessPartnerSpecificPayerInformation(businessPartnerSpecificPayerInformation || {}),
		);
	}
}
