import { createContext } from 'react';
import { WindowAccessLevel } from '../models/AuthorizationResponse';
import Client from '../models/Client';
import Organization from '../models/Organization';
import Role from '../models/Role';
import User from '../models/User';
import Warehouse from '../models/Warehouse';

export type UpdateData = Omit<Partial<UserContextInterface>, 'update' | 'clear'>;

export interface UserContextInterface {
	client: Client;
	organization: Organization;
	role: Role;
	warehouse: Warehouse;
	user: User;
	windowAccessLevels: { [pageUuid: string]: WindowAccessLevel };
	availableClients: Client[];
	update: (newData: UpdateData) => void;
	clear: () => void;
}

const UserContext = createContext<UserContextInterface>({} as UserContextInterface);

export default UserContext;
