import React, { forwardRef } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

/**
 * This component adds a second field for the textarea that can show up when printing. Textareas respect their
 * sizing when printing, meaning some text may not be visible. This copies that text to a div that shows up on printing.
 *
 * @param props Props to assign to the textarea element
 * @returns A textarea and something that can be printed
 */
const PrintableTextArea = forwardRef<
	HTMLTextAreaElement,
	Omit<React.HTMLProps<HTMLTextAreaElement>, 'value'> & Omit<FormControlProps, 'as'>
>(({ className, value, ...props }, ref) => (
	<>
		<Form.Control {...props} as="textarea" value={value} className={`${className || ''} screen`} ref={ref} />
		<div className={`form-control ${className || ''} print print__whitespace-pre ${value ? 'print__h-auto' : ''}`}>
			{value}
		</div>
	</>
));

export default PrintableTextArea;
