import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

export type BasicButtonProps = {
	classes?: string;
	active: boolean;
	text?: string;
} & ButtonProps &
	Omit<FontAwesomeIconProps, 'onClick'>;

const BasicButton = ({ classes, icon, active, onClick, text, ...extraProps }: BasicButtonProps) => {
	return (
		<Button {...extraProps} type="button" style={{ display: active ? 'block' : 'none' }} onClick={onClick}>
			<FontAwesomeIcon icon={icon} className="me-2" />
			{text}
		</Button>
	);
};

export default BasicButton;
