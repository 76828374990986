import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import UserContext from '../../contexts/UserContext';
import { convertLocaleToBackend } from '../../models/Language';
import useService from '../../hooks/useService';
import { uiText } from '../../utils/Language';

type ChangePasswordModalProps = {
	serverErrorMessage?: string;
	onHandleClose: () => void;
};

const ChangePasswordModal = ({ onHandleClose, serverErrorMessage }: ChangePasswordModalProps) => {
	const [newPassword, setNewPassword] = useState('');
	const [retypedNewPassword, setRetypedNewPassword] = useState('');
	const [errorMessageToDisplay, setErrorMessageToDisplay] = useState(serverErrorMessage);
	const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const { user } = useContext(UserContext);
	const { authService } = useService();
	const { t, i18n } = useTranslation();

	const arePasswordsValid = useCallback(
		(shouldDisplayErrors) => {
			if (!newPassword) {
				setErrorMessageToDisplay(t(uiText.changePassword.error.NO_NEW_PASSWORD_ENTERED));
				return false;
			}
			if (newPassword !== retypedNewPassword) {
				if (shouldDisplayErrors) {
					setErrorMessageToDisplay(t(uiText.changePassword.error.PASSWORDS_DIFFER));
				}
				return false;
			}
			setErrorMessageToDisplay('');
			return true;
		},
		[newPassword, retypedNewPassword, t],
	);

	useEffect(() => {
		arePasswordsValid(hasFormBeenSubmitted);
	}, [arePasswordsValid, hasFormBeenSubmitted, newPassword, retypedNewPassword]);

	useEffect(() => {
		setErrorMessageToDisplay(serverErrorMessage);
	}, [serverErrorMessage]);

	const onSubmitClick = async () => {
		setHasFormBeenSubmitted(true);
		if (!arePasswordsValid(true)) {
			return;
		}

		if (!isCurrentPasswordValid(true)) {
			return;
		}
		try {
			// Check if the selected username and password exists in any other clients,
			// and if so raise an error
			let checkOtherClients = await authService.loginCheckOtherClients(user.name, newPassword);
			if (checkOtherClients.data) {
				setErrorMessageToDisplay(t(uiText.manageUsers.error.ERROR_WITH_PASSWORD));
				return;
			}

			// Apply the new password
			const response = await authService.changePassword(
				user.name,
				currentPassword,
				newPassword,
				convertLocaleToBackend(t(i18n.language)),
			);

			if (response.data.status === 'OK') {
				onHandleClose();
				toast.success(t(uiText.changePassword.PASSWORD_CHANGED));
			} else {
				setErrorMessageToDisplay(t(uiText.login.error.ERROR_OCCURRED));
			}
		} catch (error) {
			setErrorMessageToDisplay(t(uiText.login.error.WRONG_PASSWORD));
		}
	};

	const updateInput = (stateUpdateFunction: (valueToSet: string) => void) => (event: React.ChangeEvent) => {
		stateUpdateFunction((event.target as HTMLInputElement).value);
	};

	const isCurrentPasswordValid = (shouldDisplayErrors: boolean) => {
		if (!currentPassword) {
			if (shouldDisplayErrors) {
				setErrorMessageToDisplay(t(uiText.changePassword.error.NO_CURRENT_PASSWORD));
			}
			return false;
		}
		return true;
	};

	return (
		<Modal show={true}>
			<Form>
				<Modal.Header>{t(uiText.login.changePassword.CHANGE)}</Modal.Header>
				<Modal.Body>
					<Row className="my-2">
						<Form.Label className="mb-0" htmlFor="currentPassword" xs={4}>
							{t(uiText.login.changePassword.CURRENT_PASSWORD)}
						</Form.Label>
						<Form.Control
							id="currentPassword"
							type="password"
							value={currentPassword}
							onChange={updateInput(setCurrentPassword)}
							placeholder={t(uiText.login.changePassword.CURRENT_PASSWORD)}
						/>
					</Row>
					<Row className="my-2">
						<Form.Label className="mb-0" htmlFor="currentPassword" xs={4}>
							{t(uiText.login.changePassword.NEW_PASSWORD)}
						</Form.Label>
						<Form.Control
							id="newPassword"
							type="password"
							value={newPassword}
							onChange={updateInput(setNewPassword)}
							placeholder={t(uiText.login.changePassword.NEW_PASSWORD)}
						/>
					</Row>
					<Row className="my-2">
						<Form.Label className="mb-0" htmlFor="retypedNewPassword" xs={4}>
							{t(uiText.login.changePassword.RETYPE_PASSWORD)}
						</Form.Label>
						<Form.Control
							id="retypedNewPassword"
							type="password"
							value={retypedNewPassword}
							onChange={updateInput(setRetypedNewPassword)}
							placeholder={t(uiText.login.changePassword.RETYPE_PASSWORD)}
						/>
					</Row>
					{errorMessageToDisplay ? (
						<Row>
							<Col xs={8} className="ms-auto pb-4 text-center">
								<span className="text-danger">{errorMessageToDisplay}</span>
							</Col>
						</Row>
					) : (
						''
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							onHandleClose();
						}}
					>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button variant="success" className="ms-auto" data-dismiss="modal" onClick={onSubmitClick}>
						{t(uiText.modal.button.CHANGE)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ChangePasswordModal;
