import React, { forwardRef, ReactNode } from 'react';
import { Form, FormSelectProps } from 'react-bootstrap';

type DynamicSelectProps = {
	isLoading: boolean;
	placeholder?: string;
	children: ReactNode;
} & Omit<FormSelectProps, 'as'> &
	Omit<React.HTMLProps<HTMLSelectElement>, 'as'>;

/**
 * This component is meant to be a coordination between React Hook Forms and dynamic select options. By default,
 * if the select option matching the default value supplied to React Hook Forms doesn't exist, the HTML shows the first
 * option in the dropdown. Even after subsequent loads, the selected option isn't updated to match what React Hook
 * Forms has registered as the value. This component takes care of that and ensures that, when the select options are
 * loaded, the correct value is displayed. Use it just like a normal select, aside from `isLoading` and `placeholder`.
 */
const DynamicSelect = forwardRef<HTMLSelectElement, DynamicSelectProps>(
	({ isLoading, placeholder, children, ...restOfProps }, ref) => {
		return isLoading ? (
			<Form.Select {...restOfProps} ref={() => {}}>
				<option value="">{placeholder}</option>
			</Form.Select>
		) : (
			<Form.Select {...restOfProps} ref={ref}>
				{children}
			</Form.Select>
		);
	},
);

export default DynamicSelect;
