import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import Window, { WindowDto } from './Window';

export interface EncounterTypeWindowDto extends BaseMetadataDto {
	encounterType?: ReferenceListDto | null;
	window?: WindowDto | null;
}

export interface EncounterTypeWindowDB extends BaseMetadataDB {}

export default class EncounterTypeWindow extends BaseMetadata {
	encounterType?: ReferenceList;
	window?: Window;

	constructor(props: Partial<EncounterTypeWindowDto | EncounterTypeWindow> = {}) {
		props = cloneDeep(props);
		super(props);

		this.encounterType = props.encounterType ? new ReferenceList(props.encounterType) : undefined;
		this.window = props.window ? new Window(props.window) : undefined;
	}
}
