import { cloneDeep } from 'lodash';
import { formatDate } from '../utils/DateUtil';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import DocumentStatus, { DocumentStatusValue } from './DocumentStatus';
import MovementLine, { MovementLineDB, MovementLineDto } from './MovementLine';
import User, { UserDB, UserDto } from './User';
import Warehouse, { WarehouseDto } from './Warehouse';

export const movementTypes = {
	CUSTOMER_SHIPMENT: 'C-',
	CUSTOMER_RETURNS: 'C+',
	VENDOR_RECEIPTS: 'V+',
	VENDOR_RETURNS: 'V-',
	INVENTORY_OUT: 'I-',
	INVENTORY_IN: 'I+',
	MOVEMENT_FROM: 'M-',
	MOVEMENT_TO: 'M+',
};
export interface MovementDB extends BaseMetadataDB {
	'm_warehouse::bh_from_warehouse_id.m_warehouse_uu': string;
	'm_warehouse::bh_to_warehouse_id.m_warehouse_uu': string;
	'ad_user::createdby->ad_user_id': UserDB;
	bh_from_warehouse_id: number;
	bh_to_warehouse_id: number;
	docStatus: string;
	movementDate: Date;
	m_movementline: MovementLineDB;
	description: string;
	user: User;
	'm_warehouse.m_warehouse_uu': string;
	m_movement_uu: string;
}

export interface MovementDto extends BaseMetadataDto {
	description?: string;
	fromWarehouse?: WarehouseDto;
	toWarehouse?: WarehouseDto;
	movementLines?: MovementLineDto[];
	docStatus?: string;
	movementDate?: string;
	user?: UserDto;
}

export default class Movement extends BaseMetadata {
	fromWarehouse: Warehouse;
	toWarehouse: Warehouse;
	movementLines: MovementLine[];
	documentStatus: DocumentStatusValue;
	movementDate?: Date;
	description: string;
	user: User;

	/**
	 * format movement date
	 */
	get movementDateFormatted() {
		return formatDate(this.movementDate);
	}

	constructor(props?: Partial<Movement | MovementDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.fromWarehouse = new Warehouse(props.fromWarehouse);
		this.toWarehouse = new Warehouse(props.toWarehouse);
		this.movementLines = (props.movementLines || []).map((movementLine) => new MovementLine(movementLine));
		if ('documentStatus' in props) {
			this.documentStatus = props.documentStatus || DocumentStatus.DRAFTED;
		} else if ('docStatus' in props) {
			this.documentStatus = (props.docStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
		} else {
			this.documentStatus = DocumentStatus.DRAFTED;
		}

		if (typeof props.movementDate === 'string') {
			this.movementDate = new Date(props.movementDate);
		} else if (props.movementDate instanceof Date) {
			this.movementDate = props.movementDate;
		}

		this.description = '';
		if ('description' in props) {
			this.description = props.description || '';
		}

		this.user = new User(props.user);
	}
}
