import axios from 'axios';
import { isArray } from 'lodash';
import { BaseEntityDB } from '../models/BaseEntity';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from '../models/BaseMetadata';
import DBFilter, { Filter } from '../models/DBFilter';
import Response from '../models/Response';
import Sort from '../models/Sort';
import { configs } from '../utils/Configs';
import { createPagingParameters } from '../utils/Pagination';
import BaseService from './BaseService';

/**
 * This class is not meant to be used directly, but is meant to be extended and the children classes should be used
 * @template T The data type this service returns that extends from BaseMetadata
 * @template S An optional data type for the transfer object that extends from BaseMetadataDto
 * @template P A type to define the list of properties (and their types) available to sort/filter on
 */
export default abstract class BaseEntityService<
	T extends BaseMetadata,
	S extends BaseMetadataDto = BaseMetadataDto,
	P extends BaseMetadataDB = BaseMetadataDB,
> extends BaseService<T, S> {
	protected abstract entityName: string;
	public abstract entityTableName: string;

	/**
	 * Perform a request to the specified URL with paging, sizing, and sorting
	 * @param page The page number of results to fetch
	 * @param size The page size of the results to fetch
	 * @param sorted The sorting information of what to fetch
	 * @param filter The filtering information of what to fetch
	 */
	async get(page?: number, size?: number, sorted?: Sort<P>[], filter?: Filter<P>): Promise<Response<T>> {
		return axios
			.get<Response<S>>(`${configs.apiUrl}/${this.entityName}${createPagingParameters(page, size, sorted, filter)}`)
			.then(({ data }) => {
				let mappedData: T[] = [];
				if (data && isArray(data.results)) {
					mappedData = data.results.map(this.map);
				}
				return new Response<T>({ ...data, results: mappedData });
			});
	}

	/**
	 * Fetch an entity by it's UUID
	 * @param {string} uuid The UUID of the entity to fetch
	 */
	async getByUuid(uuid: string): Promise<T> {
		return axios.get<S>(`${configs.apiUrl}/${this.entityName}/${uuid}`).then(({ data }) => this.map(data));
	}

	/**
	 * Perform a POST request to save the specified data
	 * @param {data} data The data to post to the specified URL
	 */
	async save(data: T): Promise<T> {
		return axios
			.post<S>(`${configs.apiUrl}/${this.entityName}`, this.reverseMap(data))
			.then(({ data }) => this.map(data));
	}

	/**
	 * Delete an entity from the DB
	 * @param {string} uuid Id of the entity to delete
	 */
	async deleteByUuid(uuid: string): Promise<boolean> {
		return axios.delete<boolean>(`${configs.apiUrl}/${this.entityName}/${uuid}`).then(({ data }) => data);
	}

	/**
	 * Delete batch entities by uuids
	 * @param {string[[]]} uuids of the entities to be deleted
	 */
	async delete(uuids: string[]): Promise<boolean> {
		return axios
			.delete<boolean>(`${configs.apiUrl}/${this.entityName}?${uuids?.map((uuid) => `uuids=${uuid}`).join('&')}`)
			.then(({ data }) => data);
	}

	getDefaultSearchFilter(query: string): Filter<P> {
		return DBFilter<BaseEntityDB>().property('name').contains(query) as unknown as Filter<P>;
	}
}
