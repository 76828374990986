import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';

export interface BusinessPartnerSpecificPayerInformationDto extends BaseEntityDto {
	payerInformationFieldUuid: string;
}

export default class BusinessPartnerSpecificPayerInformation extends BaseEntity {
	payerInformationFieldUuid: string;
	isFilledFromPatient?: string;

	constructor(
		entity: Partial<BusinessPartnerSpecificPayerInformation | BusinessPartnerSpecificPayerInformationDto> = {},
	) {
		entity = cloneDeep(entity);
		super(entity);

		this.payerInformationFieldUuid = entity.payerInformationFieldUuid || '';
	}
}
