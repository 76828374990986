import { ApolloClient } from '@apollo/client';
import {
	Ad_ProcessGetForRunningReportsDocument,
	Ad_ProcessRunAndExportDocument,
	ProcessInfoParameterInput,
	ReportOutput,
} from '../graphql/__generated__/graphql';

/**
 * Generate an iDempiere report where only one parameter is needed
 * @param graphqlClient The GraphQL client to use
 * @param reportUU The UUID of the report to run
 * @param parameterValue The value to pass to the report
 * @param reportOutput The format to output the report in
 */
export async function generateReportWithGivenParameterValue(
	graphqlClient: ApolloClient<object>,
	reportUU: string,
	parameterValue: string,
	reportOutput: ReportOutput,
) {
	const process = (
		await graphqlClient.query({
			query: Ad_ProcessGetForRunningReportsDocument,
			fetchPolicy: 'cache-first',
		})
	).data.AD_ProcessGet.Results.find((process) => process.UU === reportUU);
	if (!process) {
		throw new Error('process with UU ' + reportUU + ' not found');
	}
	if (!process.AD_Process_ParaList?.length || process.AD_Process_ParaList.length > 1) {
		throw new Error('This report needs values specified for more than one parameter');
	}
	return runAndExportReport(graphqlClient, process.UU, reportOutput, [
		{ AD_Process: { UU: process.UU }, ParameterName: process.AD_Process_ParaList[0].Name, Parameter: parameterValue },
	]);
}

/**
 * Run a process and return the file it exports in the given format
 * @param graphqlClient The GraphQL client to fetch data with
 * @param reportUU The report to run
 * @param reportType What file type should be exported
 * @param processInformationParameterList Any process information parameters to use in the run
 * @returns A promise with the report as a Blob
 */
export async function runAndExportReport(
	graphqlClient: ApolloClient<object>,
	reportUU: string,
	reportType?: ReportOutput,
	processInformationParameterList?: ProcessInfoParameterInput[],
): Promise<Blob> {
	reportType = reportType || ReportOutput.Pdf;
	const reportString = (
		await graphqlClient.mutate({
			mutation: Ad_ProcessRunAndExportDocument,
			variables: {
				UU: reportUU,
				ProcessInfoParameterList: processInformationParameterList,
				ReportType: reportType,
			},
		})
	).data?.AD_ProcessRunAndExport;
	if (!reportString || !reportString.includes(',')) {
		throw Error(`report didn't run`);
	}

	return await (await fetch(reportString)).blob();
}
