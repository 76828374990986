import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError } from 'axios';
import React, { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAsync, useAsyncFn, useAsyncRetry } from 'react-use';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import VisitContext from '../../contexts/VisitContext';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useDocumentActionInformation from '../../hooks/useDocumentActionInformation';
import useIsDocumentActionAvailable from '../../hooks/useIsDocumentActionAvailable';
import useReportAccess from '../../hooks/useReportAccess';
import useService from '../../hooks/useService';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BusinessPartner,
	Encounter,
	EncounterDiagnosis,
	EncounterDiagnostic,
	fieldUuid,
	Invoice,
	InvoiceLine,
	Observation,
	Order,
	Payment,
	Warehouse,
} from '../../models';
import BusinessPartnerPayerInformation from '../../models/BusinessPartnerPayerInformation';
import DocAction, { DocumentActionValue } from '../../models/DocAction';
import DocumentStatus, { DocumentStatusValue } from '../../models/DocumentStatus';
import { documentBaseType, documentSubTypeSalesOrder } from '../../models/DocumentType';
import OrderLine from '../../models/OrderLine';
import PaymentInformation from '../../models/PaymentInformation';
import ProcessType from '../../models/ProcessType';
import { ReferenceListDto } from '../../models/ReferenceList';
import {
	PATIENT_RECEIPT,
	PATIENT_VISIT_SUMMARY,
	VISIT_INVOICE,
	VISIT_INVOICE_PARAM_BH_VISIT_UU,
	VISIT_INVOICE_PARAM_SHOW_INSURANCE,
} from '../../models/Report';
import ReportParameterValue from '../../models/ReportParameterValue';
import Visit from '../../models/Visit';
import { pageUuid } from '../../services/AuthService';
import { ReportTypes } from '../../services/ReportService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import ConfirmAction from '../../utils/ConfirmAction';
import { REFRESH_PAGE, VISITS_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { getVisitNegativeInventoryErrorInformation } from '../../utils/ModelUtils';
import {
	isEntityCompleted,
	isEntityDrafted,
	isEntityNew,
	isEntityReactivated,
	isEntityVoided,
} from '../../utils/StatusUtil';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import CustomPrompt from '../CustomPrompt/CustomPrompt';
import { withFormModalSuspsenseWrapper } from '../HOCs/withFormModalSuspsenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import SaveAndSendToModal from '../Modal/SaveAndSendToModal';
import VisitCompleteConfirmationModal from '../Modal/VisitCompleteConfirmationModal';
import VoidedReasonModal from '../Modal/VoidedReasonModal';
import ReceiptPrint from '../Reports/ReceiptPrint';
import ClinicalDetails from './ClinicalDetails';
import LabDiagnosticsDetails from './LabDiagnosticsDetails';
import PaymentLineItemTable from './PaymentLineItemTable';
import ProductLineItemTable from './ProductLineItemTable';
import TriageDetails from './TriageDetails';
import VisitDetailsEdit from './VisitDetailsEdit';
import VisitInfo from './VisitInfo';
import { prepareVisit } from './VisitUtil';

export const NonModelFormField = {
	SUBMIT_EVENT: 'submitEvent',
} as const;

export type VisitFormFields = {
	vitalsEncounters: Array<{ observations?: Observation[] } & Omit<Encounter, 'createdDateFormatted'>>;
	clinicalDetailsEncounters: Array<{ observations?: Observation[] } & Omit<Encounter, 'createdDateFormatted'>>;
	labDiagnosticsEncounters: Array<{ observations?: Observation[] } & Omit<Encounter, 'createdDateFormatted'>>;
	chiefComplaintEncounter: Omit<Encounter, 'createdDateFormatted'>;
	encounterDiagnoses?: Array<EncounterDiagnosis & Omit<EncounterDiagnosis, 'createdDateFormatted'>>;
	encounterDiagnostics?: Array<EncounterDiagnostic & Omit<EncounterDiagnostic, 'createdDateFormatted'>>;
	orders: Array<
		{ orderLinePayments?: OrderLine[]; documentStatus: DocumentStatusValue } & Omit<
			Order,
			'dateOrderedFormatted' | 'createdDateFormatted'
		>
	>;
	paymentInformationList: PaymentInformation[];
	businessPartnerPayerInformationList: BusinessPartnerPayerInformation[];
	[NonModelFormField.SUBMIT_EVENT]?: string;
	isVisitReactivated: boolean;
} & Omit<
	Visit,
	| 'visitDateFormatted'
	| 'visitDateAndTimeFormatted'
	| 'dateOrderedFormatted'
	| 'createdDateFormatted'
	| 'orders'
	| 'encounters'
>;

export const SubmitAction = {
	SAVE: 'Save Bill',
	COMPLETE: 'Complete Bill',
	PRINT_INVOICE: 'Print Invoice',
	PRINT_RECEIPT: 'Print Receipt',
	VOID: 'Void Bill',
	REACTIVATE: 'Reactivate Bill',
} as const;

export type VisitFormProps = {
	savedPatient?: BusinessPartner;
} & EntityFormProperties;

const fetchWarehouseOrderDocumentTypeArguments = [
	documentBaseType.SalesOrder,
	documentSubTypeSalesOrder.WarehouseOrder,
	true,
	false,
	false,
] as const;
const fetchARInvoiceDocumentTypeArguments = [documentBaseType.ARInvoice, null, true, false, false] as const;
const fetchReceiptDocumentTypeArguments = [documentBaseType.ARReceipt, null, true, false, false] as const;

const getTitle = (uuid?: string) => (uuid ? uiText.visit.UPDATE : uiText.visit.NEW);

const VisitForm = ({ uuid, onFinish, savedPatient, renderAsModal, canSaveMany }: VisitFormProps) => {
	const {
		encounterTypeWindowService,
		metadataService,
		reportService,
		visitService,
		documentTypeService,
		encounterService,
	} = useService();

	const { data: { encounterTypeWindows, visit: initialData } = { encounterTypeWindows: [], visit: undefined } } =
		useSuspenseAsync(uuid || 'visit-data-load', async () => {
			const encounterTypeWindows = await encounterTypeWindowService.get();
			let visit: Visit | undefined;
			if (uuid) {
				visit = await visitService.getByUuid(uuid);
			}
			return { encounterTypeWindows, visit };
		});

	const { t } = useTranslation();
	const { disableView } = useActionPrivileges(pageUuid.CLINICAL_DETAILS);
	const { disableView: disableTriageView, disableWrite: isDisabledTriageWrite } = useActionPrivileges(
		pageUuid.TRIAGE_DETAILS,
	);
	const { disableView: disableLabView, disableWrite: isDisabledLabWrite } = useActionPrivileges(
		pageUuid.LAB_DIAGNOSTICS_DETAILS,
	);
	const { canView: canViewProducts } = useActionPrivileges(pageUuid.PRODUCTS);
	const { canView: canViewPayments } = useActionPrivileges(pageUuid.PAYMENTS);
	const { disableWrite } = useActionPrivileges(pageUuid.VISITS);
	const canPrintInvoice = useReportAccess(VISIT_INVOICE);
	const [processingErrorMessage, setProcessingErrorMessage] = useState<string[]>();
	const [viewVoidModal, setViewVoidModal] = useState(false);
	const [showCompleteModal, setShowCompleteModal] = useState(false);
	const [showSaveAndSendToModal, setShowSaveAndSendToModal] = useState(false);
	const history = useHistory();

	const chiefComplaintEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.CHIEF_COMPLAINT,
	);

	const clinicalEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.CLINICAL_DETAILS,
	);

	const labDiagnosticsEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.LAB_DIAGNOSTICS_DETAILS,
	);

	const vitalsEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.TRIAGE_DETAILS,
	);

	const getNewVisit = (patient?: BusinessPartner) => {
		const businessPartner = new BusinessPartner(patient);
		const order = new Order({ businessPartner });
		return new Visit({
			patient: businessPartner,
			orders: [order],
			invoices: [
				new Invoice({
					...order,
					order: { uuid: order.uuid },
					invoiceLines: [],
					documentTypeTarget: undefined, // will be set later
				}),
			],
			encounters: [
				new Encounter({
					encounterType: clinicalEncounterTypeWindow?.encounterType,
					observations: [
						new Observation({
							field: clinicalEncounterTypeWindow?.window?.tabs?.[0].fields.find(
								(field) => field.uuid === fieldUuid.CLINICAL_NOTES,
							),
							value: t(uiText.visit.DEFAULT_CLINICAL_NOTES),
						}),
					],
				}),
			],
		});
	};

	const data = useRef(prepareVisit(initialData || getNewVisit(savedPatient), encounterTypeWindows));
	const isDataReadOnly = isEntityCompleted(data.current.orders[0]) || isEntityVoided(data.current.orders[0]);
	const title = getTitle(data.current.isNew ? undefined : data.current.uuid);

	const { client, warehouse } = useContext(UserContext);

	const adjustPageTitle = () => {
		document.title = t(uiText.report.PATIENT_SUMMARY);
	};
	const resetPageTitle = () => {
		document.title = t(uiText.report.WEBSITE_TITLE);
		removeEventPrintListeners();
	};
	const addEventPrintListeners = () => {
		window.addEventListener('beforeprint', adjustPageTitle);
		window.addEventListener('afterprint', resetPageTitle);
	};
	const removeEventPrintListeners = () => {
		window.removeEventListener('beforeprint', adjustPageTitle);
		window.removeEventListener('afterprint', resetPageTitle);
	};

	const processStageList: ReferenceListDto[] = useMemo(
		() => metadataService.getLocalProcessStageList(),
		[metadataService],
	);
	const { value: canGenerateReceipt = false } = useAsync(() =>
		reportService.doesUserHaveAccessToReport(PATIENT_RECEIPT),
	);
	const { value: canGeneratePatientVisitSummary = false } = useAsync(() =>
		reportService.doesUserHaveAccessToReport(PATIENT_VISIT_SUMMARY),
	);

	const [{ value: printReceiptUrl }, onPrintReport] = useAsyncFn(
		(reportUuid: string, reportParameters?: ReportParameterValue[] | undefined) => {
			if (reportParameters) {
				return reportService
					.generateReport(reportUuid, reportParameters, ReportTypes.PDF)
					.then((response) => {
						return URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
					})
					.catch((error) => {
						console.error(error);
						return '';
					});
			} else {
				// If no report parameters were specified, pass just a single parameter - data.current.uuid -
				// which is assigned the current bh_visit_uu, and have the function automatically assign
				// it to the first available parameter
				return reportService
					.generateReportWithGivenParameterValue(reportUuid, data.current.uuid, ReportTypes.PDF)
					.then((response) => {
						return URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
					})
					.catch((error) => {
						console.error(error);
						return '';
					});
			}
		},
		[],
	);

	const formMethods = useForm<FieldValues>({ defaultValues: data.current });
	useConfirmRefresh(formMethods.formState?.isDirty && !isDataReadOnly);

	const { value: canReactivateVisit = false } = useIsDocumentActionAvailable(
		ProcessType.BILL,
		data.current.orders[0].documentStatus,
		DocAction.REACTIVATE,
	);
	const { canVoidDocument, voidDocumentAction } = useDocumentActionInformation(
		ProcessType.BILL,
		data.current.orders[0]?.documentStatus,
	);

	const { toggleNavBlocking } = useContext(NavBlockingContext);
	const { value: warehouseOrderDocumentType, retry: fetchWarehouseOurderDocumentType } = useAsyncRetry(
		async () => documentTypeService.getDocumentBaseType(...fetchWarehouseOrderDocumentTypeArguments),
		[documentTypeService],
	);
	const { value: arInvoiceDocumentType, retry: fetchARInvoiceDocumentType } = useAsyncRetry(
		async () => documentTypeService.getDocumentBaseType(...fetchARInvoiceDocumentTypeArguments),
		[documentTypeService],
	);
	const { value: receiptDocumentType, retry: fetchReceiptDocumentType } = useAsyncRetry(
		async () => documentTypeService.getDocumentBaseType(...fetchReceiptDocumentTypeArguments),
		[documentTypeService],
	);

	const checkInvoiceHasInsurersOrDonors = () => {
		return data.current.paymentInformationList.some(
			(paymentInformation) => paymentInformation.isPayment !== 'true' && paymentInformation.isWaiver !== 'true',
		);
	};

	// We can't use the context because this form is finicky, so use a reference
	const { visitFormInformation } = useContext(FormModalContext);
	const reset = formMethods.reset;
	/**
	 * Validates and submits the visit via REST
	 * @param {Order} formData The data submitted from the visit form
	 */
	const [{ loading }, submitVisit] = useAsyncFn<SubmitHandler<VisitFormFields>>(
		async (formData) => {
			let warehouseOrderDocumentTypeToUse = warehouseOrderDocumentType;
			if (!warehouseOrderDocumentTypeToUse) {
				try {
					warehouseOrderDocumentTypeToUse = await documentTypeService.getDocumentBaseType(
						...fetchWarehouseOrderDocumentTypeArguments,
					);
					fetchWarehouseOurderDocumentType();
				} catch (error) {
					exception({ description: 'Could not load warehouse order document types: ' + error });
					toast.error(t(uiText.visit.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			let arInvoiceDocumentTypeToUse = arInvoiceDocumentType;
			if (!arInvoiceDocumentTypeToUse) {
				try {
					arInvoiceDocumentTypeToUse = await documentTypeService.getDocumentBaseType(
						...fetchARInvoiceDocumentTypeArguments,
					);
					fetchARInvoiceDocumentType();
				} catch (error) {
					exception({ description: 'Could not load A/R invoice document types: ' + error });
					toast.error(t(uiText.visit.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			let receiptDocumentTypeToUse = receiptDocumentType;
			if (!receiptDocumentTypeToUse) {
				try {
					receiptDocumentTypeToUse = await documentTypeService.getDocumentBaseType(
						...fetchReceiptDocumentTypeArguments,
					);
					fetchReceiptDocumentType();
				} catch (error) {
					exception({ description: 'Could not load A/R payment document types: ' + error });
					toast.error(t(uiText.visit.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			const visitFormAction = formData[NonModelFormField.SUBMIT_EVENT];

			// check save/ complete operation
			let visit = new Visit({
				...formData,
				orders: formData.orders?.map((order) => ({
					...order,
					documentTypeTarget: warehouseOrderDocumentTypeToUse,
					createdDateFormatted: '',
					dateOrderedFormatted: '',
				})),
				encounters: [
					...(formData.vitalsEncounters?.map((encounter) => ({
						...encounter,
						// Filter out observations that don't have values entered
						observations: encounter.observations.filter((observation) => !!observation.value),
						createdDateFormatted: '',
						encounterType: vitalsEncounterTypeWindow?.encounterType,
					})) || []),
					...(formData.clinicalDetailsEncounters?.map((encounter) => ({
						...encounter,
						// Filter out observations that don't have values entered
						observations: encounter.observations.filter((observation) => !!observation.value),
						createdDateFormatted: '',
						encounterType: clinicalEncounterTypeWindow?.encounterType,
						encounterDiagnoses:
							formData.encounterDiagnoses
								?.filter(
									(encounterDiagnosis) =>
										encounterDiagnosis.codedDiagnosis?.uuid || encounterDiagnosis.uncodedDiagnosis,
								)
								.map((encounterDiagnosis, index) => ({
									...encounterDiagnosis,
									createdDateFormatted: '',
									lineNo: index,
								})) || [],
					})) || []),
					...(formData.labDiagnosticsEncounters?.map((encounter) => ({
						...encounter,
						// Filter out observations that don't have values entered
						observations: encounter.observations.filter((observation) => !!observation.value),
						createdDateFormatted: '',
						encounterType: labDiagnosticsEncounterTypeWindow?.encounterType,
						encounterDiagnostics:
							formData.encounterDiagnostics?.filter((encounterDiagnostic) => encounterDiagnostic?.uuid) || [],
					})) || []),
				].filter((encounter) => !!encounter),
			});

			// add chief encounter is present
			if (formData.chiefComplaintEncounter.observations.some((observation) => !!observation.value)) {
				visit.encounters.push(
					new Encounter({
						...formData?.chiefComplaintEncounter,
						encounterType: chiefComplaintEncounterTypeWindow?.encounterType,
						observations: formData?.chiefComplaintEncounter?.observations.filter((observation) => !!observation.value),
					}),
				);
			}
			visit.orders.forEach((order) => (order.businessPartner = visit.patient));

			visit.encounters.forEach((encounter) => {
				encounter.encounterDiagnoses.forEach((encounterDiagnosis, index) => {
					encounterDiagnosis.lineNo = index;
				});

				encounter.encounterDiagnostics.forEach((encounterDiagnostic, index) => {
					encounterDiagnostic.lineNo = index;
				});
			});

			// We also need to create an invoice for this visit
			let patientInvoice = visit.invoices.find((invoice) => invoice.order?.uuid === visit.orders[0].uuid);
			if (!patientInvoice) {
				patientInvoice = new Invoice({
					...visit.orders[0],
					order: visit.orders[0],
					documentTypeTarget: arInvoiceDocumentTypeToUse,
					uuid: undefined, // don't copy the order's UUID
				});
				if (visit.invoices.length) {
					visit.invoices.push(patientInvoice);
				} else {
					visit.invoices = [patientInvoice];
				}
			} else {
				patientInvoice.documentTypeTarget = arInvoiceDocumentTypeToUse;
				patientInvoice.businessPartner = visit.patient;
			}
			// If something has broken, there may be duplicate invoices for an order - remove them
			const orderUuids = visit.orders.map((order) => order.uuid);
			visit.invoices = visit.invoices.filter((invoice) => !invoice.order || orderUuids.includes(invoice.order.uuid));
			// Remove any invoice lines relating to order lines that were deleted
			const orderLineUuids = visit.orders[0].orderLines.map((orderLine) => orderLine.uuid);
			patientInvoice.invoiceLines = patientInvoice.invoiceLines.filter(
				(invoiceLine) => !invoiceLine.orderLine || orderLineUuids.includes(invoiceLine.orderLine.uuid),
			);
			// If the order lines match, update it
			visit.orders[0].orderLines.forEach((orderLine) => {
				let invoiceLineToUse = patientInvoice!.invoiceLines.find(
					(invoiceLine) => invoiceLine.orderLine?.uuid === orderLine.uuid,
				);
				if (!invoiceLineToUse) {
					invoiceLineToUse = new InvoiceLine({
						...orderLine,
						orderLine: orderLine,
						charge: undefined, // no charges here!
						uuid: undefined,
					});
					patientInvoice!.invoiceLines.push(invoiceLineToUse);
				} else {
					// The selected product may have changed, so update it
					invoiceLineToUse.product = orderLine.product;
				}
				// If something has broken, there may be duplicate invoice lines per order line - remove them
				if (
					invoiceLineToUse &&
					patientInvoice!.invoiceLines.filter((invoiceLine) => invoiceLine.orderLine?.uuid === orderLine.uuid).length >
						1
				) {
					patientInvoice!.invoiceLines = patientInvoice!.invoiceLines.filter(
						(invoiceLine) =>
							(invoiceLine.uuid !== invoiceLineToUse?.uuid &&
								invoiceLine.orderLine?.uuid !== invoiceLineToUse?.orderLine?.uuid) ||
							invoiceLine.uuid === invoiceLineToUse?.uuid,
					);
				}
				invoiceLineToUse.quantity = orderLine.quantity;
				invoiceLineToUse.price = orderLine.price;
			});
			// If something has broken, there may be a visit with duplicate invoices for the patient - remove them
			// Since we've already filtered out reversed/voided invoices, keep the ones with a payer UUID or the only patient invoice
			visit.invoices = visit.invoices.filter(
				(invoice) =>
					invoice.businessPartner.uuid !== patientInvoice?.businessPartner.uuid || invoice.uuid === patientInvoice.uuid,
			);

			// Since all payments are handled through the payment information list, remove the payments
			visit.payments = [];

			// Add non-patient payments to invoice lines
			const arePaymentsPresent = (formData.paymentInformationList || []).length > 0;
			if (arePaymentsPresent || visitFormAction !== SubmitAction.COMPLETE) {
				// Get the total charged for this visit
				let runningTotal = visit.orders[0]?.orderLines.reduce(
					(runningTotal, orderLine) => runningTotal + (orderLine.price || 0) * (orderLine.quantity || 0),
					0,
				);
				let payerInvoiceLines: { [payerUuid: string]: InvoiceLine[] } = {};
				// We need to map the payments to payments or invoice lines
				// If the visit is being saved, don't save things coming from the patient's data
				const nonPatientPayments =
					formData.paymentInformationList?.filter((paymentInformation) => paymentInformation.isPayment !== 'true') ||
					[];
				// Either keep the ones assigned above or the UUIDs for the ones we're working with here
				const nonPatientPaymentInvoiceLineUuids = nonPatientPayments.map(
					(paymentInformation) => paymentInformation.uuid,
				);
				const previousNonPatientPaymentInvoiceLines = patientInvoice.invoiceLines.filter(
					(invoiceLine) => !!invoiceLine.charge?.uuid,
				);
				patientInvoice.invoiceLines = patientInvoice.invoiceLines.filter(
					(invoiceLine) => !invoiceLine.charge?.uuid || nonPatientPaymentInvoiceLineUuids.includes(invoiceLine.uuid),
				);
				nonPatientPayments.forEach((paymentInformation) => {
					let priceToUse = paymentInformation.amount || 0;
					// If the running total is smaller than the entered price, we'll take the remaining total
					if (runningTotal - priceToUse < 0) {
						priceToUse = runningTotal;
					}
					// If there's no price to use, we'll not save the amount
					if (priceToUse === 0) {
						return undefined;
					}
					runningTotal -= priceToUse;
					if (paymentInformation.isWaiver !== 'true') {
						payerInvoiceLines[paymentInformation.payer.uuid] = payerInvoiceLines[paymentInformation.payer.uuid] || [];
						payerInvoiceLines[paymentInformation.payer.uuid].push(
							new InvoiceLine({
								...paymentInformation,
								quantity: 1,
								price: priceToUse,
								lineNetAmount: priceToUse,
							}),
						);
					}
					const paymentInformationInvoiceLine = new InvoiceLine({
						...paymentInformation,
						quantity: 1,
						price: priceToUse * -1,
						lineNetAmount: priceToUse * -1,
						businessPartnerSpecificPayerInformationList: (
							paymentInformation.businessPartnerSpecificPayerInformationList || []
						).filter(
							(businessPartnerSpecificPayerInformation) =>
								visitFormAction !== SubmitAction.SAVE || !businessPartnerSpecificPayerInformation.isFilledFromPatient,
						),
						// The paymentInformation UUID should be for the insurer/donors invoice - we'll want a new one for the
						// invoice line generated on the client's invoice
						uuid: undefined,
					});
					// We'll have to use a roundabout way to identify what the original invoice line was on the patient's invoice
					// If we have a match on the charge & amount, we'll assume it's the same. Otherwise, we'll just create a new
					// line (and the old invoice line will just get deleted)
					let existingButCurrentlyRemovedPatientInvoiceLine = previousNonPatientPaymentInvoiceLines.find(
						(invoiceLine) =>
							invoiceLine.charge?.uuid === paymentInformation.charge.uuid &&
							invoiceLine.price === paymentInformationInvoiceLine.price,
					);
					// If they're isn't an existing invoice line, add one. Otherwise, update it
					if (!existingButCurrentlyRemovedPatientInvoiceLine) {
						patientInvoice?.invoiceLines.push(paymentInformationInvoiceLine);
					} else {
						// Update the existing line (without overriding the UUID)
						const { uuid, ...restOfPaymentInformationInvoiceLine } = paymentInformationInvoiceLine;
						Object.assign(existingButCurrentlyRemovedPatientInvoiceLine, restOfPaymentInformationInvoiceLine);
						// Since the patient's invoice lines were filtered above, we need to re-add this one
						patientInvoice?.invoiceLines.push(existingButCurrentlyRemovedPatientInvoiceLine);
					}
				});
				visit.payments.push(
					...((formData.paymentInformationList
						?.filter((paymentInformation) => paymentInformation.isPayment === 'true')
						.map((paymentInformation) => {
							let priceToUse = paymentInformation.amount || 0;
							// If the running total is smaller than the entered price, we'll take the remaining total
							if (runningTotal - priceToUse < 0) {
								priceToUse = runningTotal;
							}
							// If there's no price to use, we'll not save the amount
							if (priceToUse === 0) {
								return undefined;
							}
							runningTotal -= priceToUse;
							return new Payment({
								...paymentInformation,
								payAmount: priceToUse,
								tenderAmount: paymentInformation.amount,
								documentType: receiptDocumentTypeToUse,
							});
						})
						.filter((payment) => !!payment) as Payment[]) || []),
				);

				// For any payers, we need to submit separate invoices for them
				const payerUuidList = [
					...new Set(
						formData.paymentInformationList
							?.filter(
								(paymentInformation) =>
									paymentInformation.isPayment !== 'true' && paymentInformation.isWaiver !== 'true',
							)
							.map((paymentInformation) => paymentInformation.payer.uuid),
					),
				];
				payerUuidList.forEach((payerUuid) => {
					let payerInvoice = visit.invoices
						.filter(
							(invoice) =>
								invoice.documentStatus !== DocumentStatus.REVERSED && invoice.documentStatus !== DocumentStatus.VOIDED,
						)
						.find((invoice) => invoice.businessPartner.uuid === payerUuid);
					if (!payerInvoice) {
						payerInvoice = new Invoice({
							businessPartner: {
								uuid: payerUuid,
							},
							description: 'Auto-generated insurer/donor invoice',
							documentTypeTarget: { uuid: arInvoiceDocumentTypeToUse?.uuid },
						});
						visit.invoices.push(payerInvoice);
					}
					payerInvoice.invoiceLines = payerInvoiceLines[payerUuid];
				});

				// Remove any invoices assigned to payers that aren't in the payment information list
				const chargeUuidList = [
					...new Set(
						formData.paymentInformationList
							?.filter((paymentInformation) => paymentInformation.isPayment !== 'true')
							.map((paymentInformation) => paymentInformation.charge.uuid),
					),
				];
				visit.invoices = visit.invoices
					.filter(
						(invoice) =>
							invoice.businessPartner.uuid === visit.patient.uuid ||
							payerUuidList.includes(invoice.businessPartner.uuid),
					)
					.map((invoice) => {
						// Remove any invoice lines with charges not meant to be saved
						invoice.invoiceLines = invoice.invoiceLines.filter(
							(invoiceLine) => !invoiceLine.charge || chargeUuidList.includes(invoiceLine.charge.uuid),
						);
						return invoice;
					});
			}

			// set warehouse
			visit.orders[0].warehouse = new Warehouse({
				uuid: warehouse.uuid,
			});

			// delete encounters not in the visit object.
			const currentEncounterUuids = [
				...data.current.vitalsEncounters,
				...data.current.clinicalDetailsEncounters,
				...data.current.labDiagnosticsEncounters,
				data.current.chiefComplaintEncounter,
			]
				.filter((encounter) => !encounter || !encounter.isNew)
				.map((encounter) => encounter.uuid);
			const encounterUuidsToSave = visit.encounters.map((encounter) => encounter.uuid);
			const encounterUuidsToDelete = currentEncounterUuids?.filter(
				(encounterUuid) => !encounterUuidsToSave.includes(encounterUuid),
			);

			if (encounterUuidsToDelete?.length) {
				await encounterService.delete(encounterUuidsToDelete);
			}

			// check save/ complete operation
			let docAction: DocumentActionValue | undefined;
			if (visitFormAction === SubmitAction.COMPLETE) {
				docAction = DocAction.COMPLETE;
			} else if (visitFormAction === SubmitAction.VOID) {
				docAction = voidDocumentAction;
			} else if (visitFormAction === SubmitAction.REACTIVATE) {
				docAction = DocAction.REACTIVATE;
			}

			let serverResponse: Promise<Visit>;
			if (docAction) {
				if (docAction === DocAction.COMPLETE) {
					serverResponse = visitService.saveAndProcess(visit, docAction);
				} else {
					serverResponse = visitService.process(visit.uuid, docAction);
				}
			} else {
				serverResponse = visitService.save(visit);
			}
			return serverResponse
				.then((response) => {
					// There has been a time when a visit response returns, but has not visit
					if (response.isNew) {
						exception({ description: `Visit ${visit.uuid} saved but no response returned` });
					}
					const transformedVisit = prepareVisit(response.isNew ? visit : response, encounterTypeWindows);
					data.current = transformedVisit;
					reset(transformedVisit);
					visitFormInformation.current.wasDataSaved = true;
					if (canSaveMany === false) {
						visitFormInformation.current.savedData = transformedVisit.uuid;
					}
				})
				.catch((error: Error | AxiosError) => {
					if (axios.isAxiosError(error) && error.response?.status === 400) {
						let transformedMessage = [error.response.data as string];
						const negativeInventoryInformation = getVisitNegativeInventoryErrorInformation(error.response.data);
						if (negativeInventoryInformation.length) {
							transformedMessage = [
								t(uiText.visit.NEGATIVE_INVENTORY_DISALLOWED),
								'',
								...negativeInventoryInformation.map(({ productName, inventoryShortageAmount }) =>
									t(uiText.visit.SELLING_MORE_OF_PRODUCT_THAN_AVAILABLE_QUANTITY, {
										productName,
										inventoryShortageAmount,
									}),
								),
							];
						}
						setProcessingErrorMessage(transformedMessage);
					}
					exception({ description: `Visit save error: ${error}` });
				});
		},
		[
			voidDocumentAction,
			visitService,
			reset,
			documentTypeService,
			warehouseOrderDocumentType,
			warehouse,
			clinicalEncounterTypeWindow,
			vitalsEncounterTypeWindow,
			chiefComplaintEncounterTypeWindow,
			encounterTypeWindows,
			canSaveMany,
		],
	);
	const [, confirmPaymentsAndSubmit] = useAsyncFn<SubmitHandler<VisitFormFields>>(
		async (formData) => {
			// If there are no payments and we're submitting, confirm with the user
			if (
				!(formData.paymentInformationList || []).length &&
				formData[NonModelFormField.SUBMIT_EVENT] === SubmitAction.COMPLETE
			) {
				if (!(await CustomPrompt(t(uiText.visit.prompt.COMPLETE_WITHOUT_PAYMENT)))) {
					return;
				}
			}
			return submitVisit(formData);
		},
		[submitVisit],
	);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(confirmPaymentsAndSubmit)} className="px-0">
				<input type="hidden" {...formMethods.register('uuid')} />
				<input type="hidden" {...formMethods.register('isNew')} />
				<input type="hidden" {...formMethods.register('voidedReason.uuid')} />
				<input type="hidden" {...formMethods.register('orders.0.uuid')} />
				<h1 className="print">{client.name}</h1>
				<VisitInfo />
				<VisitDetailsEdit isDataReadOnly={isDataReadOnly} processStageList={processStageList} />

				<fieldset disabled={disableWrite || isDataReadOnly}>
					{!disableTriageView && <TriageDetails isDataReadOnly={isDataReadOnly || isDisabledTriageWrite} />}

					{!disableView && <ClinicalDetails isDataReadOnly={isDataReadOnly} />}

					{!disableLabView && <LabDiagnosticsDetails isDataReadOnly={isDataReadOnly || isDisabledLabWrite} />}

					{canViewProducts && (
						<Card className="bh-card">
							<Card.Header className="fw-bold h5">{t(uiText.visit.form.product.LABEL)}</Card.Header>
							<Card.Body>
								<ProductLineItemTable readOnly={isDataReadOnly} />
							</Card.Body>
						</Card>
					)}

					{canViewPayments && (
						<Card className="bh-card">
							<Card.Header className="fw-bold h5">{t(uiText.visit.form.payment.LABEL)}</Card.Header>
							<Card.Body>
								<PaymentLineItemTable readOnly={isDataReadOnly} isVisitFinished={isDataReadOnly} />
							</Card.Body>
						</Card>
					)}
				</fieldset>
				<input type="hidden" {...formMethods.register(NonModelFormField.SUBMIT_EVENT)} defaultValue={''} />
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${!renderAsModal ? 'm-4 ms-3' : ''}`} role="toolbar">
			<Col xs="auto" className="me-auto">
				{disableWrite ? (
					<BasicButton
						variant="danger"
						name={uiText.product.button.BACK}
						text={t(uiText.product.button.BACK)}
						icon="arrow-left"
						active
						onClick={() => {
							if (savedPatient) {
								history.push(VISITS_PAGE);
							} else {
								visitFormInformation.current.wasDataSaved
									? onFinish(true, visitFormInformation.current.savedData)
									: onFinish(false);
							}
						}}
					/>
				) : (
					<Button
						type="button"
						variant="danger"
						name="cancel"
						className="me-auto"
						onClick={() => {
							if (savedPatient) {
								history.push(VISITS_PAGE);
							} else {
								visitFormInformation.current.wasDataSaved
									? onFinish(true, visitFormInformation.current.savedData)
									: onFinish(false);
							}
						}}
					>
						{isDataReadOnly ? t(uiText.visit.button.BACK) : t(uiText.visit.button.CANCEL)}
					</Button>
				)}
			</Col>
			{!disableWrite && isEntityDrafted(data.current.orders[0]) && !isEntityReactivated(data.current.orders[0]) ? (
				<Col xs="auto">
					<Button
						type="button"
						variant="danger"
						onClick={() =>
							ConfirmAction(t(uiText.visit.DELETE_DRAFT_PROMPT), () => {
								visitService
									.deleteByUuid(data.current.uuid)
									.then((response) => {
										toggleNavBlocking(false);
										history.push(REFRESH_PAGE + VISITS_PAGE);
									})
									.catch((error) => {
										console.error(t(uiText.visit.error.COULD_NOT_DELETE, { error }));
									});
							})
						}
					>
						{t(uiText.visit.button.DELETE)}
					</Button>
				</Col>
			) : null}

			{!disableWrite && (isEntityNew(data.current.orders[0]) || isEntityDrafted(data.current.orders[0])) ? (
				<>
					<Col xs="auto">
						<BHDropdownButton title={t(uiText.visit.button.SAVE)} variant="primary" icon="check">
							<Dropdown.Item
								as="button"
								onClick={() => {
									formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.SAVE);
									formMethods.handleSubmit(confirmPaymentsAndSubmit)();
								}}
							>
								<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
								{t(uiText.visit.button.SAVE_BILL)}
							</Dropdown.Item>
							<Dropdown.Item
								as="button"
								type="button"
								onClick={() => {
									formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.SAVE);
									setShowSaveAndSendToModal(true);
								}}
							>
								<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
								{t(uiText.visit.button.SAVE_AND_SEND_TO)}
							</Dropdown.Item>
						</BHDropdownButton>
					</Col>
					{canViewPayments && canViewProducts ? (
						<Col xs="auto">
							<Button
								name="button"
								type="button"
								variant="success"
								onClick={() => {
									setShowCompleteModal(true);
								}}
							>
								{t(uiText.visit.button.COMPLETE)}
							</Button>
						</Col>
					) : null}
				</>
			) : null}

			{isEntityCompleted(data.current.orders[0]) ? (
				<>
					{!disableWrite && (canVoidDocument || canReactivateVisit) ? (
						<Col xs="auto">
							<DropdownButton title={t(uiText.visit.button.ACTION)} variant="danger">
								{canReactivateVisit && (
									<Dropdown.Item
										as="button"
										onClick={() => {
											formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.REACTIVATE);
											formMethods.handleSubmit(confirmPaymentsAndSubmit)();
										}}
									>
										{t(uiText.visit.button.REACTIVATE_BILL)}
									</Dropdown.Item>
								)}
								{canVoidDocument && (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() => {
											formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.VOID);
											setViewVoidModal(true);
										}}
									>
										{t(uiText.visit.button.VOID_BILL)}
									</Dropdown.Item>
								)}
							</DropdownButton>
						</Col>
					) : null}

					{[canPrintInvoice, canGeneratePatientVisitSummary, canGenerateReceipt].some((access) => access) && (
						<Col xs="auto">
							<DropdownButton title={t(uiText.visit.button.PRINT)} variant="primary">
								{canGenerateReceipt && (
									<Dropdown.Item as="button" type="button" onClick={() => onPrintReport(PATIENT_RECEIPT)}>
										{t(uiText.visit.button.RECEIPT)}
									</Dropdown.Item>
								)}
								{!renderAsModal && canGeneratePatientVisitSummary && (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() => {
											addEventPrintListeners();
											window.print();
										}}
									>
										{t(uiText.visit.button.PATIENT_SUMMARY)}
									</Dropdown.Item>
								)}
								{canPrintInvoice && (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() =>
											onPrintReport(VISIT_INVOICE, [
												{ uuid: VISIT_INVOICE_PARAM_BH_VISIT_UU, value: data.current.uuid },
												{ uuid: VISIT_INVOICE_PARAM_SHOW_INSURANCE, value: false },
											])
										}
									>
										{t(uiText.visit.button.PATIENT_INVOICE)}
									</Dropdown.Item>
								)}
								{canPrintInvoice && checkInvoiceHasInsurersOrDonors() ? (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() =>
											onPrintReport(VISIT_INVOICE, [
												{ uuid: VISIT_INVOICE_PARAM_BH_VISIT_UU, value: data.current.uuid },
												{ uuid: VISIT_INVOICE_PARAM_SHOW_INSURANCE, value: true },
											])
										}
									>
										{t(uiText.visit.button.INSURER_INVOICE)}
									</Dropdown.Item>
								) : null}
							</DropdownButton>
						</Col>
					)}

					{!disableWrite && canSaveMany !== false ? (
						<Col xs="auto">
							<Button
								type="button"
								variant="success"
								onClick={() => {
									const newVisit = prepareVisit(getNewVisit(), encounterTypeWindows);
									data.current = newVisit;
									reset(newVisit);
								}}
							>
								{t(uiText.visit.button.ADD_NEW_VISIT)}
							</Button>
						</Col>
					) : null}
				</>
			) : null}
		</Row>
	);

	return (
		<VisitContext.Provider value={{ encounterTypeWindows }}>
			{printReceiptUrl && <ReceiptPrint id={'receipt'} url={printReceiptUrl} />}
			{viewVoidModal && (
				<VoidedReasonModal
					windowUuid={pageUuid.VISITS}
					onHandleClose={() => {
						setViewVoidModal(false);
					}}
					onVoidSubmit={({ voidedReason }) => {
						formMethods.setValue('voidedReason.uuid', voidedReason?.uuid);
						formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.VOID);
						setViewVoidModal(false);
						formMethods.handleSubmit(confirmPaymentsAndSubmit)();
					}}
				/>
			)}
			{showCompleteModal && (
				<VisitCompleteConfirmationModal
					cancel={() => {
						setShowCompleteModal(false);
					}}
					confirm={() => {
						setShowCompleteModal(false);
						formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.COMPLETE);
						formMethods.handleSubmit(confirmPaymentsAndSubmit)();
					}}
				/>
			)}
			{showSaveAndSendToModal && (
				<SaveAndSendToModal
					processStageList={processStageList}
					onHandleClose={() => {
						setShowSaveAndSendToModal(false);
					}}
					data={data.current}
					onHandleSubmit={(submissionData) => {
						formMethods.setValue('processStage.value', submissionData.processStage.value);
						formMethods.handleSubmit(confirmPaymentsAndSubmit)();
						setShowSaveAndSendToModal(false);
					}}
				/>
			)}
			<Modal show={!!processingErrorMessage && processingErrorMessage.length > 0}>
				<Modal.Header>
					<Modal.Title>{t(uiText.document.COULD_NOT_PROCESS_TRY_AGAIN)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t(uiText.error.FOLLOWING_ERROR_OCCURRED)}
					<em className="text-gray-500">
						{processingErrorMessage?.map((message, index) => (
							<Fragment key={index}>
								<br />
								{message}
							</Fragment>
						))}
					</em>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setProcessingErrorMessage(undefined);
						}}
					>
						{t(uiText.modal.OK)}
					</Button>
				</Modal.Footer>
			</Modal>
			{renderAsModal ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{t(title)}</Modal.Title>
					</Modal.Header>
					<Modal.Body className={`${loading ? '' : 'pt-0'}`}>
						{loading ? <LoadSpinner inline title={t(uiText.visit.PROCESSING)} /> : inputs}
					</Modal.Body>
					{!loading ? (
						<Modal.Footer>
							<div className="w-100">{buttons}</div>
						</Modal.Footer>
					) : null}
				</>
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(title)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						{loading ? (
							<LoadSpinner title={t(uiText.visit.PROCESSING)} />
						) : (
							<div className="bg-white pb-0_5 me-n2_5">
								{inputs}
								{buttons}
							</div>
						)}
					</Layout.Body>
				</>
			)}
		</VisitContext.Provider>
	);
};

export default withFormModalSuspsenseWrapper<VisitFormProps>({ loadingLabel: uiText.visit.FETCHING, getTitle })(
	VisitForm,
);
