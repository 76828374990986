import React from 'react';
import { Button } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BusinessPartner } from '../../models';
import { PATIENTS_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { setPatientStateForStartingNewPatient } from '../Patient/PatientList';

const ViewPatient = () => {
	const { t } = useTranslation();
	const patient: BusinessPartner = useWatch({ name: 'patient' });
	const history = useHistory();

	return patient?.uuid && !patient?.isNew ? (
		<Button
			variant="link"
			className="text-decoration-none"
			onClick={(event) => {
				event.preventDefault();
				history.push({
					pathname: PATIENTS_PAGE,
					state: setPatientStateForStartingNewPatient(patient || patient),
				});
			}}
		>
			{t(uiText.visit.form.patient.DETAILS)}
		</Button>
	) : null;
};

export default ViewPatient;
