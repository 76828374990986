import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';

type SearchProps = { onSearch: (text: string) => void };

function Search({ onSearch }: SearchProps) {
	const { t } = useTranslation();
	return (
		<InputGroup>
			<Form.Control
				type="search"
				placeholder={t(uiText.search.PLACEHOLDER)}
				onChange={({ target }) => onSearch(target.value)}
			/>
			<InputGroup.Text>
				<FontAwesomeIcon icon="search" />
			</InputGroup.Text>
		</InputGroup>
	);
}
export default Search;
