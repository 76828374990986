import { cloneDeep, throttle } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { AsyncTypeahead, AsyncTypeaheadProps, TypeaheadResult } from 'react-bootstrap-typeahead';
import {
	Controller,
	FieldPath,
	FieldValues,
	PathValue,
	UnpackNestedValue,
	UseControllerProps,
	useFormContext,
	useWatch,
} from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import BaseMetadata from '../../models/BaseMetadata';

export type EntityLookupProps<
	T extends BaseMetadata = BaseMetadata,
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> = {
	defaultValue?: T;
	visible?: boolean;
	onSelectNew?: (newValue: string) => void;
} & Omit<AsyncTypeaheadProps<T>, 'allowNew' | 'defaultSelected'> &
	Pick<UseControllerProps<FormValues, FieldName>, 'name' | 'rules' | 'defaultValue'>;

// ! In this component, UnpackNestedValue<PathValue<FormValues, FieldName>> = something extending from BaseMetadata

/**
 * This component creates an uncontrolled entity lookup component that allows a user to type information (such as a patient name) and
 * have the component look that information up. It also manages clearing text if nothing is selected and ensuring the form
 * data is up-to-date when a form is submitted. Also, it is required to be a child of a form context.
 *
 * @param {ControllerProps | AsyncTypeaheadProps} props The properties for both the controller and async typeahead components
 * @deprecated
 */
const EntityLookupInner = <
	T extends BaseMetadata = BaseMetadata,
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>(
	{
		name: formRegisterName,
		rules,
		defaultValue,
		onSelectNew,
		visible = true,
		...asyncTypeaheadProps
	}: EntityLookupProps<T, FormValues, FieldName>,
	ref: ((instance: AsyncTypeahead<T> | null) => void) | null,
) => {
	const { getValues, setValue, register } = useFormContext<FormValues>();

	// Since we have some particular requirements for this component (i.e. it should only select existing entities),
	// define a method to help with this
	const isSelectionValid = useCallback(
		(selected?: BaseMetadata): selected is T => !selected?.isNew && selected?.isNew !== undefined,
		[],
	);

	// function to check if the selected is custom
	const isCustomSelection = useCallback(
		(selectedFromTypeahead?: T | TypeaheadResult<T>) =>
			selectedFromTypeahead && 'customOption' in selectedFromTypeahead && selectedFromTypeahead.customOption,
		[],
	);

	// The autocomplete will call the focus twice in quick succession from time to time (I think it's
	// due to the menu opening, then re-focusing on the input), so ensure that doesn't happen
	const onFocus = asyncTypeaheadProps.onFocus
		? throttle(asyncTypeaheadProps.onFocus, 300, { trailing: false })
		: undefined;

	// The noSelectedObject is what we'll use when the form's value isn't valid
	const [noSelectedObject] = useState(new BaseMetadata({ uuid: '', isNew: true }) as T);
	const [defaultValueToUse] = useState<T>(
		isSelectionValid(defaultValue)
			? defaultValue
			: isSelectionValid(getValues(formRegisterName) as T)
				? (getValues(formRegisterName) as T)
				: noSelectedObject,
	);
	const [shouldClearInput, setShouldClearInput] = useState(false);

	// This can be modified outside this component, so we need to watch it
	// NB: Something happens with the selected object where it's properties can be updated without re-triggering a watch,
	// so we're going to copy it
	const selectedObject: T = cloneDeep(
		useWatch({ name: formRegisterName, defaultValue: defaultValueToUse as PathValue<FieldValues, FieldName> }) ||
			({} as T),
	);
	const [formObject, setFormObject] = useState(selectedObject);

	// Create some refs for working with the typeahead
	const asyncTypeaheadRef = useRef<AsyncTypeahead<T> | null>(null);
	const userHasModifiedInputWithoutSelecting = useRef(false);
	const userIsModifyingLookup = useRef(false);
	const hasUserMadeSelection = useRef(false);
	const isMenuOpen = useRef(false);
	// We don't want the typeahead's selection changes to trigger re-renders, so store it in a ref
	const selected = useRef<T[]>(isSelectionValid(defaultValueToUse) ? [defaultValueToUse] : []);

	// We'll update our stored form object if the form's value changes
	// NB: We can't leverage `getValues` from RHF because it's inconsistent
	const formObjectUuid = formObject?.uuid || '';
	useDeepCompareEffect(() => {
		// If this is run because the user made a selection, we don't care
		if (hasUserMadeSelection.current) {
			hasUserMadeSelection.current = false;
			return;
		}
		// If the UUID didn't change, we don't care
		if (formObjectUuid !== selectedObject?.uuid) {
			// If the new object is valid, we'll update
			// Otherwise, if it's not already the "noSelectedObject", we'll set it to it
			if (isSelectionValid(selectedObject)) {
				setFormObject(selectedObject);
			} else if ((selectedObject as any)?.uuid !== noSelectedObject.uuid) {
				setFormObject(noSelectedObject);
			}
		}
	}, [selectedObject, formObjectUuid, isSelectionValid]);

	// Once the selected form object updates, update the UI accordingly
	useEffect(() => {
		if (formObject.isNew || shouldClearInput) {
			if (asyncTypeaheadRef.current) {
				asyncTypeaheadRef.current.clear();
			}
			selected.current = [];
			// We're going to clone the no-selected object in case it gets modified for some reason outside of state
			setValue(formRegisterName, cloneDeep(noSelectedObject) as UnpackNestedValue<PathValue<FormValues, FieldName>>);
			setValue(`${formRegisterName}.uuid` as any, '' as any);
			setValue(`${formRegisterName}.isNew` as any, true as any);
			setShouldClearInput(false);
			if (formObject.uuid !== noSelectedObject.uuid) {
				setFormObject(noSelectedObject);
			}
		} else if (!shouldClearInput) {
			selected.current = [formObject];
			// We're also going to clone the form object in case it unintentionally gets updated outside of state
			setValue(formRegisterName, cloneDeep(formObject) as UnpackNestedValue<PathValue<FormValues, FieldName>>);
			setValue(`${formRegisterName}.uuid` as any, formObject.uuid as any, {
				shouldValidate: getValues(`${formRegisterName}.uuid` as any) !== formObject.uuid,
			});
			setValue(`${formRegisterName}.isNew` as any, false as any);
		}
	}, [setValue, formObject, formRegisterName, noSelectedObject, shouldClearInput, getValues]);

	// If the user is supplying a default input, update the selected
	if (!selected.current.length && asyncTypeaheadProps.defaultInputValue && !userIsModifyingLookup.current) {
		selected.current.push(asyncTypeaheadProps.defaultInputValue as any);
	}

	const inputProps: AsyncTypeaheadProps<T>['inputProps'] = asyncTypeaheadProps.inputProps || {};
	inputProps.id = inputProps.id || asyncTypeaheadProps.id?.toString();

	return visible ? (
		<>
			<input
				type="hidden"
				{...register(`${formRegisterName}.isNew` as any, {
					setValueAs: (value) => value !== 'false' && value !== false,
				})}
				defaultValue={(defaultValueToUse.isNew !== false).toString()}
			/>
			<Controller
				name={`${formRegisterName}.uuid` as any}
				rules={rules}
				defaultValue={defaultValueToUse.uuid || ('' as any)}
				render={({ field: { ref: controllerRef } }) => (
					<AsyncTypeahead
						delay={800}
						ignoreDiacritics={true}
						positionFixed={true}
						filterBy={() => true} // since filtering will happen on the back-end, we don't need to filter results again
						useCache={false}
						allowNew={!!onSelectNew} // If a function is passed in to select new, make sure this property set so it works
						{...asyncTypeaheadProps}
						inputProps={inputProps}
						ref={(element) => {
							controllerRef(element);
							asyncTypeaheadRef.current = element;
							if (ref) {
								ref(element);
							}
						}}
						defaultInputValue={undefined}
						selected={selected.current}
						onChange={(selectedFromTypeahead = []) => {
							if (onSelectNew && isCustomSelection(selectedFromTypeahead[0])) {
								onSelectNew(selectedFromTypeahead[0][asyncTypeaheadProps.labelKey as keyof BaseMetadata] as string);
								setShouldClearInput(true);
								userIsModifyingLookup.current = false;
								return;
							}
							// In case where the user scrolled before selecting, the input might get cleared - so undo it here
							setShouldClearInput(false);
							selected.current = [];
							if (isSelectionValid(selectedFromTypeahead[0])) {
								selected.current = selectedFromTypeahead;
							}
							// If nothing selected, be done
							if (!selectedFromTypeahead.length) {
								return;
							}
							// The user has now selected, so reset their interaction tracking
							userHasModifiedInputWithoutSelecting.current = false;
							setFormObject(selectedFromTypeahead[0]);
							hasUserMadeSelection.current = true;
							if (asyncTypeaheadProps.onChange) {
								asyncTypeaheadProps.onChange(selectedFromTypeahead);
							}
							// Now that a value has been selected, consider the user finished modifying the input
							userIsModifyingLookup.current = false;
						}}
						onInputChange={(...args) => {
							// Whenever the user changes the input, we want to know
							userIsModifyingLookup.current = true;
							userHasModifiedInputWithoutSelecting.current = true;
							// Also, we'll clear a selected value once the input changes
							selected.current = [];
							if (asyncTypeaheadProps.onInputChange) {
								asyncTypeaheadProps.onInputChange(...args);
							}
						}}
						onMenuToggle={(isOpen) => {
							isMenuOpen.current = isOpen;
							// If the menu is closing and the input no longer has focus, clear it
							if (!isOpen && asyncTypeaheadRef.current?.getInput() !== document.activeElement) {
								userIsModifyingLookup.current = false;
								// If the user has modified the input and they're moving away (without selecting), clear the selection
								if (userHasModifiedInputWithoutSelecting.current) {
									setShouldClearInput(true);
									userHasModifiedInputWithoutSelecting.current = false;
								}
							}
						}}
						onBlur={(event) => {
							// If the user hasn't selected anything (and they're done interacting with the dropdown), clear it
							if (!isMenuOpen.current && userHasModifiedInputWithoutSelecting.current) {
								setShouldClearInput(true);
								userHasModifiedInputWithoutSelecting.current = false;
							}
							if (asyncTypeaheadProps.onBlur) {
								asyncTypeaheadProps.onBlur(event);
							}
						}}
						onFocus={onFocus}
					/>
				)}
			/>
		</>
	) : (
		<></>
	);
};

/**
 * @deprecated
 */
const EntityLookup = forwardRef(EntityLookupInner as any) as <
	T extends BaseMetadata = BaseMetadata,
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>(
	props: EntityLookupProps<T, FormValues, FieldName> & { ref?: (instance: AsyncTypeahead<T> | null) => void },
) => ReturnType<typeof EntityLookupInner>;

export default EntityLookup;
