import { useContext, useEffect } from 'react';
import NavBlockingContext from '../contexts/NavBlockingContext';

export default function useConfirmRefresh(shouldConfirm: boolean = false): void {
	const { toggleNavBlocking } = useContext(NavBlockingContext) || { toggleNavBlocking: () => {} };
	useEffect(() => {
		toggleNavBlocking(shouldConfirm === true);

		return () => toggleNavBlocking(false);
	}, [shouldConfirm, toggleNavBlocking]);
}
