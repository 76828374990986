import React, { FocusEvent, useLayoutEffect, useRef, useState, useEffect } from 'react';
import { FormControl } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FieldPath, FieldValues, useFormContext, useWatch } from 'react-hook-form';
import BaseMetadata from '../../models/BaseMetadata';
import EntityLookup, { EntityLookupProps } from '../entity-lookup/EntityLookup';

export type EntitySuggestionProps<
	T extends BaseMetadata = BaseMetadata,
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
> = {
	newFieldName: string;
	modifyNewSelection?: (value: string) => string;
	unmodifyNewSelection?: (value: string) => string;
	readOnly?: boolean;
	idForLabel?: string;
} & EntityLookupProps<T, FormValues, FieldName>;

/**
 * This component creates an uncontrolled entity component that allows a user to type information (such as a patient name) and
 * have the component look that information up, while at the same time allowing a user to enter a new value. The main, initial use
 * is for the Coded Diagnoses where we combine the ability to look up an existing or create a new one. This component needs two places
 * under React Hook Forms to storm the inputs - one for a selection and the other for a new value. Also, it is required to be a child
 * of a form context.
 *
 * @param props The properties for this component, plus the controller and async typeahead components
 */
const EntitySuggestion = <
	T extends BaseMetadata = BaseMetadata,
	FormValues extends FieldValues = FieldValues,
	FieldName extends FieldPath<FormValues> = FieldPath<FormValues>,
>({
	newFieldName,
	modifyNewSelection: afterFinishNoSelect = (value) => value,
	unmodifyNewSelection: simplifyInput = (value) => value,
	idForLabel,
	...restOfProps
}: EntitySuggestionProps<T, FormValues, FieldName>) => {
	const { register, setValue, getValues } = useFormContext<FieldValues>();
	const initialNewValueToUse = useRef(
		typeof restOfProps.defaultValue === 'string'
			? (restOfProps.defaultValue as string)
			: (getValues(newFieldName) as string | undefined),
	);
	const initialValueToUse = useRef(
		(restOfProps.defaultValue as BaseMetadata | undefined)?.isNew === false
			? (restOfProps.defaultValue as BaseMetadata)
			: (getValues(restOfProps.name) as BaseMetadata | undefined),
	);

	const valueToShow = useWatch({ name: [newFieldName, restOfProps.name] });

	// We'll show the input if there's a value in the new field or we don't have a value in the regular field
	const [showInput, setShowInput] = useState(!!initialNewValueToUse.current || !initialValueToUse.current);
	const whichValueWasSelectedLast = useRef<'existing' | 'new' | undefined>();
	const didUserSelectSomething = useRef(false);
	const areProgrammaticallyFocusing = useRef(false);
	const shouldHandleInputToLoopkupSwitch = useRef(false);
	const previousSelectedOrNonSelectedValue = useRef('');

	const selectNew = (value: string) => {
		whichValueWasSelectedLast.current = 'new';
		setValue(newFieldName, afterFinishNoSelect(value));
		previousSelectedOrNonSelectedValue.current = value;
		didUserSelectSomething.current = true;
		setShowInput(true);
	};
	const selectExisting = (selected: T[]) => {
		whichValueWasSelectedLast.current = 'existing';
		didUserSelectSomething.current = true;
		setValue(newFieldName, '');
		previousSelectedOrNonSelectedValue.current = selected[0][restOfProps.labelKey as keyof T] as unknown as string;
		asyncTypeaheadRef.current?.blur();
	};
	const clearInput = () => {
		setValue(newFieldName, '');
		previousSelectedOrNonSelectedValue.current = '';
	};

	// Handle interactions between the two inputs
	useLayoutEffect(() => {
		if (!showInput && shouldHandleInputToLoopkupSwitch.current) {
			areProgrammaticallyFocusing.current = true;
			asyncTypeaheadRef.current?.getInput()?.focus();
			shouldHandleInputToLoopkupSwitch.current = false;
		}
	}, [getValues, newFieldName, showInput]);

	const asyncTypeaheadRef = useRef<AsyncTypeahead<T> | null>(null);

	useEffect(() => {
		if (!valueToShow[0] && (!valueToShow[1] || (valueToShow[1] as BaseMetadata).isNew)) {
			setShowInput(true);
			previousSelectedOrNonSelectedValue.current = '';
		}
	}, [valueToShow]);

	return (
		<>
			{showInput && (
				<FormControl
					className={restOfProps.className}
					id={idForLabel}
					defaultValue={initialNewValueToUse.current}
					{...register(newFieldName)}
					onFocus={(e: FocusEvent<HTMLInputElement>) => {
						if (!e.target.closest(':disabled') && restOfProps.readOnly !== true) {
							setShowInput(false);
							shouldHandleInputToLoopkupSwitch.current = true;
							previousSelectedOrNonSelectedValue.current = simplifyInput(getValues(newFieldName));
							if (previousSelectedOrNonSelectedValue.current) {
								whichValueWasSelectedLast.current = 'new';
							}
							restOfProps.onFocus && restOfProps.onFocus(e as unknown as Event);
						}
					}}
					placeholder={restOfProps.placeholder}
				/>
			)}
			<EntityLookup<T, FormValues, FieldName>
				{...restOfProps}
				className={`${restOfProps.className || ''} ${!showInput ? '' : ' d-none'}`}
				onSelectNew={selectNew}
				onFocus={() => {
					didUserSelectSomething.current = false;
					if (!areProgrammaticallyFocusing.current) {
						whichValueWasSelectedLast.current = 'existing';
						previousSelectedOrNonSelectedValue.current = asyncTypeaheadRef.current?.getInput()?.value || '';
					}
					areProgrammaticallyFocusing.current = false;
				}}
				onBlur={(e) => {
					if (
						previousSelectedOrNonSelectedValue.current !== asyncTypeaheadRef.current?.getInput()?.value &&
						!didUserSelectSomething.current
					) {
						setShowInput(true);
						clearInput();
					} else if (whichValueWasSelectedLast.current !== 'existing') {
						setShowInput(true);
					}
					didUserSelectSomething.current = false;
					restOfProps.onBlur && restOfProps.onBlur(e);
				}}
				onChange={selectExisting}
				ref={(element) => (asyncTypeaheadRef.current = element)}
				visible={!showInput}
				defaultInputValue={previousSelectedOrNonSelectedValue.current}
			/>
		</>
	);
};

export default EntitySuggestion;
