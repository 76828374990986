import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import Layout from '../Layout/Layout';

const Dashboard = () => {
	const { t } = useTranslation();
	const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>();
	return (
		<Layout>
			<Layout.Header>
				<Layout.Title title={t(uiText.dashboard.TITLE)}></Layout.Title>
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<Row className="bg-white ms-0">
					<Col
						as="iframe"
						title={t(uiText.dashboard.TITLE)}
						className="vh-100 px-0"
						src={`${window._env_.REACT_APP_DASHBOARD_SUB_PATH}/gl-dashboard`}
						ref={setIframeRef}
						onLoad={() => {
							// We're going to disable passing keystrokes to this iframe
							iframeRef?.contentWindow?.addEventListener(
								'keydown',
								(event) => {
									event.preventDefault();
									event.stopPropagation();
									return false;
								},
								true,
							);
						}}
						frameBorder="0"
					/>
				</Row>
			</Layout.Body>
		</Layout>
	);
};

export default Dashboard;
