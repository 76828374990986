import { sortBy } from 'lodash';
import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useService from '../../hooks/useService';
import { BusinessPartner, businessPartnerGroupSubType } from '../../models';
import BusinessPartnerGeneralPayerInformation from '../../models/BusinessPartnerGeneralPayerInformation';
import BusinessPartnerPayerInformation from '../../models/BusinessPartnerPayerInformation';
import { uiText } from '../../utils/Language';
import PatientPayerInformationInput from '../Patient/PatientPayerInformationInput';

type EditPatientInformationProps = {
	patient: BusinessPartner;
	onCancel: () => void;
	onSave: () => void;
	businessPartnerPayerInformation: BusinessPartnerPayerInformation;
	selectedPayer: Partial<BusinessPartner>;
};

const EditPatientInformation = ({
	patient,
	onCancel,
	onSave,
	businessPartnerPayerInformation,
	selectedPayer,
}: EditPatientInformationProps) => {
	const { t } = useTranslation();
	const { businessPartnerService } = useService();
	const payerInformationFieldList = sortBy(selectedPayer.payerInformationFieldList || [], 'lineNumber').filter(
		(payerInformationField) => payerInformationField.isActive && payerInformationField.shouldFillFromPatient,
	);
	const formMethods = useForm<FieldValues>({
		defaultValues: {
			data: payerInformationFieldList.map(
				(payerInformationField) =>
					businessPartnerPayerInformation.businessPartnerGeneralPayerInformationList.find(
						(businessPartnerGeneralPayerInformation) =>
							businessPartnerGeneralPayerInformation.payerInformationFieldUuid === payerInformationField.uuid,
					) || {},
			),
		},
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = formMethods;

	const onSubmit = async ({ data }: { data: BusinessPartnerPayerInformation[] }) => {
		const newBusinessPartnerPayerInformation = new BusinessPartnerPayerInformation(businessPartnerPayerInformation);
		newBusinessPartnerPayerInformation.payerUuid = selectedPayer.uuid;
		newBusinessPartnerPayerInformation.businessPartnerUuid = patient.uuid;
		newBusinessPartnerPayerInformation.businessPartnerGeneralPayerInformationList = data.map(
			(newBusinessPartnerChargeInformation) =>
				new BusinessPartnerGeneralPayerInformation(newBusinessPartnerChargeInformation),
		);
		await businessPartnerService.savePayerInformation(
			patient.uuid,
			newBusinessPartnerPayerInformation.uuid,
			newBusinessPartnerPayerInformation,
		);
		onSave();
	};

	return (
		<FormProvider {...formMethods}>
			<Modal show={true}>
				<Form>
					<Modal.Header>{t(uiText.visit.form.additionalInformation.EDIT_PATIENT_INFORMATION)}</Modal.Header>
					<Modal.Body>
						<span>{t(uiText.visit.info.NAME, { name: patient.name || '-' })}</span>
						<span className="ms-4">
							{t(uiText.visit.info.PATIENT_NUMBER_WITH_DATA, { patientNumber: patient.patientNumber || '-' })}
						</span>
						<Form.Group as={Row} controlId="chargeName" className="mt-3">
							<Col className="d-flex align-items-center">
								<Form.Label column>
									{t(
										selectedPayer.businessPartnerGroup?.subType.value === businessPartnerGroupSubType.Donation
											? uiText.visit.form.additionalInformation.DONOR
											: uiText.visit.form.additionalInformation.INSURER,
									)}
								</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<Form.Control disabled={true} defaultValue={selectedPayer.name} />
							</Col>
						</Form.Group>
						{payerInformationFieldList.map((payerInformationField, payerInformationFieldIndex) => {
							let businessPartnerGeneralPayerInformationIndex =
								businessPartnerPayerInformation.businessPartnerGeneralPayerInformationList.findIndex(
									(businessPartnerChargeInformation) =>
										businessPartnerChargeInformation.payerInformationFieldUuid === payerInformationField.uuid,
								);
							let businessPartnerGeneralPayerInformation: Partial<BusinessPartnerGeneralPayerInformation> =
								businessPartnerPayerInformation.businessPartnerGeneralPayerInformationList[
									businessPartnerGeneralPayerInformationIndex
								];
							if (businessPartnerGeneralPayerInformationIndex === -1) {
								businessPartnerGeneralPayerInformationIndex = payerInformationFieldIndex;
								businessPartnerGeneralPayerInformation = {};
							}
							businessPartnerGeneralPayerInformation = new BusinessPartnerGeneralPayerInformation(
								businessPartnerGeneralPayerInformation,
							);
							const subFieldName = `data.${payerInformationFieldIndex}`;
							return (
								<Form.Group
									as={Row}
									controlId={payerInformationField.uuid}
									className="mt-3"
									key={payerInformationField.uuid}
								>
									<Col className="d-flex align-items-center">
										<Form.Label column>{payerInformationField.name}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<input
											type="hidden"
											{...register(`${subFieldName}.uuid`)}
											defaultValue={businessPartnerGeneralPayerInformation.uuid}
										/>
										<input
											type="hidden"
											{...register(`${subFieldName}.payerInformationFieldUuid`)}
											defaultValue={payerInformationField.uuid}
										/>
										<PatientPayerInformationInput
											payerInformationField={payerInformationField}
											inputPlaceholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
												payerInformationField.name
											}`}
											name={`${subFieldName}.name`}
											register={register}
											defaultValue={businessPartnerGeneralPayerInformation.name || ''}
											selectPlaceholder={`${t(uiText.patient.additionalInformation.SELECT_PREFIX)} ${
												payerInformationField.name
											}`}
										/>
									</Col>
								</Form.Group>
							);
						})}
						{!!(errors.data || []).length && (
							<div className="text-danger">{t(uiText.patient.validationMessages.REQUIRE_INFO_TO_BE_ENTERED)}</div>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							type="button"
							variant="danger"
							onClick={() => {
								onCancel();
							}}
						>
							{t(uiText.visit.button.CANCEL)}
						</Button>
						<Button
							type="submit"
							variant="success"
							className="ms-auto"
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(onSubmit)();
							}}
						>
							{t(uiText.visit.button.SAVE)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</FormProvider>
	);
};

export default EditPatientInformation;
