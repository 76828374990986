import BaseEntity, { BaseEntityDB } from './BaseEntity';

export const DATE = 'fea92a20-5ea7-445c-b010-101d74f5e124';
export const REFERENCE_UUID_TABLE_DIRECT = '85767149-7019-4224-a8d0-41f0d9d77d47';
export const DATETIME = '9e410fd2-9e6c-4b19-842a-ef4634405244';
export const ALL_ORGANISATIONS = '3ef41ffc-8ea9-454a-afa2-22949f402ff5';
export const referenceUuids = {
	AMOUNT: '75558709-04db-4ea0-8a21-ee4dc1dff574',
	BUSINESS_PARTNER_SALES_ORDER_CREDIT_STATUS: '5073dd40-252a-4318-b7a3-ecf789c4eeaa',
	DATE: 'fea92a20-5ea7-445c-b010-101d74f5e124',
	DIAGNOSTIC_STATUSES: 'e0e8b36f-bb83-4c90-a07c-9b7209b5a8ce',
	DOCUMENT_STATUSES: 'd3f99f22-0b84-48bc-a157-38a3adf89435',
	GENDER: '4c6f3091-f6ad-4681-b8d0-cdbe00d22b28',
	INTEGER: '04246702-9e19-4942-89d8-643ca98535ef',
	ORDER_INVOICE_RULES: '9650d309-537c-4464-acfe-a65393795c4f',
	PAYER_INFORMATION_FIELD_DATA_TYPE: '15b3f5d7-205a-4d91-84c0-5e38ec36b6c6',
	PAYMENT_TYPES: 'af0136e2-1ec4-4b60-a8a1-dc6c252e4878',
	STRING: '3f9a0323-219e-4b97-af37-58a2486a50e3',
	TENDER_TYPES: '9d124599-d720-436b-a609-011bcf2eff8e',
	TEXT_LONG: '678761e9-b461-4afe-a343-6216de60c6c4',
	USER_NOTIFICATION_TYPES: '6abff1f7-f160-4cee-9f30-e7f22d90cb81',
} as const;

export interface ReferenceDB extends BaseEntityDB {
	ad_reference_uu: string;
}

/**
 * The reference class containing reference information.
 */
export default class Reference extends BaseEntity {}
