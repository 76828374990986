import { createContext } from 'react';

export interface NavBlockingContextInterface {
	isBlocking: boolean;
	toggleNavBlocking: (shouldBlock: boolean) => void;
}

const NavBlockingContext = createContext<NavBlockingContextInterface>({
	isBlocking: false,
	toggleNavBlocking: () => {},
});

export default NavBlockingContext;
