import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Column, { ColumnDto } from './Column';
import FieldGroup, { FieldGroupDto } from './FieldGroup';

export const fieldUuid = {
	CLINICAL_NOTES: '1b28d618-0529-4680-bfe2-2fcb5025fecd',
	BMI: '70b5bfa1-9c75-4fea-bf7e-076f4f4163fb',
	HEIGHT: '2842fb94-b841-4973-903e-89c7f24455b2',
	LAST_MENSTRUAL_PERIOD: 'da211453-46c6-44a6-8ee2-ded8f8630b23',
	WEIGHT: 'e0f68d60-0610-4caa-9dc3-b0143101ccd3',
} as const;

export interface FieldDto extends BaseEntityDto {
	sequenceNumber?: number | null;
	defaultValue?: string | null;
	mandatory?: string | null;
	placeholder?: string | null;
	fieldGroup?: FieldGroupDto | null;
	column?: ColumnDto | null;
	abbreviation?: string | null;
}

export interface FieldDB extends BaseEntityDB {}

export default class Field extends BaseEntity {
	sequenceNumber: number;
	defaultValue?: string;
	mandatory?: string;
	placeholder?: string;
	fieldGroup?: FieldGroup;
	column?: Column;
	abbreviation?: string;

	constructor(props: Partial<FieldDto | Field> = {}) {
		props = cloneDeep(props);
		super(props);

		this.sequenceNumber = props.sequenceNumber || 0;
		this.defaultValue = props.defaultValue || '';
		this.mandatory = props.mandatory || '';
		this.placeholder = props.placeholder || '';
		this.fieldGroup = props.fieldGroup ? new FieldGroup(props.fieldGroup) : undefined;
		this.column = new Column(('column' in props && props.column) || {});
		this.abbreviation = props.abbreviation || undefined;
	}
}
