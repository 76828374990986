import { Invoice, InvoiceDB, InvoiceDto } from '../models';
import DocumentService from './DocumentService';

export default class InvoiceService extends DocumentService<Invoice, InvoiceDto, InvoiceDB> {
	protected entityName: string = 'invoices';
	public entityTableName: string = 'c_invoice';

	map(data: InvoiceDto): Invoice {
		return new Invoice(data);
	}

	reverseMap(data: Invoice): InvoiceDto {
		return {
			...data,
			invoiceLines: (data.invoiceLines || []).map((invoiceLine) => ({
				...invoiceLine,
				product: {
					...invoiceLine.product,
					storageOnHandList: [],
				},
				attributeSetInstance: invoiceLine.attributeSetInstance
					? {
							...invoiceLine.attributeSetInstance,
							guaranteeDate: invoiceLine.attributeSetInstance.guaranteeDate?.getTime(),
							serialNumber: invoiceLine.attributeSetInstance.serialNumber || null,
							lot: invoiceLine.attributeSetInstance.lot || null,
						}
					: null,
				charge: { ...invoiceLine.charge, account: null, subType: null },
				price: invoiceLine.price || 0,
				orderLine: invoiceLine.orderLine ? { uuid: invoiceLine.orderLine.uuid } : null,
			})),
			dateInvoiced: data.dateInvoicedFormatted,
			businessPartner: {
				...data.businessPartner,
				dateOfBirth: data.businessPartner.dateOfBirth?.toISOString(),
				lastVisitDate: '',
			},
			order: data.order ? { uuid: data.order.uuid } : null,
		};
	}
}
