import { ApolloClient } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { v4 } from 'uuid';
import {
	C_DocTypeForFormsDocument,
	M_AttributeSetForProductPageQuery,
	M_AttributeSetInstanceInput,
	M_InventoryInput,
	M_InventoryLineInput,
	M_SerNoCtlCreateSerNoDocument,
} from '../../graphql/__generated__/graphql';
import { documentBaseType, documentSubTypeInventory, Warehouse } from '../../models';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import InitialStockRow from './InitialStockRow';
import { ProductFormValues } from './ProductForm';

export type InitailStockFormValues = {
	M_Inventory: { UU: string };
	M_InventoryLines: Array<{
		UU: string;
		M_AttributeSetInstance: { UU: string; GuaranteeDate: Date | null };
		QtyCount: number | null;
	}>;
	addNewInventory: {
		UU: string;
		M_AttributeSetInstance: { UU: string; GuaranteeDate: Date | null };
		QtyCount: number | null;
	};
	areExpirationDatesUnique: string;
};

export const convertToInitialStockFormFields = (): InitailStockFormValues => {
	return {
		M_Inventory: { UU: v4() },
		M_InventoryLines: [],
		addNewInventory: { UU: v4(), M_AttributeSetInstance: { UU: v4(), GuaranteeDate: null }, QtyCount: null },
		areExpirationDatesUnique: '1',
	};
};

export const constructInitialStockFormData = async (
	formData: ProductFormValues,
	warehouse: Warehouse,
	graphqlClient: ApolloClient<object>,
	attributeSet?: M_AttributeSetForProductPageQuery['M_AttributeSetGet']['Results'][0],
): Promise<[M_AttributeSetInstanceInput[], M_InventoryInput | null, M_InventoryLineInput[]]> => {
	const inventoryToSave: M_InventoryInput = {
		UU: formData.M_Inventory.UU,
		C_DocType: {
			UU: graphqlClient.readQuery({
				query: C_DocTypeForFormsDocument,
				variables: { Filter: getDocumentBaseTypeFilter(...fetchPhysicalInventoryDocumentTypeArguments).toString() },
			})?.C_DocTypeGet.Results[0].UU!,
		},
		M_Warehouse: { UU: warehouse.uuid },
		MovementDate: new Date().getTime(),
	};

	let serialNumber = '';
	// Set the serial number and attribute set correctly for the ASI
	if (attributeSet?.M_SerNoCtl) {
		serialNumber =
			(
				await graphqlClient.mutate({
					mutation: M_SerNoCtlCreateSerNoDocument,
					variables: {
						UU: attributeSet?.M_SerNoCtl.UU,
					},
				})
			).data?.M_SerNoCtlCreateSerNo || '';
	}

	const activeLocators = warehouse.locators.filter((locator) => locator.isActive);
	const locatorUU = activeLocators.find((locator) => locator.isDefault)?.uuid || activeLocators[0].uuid;
	let attributeSetInstancesToSave: M_AttributeSetInstanceInput[] = [];
	let inventoryLinesToSave: M_InventoryLineInput[] = (
		formData.needsExpirationAttributeSetInstance ? formData.M_InventoryLines : [formData.addNewInventory]
	).map((inventoryLine) => {
		const attributeSetInstanceToUse: M_AttributeSetInstanceInput = {
			UU: inventoryLine.M_AttributeSetInstance.UU,
			GuaranteeDate: formData.needsExpirationAttributeSetInstance
				? inventoryLine.M_AttributeSetInstance.GuaranteeDate?.getTime() || null
				: null,
			M_AttributeSet: attributeSet ? { UU: attributeSet.UU } : undefined,
			SerNo: serialNumber || undefined,
		};
		attributeSetInstancesToSave.push(attributeSetInstanceToUse);
		return {
			M_Inventory: { UU: formData.M_Inventory.UU },
			M_AttributeSetInstance: { UU: attributeSetInstanceToUse.UU! },
			M_Locator: { UU: locatorUU },
			M_Product: { UU: formData.UU },
			QtyCount: inventoryLine.QtyCount,
		};
	});

	// If there are no quantities being saved, just be done
	if (!inventoryLinesToSave.some((inventoryLine) => (inventoryLine.QtyCount || 0) > 0)) {
		return [[], null, []];
	}

	return [attributeSetInstancesToSave, inventoryToSave, inventoryLinesToSave];
};

const fetchPhysicalInventoryDocumentTypeArguments = [
	documentBaseType.MaterialPhysicalInventory,
	null,
	documentSubTypeInventory.PhysicalInventory,
	false,
	false,
	false,
] as const;
export const primeDataForInitialStock = (graphqlClient: ApolloClient<object>) =>
	Promise.all([
		graphqlClient.query({
			query: C_DocTypeForFormsDocument,
			variables: { Filter: getDocumentBaseTypeFilter(...fetchPhysicalInventoryDocumentTypeArguments).toString() },
			fetchPolicy: 'cache-first',
		}),
	]);

const InitialStock = () => {
	const { t } = useTranslation();
	const {
		getValues,
		setFocus,
		setValue,
		register,
		formState: { errors },
	} = useFormContext<ProductFormValues>();
	const needsExpirationAttributeSetInstance = useWatch<ProductFormValues, 'needsExpirationAttributeSetInstance'>({
		name: 'needsExpirationAttributeSetInstance',
	});
	const addNewInventoryQuantityCount =
		useWatch<InitailStockFormValues, 'addNewInventory.QtyCount'>({ name: 'addNewInventory.QtyCount' }) || 0;
	const addNewInventoryExpirationDate = useWatch<
		InitailStockFormValues,
		'addNewInventory.M_AttributeSetInstance.GuaranteeDate'
	>({
		name: 'addNewInventory.M_AttributeSetInstance.GuaranteeDate',
	});
	const {
		fields = [],
		append,
		remove,
	} = useFieldArray<InitailStockFormValues, 'M_InventoryLines', 'UU'>({
		name: 'M_InventoryLines',
		keyName: 'UU',
	});
	const inventoryLines = useWatch<InitailStockFormValues, 'M_InventoryLines'>({ name: 'M_InventoryLines' });

	// This handles adding a new row if the user started typing a new value
	const justAddedInventoryLine = useRef(false);
	useUpdateEffect(() => {
		if ((addNewInventoryQuantityCount || addNewInventoryExpirationDate) && needsExpirationAttributeSetInstance) {
			append({
				UU: getValues('addNewInventory.UU'),
				M_AttributeSetInstance: {
					UU: getValues('addNewInventory.M_AttributeSetInstance.UU'),
					GuaranteeDate: getValues('addNewInventory.M_AttributeSetInstance.GuaranteeDate') || null,
				},
				QtyCount: addNewInventoryQuantityCount,
			});
			setValue('addNewInventory.UU', v4());
			setValue('addNewInventory.QtyCount', null);
			setValue('addNewInventory.M_AttributeSetInstance.UU', v4());
			setValue('addNewInventory.M_AttributeSetInstance.GuaranteeDate', null);
			justAddedInventoryLine.current = true;
		}
	}, [
		addNewInventoryQuantityCount,
		addNewInventoryExpirationDate,
		append,
		setValue,
		getValues,
		needsExpirationAttributeSetInstance,
	]);

	useUpdateEffect(() => {
		if (justAddedInventoryLine.current) {
			setFocus(`M_InventoryLines.${fields.length - 1}.QtyCount`);
			justAddedInventoryLine.current = false;
		}
	}, [fields.length, setFocus]);

	const expirationDateList = inventoryLines.map(
		(inventoryLine) => inventoryLine?.M_AttributeSetInstance?.GuaranteeDate,
	);
	const areExpirationDatesUnique =
		!needsExpirationAttributeSetInstance || new Set(expirationDateList).size === expirationDateList.length;
	useEffect(() => {
		setValue('areExpirationDatesUnique', areExpirationDatesUnique ? '1' : '0');
	}, [setValue, areExpirationDatesUnique]);

	const totalQuantity = !needsExpirationAttributeSetInstance
		? addNewInventoryQuantityCount
		: inventoryLines.reduce((total, inventoryLine) => total + (inventoryLine.QtyCount || 0), 0);
	useEffect(() => {
		setValue('TotalQuantity', totalQuantity);
	}, [setValue, totalQuantity]);

	return (
		<Card className="bh-card">
			<input type="hidden" {...register('areExpirationDatesUnique', { validate: (value) => parseInt(value) === 1 })} />
			<input type="hidden" {...register('M_Inventory.UU')} />
			<Card.Header className="fw-bold h5">{t(uiText.product.INITIAL_STOCK)}</Card.Header>
			<Card.Body>
				<table className="bh-table--form">
					<thead>
						<tr>
							<th className="w-47">{t(uiText.product.quantity.LABEL)}</th>
							<th className="w-47">{t(uiText.product.expirationDate.LABEL)}</th>
							<th className="w-6">{t(uiText.product.button.DELETE)}</th>
						</tr>
					</thead>
					{needsExpirationAttributeSetInstance && (
						<tbody>
							{fields.map((field, index) => (
								<InitialStockRow key={field.UU} index={index} remove={remove} />
							))}
						</tbody>
					)}
					<tbody>
						<InitialStockRow isFooter={true} />
					</tbody>
				</table>
				{errors.M_InventoryLines?.some((inventoryLine) => !!inventoryLine?.QtyCount) && (
					<div className="text-danger">{t(uiText.product.error.MISSING_STOCK_QUANTITY)}</div>
				)}
				{errors.M_InventoryLines?.some((inventoryLine) => !!inventoryLine?.M_AttributeSetInstance?.GuaranteeDate) && (
					<div className="text-danger">{t(uiText.product.error.MISSING_STOCK_EXPIRATION_DATE)}</div>
				)}
				{!!errors.addNewInventory && (
					<div className="text-danger">{t(uiText.product.error.STOCK_QUANTITY_INCORRECT)}</div>
				)}
				{!!errors.areExpirationDatesUnique && (
					<div className="text-danger">{t(uiText.product.error.DUPLICATE_STOCK_EXPIRATION_DATES)}</div>
				)}
			</Card.Body>
		</Card>
	);
};

export default InitialStock;
