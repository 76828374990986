import { useQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_Payer_Info_Fld_SugForInsurersAndDonorsDocument,
	C_BPartnerForInsurerAndDonorEditingQuery,
} from '../../graphql/__generated__/graphql';
import { REFERENCE_LIST_UUID_DATA_TYPE_TEXT } from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import AdditionalInformationFromVisitRow from './AdditionalInformationFromVisitRow';
import { AdditionalInfoValuesFormFields } from './AdditionalInfoValuesForm';
import { InsurerOrDonorFormFields } from './InsurerOrDonorForm';

export type AdditionalInformationFromVisitiFormFields = {
	BH_Payer_Info_FldList: {
		UU: string;
		BH_FillFromPatient: boolean;
		BH_PayerInfoFieldDataTypeUU: string;
		IsActive: boolean;
		Name: string;
		BH_Payer_Info_Fld_ValList: { UU: string; IsActive: boolean; Name: string; isNew: boolean }[];
		isNew: boolean;
		valuesDisplay: string;
	}[];
	addNewPayerInformationField: { name: string };
	hasInfoBeenModified: string;
} & AdditionalInfoValuesFormFields;

export const convertToAdditionalInformationFromVisitiFormFields: (
	initialData?: C_BPartnerForInsurerAndDonorEditingQuery['C_BPartner'],
) => AdditionalInformationFromVisitiFormFields = (initialData) => {
	if (!initialData) {
		return {
			BH_Payer_Info_FldList: [],
			addNewPayerInformationField: { name: '' },
			addNewPayerInformationFieldValue: [],
			hasInfoBeenModified: 'false',
		};
	}
	return {
		BH_Payer_Info_FldList:
			sortBy(initialData.BH_Payer_Info_FldList || [], 'Line').map((payerInformationFieldList) => ({
				UU: payerInformationFieldList.UU,
				BH_FillFromPatient: payerInformationFieldList.BH_FillFromPatient,
				BH_PayerInfoFieldDataTypeUU: payerInformationFieldList.BH_PayerInfoFieldDataType.UU,
				IsActive: payerInformationFieldList.IsActive,
				Name: payerInformationFieldList.Name,
				BH_Payer_Info_Fld_ValList:
					sortBy(payerInformationFieldList.BH_Payer_Info_Fld_ValList || [], 'Line').map(
						(payerInformationFieldValue) => ({
							UU: payerInformationFieldValue.UU,
							IsActive: payerInformationFieldValue.IsActive,
							Name: payerInformationFieldValue.Name,
							isNew: false,
						}),
					) || [],
				isNew: false,
				valuesDisplay:
					sortBy(payerInformationFieldList.BH_Payer_Info_Fld_ValList || [], 'Line')
						.map((payerInformationFieldValue) => payerInformationFieldValue.Name)
						.join(', ') || '',
			})) || [],
		addNewPayerInformationField: { name: '' },
		addNewPayerInformationFieldValue: [],
		hasInfoBeenModified: !!initialData.BH_Payer_Info_FldList?.length ? 'true' : 'false',
	};
};

/**
 * This is the component for the additional information section. It is kept in this file
 * because it's not intended to be reused. It is separated to avoid re-rendering the whole form.
 */
const AdditionalInformationFromVisit = () => {
	const { t } = useTranslation();
	const {
		register,
		setFocus,
		setValue,
		formState: { errors },
	} = useFormContext<AdditionalInformationFromVisitiFormFields>();
	const needAdditionalVisitInformation = useWatch<InsurerOrDonorFormFields, 'BH_NeedAdditionalVisitInfo'>({
		name: 'BH_NeedAdditionalVisitInfo',
	});
	const hasInfoBeenModified =
		useWatch<AdditionalInformationFromVisitiFormFields, 'hasInfoBeenModified'>({
			name: 'hasInfoBeenModified',
		}) === 'true';
	const selectedSubTypeUuid = useWatch<InsurerOrDonorFormFields, 'BH_SubTypeUU'>({ name: 'BH_SubTypeUU' });
	const addNewPayerInformationField = useWatch<
		AdditionalInformationFromVisitiFormFields,
		'addNewPayerInformationField.name'
	>({
		name: 'addNewPayerInformationField.name',
	});
	const { fields, append, remove, ...restOfUseFieldArrayMethods } = useFieldArray<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList'
	>({
		name: 'BH_Payer_Info_FldList',
	});
	const [shouldFocusOnLastField, setShouldFocusOnLastField] = useState(false);

	const { data: allSuggestions } = useQuery(Bh_Payer_Info_Fld_SugForInsurersAndDonorsDocument, {
		fetchPolicy: 'cache-first',
	});

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewPayerInformationField && needAdditionalVisitInformation) {
			append({
				UU: v4(),
				BH_PayerInfoFieldDataTypeUU: REFERENCE_LIST_UUID_DATA_TYPE_TEXT,
				IsActive: true,
				Name: addNewPayerInformationField,
				isNew: true,
			});
			setValue('addNewPayerInformationField.name', '');
			setValue('hasInfoBeenModified', 'true');
			setShouldFocusOnLastField(true);
		}
	}, [addNewPayerInformationField, needAdditionalVisitInformation, fields.length, append, setValue]);
	// If there are fields and we should focus on the last one, do so
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField) {
			setFocus(`BH_Payer_Info_FldList.${fields.length - 1}.Name` as 'BH_Payer_Info_FldList.0.Name');
			setShouldFocusOnLastField(false);
		}
	}, [fields.length, setFocus, shouldFocusOnLastField]);
	const fieldValues = fields.map((field) => field.Name);
	// Remove suggestions if the selected sub type has changed
	useEffect(() => {
		const suggestionsForTheSelectedSubType = allSuggestions?.BH_Payer_Info_Fld_SugGet.Results.filter(
			(suggestion) => suggestion.BH_SubType.UU === selectedSubTypeUuid,
		);
		// If every suggestion isn't in the list, remove everything and re-add it if nothing has been updated
		if (
			!hasInfoBeenModified &&
			fields.length &&
			!fieldValues.every((fieldValue) =>
				suggestionsForTheSelectedSubType?.some((suggestion) => suggestion.Name === fieldValue),
			)
		) {
			remove();
		}
	}, [allSuggestions, selectedSubTypeUuid, hasInfoBeenModified, fields.length, fieldValues, remove]);
	// Add suggestions based on the selected sub-type, if possible
	useEffect(() => {
		// We're only going to update the info list if this entity is new and the list hasn't been modified or there aren't any items
		const suggestionsToAdd = allSuggestions?.BH_Payer_Info_Fld_SugGet.Results.filter(
			(suggestion) => suggestion.BH_SubType.UU === selectedSubTypeUuid,
		);
		if (!hasInfoBeenModified && !fields.length && suggestionsToAdd?.length && needAdditionalVisitInformation) {
			append(
				sortBy(suggestionsToAdd, 'Line').map((suggestion) => ({
					UU: v4(),
					BH_FillFromPatient: suggestion.BH_FillFromPatient,
					BH_PayerInfoFieldDataTypeUU: suggestion.BH_PayerInfoFieldDataType.UU,
					IsActive: suggestion.IsActive,
					Name: suggestion.Name,
					BH_Payer_Info_Fld_ValList:
						sortBy(suggestion.BH_Payer_Info_Fld_Val_SugList || [], 'Line').map((suggestedValue) => ({
							UU: v4(),
							IsActive: suggestedValue.IsActive,
							Name: suggestedValue.Name,
							isNew: true,
						})) || [],
					isNew: true,
					valuesDisplay:
						sortBy(suggestion.BH_Payer_Info_Fld_Val_SugList || [], 'Line')
							.map((suggestedValue) => suggestedValue.Name)
							.join(', ') || '',
				})),
			);
		}
	}, [allSuggestions, selectedSubTypeUuid, hasInfoBeenModified, needAdditionalVisitInformation, fields.length, append]);
	// Remove all fields on setting that no additional visit information is needed
	useEffect(() => {
		if (!needAdditionalVisitInformation) {
			remove();
		}
	}, [needAdditionalVisitInformation, remove]);

	return (
		(needAdditionalVisitInformation && (
			<Card className="bh-card">
				<Card.Header className="fw-bold h5">{t(uiText.nonPatientPayment.additionalData.TITLE)}</Card.Header>
				<Card.Body>
					<input type="hidden" {...register('hasInfoBeenModified')} />
					<table className="bh-table--form">
						<thead>
							<tr>
								<th className="w-6">{t(uiText.nonPatientPayment.tableHeaders.ORDER)}</th>
								<th className="w-7">{t(uiText.nonPatientPayment.button.ACTIVE)}</th>
								<th className="w-15">{t(uiText.nonPatientPayment.tableHeaders.FILL_FROM_PATIENT)}</th>
								<th className="w-30">{t(uiText.nonPatientPayment.tableHeaders.NAME)}</th>
								<th className="w-9">{t(uiText.nonPatientPayment.tableHeaders.TYPE)}</th>
								<th>{t(uiText.nonPatientPayment.tableHeaders.VALUES)}</th>
								<th className="w-6">{t(uiText.nonPatientPayment.button.DELETE)}</th>
							</tr>
						</thead>
						<tbody>
							{fields.map((field, index) => (
								<AdditionalInformationFromVisitRow
									key={field.id}
									field={field}
									index={index}
									remove={remove}
									isLast={index === fields.length - 1}
									{...restOfUseFieldArrayMethods}
								/>
							))}
							<tr>
								<td />
								<td />
								<td />
								<td>
									<Form.Control
										aria-label={t(uiText.nonPatientPayment.tableHeaders.NAME)}
										{...register('addNewPayerInformationField.name')}
										placeholder={t(uiText.nonPatientPayment.label.ENTER_NAME)}
										defaultValue={''}
									/>
								</td>
								<td>
									<Form.Select disabled={true}></Form.Select>
								</td>
								<td>
									<Form.Control disabled={true} />
								</td>
								<td />
							</tr>
						</tbody>
					</table>
					{errors.BH_Payer_Info_FldList?.some(
						(payerInformationFieldListValueError) => payerInformationFieldListValueError?.Name,
					) && (
						<div className="text-danger">
							{t(uiText.nonPatientPayment.validationMessages.REQUIRE_INFO_TO_HAVE_NAME)}
						</div>
					)}
					{errors.BH_Payer_Info_FldList?.some(
						(payerInformationFieldListValueError) => payerInformationFieldListValueError?.valuesDisplay,
					) && (
						<div className="text-danger">
							{t(uiText.nonPatientPayment.validationMessages.REQUIRE_INFO_ALL_LIST_TYPES_NEED_LIST_VALUES)}
						</div>
					)}
				</Card.Body>
			</Card>
		)) ||
		null
	);
};

export default AdditionalInformationFromVisit;
