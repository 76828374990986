import { isFinite, isNumber } from 'lodash';
import React, { forwardRef, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { formatNumber } from '../../utils/NumberUtil';

type FormatNumberInputProps = {
	onChange?: (number: number | null) => void;
	defaultValue?: never;
	value?: number | null;
	displayAndUseZeroIfEmpty?: boolean;
} & Omit<FormControlProps, 'as' | 'type' | 'onChange' | 'value'> &
	Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'as' | 'size' | 'type' | 'onChange'>;

const FormatNumberInput = forwardRef<HTMLInputElement, FormatNumberInputProps>(
	({ onBlur = () => {}, onChange = () => {}, displayAndUseZeroIfEmpty = true, value, className, ...props }, ref) => {
		const [userIsEditing, setUserIsEditing] = useState(false);
		const [internalValue, setInternalValue] = useState<string>((value || '').toString());
		className = className || 'text-end';
		// If a text alignment wasn't included, align it end
		if (!className.includes('text-')) {
			className += ' text-end';
		}

		return userIsEditing ? (
			<Form.Control
				{...props}
				className={className}
				ref={ref}
				type="number"
				onBlur={(e) => {
					setUserIsEditing(false);
					onBlur(e);
				}}
				onChange={(e) => {
					setInternalValue(e.target.value);
					const enteredNumber = parseFloat(e.target.value);
					onChange(isFinite(enteredNumber) ? enteredNumber : null);
				}}
				//This is to make sure that number input accepts 0 as input value
				value={internalValue ?? ''}
			/>
		) : (
			<Form.Control
				{...props}
				className={className}
				ref={ref}
				onFocus={(e) => {
					if (!e.target.closest(':disabled') && props.readOnly !== true) {
						setUserIsEditing(true);
						setInternalValue(isNumber(value) ? value.toString() : '');
					}
					// Even though the above fires, we need to ensure the on focus event is fired
					props.onFocus && props.onFocus(e);
				}}
				// This is to make sure React doesn't complain about switching between Controlled/Uncontrolled components
				onChange={() => {}}
				value={(value !== undefined && value !== null && formatNumber(value)) || (displayAndUseZeroIfEmpty ? '0' : '')}
			/>
		);
	},
);

export default FormatNumberInput;
