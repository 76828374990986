import { Spinner } from 'react-bootstrap';

type LoadSpinnerProps = {
	show?: boolean;
	title: string;
	inline?: boolean;
};

const LoadSpinner = ({ show, title, inline }: LoadSpinnerProps) => {
	const StatusSpinner = () => (
		<div className="d-flex justify-content-center align-items-center">
			<Spinner
				animation="grow"
				variant="info"
				className={`${!inline ? 'my-4_5' : 'my-3'} me-4`}
				role="status"
				aria-label={title}
			/>
			<span>{title}</span>
		</div>
	);
	return show === false ? null : !inline ? (
		<div
			style={{
				position: 'absolute',
				top: '10%',
				left: 'calc(50% - 200px)',
				width: '400px',
				border: 'thin solid var(--bs-border-color)',
				backgroundColor: '#ffffff',
				zIndex: '10000',
			}}
		>
			<StatusSpinner />
		</div>
	) : (
		<StatusSpinner />
	);
};

export default LoadSpinner;
