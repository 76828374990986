import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';
import Reference from './Reference';
import ReferenceList, { ReferenceListDto } from './ReferenceList';

export interface ReportParameterDto extends BaseEntityDto {
	reference: BaseEntityDto;
	referenceValues: ReferenceListDto[];
	sequenceNumber: number;
	columnName?: string;
}

/**
 * The class holding information about report parameters
 * @property {Reference} reference The reference for this parameter
 * @property {ReferenceList[]} referenceValues The values available to be chosen for this parameter, if any
 * @property {number} sequenceNumber The number pertaining to which order this value should be displayed in
 */
export default class ReportParameter extends BaseEntity {
	reference: Reference;
	referenceValues: ReferenceList[];
	sequenceNumber: number;
	columnName?: string;

	/**
	 * Create a new instance of this class
	 * @param {ReportParameter} props The props to assign to this class
	 */
	constructor(props: Partial<ReportParameter | ReportParameterDto> = {}) {
		props = cloneDeep(props);
		super(props);

		this.reference = new Reference(props.reference);
		this.referenceValues = (props.referenceValues || []).map((referenceValue) => new ReferenceList(referenceValue));
		this.sequenceNumber = props.sequenceNumber || 0;
		this.columnName = props.columnName;
	}
}
