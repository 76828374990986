import React from 'react';
import { Card, Row, Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import LabDiagnosticsTable from './LabDiagnosticsTable';
import ObservationFormGroup from './ObservationFormGroup';

type LabDiagnosticsDetailsProps = {
	isDataReadOnly?: boolean;
	loading?: boolean;
};

const LabDiagnosticsDetails = ({ isDataReadOnly, loading = false }: LabDiagnosticsDetailsProps) => {
	const { t } = useTranslation();
	const { getValues } = useFormContext();

	const visitUuid: string = getValues('uuid');
	const fieldPrefix = 'labDiagnosticsEncounters.0';

	return (
		<Card className="bh-card">
			<Card.Header className="d-flex">
				<div className="fw-bold h5 mb-0">{t(uiText.visit.form.diagnostic.LAB_DIAGNOSTICS_DETAILS)}</div>
				{loading && (
					<div className="ms-auto fw-light">
						<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
						<Spinner animation="border" size="sm" className="align-middle" role="status" />
					</div>
				)}
			</Card.Header>
			<Card.Body className="p-3">
				<Row className="gy-3">
					<LabDiagnosticsTable readOnly={isDataReadOnly} />

					<ObservationFormGroup visitUuid={visitUuid} fieldPrefix={fieldPrefix} />
				</Row>
			</Card.Body>
		</Card>
	);
};

export default LabDiagnosticsDetails;
