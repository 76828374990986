import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import City, { CityDto } from './City';
import Country, { CountryDB, CountryDto } from './Country';
import { RegionDB } from './Region';

export interface LocationDto extends BaseMetadataDto {
	address1: string;
	address2: string;
	address3: string;
	city: CityDto;
	country: CountryDto;
}

export interface LocationDB extends BaseMetadataDB {
	ad_orginfo: BaseMetadataDB;
	address1: string;
	address2: string;
	address3: string;
	c_country: CountryDB;
	c_region: RegionDB;
}

export default class Location extends BaseMetadata {
	address1: string;
	address2: string;
	address3: string;
	city: City;
	country: Country;

	constructor(props: Partial<LocationDto | Location> = {}) {
		props = cloneDeep(props);
		super(props);

		this.address1 = props.address1 || '';
		this.address2 = props.address2 || '';
		this.address3 = props.address3 || '';
		this.city = new City(props.city || {});
		this.country = new Country(props.country || {});
	}
}
