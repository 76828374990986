import React, { useContext, useEffect } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import VisitContext from '../../contexts/VisitContext';
import useService from '../../hooks/useService';
import ReferenceList from '../../models/ReferenceList';
import User from '../../models/User';
import Visit from '../../models/Visit';
import { formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import ClinicalDetails from './ClinicalDetails';
import LabDiagnosticsDetails from './LabDiagnosticsDetails';
import TriageDetails from './TriageDetails';
import VisitDetailsReview from './VisitDetailsReview';
import { prepareVisit } from './VisitUtil';

type VisitHistoryLineProps = {
	clinicians?: User[];
	patientTypes?: ReferenceList[];
	referrals?: ReferenceList[];
	visit: Visit;
};

const VisitHistoryLine = ({ clinicians, patientTypes, referrals, visit }: VisitHistoryLineProps) => {
	const { t } = useTranslation();
	const { visitService } = useService();
	const { encounterTypeWindows } = useContext(VisitContext);
	const formMethods = useForm({ defaultValues: prepareVisit(visit, encounterTypeWindows) });

	const reset = formMethods.reset;
	const { value: fullVisit, loading: areLoadingFullVisit } = useAsync(
		async () => prepareVisit(await visitService.getByUuid(visit.uuid), encounterTypeWindows),
		[visitService, visit.uuid, encounterTypeWindows],
	);

	useEffect(() => reset(fullVisit), [fullVisit, reset]);

	return (
		<FormProvider {...formMethods}>
			<div className="info-ribbon sticky-top print__position-relative">
				<div className="info-ribbon__entity-prop">{`${t(uiText.visit.NUMBER)} ${visit.documentNumber || '-'}`}</div>
				<div className="info-ribbon__entity-prop">
					{t(uiText.visit.TIME, { visitTime: formatDateAndTime(fullVisit?.visitDate) })}
				</div>
				<div className="info-ribbon__entity-prop">
					{t(uiText.visit.CLINICIAN_DISPLAY, {
						clinician: visit.clinician?.isNew === false ? visit.clinician.name : '-',
					})}
				</div>
				<div className="info-ribbon__entity-prop">
					{t(uiText.visit.REFERRAL, { referral: visit.referral?.isNew === false ? visit.referral.name : '-' })}
				</div>
			</div>
			<fieldset disabled={true}>
				<VisitDetailsReview
					clinicians={clinicians}
					patientTypes={patientTypes}
					referrals={referrals}
					loading={areLoadingFullVisit}
				/>
				<TriageDetails reviewHistory={true} loading={areLoadingFullVisit} />
				<ClinicalDetails reviewHistory={true} loading={areLoadingFullVisit} />
				<LabDiagnosticsDetails isDataReadOnly={true} loading={areLoadingFullVisit} />

				<Card className="bh-card">
					<Card.Header className="d-flex">
						<div className="fw-bold h5 mb-0">{t(uiText.visit.form.product.LABEL)}</div>
						{areLoadingFullVisit && (
							<div className="ms-auto fw-light">
								<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
								<Spinner animation="border" size="sm" className="align-middle" role="status" />
							</div>
						)}
					</Card.Header>
					<Card.Body>
						<table className="bh-table--form readonly">
							<thead>
								<tr>
									<th className="data-type-text">{t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE)}</th>
									<th className="data-type-text">{t(uiText.visit.form.product.table.INSTRUCTIONS)}</th>
									<th className="data-type-numeric">{t(uiText.visit.form.product.table.QUANTITY)}</th>
								</tr>
							</thead>
							<tbody>
								{fullVisit?.orders[0]?.orderLines
									?.filter((orderLine) => !orderLine.product.isNew)
									.map((orderLine) => (
										<tr key={orderLine.uuid}>
											<td>
												<Form.Control
													aria-label={t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE)}
													defaultValue={orderLine.product?.name}
												/>
											</td>
											<td>
												<Form.Control
													aria-label={t(uiText.visit.form.product.table.INSTRUCTIONS)}
													defaultValue={orderLine.instructions}
												/>
											</td>
											<td>
												<Form.Control
													aria-label={t(uiText.visit.form.product.table.QUANTITY)}
													className="text-end"
													defaultValue={orderLine.quantity}
												/>
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</Card.Body>
				</Card>
			</fieldset>
		</FormProvider>
	);
};

export default VisitHistoryLine;
