import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import Role, { RoleDto } from './Role';
import Warehouse, { WarehouseDto } from './Warehouse';
import OrganizationInformation, { OrganizationInformationDto } from './OrganizationInformation';
import { cloneDeep } from 'lodash';

export enum OrganizationUuid {
	All = '3ef41ffc-8ea9-454a-afa2-22949f402ff5',
}

export interface OrganizationDB extends BaseEntityDB {
	ad_org_uu: string;
}

export interface OrganizationDto extends BaseEntityDto {
	name: string;
	roles: RoleDto[];
	warehouses: WarehouseDto[];
	organizationInformation?: OrganizationInformationDto;
}
export default class Organization extends BaseEntity {
	name: string;
	roles: Role[];
	warehouses: Warehouse[];
	organizationInformation: OrganizationInformation;

	constructor(props: Partial<OrganizationDto | Organization> = {}) {
		props = cloneDeep(props);
		super(props);

		this.name = props.name || '';
		this.roles = (props.roles || []).map((role) => new Role(role));
		this.warehouses = (props.warehouses || []).map((warehouse) => new Warehouse(warehouse));
		this.organizationInformation = new OrganizationInformation(props.organizationInformation || {});
	}
}
