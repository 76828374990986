import DocumentStatus, { DocumentStatusDto, DocumentStatusValue } from '../models/DocumentStatus';
import { isStatusACompletedStatus, isStatusADraftedStatus, isStatusAVoidedStatus } from '../utils/StatusUtil';
import NewMetadataService from './NewMetadataService';

export default class DocumentStatusService extends NewMetadataService<DocumentStatus, DocumentStatusDto> {
	getUrl = `${this.metadataUrl}/document-statuses`;
	localStorageKey = 'documentStatuses';

	map(data: DocumentStatusDto) {
		return new DocumentStatus(data);
	}
	reverseMap(data: DocumentStatus): DocumentStatusDto {
		throw new Error('Method not implemented.');
	}

	/**
	 * Gets the mapped document status for the specified value
	 * @param {String} documentStatusValue The document status value to map
	 * @returns {DocumentStatus} The mapped document status
	 */
	async getMappedDocumentStatusForStatusValue(documentStatusValue: DocumentStatusValue): Promise<DocumentStatus> {
		const documentStatusesByValue = (await this.get()).reduce(
			(map, documentStatus) => {
				map[documentStatus.value] = documentStatus;
				return map;
			},
			{} as { [documentStatusValue: string]: DocumentStatus },
		);
		let documentStatusValueToUse: DocumentStatusValue = DocumentStatus.UNKNOWN;
		if (isStatusADraftedStatus(documentStatusValue)) {
			documentStatusValueToUse = DocumentStatus.DRAFTED;
		} else if (isStatusACompletedStatus(documentStatusValue)) {
			documentStatusValueToUse = DocumentStatus.COMPLETED;
		} else if (isStatusAVoidedStatus(documentStatusValue)) {
			documentStatusValueToUse = DocumentStatus.VOIDED;
		}
		return documentStatusesByValue[documentStatusValueToUse];
	}
}
