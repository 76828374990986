import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import useService from '../../hooks/useService';
import {
	BusinessPartner,
	BusinessPartnerDB,
	businessPartnerGroupInsurerAndDonorFilter,
	DBFilter,
	Filter,
	Paging,
} from '../../models';
import BusinessPartnerGeneralPayerInformation from '../../models/BusinessPartnerGeneralPayerInformation';
import BusinessPartnerPayerInformation from '../../models/BusinessPartnerPayerInformation';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import { PatientFormValues } from './PatientForm';
import PatientPayerInformationInput from './PatientPayerInformationInput';

type AdditionalInformationForVisitProps = { uuid: string };

const AdditionalInformationForVisit = ({ uuid }: AdditionalInformationForVisitProps) => {
	const { t } = useTranslation();
	const {
		register,
		setValue,
		formState: { errors },
	} = useFormContext();
	const { businessPartnerService } = useService();
	const addNewBusinessPartnerPayer: string = useWatch({ name: 'addNewBusinessPartnerPayer.uuid' });
	const { fields, append, remove } = useFieldArray<Required<PatientFormValues>, 'businessPartnerPayerInformationList'>({
		name: 'businessPartnerPayerInformationList',
	});
	const businessPartnerPayerInformationList: BusinessPartnerPayerInformation[] =
		useWatch({ name: 'businessPartnerPayerInformationList' }) || [];

	const { value: insurersAndDonorsThatCanTakePatientFilledData } = useAsync(
		async () =>
			sortBy(
				(
					await businessPartnerService.get(
						Paging.ALL.page,
						Paging.ALL.size,
						undefined,
						DBFilter<BusinessPartnerDB>()
							.nested('c_bp_group')
							.and(businessPartnerGroupInsurerAndDonorFilter())
							.up()
							.and(IS_ACTIVE as unknown as Filter<BusinessPartnerDB>),
					)
				).results
					.filter(
						(insurerOrDonor) =>
							insurerOrDonor.isActive &&
							insurerOrDonor.payerInformationFieldList.some(
								(payerInformationField) => payerInformationField.shouldFillFromPatient,
							),
					)
					.map(
						(businessPartner) =>
							new BusinessPartner({
								...businessPartner,
								payerInformationFieldList: businessPartner.payerInformationFieldList.filter(
									(payerInformationField) => payerInformationField.isActive,
								),
							}),
					),
				'name',
			),
		[businessPartnerService],
	);
	useAsync(
		async () =>
			setValue(
				'businessPartnerPayerInformationList',
				(await businessPartnerService.getPayerInformationList(uuid)).filter(
					(businessPartnerPayerInformation) => businessPartnerPayerInformation.isActive,
				),
			),
		[uuid, businessPartnerService, setValue],
	);

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewBusinessPartnerPayer) {
			// Get all payer info needing data for this payer and add it
			const selectedBusinessPartner = insurersAndDonorsThatCanTakePatientFilledData?.find(
				(businessPartner) => businessPartner.uuid === addNewBusinessPartnerPayer,
			);
			append(
				new BusinessPartnerPayerInformation({
					payerUuid: selectedBusinessPartner?.uuid,
					businessPartnerUuid: uuid,
					businessPartnerGeneralPayerInformationList: sortBy(
						(selectedBusinessPartner?.payerInformationFieldList || []).filter(
							(payerInformationField) => payerInformationField.shouldFillFromPatient,
						),
						'lineNumber',
					).map(
						(chargeInformation) =>
							new BusinessPartnerGeneralPayerInformation({
								payerInformationFieldUuid: chargeInformation.uuid,
							}),
					),
				}),
			);
			setValue('addNewBusinessPartnerPayer.uuid', '');
		}
	}, [addNewBusinessPartnerPayer, append, setValue, insurersAndDonorsThatCanTakePatientFilledData, uuid]);

	return (
		(insurersAndDonorsThatCanTakePatientFilledData?.length && (
			<Card className="bh-card">
				<Card.Header className="fw-bold h5">{t(uiText.patient.additionalInformation.TITLE)}</Card.Header>
				<Card.Body>
					<table className="bh-table--form">
						<thead>
							<tr>
								<th className="w-27">{t(uiText.patient.additionalInformation.NON_PATIENT_PAYMENT)}</th>
								<th>{t(uiText.patient.additionalInformation.PATIENT_INFORMATION)}</th>
								<th className="w-6">{t(uiText.patient.button.DELETE)}</th>
							</tr>
						</thead>
						<tbody>
							{fields.map((field, index) => {
								const selectedInsurerOrDonor = insurersAndDonorsThatCanTakePatientFilledData.find(
									(businessPartner) => businessPartner.uuid === field.payerUuid,
								);
								const fieldName = `businessPartnerPayerInformationList.${index}`;
								// If there isn't an associated charge, it means the charge was disabled and we shouldn't show this row
								return (
									selectedInsurerOrDonor && (
										<tr key={field.id}>
											<td className="align-middle ps-2_5">
												<input type="hidden" {...register(`${fieldName}.uuid`)} defaultValue={field.uuid} />
												<input
													type="hidden"
													{...register(`${fieldName}.chargeUuid`)}
													defaultValue={selectedInsurerOrDonor.uuid}
												/>
												<input type="hidden" {...register(`${fieldName}.businessPartnerUuid`)} defaultValue={uuid} />
												{selectedInsurerOrDonor.name}
											</td>
											<td>
												<Row>
													{sortBy(
														selectedInsurerOrDonor.payerInformationFieldList.filter(
															(payerInformationField) => payerInformationField.shouldFillFromPatient,
														),
														'lineNumber',
													).map((payerInformationField, payerInformationFieldIndex) => {
														let businessPartnerPayerInformationIndex =
															field.businessPartnerGeneralPayerInformationList.findIndex(
																(businessPartnerGeneralPayerInformation) =>
																	businessPartnerGeneralPayerInformation.payerInformationFieldUuid ===
																	payerInformationField.uuid,
															);
														let businessPartnerGeneralPayerInformation: Partial<BusinessPartnerGeneralPayerInformation> =
															field.businessPartnerGeneralPayerInformationList[businessPartnerPayerInformationIndex];
														if (businessPartnerPayerInformationIndex === -1) {
															businessPartnerPayerInformationIndex = payerInformationFieldIndex;
															businessPartnerGeneralPayerInformation = {};
														}
														const subFieldName = `${fieldName}.businessPartnerGeneralPayerInformationList.${businessPartnerPayerInformationIndex}`;
														return (
															<Col xs={3} key={businessPartnerGeneralPayerInformation.uuid}>
																<input
																	type="hidden"
																	{...register(`${subFieldName}.uuid`)}
																	defaultValue={businessPartnerGeneralPayerInformation.uuid}
																/>
																<input
																	type="hidden"
																	{...register(`${subFieldName}.payerInformationFieldUuid`)}
																	defaultValue={payerInformationField.uuid}
																/>
																<PatientPayerInformationInput
																	payerInformationField={payerInformationField}
																	label={t(uiText.patient.additionalInformation.PATIENT_INFORMATION)}
																	inputPlaceholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
																		payerInformationField.name
																	}`}
																	name={`${subFieldName}.name`}
																	register={register}
																	defaultValue={businessPartnerGeneralPayerInformation.name || ''}
																	selectPlaceholder={`${t(uiText.patient.additionalInformation.SELECT_PREFIX)} ${
																		payerInformationField.name
																	}`}
																/>
															</Col>
														);
													})}
												</Row>
											</td>
											<td className="align-middle text-center">
												<button
													aria-label={t(uiText.patient.button.DELETE)}
													type="button"
													className="btn p-0 w-100"
													tabIndex={-1}
													onClick={() => remove(index)}
												>
													<FontAwesomeIcon icon="trash" className="border-0" />
												</button>
											</td>
										</tr>
									)
								);
							})}
						</tbody>
						<tbody>
							<tr>
								<td>
									<DynamicSelect
										aria-label={t(uiText.patient.additionalInformation.NON_PATIENT_PAYMENT)}
										{...register('addNewBusinessPartnerPayer.uuid')}
										defaultValue={''}
										isLoading={!insurersAndDonorsThatCanTakePatientFilledData.length}
										placeholder={t(uiText.patient.additionalInformation.LOADING)}
									>
										<option value=""></option>
										{insurersAndDonorsThatCanTakePatientFilledData
											.filter(
												(chargeToSelect) =>
													!businessPartnerPayerInformationList.some(
														(currentBusinessPartnerCharge) =>
															currentBusinessPartnerCharge.payerUuid === chargeToSelect.uuid,
													),
											)
											.map((charge) => (
												<option key={charge.uuid} value={charge.uuid}>
													{charge.name}
												</option>
											))}
									</DynamicSelect>
								</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
					{!!(errors.businessPartnerChargeList || []).length && (
						<div className="text-danger">{t(uiText.patient.validationMessages.REQUIRE_INFO_TO_BE_ENTERED)}</div>
					)}
				</Card.Body>
			</Card>
		)) ||
		null
	);
};

export default AdditionalInformationForVisit;
