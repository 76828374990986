import axios, { AxiosError } from 'axios';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { exception } from '../utils/analytics';
import { configs } from '../utils/Configs';
import history from '../utils/History';
import { uiText } from '../utils/Language';

axios.interceptors.response.use(
	(response) => {
		const timeInMs = response.config.headers?.startTime ? Date.now() - response.config.headers.startTime : 0;
		// If a request takes more than 30secs, assume that the connection is slow. This should probably be our baseline for how long any request takes?
		if (timeInMs > 30000) {
			toast.warn(i18n.t(uiText.error.SLOW_CONNECTION));
		}
		return response;
	},
	(error: Error | AxiosError) => {
		if (axios.isAxiosError(error)) {
			switch (error.response?.status) {
				case 401: // unauthorized
					toast.error(i18n.t(uiText.error.UNAUTHORIZED));
					break;
				case 403: // forbidden
					exception({ description: i18n.t(uiText.error.FORBIDDEN) });
					toast.error(i18n.t(uiText.error.FORBIDDEN));
					history.push('/session-timeout'); //redirect user to session timeout page
					break;
				case 404: // not found
					exception({ description: i18n.t(uiText.error.NOT_FOUND) });
					toast.error(i18n.t(uiText.error.NOT_FOUND));
					history.push('/session-timeout'); //redirect user to session timeout page
					break;
				case 500:
					// If this is an inventory request, keep going with stuff
					if (error.request?.responseURL.includes('/inventory/')) {
						break;
					}
					if (error.response?.data.includes?.('password')) {
						break;
					}
					if (error.response?.data.includes?.('duplicate key')) {
						exception({ description: `entity already exists` });
						toast.error(i18n.t(uiText.error.ENTITY_ALREADY_EXISTS));
						break;
					}
					exception({ description: `Server error: ${error.response?.data}` });
					toast.error(
						error.response?.data.length > 100 ? i18n.t(uiText.error.INTERNAL_SERVER_ERROR) : error.response.data,
					);
					break;
				case 502:
					exception({ description: `Server unreachable` });
					toast.error(i18n.t(uiText.error.SERVER_UNREACHABLE));
					break;
				default:
					break;
			}
		}
		// Do something with response error
		throw error;
	},
);
axios.interceptors.request.use((requestConfig) => {
	if (requestConfig.url?.includes(configs.apiUrl)) {
		requestConfig.headers = requestConfig.headers || {};
		requestConfig.headers['Content-Type'] = 'application/json';
		requestConfig.headers['startTime'] = Date.now();
	}
	return requestConfig;
});

/**
 * This class is to be extended for all services
 * @template T The data type this service returns
 * @template S An optional data type for the transfer object
 */
export default abstract class BaseService<T = {}, S = T> {
	/**
	 * This method is meant to overridden in a child class to map data returned from the service
	 * to what the app desires
	 * @param data The data returned from the child service
	 */
	abstract map(data: S): T;

	/**
	 * This method is meant to overridden in a child class to map data returned from the service
	 * to what the app desires
	 * @param data The data returned from the child service
	 */
	abstract reverseMap(data: T): S;
}
