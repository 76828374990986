import { cloneDeep } from 'lodash';
import { v4 } from 'uuid';
import { formatDate } from '../utils/DateUtil';
import { ClientDB } from './Client';
import { OrganizationDB } from './Organization';

export interface BaseMetadataDB {
	isactive: boolean;
	created: string;
	ad_org: OrganizationDB;
	ad_client: ClientDB;
}

export interface BaseMetadataDto {
	clientId?: number | null;
	orgId?: number | null;
	uuid?: string | null;
	isActive?: boolean | null;
	created?: string | null;
	createdTimestamp?: number | null;
}

export default class BaseMetadata {
	clientId: number;
	orgId: number;
	uuid: string;
	isActive: boolean;
	created: string;
	createdBy?: number;
	isNew: boolean;
	createdDate: Date;

	get createdDateFormatted(): string {
		return (this.createdDate && formatDate(this.createdDate)) || '';
	}

	constructor(props?: Partial<BaseMetadata | BaseMetadataDto>) {
		props = cloneDeep(props || {});
		this.clientId = props.clientId || 0;
		this.orgId = props.orgId || 0;
		// There are some instances where we want to allow users to pass an empty string as a UUID, so don't override it
		this.uuid = props.uuid === undefined || props.uuid === null ? v4() : props.uuid;
		this.isActive = props.isActive === undefined ? true : !!props.isActive;
		this.created = props.created || '';
		if ('createdBy' in props) {
			this.createdBy = props.createdBy || 0;
			this.isNew = props.isNew !== undefined ? props.isNew : !props.uuid;
		} else {
			this.createdBy = 0;
		}
		// If no uuid was passed in (including an empty string), we'll assume this entity is new
		if (('isNew' in props && props.isNew) || !props.uuid) {
			this.isNew = true;
		} else {
			this.isNew = false;
		}
		if ('createdTimestamp' in props) {
			this.createdDate = (props.createdTimestamp && new Date(props.createdTimestamp)) || new Date();
		} else if ('createdDate' in props) {
			// In testing, this property may actually be returned as a string from the service - so account for that
			this.createdDate =
				(props.createdDate &&
					(typeof props.createdDate === 'string' ? new Date(props.createdDate) : props.createdDate)) ||
				new Date();
		} else {
			this.createdDate = new Date();
		}
	}
}
