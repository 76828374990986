import { ReactGAImplementation } from 'react-ga4';
import { GaOptions, InitOptions } from 'react-ga4/types/ga4';

/**
 * Extend the React-GA 4 class for some custom logic helpful to our app
 */
class BHReactG4Implementation extends ReactGAImplementation {
	/**
	 * Overriding a parent method to allow loading our script from our own server
	 * @param GA_MEASUREMENT_ID The measurement ID to use
	 * @param nonce A number to help with cryptography
	 * @returns Nothing
	 */
	_loadGA = (GA_MEASUREMENT_ID: string, nonce: number) => {
		if (typeof window === 'undefined' || typeof document === 'undefined') {
			return;
		}

		if (!this._hasLoadedGA) {
			// Global Site Tag (gtag.js) - Google Analytics
			const script = document.createElement('script');
			script.async = true;
			script.src = `${process.env.PUBLIC_URL}/cached-gtag.js`;
			if (nonce) {
				script.setAttribute('nonce', nonce.toString());
			}
			document.body.appendChild(script);

			(window as unknown as any).dataLayer = (window as unknown as any).dataLayer || [];
			(window as unknown as any).gtag = function gtag() {
				(window as unknown as any).dataLayer.push(arguments);
			};

			this._hasLoadedGA = true;
		}
	};

	/**
	 * A sugar method to help in sending exception information
	 * @param fieldObject The exception information to send
	 */
	exception = ({
		description = 'No description provided',
		fatal = false,
	}: { description?: string; fatal?: boolean } = {}) => {
		if (reactGA4Implementation.isInitialized) {
			this._gtag('event', 'exception', { description, fatal });
		}
	};

	/**
	 * React GA 4 doesn't provide the same testing API as React GA does, so allow the methods we might need
	 */
	get testModeAPI() {
		return { calls: this._queueGtag, resetCalls: () => (this._queueGtag.length = 0) };
	}

	/**
	 * A sugar method to help with setting and tracking the current user
	 * @param userId The ID of the current user
	 */
	setUser = (userId: string) => {
		this.set({ userId });
		this._gtag('set', 'user_properties', {
			crm_id: userId,
		});
	};
}

const reactGA4Implementation = new BHReactG4Implementation();

/**
 * A wrapper method around React GA 4's method so we can set a global variable to be used by our locally-loaded script
 * @param GA_MEASUREMENT_ID The measurement ID or initialization options
 * @param options The configuration options to use, if any
 */
export const initialize = (
	GA_MEASUREMENT_ID: InitOptions[] | string,
	options?: {
		legacyDimensionMetric?: boolean;
		nonce?: string;
		testMode?: boolean;
		gaOptions?: GaOptions;
		gtagOptions?: any;
	},
) => {
	(window as unknown as any).GA_MEASUREMENT_ID =
		typeof GA_MEASUREMENT_ID === 'string' ? GA_MEASUREMENT_ID : GA_MEASUREMENT_ID[0].trackingId;
	reactGA4Implementation.initialize(GA_MEASUREMENT_ID, options);
};
export const { exception, set, setUser, testModeAPI } = reactGA4Implementation;
