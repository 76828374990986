import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { BusinessPartnerGroup, BusinessPartnerGroupDB, BusinessPartnerGroupDto } from './BusinessPartnerGroup';
import { OrderDB } from './Order';
import PayerInformationField, { PayerInformationFieldDB, PayerInformationFieldDto } from './PayerInformationField';
import { VisitDB } from './Visit';

export interface BusinessPartnerDB extends BaseEntityDB {
	bh_payer_info_fld: PayerInformationFieldDB;
	c_bp_group: BusinessPartnerGroupDB;
	BH_PatientID: string;
	BH_Local_PatientID: string;
	age: number;
	gender: string;
	bh_phone: string;
	totalopenbalance: number;
	payOpenBalance: boolean;
	'bh_visit::patient_id': VisitDB;
	c_order: OrderDB;
	nextofkin_contact: string;
	nationalid: string;
	c_bpartner_uu: string;
	isCustomer: boolean;
	isVendor: boolean;
}

export interface BusinessPartnerDto extends BaseEntityDto {
	totalOpenBalance?: number | null;
	businessPartnerGroup?: BusinessPartnerGroupDto | null;
	patientNumber?: string | null;
	dateOfBirth?: string | null;
	phone?: string | null;
	email?: string | null;
	address?: string | null;
	gender?: string | null;
	nationalId?: string | null;
	occupation?: string | null;
	nextOfKinName?: string | null;
	nextOfKinContact?: string | null;
	localPatientNumber?: string | null;
	totalVisits?: number | null;
	lastVisitDate?: string | null;
	isApproximateDateOfBirth?: boolean | null;
	isCustomer?: boolean | null;
	isVendor?: boolean | null;
	needAdditionalVisitInformation?: boolean | null;
	payerInformationFieldList?: PayerInformationFieldDto[] | null;
	isLocked?: boolean | null;
}

export default class BusinessPartner extends BaseEntity {
	totalOpenBalance: number;
	businessPartnerGroup?: BusinessPartnerGroup;
	patientNumber: string;
	dateOfBirth?: Date;
	phone: string;
	address: string;
	gender: string;
	email: string;
	nationalId: string;
	occupation: string;
	nextOfKinName: string;
	nextOfKinContact: string;
	localPatientNumber: string;
	lastVisitDate?: string;
	totalVisits: number;
	isApproximateDateOfBirth: boolean;
	isCustomer: boolean;
	isVendor: boolean;
	needAdditionalVisitInformation: boolean;
	payerInformationFieldList: PayerInformationField[];
	isLocked: boolean;

	constructor(props?: DeepPartial<BusinessPartner | BusinessPartnerDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.totalOpenBalance = props.totalOpenBalance || 0;

		this.businessPartnerGroup = props.businessPartnerGroup
			? new BusinessPartnerGroup(props.businessPartnerGroup)
			: undefined;

		this.patientNumber = props.patientNumber || '';
		this.dateOfBirth =
			typeof props.dateOfBirth === 'string' || props.dateOfBirth instanceof Date
				? new Date(props.dateOfBirth)
				: undefined;
		this.phone = props.phone || '';
		this.address = props.address || '';
		this.gender = props.gender || '';
		if ('email' in props) {
			this.email = props.email || '';
		} else {
			this.email = '';
		}
		this.nationalId = props.nationalId || '';
		this.occupation = props.occupation || '';
		this.nextOfKinName = props.nextOfKinName || '';
		this.nextOfKinContact = props.nextOfKinContact || '';
		this.localPatientNumber = props.localPatientNumber || '';
		this.lastVisitDate = props.lastVisitDate || '';
		this.totalVisits = props.totalVisits || 0;
		this.isApproximateDateOfBirth =
			'isApproximateDateOfBirth' in props ? props.isApproximateDateOfBirth || false : false;
		this.isVendor = props.isVendor === true;
		this.isCustomer = props.isCustomer === true;
		this.needAdditionalVisitInformation = props.needAdditionalVisitInformation === true;
		this.payerInformationFieldList = (props.payerInformationFieldList || []).map(
			(chargeInformation) => new PayerInformationField(chargeInformation),
		);
		this.isLocked = props.isLocked === true;
	}

	isValid(): boolean {
		return !!this.name;
	}
}
