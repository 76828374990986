import i18n from 'i18next';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { uiText } from '../../utils/Language';
import BandaModal, { closeModal } from '../Modal/BandaModal';

/**
 * Adds a custom prompt to the UI and returns a promise to await the return of the prompt
 * @param message
 * @param callback - optional If not passed in, a user clicking "Continue" will not reload the page
 * @returns {Promise<Boolean>}
 */
async function CustomPrompt(message: string, callback?: (didUserClickContinue: boolean) => void) {
	function handleClose(didUserClickContinue: boolean) {
		// handle the cancel button click event
		if (!didUserClickContinue || !callback) {
			if (callback) {
				callback(didUserClickContinue);
			}
			closeModal(didUserClickContinue);
			return;
		}
		//handle continue button click
		callback(true);
		closeModal(true);
	}

	return BandaModal<boolean>(
		<Modal>
			<Modal.Header>{i18n.t(uiText.modal.WARNING)}</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-primary" onClick={() => handleClose(false)}>
					{i18n.t(uiText.modal.button.CANCEL)}
				</button>
				<button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => handleClose(true)}>
					{i18n.t(uiText.modal.CONTINUE)}
				</button>
			</Modal.Footer>
		</Modal>,
	);
}

export default CustomPrompt;
