import axios from 'axios';
import { isArray } from 'lodash';
import { ReferenceListDto } from '../models/ReferenceList';
import { configs } from '../utils/Configs';
import { PATIENT_TYPES, PROCESS_STAGE_LIST, REFERRALS } from '../utils/Constants';
import BaseService from './BaseService';

// TODO: Remove this class and put it in the ReferenceListService
export default class MetadataService extends BaseService {
	metadataUrl = `${configs.apiUrl}/metadata`;

	/**
	 * Get Patient Types
	 * @returns {Promise<Response | any>}
	 */
	async getPatientTypes(useCache?: boolean): Promise<ReferenceListDto[]> {
		useCache = useCache === undefined ? true : useCache;
		let metaData = this.getLocal(PATIENT_TYPES);
		if (metaData.length && useCache) {
			return metaData;
		}
		metaData = await axios.get<ReferenceListDto[]>(`${this.metadataUrl}/patient-types`).then(({ data }) => data);
		localStorage.setItem(PATIENT_TYPES, JSON.stringify(metaData));
		return metaData;
	}

	/**
	 * Get Referrals
	 * @returns {Promise<Response | any>}
	 */
	async getReferrals(useCache?: boolean): Promise<ReferenceListDto[]> {
		useCache = useCache === undefined ? true : useCache;
		let metaData = this.getLocal(REFERRALS);
		if (metaData.length && useCache) {
			return metaData;
		}
		metaData = await axios.get<ReferenceListDto[]>(`${this.metadataUrl}/referrals`).then(({ data }) => data);
		localStorage.setItem(REFERRALS, JSON.stringify(metaData));
		return metaData;
	}

	/**
	 * Get ProcessStageList
	 * @returns {Promise<BaseEntityDto>}
	 */
	async getProcessStageList(): Promise<ReferenceListDto[]> {
		const localProcessStageList = this.getLocalProcessStageList();
		if (isArray(localProcessStageList) && localProcessStageList.length) {
			return localProcessStageList;
		}
		return axios.get<ReferenceListDto[]>(`${this.metadataUrl}/process-stage-list`).then(({ data }) => {
			localStorage.setItem(PROCESS_STAGE_LIST, JSON.stringify(data));
			return data;
		});
	}

	getLocalProcessStageList = (): ReferenceListDto[] => JSON.parse(localStorage.getItem(PROCESS_STAGE_LIST) || '[]');

	map(data: {}): {} {
		throw new Error('Method not implemented.');
	}

	reverseMap(data: {}): {} {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get the data stored locally (may be an empty array if not initialized yet)
	 */
	private getLocal(localStorageKey: string): ReferenceListDto[] {
		let metaData = JSON.parse(localStorage.getItem(localStorageKey) || '[]');
		if (metaData && isArray(metaData) && metaData.length) {
			return metaData.map(this.map);
		}
		return [];
	}
}
