import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import useService from '../../hooks/useService';
import {
	Concept,
	ConceptClass,
	ConceptDataType,
	EncounterDiagnostic,
	EncounterDiagnosticStatusCode,
	Paging,
	Reference,
} from '../../models';
import { uiText } from '../../utils/Language';
import {
	getConceptSearchTerms,
	getLabTestResultReferenceRange,
	isLabTestResultOutsideReferenceRange,
} from '../../utils/ModelUtils';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import EntityLookup from '../entity-lookup/EntityLookup';

type LabDiagnosticsTableRowProps = {
	field?: EncounterDiagnostic;
	fieldPrefix: string;
	remove?: (index: number) => void;
	isAddRow?: boolean;
	index?: number;
	readOnly?: boolean;
	conceptTestsAndPanels?: Concept[];
	diagnosticStatuses?: Reference[];
};

const LabDiagnosticsTableRow = ({
	field,
	fieldPrefix,
	remove,
	isAddRow = false,
	index = 0,
	readOnly,
	conceptTestsAndPanels = [],
	diagnosticStatuses = [],
}: LabDiagnosticsTableRowProps) => {
	const { t } = useTranslation();
	const { register, setValue, getValues } = useFormContext();
	const { conceptService } = useService();

	const lastSelectedConceptUuid = useRef(undefined);
	const selectedConcept = useWatch({ name: `${fieldPrefix}.concept` }) || field?.concept;
	const resultValue = useWatch({ name: `${fieldPrefix}.value` }) || field?.value;
	// The concept stored in the form may have had some extras and mappings removed to speed
	// up the form. So, retrieve the full original concept as well.
	const fullConcept =
		selectedConcept?.uuid && conceptTestsAndPanels
			? conceptTestsAndPanels.find((concept) => concept.uuid === selectedConcept.uuid)
			: undefined;
	const refRange = fullConcept ? getLabTestResultReferenceRange(fullConcept, true) : '';
	const [referenceRange, setReferenceRange] = useState(refRange);

	const [testResults, onSearchTestResults] = useAsyncFn(
		async (conceptUuid: string) => {
			if (!conceptUuid) {
				return [];
			}

			const response = await conceptService.get(
				Paging.ALL.page,
				Paging.ALL.size,
				undefined,
				conceptService.getTestQAndAFilter(conceptUuid),
			);
			const testResults = response.results.map((concept) => concept.name);
			if (testResults.length > 0) {
				// Add a blank entry at the front of the list, so no result is selected by default
				testResults.unshift('');
			}
			return testResults;
		},
		[conceptService],
	);

	// If new test results have loaded, make sure the correct drop-down item is selected
	useEffect(() => {
		if (testResults) {
			setValue(`${fieldPrefix}.value`, getValues(`${fieldPrefix}.value`));
		}
	}, [testResults, fieldPrefix, setValue, getValues]);

	// Handle several changes if the selected test was changed
	useEffect(() => {
		// If the data type of the new test is "CODED", get the list of possible test results to populate the "Results" drop-down list
		if (selectedConcept && selectedConcept.uuid && selectedConcept.dataType === ConceptDataType.CODED) {
			// Ensure we don't do unnecessary calls if the requests for this concept were already loaded
			if (lastSelectedConceptUuid.current !== selectedConcept.uuid) {
				onSearchTestResults(selectedConcept.uuid);
			}
		}

		// If there was a test selected, and it was switched to a new one, clear the result field as it should
		// no longer apply and update the reference range
		if (
			selectedConcept &&
			selectedConcept.uuid &&
			lastSelectedConceptUuid.current &&
			selectedConcept.uuid !== lastSelectedConceptUuid.current
		) {
			setValue(`${fieldPrefix}.value`, '');
			setReferenceRange(getLabTestResultReferenceRange(selectedConcept, true));
		} else if (!selectedConcept || selectedConcept.isNew) {
			// If the lab test was reset to blank, clear the reference range as well
			setReferenceRange('');
		}

		// Finally, ensure the lastSelectedConceptUuid is updated if necessary
		if (lastSelectedConceptUuid.current !== selectedConcept?.uuid) {
			lastSelectedConceptUuid.current = selectedConcept?.uuid;
		}
	}, [selectedConcept, onSearchTestResults, lastSelectedConceptUuid, setValue, fieldPrefix]);

	// If a result value was entered/selected, mark the status as Complete
	useEffect(() => {
		if (resultValue && getValues(`${fieldPrefix}.value`) !== EncounterDiagnosticStatusCode.COMPLETE) {
			setValue(`${fieldPrefix}.status`, EncounterDiagnosticStatusCode.COMPLETE);
		}
	}, [resultValue, fieldPrefix, setValue, getValues]);

	const [{ value: filteredConceptOptions, loading: areLoadingConcepts }, onSearchConcepts] = useAsyncFn(
		async (query: string) => {
			// Initially, before a query is entered, just show all the panels
			if (query === '') {
				return conceptTestsAndPanels.filter((concept) => concept.conceptClass === ConceptClass.LAB_SET);
			}

			// Build a filtered list using the available search terms
			const filteredList = conceptTestsAndPanels.filter((concept) =>
				getConceptSearchTerms(concept).some((term) => term.indexOf(query.toLowerCase()) >= 0),
			);

			// Push a dividing line between panels and tests (if both exist in the filtered list)
			let hasPanels = false;
			for (let i = 0; i < filteredList.length; i++) {
				if (filteredList[i].conceptClass === ConceptClass.LAB_SET) {
					hasPanels = true;
				} else if (filteredList[i].conceptClass === ConceptClass.TEST) {
					if (hasPanels) {
						filteredList.splice(i, 0, new Concept({ displayName: '-------' }));
					}
					break;
				}
			}

			return filteredList;
		},
		[conceptTestsAndPanels],
	);

	return (
		<tr>
			<td className="align-middle">
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.uuid`)} defaultValue={field?.uuid} />}
				<EntityLookup
					name={`${fieldPrefix}.concept`}
					rules={{ required: !isAddRow }}
					defaultValue={field?.concept}
					isLoading={areLoadingConcepts}
					id={`${fieldPrefix}.concept`}
					delay={500}
					inputProps={{ 'aria-label': t(uiText.visit.form.diagnostic.TEST) }}
					emptyLabel={t(uiText.visit.form.diagnostic.NOT_FOUND)}
					labelKey="name"
					minLength={0}
					placeholder={t(uiText.visit.form.diagnostic.SEARCH)}
					promptText={t(uiText.visit.form.diagnostic.SEARCHING)}
					searchText={t(uiText.visit.form.diagnostic.SEARCHING)}
					options={filteredConceptOptions || []}
					onSearch={onSearchConcepts}
					disabled={readOnly}
					onInputChange={(text) => {
						if (text === '') {
							onSearchConcepts('');
						}
					}}
					onFocus={(event) => {
						if ((event?.target as any)?.value === '') {
							onSearchConcepts('');
						}
					}}
				/>
			</td>
			<td className="align-middle">
				{selectedConcept?.dataType === ConceptDataType.CODED ? (
					<DynamicSelect
						aria-label={t(uiText.visit.form.diagnostic.RESULT)}
						isLoading={testResults.loading}
						className="form-control"
						{...register(`${fieldPrefix}.value`, {
							validate: () => {
								return true;
							},
						})}
						defaultValue={field?.value}
					>
						{testResults.value?.map((result) => (
							<option key={result} value={result}>
								{result}
							</option>
						))}
					</DynamicSelect>
				) : selectedConcept?.dataType === ConceptDataType.NUMERIC ? (
					<Form.Control
						aria-label={t(uiText.visit.form.diagnostic.RESULT)}
						className={isLabTestResultOutsideReferenceRange(resultValue, fullConcept) ? 'text-danger' : ''}
						defaultValue={field?.value}
						placeholder={
							// Pick the appropriate placeholder, depending on whether minimum and/or maximum values are defined
							selectedConcept?.minimumValue !== undefined && selectedConcept?.maximumValue !== undefined
								? t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC_BETWEEN, {
										minimumValue: selectedConcept?.minimumValue,
										maximumValue: selectedConcept?.maximumValue,
									})
								: selectedConcept?.minimumValue !== undefined
									? t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC_GREATER, {
											minimumValue: selectedConcept?.minimumValue,
										})
									: selectedConcept?.maximumValue !== undefined
										? t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC_LESS, {
												maximumValue: selectedConcept?.maximumValue,
											})
										: t(uiText.visit.form.diagnostic.PLACEHOLDER_NUMERIC)
						}
						{...register(`${fieldPrefix}.value`, {
							validate: (value) => {
								// If the field is blank, that is fine, but otherwise, check if it is a number, and within the right range
								if (!value) {
									return;
								}
								if (!/^-?\d+(\.\d+)?$/.test(value)) {
									return t(uiText.visit.error.TEST_RESULT_MUST_BE_NUMBER, {
										testName: selectedConcept?.name,
									});
								}
								if (selectedConcept?.minimumValue !== undefined && value < selectedConcept?.minimumValue) {
									return t(uiText.visit.error.TEST_RESULT_MUST_BE_GREATER, {
										testName: selectedConcept?.name,
										minimumValue: selectedConcept?.minimumValue,
									});
								}
								if (selectedConcept?.maximumValue !== undefined && value > selectedConcept?.maximumValue) {
									return t(uiText.visit.error.TEST_RESULT_MUST_BE_LESS, {
										testName: selectedConcept?.name,
										maximumValue: selectedConcept?.maximumValue,
									});
								}
							},
						})}
					/>
				) : (
					<Form.Control
						aria-label={t(uiText.visit.form.diagnostic.RESULT)}
						defaultValue={field?.value}
						placeholder={
							selectedConcept?.dataType === ConceptDataType.TEXT ? t(uiText.visit.form.diagnostic.PLACEHOLDER_TEXT) : ''
						}
						{...register(`${fieldPrefix}.value`, {
							validate: () => {
								return true;
							},
						})}
					/>
				)}
			</td>
			<td>
				<Form.Control
					aria-label={t(uiText.visit.form.diagnostic.REFERENCE_RANGE)}
					value={referenceRange}
					readOnly={true}
				/>
			</td>
			<td className="align-middle">
				<DynamicSelect
					aria-label={t(uiText.visit.form.diagnostic.STATUS)}
					isLoading={!diagnosticStatuses.length}
					className="form-control"
					{...register(`${fieldPrefix}.status`)}
					defaultValue={field?.status}
				>
					{diagnosticStatuses?.map((status) => (
						<option key={status.value} value={status.value}>
							{status.name}
						</option>
					))}
				</DynamicSelect>
			</td>
			{!readOnly && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							type="button"
							aria-label={t(uiText.visit.form.diagnostic.DELETE_DIAGNOSTIC)}
							className="btn p-0 w-100"
							tabIndex={-1}
							id={`${fieldPrefix}.delete`}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default LabDiagnosticsTableRow;
