import { DBFilter } from '../models';
import DocumentType, { DocumentTypeDB, DocumentTypeDto } from '../models/DocumentType';
import BaseEntityService from './BaseEntityService';

export default class DocumentTypeService extends BaseEntityService<DocumentType, DocumentTypeDto, DocumentTypeDB> {
	entityTableName: string = 'c_doctype';
	entityName = 'document-types';

	map(data: DocumentTypeDto) {
		return new DocumentType(data);
	}

	reverseMap(data: DocumentType): DocumentTypeDto {
		throw new Error('Method not implemented.');
	}

	async getDocumentBaseType(
		documentBaseType: string,
		documentSalesSubType: string | null,
		isSalesTransaction: boolean,
		isShipmentConfirm: boolean,
		isPickQAConfirm: boolean,
	) {
		const filter = DBFilter<DocumentTypeDB>()
			.property('docbasetype')
			.equals(documentBaseType)
			.property('issotrx')
			.equals(isSalesTransaction)
			.property('isshipconfirm')
			.equals(isShipmentConfirm)
			.property('ispickqaconfirm')
			.equals(isPickQAConfirm);
		if (documentSalesSubType) {
			filter.property('docsubtypeso').equals(documentSalesSubType);
		} else {
			filter.property('docsubtypeso').isNull();
		}
		// Based on this filter, see if we've fetched this value before; if so, return it
		const filterString = 'documentType_' + JSON.stringify(filter);
		if (localStorage.getItem(filterString)) {
			return new DocumentType(JSON.parse(localStorage.getItem(filterString)!));
		}
		// We haven't gotten it before, so fetch it
		const documentType = (await this.get(0, 100, undefined, filter)).results[0];
		// If something was returned, we'll cache it
		if (documentType) {
			localStorage.setItem(filterString, JSON.stringify(documentType));
		}
		return documentType;
	}
}
