import PayerInformationFieldSuggestion, {
	PayerInformationFieldSuggestionDto,
} from '../models/PayerInformationFieldSuggestion';
import PayerInformationFieldValueSuggestion from '../models/PayerInformationFieldValueSuggestion';
import ReferenceList from '../models/ReferenceList';
import BaseEntityService from './BaseEntityService';

export default class PayerInformationFieldSuggestionService extends BaseEntityService<
	PayerInformationFieldSuggestion,
	PayerInformationFieldSuggestionDto
> {
	public entityTableName: string = 'bh_payer_info_fld_sug';
	entityName = 'payer-information-field-suggestions';

	map(data: Partial<PayerInformationFieldSuggestionDto> = {}) {
		return new PayerInformationFieldSuggestion({
			...data,
			dataType: new ReferenceList(data.dataType),
			values: (data.values || []).map(
				(chargeInformationValueSuggestion) =>
					new PayerInformationFieldValueSuggestion(chargeInformationValueSuggestion),
			),
			subType: new ReferenceList(data.subType),
		});
	}

	reverseMap(data: PayerInformationFieldSuggestion): PayerInformationFieldSuggestionDto {
		throw new Error('Method not implemented.');
	}
}
