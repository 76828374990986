import { cloneDeep } from 'lodash';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';
import BaseEntity, { BaseEntityDto } from './BaseEntity';
import Locator, { LocatorDto } from './Locator';
import Product, { ProductDto } from './Product';

export interface InventoryLineDto extends BaseEntityDto {
	product?: ProductDto | null;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	locator?: LocatorDto;
	line?: number;
	quantityCount?: number;
	quantityBook?: number;
}

export default class InventoryLine extends BaseEntity {
	product: Product;
	attributeSetInstance: AttributeSetInstance;
	locator: Locator;
	line: number;
	quantityCount: number;
	quantityBook: number;

	constructor(props?: Partial<InventoryLine | InventoryLineDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.product = new Product('product' in props && props.product ? props.product : {});
		this.attributeSetInstance = new AttributeSetInstance(
			'attributeSetInstance' in props && props.attributeSetInstance ? props.attributeSetInstance : {},
		);
		this.locator = new Locator(props.locator);
		this.line = props.line || 0;
		this.quantityCount = props.quantityCount || 0;
		this.quantityBook = props.quantityBook || 0;
	}
}
