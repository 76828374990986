import React, { useContext } from 'react';
import { Button, Col, Row, ToastContainer } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';
import { useUpdateCheck } from 'react-update-notification';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import { DEFAULT_DEPLOYMENT_CHECK_DELAY } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import CustomPrompt from '../CustomPrompt/CustomPrompt';

/**
 * Notification container
 * After a production build a version number from package.json is inserted into index.html
 * along a version.json file in the same directory
 * This component checks the compares the VERSION in version.json and in the meta tag of index.html
 * If the version file has a higher version then it prompts the user to update.
 *
 * Testing locally:
 * in the terminal run ` yarn run build-dev` to update the index file and manually update the
 * version.json file in the `public` folder. Navigate to a new page or wait 5 minutes for the popup.
 */

const NotificationContainer = () => {
	const { t } = useTranslation();

	const context = useContext(NavBlockingContext);

	const { status, reloadPage } = useUpdateCheck({
		type: 'interval',
		interval: DEFAULT_DEPLOYMENT_CHECK_DELAY,
		ignoreServerCache: true,
	});

	const reloader = async () => {
		if (context?.isBlocking) {
			const userWantsToProceed = await CustomPrompt(t(uiText.notificationContainer.prompt.PROMPT_MESSAGE));
			if (userWantsToProceed) {
				reloadPage();
			}
			return;
		} else {
			reloadPage();
		}
	};

	return (
		<ToastContainer position="top-center" className="notification-container">
			<Toast show={status !== 'checking' && status !== 'current'}>
				<Toast.Body className="p-2">
					<Row>
						<Col xs="auto" className="d-flex align-items-center pe-0">
							{t(uiText.notificationContainer.updateAvailable)}
						</Col>
						<Col xs="auto" className="d-flex align-items-center ms-auto ps-0">
							<Button type="button" variant="info" size="sm" onClick={reloader}>
								{t(uiText.notificationContainer.button.clickReload)}
							</Button>
						</Col>
					</Row>
				</Toast.Body>
			</Toast>
		</ToastContainer>
	);
};

export default NotificationContainer;
