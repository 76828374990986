import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useMemo, useState } from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import { FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import VisitContext from '../../contexts/VisitContext';
import { Encounter, fieldUuid, referenceUuids } from '../../models';
import { pageUuid } from '../../services/AuthService';
import { formatDate, formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import EncounterModal from './EncounterModal';
import { createNewVitalEncounter, getObservationFields, isFieldGroup } from './VisitUtil';

type FieldComponentProps = {
	visitUuid: string;
	isDataReadOnly?: boolean;
};

export type EncounterFormFields = Partial<Encounter>;

const ObservationTable = ({ visitUuid, isDataReadOnly }: FieldComponentProps) => {
	const { t } = useTranslation();

	const { setValue } = useFormContext();
	const [selectedEncounter, setSelectedEncounter] = useState<Encounter | undefined>();
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
	const { fields, remove, prepend } = useFieldArray<FieldValues, 'vitalsEncounters', 'uuid'>({
		name: 'vitalsEncounters',
		keyName: 'uuid',
	});

	const { encounterTypeWindows } = useContext(VisitContext);

	const vitalsEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.TRIAGE_DETAILS,
	);
	// get display fields for the first encounter
	const observationHeaderDisplayList = useMemo(
		() => getObservationFields(createNewVitalEncounter(vitalsEncounterTypeWindow).observations),
		[vitalsEncounterTypeWindow],
	);

	const onSubmit = (formData: Partial<Encounter>) => {
		setSelectedEncounter(undefined);
		setSelectedIndex(undefined);
		const encounter = new Encounter(formData);
		if (selectedIndex === undefined) {
			prepend(encounter);
		} else {
			// To make sure this performs quickly, just update the values (but still update the encounter so the watch is triggered)
			setValue(`vitalsEncounters.${selectedIndex}`, {
				uuid: encounter.uuid,
				encounterDate: encounter.encounterDate,
				observations: encounter.observations.map((observation) => ({
					uuid: observation.uuid,
					value: observation.value,
				})),
			});
		}
	};

	const onEditVitals = (selectedIndex?: number, encounter?: Encounter) => {
		setSelectedEncounter(encounter || createNewVitalEncounter(vitalsEncounterTypeWindow));
		setSelectedIndex(selectedIndex);
	};

	const watchFieldArray = useWatch({ name: 'vitalsEncounters' });
	const controlledFields = fields.map((field, index) => ({
		...field,
		...watchFieldArray[index],
	}));

	return (
		<>
			<Col xs={12}>
				<Table hover className="bh-table--form table-fixed w-100">
					<thead>
						<tr>
							<th style={{ width: '150px' }}>Date/Time</th>
							{observationHeaderDisplayList?.map((observationDisplay) => (
								<th
									key={observationDisplay.index}
									style={{
										width:
											!isFieldGroup(observationDisplay) &&
											observationDisplay.observation.field.uuid === fieldUuid.LAST_MENSTRUAL_PERIOD
												? '150px'
												: 'auto',
									}}
								>
									{isFieldGroup(observationDisplay)
										? observationDisplay?.observations[0].observation?.field?.fieldGroup?.abbreviation
										: observationDisplay?.observation?.field?.abbreviation}
								</th>
							))}
							{!isDataReadOnly && <th style={{ width: '75px' }}>{t(uiText.visit.button.DELETE)}</th>}
						</tr>
					</thead>
					<tbody>
						{((controlledFields || []) as unknown as Encounter[]).map((encounter, encounterIndex) => (
							<tr
								key={encounter.uuid}
								onClick={() => {
									if (!isDataReadOnly) {
										onEditVitals(encounterIndex, encounter);
									}
								}}
								className={`${isDataReadOnly ? 'bg-gray-200' : ''}`}
							>
								<td className="align-middle text-center">{formatDateAndTime(encounter.encounterDate)}</td>
								{getObservationFields(encounter?.observations)?.map((observationDisplay) => (
									<td
										className="align-middle text-center"
										key={encounter.uuid + observationDisplay.index}
										aria-label={
											isFieldGroup(observationDisplay)
												? observationDisplay?.observations[0].observation?.field?.fieldGroup?.abbreviation
												: observationDisplay?.observation?.field?.abbreviation
										}
									>
										{(isFieldGroup(observationDisplay)
											? observationDisplay.observations
													.map(
														(observationGroupDisplay) =>
															(observationGroupDisplay.observation.field.column?.reference?.uuid === referenceUuids.DATE
																? (observationGroupDisplay.observation.value &&
																		formatDate(new Date(parseInt(observationGroupDisplay.observation.value, 10)))) ||
																	'-'
																: observationGroupDisplay.observation.value) || '-',
													)
													.join(' / ')
											: observationDisplay.observation.field.column?.reference?.uuid === referenceUuids.DATE
												? (observationDisplay.observation.value &&
														formatDate(new Date(parseInt(observationDisplay.observation.value, 10)))) ||
													'-'
												: observationDisplay.observation.value) || '-'}
									</td>
								))}
								{!isDataReadOnly && (
									<td className="align-middle text-center">
										<button
											aria-label={t(uiText.visit.button.DELETE)}
											type="button"
											className="btn p-0"
											tabIndex={-1}
											onClick={(e) => {
												e.stopPropagation();
												remove(encounterIndex);
											}}
										>
											<FontAwesomeIcon icon="trash" className="border-0" />
										</button>
									</td>
								)}
							</tr>
						))}
					</tbody>
					{!isDataReadOnly && (
						<tfoot>
							<tr>
								<td colSpan={observationHeaderDisplayList.length + 1} className="align-middle px-2 py-2">
									<Button type="button" variant="success" onClick={() => onEditVitals()}>
										<FontAwesomeIcon icon={faPlus} className="me-1" />
										{t(uiText.visit.button.ADD_NEW_VITAL)}
									</Button>
								</td>
							</tr>
						</tfoot>
					)}
				</Table>
			</Col>
			{selectedEncounter && (
				<EncounterModal
					visitUuid={visitUuid}
					encounter={selectedEncounter}
					onHandleClose={() => setSelectedEncounter(undefined)}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
};
export default ObservationTable;
