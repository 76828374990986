import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
	C_BPartnerForInsurerAndDonorsDocument,
	C_BPartnerForInsurerAndDonorsQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import { BusinessPartnerDB, businessPartnerGroupInsurerAndDonorFilter } from '../../models';
import DBFilter from '../../models/DBFilter';
import ListPageState from '../../models/ListPageState';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import InsurerOrDonorForm from './InsurerOrDonorForm';

const sortedMap = { totalOpenBalance_profit: 'totalopenbalance' } as const;
const sortedReverseMap = Object.entries(sortedMap).reduce((map: any, [tableSort, dbSort]) => {
	map[dbSort] = tableSort;
	return map;
}, {});

const InsurersAndDonorsList = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { disableWrite } = useActionPrivileges(pageUuid.INSURERS_AND_DONORS);
	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid: selectedData,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<C_BPartnerForInsurerAndDonorsQuery['C_BPartnerGet']['Results'][0]>({
		fetch: useCallback(
			async (variables) =>
				(
					await graphqlClient.query({
						query: C_BPartnerForInsurerAndDonorsDocument,
						variables,
						fetchPolicy: 'network-only',
					})
				).data.C_BPartnerGet,
			[graphqlClient],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.nonPatientPayment.error.COULD_NOT_LOAD));
				}
				exception({ description: `Inurer & Donor fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});

	// Handle searching and filtering
	useEffect(() => {
		let defaultFilter = DBFilter<BusinessPartnerDB>()
			.nested('c_bp_group')
			.and(businessPartnerGroupInsurerAndDonorFilter())
			.up();
		if (searchText) {
			defaultFilter.and(DBFilter<BusinessPartnerDB>().property('name').contains(searchText));
		}
		onFilterUpdate(defaultFilter.toString());
	}, [searchText, onFilterUpdate]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		reset();
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.nonPatientPayment.title.LIST)}
							showRefreshIcon
							onRefresh={() => {
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<C_BPartnerForInsurerAndDonorsQuery['C_BPartnerGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'created',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.nonPatientPayment.tableHeaders.CREATED)}</div>
										),
										accessor: (businessPartner) => formatDate(new Date(businessPartner.Created)),
									},
									{
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.nonPatientPayment.tableHeaders.NAME)}</div>
										),
										accessor: 'Name',
									},
									{
										id: 'type',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.nonPatientPayment.tableHeaders.TYPE)}</div>
										),
										accessor: (businessPartner) => businessPartner.C_BP_Group.BH_SubType?.Name,
										disableSortBy: true,
									},
									{
										id: 'totalOpenBalance',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.nonPatientPayment.CLAIM_AMOUNT)}</div>
										),
										accessor: (businessPartner) =>
											formatNumber(
												businessPartner.TotalOpenBalance && businessPartner.TotalOpenBalance >= 0
													? businessPartner.TotalOpenBalance
													: 0,
											),
									},
									{
										id: 'totalOpenBalance_profit',
										Header: () => <div className={'React-table-header'}>{t(uiText.nonPatientPayment.PROFIT)}</div>,
										accessor: (businessPartner) =>
											formatNumber(
												businessPartner.TotalOpenBalance && businessPartner.TotalOpenBalance < 0
													? businessPartner.TotalOpenBalance * -1
													: 0,
											),
									},
									{
										id: 'isactive',
										Header: () => (
											<div className={'React-table-header'}>{t(uiText.nonPatientPayment.button.ACTIVE)}</div>
										),
										accessor: (businessPartner) => (
											<Form.Check
												aria-label={t(uiText.nonPatientPayment.button.ACTIVE)}
												defaultChecked={businessPartner.IsActive}
												disabled={true}
											/>
										),
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.nonPatientPayment.LOADING)} />;
								}}
								onFetchData={(data) => {
									if (data.sorted) {
										data.sorted = JSON.stringify(
											(JSON.parse(data.sorted) as Array<[string, string]>).map(([column, order]) =>
												column in sortedMap ? [sortedMap[column as keyof typeof sortedMap], order] : [column, order],
											),
										);
									}
									onTableUpdate(data);
								}}
								getTrGroupProps={rowProperties}
								sortBy={
									(sorted &&
										JSON.stringify(
											(JSON.parse(sorted) as Array<[string, string]>).map(([column, order]) =>
												sortedReverseMap[column] ? [sortedReverseMap[column], order] : [column, order],
											),
										)) ||
									''
								}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<InsurerOrDonorForm
					uuid={selectedData}
					onFinish={(refreshData) => {
						setViewState(ListPageState.LIST);
						if (refreshData) {
							refresh();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default InsurersAndDonorsList;
