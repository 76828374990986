import { StorageOnHand, StorageOnHandDB, StorageOnHandDto } from '../models';
import BaseEntityService from './BaseEntityService';

export default class StorageOnHandService extends BaseEntityService<StorageOnHand, StorageOnHandDto, StorageOnHandDB> {
	protected entityName: string = 'storage-on-hand';
	public entityTableName: string = 'm_storageonhand';

	map(data: StorageOnHandDto): StorageOnHand {
		return new StorageOnHand(data);
	}

	reverseMap(data: StorageOnHand): StorageOnHandDto {
		throw new Error('Method not implemented.');
	}
}
