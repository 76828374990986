import { useAsync } from 'react-use';
import useService from './useService';

/**
 * @deprecated
 */
export default function useReportAccess(reportUuid: string) {
	const { reportService } = useService();

	const hasAccess = useAsync(async () => {
		return await reportService.doesUserHaveAccessToReport(reportUuid);
	}, [reportService, reportUuid]);

	return !!hasAccess.value;
}
