import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDto } from './BaseEntity';
import PayerInformationFieldValueSuggestion, {
	PayerInformationFieldValueSuggestionDto,
} from './PayerInformationFieldValueSuggestion';
import ReferenceList, { ReferenceListDto } from './ReferenceList';

export interface PayerInformationFieldSuggestionDto extends BaseEntityDto {
	shouldFillFromPatient: boolean;
	lineNumber: number;
	dataType: ReferenceListDto;
	values: PayerInformationFieldValueSuggestionDto[];
	subType: ReferenceListDto;
}

export default class PayerInformationFieldSuggestion extends BaseEntity {
	shouldFillFromPatient: boolean;
	lineNumber: number;
	dataType: ReferenceList;
	values: PayerInformationFieldValueSuggestion[];
	subType: ReferenceList;

	/**
	 * Create a new instance of this class
	 * @param {PayerInformationFieldSuggestion} entity The entity to map to this class
	 */
	constructor(entity: DeepPartial<PayerInformationFieldSuggestion | PayerInformationFieldSuggestionDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.shouldFillFromPatient = entity.shouldFillFromPatient === true;
		this.lineNumber = entity.lineNumber || 0;
		this.dataType = new ReferenceList(entity.dataType);
		this.values = (entity.values || []).map((value) => new PayerInformationFieldValueSuggestion(value));
		this.subType = new ReferenceList(entity.subType);
	}
}
