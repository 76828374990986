import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import BandaModal, { closeModal } from './BandaModal';

export const FyiModalComponent = ({ children, ...props }: ModalProps) => {
	const { t } = useTranslation();
	return (
		<Modal {...props}>
			<Modal.Header>{t(uiText.modal.WARNING)}</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				<Button
					variant="danger"
					onClick={(e) => {
						closeModal(e);
						props.onHide?.();
					}}
				>
					{t(uiText.modal.OK)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

async function FyiModal(message: string) {
	return BandaModal<void>(<FyiModalComponent>{message}</FyiModalComponent>);
}

export default FyiModal;
