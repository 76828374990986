import { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { REFERENCE_LIST_UUID_DATA_TYPE_LIST } from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import { AdditionalInformationFromVisitiFormFields } from './AdditionalInformationFromVisit';
import AdditionalInfoValuesFormRow from './AdditionalInfoValuesFormRow';

export type AdditionalInfoValuesFormFields = { addNewPayerInformationFieldValue: { name: string }[] };

type AdditionalInfoValuesFormProps = {
	field: AdditionalInformationFromVisitiFormFields['BH_Payer_Info_FldList'][0];
	index: number;
};

/**
 * This is the component for the additional info values form component. It is kept in this file
 * because it's not intended to be reused. It is separated to avoid re-rendering the whole form.
 * @param {{field: object & { id: string }, index: number}} props The props for this component
 * @returns {ReactElement}
 */
const AdditionalInfoValuesForm = ({
	field: chargeInformationList,
	index: chargeInformationListIndex,
}: AdditionalInfoValuesFormProps) => {
	const { t } = useTranslation();
	const { register, getValues, setValue, setFocus } = useFormContext<AdditionalInformationFromVisitiFormFields>();
	const payerInformationFieldListValueName = `BH_Payer_Info_FldList.${chargeInformationListIndex}`;
	const payerInformationFieldValueListValueName = `${payerInformationFieldListValueName}.BH_Payer_Info_Fld_ValList`;
	const { fields, append, remove, ...restOfUseFieldArrayMethods } = useFieldArray<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList'
	>({
		name: payerInformationFieldValueListValueName as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList',
	});
	// We have to specify the default value because, since it's a dynamic field, one wouldn't be defined in the useForm initialization
	const payerInformationFieldIsActive = useWatch<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.IsActive'
	>({
		name: `${payerInformationFieldListValueName}.IsActive` as 'BH_Payer_Info_FldList.0.IsActive',
	});
	// We have to specify the default value because, since it's a dynamic field, one wouldn't be defined in the useForm initialization
	const dataTypeUuid = useWatch<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.BH_PayerInfoFieldDataTypeUU'
	>({
		name: `${payerInformationFieldListValueName}.BH_PayerInfoFieldDataTypeUU` as 'BH_Payer_Info_FldList.0.BH_PayerInfoFieldDataTypeUU',
	});
	const addNewPayerInformationFieldValue = useWatch<
		AdditionalInformationFromVisitiFormFields,
		'addNewPayerInformationFieldValue.0.name'
	>({
		name: `addNewPayerInformationFieldValue.${chargeInformationListIndex}.name` as 'addNewPayerInformationFieldValue.0.name',
	});
	const previousInformationFieldValues = useRef(
		getValues(payerInformationFieldValueListValueName as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList'),
	);
	const [viewModal, setViewModal] = useState(false);
	const disableInput = dataTypeUuid !== REFERENCE_LIST_UUID_DATA_TYPE_LIST || !payerInformationFieldIsActive;
	const [shouldFocusOnLastField, setShouldFocusOnLastField] = useState(false);

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewPayerInformationFieldValue) {
			append({ UU: v4(), Name: addNewPayerInformationFieldValue, IsActive: true, isNew: true });
			setValue(`addNewPayerInformationFieldValue.${chargeInformationListIndex}.name`, '');
			setShouldFocusOnLastField(true);
		}
	}, [addNewPayerInformationFieldValue, append, chargeInformationListIndex, setValue]);
	// If there are fields and the new charge name is present, clear it and focus on the new row added
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField) {
			setFocus(
				`${payerInformationFieldValueListValueName}.${fields.length - 1}.Name` as `BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList.0.Name`,
			);
			setShouldFocusOnLastField(false);
		}
	}, [payerInformationFieldValueListValueName, fields.length, setFocus, shouldFocusOnLastField]);

	return (
		<>
			<fieldset disabled={disableInput}>
				<input
					type="hidden"
					{...register(`BH_Payer_Info_FldList.${chargeInformationListIndex}.valuesDisplay`, {
						validate: () =>
							dataTypeUuid !== REFERENCE_LIST_UUID_DATA_TYPE_LIST ||
							chargeInformationList.BH_Payer_Info_Fld_ValList.length > 0,
					})}
				/>
				{disableInput && (
					<Form.Control
						aria-label={t(uiText.nonPatientPayment.tableHeaders.VALUES)}
						defaultValue={getValues(`BH_Payer_Info_FldList.${chargeInformationListIndex}.valuesDisplay`)}
					/>
				)}
				{!disableInput && (
					<button
						aria-label={t(uiText.nonPatientPayment.tableHeaders.VALUES)}
						type="button"
						className="btn w-100 text-start btn-input border-0"
						onClick={() => {
							previousInformationFieldValues.current = getValues(
								payerInformationFieldValueListValueName as 'BH_Payer_Info_FldList.0.BH_Payer_Info_Fld_ValList',
							);
							setViewModal(true);
						}}
					>
						{getValues(`BH_Payer_Info_FldList.${chargeInformationListIndex}.valuesDisplay`)}
					</button>
				)}
			</fieldset>
			<Modal show={viewModal}>
				<Modal.Header>{t(uiText.nonPatientPayment.EDIT_LIST_VALUES)}</Modal.Header>
				<Modal.Body>
					<table className="bh-table--form">
						<thead>
							<tr>
								<th className="w-11">{t(uiText.nonPatientPayment.tableHeaders.ORDER)}</th>
								<th className="w-14">{t(uiText.nonPatientPayment.button.ACTIVE)}</th>
								<th>{t(uiText.nonPatientPayment.tableHeaders.VALUE)}</th>
								<th className="w-14">{t(uiText.nonPatientPayment.button.DELETE)}</th>
							</tr>
						</thead>
						<tbody>
							{fields.map((field, index) => (
								<AdditionalInfoValuesFormRow
									key={field.id}
									field={field}
									index={index}
									baseName={payerInformationFieldValueListValueName}
									remove={remove}
									isLast={index === fields.length - 1}
									{...restOfUseFieldArrayMethods}
								/>
							))}
							<tr>
								<td />
								<td />
								<td>
									<Form.Control
										aria-label={t(uiText.nonPatientPayment.tableHeaders.VALUE)}
										{...register(`addNewPayerInformationFieldValue.${chargeInformationListIndex}.name`)}
										placeholder={t(uiText.nonPatientPayment.label.ENTER_VALUE)}
									/>
								</td>
								<td />
							</tr>
						</tbody>
					</table>
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="button"
						variant="danger"
						onClick={() => {
							setValue(
								`BH_Payer_Info_FldList.${chargeInformationListIndex}.BH_Payer_Info_Fld_ValList`,
								previousInformationFieldValues.current,
							);
							previousInformationFieldValues.current = [];
							setViewModal(false);
						}}
					>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button
						type="button"
						variant="success"
						className="ms-auto"
						data-dismiss="modal"
						onClick={() => {
							setViewModal(false);
							setValue('hasInfoBeenModified', 'true');
							setValue(
								`BH_Payer_Info_FldList.${chargeInformationListIndex}.valuesDisplay`,
								getValues(`BH_Payer_Info_FldList.${chargeInformationListIndex}.BH_Payer_Info_Fld_ValList`)
									.map((payerInformationFieldListValue) => payerInformationFieldListValue.Name)
									.join(', '),
							);
						}}
					>
						{t(uiText.nonPatientPayment.button.SAVE)}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default AdditionalInfoValuesForm;
