import { cloneDeep, isNumber } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import AttributeSetInstance, { AttributeSetInstanceDB, AttributeSetInstanceDto } from './AttributeSetInstance';
import { BaseEntityDB } from './BaseEntity';
import BaseMetadata, { BaseMetadataDto } from './BaseMetadata';
import Locator, { LocatorDB, LocatorDto } from './Locator';
import Product, { ProductDB, ProductDto } from './Product';

export interface StorageOnHandDB extends BaseEntityDB {
	qtyonhand: number;
	m_attributesetinstance: AttributeSetInstanceDB;
	m_product: ProductDB;
	m_locator: LocatorDB;
	'm_product.name': string;
	'm_locator.m_warehouse.name': string;
	'm_attributesetinstance.guaranteedate': number;
	shelflife: number;
}

export interface StorageOnHandDto extends BaseMetadataDto {
	quantityOnHand: number;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	locator?: LocatorDto | null;
	dateMaterialPolicy?: number;
	product?: ProductDto | null;
}

export default class StorageOnHand extends BaseMetadata {
	quantityOnHand: number;
	attributeSetInstance: AttributeSetInstance;
	locator: Locator;
	dateMaterialPolicy: number | undefined;
	product: Product;

	constructor(props: DeepPartial<StorageOnHand | StorageOnHandDto> = {}) {
		props = cloneDeep(props);
		super(props);

		this.quantityOnHand = ('quantityOnHand' in props && isNumber(props?.quantityOnHand) && props.quantityOnHand) || 0;
		this.attributeSetInstance = new AttributeSetInstance(
			'attributeSetInstance' in props && props.attributeSetInstance ? props.attributeSetInstance : {},
		);
		this.locator = new Locator('locator' in props && props.locator ? props.locator : {});
		if ('dateMaterialPolicy' in props) {
			this.dateMaterialPolicy = props.dateMaterialPolicy;
		}
		this.product = new Product('product' in props && props.product ? props.product : {});
	}
}
