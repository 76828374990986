import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import ConceptExtra, { ConceptExtraDB, ConceptExtraDto } from './ConceptExtra';
import { ConceptDB } from './Concept';

export enum ConceptMappingMapType {
	CONCEPT_SET = 'CONCEPT-SET',
	Q_AND_A = 'Q-AND-A',
}

export interface ConceptMappingDB extends BaseMetadataDB {
	bh_oclid: string;
	bh_map_type: string;
	bh_from_concept_code: string;
	bh_from_concept_name_resolved: string;
	bh_to_concept_code: string;
	bh_to_concept_name_resolved: string;
	bh_concept_extra: ConceptExtraDB;
	bh_concept: ConceptDB;
}

export interface ConceptMappingDto extends BaseMetadataDto {
	fromConceptCode?: string;
	fromConceptName?: string;
	fromConceptNameResolved?: string;
	toConceptCode?: string;
	toConceptName?: string;
	toConceptNameResolved?: string;
	mapType?: string;
	oclId?: string;
	conceptExtras?: ConceptExtraDto[];
}

export default class ConceptMapping extends BaseMetadata {
	fromConceptCode: string;
	fromConceptName: string;
	fromConceptNameResolved: string;
	toConceptCode: string;
	toConceptName: string;
	toConceptNameResolved: string;
	mapType: string;
	oclId: string;
	conceptExtras: ConceptExtra[];

	constructor(props?: Partial<ConceptMapping | ConceptMappingDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.fromConceptCode = props.fromConceptCode || '';
		this.fromConceptName = props.fromConceptName || '';
		this.fromConceptNameResolved = props.fromConceptNameResolved || '';
		this.toConceptCode = props.toConceptCode || '';
		this.toConceptName = props.toConceptName || '';
		this.toConceptNameResolved = props.toConceptNameResolved || '';
		this.mapType = props.mapType || '';
		this.oclId = props.oclId || '';
		this.conceptExtras = (props.conceptExtras || []).map((conceptExtra) => new ConceptExtra(conceptExtra));
	}
}
