import React, { Fragment, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';
import { v4 } from 'uuid';
import { fieldUuid, Observation, referenceUuids } from '../../models';
import ObservationDisplay from './ObservationDisplay';
import { getObservationFields, isFieldGroup } from './VisitUtil';

type FieldComponentProps = {
	visitUuid: string;
	fieldPrefix?: string;
	displayCondensed?: boolean;
};

const ObservationFormGroup = ({ visitUuid, fieldPrefix, displayCondensed }: FieldComponentProps) => {
	const { getValues, register, setValue } = useFormContext();
	const observationFieldsPrefix = `${fieldPrefix ? fieldPrefix + '.' : ''}observations`;
	const observations = getValues(observationFieldsPrefix) as Observation[];
	const controlIdPrefix = useMemo(() => fieldPrefix || v4(), [fieldPrefix]);
	const condensionFactor = displayCondensed ? 2 : 1;

	const observationDisplayList = getObservationFields(observations);

	// Create a list of the form names
	const formFieldNamesForBmiCalculation = useMemo(() => {
		const bmiIndex = observationDisplayList
			.flatMap((observationDisplay) =>
				isFieldGroup(observationDisplay) ? observationDisplay.observations : observationDisplay,
			)
			.find((observationDisplay) => observationDisplay.observation.field.uuid === fieldUuid.BMI)?.index;
		const heightIndex = observationDisplayList
			.flatMap((observationDisplay) =>
				isFieldGroup(observationDisplay) ? observationDisplay.observations : observationDisplay,
			)
			.find((observationDisplay) => observationDisplay.observation.field.uuid === fieldUuid.HEIGHT)?.index;
		const weightIndex = observationDisplayList
			.flatMap((observationDisplay) =>
				isFieldGroup(observationDisplay) ? observationDisplay.observations : observationDisplay,
			)
			.find((observationDisplay) => observationDisplay.observation.field.uuid === fieldUuid.WEIGHT)?.index;
		return {
			bmi: bmiIndex !== undefined ? `${observationFieldsPrefix}.${bmiIndex}.value` : '',
			height: heightIndex !== undefined ? `${observationFieldsPrefix}.${heightIndex}.value` : '',
			weight: weightIndex !== undefined ? `${observationFieldsPrefix}.${weightIndex}.value` : '',
		};
	}, [observationDisplayList, observationFieldsPrefix]);
	// For calculated observations, get a list of fields that we need to watch
	const heightAndWeightWatchers = useWatch({
		name: [formFieldNamesForBmiCalculation.height, formFieldNamesForBmiCalculation.weight],
	}) as string[];
	// Any time any of these form values change, any that are calculated should also update
	useDeepCompareEffect(() => {
		const height = parseFloat(getValues(formFieldNamesForBmiCalculation.height));
		const weight = parseFloat(getValues(formFieldNamesForBmiCalculation.weight));
		if (!isNaN(height) && !isNaN(weight) && height > 0) {
			setValue(
				formFieldNamesForBmiCalculation.bmi,
				(Math.round((weight * 100) / Math.pow(height / 100, 2)) / 100).toString(),
			);
		} else if (formFieldNamesForBmiCalculation.bmi) {
			setValue(formFieldNamesForBmiCalculation.bmi, '');
		}
	}, [heightAndWeightWatchers, formFieldNamesForBmiCalculation, setValue]);

	return (
		<>
			{observationDisplayList?.map((observationDisplay) => (
				<Col
					xs={
						!isFieldGroup(observationDisplay) &&
						observationDisplay.observation.field.column?.reference?.uuid === referenceUuids.TEXT_LONG
							? 12
							: 4
					}
					key={observationDisplay.index}
				>
					<Row className="h-100">
						<Form.Group as={Fragment} controlId={`${controlIdPrefix}${observationDisplay.index}${visitUuid}`}>
							{/* The first column is the label */}
							<Col
								xs={
									!isFieldGroup(observationDisplay) &&
									observationDisplay.observation.field.column?.reference?.uuid === referenceUuids.TEXT_LONG
										? 1 * condensionFactor
										: 3 * condensionFactor
								}
								className={`d-flex ${
									!isFieldGroup(observationDisplay) &&
									observationDisplay.observation.field.column?.reference?.uuid !== referenceUuids.TEXT_LONG
										? 'align-items-center'
										: ''
								}`}
							>
								<Form.Label column>
									{isFieldGroup(observationDisplay)
										? observationDisplay.observations[0].observation.field.fieldGroup?.name
										: observationDisplay.observation.field.name}
								</Form.Label>
							</Col>

							{/* Value column */}
							<Col
								xs={
									!isFieldGroup(observationDisplay) &&
									observationDisplay.observation.field.column?.reference?.uuid === referenceUuids.TEXT_LONG
										? 12 - 1 * condensionFactor
										: 12 - 3 * condensionFactor
								}
								className="d-flex align-items-center"
							>
								{isFieldGroup(observationDisplay) ? (
									<>
										{observationDisplay.observations.map(
											(groupedObservationDisplay, groupedObservationDisplayIndex) => (
												<Fragment key={groupedObservationDisplay.observation.uuid}>
													<input
														type="hidden"
														{...register(`${observationFieldsPrefix}.${groupedObservationDisplay.index}.field.uuid`)}
														defaultValue={groupedObservationDisplay.observation.field.uuid}
													/>
													{groupedObservationDisplayIndex > 0 && <span className="mx-1">/</span>}
													<ObservationDisplay
														name={`${observationFieldsPrefix}.${groupedObservationDisplay.index}.value`}
														observation={groupedObservationDisplay.observation}
													/>
												</Fragment>
											),
										)}
									</>
								) : (
									<ObservationDisplay
										name={`${observationFieldsPrefix}.${observationDisplay.index}.value`}
										observation={observationDisplay.observation}
									/>
								)}
							</Col>
						</Form.Group>
					</Row>
				</Col>
			))}
		</>
	);
};

export default ObservationFormGroup;
