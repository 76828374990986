import React, { Fragment } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReferenceListDto } from '../../models';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';

type FinalVisitUpdateFields = {
	processStage: Pick<ReferenceListDto, 'value'>;
};

type SaveAndSendToModalProps = {
	processStageList: ReferenceListDto[];
	onHandleClose: () => void;
	data: FinalVisitUpdateFields;
	onHandleSubmit: (finalVisitUpdateData: FinalVisitUpdateFields) => void;
};

const SaveAndSendToModal = ({ processStageList, onHandleClose, data, onHandleSubmit }: SaveAndSendToModalProps) => {
	const { t } = useTranslation();
	const formMethods = useForm<FinalVisitUpdateFields>({
		defaultValues: data,
	});

	return (
		<Modal show={true}>
			<Form onSubmit={formMethods.handleSubmit(onHandleSubmit)} className="order-form">
				<Modal.Header>{t(uiText.visit.form.patient.SEND_PATIENT_TO)}</Modal.Header>
				<Modal.Body>
					<Form.Group as={Fragment}>
						<Col xs="auto" className="d-flex align-items-center">
							<DynamicSelect
								aria-label={t(uiText.visit.form.patient.SEND_PATIENT_TO)}
								{...formMethods.register('processStage.value')}
								isLoading={!(processStageList || []).length}
							>
								<option value="">&nbsp;</option>
								{(processStageList || []).map((processStage) => (
									<option key={processStage.uuid} value={processStage.value || ''}>
										{processStage.name}
									</option>
								))}
							</DynamicSelect>
						</Col>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="button"
						variant="danger"
						onClick={() => {
							onHandleClose();
						}}
					>
						{t(uiText.modal.button.CANCEL)}
					</Button>
					<Button type="submit" variant="success" className="ms-auto">
						{t(uiText.visit.button.SAVE_BILL)}
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default SaveAndSendToModal;
