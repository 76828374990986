import { debounce } from 'lodash';
import { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { SEARCH } from '../../utils/Constants';
import Search from '../Search/Search';

export type WorkspaceMenuSearchProps = {
	onSearch: (searchText: string) => void;
};

const WorkspaceMenuSearch = ({ onSearch }: WorkspaceMenuSearchProps) => {
	const doSearch = useMemo(
		() =>
			debounce((searchString) => {
				onSearch(searchString);
			}, SEARCH.INPUT_DELAY),
		[onSearch],
	);

	return (
		<Col xs="auto" className="my-2 workspace-menu workspace-menu__page-search pe-0">
			<Search onSearch={doSearch} />
		</Col>
	);
};

export default WorkspaceMenuSearch;
