import { Button, Form, Modal } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import PriceUpdateModalRow from './PriceUpdateModalRow';

type PriceUpdateModalProps = {
	data: PriceUpdateFormValues['products'];
	onSubmit: SubmitHandler<PriceUpdateFormValues>;
	cancel: () => void;
};

export type PriceUpdateFormValues = {
	products: {
		UU: string;
		newBuyPrice: number;
		newPriceMargin: number | null;
		newSellPrice: number | null;
		wasSellPriceChanged: boolean;
	}[];
};

const PriceUpdateModal = ({ data, onSubmit, cancel }: PriceUpdateModalProps) => {
	const formMethods = useForm<PriceUpdateFormValues>({ defaultValues: { products: data } });
	const { fields = [] } = useFieldArray<PriceUpdateFormValues, 'products', 'UU'>({
		control: formMethods.control,
		name: 'products',
		keyName: 'UU',
	});
	const { t } = useTranslation();

	return (
		<Modal show={true} size="lg" onHide={() => {}}>
			<Modal.Header>{t(uiText.receiveProduct.modal.TITLE)}</Modal.Header>
			<Modal.Body>
				<FormProvider {...formMethods}>
					<Form onSubmit={formMethods.handleSubmit(onSubmit)}>
						<table className="bh-table--form">
							<thead>
								<tr>
									<th className="w-30">{t(uiText.receiveProduct.modal.columns.PRODUCT)}</th>
									<th className="w-14">{t(uiText.receiveProduct.modal.columns.PREVIOUS_BUY_PRICE)}</th>
									<th className="w-14">{t(uiText.receiveProduct.modal.columns.CURRENT_BUY_PRICE)}</th>
									<th className="w-14">{t(uiText.receiveProduct.modal.columns.OLD_MARGIN)}</th>
									<th className="w-14">{t(uiText.receiveProduct.modal.columns.NEW_MARGIN)}</th>
									<th className="w-14">{t(uiText.receiveProduct.modal.columns.SELL_PRICE)}</th>
								</tr>
							</thead>
							<tbody>
								{fields.map((item, index) => (
									<PriceUpdateModalRow key={item.UU} field={item} index={index} />
								))}
							</tbody>
						</table>
						{!!formMethods.formState?.errors?.products?.length && (
							<div className="text-danger">{t(uiText.order.error.PRODUCT_MISSING_SELL_PRICE_GENERIC)}</div>
						)}
					</Form>
				</FormProvider>
			</Modal.Body>
			<Modal.Footer>
				<Button type="button" variant="danger" onClick={cancel}>
					{t(uiText.receiveProduct.button.CANCEL)}
				</Button>
				<Button type="button" variant="success" className="ms-auto" onClick={formMethods.handleSubmit(onSubmit)}>
					{t(uiText.receiveProduct.button.SAVE)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PriceUpdateModal;
