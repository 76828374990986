import { createContext } from 'react';
import { EncounterTypeWindow } from '../models';

export interface VisitContextInterface {
	encounterTypeWindows: EncounterTypeWindow[];
}

const VisitContext = createContext<VisitContextInterface>({ encounterTypeWindows: [] });

export default VisitContext;
