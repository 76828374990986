import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseMetadata from './BaseMetadata';
import BusinessPartner from './BusinessPartner';
import BusinessPartnerSpecificPayerInformation from './BusinessPartnerSpecificPayerInformation';
import Charge from './Charge';
import ReferenceList from './ReferenceList';

export default class PaymentInformation extends BaseMetadata {
	paymentType?: ReferenceList;
	amount?: number;
	description: string;
	payer: BusinessPartner;
	charge: Charge;
	businessPartnerSpecificPayerInformationList: BusinessPartnerSpecificPayerInformation[];
	payerSubTypeLimiter?: ReferenceList;
	subType?: ReferenceList;
	isPayment: 'true' | 'false';
	isWaiver: 'true' | 'false';

	constructor(entity: DeepPartial<PaymentInformation> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.paymentType = entity.paymentType && new ReferenceList(entity.paymentType);
		this.amount = entity.amount;
		this.description = entity.description || '';
		this.payer = new BusinessPartner(entity.payer);
		this.charge = new Charge(entity.charge);
		this.businessPartnerSpecificPayerInformationList = (entity.businessPartnerSpecificPayerInformationList || []).map(
			(businessPartnerSpecificPayerInformation) =>
				new BusinessPartnerSpecificPayerInformation(businessPartnerSpecificPayerInformation),
		);
		this.subType = entity.subType && new ReferenceList(entity.subType);
		this.payerSubTypeLimiter = entity.payerSubTypeLimiter && new ReferenceList(entity.payerSubTypeLimiter);
		this.isPayment = entity.isPayment || 'false';
		this.isWaiver = entity.isWaiver || 'false';
	}
}
