import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import Account, { AccountDto } from './Account';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import ChargeType, { chargeTypeDefaultNames, ChargeTypeDto } from './ChargeType';
import DBFilter from './DBFilter';
import ReferenceList, { ReferenceListDto } from './ReferenceList';

export const chargeDefaultNames = { BAD_DEBT: 'Bad debt write-off - DO NOT CHANGE' } as const;

export const chargeSubType = { Insurance: 'I', Waiver: 'W', Donation: 'D' } as const;

export interface ChargeDB extends BaseEntityDB {
	type: string;
	c_chargetype: BaseEntityDB;
	bh_subtype: string;
}

export interface ChargeDto extends BaseEntityDto {
	subType: ReferenceListDto | null;
	account?: AccountDto | null;
	chargeType?: ChargeTypeDto | null;
	isLocked?: boolean | null;
}

export const chargeNonPatientPaymentFilter = () =>
	DBFilter<ChargeDB>().nested('c_chargetype').property('name').equals(chargeTypeDefaultNames.NON_PATIENT_PAYMENTS).up();

export const chargeExpenseCategoryFilter = () =>
	DBFilter<ChargeDB>().nested('c_chargetype').property('name').equals(chargeTypeDefaultNames.EXPENSE_CATEGORY).up();

export const chargeOneOffsFilter = () =>
	DBFilter<ChargeDB>().nested('c_chargetype').property('name').equals(chargeTypeDefaultNames.ONE_OFFS).up();

/**
 * The class holding information about charges
 * @property {ReferenceList} subType The specified sub-type of the charge
 * @property {Account} account The account this charge maps to
 * @property {ChargeType} chargeType The charge type of this charge
 * @property {boolean} isLocked Whether this charge is locked and not editable or not
 */
export default class Charge extends BaseEntity {
	subType: ReferenceList;
	account: Account;
	chargeType: ChargeType;
	isLocked: boolean;

	/**
	 * Create a new instance of this class
	 * @param {Charge} entity The entity to map to this class
	 */
	constructor(entity: DeepPartial<Charge | ChargeDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.subType = new ReferenceList(entity.subType || {});
		this.account = new Account(entity.account || {});
		this.chargeType = new ChargeType(entity.chargeType || {});
		this.isLocked = entity.isLocked === true;
	}

	getDropdownDisplay() {
		return this.name + (this.description ? ` -> ${this.description}` : '');
	}
}
