import { cloneDeep } from 'lodash';
import { formatDateAndTime } from '../utils/DateUtil';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import { BusinessPartnerDB } from './BusinessPartner';
import { InvoiceDB } from './Invoice';
import { MovementDB } from './Movement';
import Role, { RoleDB, RoleDto } from './Role';

export interface UserDto extends BaseMetadataDto {
	name?: string;
	roles?: RoleDto[] | null;
	dateLastLogin?: number | null;
	resetPassword?: string;
}

export interface UserDB extends BaseMetadataDB {
	'm_movement::ad_user_id->createdby': MovementDB;
	dateLastLogin: Date;
	action: string;
	role: string;
	name: string;
	'c_invoice::ad_user_id->createdby': InvoiceDB;
	'ad_user_roles.ad_role': RoleDB;
	c_bpartner: BusinessPartnerDB;
	ad_user_uu: string;
}

export default class User extends BaseMetadata {
	name: string;
	created: string;
	roles: Role[];
	dateLastLogin?: number;
	resetPassword?: string;

	constructor(entity: Partial<User | UserDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.name = entity.name || '';
		this.created = entity.created || '';
		this.roles = (entity.roles || []).map((role) => new Role(role));
		this.dateLastLogin = entity.dateLastLogin || undefined;
		this.resetPassword = entity.resetPassword || '';
	}

	/**
	 * The last login  date, but formatted
	 */
	get dateLastLoginFormatted(): string {
		return this.dateLastLogin ? formatDateAndTime(new Date(this.dateLastLogin)) : '';
	}
}
