import { cloneDeep } from 'lodash';
import { enumFromStringValue } from '../utils/EnumUtil';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Locator, { LocatorDB, LocatorDto } from './Locator';
import Movement, { MovementDto } from './Movement';
import Order, { OrderDto } from './Order';
import Product, { ProductDB, ProductDto } from './Product';
import Transaction, { TransactionDto } from './Transaction';
import User, { UserDto } from './User';
import Visit, { VisitDto } from './Visit';

export enum TransactionType {
	DRAFTED = 'Drafted',
	INITIAL_INVENTORY = 'Initial Inventory',
	MANUAL_INVENTORY_ADJUSTMENT = 'Manual Inventory Adjustment',
	OTC_SALE = 'OTC Sale',
	PATIENT_RETURNS = 'Customer Returns',
	PATIENT_SALE = 'Patient Sale',
	PRODUCT_RECEIVED = 'Product Received',
	REACTIVATE_PATIENT_SALE = 'Reactivate Patient Sale',
	REACTIVATED_PRODUCT_RECEIPT = 'Reactivated Product Receipt',
	SUPPLIER_RETURNS = 'Vendor Returns',
	TRANSFER_IN = 'Transfer In',
	TRANSFER_OUT = 'Transfer Out',
	UNKNOWN_STATUS = 'Unknown Status',
	VOID_PATIENT_SALE = 'Void Patient Sale',
	VOID_PRODUCT_RECEIPT = 'Void Product Receipt',
}

export interface InventoryTransactionDB extends BaseMetadataDB {
	m_product: ProductDB;
	m_locator: LocatorDB;
}

export interface InventoryTransactionDto extends BaseMetadataDto {
	transaction?: TransactionDto | null;
	order?: OrderDto | null;
	movement?: MovementDto | null;
	visit?: VisitDto | null;
	locator?: LocatorDto | null;
	product?: ProductDto | null;
	movementQuantity: number;
	attributeSetInstance?: AttributeSetInstanceDto | null;
	transactionType: string;
	user: UserDto;
	runningTotal?: number;
}

export default class InventoryTransaction extends BaseMetadata {
	transaction: Transaction;
	order: Order;
	movement: Movement;
	visit: Visit;
	locator: Locator;
	product: Product;
	movementQuantity: number;
	attributeSetInstance: AttributeSetInstance;
	transactionType?: TransactionType;
	user: User;
	runningTotal?: number;

	constructor(props?: Partial<InventoryTransaction | InventoryTransactionDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.transaction = new Transaction('transaction' in props && props.transaction ? props.transaction : {});
		this.order = new Order('order' in props && props.order ? props.order : {});
		this.locator = new Locator('locator' in props && props.locator ? props.locator : {});
		this.movement = new Movement('movement' in props && props.movement ? props.movement : {});
		this.visit = new Visit('visit' in props && props.visit ? props.visit : {});
		this.product = new Product('product' in props && props.product ? props.product : {});
		this.movementQuantity = props.movementQuantity || 0;
		this.attributeSetInstance = new AttributeSetInstance(
			'attributeSetInstance' in props && props.attributeSetInstance ? props.attributeSetInstance : {},
		);
		this.transactionType = enumFromStringValue(TransactionType, props.transactionType || '');
		this.user = new User('user' in props && props.user ? props.user : {});
		this.runningTotal = props.runningTotal;
	}
}
