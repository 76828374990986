import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';

export type WorkspaceMenuNewButtonProps = {
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const WorkspaceMenuNewButton = ({ onClick: onNewEntity }: WorkspaceMenuNewButtonProps) => {
	const { t } = useTranslation();

	return (
		<Col xs="auto" className="my-2 ms-auto flex-shrink-0">
			<BasicButton
				name={uiText.toolbar.button.NEW}
				text={t(uiText.toolbar.button.NEW)}
				icon="plus"
				variant="success"
				className="float-end"
				active={true}
				onClick={onNewEntity}
			/>
		</Col>
	);
};

export default WorkspaceMenuNewButton;
