import axios from 'axios';
import { isArray } from 'lodash';
import { businessPartnerGroupName, DBFilter, Filter } from '../models';
import BusinessPartner, { BusinessPartnerDB, BusinessPartnerDto } from '../models/BusinessPartner';
import BusinessPartnerGeneralPayerInformation from '../models/BusinessPartnerGeneralPayerInformation';
import BusinessPartnerPayerInformation, {
	BusinessPartnerPayerInformationDto,
} from '../models/BusinessPartnerPayerInformation';
import { configs } from '../utils/Configs';
import { SEARCH } from '../utils/Constants';
import BaseEntityService from './BaseEntityService';

export default class BusinessPartnerService extends BaseEntityService<
	BusinessPartner,
	BusinessPartnerDto,
	BusinessPartnerDB
> {
	public entityTableName: string = 'c_bpartner';
	entityName = 'business-partners';
	public overTheCounterPatientKey: string = 'overTheCounterPatientKey';

	map(data: BusinessPartnerDto): BusinessPartner {
		return new BusinessPartner(data);
	}

	reverseMap(data: BusinessPartner): BusinessPartnerDto {
		return { ...data, dateOfBirth: data.dateOfBirth?.toISOString() || new Date().toISOString(), lastVisitDate: '' };
	}

	/**
	 * Get the information associated with this business partner
	 * @param {string} businessPartnerUuid The UUID of the business partner to fetch information for
	 * @returns {Promise<BusinessPartnerPayerInformation[]>}
	 */
	async getPayerInformationList(businessPartnerUuid: string): Promise<BusinessPartnerPayerInformation[]> {
		return axios
			.get<
				BusinessPartnerPayerInformationDto[]
			>(`${configs.apiUrl}/${this.entityName}/${businessPartnerUuid}/payer-information-list`)
			.then(({ data }) => {
				if (isArray(data)) {
					return data.map(this.mapCharges);
				}
				return [];
			});
	}

	/**
	 * Save the specified data for this business partner
	 * @param {string} businessPartnerUuid The UUID of the business partner to save information for
	 * @param {BusinessPartnerPayerInformation[]} data The data to save
	 * @returns {Promise<BusinessPartnerPayerInformation[]>}
	 */
	async savePayerInformationList(
		businessPartnerUuid: string,
		data: BusinessPartnerPayerInformation[],
	): Promise<BusinessPartnerPayerInformation[]> {
		return axios
			.post<
				BusinessPartnerPayerInformationDto[]
			>(`${configs.apiUrl}/${this.entityName}/${businessPartnerUuid}/payer-information-list`, data)
			.then(({ data }) => {
				if (isArray(data)) {
					return data.map(this.mapCharges);
				}
				return [];
			});
	}

	/**
	 * Save the specified data for this business partner
	 * @param {string} businessPartnerUuid The UUID of the business partner to save information for
	 * @param {string} businessPartnerPayerInformationUuid The UUID of the business partner charge to save
	 * @param {BusinessPartnerPayerInformation} data The data to save
	 * @returns {Promise<BusinessPartnerPayerInformation>}
	 */
	async savePayerInformation(
		businessPartnerUuid: string,
		businessPartnerPayerInformationUuid: string,
		data: BusinessPartnerPayerInformation,
	): Promise<BusinessPartnerPayerInformation> {
		return axios
			.post<BusinessPartnerPayerInformationDto>(
				`${configs.apiUrl}/${this.entityName}/${businessPartnerUuid}/payer-information-list/${businessPartnerPayerInformationUuid}`,
				data,
			)
			.then(({ data }) => this.mapCharges(data));
	}

	mapCharges(data: Partial<BusinessPartnerPayerInformationDto> = {}) {
		return new BusinessPartnerPayerInformation({
			...data,
			businessPartnerGeneralPayerInformationList: (data.businessPartnerGeneralPayerInformationList || []).map(
				(businessPartnerChargeInformation) =>
					new BusinessPartnerGeneralPayerInformation(businessPartnerChargeInformation),
			),
		});
	}

	/**
	 * Retrieve Over The Counter Patient
	 * @param useCache
	 */
	async getOverTheCounterPatient(useCache?: boolean): Promise<BusinessPartner> {
		useCache = useCache !== false;
		const overTheCounterPatientString = localStorage.getItem(this.overTheCounterPatientKey);
		if (overTheCounterPatientString !== null && useCache) {
			return new BusinessPartner(JSON.parse(overTheCounterPatientString));
		}

		return this.get(
			SEARCH.DEFAULT_PAGE_NUMBER,
			SEARCH.DEFAULT_PAGE_SIZE,
			undefined,
			DBFilter<BusinessPartnerDB>().nested('c_bp_group').property('name').equals(businessPartnerGroupName.OTC).up(),
		).then((response) => {
			localStorage.setItem(this.overTheCounterPatientKey, JSON.stringify(response.results[0]));
			return response.results[0];
		});
	}

	getPatientSearchFilter(query: string): Filter<BusinessPartnerDB> {
		return DBFilter<BusinessPartnerDB>()
			.nested('c_bp_group')
			.property('name')
			.equals(businessPartnerGroupName.PATIENTS)
			.up()
			.and(
				DBFilter<BusinessPartnerDB>()
					.or(DBFilter<BusinessPartnerDB>().property('name').contains(query))
					.or(DBFilter<BusinessPartnerDB>().property('BH_PatientID').contains(query))
					.or(DBFilter<BusinessPartnerDB>().property('bh_phone').contains(query))
					.or(DBFilter<BusinessPartnerDB>().property('BH_Local_PatientID').contains(query))
					.or(DBFilter<BusinessPartnerDB>().property('nextofkin_contact').contains(query))
					.or(DBFilter<BusinessPartnerDB>().property('nationalid').contains(query)),
			)
			.and(DBFilter<BusinessPartnerDB>().property('isactive').equals(true));
	}
}
