import React from 'react';
import { useTranslation } from 'react-i18next';
import useBillingTotals from '../../hooks/useBillingTotals';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';

type ProductLineItemTableFooterProps = {
	shouldDisplayTheDeleteColumn: boolean;
};

const ProductLineItemTableFooter = ({ shouldDisplayTheDeleteColumn }: ProductLineItemTableFooterProps) => {
	const { t } = useTranslation();
	const { orderLineTotal } = useBillingTotals();

	return (
		<tr>
			<td colSpan={5} className="align-middle">
				<span className="float-end me-2">
					<strong>{t(uiText.visit.form.product.TOTAL)}</strong>
				</span>
			</td>
			<td>
				<FormatNumberInput
					className="fw-bold"
					readOnly={true}
					tabIndex={-1}
					value={orderLineTotal}
					aria-label={t(uiText.visit.form.product.table.VISIT_TOTAL)}
				/>
			</td>
			{shouldDisplayTheDeleteColumn ? <td className="d-none print__d-none"></td> : null}
		</tr>
	);
};

export default ProductLineItemTableFooter;
