import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, FieldValues, useFieldArray, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BusinessPartner, businessPartnerGroupSubType } from '../../models';
import BusinessPartnerPayerInformation from '../../models/BusinessPartnerPayerInformation';
import BusinessPartnerSpecificPayerInformation from '../../models/BusinessPartnerSpecificPayerInformation';
import Charge, { chargeSubType } from '../../models/Charge';
import PayerInformationField from '../../models/PayerInformationField';
import PaymentInformation from '../../models/PaymentInformation';
import ReferenceList from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import PatientPayerInformationInput from '../Patient/PatientPayerInformationInput';
import EditPatientInformation from './EditPatientInformation';

type PaymentInformationRowProps = {
	field?: PaymentInformation;
	index?: number;
	remove?: UseFieldArrayReturn['remove'];
	paymentTypes?: ReferenceList[];
	subTypes?: ReferenceList[];
	insuranceAndDonorList?: BusinessPartner[];
	waiver?: Charge;
	patientPayerInformationList?: BusinessPartnerPayerInformation[];
	onChargesUpdated?: () => void;
	shouldDisplayTheDeleteColumn: boolean;
	isVisitFinished?: boolean;
};

const PaymentInformationRow = ({
	field,
	index,
	remove,
	paymentTypes = [],
	subTypes = [],
	insuranceAndDonorList = [],
	waiver,
	patientPayerInformationList = [],
	onChargesUpdated,
	shouldDisplayTheDeleteColumn,
	isVisitFinished,
}: PaymentInformationRowProps) => {
	const { t } = useTranslation();
	const { register, setValue } = useFormContext();
	const [viewModal, setViewModal] = useState(false);
	const isFirstRender = useRef(true);
	const areRemovingChargeInformation = useRef(false);
	const areReadyToRespondToPaymentTypeUuidChanges = useRef(false);
	const initialBusinessPartnerSpecificPayerInformationList = useRef(
		field?.businessPartnerSpecificPayerInformationList || [],
	);

	const patient = useWatch({ name: 'patient' });

	const fieldPrefix = `paymentInformationList.${index}`;
	const {
		fields: businessPartnerSpecificPayerInformationList,
		remove: removeBusinessPartnerSpecificPayerInformation,
		append,
	} = useFieldArray<FieldValues, 'paymentInformationList.0.businessPartnerSpecificPayerInformationList'>({
		name: `${fieldPrefix}.businessPartnerSpecificPayerInformationList` as 'paymentInformationList.0.businessPartnerSpecificPayerInformationList',
	});
	const paymentTypeUuid: string = useWatch({
		name: `${fieldPrefix}.paymentType.uuid`,
		defaultValue: (field?.paymentType || {}).uuid,
	});
	const insurerOrDonorUuid: string = useWatch({
		name: `${fieldPrefix}.payer.uuid`,
		defaultValue: (field?.payer || {}).uuid || '',
	});
	const isPayment: boolean =
		useWatch({
			name: `${fieldPrefix}.isPayment`,
			defaultValue: field?.isPayment,
		}) === 'true';
	const isWaiver: boolean =
		useWatch({
			name: `${fieldPrefix}.isWaiver`,
			defaultValue: field?.isPayment,
		}) === 'true';

	// When the payment type uuid changes, handle it
	useEffect(() => {
		// Don't run this on first render
		if (isVisitFinished || !subTypes.length || !insuranceAndDonorList.length || !waiver) {
			return;
		}
		// If it's a sub type, we need to update the non-patient payment field
		let isPayment: boolean | undefined;
		let isWaiver: boolean | undefined;
		if (paymentTypeUuid) {
			isPayment = paymentTypes.some((paymentType) => paymentType.uuid === paymentTypeUuid);
			isWaiver = subTypes.find((subType) => subType.uuid === paymentTypeUuid)?.value === chargeSubType.Waiver;
			setValue(`${fieldPrefix}.isPayment`, isPayment.toString());
			setValue(`${fieldPrefix}.isWaiver`, isWaiver.toString());
		}
		// Only do this AFTER the first pass-through of this effect (because sub types, BPs, and the waiver are loading then
		// and we only want to respond to payment type changes in here)
		if (areReadyToRespondToPaymentTypeUuidChanges.current) {
			// See if the selected payment type matches a any insurance or donors
			const businessPartnersForThisPaymentType = insuranceAndDonorList.filter(
				(businessPartner) => businessPartner.businessPartnerGroup?.subType.uuid === paymentTypeUuid,
			);
			if (isWaiver === true) {
				// Clear the payer and set the charge
				setValue(`${fieldPrefix}.payer.uuid`, '');
				setValue(`${fieldPrefix}.charge.uuid`, waiver.uuid);
			} else if (isPayment === false && isWaiver === false && businessPartnersForThisPaymentType.length === 1) {
				// We only have a single insurer/donor, so select everything
				setValue(`${fieldPrefix}.payer.uuid`, businessPartnersForThisPaymentType[0].uuid);
				setValue(
					`${fieldPrefix}.charge.uuid`,
					businessPartnersForThisPaymentType[0].businessPartnerGroup?.associatedCustomerReceivablesCharge.uuid,
				);
			} else {
				// Just clear the payer & charge
				setValue(`${fieldPrefix}.payer.uuid`, '');
				setValue(`${fieldPrefix}.charge.uuid`, '');
			}
		}
		areReadyToRespondToPaymentTypeUuidChanges.current = true;
	}, [paymentTypes, subTypes, paymentTypeUuid, setValue, fieldPrefix, isVisitFinished, insuranceAndDonorList, waiver]);

	// As soon as the selected insurer/donor changes (and we can edit the visit), remove the entered payer information
	useEffect(() => {
		// Don't run this on first render (if there's no saved data)
		if (isFirstRender.current && !initialBusinessPartnerSpecificPayerInformationList.current.length) {
			return;
		}
		areRemovingChargeInformation.current = true;
		removeBusinessPartnerSpecificPayerInformation();
	}, [insurerOrDonorUuid, removeBusinessPartnerSpecificPayerInformation]);

	// Define some helper functions that fetch data
	const getSelectedInsurerOrDonor = useCallback(
		() => insuranceAndDonorList.find((businessPartner) => businessPartner.uuid === insurerOrDonorUuid),
		[insuranceAndDonorList, insurerOrDonorUuid],
	);
	const getPatientPayerInformation = useCallback(() => {
		const selectedInsurerOrDonor = getSelectedInsurerOrDonor();
		return patientPayerInformationList.find(
			(getPatientPayerInformation) => getPatientPayerInformation.payerUuid === selectedInsurerOrDonor?.uuid,
		);
	}, [getSelectedInsurerOrDonor, patientPayerInformationList]);
	const getSelectedPayerInformationFieldList = useCallback(() => {
		const selectedInsurerOrDonor = getSelectedInsurerOrDonor();
		return sortBy((selectedInsurerOrDonor || {}).payerInformationFieldList || [], 'lineNumber').filter(
			(payerInformationField) => payerInformationField.isActive,
		);
	}, [getSelectedInsurerOrDonor]);

	// If the selected insurer/donor changes, we need to add data (an effect above handles clearing the data)
	useEffect(() => {
		// If there's no insurer/donor selected (or none to select), there's nothing else to do
		if (!insurerOrDonorUuid || !insuranceAndDonorList.length) {
			return;
		}
		// Update the correct insurer/donor
		setValue(
			`${fieldPrefix}.charge.uuid`,
			insuranceAndDonorList.find((businessPartner) => businessPartner.uuid === insurerOrDonorUuid)?.businessPartnerGroup
				?.associatedCustomerReceivablesCharge.uuid,
		);
		// If the selected insurer/donor has no information, there's nothing else to do
		if (!getSelectedPayerInformationFieldList().length) {
			return;
		}
		// If there aren't already information fields added, do it
		// Else, update values to ensure the most recent information is displayed
		if (!(businessPartnerSpecificPayerInformationList || []).length) {
			// If there is initial data, use that
			const dataToUse = initialBusinessPartnerSpecificPayerInformationList.current.length
				? initialBusinessPartnerSpecificPayerInformationList.current
				: (businessPartnerSpecificPayerInformationList as unknown as BusinessPartnerSpecificPayerInformation[]);
			append(
				getSelectedPayerInformationFieldList().map((chargeInformation) => {
					let businessPartnerSpecificPayerInformation: Partial<BusinessPartnerSpecificPayerInformation> | undefined =
						{};
					if (isVisitFinished || !chargeInformation.shouldFillFromPatient) {
						businessPartnerSpecificPayerInformation = (dataToUse || []).find(
							(orderLineChargeInformation) =>
								orderLineChargeInformation.payerInformationFieldUuid === chargeInformation.uuid,
						);
					} else {
						// Otherwise, get what's on the patient
						businessPartnerSpecificPayerInformation = (
							(getPatientPayerInformation() || {}).businessPartnerGeneralPayerInformationList || []
						).find(
							(businessPartnerChargeInformation) =>
								businessPartnerChargeInformation.payerInformationFieldUuid === chargeInformation.uuid,
						);
					}
					return new BusinessPartnerSpecificPayerInformation({
						...(businessPartnerSpecificPayerInformation || {}),
						payerInformationFieldUuid: chargeInformation.uuid,
					});
				}),
			);
			// The chance to use initial data is over, so clear it
			initialBusinessPartnerSpecificPayerInformationList.current = [];
		} else if (!areRemovingChargeInformation.current) {
			// The above is necessary because, if a charge already exists and a user is changing it, this will run
			// at the same time as the remove, but orderLineChargeInformationList will not have been updated yet
			(businessPartnerSpecificPayerInformationList as unknown as BusinessPartnerSpecificPayerInformation[]).forEach(
				(businessPartnerSpecificPayerInformation, businessPartnerSpecificPayerInformationIndex) => {
					// The associated charge information might come back null if the user is changing from one charge to another
					let associatedPayerInformationField: Partial<PayerInformationField> =
						getSelectedPayerInformationFieldList().find(
							(payerInformationField) =>
								payerInformationField.uuid === businessPartnerSpecificPayerInformation.payerInformationFieldUuid,
						) || {};
					let nameToUse: string | undefined = businessPartnerSpecificPayerInformation.name;
					// If the visit isn't finished, pull from the patient
					if (!isVisitFinished && associatedPayerInformationField.shouldFillFromPatient) {
						nameToUse = (
							((getPatientPayerInformation() || {}).businessPartnerGeneralPayerInformationList || []).find(
								(businessPartnerGeneralPayerInformation) =>
									businessPartnerGeneralPayerInformation.payerInformationFieldUuid ===
									associatedPayerInformationField.uuid,
							) || {}
						).name;
					}
					setValue(
						`${fieldPrefix}.businessPartnerSpecificPayerInformationList.${businessPartnerSpecificPayerInformationIndex}.name`,
						nameToUse,
					);
				},
			);
		}
	}, [
		insurerOrDonorUuid,
		isVisitFinished,
		businessPartnerSpecificPayerInformationList,
		getSelectedPayerInformationFieldList,
		append,
		getPatientPayerInformation,
		fieldPrefix,
		setValue,
		insuranceAndDonorList,
	]);

	// Sometimes the dropdown display isn't updated, so make sure it gets updated when the values come through
	const fieldPaymentTypeUuid = field?.paymentType?.uuid;
	useEffect(() => {
		if (subTypes.length && paymentTypes.length) {
			setValue(`${fieldPrefix}.paymentType.uuid`, fieldPaymentTypeUuid);
		}
	}, [subTypes, paymentTypes, fieldPaymentTypeUuid, fieldPrefix, setValue]);
	const fieldPayerUuid = field?.payer?.uuid;
	useEffect(() => {
		if (insuranceAndDonorList.length) {
			setValue(`${fieldPrefix}.payer.uuid`, fieldPayerUuid);
		}
	}, [insuranceAndDonorList, fieldPayerUuid, fieldPrefix, setValue]);

	// Now that we're returning data, say the first render is over
	isFirstRender.current = false;
	areRemovingChargeInformation.current = false;
	// Hide the row if we have one charge (it will be auto-selected) and there is no data to collect for the one charge selected
	const shouldHideAdditionalRow =
		insuranceAndDonorList.filter(
			(businessPartner) => businessPartner.businessPartnerGroup?.subType.uuid === paymentTypeUuid,
		).length === 1 && !businessPartnerSpecificPayerInformationList.length;

	return (
		<>
			<tr>
				<td>
					<input type="hidden" {...register(`${fieldPrefix}.uuid`)} defaultValue={field?.uuid} />
					<input type="hidden" {...register(`${fieldPrefix}.isPayment`)} defaultValue={field?.isPayment || 'false'} />
					<input type="hidden" {...register(`${fieldPrefix}.isWaiver`)} defaultValue={field?.isWaiver || 'false'} />
					<DynamicSelect
						aria-label={t(uiText.visit.form.payment.table.TYPE)}
						isLoading={!paymentTypes.length && !subTypes.length}
						className="form-control"
						{...register(`${fieldPrefix}.paymentType.uuid`)}
						defaultValue={paymentTypeUuid || ''}
					>
						{[...paymentTypes, ...subTypes].map((type) => (
							<option key={type.uuid} value={type.uuid}>
								{type.name}
							</option>
						))}
					</DynamicSelect>
				</td>
				<td>
					<Controller
						name={`${fieldPrefix}.amount`}
						defaultValue={field?.amount}
						rules={{ validate: (value) => value > 0 }}
						render={({ field }) => (
							<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
						)}
					/>
				</td>

				<td>
					<Form.Control {...register(`${fieldPrefix}.description`)} defaultValue={field?.description} />
				</td>
				{shouldDisplayTheDeleteColumn ? (
					<td className="align-middle text-center print__d-none" onClick={() => remove && remove(index)}>
						<button
							type="button"
							className="btn p-0 w-100 flex-grow-1"
							tabIndex={-1}
							aria-label={t(uiText.visit.button.DELETE)}
						>
							<FontAwesomeIcon icon={faTrash} />
						</button>
					</td>
				) : null}
			</tr>
			{!isPayment && !isWaiver && (
				<tr className={shouldHideAdditionalRow ? 'd-none' : ''}>
					<td colSpan={4}>
						<Row className="align-items-center">
							<Col xs={2}>
								<DynamicSelect
									aria-label={t(
										getSelectedInsurerOrDonor()?.businessPartnerGroup?.subType.value ===
											businessPartnerGroupSubType.Donation
											? uiText.visit.form.additionalInformation.DONOR
											: uiText.visit.form.additionalInformation.INSURER,
									)}
									{...register(`${fieldPrefix}.payer.uuid`, { required: true })}
									className="keep-border"
									defaultValue={fieldPayerUuid || ''}
									isLoading={!insuranceAndDonorList.length}
								>
									<option value="">{t(uiText.visit.form.additionalInformation.SELECT_TYPE)}</option>
									{insuranceAndDonorList
										.filter((businessPartner) => businessPartner.businessPartnerGroup?.subType.uuid === paymentTypeUuid)
										.map((businessPartner) => (
											<option key={businessPartner.uuid} value={businessPartner.uuid}>
												{businessPartner.name}
											</option>
										))}
								</DynamicSelect>
							</Col>
							<Col xs={10}>
								<Row className="gy-3">
									{(
										businessPartnerSpecificPayerInformationList as unknown as BusinessPartnerSpecificPayerInformation[]
									).map((businessPartnerSpecificPayerInformation, businessPartnerSpecificPayerInformationIndex) => {
										const payerInformationField: Partial<PayerInformationField> =
											getSelectedPayerInformationFieldList().find(
												(payerInformationField) =>
													payerInformationField.uuid ===
													businessPartnerSpecificPayerInformation.payerInformationFieldUuid,
											) || {};
										return (
											<Col xs={3} key={businessPartnerSpecificPayerInformation.uuid}>
												<input
													type="hidden"
													{...register(
														`${fieldPrefix}.businessPartnerSpecificPayerInformationList.${businessPartnerSpecificPayerInformationIndex}.chargeInformationUuid`,
													)}
													defaultValue={businessPartnerSpecificPayerInformation.payerInformationFieldUuid}
												/>
												<input
													type="hidden"
													{...register(
														`${fieldPrefix}.businessPartnerSpecificPayerInformationList.${businessPartnerSpecificPayerInformationIndex}.uuid`,
													)}
													defaultValue={businessPartnerSpecificPayerInformation.uuid}
												/>
												{isVisitFinished ? (
													<Form.Control
														className="keep-border"
														defaultValue={businessPartnerSpecificPayerInformation.name || ''}
													/>
												) : !!payerInformationField.shouldFillFromPatient ? (
													<fieldset disabled={true}>
														<input
															type="hidden"
															{...register(
																`${fieldPrefix}.businessPartnerSpecificPayerInformationList.${businessPartnerSpecificPayerInformationIndex}.isFilledFromPatient`,
															)}
															defaultValue={'true'}
														/>
														<InputGroup className="input-group" onClick={() => setViewModal(true)}>
															<Form.Control
																type="text"
																className="keep-border cursor-pointer"
																placeholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
																	payerInformationField.name
																}`}
																{...register(
																	`${fieldPrefix}.businessPartnerSpecificPayerInformationList.${businessPartnerSpecificPayerInformationIndex}.name`,
																)}
																defaultValue={businessPartnerSpecificPayerInformation.name || ''}
															/>
															<InputGroup.Text className="keep-border cursor-pointer">
																<FontAwesomeIcon icon={['fas', 'edit']} />
															</InputGroup.Text>
														</InputGroup>
													</fieldset>
												) : (
													<PatientPayerInformationInput
														payerInformationField={payerInformationField}
														inputPlaceholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
															payerInformationField.name
														}`}
														name={`${fieldPrefix}.businessPartnerSpecificPayerInformationList.${businessPartnerSpecificPayerInformationIndex}.name`}
														register={register}
														defaultValue={businessPartnerSpecificPayerInformation.name || ''}
														selectPlaceholder={`${t(uiText.patient.additionalInformation.SELECT_PREFIX)} ${
															payerInformationField.name
														}`}
														required={false}
													/>
												)}
											</Col>
										);
									})}
								</Row>
							</Col>
						</Row>
						{viewModal && (
							<EditPatientInformation
								patient={patient}
								onCancel={() => setViewModal(false)}
								onSave={() => {
									setViewModal(false);
									if (onChargesUpdated) {
										onChargesUpdated();
									}
								}}
								businessPartnerPayerInformation={new BusinessPartnerPayerInformation(getPatientPayerInformation())}
								selectedPayer={getSelectedInsurerOrDonor() || {}}
							/>
						)}
					</td>
				</tr>
			)}
		</>
	);
};

export default PaymentInformationRow;
