import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';

export interface PayerInformationFieldValueDto extends BaseEntityDto {
	lineNumber: number;
}

export default class PayerInformationFieldValue extends BaseEntity {
	lineNumber: number;

	/**
	 * Create a new instance of this class
	 * @param {PayerInformationFieldValue} entity The entity to map to this class
	 */
	constructor(entity: Partial<PayerInformationFieldValue | PayerInformationFieldValueDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.lineNumber = entity.lineNumber || 0;
	}
}
