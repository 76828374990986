import {
	BusinessPartnerDto,
	BusinessPartnerGroup,
	BusinessPartnerGroupDB,
	BusinessPartnerGroupDto,
	Filter,
	Response,
	Sort,
} from '../models';
import BaseEntityService from './BaseEntityService';

export default class BusinessPartnerGroupService extends BaseEntityService<
	BusinessPartnerGroup,
	BusinessPartnerGroupDto,
	BusinessPartnerGroupDB
> {
	entityTableName: string = 'c_bp_group';
	entityName = 'business-partner-groups';

	async get(
		page?: number | undefined,
		size?: number | undefined,
		sorted?: Sort<BusinessPartnerGroupDB>[] | undefined,
		filter?: Filter<BusinessPartnerGroupDB> | undefined,
	): Promise<Response<BusinessPartnerGroup>> {
		let filterString;
		// If there was a filter, we can cache this
		if (filter) {
			// Based on this filter, see if we've fetched this value before; if so, return it
			filterString = 'businessPartnerGroup_' + JSON.stringify(filter);
			if (localStorage.getItem(filterString)) {
				return new Response<BusinessPartnerGroup>({
					results: [new BusinessPartnerGroup(JSON.parse(localStorage.getItem(filterString)!))],
				});
			}
		}
		// We haven't gotten it before, so fetch it
		const response = await super.get(page, size, sorted, filter);
		// If something was returned, we'll cache it
		if (response.results.length === 1 && filterString) {
			localStorage.setItem(filterString, JSON.stringify(response.results[0]));
		}
		return response;
	}

	map(data: BusinessPartnerDto) {
		return new BusinessPartnerGroup(data);
	}

	reverseMap(data: BusinessPartnerGroup): BusinessPartnerDto {
		throw new Error('Method not implemented.');
	}
}
