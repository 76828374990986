import DocumentStatus, { DocumentStatusValue } from '../models/DocumentStatus';

// Note that IN_PROGRESS is counted as both a drafted AND reactivated status. This is because,
// in most cases, the user just needs to know whether they can work with the document (i.e. it's in
// a drafted state). However, there are a few cases where we need more granularity to know whether the
// document has actually been reversed or not, or a user can work with it.
const draftedStatuses: DocumentStatusValue[] = [
	DocumentStatus.DRAFTED,
	DocumentStatus.IN_PROGRESS,
	DocumentStatus.INVALID,
];
const completedStatuses: DocumentStatusValue[] = [DocumentStatus.COMPLETED];
const voidedStatuses: DocumentStatusValue[] = [DocumentStatus.VOIDED, DocumentStatus.REVERSED];
const reactivatedStatuses: DocumentStatusValue[] = [DocumentStatus.IN_PROGRESS, DocumentStatus.REVERSED];

/**
 * Determine if the status is a drafted status
 * @param documentStatusValue An entity with a docStatus property
 */
export const isStatusADraftedStatus = (documentStatusValue: DocumentStatusValue) => {
	return draftedStatuses.includes(documentStatusValue);
};

/**
 * Determine if the status is a completed status
 * @param documentStatusValue An entity with a docStatus property
 */
export const isStatusACompletedStatus = (documentStatusValue: DocumentStatusValue) => {
	return completedStatuses.includes(documentStatusValue);
};

/**
 * Determine if the status is a voided status
 * @param documentStatusValue An entity with a docStatus property
 */
export const isStatusAVoidedStatus = (documentStatusValue: DocumentStatusValue) => {
	return voidedStatuses.includes(documentStatusValue);
};

/**
 * Determine if the status is a voided status
 * @param documentStatusValue An entity with a docStatus property
 */
export const isStatusAReactivatedStatus = (documentStatusValue: DocumentStatusValue) => {
	return reactivatedStatuses.includes(documentStatusValue);
};

/**
 * Determine if the entity is in a new entity
 * @param entity An entity with a uuid property
 */
export const isEntityNew = (entity: { docStatus?: string; documentStatus?: string }) => {
	return !('docStatus' in entity && entity.docStatus) && !('documentStatus' in entity && entity.documentStatus);
};

/**
 * Determine if the entity is in the drafted state
 * @param entity An entity with a docStatus property
 */
export const isEntityDrafted = (
	entity?:
		| { docStatus: DocumentStatusValue }
		| { documentStatus: DocumentStatusValue }
		| { DocStatus: { Value: string } }
		| null,
) => {
	if (!entity || (!('docStatus' in entity) && !('documentStatus' in entity) && !('DocStatus' in entity))) {
		return false;
	}
	if ('docStatus' in entity) {
		return isStatusADraftedStatus(entity.docStatus);
	} else if ('DocStatus' in entity) {
		return isStatusADraftedStatus(entity.DocStatus.Value as DocumentStatusValue);
	}
	return isStatusADraftedStatus(entity.documentStatus);
};

/**
 * Determine if the entity is in the completed state
 * @param entity An entity with a docStatus property
 */
export const isEntityCompleted = (
	entity?:
		| { docStatus: DocumentStatusValue }
		| { documentStatus: DocumentStatusValue }
		| { DocStatus: { Value: string } }
		| null,
) => {
	if (!entity || (!('docStatus' in entity) && !('documentStatus' in entity) && !('DocStatus' in entity))) {
		return false;
	}
	if ('docStatus' in entity) {
		return isStatusACompletedStatus(entity.docStatus);
	} else if ('documentStatus' in entity) {
		return isStatusACompletedStatus(entity.documentStatus);
	}
	return isStatusACompletedStatus(entity.DocStatus.Value as DocumentStatusValue);
};

/**
 * Determine if the entity is in the voided state
 * @param entity An entity with a docStatus property
 */
export const isEntityVoided = (
	entity?:
		| { docStatus: DocumentStatusValue }
		| { documentStatus: DocumentStatusValue }
		| { DocStatus: { Value: string } }
		| null,
) => {
	if (!entity || (!('docStatus' in entity) && !('documentStatus' in entity) && !('DocStatus' in entity))) {
		return false;
	}
	if ('docStatus' in entity) {
		return isStatusAVoidedStatus(entity.docStatus);
	} else if ('DocStatus' in entity) {
		return isStatusAVoidedStatus(entity.DocStatus.Value as DocumentStatusValue);
	}
	return isStatusAVoidedStatus(entity.documentStatus);
};

/**
 * Determine if the document has been reactivated or not
 * @param document An entity with an appropriate document status property
 * @returns Whether the entity has been reactivated or not
 */
export const isEntityReactivated = (
	document?:
		| { docStatus: DocumentStatusValue }
		| { documentStatus: DocumentStatusValue }
		| { DocStatus: { Value: string } }
		| null,
) => {
	if (!document || (!('docStatus' in document) && !('documentStatus' in document) && !('DocStatus' in document))) {
		return false;
	}
	if ('docStatus' in document) {
		return isStatusAReactivatedStatus(document.docStatus);
	} else if ('DocStatus' in document) {
		return isStatusAReactivatedStatus(document.DocStatus.Value as DocumentStatusValue);
	}
	return isStatusAReactivatedStatus(document.documentStatus);
};
