import React, { useState } from 'react';
import { Button, Card, Row, Spinner } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BusinessPartner } from '../../models';
import { uiText } from '../../utils/Language';
import ObservationFormGroup from './ObservationFormGroup';
import VisitHistory from './VisitHistory';
import DiagnosisTable from './DiagnosisTable';

type ClinicalDetailsProps = {
	isDataReadOnly?: boolean;
	reviewHistory?: boolean;
	loading?: boolean;
};

const ClinicalDetails = ({ isDataReadOnly, reviewHistory = false, loading = false }: ClinicalDetailsProps) => {
	const { t } = useTranslation();
	const { getValues } = useFormContext();
	const [showHistory, setShowHistory] = useState(false);

	const patient: BusinessPartner = useWatch({ name: 'patient' });
	const visitUuid: string = getValues('uuid');

	const fieldPrefix = 'clinicalDetailsEncounters.0';

	return (
		<>
			<Card className="bh-card">
				<Card.Header className="d-flex">
					<div className="fw-bold h5 mb-0">{t(uiText.visit.form.CLINICAL_DETAILS)}</div>
					{!reviewHistory && !isDataReadOnly && patient.uuid && !patient.isNew && patient?.totalVisits > 0 && (
						<Button variant="info" className="ms-auto py-0" onClick={() => setShowHistory(true)}>
							{t(uiText.visit.HISTORY)}
						</Button>
					)}
					{loading && (
						<div className="ms-auto fw-light">
							<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
							<Spinner animation="border" size="sm" className="align-middle" role="status" />
						</div>
					)}
				</Card.Header>
				<Card.Body className="p-3">
					<Row className="gy-3">
						<ObservationFormGroup visitUuid={visitUuid} fieldPrefix={fieldPrefix} />

						<DiagnosisTable visitUuid={visitUuid} readOnly={isDataReadOnly || reviewHistory} />
					</Row>
				</Card.Body>
			</Card>
			{showHistory && <VisitHistory onClose={() => setShowHistory(false)} />}
		</>
	);
};

export default ClinicalDetails;
