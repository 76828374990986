import { ApolloClient } from '@apollo/client';
import { sortBy } from 'lodash';
import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	C_OrderForReceiveProductEditingQuery,
	C_OrderLineInput,
	M_AttributeSetInstanceInput,
	M_ProductDataForReceiveProductsFragmentDoc,
} from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import './ProductLineItemTable.scss';
import { ReceiveProductFormValues } from './ReceiveProductForm';
import ReceiveProductLineItemTableFooter from './ReceiveProductLineItemTableFooter';
import ReceiveProductLineItemTableRow from './ReceiveProductLineItemTableRow';

export type ReceiveProductLinesFormValues = {
	C_OrderLines: Array<{
		UU: string;
		GuaranteeDate: Date | null;
		LineNetAmt: number | null;
		M_Product: { UU: string };
		PriceEntered: number | null;
		QtyEntered: number | null;
		isOrderLineNew: boolean;
	}>;
	addNewOrderLine: {
		LineNetAmt: number | null;
		M_Product: { UU: string };
		PriceEntered: number | null;
		QtyEntered: number | null;
	};
	orderLineCount: number;
};

type ProductLineItemTableProps = {
	readOnly: boolean;
};

export const constructReceiveProductLinesFormData = (
	initialData: C_OrderForReceiveProductEditingQuery['C_Order'],
	data: ReceiveProductFormValues,
	graphqlClient: ApolloClient<object>,
): [M_AttributeSetInstanceInput[], C_OrderLineInput[]] => {
	let attributeSetInstances: M_AttributeSetInstanceInput[] = [];
	const orderLines = data.C_OrderLines.map((orderLine) => {
		// If we have an existing order line, we'll use the existin ASI stuff
		const existingOrderLine = initialData?.C_OrderLines?.find((savedOrderLine) => savedOrderLine.UU === orderLine.UU);
		const attributeSetInstanceToSave: M_AttributeSetInstanceInput = {
			UU: existingOrderLine?.M_AttributeSetInstance?.UU || v4(),
			GuaranteeDate: orderLine.GuaranteeDate?.getTime() || null,
			M_AttributeSet: graphqlClient.readFragment({
				id: orderLine.M_Product.UU,
				fragment: M_ProductDataForReceiveProductsFragmentDoc,
			})?.M_AttributeSet?.UU
				? {
						UU: graphqlClient.readFragment({
							id: orderLine.M_Product.UU,
							fragment: M_ProductDataForReceiveProductsFragmentDoc,
						})!.M_AttributeSet!.UU,
					}
				: existingOrderLine?.M_Product?.M_AttributeSet?.UU
					? { UU: existingOrderLine.M_Product.M_AttributeSet.UU }
					: undefined,
			// If the current product's serial number control on the product's attribute set matches the existing
			// order lines ASI's attribute set's serial number control, we can use any pre-existing serial number!
			SerNo:
				existingOrderLine?.M_AttributeSetInstance?.M_AttributeSet?.M_SerNoCtl?.UU &&
				existingOrderLine.M_AttributeSetInstance.M_AttributeSet.M_SerNoCtl.UU ===
					graphqlClient.readFragment({
						id: orderLine.M_Product.UU,
						fragment: M_ProductDataForReceiveProductsFragmentDoc,
					})?.M_AttributeSet?.M_SerNoCtl?.UU
					? existingOrderLine?.M_AttributeSetInstance?.SerNo
					: null,
		};
		attributeSetInstances.push(attributeSetInstanceToSave);
		return {
			UU: orderLine.UU,
			C_Order: { UU: data.UU },
			M_AttributeSetInstance: { UU: attributeSetInstanceToSave.UU! },
			M_Product: orderLine.M_Product.UU ? { UU: orderLine.M_Product.UU } : undefined,
			Qty: orderLine.QtyEntered,
			Price: orderLine.PriceEntered,
		};
	});
	return [attributeSetInstances, orderLines];
};

export const convertToReceiveProductLinesFormFields: (
	initialData: C_OrderForReceiveProductEditingQuery['C_Order'],
) => ReceiveProductLinesFormValues = (initialData) => {
	const additionalFormFields = {
		addNewOrderLine: { LineNetAmt: null, M_Product: { UU: '' }, PriceEntered: null, QtyEntered: null },
	};
	if (!initialData) {
		return { C_OrderLines: [], orderLineCount: 0, ...additionalFormFields };
	}
	return {
		C_OrderLines: sortBy(initialData.C_OrderLines || [], 'Line').map((orderLine) => ({
			UU: orderLine.UU,
			GuaranteeDate:
				(orderLine.M_AttributeSetInstance.GuaranteeDate && new Date(orderLine.M_AttributeSetInstance.GuaranteeDate)) ||
				null,
			LineNetAmt: orderLine.LineNetAmt,
			M_Product: { UU: orderLine.M_Product?.UU || '' },
			PriceEntered: orderLine.PriceEntered,
			QtyEntered: orderLine.QtyEntered,
			isOrderLineNew: false,
		})),
		orderLineCount: initialData.C_OrderLines?.length || 0,
		...additionalFormFields,
	};
};

const ProductLineItemTable = ({ readOnly }: ProductLineItemTableProps) => {
	const { t } = useTranslation();
	const {
		register,
		setFocus,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<ReceiveProductFormValues>();
	const { fields, append, remove } = useFieldArray<ReceiveProductLinesFormValues, 'C_OrderLines', 'UU'>({
		name: 'C_OrderLines',
		keyName: 'UU',
	});
	const addNewOrderLine = useWatch<ReceiveProductLinesFormValues, 'addNewOrderLine'>({ name: 'addNewOrderLine' });
	const shouldDisplayTheDeleteColumn = !readOnly;
	const shouldFocusOnLastField = useRef(false);

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewOrderLine.M_Product.UU) {
			append({
				UU: v4(),
				GuaranteeDate: null,
				LineNetAmt: addNewOrderLine.LineNetAmt,
				M_Product: { UU: addNewOrderLine.M_Product.UU },
				PriceEntered: addNewOrderLine.PriceEntered,
				QtyEntered: addNewOrderLine.QtyEntered,
				isOrderLineNew: true,
			});
			setValue('addNewOrderLine', {
				LineNetAmt: null,
				M_Product: { UU: '' },
				PriceEntered: null,
				QtyEntered: null,
			});
			shouldFocusOnLastField.current = true;
		}
		// We want this to re-run every time the product updates and we have a check in the hook; this will be refactor with GraphQL
		// eslint-disable-next-line
	}, [addNewOrderLine, fields.length, append, setValue]);
	// If there are fields and we should focus on the last one, do so
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField.current) {
			setFocus(`C_OrderLines.${fields.length - 1}.QtyEntered` as 'C_OrderLines.0.QtyEntered');
			shouldFocusOnLastField.current = false;
		}
	}, [fields.length, setFocus, shouldFocusOnLastField]);
	useEffect(() => {
		setValue('orderLineCount', fields.length, { shouldValidate: true });
	}, [fields.length, setValue]);

	return (
		<>
			<input
				type="hidden"
				{...register('orderLineCount', {
					valueAsNumber: true,
					validate: (value) => (getValues('submitEvent') === 'complete' ? value > 0 : true),
				})}
				defaultValue={fields.length}
			/>
			<table className="bh-table--form">
				<thead>
					<tr>
						<th className="data-type-text">{t(uiText.receiveProduct.name.LABEL)}</th>
						<th className="data-type-text">{t(uiText.receiveProduct.QUANTITY_RECEIVED)}</th>
						<th className="data-type-numeric">{t(uiText.receiveProduct.UNIT_BUY_PRICE)}</th>
						<th className="data-type-numeric">{t(uiText.receiveProduct.TOTAL_PAID)}</th>
						<th className="data-type-text" id="expirationEntry">
							{t(uiText.receiveProduct.EXPIRATION_DATE)}
						</th>
						{shouldDisplayTheDeleteColumn && <th className="w-6">{t(uiText.receiveProduct.button.DELETE)}</th>}
					</tr>
				</thead>
				<tbody>
					{fields.map((orderLine, index) => (
						<ReceiveProductLineItemTableRow
							key={orderLine.UU}
							field={orderLine}
							index={index}
							remove={remove}
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
						/>
					))}
				</tbody>
				<tbody>
					{!readOnly && (
						<ReceiveProductLineItemTableRow
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isAddRow={true}
						/>
					)}
				</tbody>
				<tbody>
					<ReceiveProductLineItemTableFooter shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn} />
				</tbody>
			</table>
			{errors.C_OrderLines?.some((orderLineError) => !!orderLineError?.QtyEntered) && (
				<div className="text-danger">{t(uiText.order.error.PRODUCT_MISSING_QUANTITY_GENERIC)}</div>
			)}
			{errors.C_OrderLines?.some((orderLineError) => !!orderLineError?.GuaranteeDate) && (
				<div className="text-danger">{t(uiText.order.error.PRODUCT_MISSING_EXPIRATION_GENERIC)}</div>
			)}
			{errors.orderLineCount && <div className="text-danger">{t(uiText.order.error.MISSING_PRODUCT)}</div>}
		</>
	);
};

export default ProductLineItemTable;
