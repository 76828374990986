import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import { formatDate } from '../utils/DateUtil';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import BusinessPartner, { BusinessPartnerDB, BusinessPartnerDto } from './BusinessPartner';
import DocumentStatus, { DocumentStatusValue } from './DocumentStatus';
import InvoiceLine, { InvoiceLineDto } from './InvoiceLine';
import VoidedReason, { VoidedReasonDto } from './VoidedReason';
import { UserDB } from './User';
import DocumentType, { DocumentTypeDto } from './DocumentType';
import Order, { OrderDto } from './Order';

export interface InvoiceDB extends BaseMetadataDB {
	issotrx: boolean;
	dateInvoiced: Date;
	'C_BPartner.name': string;
	grandTotal: number;
	docStatus: string;
	c_bpartner: BusinessPartnerDB;
	'ad_user::createdby->ad_user_id': UserDB;
	'c_invoice.ad_user.ad_user_uu': string;
	'ad_user.name': string;
	bh_visit_id: number;
}

export interface InvoiceDto extends BaseMetadataDto {
	invoiceLines?: InvoiceLineDto[];
	docStatus?: string;
	dateInvoiced?: string;
	grandTotal?: number;
	paymentRule?: string;
	voidedReason?: VoidedReasonDto;
	businessPartner?: BusinessPartnerDto | null;
	documentTypeTarget?: DocumentTypeDto | null;
	order?: OrderDto | null;
	isSalesOrderTransaction?: boolean | null;
}

export default class Invoice extends BaseMetadata {
	businessPartner: BusinessPartner;
	dateInvoiced?: Date;
	isSalesOrderTransaction: boolean;
	description: string;
	invoiceLines: InvoiceLine[];
	documentStatus: DocumentStatusValue;
	grandTotal: number;
	paymentRule: string;
	voidedReason: VoidedReason;
	documentTypeTarget?: DocumentType;
	order?: Order;

	/**
	 * The invoice date, but formatted
	 */
	get dateInvoicedFormatted() {
		return formatDate(this.dateInvoiced);
	}

	constructor(props?: DeepPartial<Invoice | InvoiceDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.businessPartner = new BusinessPartner(('businessPartner' in props && props.businessPartner) || {});
		if (typeof props.dateInvoiced === 'string') {
			this.dateInvoiced = new Date(props.dateInvoiced);
		} else if (props.dateInvoiced instanceof Date) {
			this.dateInvoiced = props.dateInvoiced;
		}
		this.isSalesOrderTransaction = false;
		if ('isSalesOrderTransaction' in props) {
			this.isSalesOrderTransaction = props.isSalesOrderTransaction || false;
		}
		this.description = '';
		if ('description' in props) {
			this.description = props.description || '';
		}
		this.invoiceLines = (props.invoiceLines || []).map((invoiceLine) => new InvoiceLine(invoiceLine));
		if ('documentStatus' in props) {
			this.documentStatus = props.documentStatus || DocumentStatus.DRAFTED;
		} else if ('docStatus' in props) {
			this.documentStatus = (props.docStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
		} else {
			this.documentStatus = DocumentStatus.DRAFTED;
		}
		this.grandTotal = props.grandTotal || 0;
		this.paymentRule = props.paymentRule || '';
		this.voidedReason = !props.voidedReason ? new VoidedReason({ uuid: '' }) : new VoidedReason(props.voidedReason);
		this.documentTypeTarget = props.documentTypeTarget ? new DocumentType(props.documentTypeTarget) : undefined;
		this.order = props.order ? new Order(props.order) : undefined;
	}
}
