import axios from 'axios';
import { isArray } from 'lodash';
import { DBFilter, Filter, Paging, referenceUuids } from '../models';
import ReferenceList, { ReferenceListDB, ReferenceListDto } from '../models/ReferenceList';
import { configs } from '../utils/Configs';
import BaseEntityService from './BaseEntityService';

export default class ReferenceListService extends BaseEntityService<ReferenceList, ReferenceListDto, ReferenceListDB> {
	protected entityName: string = 'reference-lists';
	public entityTableName: string = 'ad_ref_list';

	/**
	 * Called to get a specific entity set and store it in the cache
	 * @param entityUrlTail The tail of the URL to call
	 * @param cacheKey The cache key for this entity
	 * @param useCache Whether to use the cache or not
	 */
	private async getSpecific(entityUrlTail: string, cacheKey: string, useCache = true): Promise<ReferenceList[]> {
		let referenceLists = JSON.parse(localStorage.getItem(cacheKey) || '[]');
		if (isArray(referenceLists) && referenceLists.length && useCache) {
			return referenceLists.map(this.map);
		}
		referenceLists = await axios
			.get<ReferenceListDto[]>(`${configs.apiUrl}/${this.entityName}${entityUrlTail}`)
			.then(({ data }) => {
				if (data && isArray(data)) {
					return data.map(this.map);
				}
				return [];
			});
		localStorage.setItem(cacheKey, JSON.stringify(referenceLists));
		return referenceLists;
	}

	/**
	 * Called to get a specific entity set and store it in the cache
	 * @param entityUrlTail The tail of the URL to call
	 * @param cacheKey The cache key for this entity
	 * @param useCache Whether to use the cache or not
	 */
	private async getByReference(
		referenceUuid: string,
		filter?: Filter<ReferenceListDB>,
		useCache = true,
	): Promise<ReferenceList[]> {
		let referenceLists = JSON.parse(localStorage.getItem(referenceUuid) || '[]');
		if (isArray(referenceLists) && referenceLists.length && useCache) {
			return referenceLists.map(this.map);
		}
		if (!filter) {
			filter = DBFilter<ReferenceListDB>().property('isactive').equals(true);
		}
		referenceLists = (
			await this.get(
				Paging.ALL.page,
				Paging.ALL.size,
				undefined,
				filter.nested('ad_reference').property('ad_reference_uu').equals(referenceUuid).up(),
			)
		).results;
		localStorage.setItem(referenceUuid, JSON.stringify(referenceLists));
		return referenceLists;
	}

	/**
	 * Get the non patient payment reference list items
	 * @param useCache Whether to use the cache or not
	 */
	getNonPatientPaymentSubTypes(useCache = true): Promise<ReferenceList[]> {
		return this.getSpecific('/non-patient-payment-sub-types', 'nonPatientPaymentSubTypes', useCache);
	}

	/**
	 * Get the non patient payment reference list items
	 * @param useCache Whether to use the cache or not
	 */
	getChargeInformationDataTypes(useCache = true): Promise<ReferenceList[]> {
		return this.getSpecific('/charge-information-data-types', 'chargeInformationDataTypes', useCache);
	}

	getStockUpdateReasons(useCache = true): Promise<ReferenceList[]> {
		return this.getSpecific('/stock-update-reasons', 'stockUpdateReasons', useCache);
	}

	getDiagnosticStatuses(filter?: Filter<ReferenceListDB>, useCache = true) {
		return this.getByReference(referenceUuids.DIAGNOSTIC_STATUSES, filter, useCache);
	}

	getPaymentTypes(filter?: Filter<ReferenceListDB>, useCache = true) {
		return this.getByReference(referenceUuids.PAYMENT_TYPES, filter, useCache);
	}

	getTenderTypes(filter?: Filter<ReferenceListDB>, useCache = true) {
		return this.getByReference(referenceUuids.TENDER_TYPES, filter, useCache);
	}

	map(data: ReferenceListDto) {
		return new ReferenceList(data);
	}

	reverseMap(data: ReferenceList): ReferenceListDto {
		throw new Error('Method not implemented.');
	}
}
