import { useApolloClient, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import { isNumber } from 'lodash';
import { useEffect, useRef } from 'react';
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import {
	C_OrderDocumentStatusForReceiveProductsFragmentDoc,
	M_ProductDisplayForReceiveProductsFragmentDoc,
	M_ProductForReceiveProductsDocument,
	M_ProductSearchForReceiveProductsDocument,
} from '../../graphql/__generated__/graphql';
import { DBFilter, Filter, Paging, ProductDB, ProductType } from '../../models';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { uiText } from '../../utils/Language';
import { isEntityReactivated } from '../../utils/StatusUtil';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookupGraphQL from '../entity-lookup/EntityLookupGraphQL';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { ReceiveProductLinesFormValues } from './ProductLineItemTable';
import { ReceiveProductFormValues } from './ReceiveProductForm';

type ReceiveProductLineItemTableRowProps = {
	field?: ReceiveProductLinesFormValues['C_OrderLines'][0];
	index?: number;
	remove?: UseFieldArrayReturn<ReceiveProductLinesFormValues, 'C_OrderLines'>['remove'];
	shouldDisplayTheDeleteColumn: boolean;
	isAddRow?: boolean;
	isDataReadOnly?: boolean;
};

const ReceiveProductLineItemTableRow = ({
	field,
	index,
	remove,
	shouldDisplayTheDeleteColumn,
	isAddRow,
	isDataReadOnly,
}: ReceiveProductLineItemTableRowProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		getValues,
		setValue,
		formState: { isSubmitted },
	} = useFormContext<ReceiveProductFormValues>();
	const isUserWorkingWithTotalPaidField = useRef(false);
	const hasUserAdjustedTotalPaidField = useRef(false);

	const fieldPrefix = isAddRow ? 'addNewOrderLine' : `C_OrderLines.${index}`;

	const quantity =
		useWatch<ReceiveProductLinesFormValues, 'C_OrderLines.0.QtyEntered'>({
			name: `${fieldPrefix}.QtyEntered` as 'C_OrderLines.0.QtyEntered',
		}) || 0;
	const price =
		useWatch<ReceiveProductLinesFormValues, 'C_OrderLines.0.PriceEntered'>({
			name: `${fieldPrefix}.PriceEntered` as 'C_OrderLines.0.PriceEntered',
		}) || 0;
	const totalPaid =
		useWatch<ReceiveProductLinesFormValues, 'C_OrderLines.0.LineNetAmt'>({
			name: `${fieldPrefix}.LineNetAmt` as 'C_OrderLines.0.LineNetAmt',
		}) || 0;
	const productUuid: string = useWatch<ReceiveProductLinesFormValues, 'C_OrderLines.0.M_Product.UU'>({
		name: `${fieldPrefix}.M_Product.UU` as 'C_OrderLines.0.M_Product.UU',
	});
	const [getProduct, { data: product }] = useLazyQuery(M_ProductForReceiveProductsDocument, {
		fetchPolicy: 'cache-first',
	});
	useEffect(() => {
		if (productUuid) {
			getProduct({ variables: { UU: productUuid } });
		}
	}, [getProduct, productUuid]);
	const formIsSubmitted = useRef(isSubmitted);
	formIsSubmitted.current = isSubmitted;

	// Update the total paid if the quantity changes and the user hasn't adjusted the total paid, or update the price if otherwise
	useEffect(() => {
		const price = getValues(`${fieldPrefix}.PriceEntered` as 'C_OrderLines.0.PriceEntered') || 0;
		const totalPaid = getValues(`${fieldPrefix}.LineNetAmt` as 'C_OrderLines.0.LineNetAmt') || 0;
		if (hasUserAdjustedTotalPaidField.current) {
			setValue(`${fieldPrefix}.PriceEntered` as 'C_OrderLines.0.PriceEntered', totalPaid / quantity);
		} else if (quantity !== 0) {
			setValue(`${fieldPrefix}.LineNetAmt` as 'C_OrderLines.0.LineNetAmt', quantity * price, {
				shouldValidate: formIsSubmitted.current,
			});
		}
	}, [quantity, getValues, fieldPrefix, setValue]);
	// Update the total paid if the unit buying price changes
	useEffect(() => {
		const quantity = getValues(`${fieldPrefix}.QtyEntered` as 'C_OrderLines.0.QtyEntered') || 0;
		const totalPaid = getValues(`${fieldPrefix}.LineNetAmt` as 'C_OrderLines.0.LineNetAmt') || 0;
		if (totalPaid !== quantity * price) {
			setValue(`${fieldPrefix}.LineNetAmt` as 'C_OrderLines.0.LineNetAmt', quantity * price, {
				shouldValidate: formIsSubmitted.current,
			});
		}
	}, [price, getValues, fieldPrefix, setValue]);
	// Update the buy price if the total price changes if the user has modified the total
	useEffect(() => {
		if (!hasUserAdjustedTotalPaidField.current) {
			return;
		}
		const quantity = getValues(`${fieldPrefix}.QtyEntered` as 'C_OrderLines.0.QtyEntered') || 0;
		const price = getValues(`${fieldPrefix}.PriceEntered` as 'C_OrderLines.0.PriceEntered') || 0;
		if (totalPaid !== quantity * price && isNumber(totalPaid) && quantity > 0) {
			setValue(`${fieldPrefix}.PriceEntered` as 'C_OrderLines.0.PriceEntered', totalPaid / quantity, {
				shouldValidate: formIsSubmitted.current,
			});
		}
	}, [totalPaid, getValues, fieldPrefix, setValue]);

	const [onSearchProducts, { data: productOptions, loading: areLoadingProducts }] = useLazyQuery(
		M_ProductSearchForReceiveProductsDocument,
		{
			fetchPolicy: 'network-only',
			variables: { Size: Paging.MOST.size, Sort: JSON.stringify([['name', 'asc']]) },
		},
	);

	// If we switch to a product that doesn't expire, clear the guarantee date
	useUpdateEffect(() => {
		if (!product?.M_Product?.M_AttributeSet?.IsGuaranteeDate) {
			setValue(`${fieldPrefix}.GuaranteeDate` as 'C_OrderLines.0.GuaranteeDate', null);
		}
	}, [setValue, product, fieldPrefix]);

	const isOrderLineNew = field?.isOrderLineNew !== false;
	const reactivatedRow =
		isEntityReactivated(
			graphqlClient.readFragment({ id: getValues('UU'), fragment: C_OrderDocumentStatusForReceiveProductsFragmentDoc }),
		) && !isOrderLineNew;

	return (
		<tr>
			<td>
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.UU` as 'C_OrderLines.0.UU')} />}
				<EntityLookupGraphQL
					name={`${fieldPrefix}.M_Product` as 'C_OrderLines.0.M_Product'}
					rules={{ required: !isAddRow }}
					isLoading={areLoadingProducts}
					id={`${fieldPrefix}.M_Product`}
					delay={500}
					inputProps={{ 'aria-label': t(uiText.receiveProduct.name.LABEL) }}
					emptyLabel={t(uiText.product.search.EMPTY)}
					ignoreDiacritics={true}
					labelKey={(data) =>
						graphqlClient.readFragment({ id: data.UU, fragment: M_ProductDisplayForReceiveProductsFragmentDoc })
							?.Name || ''
					}
					minLength={2}
					placeholder={t(uiText.product.search.PLACEHOLDER)}
					promptText={t(uiText.product.search.SEARCHING)}
					searchText={t(uiText.product.search.SEARCHING)}
					options={productOptions?.M_ProductGet.Results || []}
					onSearch={(query) =>
						onSearchProducts({
							variables: {
								Filter: DBFilter<ProductDB>()
									.and(IS_ACTIVE as unknown as Filter<ProductDB>)
									.property('name')
									.contains(query)
									.property('producttype')
									.equals(ProductType.ITEM)
									.toString(),
							},
						})
					}
					disabled={isDataReadOnly || reactivatedRow}
				/>
			</td>
			<td>
				<Controller<ReceiveProductLinesFormValues, 'C_OrderLines.0.QtyEntered'>
					name={`${fieldPrefix}.QtyEntered` as 'C_OrderLines.0.QtyEntered'}
					rules={{
						required: !isAddRow,
						validate: (value) => isAddRow || value !== 0,
					}}
					render={({ field }) => (
						<FormatNumberInput aria-label={t(uiText.receiveProduct.QUANTITY_RECEIVED)} {...field} />
					)}
				/>
			</td>
			<td>
				<Controller<ReceiveProductLinesFormValues, 'C_OrderLines.0.PriceEntered'>
					name={`${fieldPrefix}.PriceEntered` as 'C_OrderLines.0.PriceEntered'}
					render={({ field }) => <FormatNumberInput aria-label={t(uiText.receiveProduct.UNIT_BUY_PRICE)} {...field} />}
				/>
			</td>
			<td>
				<Controller<ReceiveProductLinesFormValues, 'C_OrderLines.0.LineNetAmt'>
					name={`${fieldPrefix}.LineNetAmt` as 'C_OrderLines.0.LineNetAmt'}
					render={({ field }) => (
						<FormatNumberInput
							aria-label={t(uiText.receiveProduct.TOTAL_PAID)}
							{...field}
							onFocus={() => {
								isUserWorkingWithTotalPaidField.current = true;
							}}
							onBlur={() => {
								isUserWorkingWithTotalPaidField.current = false;
								field.onBlur();
							}}
							onChange={(event) => {
								if (isUserWorkingWithTotalPaidField.current) {
									hasUserAdjustedTotalPaidField.current = true;
								}
								field.onChange(event);
							}}
						/>
					)}
				/>
			</td>
			<td>
				<fieldset disabled={!product?.M_Product?.M_AttributeSet?.IsGuaranteeDate || isAddRow}>
					<Controller<ReceiveProductLinesFormValues, 'C_OrderLines.0.GuaranteeDate'>
						name={`${fieldPrefix}.GuaranteeDate` as 'C_OrderLines.0.GuaranteeDate'}
						rules={{ required: !!product?.M_Product?.M_AttributeSet?.IsGuaranteeDate && !isAddRow }}
						render={({ field }) => (
							<BandaDatePicker
								id={`${fieldPrefix}.GuaranteeDate`}
								minDate={startOfDay(new Date())}
								{...field}
								value={undefined}
								selected={field.value}
								aria-label={uiText.receiveProduct.EXPIRATION_DATE}
							/>
						)}
					/>
				</fieldset>
			</td>
			{shouldDisplayTheDeleteColumn && (
				<td className="text-center align-middle">
					{!isAddRow && (
						<button
							aria-label={t(uiText.receiveProduct.button.DELETE)}
							className="btn p-0 w-100"
							tabIndex={-1}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default ReceiveProductLineItemTableRow;
