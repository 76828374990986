import React, { Fragment } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import PrintableTextArea from '../PrintableTextArea/PrintableTextArea';
import ObservationTable from './ObservationTable';

type TriageDetailsProps = {
	isDataReadOnly?: boolean;
	reviewHistory?: boolean;
	loading?: boolean;
};

const TriageDetails = ({ isDataReadOnly, reviewHistory = false, loading = false }: TriageDetailsProps) => {
	const { getValues } = useFormContext();
	const visitUuid: string = getValues('uuid');
	const { t } = useTranslation();

	return (
		<Card className="bh-card">
			<Card.Header className="d-flex">
				<div className="fw-bold h5 mb-0">{t(uiText.visit.form.VITALS)}</div>
				{loading && (
					<div className="ms-auto fw-light">
						<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
						<Spinner animation="border" size="sm" className="align-middle" role="status" />
					</div>
				)}
			</Card.Header>
			<Card.Body>
				<fieldset disabled={isDataReadOnly} className="w-100">
					<Row className="gy-3">
						<ObservationTable visitUuid={visitUuid} isDataReadOnly={isDataReadOnly || reviewHistory} />

						<Form.Group as={Fragment} controlId={`chiefComplaintEncounter${visitUuid}`}>
							<Col xs={1}>
								<Form.Label column>{t(uiText.visit.form.patient.CHIEF_COMPLAINT)}</Form.Label>
							</Col>
							<Col xs={11} className="d-flex align-items-center">
								<Controller
									aria-label={t(uiText.visit.form.patient.CHIEF_COMPLAINT)}
									name={`chiefComplaintEncounter.observations.0.value`}
									render={({ field }) => <PrintableTextArea rows={6} {...field} />}
								/>
							</Col>
						</Form.Group>
					</Row>
				</fieldset>
			</Card.Body>
		</Card>
	);
};

export default TriageDetails;
