import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';

export interface DocumentStatusDto extends BaseEntityDto {}

export default class DocumentStatus extends BaseEntity {
	static APPROVED: 'AP' = 'AP';
	static COMPLETED: 'CO' = 'CO';
	static CLOSED: 'CL' = 'CL';
	static DRAFTED: 'DR' = 'DR';
	static IN_PROGRESS: 'IP' = 'IP';
	static INVALID: 'IN' = 'IN';
	static NOT_APPROVED: 'NA' = 'NA';
	static REVERSED: 'RE' = 'RE';
	static UNKNOWN: '??' = '??';
	static VOIDED: 'VO' = 'VO';
	static WAITING_CONFIRMATION: 'WC' = 'WC';
	static WAITING_PAYMENT: 'WP' = 'WP';

	/**
	 * The text that shows up on the UI
	 */
	get display() {
		return this.name;
	}

	constructor(props?: Partial<DocumentStatus | DocumentStatusDto>) {
		props = cloneDeep(props || {});
		super(props);
	}
}

const DocumentStatusValueObject = {
	Approved: 'AP',
	Completed: 'CO',
	Closed: 'CL',
	Drafted: 'DR',
	InProgress: 'IP',
	Invalid: 'IN',
	NotApproved: 'NA',
	Reversed: 'RE',
	Unknown: '??',
	Voided: 'VO',
	WaitingConfirmation: 'WC',
	WaitingPayment: 'WP',
} as const;

export type DocumentStatusValue = (typeof DocumentStatusValueObject)[keyof typeof DocumentStatusValueObject];
