import { cloneDeep } from 'lodash';
import ProductCategoryTypeService from '../services/ProductCategoryTypeService';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import ProductCategoryType from './ProductCategoryType';

export interface ProductCategoryDB extends BaseEntityDB {
	bh_product_category_type: string;
}

export interface ProductCategoryDto extends BaseEntityDto {
	productCategoryType?: string;
}

export default class ProductCategory extends BaseEntity {
	static getProductProductCategories(productCategories: ProductCategory[]) {
		const productCategoryTypes = new ProductCategoryTypeService().getLocal() || [];
		const productProductCategoryType: Partial<ProductCategoryType> =
			productCategoryTypes.find(
				(productCategoryType) => productCategoryType.name === ProductCategoryType.NAME_PRODUCT,
			) || {};
		return (
			productCategories.filter(
				(productCategory) => productCategory.productCategoryType === productProductCategoryType.value,
			) || []
		);
	}

	static getServiceProductCategories(productCategories: ProductCategory[]) {
		const productCategoryTypes = new ProductCategoryTypeService().getLocal() || [];
		const serviceProductCategoryType: Partial<ProductCategoryType> =
			productCategoryTypes.find(
				(productCategoryType) => productCategoryType.name === ProductCategoryType.NAME_SERVICE,
			) || {};
		return (
			productCategories.filter(
				(productCategory) => productCategory.productCategoryType === serviceProductCategoryType.value,
			) || []
		);
	}

	productCategoryType: string;

	constructor(props?: Partial<ProductCategory | ProductCategoryDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.productCategoryType = props.productCategoryType || '';
	}
}
