import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { C_BPartnerForReceiveProductsDocument } from '../../graphql/__generated__/graphql';
import { ANCHOR_CURRENT_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { RecieveProductSupplierInputFormValues } from './SupplierInput';

const EntityInfo = () => {
	const { t } = useTranslation();
	const businessPartnerUU = useWatch<RecieveProductSupplierInputFormValues, 'C_BPartner.UU'>({
		name: 'C_BPartner.UU',
	});
	const [getBusinessPartner, { data: businessPartner }] = useLazyQuery(C_BPartnerForReceiveProductsDocument, {
		fetchPolicy: 'cache-first',
	});
	useEffect(() => {
		if (businessPartnerUU) {
			getBusinessPartner({ variables: { UU: businessPartnerUU } });
		}
	}, [getBusinessPartner, businessPartnerUU]);
	return (
		<div className="info-ribbon sticky-top print__position-relative">
			{businessPartner?.C_BPartner?.UU && (
				<>
					{!!businessPartner.C_BPartner.Name && (
						<div className="info-ribbon__entity-prop">
							{t(uiText.supplier.summary.OPEN_BALANCE, { openBalance: businessPartner.C_BPartner.TotalOpenBalance })}
						</div>
					)}
					{!!businessPartner.C_BPartner.Contacts?.[0]?.Phone && (
						<div className="info-ribbon__entity-prop">
							{t(uiText.supplier.summary.PHONE, { phone: businessPartner.C_BPartner.Contacts[0].Phone })}
						</div>
					)}
					{businessPartner.C_BPartner.TotalOpenBalance !== undefined &&
						businessPartner.C_BPartner.TotalOpenBalance !== null && (
							<div className="info-ribbon__entity-prop">
								<a href={ANCHOR_CURRENT_PAGE}>{t(uiText.supplier.summary.EDIT)}</a>
							</div>
						)}
				</>
			)}
		</div>
	);
};

export default EntityInfo;
