import React from 'react';

type WindowsLoaderProps = {
	width?: number;
};

const WindowsLoader = ({ width }: WindowsLoaderProps) => {
	width = width || 360;
	const translationDistance = 50;
	const interval = 0.2;
	const commonProperties = {
		attributeName: 'transform',
		type: 'translate',
		dur: '3s',
		repeatCount: 'indefinite',
		keyTimes: '0;.1;.4;.5;1',
		keySplines: '0.2 0.6 0.4 0.8;0 0 1 1;0.4 0.2 0.6 0.2;0 0 1 1',
		calcMode: 'spline',
	};

	return (
		<svg
			className="icon-loading"
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 ${width} 32`}
			width={width}
			height="32"
			fill="white"
		>
			<path transform="translate(-8 0)" d="M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12">
				<animateTransform
					{...commonProperties}
					values={`-8 0; ${(width - translationDistance) / 2 + 20} 0; ${(width + translationDistance) / 2 + 20} 0; ${
						width + 8
					} 0; ${width + 8} 0`}
					begin="0"
				/>
			</path>
			<path transform="translate(-8 0)" d="M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12">
				<animateTransform
					{...commonProperties}
					values={`-8 0; ${(width - translationDistance) / 2 + 10} 0; ${(width + translationDistance) / 2 + 10} 0; ${
						width + 8
					} 0; ${width + 8} 0`}
					begin={`${interval}s`}
				/>
			</path>
			<path transform="translate(-8 0)" d="M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12">
				<animateTransform
					{...commonProperties}
					values={`-8 0; ${(width - translationDistance) / 2} 0; ${(width + translationDistance) / 2} 0; ${
						width + 8
					} 0; ${width + 8} 0`}
					begin={`${interval * 2}s`}
				/>
			</path>
			<path transform="translate(-8 0)" d="M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12">
				<animateTransform
					{...commonProperties}
					values={`-8 0; ${(width - translationDistance) / 2 - 10} 0; ${(width + translationDistance) / 2 - 10} 0; ${
						width + 8
					} 0; ${width + 8} 0`}
					begin={`${interval * 3}s`}
				/>
			</path>
			<path transform="translate(-8 0)" d="M4 12 A4 4 0 0 0 4 20 A4 4 0 0 0 4 12">
				<animateTransform
					{...commonProperties}
					values={`-8 0; ${(width - translationDistance) / 2 - 20} 0; ${(width + translationDistance) / 2 - 20} 0; ${
						width + 8
					} 0; ${width + 8} 0`}
					begin={`${interval * 4}s`}
				/>
			</path>
		</svg>
	);
};

export default WindowsLoader;
