import axios from 'axios';
import { isArray } from 'lodash';
import { Response } from '../models';
import BaseMetadata, { BaseMetadataDto } from '../models/BaseMetadata';
import { configs } from '../utils/Configs';
import BaseService from './BaseService';

/**
 * This class is not meant to be used directly, but is meant to be extended and the children classes should be used
 */
export default abstract class NewMetadataService<
	T extends BaseMetadata = BaseMetadata,
	S extends BaseMetadataDto = BaseMetadataDto,
> extends BaseService<T, S> {
	protected metadataUrl = `${configs.apiUrl}/metadata`;

	// Override these in the child class ("abstract" properties)
	protected abstract getUrl: string;
	protected abstract localStorageKey: string;

	/**
	 * Perform a request to the specified URL with paging, sizing, and sorting
	 * @param useCache Whether the cache should be used, if available (defaults to true)
	 */
	async get(useCache?: boolean) {
		useCache = useCache === undefined ? true : useCache;
		let metaData = this.getLocal();
		if (metaData.length && useCache) {
			return metaData;
		}
		metaData = await axios.get<S[] | Response<S>>(this.getUrl).then(({ data }) => {
			if (!data) {
				return [];
			}

			if (isArray(data)) {
				return data.map(this.map);
			} else if (isArray(data.results)) {
				return data.results.map(this.map);
			}
			return [];
		});
		localStorage.setItem(this.localStorageKey, JSON.stringify(metaData));
		return metaData;
	}

	/**
	 * Get the data stored locally (may be an empty array if not initialized yet)
	 */
	getLocal(): T[] {
		let metaData = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
		if (metaData && isArray(metaData) && metaData.length) {
			return metaData.map(this.map);
		}
		return [];
	}

	/**
	 * Clear the local storage for this metadata
	 */
	clear(): void {
		localStorage.removeItem(this.localStorageKey);
	}
}
