import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';

export interface ConceptNameDB extends BaseMetadataDB {}

export interface ConceptNameDto extends BaseMetadataDto {
	locale?: string;
	type?: string;
	nameType?: string;
	externalId?: string;
	name?: string;
	localePreferred?: boolean;
}

export default class ConceptName extends BaseMetadata {
	locale: string;
	type: string;
	nameType: string;
	externalId: string;
	name: string;
	localePreferred: boolean;

	constructor(props?: Partial<ConceptName | ConceptNameDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.locale = props.locale || '';
		this.type = props.type || '';
		this.nameType = props.nameType || '';
		this.externalId = props.externalId || '';
		this.name = props.name || '';
		this.localePreferred = props.localePreferred === undefined ? false : !!props.localePreferred;
	}
}
