import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';

export interface SerialNumberControlDB extends BaseEntityDB {
	bh_locked: boolean;
}

export interface SerialNumberControlDto extends BaseEntityDto {
	startNumber?: number;
	incrementNumber?: number;
	currentNext?: number;
	prefix?: string | null;
	suffix?: string | null;
	isLocked?: boolean;
}

export default class SerialNumberControl extends BaseEntity {
	startNumber: number;
	incrementNumber: number;
	currentNext: number;
	prefix?: string;
	suffix?: string;
	isLocked: boolean;

	constructor(props?: DeepPartial<SerialNumberControl | SerialNumberControlDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.startNumber = props.startNumber || 100;
		this.incrementNumber = props.incrementNumber || 1;
		this.currentNext = props.currentNext || 100;
		this.prefix = props.prefix || undefined;
		this.suffix = props.suffix || undefined;
		this.isLocked = props.isLocked !== false;
	}
}
