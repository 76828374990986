import axios from 'axios';
import { isArray } from 'lodash';
import { Filter } from '../models';
import InventoryTransaction, { InventoryTransactionDB, InventoryTransactionDto } from '../models/InventoryTransaction';
import Response from '../models/Response';
import Sort from '../models/Sort';
import { configs } from '../utils/Configs';
import { createPagingParameters } from '../utils/Pagination';
import BaseService from './BaseService';

export default class InventoryTransactionService extends BaseService {
	private entityName = 'inventory-transactions';

	map(data: InventoryTransactionDto): InventoryTransaction {
		return new InventoryTransaction(data);
	}

	reverseMap(data: InventoryTransaction): InventoryTransactionDto {
		throw new Error('not implemented');
	}

	async get(
		page?: number,
		size?: number,
		sorted?: Sort<InventoryTransactionDB>[],
		filter?: Filter<InventoryTransactionDB>,
	): Promise<Response<InventoryTransaction>> {
		return axios
			.get<
				Response<InventoryTransactionDto>
			>(`${configs.apiUrl}/${this.entityName}${createPagingParameters(page, size, sorted, filter)}`)
			.then(({ data }) => {
				let mappedData: InventoryTransaction[] = [];
				if (data && isArray(data.results)) {
					mappedData = data.results.map(this.map);
				}
				return new Response<InventoryTransaction>({ ...data, results: mappedData });
			});
	}
}
