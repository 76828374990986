import { cloneDeep, isNumber } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import AttributeSet, { AttributeSetDto } from './AttributeSet';
import BaseMetadata, { BaseMetadataDto } from './BaseMetadata';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import { BaseEntityDB } from './BaseEntity';

export interface AttributeSetInstanceDB extends BaseEntityDB {
	guaranteeDate: Date;
}

export interface AttributeSetInstanceDto extends BaseMetadataDto {
	guaranteeDate?: number | null;
	updateReason?: ReferenceListDto | null;
	serialNumber?: string | null;
	lot?: string | null;
	attributeSet?: AttributeSetDto | null;
	purchaseDate?: number | null;
	purchasePrice?: number;
}

export default class AttributeSetInstance extends BaseMetadata {
	guaranteeDate?: Date;
	updateReason?: ReferenceList;
	serialNumber?: string;
	lot?: string;
	attributeSet?: AttributeSet;
	purchaseDate?: number;
	purchasePrice: number;

	constructor(props: DeepPartial<AttributeSetInstance | AttributeSetInstanceDto> = {}) {
		props = cloneDeep(props);
		super(props);

		this.guaranteeDate =
			((isNumber(props.guaranteeDate) || props.guaranteeDate instanceof Date) && new Date(props.guaranteeDate)) ||
			undefined;
		this.updateReason = (props.updateReason && new ReferenceList(props.updateReason)) || undefined;
		this.serialNumber = props.serialNumber || undefined;
		this.lot = props.lot || undefined;
		this.attributeSet = props.attributeSet ? new AttributeSet(props.attributeSet) : undefined;
		this.purchaseDate = props.purchaseDate || undefined;
		this.purchasePrice = props.purchasePrice || 0;
	}
}
