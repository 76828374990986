import { useApolloClient } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { TableRowProps, UseRowSelectState, UseTableRowProps } from 'react-table';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import { ProductRefreshContext } from '../../contexts/ProductRefreshContext';
import UserContext from '../../contexts/UserContext';
import {
	InventoryTransactionForProductPageDocument,
	InventoryTransactionForProductPageQuery,
	M_WarehousesForProductsPageDocument,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import { businessPartnerGroupName, DBFilter } from '../../models';
import { InventoryTransactionDB, TransactionType } from '../../models/InventoryTransaction';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { formatDate } from '../../utils/DateUtil';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import BHGraphQLTable, { BHGraphQLTableProps } from '../BHTable/BHGraphQLTable';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReceiveProductForm from '../ReceiveProduct/ReceiveProductForm';
import TransferInventoryForm from '../TransferInventory/TransferInventoryForm';
import VisitForm from '../Visit/VisitForm';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';

type TransactionTableProps = {
	productUuid: string;
};

const isTransactionDrafted = (
	inventoryTransaction: InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0],
) => {
	return inventoryTransaction.TransactionType === TransactionType.DRAFTED;
};

const getTransactionTypeToUse = (
	inventoryTransaction: InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0],
) => {
	if (inventoryTransaction.C_Order?.C_BPartner?.C_BP_Group?.Name === businessPartnerGroupName.OTC) {
		return TransactionType.OTC_SALE;
	}

	if (!isTransactionDrafted(inventoryTransaction)) {
		return inventoryTransaction.TransactionType;
	}

	if (inventoryTransaction?.C_Order?.UU) {
		return inventoryTransaction.C_Order.IsSOTrx ? TransactionType.PATIENT_SALE : TransactionType.PRODUCT_RECEIVED;
	}

	if (inventoryTransaction?.M_Movement?.UU) {
		return inventoryTransaction.MovementQty > 0 ? TransactionType.TRANSFER_IN : TransactionType.TRANSFER_OUT;
	}

	return inventoryTransaction.TransactionType;
};

const transactionTypeTranslations: { [key: string]: string } = {
	[TransactionType.DRAFTED]: uiText.transaction.transactionType.DRAFTED,
	[TransactionType.INITIAL_INVENTORY]: uiText.transaction.transactionType.INITIAL_INVENTORY,
	[TransactionType.MANUAL_INVENTORY_ADJUSTMENT]: uiText.transaction.transactionType.MANUAL_INVENTORY_ADJUSTMENT,
	[TransactionType.OTC_SALE]: uiText.transaction.transactionType.OTC_SALE,
	[TransactionType.PATIENT_RETURNS]: uiText.transaction.transactionType.PATIENT_RETURNS,
	[TransactionType.PATIENT_SALE]: uiText.transaction.transactionType.PATIENT_SALE,
	[TransactionType.PRODUCT_RECEIVED]: uiText.transaction.transactionType.PRODUCT_RECEIVED,
	[TransactionType.REACTIVATE_PATIENT_SALE]: uiText.transaction.transactionType.REACTIVATE_PATIENT_SALE,
	[TransactionType.REACTIVATED_PRODUCT_RECEIPT]: uiText.transaction.transactionType.REACTIVATED_PRODUCT_RECEIPT,
	[TransactionType.SUPPLIER_RETURNS]: uiText.transaction.transactionType.SUPPLIER_RETURNS,
	[TransactionType.TRANSFER_IN]: uiText.transaction.transactionType.TRANSFER_IN,
	[TransactionType.TRANSFER_OUT]: uiText.transaction.transactionType.TRANSFER_OUT,
	[TransactionType.UNKNOWN_STATUS]: uiText.transaction.transactionType.UNKNOWN_STATUS,
	[TransactionType.VOID_PATIENT_SALE]: uiText.transaction.transactionType.VOID_PATIENT_SALE,
	[TransactionType.VOID_PRODUCT_RECEIPT]: uiText.transaction.transactionType.VOID_PRODUCT_RECEIPT,
	unknown: uiText.transaction.transactionType.UNKNOWN_STATUS,
};

const TransactionTable = ({ productUuid }: TransactionTableProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { disableWrite } = useActionPrivileges(pageUuid.PRODUCTS);

	// Used to Refresh the transaction when there is a change in the inventory
	const { triggerUpdate: triggerProductRefresh, willTrigger: willTriggerProductRefresh } =
		useContext(ProductRefreshContext);

	const {
		data,
		isLoading,
		onFilterUpdate,
		reset,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, sorted, totalRecordCount },
	} = useGraphQLListPageFunctionality<InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0]>(
		{
			fetch: useCallback(
				async (variables) =>
					(
						await graphqlClient.query({
							query: InventoryTransactionForProductPageDocument,
							variables: variables,
							fetchPolicy: 'network-only',
						})
					).data.InventoryTransactionGet,
				[graphqlClient, willTriggerProductRefresh],
			),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.transaction.loading.FAIL));
					}
					exception({ description: `Transaction fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			sorted: JSON.stringify([['Created', 'desc']]),
		},
	);

	const [visitUuid, setVisitUuid] = useState<string | undefined>();
	const [purchaseOrderUuid, setPurchaseOrderUuid] = useState<string | undefined>();
	const [movementUuid, setMovementUuid] = useState<string | undefined>();

	const { willTrigger: willTriggerFilters, triggerUpdate: triggerFilterRefresh } = useTriggerUpdate();

	const { organization } = useContext(UserContext);

	// Filter state
	const [warehouseFilter, setWarehouseFilter, { reset: resetWarehouseFilter }] = useStateWithReset('');

	const { value: warehouses = [] } = useAsync(
		async () =>
			(
				await graphqlClient.query({
					query: M_WarehousesForProductsPageDocument,
					variables: {
						Filter: getWarehouseByOrg(organization.uuid).toString(),
					},
					fetchPolicy: 'cache-first',
				})
			).data.M_WarehouseGet.Results,
		[organization, graphqlClient],
	);

	// Handle filtering & refreshes
	useEffect(() => {
		const filter = DBFilter<InventoryTransactionDB>()
			.nested('m_product')
			.property('m_product_uu')
			.equals(productUuid)
			.up();

		if (warehouseFilter) {
			filter.and(
				DBFilter<InventoryTransactionDB>()
					.nested('m_locator')
					.nested('m_warehouse')
					.property('M_Warehouse_UU')
					.equals(warehouseFilter)
					.up()
					.up(),
			);
		}
		onFilterUpdate(filter.toString());
	}, [onFilterUpdate, willTriggerFilters, warehouseFilter, productUuid, willTriggerProductRefresh, graphqlClient]);

	useRefreshOnRepeatedRoute(() => {
		resetWarehouseFilter();
		triggerFilterRefresh();
		reset(false);
	});

	// redirect the entity to the appropriate page
	const onClickRow = async (
		inventoryTransaction: InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0],
	) => {
		if (disableWrite) {
			return;
		}

		// redirect visit
		if (inventoryTransaction?.BH_Visit?.UU) {
			setVisitUuid(inventoryTransaction.BH_Visit.UU);
			setPurchaseOrderUuid(undefined);
			setMovementUuid(undefined);
			return;
		}

		// redirect receive product
		if (inventoryTransaction?.C_Order?.UU) {
			setVisitUuid(undefined);
			setPurchaseOrderUuid(inventoryTransaction.C_Order.UU);
			setMovementUuid(undefined);
			return;
		}

		// redirect transfer inventory
		if (inventoryTransaction?.M_Movement?.UU) {
			setVisitUuid(undefined);
			setPurchaseOrderUuid(undefined);
			setMovementUuid(inventoryTransaction.M_Movement.UU);
			return;
		}
	};

	const rowPropertiesForDisabledRow = (
		state?: UseRowSelectState<InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0]>,
		rowInfo?: UseTableRowProps<InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0]>,
	): TableRowProps | object => {
		if (!rowInfo) {
			return {};
		}

		return {
			className: isTransactionDrafted(rowInfo.original)
				? (rowInfo.getRowProps().className = 'bg-gray-200 fst-italic')
				: '',
			onClick: () => onClickRow(rowInfo?.original),
			style: {
				cursor: 'pointer',
			},
		};
	};

	const stockTakeColumns = useMemo(() => {
		type ColumnType = BHGraphQLTableProps<
			InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0]
		>['columns'][0];
		let columns: ColumnType[] = [];
		columns.push({
			id: 'Created',
			Header: () => (
				<div className={'React-table-header align-middle text-center'}>{t(uiText.transaction.columnHeader.DATE)}</div>
			),
			accessor: (d) => <div className="px-2">{formatDate(new Date(d.Created))}</div>,
			disableSortBy: true,
		} as ColumnType);

		if (warehouses.length > 1) {
			columns.push({
				id: 'M_Locator.M_Warehouse.Name',
				Header: () => <div className={'React-table-header'}>{t(uiText.transaction.columnHeader.STORE_ROOM)}</div>,
				accessor: (d) => <div className="px-2">{d.M_Locator?.M_Warehouse?.Name}</div>,
				disableSortBy: true,
			} as ColumnType);
		}

		columns.push(
			{
				id: 'TransactionType',
				Header: () => <div className={'React-table-header'}>{t(uiText.transaction.columnHeader.TRANSACTION_TYPE)}</div>,
				accessor: (d) => (
					<div className="px-2">{t(transactionTypeTranslations[getTransactionTypeToUse(d) || 'unknown'])}</div>
				),
				disableSortBy: true,
			} as ColumnType,
			{
				id: 'm_attributesetinstance.Guaranteedate',
				Header: () => <div className={'React-table-header'}>{t(uiText.transaction.columnHeader.BATCH)}</div>,
				accessor: (d) => (
					<div className="px-2">
						{d.M_AttributeSetInstance?.GuaranteeDate &&
							t(uiText.transaction.EXPIRATION, {
								date: formatDate(new Date(d.M_AttributeSetInstance.GuaranteeDate)),
							})}
					</div>
				),
				disableSortBy: true,
			} as ColumnType,
			{
				id: 'CreatedBy.Name',
				Header: () => <div className={'React-table-header'}>{t(uiText.transaction.columnHeader.DONE_BY)}</div>,
				accessor: (d) => <div className="px-2">{d.CreatedBy.Name || '-'}</div>,
				disableSortBy: true,
			} as ColumnType,
			{
				id: 'MovementQty',
				Header: () => <div className={'React-table-header'}>{t(uiText.transaction.columnHeader.QUANTITY)}</div>,
				accessor: (d) => <div className="px-2 text-end">{d.MovementQty}</div>,
				disableSortBy: true,
			} as ColumnType,
			{
				id: 'RunningTotal',
				Header: () => <div className={'React-table-header'}>{t(uiText.transaction.columnHeader.TOTAL)}</div>,
				accessor: (d) => (
					<div className="px-2 text-end">
						{isTransactionDrafted(d) ? t(uiText.transaction.DRAFTED_STATUS) : d.RunningTotal}
					</div>
				),
				disableSortBy: true,
			} as ColumnType,
		);
		return columns;
	}, [t, warehouses]);

	return (
		<>
			{visitUuid && (
				<VisitForm
					uuid={visitUuid}
					onFinish={(refresh) => {
						if (refresh) {
							triggerProductRefresh();
						}
						setVisitUuid(undefined);
					}}
					renderAsModal
					canSaveMany={false}
				/>
			)}
			{purchaseOrderUuid && (
				<ReceiveProductForm
					uuid={purchaseOrderUuid}
					onFinish={(refresh) => {
						if (refresh) {
							triggerProductRefresh();
						}
						setPurchaseOrderUuid(undefined);
					}}
					renderAsModal
					canSaveMany={false}
				/>
			)}
			{movementUuid && (
				<TransferInventoryForm
					uuid={movementUuid}
					onFinish={(refresh) => {
						if (refresh) {
							triggerProductRefresh();
						}
						setMovementUuid(undefined);
					}}
					renderAsModal
					canSaveMany={false}
				/>
			)}
			<WorkspaceMenu.Filters>
				{warehouses.length > 1 && (
					<Form.Group controlId="storeroomFilter">
						<Form.Label column>{t(uiText.inventory.filter.SHOW_STOREROOMS)}</Form.Label>
						<Form.Select
							className="ms-2 w-auto d-inline-block"
							value={warehouseFilter}
							onChange={(e) => setWarehouseFilter(e.target.value)}
						>
							<option value="">{t(uiText.inventory.filter.ALL)}</option>
							{warehouses.map((warehouse) => (
								<option key={warehouse.UU} value={warehouse.UU}>
									{warehouse.Name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				)}
			</WorkspaceMenu.Filters>
			<div className={'mt-2'}>
				<BHGraphQLTable<InventoryTransactionForProductPageQuery['InventoryTransactionGet']['Results'][0]>
					data={data}
					columns={stockTakeColumns}
					defaultPageSize={pageSize}
					pages={pages}
					page={page}
					pageSizeOptions={pageSizeOptions}
					LoadingComponent={() => {
						return <LoadSpinner show={isLoading} title={t(uiText.transaction.loading.LOADING)} />;
					}}
					onFetchData={onTableUpdate}
					sortBy={sorted}
					totalRecordCount={totalRecordCount}
					getTrGroupProps={rowPropertiesForDisabledRow}
					className={'bh-table--form mb-3'}
					paginationClassName={'px-0 py-1'}
				/>
			</div>
		</>
	);
};

export default TransactionTable;
