import i18n from 'i18next';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import BandaModal, { closeModal } from '../components/Modal/BandaModal';
import { uiText } from './Language';

function ConfirmAction(message: string, callback: () => void) {
	const handleOk = () => {
		callback();
		closeModal();
	};
	return BandaModal<void>(
		<Modal>
			<Modal.Header>{i18n.t(uiText.modal.WARNING)}</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={closeModal}>
					{i18n.t(uiText.modal.button.CANCEL)}
				</Button>
				<Button variant="danger" onClick={handleOk}>
					{i18n.t(uiText.modal.button.DELETE)}
				</Button>
			</Modal.Footer>
		</Modal>,
	);
}

export default ConfirmAction;
