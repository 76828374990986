import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Ad_Ref_ListPayerInformationFieldDataTypesDocument } from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import { DBFilter, ReferenceListDB, referenceUuids } from '../../models';
import { pageUuid } from '../../services/AuthService';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import { AdditionalInformationFromVisitiFormFields } from './AdditionalInformationFromVisit';
import AdditionalInfoValuesForm from './AdditionalInfoValuesForm';
import ReorderButtons from './ReorderButtons';

type AdditionalInformationFromVisitRowProps = {
	field: AdditionalInformationFromVisitiFormFields['BH_Payer_Info_FldList'][0];
	index: number;
	remove: UseFieldArrayReturn<AdditionalInformationFromVisitiFormFields, 'BH_Payer_Info_FldList'>['remove'];
	move: UseFieldArrayReturn<AdditionalInformationFromVisitiFormFields, 'BH_Payer_Info_FldList'>['move'];
	isLast: boolean;
};

/**
 * This is the component for the additional information rows in the additional information section. It is kept in this file
 * because it's not intended to be reused. It is separated to avoid re-rendering the whole form.
 * @param props The props for this component
 */
const AdditionalInformationFromVisitRow = ({
	field,
	index,
	remove,
	move,
	isLast,
}: AdditionalInformationFromVisitRowProps) => {
	const { t } = useTranslation();
	const { disableDeactivate } = useActionPrivileges(pageUuid.INSURERS_AND_DONORS);
	const { register, setValue } = useFormContext<AdditionalInformationFromVisitiFormFields>();
	const payerInformationFieldListFieldName = `BH_Payer_Info_FldList.${index}`;
	const chargeInformationIsActive: boolean = useWatch<
		AdditionalInformationFromVisitiFormFields,
		'BH_Payer_Info_FldList.0.IsActive'
	>({
		name: `${payerInformationFieldListFieldName}.IsActive` as 'BH_Payer_Info_FldList.0.IsActive',
		defaultValue: field.IsActive,
	});

	const { data: dataTypes, loading: areLoadingDataTypes } = useQuery(
		Ad_Ref_ListPayerInformationFieldDataTypesDocument,
		{
			variables: {
				Filter: DBFilter<ReferenceListDB>()
					.nested('ad_reference')
					.property('ad_reference_uu')
					.equals(referenceUuids.PAYER_INFORMATION_FIELD_DATA_TYPE)
					.up()
					.toString(),
			},
			fetchPolicy: 'cache-first',
		},
	);

	// We need to override one of the registration functions, so do that here
	const nameRegister = register(`${payerInformationFieldListFieldName}.Name` as 'BH_Payer_Info_FldList.0.Name', {
		required: true,
	});

	return (
		<tr>
			<td className="align-middle">
				<input
					type="hidden"
					{...register(`${payerInformationFieldListFieldName}.UU` as 'BH_Payer_Info_FldList.0.UU')}
				/>
				<ReorderButtons index={index} move={move} isLast={isLast} />
			</td>
			<td className="align-middle text-center">
				<fieldset disabled={disableDeactivate}>
					<Form.Check
						aria-label={t(uiText.nonPatientPayment.button.ACTIVE)}
						id={`${payerInformationFieldListFieldName}.isActive`}
						{...register(`${payerInformationFieldListFieldName}.IsActive` as 'BH_Payer_Info_FldList.0.IsActive')}
					/>
				</fieldset>
			</td>
			<td className="align-middle text-center">
				<fieldset disabled={!chargeInformationIsActive} className="custom-control form-checkbox">
					<Form.Check
						aria-label={t(uiText.nonPatientPayment.tableHeaders.FILL_FROM_PATIENT)}
						id={`${payerInformationFieldListFieldName}.shouldFillFromPatient`}
						{...register(
							`${payerInformationFieldListFieldName}.BH_FillFromPatient` as 'BH_Payer_Info_FldList.0.BH_FillFromPatient',
						)}
					/>
				</fieldset>
			</td>
			<td className="align-middle">
				<fieldset disabled={!chargeInformationIsActive}>
					<Form.Control
						aria-label={t(uiText.nonPatientPayment.tableHeaders.NAME)}
						placeholder={t(uiText.nonPatientPayment.label.ENTER_NAME)}
						{...nameRegister}
						onChange={(e) => {
							setValue('hasInfoBeenModified', 'true');
							nameRegister.onChange(e);
						}}
					/>
				</fieldset>
			</td>
			<td className="align-middle">
				<fieldset disabled={!chargeInformationIsActive}>
					<Controller
						name={
							`${payerInformationFieldListFieldName}.BH_PayerInfoFieldDataTypeUU` as 'BH_Payer_Info_FldList.0.BH_PayerInfoFieldDataTypeUU'
						}
						rules={{ required: true }}
						render={({ field: controllerField }) => (
							<DynamicSelect
								isLoading={areLoadingDataTypes}
								aria-label={t(uiText.nonPatientPayment.tableHeaders.TYPE)}
								{...controllerField}
							>
								{dataTypes?.AD_Ref_ListGet.Results.map((dataType) => (
									<option key={dataType.UU} value={dataType.UU}>
										{dataType.Name}
									</option>
								))}
							</DynamicSelect>
						)}
					/>
				</fieldset>
			</td>
			<td className="align-middle">
				<AdditionalInfoValuesForm field={field} index={index} />
			</td>
			<td className="align-middle text-center">
				{field.isNew && (
					<button
						aria-label={t(uiText.nonPatientPayment.button.DELETE)}
						type="button"
						className="btn p-0 w-100"
						tabIndex={-1}
						onClick={() => {
							setValue('hasInfoBeenModified', 'true');
							remove(index);
						}}
					>
						<FontAwesomeIcon icon="trash" className="border-0" />
					</button>
				)}
			</td>
		</tr>
	);
};

export default AdditionalInformationFromVisitRow;
