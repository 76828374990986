import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Image, { ImageDto } from './Image';
import Location, { LocationDto } from './Location';

export interface OrganizationInformationDto extends BaseMetadataDto {
	receiptFooterMessage: string;
	phone: string;
	headerMessage: string;
	facilityNumber: string;
	paymentInformation: string;
	location: LocationDto;
	logo?: ImageDto;
}

export interface OrganizationInformationDB extends BaseMetadataDB {}

export default class OrganizationInformation extends BaseMetadata {
	receiptFooterMessage: string;
	phone: string;
	headerMessage: string;
	facilityNumber: string;
	paymentInformation: string;
	location: Location;
	logo?: Image;

	constructor(props: Partial<OrganizationInformationDto | OrganizationInformation> = {}) {
		props = cloneDeep(props);
		super(props);

		this.receiptFooterMessage = props.receiptFooterMessage || '';
		this.phone = props.phone || '';
		this.headerMessage = props.headerMessage || '';
		this.facilityNumber = props.facilityNumber || '';
		this.paymentInformation = props.paymentInformation || '';
		this.location = new Location(props.location || {});
		this.logo = new Image(props.logo || {});
	}
}
