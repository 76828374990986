import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col } from 'react-bootstrap';

type LayoutNotificationProps = { title: string; listener: () => void; count: number };

const LayoutNotification = ({ title, listener, count }: LayoutNotificationProps) => (
	<Col xs="auto" className="pe-1 d-flex align-items-center">
		<div className="cursor-pointer" title={title} onClick={listener}>
			<FontAwesomeIcon icon="bell" className="layout__top-bar__user-panel__notifications__icon" />
			<span className="layout__top-bar__user-panel__notifications__counter text-white bg-danger">{count}</span>
		</div>
	</Col>
);

export default LayoutNotification;
