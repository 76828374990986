import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import { formatDate } from '../utils/DateUtil';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import BusinessPartner, { BusinessPartnerDB, BusinessPartnerDto } from './BusinessPartner';
import DocumentStatus, { DocumentStatusValue } from './DocumentStatus';
import DocumentType, { DocumentTypeDto } from './DocumentType';
import ReferenceList, { ReferenceListDto } from './ReferenceList';

export interface PaymentDB extends BaseMetadataDB {
	'C_BPartner.totalopenbalance': number;
	dateTrx: Date;
	'C_BPartner.name': string;
	payAmt: number;
	'patient.totalOpenBalance': number;
	docStatus: string;
	bh_visit_id: number;
	isReceipt: boolean;
	c_bpartner: BusinessPartnerDB;
}

export interface PaymentDto extends BaseMetadataDto {
	transactionDate?: string;
	payAmount: number;
	businessPartner?: BusinessPartnerDto;
	paymentType?: ReferenceListDto;
	description?: string;
	docStatus?: string;
	tenderAmount: number;
	documentType?: DocumentTypeDto | null;
}

/**
 * Payment information for anything in the system
 * @property {Patient} patient The patient making this payment
 * @property {number} payAmount The amount this payment is for
 * @property {ReferenceList} paymentType The payment type for this payment
 * @property {string} description The description for this payment
 * @property {string} documentStatus The document status for this payment
 * @property {Date} transactionDate The date associated with this payment
 * @property {number} tenderAmount The amount tendered on this payment
 */
export default class Payment extends BaseMetadata {
	businessPartner: BusinessPartner;
	payAmount?: number;
	paymentType: ReferenceList;
	description: string;
	documentStatus: DocumentStatusValue;
	transactionDate: Date;
	tenderAmount: number;
	documentType?: DocumentType;

	/**
	 * The transaction date, but formatted
	 */
	get transactionDateFormatted() {
		return formatDate(this.transactionDate);
	}

	/**
	 * The default constructor for a payment
	 * @param {Payment} entity The payment to copy data from
	 */
	constructor(entity: DeepPartial<Payment | PaymentDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.businessPartner = new BusinessPartner(entity.businessPartner);
		this.payAmount = entity.payAmount;
		this.paymentType = new ReferenceList(entity.paymentType);
		this.description = entity.description || '';
		if ('documentStatus' in entity) {
			this.documentStatus = entity.documentStatus || DocumentStatus.DRAFTED;
		} else if ('docStatus' in entity) {
			this.documentStatus = (entity.docStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
		} else {
			this.documentStatus = DocumentStatus.DRAFTED;
		}
		this.transactionDate =
			((typeof entity.transactionDate === 'string' || entity.transactionDate instanceof Date) &&
				new Date(entity.transactionDate)) ||
			new Date();
		this.tenderAmount = entity.tenderAmount || 0;
		this.documentType = entity.documentType ? new DocumentType(entity.documentType) : undefined;
	}
}
