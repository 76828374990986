import {
	ApolloClient,
	ApolloLink,
	defaultDataIdFromObject,
	FieldFunctionOptions,
	FieldPolicy,
	FieldReadFunction,
	from,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { RetryLink } from '@apollo/client/link/retry';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import history from './utils/History';
import { uiText } from './utils/Language';

// Rather than have to duplicate the field logic below, just add to this array
const fieldMappingsWithUUForCache = [
	'AD_Process',
	'AD_Process_Para',
	'AD_Ref_List',
	'AD_Role',
	'AD_User',
	'AD_User_Roles',
	'BH_Concept',
	'BH_Payer_Info_Fld_Sug',
	'BH_Payer_Info_Fld',
	'C_AcctSchema',
	'C_BP_Group',
	'C_BPartner_Locations',
	'C_BankAccount',
	'C_Charge',
	'C_Charge_Acct',
	'C_Currency',
	'C_DocStatus',
	'C_ElementValue',
	'C_Location',
	'C_Order',
	'C_OrderLine',
	'C_Payment',
	'C_PaymentTerm',
	'C_TaxCategory',
	'C_ValidCombination',
	'M_AttributeSet',
	'M_AttributeSetInstance',
	'M_Locator',
	'M_PriceList',
	'M_PriceList_Version',
	'M_Product',
	'M_Product_Category',
	'M_ProductPrice',
	'M_SerNoCtl',
	'M_StorageOnHand',
	'M_Warehouse',
];

export default function getGraphqlClient() {
	const slowConnectionMeasurementLink = new ApolloLink((operation, forward) => {
		const startTime = Date.now();
		return forward(operation).map((response) => {
			const timeInMs = startTime - Date.now();
			// If a request takes more than 30secs, assume that the connection is slow. This should probably be our baseline for how long any request takes?
			if (timeInMs > 30000) {
				toast.warn(i18n.t(uiText.error.SLOW_CONNECTION));
			}
			return response;
		});
	});

	const unauthorizedLink = new ApolloLink((operation, forward) =>
		forward(operation).map((response) => {
			if (response.errors?.some((error) => error.message === 'Unauthorized')) {
				history.push('/session-timeout'); //redirect user to session timeout page
			}
			return response;
		}),
	);

	let httpLink: ApolloLink = new BatchHttpLink({
		uri: '/graphql/',
		batchMax: 10,
		batchInterval: 20,
	});
	// ! TODO Remove this when we're using MSW 2.x (which is Node 18+)
	if (process.env.NODE_ENV === 'test') {
		httpLink = new HttpLink({ uri: '/graphql/' });
	}

	return new ApolloClient({
		cache: new InMemoryCache({
			dataIdFromObject(responseObject) {
				if (typeof responseObject['UU'] === 'string') {
					return responseObject.UU;
				}
				return defaultDataIdFromObject(responseObject);
			},
			typePolicies: {
				Query: {
					// Define the specific field mappings for each entity
					fields: {
						...fieldMappingsWithUUForCache.reduce(
							(fields, field) => {
								fields[field] = fields[field] || {};
								(fields[field] as FieldPolicy<any>).read = (
									_existing: SafeReadonly<any> | undefined,
									{ args, toReference }: FieldFunctionOptions,
								) => toReference({ __typename: field, UU: args?.UU });
								return fields;
							},
							{
								// Define other field type policies to merge with the ones from the entities above
							} as {
								[fieldName: string]: FieldPolicy<any> | FieldReadFunction<any>;
							},
						),
						DocumentStatusActionMap: {
							read(value) {
								return value && typeof value === 'string' ? JSON.parse(value) : value;
							},
						},
					},
				},
				M_Product: {
					fields: {
						// Ensure this value is never cached - we always want to get it from the server
						M_StorageOnHandList: {
							read() {
								return undefined;
							},
						},
					},
				},
			},
		}),
		link: from([slowConnectionMeasurementLink, new RetryLink(), unauthorizedLink, httpLink]),
	});
}
