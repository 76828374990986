import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';

export interface BusinessPartnerGeneralPayerInformationDto extends BaseEntityDto {
	payerInformationFieldUuid: string;
}

/**
 * The class containing information about the business partner charge information model
 * @property {string} chargeInformationUuid The UUID of the charge information associated with this data
 */
export default class BusinessPartnerGeneralPayerInformation extends BaseEntity {
	payerInformationFieldUuid: string;

	constructor(
		entity: Partial<BusinessPartnerGeneralPayerInformation | BusinessPartnerGeneralPayerInformationDto> = {},
	) {
		entity = cloneDeep(entity);
		super(entity);

		this.payerInformationFieldUuid = entity.payerInformationFieldUuid || '';
	}
}
