import axios from 'axios';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from '../models/BaseMetadata';
import { DocumentActionValue } from '../models/DocAction';
import { configs } from '../utils/Configs';
import { PROCESS_URI } from '../utils/Constants';
import BaseEntityService from './BaseEntityService';

export default abstract class DocumentService<
	T extends BaseMetadata,
	S extends BaseMetadataDto = BaseMetadataDto,
	P extends BaseMetadataDB = BaseMetadataDB,
> extends BaseEntityService<T, S, P> {
	/**
	 * Process a given entity, such as completing it or voiding it
	 * @param uuid The uuid of the entity to process
	 * @param documentAction The process action to be executed
	 * @returns The entity after it's been processed
	 */
	async process(uuid: string, documentAction: DocumentActionValue) {
		return axios
			.post<S>(`${configs.apiUrl}/${this.entityName}/${uuid}/process/${documentAction}`)
			.then(({ data }) => this.map(data));
	}

	/**
	 * A sugar method to first save the entity to process, then process the entity
	 * @param data The entity to save and process
	 * @param documentAction The process action to be executed
	 * @returns The saved and processed entity
	 */
	async saveAndProcess(data: T, documentAction: DocumentActionValue) {
		return axios
			.post<S>(`${configs.apiUrl}/${this.entityName}${PROCESS_URI}/${documentAction}`, this.reverseMap(data))
			.then(({ data }) => this.map(data));
	}
}
