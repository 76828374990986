import { isObject, keys, omit } from 'lodash';
import { BaseMetadataDB } from './BaseMetadata';

type Operator<TEntity> = {
	[TEntityProperty in keyof TEntity]: {
		$eq: TEntity[TEntityProperty];
		$neq: TEntity[TEntityProperty];
		$gt: TEntity[TEntityProperty];
		$gte: TEntity[TEntityProperty];
		$lt: TEntity[TEntityProperty];
		$lte: TEntity[TEntityProperty];
		$in: TEntity[TEntityProperty][];
		$nin: TEntity[TEntityProperty][];
		$text: TEntity[TEntityProperty] extends string ? string : never;
		$ntext: TEntity[TEntityProperty] extends string ? string : never;
		$null: boolean;
		$nnull: boolean;
	};
};

type Query<TEntity> = {
	$and: Operator<TEntity>[];
	$not: Operator<TEntity>[];
	$or: Operator<TEntity>[];
	$nor: Operator<TEntity>[];
} & Operator<TEntity>;

/**
 * This base class has the methods to handle the comparisons for properties.
 * @template TEntity The entity this property is a comparison on
 * @template TNextCast The type this entity should be cast to after completing the comparison
 * @template TEntityProperty The specific property of the entity to handle type comparison checking
 */
abstract class Comparison<TEntity, TNextCast, TEntityProperty extends keyof TEntity = keyof TEntity> {
	protected nextPropertyNameToUse?: TEntityProperty;

	/**
	 * This is used to assign a comparison with a passed-in value. For whatever property we're working with, the comparison
	 * will be set. For example, if the private property `#nextPropertyNameToUse` is set to `name` and the comparison to use
	 * is `$eq`, the following will update this object to be:
	 * ```
	 * {
	 * 	...previous properties...
	 * 	name: {
	 * 		$eq: value
	 * 	}
	 * }
	 * ```
	 */
	protected assign<TEntityPropertyOperator extends keyof Operator<TEntity>[TEntityProperty]>(
		comparison: TEntityPropertyOperator,
		value: Operator<TEntity>[TEntityProperty][TEntityPropertyOperator],
	) {
		const operator = this as unknown as Operator<TEntity>;
		if (!this.nextPropertyNameToUse) {
			throw Error('call `property` before calling assignment');
		}
		operator[this.nextPropertyNameToUse] = operator[this.nextPropertyNameToUse] || {};
		operator[this.nextPropertyNameToUse][comparison] = value;
		this.nextPropertyNameToUse = undefined;
		return this as unknown as TNextCast;
	}

	equals(value: Operator<TEntity>[TEntityProperty]['$eq']) {
		return this.assign('$eq', value);
	}

	doesNotEqual(value: Operator<TEntity>[TEntityProperty]['$neq']) {
		return this.assign('$neq', value);
	}

	isGreaterThan(value: Operator<TEntity>[TEntityProperty]['$gt']) {
		return this.assign('$gt', value);
	}

	isGreaterThanOrEqualTo(value: Operator<TEntity>[TEntityProperty]['$gte']) {
		return this.assign('$gte', value);
	}

	isLessThan(value: Operator<TEntity>[TEntityProperty]['$lt']) {
		return this.assign('$lt', value);
	}

	isLessThanOrEqualTo(value: Operator<TEntity>[TEntityProperty]['$lte']) {
		return this.assign('$lte', value);
	}

	isIn(arrayOfValues: Operator<TEntity>[TEntityProperty]['$in']) {
		return this.assign('$in', arrayOfValues);
	}

	isNotIn(arrayOfValues: Operator<TEntity>[TEntityProperty]['$nin']) {
		return this.assign('$nin', arrayOfValues);
	}

	contains(text: Operator<TEntity>[TEntityProperty]['$text']) {
		return this.assign('$text', text);
	}

	doesNotContain(text: Operator<TEntity>[TEntityProperty]['$ntext']) {
		return this.assign('$ntext', text);
	}

	isNull() {
		return this.assign('$null', true);
	}

	isNotNull() {
		return this.assign('$nnull', true);
	}
}

/**
 * This class provides wrappers around creating a DB filter for filtering on the backend. When TypeScript is in place,
 * this object won't be needed.
 * @template TEntity The entity this filter is for.
 */
abstract class FullFilter<TEntity> extends Comparison<TEntity, FullFilter<TEntity>> {
	/**
	 * This is a protected method not meant to be used outside this class. It's used to assign an expression to a logical query selector.
	 * @param logicalQuerySelector One of `'$and'`, `'$not'`, `'$or'`, `'$nor'`
	 * @param expression The expression to use
	 */
	protected assignFilter(logicalQuerySelector: keyof Query<TEntity>, expression: Filter<TEntity>): this {
		const filter = this as unknown as Query<TEntity>;
		// Expression must be an object with keys
		if (isObject(expression) && keys(expression).length) {
			filter[logicalQuerySelector] = filter[logicalQuerySelector] || [];
			(filter[logicalQuerySelector] as any).push(expression as unknown as Operator<TEntity>);
		}
		return this;
	}

	and(expression: Filter<TEntity>) {
		return this.assignFilter('$and', expression);
	}

	not(expression: Filter<TEntity>) {
		return this.assignFilter('$not', expression);
	}

	or(expression: Filter<TEntity>) {
		return this.assignFilter('$or', expression);
	}

	nor(expression: Filter<TEntity>) {
		return this.assignFilter('$nor', expression);
	}

	/**
	 * Pick a property to do a filter on
	 * @param propertyToFilter The specific property we'll be filtering on
	 * @returns A comparison object to determine the type of the filter
	 */
	property<
		TEntityProperty extends {
			[TTempProperty in keyof TEntity]-?: TEntity[TTempProperty] extends BaseMetadataDB ? never : TTempProperty;
		}[keyof TEntity],
	>(propertyToFilter: TEntityProperty): Comparison<TEntity, this, TEntityProperty> {
		// If this was called more than once in a row, we're creating a nested object and we should return it
		if (this.nextPropertyNameToUse) {
			throw new Error('comparison incomplete');
		}
		this.nextPropertyNameToUse = propertyToFilter as unknown as TEntityProperty;
		return this as unknown as Comparison<TEntity, this, TEntityProperty>;
	}

	/**
	 * Created an nested filter to allow sub-filtering of an entity list
	 * @param propertyToNest The property we'll create a nested filter for (the type it refers to it must extend BaseMetadataDB)
	 * @returns Another filter object to do further property filtering
	 */
	nested<
		TEntityProperty extends {
			[TTempProperty in keyof TEntity]-?: TEntity[TTempProperty] extends BaseMetadataDB ? TTempProperty : never;
		}[keyof TEntity],
	>(
		propertyToNest: TEntityProperty,
	): Omit<
		SubTableFilter<TEntity, TEntityProperty, this>,
		keyof Comparison<TEntityProperty, SubTableFilter<TEntity, TEntityProperty, this>>
	> {
		const newSubTableFilter = new SubTableFilter<TEntity, TEntityProperty, this>(this) as unknown as Omit<
			SubTableFilter<TEntity, TEntityProperty, this>,
			keyof Comparison<TEntityProperty, SubTableFilter<TEntity, TEntityProperty, this>>
		>;
		this[propertyToNest as keyof this] = newSubTableFilter as any;
		return newSubTableFilter;
	}

	toJSON() {
		if (this.nextPropertyNameToUse) {
			throw new Error('comparison incomplete');
		}
		const self = this;
		return omit(self, 'parent');
	}

	toString() {
		return JSON.stringify(this.toJSON());
	}
}

/**
 * This is the actual class that will get instantiated. The classes it inherits from are to help with typing and casting.
 * @template TEntity The entity to filter on
 * @template TEntityProperty The specific property this filter will apply to
 * @template TParent An optional parent to return to if performing a sub-filter
 * @template TChild The child that will be filtered on
 */
class SubTableFilter<
	TEntity,
	TEntityProperty extends keyof TEntity,
	TParent = Filter<TEntity>,
	TChild = TEntity[TEntityProperty],
> extends FullFilter<TChild> {
	constructor(private parent?: TParent) {
		super();
	}

	/**
	 * An internal method to handle all the aggregation setup on the object.
	 * @param aggregateQuerySelector The type of aggregation to perform, such as $max, $sum, or $count
	 * @param propertyToAggregate The property to aggregate
	 * @param aggregateFilter A filter that can be used to filter the results prior to aggregation
	 * @returns A comparison to then handle the aggregation
	 */
	protected assignAggregate<TChildProperty extends keyof TChild>(
		aggregateQuerySelector: string,
		propertyToAggregate: TChildProperty,
		aggregateFilter: Filter<TChild>,
	) {
		this[`${aggregateQuerySelector}(${propertyToAggregate as string})` as unknown as keyof this] =
			aggregateFilter as any;
		this.nextPropertyNameToUse = propertyToAggregate as any;
		return this as unknown as Comparison<
			Pick<TChild, TChildProperty>,
			SubTableFilter<TEntity, TEntityProperty, TParent, Pick<TChild, TChildProperty>>
		>;
	}

	sumOf<TChildProperty extends keyof TChild>(
		propertyToSum: TChildProperty,
		filter: Filter<TChild> = {} as Filter<TChild>,
	): Comparison<
		Pick<TChild, TChildProperty>,
		SubTableFilter<TEntity, TEntityProperty, TParent, Pick<TChild, TChildProperty>>
	> {
		return this.assignAggregate('$sum', propertyToSum, filter);
	}

	countOf<TChildProperty extends keyof TChild>(
		propertyToCount: TChildProperty,
		filter: Filter<TChild> = {} as Filter<TChild>,
	) {
		return this.assignAggregate('$count', propertyToCount, filter);
	}

	maxOf<TChildProperty extends keyof TChild>(
		propertyToMax: TChildProperty,
		filter: Filter<TChild> = {} as Filter<TChild>,
	) {
		return this.assignAggregate('$max', propertyToMax, filter);
	}

	minOf<TChildProperty extends keyof TChild>(
		propertyToMin: TChildProperty,
		filter: Filter<TChild> = {} as Filter<TChild>,
	) {
		return this.assignAggregate('$min', propertyToMin, filter);
	}

	/**
	 * Called from within a sub-filter, this will return to the original table to continue working with the filter
	 * @returns The table filter that was mapped to from this one
	 */
	up() {
		if (!this.parent) {
			throw Error('no parent defined');
		}
		const parent = this.parent;
		delete this.parent;
		return parent;
	}

	toJSON() {
		if (this.parent) {
			throw new Error('comparison incomplete');
		}
		return super.toJSON();
	}

	toString() {
		if (this.parent) {
			throw new Error('comparison incomplete');
		}
		return super.toString();
	}
}

export type Filter<TEntity> = Omit<FullFilter<TEntity>, keyof Comparison<TEntity, FullFilter<TEntity>>>;

/**
 * Create a new filter of a given type to pass in a query
 * @returns A filter to work with
 */
const DBFilter = <TEntity>(): Filter<TEntity> =>
	new SubTableFilter<TEntity, keyof TEntity>() as unknown as Filter<TEntity>;

export default DBFilter;
