import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';

export interface FieldGroupDto extends BaseEntityDto {
	abbreviation?: string | null;
}

export interface FieldGroupDB extends BaseEntityDB {}

export default class FieldGroup extends BaseEntity {
	abbreviation?: string;

	constructor(props: Partial<FieldGroupDto | FieldGroup> = {}) {
		props = cloneDeep(props);
		super(props);

		this.abbreviation = props.abbreviation || undefined;
	}
}
