import React from 'react';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';

const ToSContent = () => {
	const { t } = useTranslation();
	return (
		<>
			<p className="card-text">
				<br />
				<b>{t(uiText.termsOfService.content.acceptance.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.acceptance.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.updates.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.updates.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.eligibility.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.eligibility.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.registration.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.registration.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.feesAndPayments.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.feesAndPayments.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.taxes.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.taxes.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.notHealthcareServices.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.notHealthcareServices.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.serviceDataAndAnalytics.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.serviceDataAndAnalytics.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.userRulesAndConduct.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.userRulesAndConduct.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.connections.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.connections.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.advertisingAndPromotions.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.advertisingAndPromotions.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.proprietaryRights.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.proprietaryRights.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.feedbackLicense.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.feedbackLicense.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.termination.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.termination.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.noWarranties.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.noWarranties.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.limitationOfLiability.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.limitationOfLiability.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.indemnification.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.indemnification.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.governingLawAndArbitration.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.governingLawAndArbitration.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.integration.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.integration.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.generalProvisions.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.generalProvisions.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.copyrightAndTrademarkNotices.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.copyrightAndTrademarkNotices.TEXT)}
				<br />
				<br />
			</p>
			<hr />
			<p>
				<b>{t(uiText.termsOfService.content.privacyPolicy.TITLE)}</b>
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.COMPANY)}</b>
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.ourCommitmentToPrivacy.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.ourCommitmentToPrivacy.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.acceptancePrivacyPolicyTerms.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.acceptancePrivacyPolicyTerms.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.links.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.links.TEXT)}
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.HEADER)}</b>
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.sectionA.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.sectionA.TEXT)}
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.sectionB.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.sectionB.TEXT)}
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.sectionC.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.theInformationWeCollect.sectionC.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.howWeUsePersonalInformation.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.howWeUsePersonalInformation.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.optingOutOrUnsubscribing.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.optingOutOrUnsubscribing.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.ourCommitmentToDataSecurity.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.ourCommitmentToDataSecurity.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.amendments.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.amendments.TEXT)}
				<br />
				<br />
				<b>{t(uiText.termsOfService.content.privacyPolicy.howToContactUs.HEADER)}</b>
				<br />
				{t(uiText.termsOfService.content.privacyPolicy.howToContactUs.TEXT)}
			</p>
		</>
	);
};

export default ToSContent;
