import { AttributeSet, AttributeSetDB, AttributeSetDto, DBFilter } from '../models';
import BaseEntityService from './BaseEntityService';

export default class AttributeSetService extends BaseEntityService<AttributeSet, AttributeSetDto, AttributeSetDB> {
	protected entityName: string = 'attribute-sets';
	public entityTableName: string = 'm_attributeset';

	public async get() {
		return super.get(undefined, undefined, undefined, DBFilter<AttributeSetDB>().property('isactive').equals(true));
	}

	map(data: AttributeSetDto): AttributeSet {
		return new AttributeSet(data);
	}

	reverseMap(data: AttributeSet): AttributeSetDto {
		throw new Error('Method not implemented.');
	}
}
