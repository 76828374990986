import { useApolloClient } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import UserContext from '../../contexts/UserContext';
import {
	Ad_UserCreatorsForExpensesDocument,
	C_InvoiceForExpensesDocument,
	C_InvoiceForExpensesQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useGraphQLListPageFunctionality from '../../hooks/useGraphQLListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useStateWithReset from '../../hooks/useStateWithReset';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import { DBFilter, DocumentStatus, InvoiceDB, ListPageState, UserDB } from '../../models';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { SEARCH } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHGraphQLTable from '../BHTable/BHGraphQLTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import GraphQLStatusBadge from '../StatusBadge/GraphQLStatusBadge';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import ExpenseForm from './ExpenseForm';

/**
 * Main  Expenses page.
 */
const CLINICAL_ADMIN_ROLE = 'Clinic Admin';

const Expenses = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { willTrigger, triggerUpdate: updateUsers } = useTriggerUpdate();

	const { disableWrite } = useActionPrivileges(pageUuid.EXPENSES);
	const [searchText, setSearchText] = useState('');
	const { role, user } = useContext(UserContext);
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useGraphQLListPageFunctionality<C_InvoiceForExpensesQuery['C_InvoiceGet']['Results'][0]>({
		fetch: useCallback(
			async (variables) =>
				(await graphqlClient.query({ query: C_InvoiceForExpensesDocument, variables, fetchPolicy: 'network-only' }))
					.data.C_InvoiceGet,
			[graphqlClient],
		),
		onError: useCallback(
			(error) => {
				if (error.response) {
					toast.error(t(uiText.expense.error.COULD_NOT_LOAD));
				}
				exception({ description: `Expense fetch error: ${error}` });
			},
			[t],
		),
		refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
	});
	const displayCreatedByFilterAllOption = useMemo(() => role.name.includes(CLINICAL_ADMIN_ROLE), [role]);

	// If the user isn't an admin role, the default created by filter should be them
	const [createdByFilter, setCreatedByFilter, { reset: resetCreatedByFilter }] = useStateWithReset(
		displayCreatedByFilterAllOption ? '' : user.uuid,
	);
	let { value: createdByList = [] } = useAsync(async () => {
		// If we're not displaying the "ALL" option, the user should only be able to select themselves (and no others)
		if (!displayCreatedByFilterAllOption) {
			return [{ UU: user.uuid, Name: user.name }];
		}
		const userFilter = DBFilter<UserDB>()
			.nested('c_invoice::ad_user_id->createdby')
			.property('issotrx')
			.equals(false)
			.property('bh_visit_id')
			.isNull()
			.up();
		const createdByUsers = (
			await graphqlClient.query({
				query: Ad_UserCreatorsForExpensesDocument,
				variables: { Page: SEARCH.DEFAULT_PAGE_NUMBER, Size: SEARCH.DEFAULT_PAGE_SIZE, Filter: userFilter.toString() },
				fetchPolicy: 'network-only',
			})
		).data.AD_UserGet.Results;
		// If the current user isn't there, add them
		if (!createdByUsers.some((createdByUser) => createdByUser.UU === user.uuid)) {
			createdByUsers.push({ UU: user.uuid, Name: user.name });
		}
		return createdByUsers;
	}, [willTrigger, displayCreatedByFilterAllOption, user, graphqlClient]);

	// Handle searching and filtering
	useEffect(() => {
		const filter = DBFilter<InvoiceDB>().property('issotrx').equals(false).property('bh_visit_id').isNull();
		if (createdByFilter) {
			filter.nested('ad_user::createdby->ad_user_id').property('ad_user_uu').equals(createdByFilter).up();
		}

		if (searchText) {
			filter.and(
				DBFilter<InvoiceDB>()
					.and(DBFilter<InvoiceDB>().property('docStatus').doesNotEqual(DocumentStatus.REVERSED))
					.and(DBFilter<InvoiceDB>().property('C_BPartner.name').contains(searchText)),
			);
		}
		onFilterUpdate(filter.toString());
	}, [searchText, onFilterUpdate, createdByFilter, user.uuid, role]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetCreatedByFilter();
		reset(true);
	});

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.expense.pageDisplay.LIST)}
							showRefreshIcon
							onRefresh={() => {
								updateUsers();
								refresh({ resetPage: true });
							}}
							areRefreshing={areRefreshing}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="expensesCreatedBy">
									<Form.Label column>{t(uiText.expense.CREATED_BY)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={createdByFilter}
										onChange={(e) => setCreatedByFilter(e.target.value)}
									>
										{displayCreatedByFilterAllOption && <option value={''}>{t(uiText.expense.filter.ALL)}</option>}
										{createdByList.map((user) => (
											<option key={user.UU} value={user.UU}>
												{user.Name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							<BHGraphQLTable<C_InvoiceForExpensesQuery['C_InvoiceGet']['Results'][0]>
								data={data}
								columns={[
									{
										id: 'dateInvoiced',
										Header: () => <div className={'React-table-header'}>{t(uiText.expense.columnHeader.DATE)}</div>,
										accessor: (d) => formatDate(new Date(d.DateInvoiced)),
									},
									{
										id: 'C_BPartner.name',
										Header: () => <div className={'React-table-header'}>{t(uiText.expense.columnHeader.SUPPLIER)}</div>,
										accessor: (d) => d.C_BPartner.Name,
									},
									{
										id: 'grandTotal',
										Header: () => <div className={'React-table-header'}>{t(uiText.expense.columnHeader.TOTAL)}</div>,
										accessor: (d) => <div className="d-flex justify-content-center">{formatNumber(d.GrandTotal)}</div>,
									},
									{
										id: 'docStatus',
										Header: () => <div className={'React-table-header'}>{t(uiText.expense.columnHeader.STATUS)}</div>,
										accessor: (d) => <GraphQLStatusBadge documentStatus={d.DocStatus} />,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return <LoadSpinner show={isLoading} title={t(uiText.expense.LOADING)} />;
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowProperties}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<ExpenseForm
					uuid={selectedUuid}
					onFinish={(shouldRefresh?: boolean) => {
						setViewState(ListPageState.LIST);
						if (shouldRefresh) {
							refresh();
							updateUsers();
						}
					}}
				/>
			)}
		</Layout>
	);
};

export default Expenses;
