import axios from 'axios';
import { isArray } from 'lodash';
import { Response } from '../models';
import Menu, { MenuDto } from '../models/Menu';
import { configs } from '../utils/Configs';
import NewMetadataService from './NewMetadataService';

export default class MenuService extends NewMetadataService<Menu, MenuDto> {
	getUrl = `${configs.apiUrl}/menus`;
	localStorageKey = 'menus';

	map(data: MenuDto) {
		return new Menu(data);
	}

	reverseMap(data: Menu): MenuDto {
		throw new Error('Method not implemented.');
	}

	/**
	 * Get the data stored locally (may be an empty array if not initialized yet)
	 */
	getLocal(localStorageKey: string = this.localStorageKey): Menu[] {
		let metaData = JSON.parse(localStorage.getItem(localStorageKey) || '[]');
		if (metaData && isArray(metaData) && metaData.length) {
			return metaData.map(this.map);
		}
		return [];
	}

	/**
	 * Perform a request to the specified URL with paging, sizing, and sorting
	 * @param useCache Whether the cache should be used, if available (defaults to true)
	 */
	async getByRootId(rootId: string, useCache?: boolean) {
		useCache = useCache === undefined ? true : useCache;
		const storageKey = this.localStorageKey + '_' + rootId;
		let metaData = this.getLocal(storageKey);
		if (metaData.length && useCache) {
			return metaData;
		}
		metaData = await axios.get<Response<MenuDto>>(`${this.getUrl}/root/${rootId}`).then(({ data }) => {
			if (isArray(data.results)) {
				return data.results.map(this.map);
			}
			return [];
		});
		localStorage.setItem(storageKey, JSON.stringify(metaData));
		return metaData;
	}
}
