import { cloneDeep } from 'lodash';
import { DocumentStatus, DocumentStatusValue } from '.';
import BaseEntity, { BaseEntityDto } from './BaseEntity';
import InventoryLine, { InventoryLineDto } from './InventoryLine';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import Warehouse, { WarehouseDto } from './Warehouse';

export interface InventoryDto extends BaseEntityDto {
	warehouse?: WarehouseDto;
	updateReason?: ReferenceListDto;
	inventoryLines?: InventoryLineDto[];
	documentStatus?: string | null;
	movementDate?: number;
}

export default class Inventory extends BaseEntity {
	warehouse: Warehouse;
	updateReason: ReferenceList;
	inventoryLines: InventoryLine[];
	documentStatus: DocumentStatusValue;
	movementDate?: number;

	constructor(props?: Partial<Inventory | InventoryDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.warehouse = new Warehouse(props.warehouse);
		this.updateReason = new ReferenceList(props.updateReason);
		this.inventoryLines = (props.inventoryLines || []).map((inventoryLine) => new InventoryLine(inventoryLine));
		if ('documentStatus' in props) {
			this.documentStatus = (props.documentStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
		} else {
			this.documentStatus = DocumentStatus.DRAFTED;
		}
		this.movementDate = props.movementDate;
	}
}
