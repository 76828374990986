import { useApolloClient } from '@apollo/client';
import { endOfDay } from 'date-fns';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { v4 } from 'uuid';
import UserContext from '../../contexts/UserContext';
import {
	C_DocTypeForFormsDocument,
	M_MovementDeleteDocument,
	M_MovementForEditingDocument,
	M_MovementForEditingQuery,
	M_MovementSaveWithMovementLinesAndProcessDocument,
	M_MovementSaveWithMovementLinesDocument,
	M_MovementSaveWithMovementLinesMutationVariables,
	M_WarehouseForMovementFormDocument,
	M_WarehousesForMovementsPageDocument,
	M_WarehousesForMovementsPageQuery,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import { DocAction, documentBaseType, Warehouse } from '../../models';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { getWarehouseByOrg } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { isEntityCompleted, isEntityDrafted, isEntityVoided } from '../../utils/StatusUtil';
import BasicButton from '../ActionButtons/BasicButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import { withFormModalSuspsenseWrapper } from '../HOCs/withFormModalSuspsenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ProductLineTransferInventoryTable, {
	constructMovementLinesSubmissionObject,
	convertToFormMovementLineFields,
	MovementFormMovementLineFields,
} from './ProductLineTransferInventoryTable';

export type TransferInventoryFormProps = EntityFormProperties;

export type MovementFormFields = {
	UU: string;
	MovementDate: Date;
	BH_From_Warehouse: {
		UU: string;
	};
	BH_To_Warehouse: {
		UU: string;
	};
	submitEvent: 'complete' | '' | 'save';
} & MovementFormMovementLineFields;

const fetchMaterialMovementDocumentTypeArguments = [
	documentBaseType.MaterialMovement,
	null,
	null,
	false,
	false,
	false,
] as const;
const getTitle = (uuid?: string) => (uuid ? uiText.transferInventory.title.UPDATE : uiText.transferInventory.title.NEW);
const convertToFormFields = (
	selectedWarehouse: Warehouse,
	warehouses: M_WarehousesForMovementsPageQuery['M_WarehouseGet']['Results'],
	initialData?: M_MovementForEditingQuery['M_Movement'],
): MovementFormFields => {
	if (!initialData) {
		const fromWarehouse = warehouses.find((warehouse) => warehouse.UU === selectedWarehouse.uuid);
		const otherWarehouses = warehouses.filter((warehouse) => warehouse.UU !== fromWarehouse?.UU);
		const toWarehouse = otherWarehouses.length === 1 ? otherWarehouses[0] : undefined;
		return {
			UU: v4(),
			MovementDate: new Date(),
			BH_From_Warehouse: { UU: fromWarehouse?.UU || '' },
			BH_To_Warehouse: { UU: toWarehouse?.UU || '' },
			submitEvent: '',
			...convertToFormMovementLineFields(initialData),
		};
	}
	const fromWarehouse = warehouses.find(
		(warehouse) => warehouse.UU === (initialData.BH_From_Warehouse?.UU || selectedWarehouse.uuid),
	);
	const otherWarehouses = warehouses.filter((warehouse) => warehouse.UU !== fromWarehouse?.UU);
	const toWarehouse = initialData.BH_To_Warehouse?.UU
		? warehouses.find((warehouse) => warehouse.UU === initialData.BH_To_Warehouse?.UU)
		: otherWarehouses.length === 1
			? otherWarehouses[0]
			: undefined;
	return {
		UU: initialData.UU,
		MovementDate: new Date(initialData.MovementDate),
		BH_From_Warehouse: { UU: fromWarehouse?.UU || '' },
		BH_To_Warehouse: { UU: toWarehouse?.UU || '' },
		submitEvent: '',
		...convertToFormMovementLineFields(initialData),
	};
};

const TransferInventoryForm = ({ uuid, onFinish, renderAsModal, canSaveMany }: TransferInventoryFormProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { organization, warehouse } = useContext(UserContext);

	const { data: [initialData, warehouses, materialMovementDocumentType] = [] } = useSuspenseAsync(
		uuid || 'inventory-data-load',
		async () =>
			await Promise.all([
				uuid
					? graphqlClient
							.query({
								query: M_MovementForEditingDocument,
								variables: { UU: uuid },
								fetchPolicy: 'network-only',
							})
							.then((response) => response.data.M_Movement)
					: undefined,
				graphqlClient
					.query({
						query: M_WarehousesForMovementsPageDocument,
						variables: { Filter: getWarehouseByOrg(organization.uuid).toString() },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.M_WarehouseGet.Results),
				graphqlClient
					.query({
						query: C_DocTypeForFormsDocument,
						variables: {
							Filter: getDocumentBaseTypeFilter(...fetchMaterialMovementDocumentTypeArguments).toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_DocTypeGet.Results[0]),
			]),
	);
	const [data] = useState(initialData);
	const title = getTitle(data ? data.UU : undefined);

	const formMethods = useForm<MovementFormFields>({
		defaultValues: convertToFormFields(warehouse, warehouses!, data),
	});
	const isDataReadOnly = isEntityCompleted(data) || isEntityVoided(data);
	const { disableWrite } = useActionPrivileges(pageUuid.TRANSFER_INVENTORY);

	useConfirmRefresh(formMethods.formState?.isDirty);

	const setValue = formMethods.setValue;
	const getValues = formMethods.getValues;
	const currentFromWarehouseUuid = data?.BH_From_Warehouse?.UU;
	const isNew = !data;
	useEffect(() => {
		// only set a default for new entries
		if (isNew && !currentFromWarehouseUuid && warehouse && getValues('BH_From_Warehouse.UU') !== warehouse.uuid) {
			setValue('BH_From_Warehouse.UU', warehouse.uuid);
		}
	}, [isNew, currentFromWarehouseUuid, setValue, warehouse, warehouses, getValues]);

	// Any time the from warehouse uuid changes, update the to warehouse (if need be)
	const fromWarehouseUuid = formMethods.watch('BH_From_Warehouse.UU');
	const toWarehouseUuid = formMethods.watch('BH_To_Warehouse.UU');
	useEffect(() => {
		if (!fromWarehouseUuid || !warehouses) {
			return;
		}
		// Get the value to set the to warehouse
		const filteredWarehouses = warehouses.filter((warehouse) => warehouse.UU !== fromWarehouseUuid);
		if (filteredWarehouses.length === 1 && getValues('BH_To_Warehouse.UU') !== filteredWarehouses[0].UU) {
			setValue('BH_To_Warehouse.UU', filteredWarehouses[0].UU);
		} else if (toWarehouseUuid === fromWarehouseUuid) {
			setValue('BH_From_Warehouse.UU', '');
		}
	}, [fromWarehouseUuid, setValue, warehouses, toWarehouseUuid, getValues]);

	const [{ loading }, onSubmit] = useAsyncFn<SubmitHandler<MovementFormFields>>(
		async (formData) => {
			const formAction = formData.submitEvent;

			const movement: M_MovementSaveWithMovementLinesMutationVariables['M_Movement'] = {
				UU: formData.UU,
				BH_From_Warehouse: formData.BH_From_Warehouse?.UU ? { UU: formData.BH_From_Warehouse.UU } : undefined,
				BH_To_Warehouse: formData.BH_To_Warehouse?.UU ? { UU: formData.BH_To_Warehouse.UU } : undefined,
				C_DocType: { UU: materialMovementDocumentType?.UU! },
				MovementDate: formData.MovementDate.getTime(),
			};

			// Cycle through and set the correct locator ids based on the warehouse
			const [fromWarehouse, toWarehouse] = await Promise.all([
				graphqlClient
					.query({
						query: M_WarehouseForMovementFormDocument,
						variables: { UU: formData.BH_From_Warehouse.UU },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.M_Warehouse),
				graphqlClient
					.query({
						query: M_WarehouseForMovementFormDocument,
						variables: { UU: formData.BH_To_Warehouse.UU },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.M_Warehouse),
			]);
			const activeFromLocators = fromWarehouse?.M_Locators?.filter((locator) => locator.IsActive);
			const fromLocatorUU = activeFromLocators?.find((locator) => locator.IsDefault)?.UU || activeFromLocators?.[0]?.UU;
			const activeToLocators = toWarehouse?.M_Locators?.filter((locator) => locator.IsActive);
			const toLocatorUU = activeToLocators?.find((locator) => locator.IsDefault)?.UU || activeToLocators?.[0]?.UU;
			const movementLines = constructMovementLinesSubmissionObject(formData, movement.UU!, fromLocatorUU, toLocatorUU);

			// check save/ complete operation
			try {
				if (formAction === 'complete') {
					await graphqlClient.mutate({
						mutation: M_MovementSaveWithMovementLinesAndProcessDocument,
						variables: {
							M_Movement: movement,
							M_MovementLines: movementLines,
							UU: movement.UU!,
							DocumentAction: DocAction.COMPLETE,
						},
					});
				} else {
					await graphqlClient.mutate({
						mutation: M_MovementSaveWithMovementLinesDocument,
						variables: {
							M_Movement: movement,
							M_MovementLines: movementLines,
						},
					});
				}

				toast.success(t(uiText.transferInventory.success.UPDATE));
				onFinish(true, canSaveMany === false ? movement.UU! : undefined);
			} catch (error: any) {
				exception({ description: `Inventory Transfer save error: ${error}` });
				toast.error(t(uiText.transferInventory.error.COULD_NOT_SAVE, { error }));
			}
		},
		[graphqlClient, materialMovementDocumentType],
	);

	const onDelete = async (uuid: string) => {
		try {
			await graphqlClient.mutate({ mutation: M_MovementDeleteDocument, variables: { UUs: [uuid] } });
			onFinish(true);
		} catch (error) {
			exception({ description: `Inventory Transfer delete error: ${error}` });
			toast.error(t(uiText.transferInventory.error.COULD_NOT_DELETE));
		}
	};

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(onSubmit)} className="px-0" hidden={loading}>
				<fieldset disabled={disableWrite || isDataReadOnly}>
					<Card className="bh-card">
						<Card.Body>
							<Row className="gy-3">
								<Form.Group as={Fragment} controlId="MovementDate">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.transferInventory.labels.DATE)}</Form.Label>
									</Col>
									<Col xs={8} className="d-flex align-items-center">
										<Controller<MovementFormFields, 'MovementDate'>
											name="MovementDate"
											render={({ field }) => (
												<BandaDatePicker
													maxDate={endOfDay(new Date())}
													{...field}
													selected={field.value}
													value={undefined}
												/>
											)}
										/>
										{formMethods.formState.errors.MovementDate && (
											<span className="text-danger">{t(uiText.transferInventory.error.MISSING_MOVEMENT_DATE)}</span>
										)}
									</Col>
								</Form.Group>
								<Col xs={3} />

								<Form.Group as={Fragment} controlId="BH_From_Warehouse">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.transferInventory.labels.FROM_STOREROOM)}</Form.Label>
									</Col>
									<Col xs={5} className="d-flex align-items-center">
										<Form.Select
											{...formMethods.register('BH_From_Warehouse.UU', {
												required: true,
												pattern: /[0-9]{1,5}[A-Za-z]/,
											})}
										>
											{warehouses?.map((warehouse) => (
												<option key={warehouse.UU} value={warehouse.UU}>
													{warehouse.Name}
												</option>
											))}
										</Form.Select>
										{formMethods.formState.errors.BH_From_Warehouse?.UU && (
											<span className="text-danger">{t(uiText.transferInventory.error.MISSING_FROM_STOREROOM)}</span>
										)}
									</Col>
								</Form.Group>
								<Form.Group as={Fragment} controlId="toStoreroom">
									<Col xs={1} className="d-flex align-items-center">
										<Form.Label column>{t(uiText.transferInventory.labels.TO_STOREROOM)}</Form.Label>
									</Col>
									<Col xs={5} className="d-flex align-items-center">
										<Form.Select
											{...formMethods.register('BH_To_Warehouse.UU', {
												required: true,
												pattern: /[0-9]{1,5}[A-Za-z]/,
											})}
											aria-label={t(uiText.transferInventory.labels.TO_STOREROOM)}
										>
											{warehouses
												?.filter((warehouse) => warehouse.UU !== fromWarehouseUuid)
												.map((warehouse) => (
													<option key={warehouse.UU} value={warehouse.UU}>
														{warehouse.Name}
													</option>
												))}
										</Form.Select>

										{formMethods.formState.errors.BH_To_Warehouse?.UU && (
											<span className="text-danger">{t(uiText.transferInventory.error.MISSING_TO_STOREROOM)}</span>
										)}
									</Col>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>

					<Card className="bh-card">
						<Card.Header className="fw-bold h5">{t(uiText.receiveProduct.PRODUCTS)}</Card.Header>
						<Card.Body>
							<ProductLineTransferInventoryTable readOnly={isDataReadOnly} />
						</Card.Body>
					</Card>
				</fieldset>
				<input type="hidden" {...formMethods.register('submitEvent')} defaultValue={''} />
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.transferInventory.button.BACK}
						text={t(uiText.transferInventory.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => onFinish()}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto" className="me-auto">
						<Button type="button" name="cancel" variant="danger" onClick={() => onFinish()}>
							{disableWrite ? t(uiText.transferInventory.button.BACK) : t(uiText.transferInventory.button.CANCEL)}
						</Button>
					</Col>
					{!disableWrite && (isNew || isEntityDrafted(data)) ? (
						<>
							<Col xs="auto">
								<Button
									type="submit"
									variant="primary"
									onClick={() => {
										formMethods.setValue('submitEvent', 'save');
										formMethods.handleSubmit(onSubmit)();
									}}
								>
									{t(uiText.transferInventory.button.SAVE)}
								</Button>
							</Col>
							<Col xs="auto">
								<Button
									type="submit"
									variant="success"
									onClick={() => {
										formMethods.setValue('submitEvent', 'complete');
										formMethods.handleSubmit(onSubmit)();
									}}
								>
									{t(uiText.transferInventory.button.COMPLETE)}
								</Button>
							</Col>
						</>
					) : null}
					{!disableWrite && data && isEntityDrafted(data) && !isNew ? (
						<Col xs="auto">
							<Button
								type="button"
								name="delete"
								variant="danger"
								onClick={(e) => {
									e.preventDefault();
									onDelete(data.UU);
								}}
							>
								{t(uiText.transferInventory.button.DELETE)}
							</Button>
						</Col>
					) : null}
				</>
			)}
		</Row>
	);

	return (
		<>
			{renderAsModal ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{t(title)}</Modal.Title>
					</Modal.Header>
					<Modal.Body className={`${loading ? '' : 'pt-0'}`}>
						{loading && <LoadSpinner inline title={t(uiText.visit.PROCESSING)} />}
						{inputs}
					</Modal.Body>
					{!loading ? (
						<Modal.Footer>
							<div className="w-100">{buttons}</div>
						</Modal.Footer>
					) : null}
				</>
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(title)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						{loading && <LoadSpinner title={t(uiText.visit.PROCESSING)} />}
						<div hidden={loading} className="bg-white pb-0_5 ms-2_5">
							{inputs}
							{buttons}
						</div>
					</Layout.Body>
				</>
			)}
		</>
	);
};

export default withFormModalSuspsenseWrapper<TransferInventoryFormProps>({
	loadingLabel: uiText.transferInventory.LOADING,
	getTitle,
})(TransferInventoryForm);
