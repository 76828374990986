import Service, { ServiceDB, ServiceDto } from '../models/Service';
import BaseEntityService from './BaseEntityService';

export default class ServiceService extends BaseEntityService<Service, ServiceDto, ServiceDB> {
	public entityTableName: string = 'm_product';
	entityName = 'bh-services';

	map(data: ServiceDto): Service {
		return new Service(data);
	}

	reverseMap(data: Service): ServiceDto {
		return { ...data, sellingPrice: data.sellingPrice || 0 };
	}
}
