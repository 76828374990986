import { endOfDay } from 'date-fns';
import { isArray } from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsync } from 'react-use';
import useService from '../../hooks/useService';
import BaseEntity from '../../models/BaseEntity';
import { DATE, DATETIME, REFERENCE_UUID_TABLE_DIRECT } from '../../models/Reference';
import BaseEntityService from '../../services/BaseEntityService';
import { exception } from '../../utils/analytics';
import { formatDate, formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookup from '../entity-lookup/EntityLookup';
import { SelectedReportParameter } from './Report';

type ReportFieldProps = {
	parameterWithSelectedValue: SelectedReportParameter;
	onParameterChange: (parameterUuid: string, selectedValue: string) => void;
	formErrors: any;
};

const ReportField = ({ parameterWithSelectedValue, onParameterChange, formErrors }: ReportFieldProps) => {
	const { t } = useTranslation();
	const services = useService();
	const formMethods = useForm<FieldValues>();

	const [query, setQuery] = useState('');
	const entityService = useMemo<BaseEntityService<BaseEntity> | undefined>(
		() =>
			(
				Object.values(services).filter(
					(service) => service instanceof BaseEntityService,
				) as BaseEntityService<BaseEntity>[]
			).find(
				(service) =>
					service.entityTableName.toLowerCase() + '_uu' === parameterWithSelectedValue.columnName?.toLowerCase(),
			),
		[parameterWithSelectedValue, services],
	);
	const { value: entities = [], loading } = useAsync(
		() =>
			(entityService &&
				query &&
				entityService
					.get(undefined, undefined, undefined, entityService.getDefaultSearchFilter(query))
					.then(({ results }) => results)) ||
			Promise.resolve([]),
		[entityService, query],
	);

	const entityUuid = formMethods.watch(`${parameterWithSelectedValue.uuid}.entity.uuid`) || '';
	const selectedParameterUuid = parameterWithSelectedValue.uuid;
	useEffect(
		() => entityService && onParameterChange(selectedParameterUuid, entityUuid),
		[entityService, entityUuid, onParameterChange, selectedParameterUuid, formErrors],
	);

	const errorsExists = (value: string): boolean => {
		let index = formErrors.findIndex((e: string) => e === value);
		if (index !== -1) {
			return true;
		}
		return false;
	};

	return (
		<FormProvider {...formMethods}>
			<Form.Group as={Fragment} controlId={parameterWithSelectedValue.uuid}>
				<Col xs={2} className="d-flex align-items-center">
					<Form.Label column>{parameterWithSelectedValue.name}</Form.Label>
				</Col>
				<Col xs={7} className="d-flex align-items-center">
					{parameterWithSelectedValue.reference.uuid === DATE ? (
						<BandaDatePicker
							key={parameterWithSelectedValue.uuid}
							onChange={(date: Date | null) =>
								date && date instanceof Date
									? onParameterChange(parameterWithSelectedValue.uuid, formatDate(date))
									: null
							}
							selected={
								(parameterWithSelectedValue.selectedValue && new Date(parameterWithSelectedValue.selectedValue)) ||
								undefined
							}
						/>
					) : parameterWithSelectedValue.reference.uuid === DATETIME ? (
						<BandaDatePicker
							key={parameterWithSelectedValue.uuid}
							dateFormat="yyyy-MM-dd HH:mm"
							timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
							showTimeInput
							onChange={(date: Date | null) =>
								date && date instanceof Date
									? onParameterChange(parameterWithSelectedValue.uuid, formatDateAndTime(date))
									: null
							}
							selected={
								(parameterWithSelectedValue.selectedValue && new Date(parameterWithSelectedValue.selectedValue)) ||
								undefined
							}
							maxDate={endOfDay(new Date())}
						/>
					) : parameterWithSelectedValue.reference.uuid === REFERENCE_UUID_TABLE_DIRECT ? (
						(() => {
							if (!entityService) {
								exception({
									description: `Report Field fetch error - no data fetcher defined for ${parameterWithSelectedValue.name}`,
								});
								toast.error(t(uiText.report.NO_DATA_FETCHER_DEFINED, { entityName: parameterWithSelectedValue.name }));
								return <></>;
							}

							// Return an entity lookup with the right stuff
							return (
								<EntityLookup<BaseEntity>
									name={`${parameterWithSelectedValue.uuid}.entity`}
									id={parameterWithSelectedValue.uuid}
									inputProps={{ id: parameterWithSelectedValue.uuid }}
									emptyLabel={t(uiText.report.NOTHING_FOUND)}
									labelKey={(entity) => entity.name}
									placeholder={t(uiText.report.PLACEHOLDER)}
									promptText={t(uiText.report.SEARCHING)}
									searchText={t(uiText.report.SEARCHING)}
									options={entities}
									onSearch={(query) => setQuery(query)}
									isLoading={loading}
									className="w-100"
								/>
							);
						})()
					) : isArray(parameterWithSelectedValue.referenceValues) &&
					  parameterWithSelectedValue.referenceValues.length ? (
						<Form.Select
							name={parameterWithSelectedValue.name}
							key={parameterWithSelectedValue.uuid}
							value={parameterWithSelectedValue.selectedValue || ''}
							onChange={(e) => onParameterChange(parameterWithSelectedValue.uuid, e.target.value)}
						>
							<option>{t(uiText.report.ALL)}</option>
							{parameterWithSelectedValue.referenceValues.map((referenceValue) => (
								<option key={referenceValue.uuid} value={referenceValue.value}>
									{referenceValue.name}
								</option>
							))}
						</Form.Select>
					) : (
						<Form.Control
							name={parameterWithSelectedValue.name}
							key={parameterWithSelectedValue.uuid}
							value={parameterWithSelectedValue.selectedValue || ''}
							onChange={(e) => onParameterChange(parameterWithSelectedValue.uuid, e.target.value)}
						/>
					)}
					{errorsExists(parameterWithSelectedValue.name) ? (
						<p className="text-danger">{t(uiText.error.FIELD_REQUIRED)}</p>
					) : (
						''
					)}
				</Col>
			</Form.Group>
			<Col xs={3} />
		</FormProvider>
	);
};

export default ReportField;
