import React, { Suspense, useContext, useRef, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import EntityFormProperties from '../../types/EntityFormProperties';
import { uiText } from '../../utils/Language';
import CustomPrompt from '../CustomPrompt/CustomPrompt';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

export function withFormModalSuspsenseWrapper<T extends EntityFormProperties = EntityFormProperties>({
	loadingLabel,
	getTitle,
	size = 'xl',
}: {
	loadingLabel: string;
	getTitle: (uuid?: string) => string;
	size?: ModalProps['size'];
}) {
	return (WrappedComponent: React.ComponentType<T>) => {
		const FormComponentWithModalSuspense = (props: T) => {
			const { t } = useTranslation();
			const navigationBlockingContext = useContext(NavBlockingContext);
			const [savedUuid, setSavedUuid] = useState<string | undefined>();
			const [wasDataSaved, setWasDataSaved] = useState(false);
			// ! TODO - remove this when we can update the visit form to not be finicky
			const visitFormInformation = useRef({ wasDataSaved: false, savedUuid: undefined });
			return (
				<FormModalContext.Provider
					value={{
						dataWasSaved: (uuid) => {
							setWasDataSaved(true);
							if (props.canSaveMany !== false) {
								setSavedUuid(uuid);
							}
						},
						wasDataSaved,
						savedData: savedUuid,
						visitFormInformation,
					}}
				>
					{props.renderAsModal ? (
						<Modal
							show
							size={size}
							onHide={async () => {
								if (navigationBlockingContext?.isBlocking) {
									const userWantsToProceed = await CustomPrompt(t(uiText.notificationContainer.prompt.PROMPT_MESSAGE));
									if (!userWantsToProceed) {
										return;
									}
								}
								visitFormInformation.current.wasDataSaved || wasDataSaved
									? props.onFinish(true, visitFormInformation.current.savedUuid || savedUuid)
									: props.onFinish(false);
							}}
							scrollable
						>
							<Suspense
								fallback={
									<>
										<Modal.Header>
											<Modal.Title>{t(getTitle(props.uuid))}</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<LoadSpinner inline title={t(loadingLabel)} />
										</Modal.Body>
									</>
								}
							>
								<WrappedComponent {...props} />
							</Suspense>
						</Modal>
					) : (
						<Suspense
							fallback={
								<>
									<LoadSpinner title={t(loadingLabel)} />
									<Layout.Header>
										<Layout.Title title={t(getTitle(props.uuid))} />
										<Layout.Menu />
									</Layout.Header>
								</>
							}
						>
							<WrappedComponent {...props} />
						</Suspense>
					)}
				</FormModalContext.Provider>
			);
		};

		return FormComponentWithModalSuspense;
	};
}
