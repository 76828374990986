import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import { CodedDiagnosis, CodedDiagnosisDto } from './index';

export interface EncounterDiagnosisDB extends BaseMetadataDB {}

export interface EncounterDiagnosisDto extends BaseMetadataDto {
	codedDiagnosis?: CodedDiagnosisDto | null;
	uncodedDiagnosis?: string | null;
	lineNo?: number | null;
}

export default class EncounterDiagnosis extends BaseMetadata {
	codedDiagnosis?: CodedDiagnosis;
	uncodedDiagnosis?: string;
	lineNo: number;

	constructor(props: Partial<EncounterDiagnosisDto | EncounterDiagnosis> = {}) {
		props = cloneDeep(props);
		super(props);

		this.codedDiagnosis = props.codedDiagnosis ? new CodedDiagnosis(props.codedDiagnosis) : undefined;
		this.uncodedDiagnosis = props.uncodedDiagnosis || undefined;
		this.lineNo = props.lineNo || 0;
	}
}
