import axios from 'axios';
import AuthorizationResponse from '../models/AuthorizationResponse';
import ChangeAccess from '../models/ChangeAccess';
import { configs } from '../utils/Configs';
import { LOGIN_URI } from '../utils/Constants';
import BaseService from './BaseService';

export type PageUuid = (typeof pageUuid)[keyof typeof pageUuid];

export const pageUuid = {
	CHIEF_COMPLAINT: 'ee3189d3-9bf5-4528-b5c8-26f2cabde1ed',
	CLINICAL_DETAILS: '2e37e97b-aeb5-47d7-add3-0d602233c2aa',
	CODED_DIAGNOSIS: '1f29f7ab-bc9a-427c-b35b-87589e4612b5',
	DASHBOARD: 'd91768c8-5c5b-4d7c-9a6f-15b06d45908b',
	DIAGNOSTICS: '55f080e3-17f4-4ffa-b03b-6dda34884bcc',
	EXPENSE_CATEGORIES: '5731bc45-3b78-475a-a347-4ca899f19e32',
	EXPENSES: '37df7931-7d07-4812-b9d4-dec7a53bb70f',
	INSURERS_AND_DONORS: 'ab23d5c5-19ce-4c46-a17a-5ae2c37dd89d',
	INVENTORY: '8f744d1c-427a-4b85-ab98-38e50258e86d',
	LAB_DIAGNOSTICS_DETAILS: '3084592a-531b-4fbd-a412-5c14c2b15288',
	ORGANIZATION: '66df8b28-5a44-40a0-b63e-d51695bdfc92',
	OTC: '3a4ac3cd-9e1b-4a2c-82d3-78f698ec9e1f',
	PATIENTS: 'ba697729-5ec8-44f7-b534-446310bb5782',
	PAYMENTS: '4497b5f7-758d-4e82-8e2b-01c4364ce609',
	PRODUCTS: 'c63b9972-1b23-4140-8bbb-0ea2b0b81024',
	RECEIVE_PRODUCTS: '78dd6f39-84f9-4e19-b08e-7a3441af15e5',
	REPORTS: '584a4f57-33c6-460e-9916-9ad0347cac5b',
	SERVICES: 'fd93da00-871d-4996-a3f7-4528bed8b758',
	SUPPLIERS: '565af89e-8f10-4469-84f5-6cca8d7fae27',
	TRACK_INCOME: '44c02ddc-ef83-4020-8e4c-709d8cbeadc2',
	TRANSFER_INVENTORY: 'd3c84cad-7306-464d-85da-7e629846f8c0',
	TRIAGE_DETAILS: '53b4d743-c311-40e5-aa8e-c0880c42c1b1',
	USERS: '6b934ec2-7f45-4104-ba10-08e3ce54de7e',
	VISITS: 'a1f3e45c-4a6f-4c05-af26-517b8e9cbb77',
} as const;

export default class AuthService extends BaseService {
	map(data: {}): {} {
		throw new Error('Method not implemented.');
	}
	reverseMap(data: {}): {} {
		throw new Error('Method not implemented.');
	}
	loginUrl = `${configs.apiUrl}${LOGIN_URI}`;
	changePasswordUrl = `${configs.apiUrl}/change-password`;
	changeAccessUrl = `${configs.apiUrl}/change-access`;
	loginCheckOtherClientsUrl = `${configs.apiUrl}/login/check`;

	/**
	 * Login
	 * @param username
	 * @param password
	 */
	async login(
		username: string,
		password: string,
		clientUuid: string,
		roleUuid: string,
		organizationUuid: string,
		warehouseUuid: string,
		language: string,
	) {
		const loginCredentials = {
			username,
			password,
			clientUuid,
			roleUuid,
			organizationUuid,
			warehouseUuid,
			language,
		};
		return axios.post<AuthorizationResponse>(this.loginUrl, loginCredentials);
	}

	async changePassword(username: string, password: string, newPassword: string, language: string) {
		const loginCredentials = {
			username,
			password,
			newPassword,
			language,
		};
		return axios.post<AuthorizationResponse>(this.changePasswordUrl, loginCredentials);
	}

	async changeAccess(changeAccess: ChangeAccess) {
		return axios.post<AuthorizationResponse>(this.changeAccessUrl, changeAccess);
	}

	termsOfService = (doesAccept: boolean) =>
		axios.post<void>(`${configs.apiUrl}/terms-of-service?accept=${doesAccept}`, null);

	async loginCheckOtherClients(username: string, password: string) {
		return axios.post<boolean>(this.loginCheckOtherClientsUrl, {
			username,
			password,
		});
	}
}
