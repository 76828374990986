export type DocumentActionValue = (typeof DocAction)[keyof typeof DocAction];

const DocAction = {
	REVERSE_ACCRUAL: 'RA',
	REVERSE_CORRECT: 'RC',
	REACTIVATE: 'RE',
	COMPLETE: 'CO',
	CLOSE: 'CL',
	PREPARE: 'PR',
	VOID: 'VO',
} as const;

export default DocAction;
