import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useEffect } from 'react';
import { Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAsyncFn, useAsyncRetry } from 'react-use';
import { v4 } from 'uuid';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useService from '../../hooks/useService';
import {
	documentBaseType,
	documentSubTypeSalesOrder,
	Order,
	OrderLine,
	ReferenceList,
	ReferenceListDto,
	referenceListUuids,
} from '../../models';
import DocAction from '../../models/DocAction';
import DocumentStatus, { DocumentStatusValue } from '../../models/DocumentStatus';
import Payment from '../../models/Payment';
import PaymentInformation from '../../models/PaymentInformation';
import { PATIENT_RECEIPT } from '../../models/Report';
import Visit from '../../models/Visit';
import Warehouse from '../../models/Warehouse';
import { ReportTypes } from '../../services/ReportService';
import { exception } from '../../utils/analytics';
import { VISITS_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import CustomPrompt from '../CustomPrompt/CustomPrompt';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReceiptPrint from '../Reports/ReceiptPrint';
import ProductLineItemTable from '../Visit/ProductLineItemTable';
import { NonModelFormField } from '../Visit/VisitForm';
import PharmacySalesDetailsEdit from './PharmacySalesDetailsEdit';
import PharmacySalesPaymentLineItemTable from './PharmacySalesPaymentLineItemTable';

export const SubmitAction = {
	COMPLETE: 'Complete Bill',
	COMPLETE_AND_CLOSE: 'Complete and Close',
	COMPLETE_AND_NEW_VISIT: 'Complete and Start Visit',
	COMPLETE_AND_PRINT_RECEIPT: 'Complete and Print Receipt',
} as const;

const fetchOnCreditOrderDocumentTypeArguments = [
	documentBaseType.SalesOrder,
	documentSubTypeSalesOrder.OnCreditOrder,
	true,
	false,
	false,
] as const;
const fetchReceiptDocumentTypeArguments = [documentBaseType.ARReceipt, null, true, false, false] as const;

export type PharmacySalesFormFields = {
	orders: Array<
		{ orderLinePayments?: OrderLine[]; documentStatus: DocumentStatusValue } & Omit<
			Order,
			'dateOrderedFormatted' | 'createdDateFormatted'
		>
	>;
	orderUuid: string;
	paymentInformationList: PaymentInformation[];
	[NonModelFormField.SUBMIT_EVENT]?: string;
	formAction?: string;
} & Omit<Visit, 'visitDateFormatted' | 'visitDateAndTimeFormatted' | 'dateOrderedFormatted' | 'createdDateFormatted'>;

export type PharmacySalesFormProps = {
	orderToResetFormWith?: PharmacySalesFormFields;
};

const PharmacySalesForm = ({ orderToResetFormWith }: PharmacySalesFormProps) => {
	const { t } = useTranslation();
	const { documentTypeService, visitService, businessPartnerService, reportService } = useService();
	const history = useHistory();
	const { toggleNavBlocking } = useContext(NavBlockingContext);
	const { warehouse } = useContext(UserContext);

	const { value: onCreditOrderDocumentType, retry: fetchWarehouseOurderDocumentType } = useAsyncRetry(
		async () => documentTypeService.getDocumentBaseType(...fetchOnCreditOrderDocumentTypeArguments),
		[documentTypeService],
	);
	const { value: receiptDocumentType, retry: fetchReceiptDocumentType } = useAsyncRetry(
		async () => documentTypeService.getDocumentBaseType(...fetchReceiptDocumentTypeArguments),
		[documentTypeService],
	);

	const [{ value: printReceiptUrl }, onPrintReport] = useAsyncFn((reportUuid: string, visitUuid: string) => {
		return reportService
			.generateReportWithGivenParameterValue(reportUuid, visitUuid, ReportTypes.PDF)
			.then((response) => {
				return URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
			})
			.catch((error) => {
				console.error(error);
				return '';
			});
	}, []);

	const [{ loading }, onSubmit] = useAsyncFn<SubmitHandler<PharmacySalesFormFields>>(
		async (data) => {
			let onCreditOrderDocumentTypeToUse = onCreditOrderDocumentType;
			if (!onCreditOrderDocumentTypeToUse) {
				try {
					onCreditOrderDocumentTypeToUse = await documentTypeService.getDocumentBaseType(
						...fetchOnCreditOrderDocumentTypeArguments,
					);
					fetchWarehouseOurderDocumentType();
				} catch (error) {
					exception({ description: 'Could not load warehouse order document types: ' + error });
					toast.error(t(uiText.visit.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			let receiptDocumentTypeToUse = receiptDocumentType;
			if (!receiptDocumentTypeToUse) {
				try {
					receiptDocumentTypeToUse = await documentTypeService.getDocumentBaseType(
						...fetchReceiptDocumentTypeArguments,
					);
					fetchReceiptDocumentType();
				} catch (error) {
					exception({ description: 'Could not load A/R payment document types: ' + error });
					toast.error(t(uiText.visit.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			const visitFormAction = data.formAction;

			let visit = new Visit({
				...data,
				orders: data.orders?.map((order) => ({
					...order,
					documentTypeTarget: onCreditOrderDocumentTypeToUse,
					createdDateFormatted: '',
					dateOrderedFormatted: '',
					uuid: data.orderUuid,
				})),
			});

			// Remove non-patient payments from order lines
			const arePaymentsPresent = (data.paymentInformationList || []).length > 0;
			if (!arePaymentsPresent) {
				const userWantsToProceed = await CustomPrompt(t(uiText.visit.prompt.COMPLETE_WITHOUT_PAYMENT));
				if (!userWantsToProceed) {
					return;
				}
			} else {
				// Get the total charged for this visit
				let runningTotal = visit.orders[0].orderLines.reduce(
					(runningTotal, orderLine) => runningTotal + (orderLine.price || 0) * (orderLine.quantity || 0),
					0,
				);
				visit.payments.push(
					...(data.paymentInformationList || []).map((paymentInformation) => {
						let priceToUse = paymentInformation.amount || 0;
						// If the running total is smaller than the entered price, we'll take the remaining total
						if (runningTotal - priceToUse < 0) {
							priceToUse = runningTotal;
						}

						runningTotal -= priceToUse;
						return new Payment({
							...paymentInformation,
							payAmount: priceToUse,
							tenderAmount: paymentInformation.amount,
							documentType: receiptDocumentTypeToUse,
						});
					}),
				);
			}

			// set warehouse,
			visit.orders[0].warehouse = new Warehouse({
				uuid: warehouse.uuid,
			});

			// set Over The Counter Patient.
			const overTheCounterPatient = await businessPartnerService.getOverTheCounterPatient(true);
			visit.patient = overTheCounterPatient;

			// set PharmacySales patient type
			const pharmacySalesPatientType = JSON.parse(localStorage.getItem('patientTypes') || '[]')?.find(
				(type: ReferenceListDto) => type.uuid === referenceListUuids.PHARMACY_SALES_TYPE,
			);

			visit.patientType = new ReferenceList(pharmacySalesPatientType);

			try {
				const response = await visitService.saveAndProcess(visit, DocAction.COMPLETE);
				if (response) {
					if (response.isNew) {
						exception({ description: `Visit ${visit.uuid} saved but no response returned` });
					}

					if (response.orders.some((order) => order.documentStatus !== DocumentStatus.COMPLETED)) {
						exception({ description: `Visit ${visit.uuid} not processed` });
						toast.error(t(uiText.order.ERROR_SAVING_PLEASE_TRY_AGAIN));
						return;
					}

					toggleNavBlocking(false);
					if (visitFormAction === SubmitAction.COMPLETE_AND_CLOSE) {
						onCancel();
					} else if (visitFormAction === SubmitAction.COMPLETE_AND_NEW_VISIT) {
						formMethods.reset(new Visit());
					} else if (visitFormAction === SubmitAction.COMPLETE_AND_PRINT_RECEIPT) {
						onPrintReport(PATIENT_RECEIPT, visit.uuid);
						formMethods.reset(new Visit());
					}
				}
			} catch (error) {
				exception({ description: `Visit save error: ${error}` });
				toast.error(t(uiText.visit.error.UNABLE_TO_PROCESS) + error);
			}
		},
		[visitService, warehouse, documentTypeService, onCreditOrderDocumentType, receiptDocumentType],
	);

	const transformOrderData = useCallback(
		(visit: PharmacySalesFormFields): PharmacySalesFormFields => ({
			...new Visit({
				...visit,
				orders: (visit.orders || []).map((order) => ({
					...order,
					orderLines: (order.orderLines || []).map((orderLine) => {
						orderLine.product.attributeSet = undefined;
						return orderLine;
					}),
					createdDateFormatted: '',
					dateOrderedFormatted: '',
				})),
			}),
			paymentInformationList: [
				...(visit.payments || []).map(
					(payment) =>
						new PaymentInformation({
							...payment,
							amount: payment.payAmount,
							isPayment: 'true',
							isWaiver: 'false',
						}),
				),
			],
			orderUuid: v4(),
		}),
		[],
	);

	const onCancel = () => {
		history.push(VISITS_PAGE);
	};

	const formMethods = useForm<FieldValues>({ defaultValues: new Visit() });

	const reset = formMethods.reset;
	useEffect(() => {
		if (orderToResetFormWith) {
			reset(transformOrderData(orderToResetFormWith));
		}
	}, [reset, orderToResetFormWith, transformOrderData]);

	useConfirmRefresh(formMethods.formState?.isDirty);

	return loading ? (
		<LoadSpinner title={t(uiText.visit.PROCESSING)} />
	) : (
		<Layout>
			<Layout.Header>
				<Layout.Title title={t(uiText.pharmacySales.title.NEW)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				{printReceiptUrl && <ReceiptPrint id={'receipt'} url={printReceiptUrl} />}

				<Row className="bg-white ms-0">
					<FormProvider {...formMethods}>
						<Form onSubmit={formMethods.handleSubmit(onSubmit)} className="px-0">
							<input type="hidden" {...formMethods.register('uuid')} />
							<input type="hidden" {...formMethods.register('isNew')} />
							<input type="hidden" {...formMethods.register('orderUuid')} defaultValue={v4()} />

							<PharmacySalesDetailsEdit />

							<Card className="bh-card">
								<Card.Header className="fw-bold h5">{t(uiText.visit.form.product.LABEL)}</Card.Header>
								<Card.Body>
									<ProductLineItemTable readOnly={false} />
								</Card.Body>
							</Card>

							<Card className="bh-card">
								<Card.Header className="fw-bold h5">{t(uiText.visit.form.payment.LABEL)}</Card.Header>
								<Card.Body>
									<PharmacySalesPaymentLineItemTable />
								</Card.Body>
							</Card>

							<Row className="m-4 ms-3">
								<Col xs="auto" className="me-auto">
									<Button type="button" variant="danger" name="cancel" className="me-auto" onClick={onCancel}>
										{t(uiText.visit.button.CANCEL)}
									</Button>
								</Col>

								<Col xs="auto">
									<BHDropdownButton title={t(uiText.visit.button.COMPLETE)} variant="success" icon="check">
										<Dropdown.Item
											onClick={(e) => {
												formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.COMPLETE);
												formMethods.setValue('formAction', SubmitAction.COMPLETE_AND_CLOSE);
												formMethods.handleSubmit(onSubmit)(e);
											}}
										>
											<FontAwesomeIcon icon="file" className="me-2 fa-fw" />
											{t(uiText.visit.button.COMPLETE_AND_CLOSE)}
										</Dropdown.Item>
										<Dropdown.Item
											onClick={(e) => {
												formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.COMPLETE);
												formMethods.setValue('formAction', SubmitAction.COMPLETE_AND_NEW_VISIT);
												formMethods.handleSubmit(onSubmit)(e);
											}}
										>
											<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
											{t(uiText.visit.button.COMPLETE_AND_NEW_VISIT)}
										</Dropdown.Item>
										<Dropdown.Item
											onClick={(e) => {
												formMethods.setValue(NonModelFormField.SUBMIT_EVENT, SubmitAction.COMPLETE);
												formMethods.setValue('formAction', SubmitAction.COMPLETE_AND_PRINT_RECEIPT);
												formMethods.handleSubmit(onSubmit)(e);
											}}
										>
											<FontAwesomeIcon icon="print" className="me-2 fa-fw" />
											{t(uiText.pharmacySales.button.COMPLETE_AND_PRINT_RECEIPT)}
										</Dropdown.Item>
									</BHDropdownButton>
								</Col>
							</Row>
						</Form>
					</FormProvider>
				</Row>
			</Layout.Body>
		</Layout>
	);
};

export default PharmacySalesForm;
