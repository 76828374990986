import React, { Children, ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

export type WorkspaceMenuFilterProps = {
	children: ReactNode;
};

const WorkspaceMenuFilter = ({ children }: WorkspaceMenuFilterProps) => {
	const arrayChildren = Children.toArray(children);
	return (
		<Col className="my-2 ps-0">
			<Row className="gy-1 mx-0 h-100">
				{arrayChildren.map((child, index) => (
					<Col xs="auto" className="d-flex align-items-center" key={index}>
						{child}
					</Col>
				))}
			</Row>
		</Col>
	);
};

export default WorkspaceMenuFilter;
