import { startOfDay } from 'date-fns';
import React, { Fragment } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsync, useAsyncFn } from 'react-use';
import useService from '../../hooks/useService';
import { DocumentStatus, Inventory, InventoryLine, StorageOnHand } from '../../models';
import AttributeSetInstance from '../../models/AttributeSetInstance';
import DocAction from '../../models/DocAction';
import ReferenceList from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import FormatNumberInput from '../format-number-input/FormatNumberInput';

type StockUpdateModalProps = {
	selectedData: StorageOnHand;
	onFinish: (refresh: boolean) => void;
};

const StockUpdateModal = ({ selectedData, onFinish }: StockUpdateModalProps) => {
	const { t } = useTranslation();
	const { referenceListService, attributeSetInstanceService, inventoryService } = useService();

	const formMethods = useForm<FieldValues>({
		defaultValues: {
			...selectedData,
			attributeSetInstance: {
				...new AttributeSetInstance(selectedData.attributeSetInstance),
				updateReason: { uuid: null },
			},
		},
	});

	const getMaxQuantityAllowed = () => {
		// If this is a "new" ASI (i.e. it's ID is 0 in the DB), they can't make this go positive
		if (selectedData.attributeSetInstance.isNew) {
			return 0;
		}

		return Infinity;
	};

	const { value: stockUpdateReasons = [] } = useAsync(
		async () => await referenceListService.getStockUpdateReasons(),
		[referenceListService],
	);

	const [, handleUpdateSave] = useAsyncFn<SubmitHandler<StorageOnHand>>(
		async (formData) => {
			let updateSuccessful = true;
			// Send the inventory update, if one was made
			if (formData.quantityOnHand !== selectedData.quantityOnHand) {
				const inventory = new Inventory({
					warehouse: selectedData.locator.warehouse,
					updateReason: new ReferenceList({ uuid: formData.attributeSetInstance.updateReason?.uuid }),
					movementDate: new Date().getTime(),
					inventoryLines: [
						new InventoryLine({
							line: 10,
							quantityCount: formData.quantityOnHand,
							product: selectedData.product,
							locator: selectedData.locator,
							attributeSetInstance: new AttributeSetInstance({ uuid: selectedData.attributeSetInstance.uuid }),
						}),
					],
				});

				try {
					const updatedInventory = await inventoryService.saveAndProcess(inventory, DocAction.COMPLETE);
					// If the document status isn't completed, we have a problem
					if (updatedInventory.documentStatus !== DocumentStatus.COMPLETED) {
						updateSuccessful = false;
						// If the data to update has no expiration and/or, assume it was an error where the product needs an expiration, but
						// still has quantity that didn't have an expiration and the user was trying to update this quantity
						if (!selectedData.attributeSetInstance.uuid && !selectedData.attributeSetInstance.guaranteeDate) {
							toast.error(t(uiText.inventory.updateQuantityProcess.error.PRODUCT_MISCONFIGURED));
						} else {
							// TODO Update the document processing to return the actual error so we can give the user (or ourselves) more information
							toast.error(t(uiText.inventory.updateQuantityProcess.error.ERROR_UPDATING_QUANTITY));
						}
					}
				} catch (error) {
					updateSuccessful = false;
					if (error) {
						toast.error(error.toString());
					}
				}
			}
			const originalExpirationDate =
				(selectedData.attributeSetInstance.guaranteeDate &&
					new Date(selectedData.attributeSetInstance.guaranteeDate)) ||
				undefined;
			if (
				formData.attributeSetInstance.guaranteeDate?.getDate() &&
				formData.attributeSetInstance.guaranteeDate !== originalExpirationDate
			) {
				const attributeSetInstanceToUpdate = new AttributeSetInstance({
					uuid: formData.attributeSetInstance.uuid,
					guaranteeDate: formData.attributeSetInstance.guaranteeDate,
					updateReason: new ReferenceList({ uuid: formData.attributeSetInstance.updateReason?.uuid }),
				});
				await attributeSetInstanceService.save(attributeSetInstanceToUpdate);
			}
			if (updateSuccessful) {
				onFinish(true);
			}
		},
		[selectedData, attributeSetInstanceService, inventoryService, t],
	);

	return (
		<Modal show={true}>
			<FormProvider {...formMethods}>
				<Form className="p-0">
					<Modal.Header>
						{t(uiText.inventory.QUANTITY_UPDATE)} {selectedData?.product.name}
					</Modal.Header>
					<Modal.Body>
						<Row className="gy-2">
							<Form.Group as={Fragment} controlId="quantityOnHand">
								<Col xs={4} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.inventory.label.QUANTITY)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Controller
										name="quantityOnHand"
										rules={{ max: getMaxQuantityAllowed(), min: 0, required: true }}
										render={({ field }) => (
											<FormatNumberInput step="0.01" {...field} displayAndUseZeroIfEmpty={false} />
										)}
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="expirationDate">
								<Col xs={4} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.inventory.label.EXPIRATION_DATE)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<Controller
										name="attributeSetInstance.guaranteeDate"
										rules={{
											required: selectedData.product.hasExpiration && !!selectedData.attributeSetInstance.guaranteeDate,
										}}
										render={({ field }) => (
											<BandaDatePicker
												minDate={startOfDay(new Date())}
												{...field}
												selected={field.value}
												disabled={selectedData.attributeSetInstance.isNew || !selectedData.product.hasExpiration}
											/>
										)}
									/>
									{formMethods.formState.errors?.attributeSetInstance?.guaranteeDate && (
										<span className="text-danger">
											{t(uiText.inventory.updateQuantityProcess.error.MISSING_EXPIRATION_DATE)}
										</span>
									)}
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="updateReason">
								<Col xs={4} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.inventory.label.UPDATE_REASON)}</Form.Label>
								</Col>
								<Col xs={8} className="d-flex align-items-center">
									<DynamicSelect
										{...formMethods.register('attributeSetInstance.updateReason.uuid', { required: true })}
										required
										aria-label={t(uiText.inventory.label.UPDATE_REASON)}
										isLoading={!stockUpdateReasons.length}
									>
										<option value="">{t(uiText.inventory.SELECT_REASON)}</option>
										{stockUpdateReasons &&
											stockUpdateReasons.map(function (updateReason, index) {
												return (
													<option key={index} value={updateReason.uuid}>
														{updateReason.name}
													</option>
												);
											})}
									</DynamicSelect>
									{formMethods.formState.errors?.attributeSetInstance?.updateReason?.uuid && (
										<span className="text-danger">
											{t(uiText.inventory.updateQuantityProcess.error.MISSING_UPDATE_REASON)}
										</span>
									)}
									{formMethods.formState.errors?.quantityOnHand && (
										<span className="text-danger">
											{t(uiText.inventory.updateQuantityProcess.error.UPDATING_EXPIRED_PRODUCT_QUANTITY)}
										</span>
									)}
								</Col>
							</Form.Group>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Row>
							<Col xs="auto" className="ms-auto">
								<BasicButton
									name={uiText.inventory.button.CANCEL}
									text={t(uiText.inventory.button.CANCEL)}
									variant="danger"
									icon="times"
									active={true}
									onClick={() => {
										onFinish(false);
									}}
								/>
							</Col>
							<Col xs="auto">
								<BasicButton
									name={uiText.inventory.button.SAVE}
									text={t(uiText.inventory.button.SAVE)}
									variant="success"
									icon="check"
									active={true}
									onClick={formMethods.handleSubmit(handleUpdateSave)}
								/>
							</Col>
						</Row>
					</Modal.Footer>
				</Form>
			</FormProvider>
		</Modal>
	);
};

export default StockUpdateModal;
