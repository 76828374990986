import React from 'react';
import { Col, Row } from 'react-bootstrap';

const LayoutBody = ({ children }: { children: React.ReactNode }) => (
	<Row className="mt-0">
		<Col>{children}</Col>
	</Row>
);

export default LayoutBody;
