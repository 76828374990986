import { Modal } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import useService from '../../hooks/useService';
import { BusinessPartner } from '../../models';
import DBFilter from '../../models/DBFilter';
import DocumentStatus from '../../models/DocumentStatus';
import ReferenceList from '../../models/ReferenceList';
import { VisitDB } from '../../models/Visit';
import { uiText } from '../../utils/Language';
import VisitHistoryLine from './VisitHistoryLine';
import { visitDbProperty } from './Visits';

type VisitHistoryProps = { onClose: () => void };

const VisitHistory = ({ onClose }: VisitHistoryProps) => {
	const { t } = useTranslation();
	const { metadataService, userService, visitService } = useService();

	const patient: BusinessPartner = useWatch({ name: 'patient' });
	const visitUuid: string = useWatch({ name: 'uuid' });
	const isNewVisit: string = useWatch({ name: 'isNew' });
	const { value: patientTypes } = useAsync(
		async () =>
			await metadataService
				.getPatientTypes()
				.then((data) => data.map((referenceList) => new ReferenceList(referenceList))),
	);
	const { value: referrals } = useAsync(
		async () =>
			await metadataService
				.getReferrals()
				.then((data) => data.map((referenceList) => new ReferenceList(referenceList))),
	);

	const patientUuid = patient?.uuid;
	const isNewPatient = patient?.isNew || !patient;
	const initialVisitCountToFetch = 3;
	const visitCountModifier = isNewVisit === 'true' ? 0 : 1;
	const { value: patientVisitDetails, loading: areLoadingVisits } = useAsync(async () => {
		if (!patientUuid || isNewPatient) {
			return { visits: [], totalVisits: 0 };
		}
		const filter = DBFilter<VisitDB>()
			.and(DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('c_bpartner_uu').equals(patientUuid).up())
			.and(DBFilter<VisitDB>().property('bh_visit_uu').doesNotEqual(visitUuid))
			.and(DBFilter<VisitDB>().nested('c_order').property('docStatus').doesNotEqual(DocumentStatus.VOIDED).up());
		const { results, pagingInfo } = await visitService.get(
			0,
			initialVisitCountToFetch,
			[{ id: visitDbProperty.VISIT_DATE, desc: true }],
			filter,
		);
		return {
			visits: results,
			// Add one to the total count if this isn't a new visit (since we filtered out the current visit from the search)
			totalVisits: (pagingInfo.totalRecordCount || 0) + visitCountModifier,
		};
	}, [visitService, patientUuid, isNewPatient, initialVisitCountToFetch, visitUuid]);
	const { value: clinicians } = useAsync(async () => (await userService.getClinicians()).results, [userService]);

	return (
		<Modal show={true} onHide={onClose} size="lg">
			<Modal.Header closeButton>
				{t(uiText.visit.MEDICAL_RECORDS, {
					patientName: patient?.name,
					patientNumber: patient?.localPatientNumber || patient?.patientNumber,
				})}
				<br />
				{t(uiText.visit.TOTAL_NUMBER_OF_VISITS, {
					totalVisits: areLoadingVisits ? t(uiText.visit.COUNTING) : patientVisitDetails?.totalVisits,
				})}
			</Modal.Header>
			<Modal.Body className="bh-form--review">
				{areLoadingVisits
					? t(uiText.visit.FETCHING_VISITS)
					: patientVisitDetails?.visits.map((visit) => (
							<VisitHistoryLine
								key={visit.uuid}
								clinicians={clinicians}
								patientTypes={patientTypes}
								referrals={referrals}
								visit={visit}
							/>
						))}
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" onClick={onClose}>
					{t(uiText.visit.button.CLOSE)}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default VisitHistory;
