import React from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from '../../assets/images/b-logo.png';
import { textNeededBeforeTranslation } from '../../utils/Language';
import WindowsLoader from '../Loading/WindowsLoader';
import './Splash.scss';

const Splash = () => {
	return (
		<Row className="splash flex-column justify-content-center align-items-center w-100 m-0">
			<Col xs="auto">
				<img src={logo} alt={textNeededBeforeTranslation.LOGO_ALT} />
			</Col>
			<Col xs="auto">
				<WindowsLoader width={500} />
			</Col>
		</Row>
	);
};

export default Splash;
