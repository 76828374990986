import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import AttributeSet, { AttributeSetDto } from './AttributeSet';
import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import StorageOnHand, { StorageOnHandDB, StorageOnHandDto } from './StorageOnHand';

export const ProductType = {
	ITEM: 'I',
	SERVICE: 'S',
};

export interface ProductDB extends BaseEntityDB {
	m_product_uu: string;
	isActive: boolean;
	'm_product_category.name': string;
	'product_costs.purchase_price': number;
	BH_SellPrice: number;
	producttype: string;
	m_storageonhand: StorageOnHandDB;
}

export interface ProductDto extends BaseEntityDto {
	sellPrice?: number;
	buyPrice?: number;
	reorderLevel?: number;
	reorderQuantity?: number;
	type?: string;
	productCategoryUuid?: string;
	totalQuantity?: number;
	storageOnHandList?: StorageOnHandDto[] | null;
	attributeSet?: AttributeSetDto | null;
	hasBeenPurchased?: boolean | null;
}

export default class Product extends BaseEntity {
	sellPrice?: number;
	sellingPrice?: number;
	buyPrice?: number;
	reorderLevel?: number;
	reorderQuantity?: number;
	type: string;
	productCategoryUuid: string;
	totalQuantity: number;
	price?: number;
	storageOnHandList: StorageOnHand[];
	attributeSet?: AttributeSet;
	hasBeenPurchased?: boolean | null;

	get hasExpiration() {
		return this.attributeSet?.isGuaranteeDate || false;
	}

	constructor(props?: DeepPartial<Product | ProductDto>) {
		props = cloneDeep(props || {});
		super(props);

		if ('sellPrice' in props) {
			this.sellPrice = props.sellPrice;
		}
		if ('sellingPrice' in props) {
			this.sellingPrice = props.sellingPrice;
		}
		if ('buyPrice' in props) {
			this.buyPrice = props.buyPrice;
		} else if ('price' in props) {
			this.buyPrice = props.price;
		}
		if ('reorderLevel' in props) {
			this.reorderLevel = props.reorderLevel;
		}
		if ('reorderQuantity' in props) {
			this.reorderQuantity = props.reorderQuantity;
		}
		this.type = props.type || '';
		this.productCategoryUuid = '';
		if ('productCategoryUuid' in props) {
			this.productCategoryUuid = props.productCategoryUuid || '';
		}
		this.totalQuantity = props.totalQuantity || 0;
		this.storageOnHandList = (('storageOnHandList' in props && props.storageOnHandList) || []).map(
			(storageOnHand) => new StorageOnHand(storageOnHand),
		);
		if ('attributeSet' in props) {
			this.attributeSet = props.attributeSet ? new AttributeSet(props.attributeSet) : undefined;
		}

		if ('price' in props) {
			this.price = props.price;
		} else {
			this.price = this.sellPrice || this.sellingPrice;
		}
		this.hasBeenPurchased = props.hasBeenPurchased;
	}
}
