import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useService from '../../hooks/useService';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import CodedDiagnosis from '../../models/CodedDiagnosis';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import { withFormModalSuspsenseWrapper } from '../HOCs/withFormModalSuspsenseWrapper';
import Layout from '../Layout/Layout';

export type CodedDiagnosisFormProps = EntityFormProperties;

const getTitle = (uuid?: string) => uiText.codedDiagnosis.title.VIEW;

const CodedDiagnosisForm = ({ uuid, onFinish, renderAsModal }: CodedDiagnosisFormProps) => {
	const { t } = useTranslation();
	const { codedDiagnosisService } = useService();

	const { data: codedDiagnosis } = useSuspenseAsync(uuid || 'add-coded-diagnosis', async () =>
		uuid ? codedDiagnosisService.getByUuid(uuid) : undefined,
	);

	const title = getTitle(uuid);
	const [data] = useState(codedDiagnosis || new CodedDiagnosis());

	const submit: SubmitHandler<CodedDiagnosis> = (data: CodedDiagnosis) => {
		const codedDiagnosis = new CodedDiagnosis(data); // Why is this needed? shouldn't we make sure all the properties come set here?

		codedDiagnosisService
			.save(codedDiagnosis)
			.then((result) => {
				toast.success(t(uiText.codedDiagnosis.success.UPDATE));
				onFinish(true, result.uuid);
			})
			.catch((error) => {
				exception({ description: `Coded diagnosis save error: ${error}` });
				toast.error(t(uiText.codedDiagnosis.error.COULD_NOT_SAVE, { error }));
			});
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm({ defaultValues: data });
	useConfirmRefresh(isDirty);
	const { disableWrite } = useActionPrivileges(pageUuid.CODED_DIAGNOSIS);

	const inputLabelWidth = renderAsModal ? 3 : 2;
	const inputs = (
		<Form onSubmit={handleSubmit(submit)}>
			<fieldset disabled={disableWrite}>
				<Form.Group as={Row} controlId="cielId">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.CIEL_ID)}
					</Form.Label>
					<Col>
						<Form.Control type="number" {...register('cielId', { valueAsNumber: true })} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="cielName">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.CIEL_NAME)}
					</Form.Label>
					<Col>
						<Form.Control {...register('cielName', { required: true })} />
						{errors.cielName && (
							<span className="text-danger">{t(uiText.codedDiagnosis.validationMessages.REQUIRE_CIEL_NAME)}</span>
						)}
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="conceptClass">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.CONCEPT_CLASS)}
					</Form.Label>
					<Col>
						<Form.Control {...register('conceptClass')} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="icd10">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.ICD10_WHO)}
					</Form.Label>
					<Col>
						<Form.Control {...register('icd10')} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="synomedCT">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.SYNOMED_CT)}
					</Form.Label>
					<Col>
						<Form.Control {...register('synomedCT', { valueAsNumber: true })} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="synomedNP">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.SYNOMED_NP)}
					</Form.Label>
					<Col>
						<Form.Control {...register('synomedNP', { valueAsNumber: true })} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="synonyms">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.SYNONYMS)}
					</Form.Label>
					<Col>
						<Form.Control {...register('synonyms')} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="moh705aLessThan5">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.MOH705A_LESSTHAN5)}
					</Form.Label>
					<Col>
						<Form.Control {...register('moh705aLessThan5')} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="moh705bGreaterThan5">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.MOH705B_GREATERTHAN5)}
					</Form.Label>
					<Col>
						<Form.Control {...register('moh705bGreaterThan5')} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="searchTerms">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.labels.SEARCH_TERMS)}
					</Form.Label>
					<Col>
						<Form.Control {...register('searchTerms')} />
					</Col>
				</Form.Group>

				<Form.Group as={Row} controlId="description">
					<Form.Label column xs={inputLabelWidth}>
						{t(uiText.codedDiagnosis.description.LABEL)}
					</Form.Label>
					<Col>
						<Form.Control
							as="textarea"
							rows={3}
							placeholder={t(uiText.codedDiagnosis.description.ENTER)}
							{...register('description')}
						/>
					</Col>
				</Form.Group>
			</fieldset>
		</Form>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'mt-4'}`}>
			{disableWrite ? (
				<Col xs="auto" className="me-auto">
					<BasicButton
						name={uiText.codedDiagnosis.button.BACK}
						text={t(uiText.codedDiagnosis.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => onFinish(false)}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto" className="me-auto">
						<Button type="button" variant="danger" onClick={() => onFinish(false)}>
							{disableWrite ? t(uiText.codedDiagnosis.button.BACK) : t(uiText.codedDiagnosis.button.CANCEL)}
						</Button>
					</Col>
					{disableWrite ? null : (
						<Col xs="auto">
							<Button
								variant="success"
								onClick={() => {
									handleSubmit(submit)();
								}}
							>
								{t(uiText.codedDiagnosis.button.SAVE)}
							</Button>
						</Col>
					)}
				</>
			)}
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="ps-2_5 pb-0_5 bg-white">
					<Card className="bh-card">
						<Card.Body>
							{inputs}
							{buttons}
						</Card.Body>
					</Card>
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspsenseWrapper<CodedDiagnosisFormProps>({
	loadingLabel: uiText.codedDiagnosis.LOADING,
	getTitle,
	size: 'lg',
})(CodedDiagnosisForm);
