import {
	BaseEntityDB,
	BusinessPartnerDB,
	businessPartnerGroupName,
	ConceptClass,
	ConceptDB,
	ConceptExtraKey,
	ConceptMappingMapType,
	DBFilter,
	WarehouseDB,
} from '../models';

export function getDefaultSearchFilter(query: string) {
	return DBFilter<BaseEntityDB>().property('name').contains(query);
}

export function getWarehouseByOrg(organizationUuid: string) {
	return DBFilter<WarehouseDB>()
		.and(DBFilter<WarehouseDB>().property('AD_Org.AD_Org_UU').equals(organizationUuid))
		.and(DBFilter<WarehouseDB>().property('name').doesNotEqual('Standard'))
		.and(DBFilter<WarehouseDB>().property('isactive').equals(true));
}

export function getConceptTestSearchFilter(searchName: string) {
	return (
		DBFilter<ConceptDB>()
			.property('bh_concept_class')
			.equals(ConceptClass.TEST)
			// search in display name (which can be scientific name)
			.or(DBFilter<ConceptDB>().property('bh_display_name').contains(searchName))
			// search in any client concepts
			.or(DBFilter<ConceptDB>().nested('bh_client_concept').property('name').contains(searchName).up())
			// search in local name attributes attached to the concept
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up(),
			)
			// search in local name attributes attached to the mapping to parent
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up()
					.up(),
			)
			// search in panel display name
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					.nested('bh_concept')
					.property('bh_concept_class')
					.equals(ConceptClass.LAB_SET)
					.property('bh_display_name')
					.contains(searchName)
					.up()
					.up(),
			)
			// search in panel local name
			.or(
				DBFilter<ConceptDB>()
					.nested('bh_concept_mapping::bh_oclid->bh_to_concept_code')
					.property('bh_map_type')
					.equals(ConceptMappingMapType.CONCEPT_SET)
					.nested('bh_concept')
					.property('bh_concept_class')
					.equals(ConceptClass.LAB_SET)
					.nested('bh_concept_extra')
					.property('bh_key')
					.equals(ConceptExtraKey.LOCAL_NAME)
					.property('bh_value')
					.contains(searchName)
					.up()
					.up()
					.up(),
			)
	);
}

export function getBusinessPartnerDefaultSearchFilter(searchName: string) {
	return DBFilter<BusinessPartnerDB>().property('name').contains(searchName);
}

export function getPatientSearchFilter(query: string) {
	return DBFilter<BusinessPartnerDB>()
		.nested('c_bp_group')
		.property('name')
		.equals(businessPartnerGroupName.PATIENTS)
		.up()
		.and(
			DBFilter<BusinessPartnerDB>()
				.or(DBFilter<BusinessPartnerDB>().property('name').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('BH_PatientID').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('bh_phone').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('BH_Local_PatientID').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('nextofkin_contact').contains(query))
				.or(DBFilter<BusinessPartnerDB>().property('nationalid').contains(query)),
		)
		.and(DBFilter<BusinessPartnerDB>().property('isactive').equals(true));
}
