import React, { Fragment } from 'react';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReferenceList from '../../models/ReferenceList';
import User from '../../models/User';
import { uiText } from '../../utils/Language';

type VisitDetailsReviewProps = {
	clinicians?: User[];
	patientTypes?: ReferenceList[];
	referrals?: ReferenceList[];
	loading: boolean;
};

const VisitDetailsReview = ({ clinicians, patientTypes, referrals, loading }: VisitDetailsReviewProps) => {
	const { t } = useTranslation();
	const { register, getValues } = useFormContext();

	const visitUuid: string = getValues('uuid');

	return (
		<fieldset disabled={true}>
			<Card className="bh-card">
				<Card.Header className="d-flex">
					<div className="fw-bold h5">{t(uiText.visit.DETAILS)}</div>
					{loading && (
						<div className="ms-auto fw-light">
							<small>{t(uiText.visit.LOADING_MORE_DETAILS)}</small>{' '}
							<Spinner animation="border" size="sm" className="align-middle" role="status" />
						</div>
					)}
				</Card.Header>
				<Card.Body>
					<Row className="gy-3">
						<Form.Group as={Fragment} controlId={`visitDate${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.DATE)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Controller
									name="visitDate"
									render={({ field }) => (
										<DatePicker
											wrapperClassName="w-100"
											className="form-control"
											dateFormat="yyyy-MM-dd h:mm aa"
											{...field}
											selected={field.value}
											timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
											showTimeInput
										/>
									)}
								/>
							</Col>
						</Form.Group>
						<Col xs={4} />
						<Form.Group as={Fragment} controlId={`txReferral${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.patient.REFERRAL)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Select {...register('referral.value')}>
									<option>&nbsp;</option>
									{(referrals || []).map((referral) => (
										<option key={referral.uuid} value={referral.value}>
											{referral.name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>

						<Form.Group as={Fragment} controlId={`txPatientType${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.patient.TYPE)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Select {...register('patientType.value')}>
									<option>&nbsp;</option>
									{(patientTypes || []).map((patientType) => (
										<option key={patientType.uuid} value={patientType.value}>
											{patientType.name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>
						<Form.Group as={Fragment} controlId={`txClinician${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.CLINICIAN)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Select {...register('clinician.uuid')}>
									<option>&nbsp;</option>
									{clinicians?.map((clinician) => (
										<option key={clinician.uuid} value={clinician.uuid}>
											{clinician.name}
										</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>
						<Form.Group as={Fragment} controlId={`referredFromTo${visitUuid}`}>
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.visit.form.patient.REFERRED_FROM_TO)}</Form.Label>
							</Col>
							<Col xs={3} className="d-flex align-items-center">
								<Form.Control {...register('referredFromTo')} />
							</Col>
						</Form.Group>
					</Row>
				</Card.Body>
			</Card>
		</fieldset>
	);
};

export default VisitDetailsReview;
