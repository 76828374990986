import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { M_ProductDataForReceiveProductsFragmentDoc } from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import { getManualModelPriceMargin } from '../../utils/ModelUtils';
import { formatNumber, precisionAdd, precisionSubtract } from '../../utils/NumberUtil';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { PriceUpdateFormValues } from './PriceUpdateModal';

type PriceUpdateModalRowProps = {
	field: PriceUpdateFormValues['products'][0];
	index: number;
};

const PriceUpdateModalRow = ({ field, index }: PriceUpdateModalRowProps) => {
	const { setValue, getValues } = useFormContext<PriceUpdateFormValues>();
	const fieldPrefix = `products.${index}`;
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const newPriceMargin =
		useWatch<PriceUpdateFormValues, 'products.0.newPriceMargin'>({
			name: `${fieldPrefix}.newPriceMargin` as 'products.0.newPriceMargin',
		}) || 0;
	const newSellPrice =
		useWatch<PriceUpdateFormValues, 'products.0.newSellPrice'>({
			name: `${fieldPrefix}.newSellPrice` as 'products.0.newSellPrice',
		}) || 0;

	const product = graphqlClient.readFragment({ id: field.UU, fragment: M_ProductDataForReceiveProductsFragmentDoc });

	useEffect(() => {
		setValue(
			`${fieldPrefix}.wasSellPriceChanged` as 'products.0.wasSellPriceChanged',
			newSellPrice !== product?.BH_SellPrice,
		);
	}, [newSellPrice, product, fieldPrefix, setValue]);

	useEffect(() => {
		const calculatedSellPrice = precisionAdd(field.newBuyPrice, newPriceMargin);
		if (calculatedSellPrice !== getValues(`${fieldPrefix}.newSellPrice` as 'products.0.newSellPrice')) {
			setValue(`${fieldPrefix}.newSellPrice` as 'products.0.newSellPrice', calculatedSellPrice);
		}
	}, [newPriceMargin, setValue, getValues, fieldPrefix, field.newBuyPrice]);

	useEffect(() => {
		const newPriceMargin = precisionSubtract(newSellPrice, field.newBuyPrice);
		setValue(`${fieldPrefix}.newPriceMargin` as 'products.0.newPriceMargin', newPriceMargin);
	}, [newSellPrice, setValue, fieldPrefix, field.newBuyPrice]);

	return (
		<tr>
			<td className="p-2" title={product?.Name}>
				{product?.Name}
			</td>
			<td className="p-2 text-end bg-light" aria-readonly={true}>
				{formatNumber(product?.LastPurchasePrice)}
			</td>
			<td className="p-2 text-end bg-light">{formatNumber(field.newBuyPrice)}</td>
			<td className="p-2 text-end bg-light">
				{formatNumber(getManualModelPriceMargin(product?.LastPurchasePrice, product?.BH_SellPrice))}
			</td>
			<td>
				<Controller<PriceUpdateFormValues, 'products.0.newPriceMargin'>
					name={`${fieldPrefix}.newPriceMargin` as 'products.0.newPriceMargin'}
					render={({ field }) => (
						<FormatNumberInput aria-label={t(uiText.receiveProduct.modal.columns.NEW_MARGIN)} {...field} />
					)}
				/>
			</td>
			<td>
				<Controller<PriceUpdateFormValues, 'products.0.newSellPrice'>
					name={`${fieldPrefix}.newSellPrice` as 'products.0.newSellPrice'}
					render={({ field }) => (
						<FormatNumberInput aria-label={t(uiText.receiveProduct.modal.columns.SELL_PRICE)} {...field} />
					)}
				/>
			</td>
		</tr>
	);
};

export default PriceUpdateModalRow;
