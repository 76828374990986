import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';
import BusinessPartnerGeneralPayerInformation, {
	BusinessPartnerGeneralPayerInformationDto,
} from './BusinessPartnerGeneralPayerInformation';

export interface BusinessPartnerPayerInformationDto extends BaseEntityDto {
	businessPartnerUuid: string;
	payerUuid: string;
	businessPartnerGeneralPayerInformationList: BusinessPartnerGeneralPayerInformationDto[];
}

export default class BusinessPartnerPayerInformation extends BaseEntity {
	businessPartnerUuid?: string;
	payerUuid?: string;
	businessPartnerGeneralPayerInformationList: BusinessPartnerGeneralPayerInformation[];

	constructor(entity: Partial<BusinessPartnerPayerInformation | BusinessPartnerPayerInformationDto> = {}) {
		entity = cloneDeep(entity);
		super(entity);

		this.businessPartnerUuid = entity.businessPartnerUuid;
		this.payerUuid = entity.payerUuid;
		this.businessPartnerGeneralPayerInformationList = (entity.businessPartnerGeneralPayerInformationList || []).map(
			(chargeInformation) => new BusinessPartnerGeneralPayerInformation(chargeInformation),
		);
	}
}
