import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import { formatDate } from '../utils/DateUtil';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import BusinessPartner, { BusinessPartnerDB, BusinessPartnerDto } from './BusinessPartner';
import DocumentStatus, { DocumentStatusValue } from './DocumentStatus';
import DocumentType, { DocumentTypeDto } from './DocumentType';
import OrderLine, { OrderLineDB, OrderLineDto } from './OrderLine';
import VoidedReason, { VoidedReasonDto } from './VoidedReason';
import Warehouse, { WarehouseDB, WarehouseDto } from './Warehouse';

export interface OrderDB extends BaseMetadataDB {
	dateordered: Date;
	dateacct: Date;
	docStatus: string;
	grandTotal: number;
	c_order_uu: string;
	c_bpartner: BusinessPartnerDB;
	issotrx: boolean;
	c_orderline: OrderLineDB;
	bh_visit_id: number;
	m_warehouse: WarehouseDB;
}

export interface OrderDto extends BaseMetadataDto {
	businessPartner?: BusinessPartnerDto | null;
	description?: string | null;
	dateOrdered?: number | null;
	dateAccount?: number | null;
	grandTotal?: number | null;
	docStatus?: string | null;
	orderLines?: OrderLineDto[] | null;
	voidedReason?: VoidedReasonDto | null;
	warehouse?: WarehouseDto | null;
	documentTypeTarget?: DocumentTypeDto | null;
	isSalesOrderTransaction?: boolean;
}

export default class Order extends BaseMetadata {
	businessPartner: BusinessPartner;
	description?: string;
	dateOrdered: Date;
	dateAccount: Date;
	grandTotal: number;
	documentStatus: DocumentStatusValue;
	orderLines: OrderLine[];
	voidedReason: VoidedReason;
	warehouse: Warehouse;
	documentTypeTarget?: DocumentTypeDto;
	isSalesOrderTransaction?: boolean;

	/**
	 * The visit date, but formatted
	 */
	get dateOrderedFormatted(): string {
		return formatDate(this.dateOrdered);
	}

	constructor(props?: DeepPartial<Order | OrderDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.description = props.description || '';
		this.dateOrdered =
			((typeof props.dateOrdered === 'string' ||
				props.dateOrdered instanceof Date ||
				typeof props.dateOrdered === 'number') &&
				new Date(props.dateOrdered)) ||
			new Date();
		this.dateAccount = props.dateAccount ? new Date(props.dateAccount) : new Date();
		this.grandTotal = props.grandTotal || 0;
		if ('documentStatus' in props) {
			this.documentStatus = props.documentStatus || DocumentStatus.DRAFTED;
		} else if ('docStatus' in props) {
			this.documentStatus = (props.docStatus as DocumentStatusValue) || DocumentStatus.DRAFTED;
		} else {
			this.documentStatus = DocumentStatus.DRAFTED;
		}
		this.orderLines = (props.orderLines || []).map((orderLine) => new OrderLine(orderLine));
		this.voidedReason = !props.voidedReason ? new VoidedReason({ uuid: '' }) : new VoidedReason(props.voidedReason);
		this.warehouse = new Warehouse(props.warehouse || {});
		this.documentTypeTarget = props.documentTypeTarget ? new DocumentType(props.documentTypeTarget) : undefined;
		this.businessPartner = new BusinessPartner(props.businessPartner || {});
		this.isSalesOrderTransaction = props.isSalesOrderTransaction || false;
	}
}
