import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { ExpenseFormLineFields } from './ExpenseLineItemTable';

type ExpenseLineItemTableFooterProps = { shouldDisplayTheDeleteColumn: boolean };

const ExpenseLineItemTableFooter = ({ shouldDisplayTheDeleteColumn }: ExpenseLineItemTableFooterProps) => {
	const { t } = useTranslation();
	const invoiceLines = useWatch<ExpenseFormLineFields, 'C_InvoiceLines'>({ name: 'C_InvoiceLines' });
	const addInvoiceLine = useWatch<ExpenseFormLineFields, 'addInvoiceLine'>({
		name: 'addInvoiceLine',
	});

	const invoiceLineTotal = [...invoiceLines, addInvoiceLine].reduce(
		(runningTotal, invoiceLine) => runningTotal + (invoiceLine.Price || 0),
		0,
	);

	return (
		<tr>
			<td colSpan={2} className="bh-table--form__data__cell align-middle">
				<span className="float-end me-2">
					<strong>{t(uiText.expense.lineItem.summary.TOTAL)}</strong>
				</span>
			</td>
			<td className="bh-table--form__data__cell">
				<FormatNumberInput
					className="fw-bold"
					value={invoiceLineTotal}
					readOnly={true}
					tabIndex={-1}
					aria-label={t(uiText.expense.lineItem.summary.TOTAL)}
				/>
			</td>
			{shouldDisplayTheDeleteColumn && <td className="bh-table--form__data__cell">&nbsp;</td>}
		</tr>
	);
};

export default ExpenseLineItemTableFooter;
