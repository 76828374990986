import { useWatch } from 'react-hook-form';
import { ADD_PAYMENT_ROW_NAME } from '../components/Visit/PaymentLineItemTable';
import { ADD_ORDER_LINE_ROW_NAME } from '../components/Visit/ProductLineItemTableRow';
import OrderLine from '../models/OrderLine';
import PaymentInformation from '../models/PaymentInformation';

/**
 * This hook is meant to be used on a form where the Visit is the model behind the data. It also requires
 * being used underneath a React Hook Forms Form Context.
 */
export default function useBillingTotals() {
	const visitOrderLines: OrderLine[] | undefined = useWatch({ name: 'orders.0.orderLines' });
	const orderLines: OrderLine[] | undefined = useWatch({ name: 'orderLines' });
	const addOrderLine: OrderLine = useWatch({ name: ADD_ORDER_LINE_ROW_NAME, defaultValue: {} });
	const paymentInformationList: PaymentInformation[] = useWatch({ name: 'paymentInformationList' }) || [];
	const addPaymentInformation: PaymentInformation = useWatch({ name: ADD_PAYMENT_ROW_NAME, defaultValue: {} });

	return {
		orderLineTotal: [...(visitOrderLines || orderLines || []), addOrderLine || {}].reduce(
			(runningTotal, orderLine) => runningTotal + (orderLine.quantity || 0) * (orderLine.price || 0),
			0,
		),
		paymentLineTotal: [...(paymentInformationList || []), addPaymentInformation || {}].reduce(
			(runningTotal, paymentInformation) => runningTotal + (paymentInformation.amount || 0),
			0,
		),
	} as const;
}
