import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';

type ReceiptPrintProps = {
	id: string;
	url: string;
};

const ReceiptPrint = ({ id, url }: ReceiptPrintProps) => {
	const { t } = useTranslation();
	const print = () => {
		const iframe = (document as any).frames ? (document as any).frames[id] : document.getElementById(id);
		if (iframe == null) {
			return false;
		}

		const iframeWindow = iframe.contentWindow || iframe;

		setTimeout(() => {
			iframe.focus();
			iframeWindow.print();
		}, 0);

		return false;
	};

	return <iframe id={id} onLoad={print} style={{ display: 'none' }} src={url} title={t(uiText.receipt.PRINT)} />;
};

export default ReceiptPrint;
