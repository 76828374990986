import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import BaseMetadata from './BaseMetadata';
import ReferenceList, { ReferenceListDto } from './ReferenceList';
import SerialNumberControl, { SerialNumberControlDto } from './SerialNumberControl';

export interface AttributeSetDB extends BaseEntityDB {
	bh_locked: boolean;
}

export interface AttributeSetDto extends BaseEntityDto {
	isSerialNumber?: boolean;
	serialNumberControl?: SerialNumberControlDto | null;
	isLot?: boolean;
	isGuaranteeDate?: boolean;
	guaranteeDays?: number;
	isInstanceAttribute?: boolean;
	mandatoryType?: ReferenceListDto | null;
	isGuaranteeDateMandatory?: boolean;
	isLotMandatory?: boolean;
	isSerialNumberMandatory?: boolean;
	serialNumberStartCharacterOverwrite?: string | null;
	serialNumberEndCharacterOverwrite?: string | null;
	lotStartCharacterOverwrite?: string | null;
	lotEndCharacterOverwrite?: string | null;
	useGuaranteeDateForMaterialPolicy?: boolean;
	isAutoGeneratedLot?: boolean;
	type?: string | null;
	isLocked?: boolean;
}

export default class AttributeSet extends BaseMetadata {
	isSerialNumber: boolean;
	serialNumberControl?: SerialNumberControl;
	isLot: boolean;
	isGuaranteeDate: boolean;
	guaranteeDays: number;
	isInstanceAttribute: boolean;
	mandatoryType?: ReferenceList;
	isGuaranteeDateMandatory: boolean;
	isLotMandatory: boolean;
	isSerialNumberMandatory: boolean;
	serialNumberStartCharacterOverwrite?: string;
	serialNumberEndCharacterOverwrite?: string;
	lotStartCharacterOverwrite?: string;
	lotEndCharacterOverwrite?: string;
	useGuaranteeDateForMaterialPolicy: boolean;
	isAutoGeneratedLot: boolean;
	type?: string;
	isLocked: boolean;

	constructor(props?: DeepPartial<AttributeSet | AttributeSetDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.isSerialNumber = props.isSerialNumber || false;
		this.serialNumberControl = props.serialNumberControl
			? new SerialNumberControl(props.serialNumberControl)
			: undefined;
		this.isLot = props.isLot || false;
		this.isGuaranteeDate = props.isGuaranteeDate || false;
		this.guaranteeDays = props.guaranteeDays || 0;
		this.isInstanceAttribute = props.isInstanceAttribute || false;
		this.mandatoryType = props.mandatoryType ? new ReferenceList(props.mandatoryType) : undefined;
		this.isGuaranteeDateMandatory = props.isGuaranteeDateMandatory || false;
		this.isLotMandatory = props.isLotMandatory || false;
		this.isSerialNumberMandatory = props.isSerialNumberMandatory || false;
		this.serialNumberStartCharacterOverwrite = props.serialNumberStartCharacterOverwrite || undefined;
		this.serialNumberEndCharacterOverwrite = props.serialNumberEndCharacterOverwrite || undefined;
		this.lotStartCharacterOverwrite = props.lotStartCharacterOverwrite || undefined;
		this.lotEndCharacterOverwrite = props.lotEndCharacterOverwrite || undefined;
		this.useGuaranteeDateForMaterialPolicy = props.useGuaranteeDateForMaterialPolicy || false;
		this.isAutoGeneratedLot = props.isAutoGeneratedLot || false;
		this.type = props.type || undefined;
		this.isLocked = props.isLocked !== false;
	}
}
