import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Country, { CountryDto } from './Country';

export interface CityDto extends BaseMetadataDto {
	name: string;
	postal: string;
	areaCode: string;
	country: CountryDto;
}

export interface CityDB extends BaseMetadataDB {}

export default class City extends BaseMetadata {
	name: string;
	postal: string;
	areaCode: string;
	country: Country;

	constructor(props: Partial<CityDto | City> = {}) {
		props = cloneDeep(props);
		super(props);

		this.name = props.name || '';
		this.postal = props.postal || '';
		this.areaCode = props.areaCode || '';
		this.country = new Country(props.country || {});
	}
}
