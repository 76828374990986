import { cloneDeep } from 'lodash';
import BaseEntity, { BaseEntityDto } from './BaseEntity';

export interface AccountDto extends BaseEntityDto {}

export default class Account extends BaseEntity {
	display: string;

	constructor(props?: Partial<Account | AccountDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.display = `${this.value} - ${this.name}`;
	}
}
