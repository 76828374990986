import Account, { AccountDto } from '../models/Account';
import BaseEntityService from './BaseEntityService';

export default class AccountService extends BaseEntityService<Account, AccountDto> {
	entityTableName: string = 'c_elementvalue';
	entityName = 'accounts';

	map(data: AccountDto) {
		return new Account(data);
	}

	reverseMap(data: Account): AccountDto {
		throw new Error('Method not implemented.');
	}
}
