import { createContext } from 'react';

export interface InventoryTransactionContextInterface {
	refresh: () => void;
	areRefreshing?: boolean;
}

const InventoryTransactionContext = createContext<InventoryTransactionContextInterface>(
	{} as InventoryTransactionContextInterface,
);

export default InventoryTransactionContext;
