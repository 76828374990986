import React from 'react';
import { Row } from 'react-bootstrap';

const LayoutHeader = ({ children }: { children: React.ReactNode }) => (
	<Row className="layout__top-bar__pg-header-panel print__d-none" role="banner">
		{children}
	</Row>
);

export default LayoutHeader;
