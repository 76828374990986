import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';

export enum ConceptExtraKey {
	HI_ABSOLUTE = 'hi_absolute',
	HI_NORMAL = 'hi_normal',
	LOCAL_NAME = 'local_name',
	LOW_ABSOLUTE = 'low_absolute',
	LOW_NORMAL = 'low_normal',
	MOH_706 = 'MOH-706',
	UNITS = 'units',
}

export interface ConceptExtraDB extends BaseMetadataDB {
	bh_key: string;
	bh_value: string;
}

export interface ConceptExtraDto extends BaseMetadataDto {
	key?: string;
	value?: string;
}

export default class ConceptExtra extends BaseMetadata {
	key: string;
	value: string;

	constructor(props?: Partial<ConceptExtra | ConceptExtraDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.key = props.key || '';
		this.value = props.value || '';
	}
}
