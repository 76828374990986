import React, { Fragment, useMemo } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync, useAsyncFn } from 'react-use';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useService from '../../hooks/useService';
import { BusinessPartner } from '../../models';
import { ReferenceListDto } from '../../models/ReferenceList';
import { pageUuid } from '../../services/AuthService';
import { uiText } from '../../utils/Language';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import EntityLookup from '../entity-lookup/EntityLookup';
import ViewPatient from './ViewPatient';

type VisitDetailsEditProps = {
	isDataReadOnly: boolean;
	processStageList: ReferenceListDto[];
};

const VisitDetailsEdit = ({ isDataReadOnly, processStageList }: VisitDetailsEditProps) => {
	const { t } = useTranslation();
	const { userService, businessPartnerService } = useService();
	const {
		register,
		formState: { errors },
	} = useFormContext();
	const { disableWrite } = useActionPrivileges(pageUuid.VISITS);

	const patient: BusinessPartner = useWatch({ name: 'patient' });
	const firstVisit = patient.lastVisitDate === '' && patient.totalVisits === 0;
	const { value: clinicians } = useAsync(async () => (await userService.getClinicians()).results, [userService]);
	const referrals = useMemo<ReferenceListDto[]>(() => JSON.parse(localStorage.getItem('referrals') || '[]'), []);
	const patientTypes = useMemo<ReferenceListDto[]>(() => JSON.parse(localStorage.getItem('patientTypes') || '[]'), []);
	const canViewPatient = useActionPrivileges(pageUuid.PATIENTS).canView;

	const [{ value: patientOptions, loading: areLoadingPatients }, onSearchPatient] = useAsyncFn(
		async (query: string) =>
			businessPartnerService
				.get(
					undefined,
					undefined,
					undefined,
					businessPartnerService.getPatientSearchFilter(query).property('isactive').equals(true),
				)
				.then((response) => {
					return response.results;
				}),
		[businessPartnerService],
	);

	return (
		<Card className="bh-card mb-0">
			<Card.Body>
				<Row className="gy-3">
					<Form.Group as={Fragment} controlId="txPatientSearch">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.LABEL)}</Form.Label>
						</Col>
						<Col xs={7} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<EntityLookup
									name="patient"
									rules={{ required: true }}
									isLoading={areLoadingPatients}
									id="txPatientSearch"
									emptyLabel={t(uiText.visit.form.patient.NOT_FOUND)}
									labelKey="name"
									placeholder={t(uiText.visit.form.patient.SEARCH)}
									promptText={t(uiText.visit.form.patient.SEARCHING)}
									searchText={t(uiText.visit.form.patient.SEARCHING)}
									options={areLoadingPatients ? [] : patientOptions || []}
									onSearch={onSearchPatient}
									disabled={isDataReadOnly}
								/>
								{errors?.patient && <span className="text-danger">{t(uiText.visit.form.patient.NAME_REQUIRED)}</span>}
							</fieldset>
						</Col>
					</Form.Group>
					<Col xs={2} className="d-flex align-items-center">
						{canViewPatient && <ViewPatient />}
					</Col>
					<Col xs={2} className="d-flex align-items-center">
						{firstVisit && (
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Group as={Fragment} controlId="checkFirstVisit">
									<Form.Check
										defaultChecked={true}
										{...register('newVisit')}
										label={t(uiText.visit.form.FIRST_VISIT)}
									/>
								</Form.Group>
							</fieldset>
						)}
					</Col>
					<Form.Group as={Fragment} controlId="txProcessStage">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.SEND_PATIENT_TO)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<DynamicSelect {...register('processStage.value')} isLoading={!(processStageList || []).length}>
									<option value="">&nbsp;</option>
									{(processStageList || []).map((processStage) => (
										<option key={processStage.uuid} value={processStage.value || ''}>
											{processStage.name}
										</option>
									))}
								</DynamicSelect>
							</fieldset>
						</Col>
					</Form.Group>
					<Form.Group as={Fragment} controlId="txClinician">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.CLINICIAN)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<DynamicSelect {...register('clinician.uuid')} isLoading={!(clinicians || []).length}>
									<option>&nbsp;</option>
									{(clinicians || []).map((clinician) => (
										<option key={clinician.uuid} value={clinician.uuid}>
											{clinician.name}
										</option>
									))}
								</DynamicSelect>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="txPatientType">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.TYPE)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<DynamicSelect {...register('patientType.value')} isLoading={!(patientTypes || []).length}>
									<option>&nbsp;</option>
									{(patientTypes || []).map((patientType) => (
										<option key={patientType.uuid} value={patientType.value || ''}>
											{patientType.name}
										</option>
									))}
								</DynamicSelect>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="visitDate">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.DATE)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Controller
									name="visitDate"
									render={({ field }) => (
										<BandaDatePicker
											dateFormat="yyyy-MM-dd h:mm aa"
											{...field}
											selected={field.value}
											timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
											showTimeInput
											maxDate={new Date()}
										/>
									)}
								/>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="txReferral">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.REFERRAL)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<DynamicSelect {...register('referral.value')} isLoading={!(referrals || []).length}>
									<option>&nbsp;</option>
									{(referrals || []).map((referral) => (
										<option key={referral.uuid} value={referral.value || ''}>
											{referral.name}
										</option>
									))}
								</DynamicSelect>
							</fieldset>
						</Col>
					</Form.Group>

					<Form.Group as={Fragment} controlId="referredFromTo">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.patient.REFERRED_FROM_TO)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset disabled={disableWrite || isDataReadOnly} className="w-100">
								<Form.Control {...register('referredFromTo')} />
							</fieldset>
						</Col>
					</Form.Group>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default VisitDetailsEdit;
