import { Order, OrderDB, OrderDto } from '../models';
import DocumentService from './DocumentService';

export default class OrderService extends DocumentService<Order, OrderDto, OrderDB> {
	protected entityName: string = 'orders';
	public entityTableName: string = 'c_order';

	map(data: OrderDto): Order {
		return new Order(data);
	}

	reverseMap(data: Order): OrderDto {
		return {
			...data,
			orderLines: (data.orderLines || []).map((orderLine) => ({
				...orderLine,
				product: { ...orderLine.product, storageOnHandList: [], attributeSet: null },
				attributeSetInstance: orderLine.attributeSetInstance
					? {
							...orderLine.attributeSetInstance,
							guaranteeDate: orderLine.attributeSetInstance.guaranteeDate?.getTime(),
							serialNumber: orderLine.attributeSetInstance.serialNumber || null,
							lot: orderLine.attributeSetInstance.lot || null,
						}
					: null,
				charge: { ...orderLine.charge, chargeInformationList: [] },
				price: orderLine.price || 0,
			})),
			dateOrdered: data.dateOrdered.getTime(),
			dateAccount: data.dateAccount.getTime(),
			description: data.description || '',
			businessPartner: data.businessPartner ? { uuid: data.businessPartner.uuid } : null,
		};
	}
}
