import { DocumentActionValue } from './DocAction';
import { DocumentStatusValue } from './DocumentStatus';

export type ProcessTypeValue = (typeof ProcessType)[keyof typeof ProcessType];

/**
 * These are the available process types for entities in the system
 */
const ProcessType = {
	CUSTOMER_INVOICE: 'ARI',
	EXPENSE: 'Expenses',
	VENDOR_INVOICE: 'API',
	BILL: 'Bills',
	RECEIVE_PRODUCT: 'ReceiveProducts',
	PAYMENT: 'Payments',
} as const;

export type StatusActionMap = {
	[P in ProcessTypeValue]: {
		[Status in DocumentStatusValue]: DocumentActionValue[];
	};
};

export default ProcessType;
