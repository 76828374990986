import axios from 'axios';
import { DBFilter, SerialNumberControl, SerialNumberControlDB, SerialNumberControlDto } from '../models';
import { configs } from '../utils/Configs';
import BaseEntityService from './BaseEntityService';

export default class SerialNumberControlService extends BaseEntityService<
	SerialNumberControl,
	SerialNumberControlDto,
	SerialNumberControlDB
> {
	protected entityName: string = 'serial-number-controls';
	public entityTableName: string = 'm_sernoctl';

	public async get() {
		return super.get(
			undefined,
			undefined,
			undefined,
			DBFilter<SerialNumberControlDB>().property('isactive').equals(true),
		);
	}

	public async nextCounter(serialNumberControl: SerialNumberControl): Promise<string> {
		const { data } = await axios.post<string>(`${configs.apiUrl}/${this.entityName}/${serialNumberControl.uuid}/next`);
		serialNumberControl.currentNext =
			parseInt(data.toString().replace('#', ''), 10) + serialNumberControl.incrementNumber;
		// Sometimes data is a number - not sure why that is
		return data.toString();
	}

	map(data: SerialNumberControlDto): SerialNumberControl {
		return new SerialNumberControl(data);
	}

	reverseMap(data: SerialNumberControl): SerialNumberControlDto {
		throw new Error('Method not implemented.');
	}
}
