import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { endOfDay } from 'date-fns';
import { Fragment, useContext, useRef, useState } from 'react';
import { Card, Col, Dropdown, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { Controller, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAsyncFn, useAsyncRetry, useUpdateEffect } from 'react-use';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useService from '../../hooks/useService';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BusinessPartner,
	BusinessPartnerGeneralPayerInformation,
	BusinessPartnerGroupDB,
	businessPartnerGroupName,
	BusinessPartnerPayerInformation,
	DBFilter,
} from '../../models';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { VISITS_PAGE } from '../../utils/Constants';
import { middleOfToday } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { withFormModalSuspsenseWrapper } from '../HOCs/withFormModalSuspsenseWrapper';
import Layout from '../Layout/Layout';
import { setVisitStateForStartingNewVisitWithPatientSelected } from '../Visit/Visits';
import AdditionalInformationForVisit from './AdditionalInformationForVisit';

export type PatientFormProps = EntityFormProperties;

export type PatientFormValues = {
	businessPartnerPayerInformationList?: BusinessPartnerPayerInformation[];
	calculatedIsDateOfBirth: 'true' | 'false' | '';
	submitEvent?: 'saveAndNewVisit' | 'saveAndNew';
} & Omit<BusinessPartner, 'age' | 'isValid' | 'createdDateFormatted'>;

const patientBusinessPartnerGroupFilter = DBFilter<BusinessPartnerGroupDB>()
	.property('name')
	.equals(businessPartnerGroupName.PATIENTS);
const getTitle = (uuid?: string) => (uuid ? uiText.patient.title.UPDATE : uiText.patient.title.NEW);

const PatientForm = ({ uuid, onFinish, renderAsModal, canSaveMany }: PatientFormProps) => {
	const { businessPartnerService, businessPartnerGroupService } = useService();
	const { data } = useSuspenseAsync(uuid || 'add-business-partner', async () =>
		uuid ? businessPartnerService.getByUuid(uuid) : undefined,
	);

	const { t } = useTranslation();
	const { disableWrite, disableDeactivate } = useActionPrivileges(pageUuid.PATIENTS);
	const { toggleNavBlocking } = useContext(NavBlockingContext);
	const history = useHistory();

	const canCreateVisit = useActionPrivileges(pageUuid.VISITS).canWrite;

	const [dataToUse, setDataToUse] = useState<PatientFormValues>(
		data
			? {
					...data,
					calculatedIsDateOfBirth: data.isApproximateDateOfBirth ? 'true' : data.dateOfBirth ? 'false' : '',
				}
			: { ...new BusinessPartner(), calculatedIsDateOfBirth: '' },
	);
	const title = getTitle(dataToUse.isNew ? undefined : dataToUse.uuid);

	const { value: patientBusinessPartnerGroup, retry: fetchPatientBusinessPartnerGroup } = useAsyncRetry(
		async () =>
			(await businessPartnerGroupService.get(undefined, undefined, undefined, patientBusinessPartnerGroupFilter))
				.results[0],
		[businessPartnerGroupService],
	);

	let approximateAge: number[] = [];
	if (dataToUse.dateOfBirth && dataToUse.isApproximateDateOfBirth) {
		approximateAge[0] = (middleOfToday().getMonth() - dataToUse.dateOfBirth.getMonth() + 12) % 12;
		approximateAge[1] =
			middleOfToday().getMonth() < dataToUse.dateOfBirth?.getMonth()
				? middleOfToday().getFullYear() - dataToUse.dateOfBirth.getFullYear() - 1
				: middleOfToday().getFullYear() - dataToUse.dateOfBirth.getFullYear();
	}

	const formMethods = useForm<FieldValues>({
		defaultValues: {
			...dataToUse,
			approxYears: approximateAge[1],
			approxMonths: approximateAge[0],
		},
	});

	const [isDateOfBirthDisabled, setIsDateOfBirthDisabled] = useState(dataToUse.calculatedIsDateOfBirth === 'true');
	const [isApproximateAgeDisabled, setIsApproximateAgeDisabled] = useState(
		dataToUse.calculatedIsDateOfBirth === 'false',
	);

	const isUserInteractingWithApproximateYearsField = useRef(dataToUse.calculatedIsDateOfBirth === 'true');
	const isUserInteractingWithApproximateMonthsField = useRef(dataToUse.calculatedIsDateOfBirth === 'true');
	const isUserInteractingWithDateOfBirthField = useRef(dataToUse.calculatedIsDateOfBirth === 'false');

	const approximateYears: number | undefined | null = formMethods.watch('approxYears');
	const approximateMonths: number | undefined | null = formMethods.watch('approxMonths');
	const dateOfBirth: Date | undefined = formMethods.watch('dateOfBirth');

	const { setValue } = formMethods;

	const dateOfBirthTime = dateOfBirth?.getTime();

	const { onChange: onApproxYearsChange, ...restOfApproximateYearsFields } = formMethods.register('approxYears', {
		valueAsNumber: true,
	});
	const { onChange: onApproxMonthsChange, ...restOfApproximateMonthsFields } = formMethods.register('approxMonths', {
		valueAsNumber: true,
	});

	useUpdateEffect(() => {
		if (
			isUserInteractingWithDateOfBirthField.current &&
			!isUserInteractingWithApproximateYearsField.current &&
			!isUserInteractingWithApproximateMonthsField.current
		) {
			setValue('isApproximateDateOfBirth', false);
			setIsApproximateAgeDisabled(true);
			setIsDateOfBirthDisabled(false);
			setValue('dateOfBirth', dateOfBirthTime ? new Date(dateOfBirthTime) : middleOfToday());
		} else if (
			!isUserInteractingWithDateOfBirthField.current &&
			(isUserInteractingWithApproximateYearsField.current || isUserInteractingWithApproximateMonthsField.current) &&
			(dateOfBirthTime || approximateMonths || approximateYears)
		) {
			setValue('isApproximateDateOfBirth', true);
			setIsDateOfBirthDisabled(true);
			setIsApproximateAgeDisabled(false);

			const yearsToMonths = (approximateYears || 0) * 12;
			const ageInMonths = yearsToMonths + (approximateMonths || 0);
			const dateNow = middleOfToday();
			dateNow.setDate(1);
			const dob = new Date(dateNow.setMonth(dateNow.getMonth() - ageInMonths));
			setValue('dateOfBirth', dob);
		} else {
			setIsDateOfBirthDisabled(false);
			setIsApproximateAgeDisabled(false);
			setValue('isApproximateDateOfBirth', false);
			setValue('dateOfBirth', null);
		}
	}, [setValue, dateOfBirthTime, approximateMonths, approximateYears]);

	useConfirmRefresh(formMethods?.formState?.isDirty === true);
	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const [, onSubmit] = useAsyncFn<SubmitHandler<PatientFormValues>>(
		async (formData) => {
			let businessPartnerGroupToUse = patientBusinessPartnerGroup;
			if (!businessPartnerGroupToUse) {
				try {
					businessPartnerGroupToUse = (
						await businessPartnerGroupService.get(undefined, undefined, undefined, patientBusinessPartnerGroupFilter)
					).results[0];
					fetchPatientBusinessPartnerGroup();
				} catch (error) {
					exception({ description: 'Could not load patients business partner group: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}

			const submitName = formData.submitEvent;
			const patient = new BusinessPartner(formData);
			patient.businessPartnerGroup = businessPartnerGroupToUse;
			const isNewPatient = patient.isNew;
			patient.isCustomer = true;
			patient.isVendor = false;

			const businessPartnerGeneralPayerInformationList = (formData.businessPartnerPayerInformationList || []).map(
				(businessPartnerGeneralPayerInformation) =>
					new BusinessPartnerPayerInformation({
						...businessPartnerGeneralPayerInformation,
						businessPartnerGeneralPayerInformationList: (
							businessPartnerGeneralPayerInformation.businessPartnerGeneralPayerInformationList || []
						).map(
							(businessPartnerChargeInformation) =>
								new BusinessPartnerGeneralPayerInformation(businessPartnerChargeInformation),
						),
					}),
			);

			try {
				const response = await businessPartnerService.save(patient);

				const businessPartnerUuid = response.uuid;
				businessPartnerGeneralPayerInformationList.forEach(
					(businessPartnerCharge) => (businessPartnerCharge.businessPartnerUuid = businessPartnerUuid),
				);

				await businessPartnerService.savePayerInformationList(
					businessPartnerUuid,
					businessPartnerGeneralPayerInformationList,
				);

				toggleNavBlocking(false);
				if (isNewPatient) {
					toast.success(t(uiText.patient.success.REGISTERED));
				}

				switch (submitName) {
					case 'saveAndNew':
						const newData = { ...new BusinessPartner(), calculatedIsDateOfBirth: '' } as PatientFormValues;
						setDataToUse(newData);
						formMethods.reset(newData);
						isUserInteractingWithApproximateYearsField.current = false;
						isUserInteractingWithApproximateMonthsField.current = false;
						isUserInteractingWithDateOfBirthField.current = false;
						dataWasSaved(response.uuid);
						break;
					case 'saveAndNewVisit':
						history.push({
							pathname: VISITS_PAGE,
							state: setVisitStateForStartingNewVisitWithPatientSelected(response),
						});
						break;
					default:
						onFinish(true, canSaveMany === false ? response.uuid : undefined);
						break;
				}
			} catch (error) {}
		},
		[
			businessPartnerService,
			patientBusinessPartnerGroup,
			fetchPatientBusinessPartnerGroup,
			businessPartnerGroupService,
			canSaveMany,
			dataWasSaved,
		],
	);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(onSubmit)} className="px-0" autoComplete="off">
				<Card className="bh-card mx-n2_5 my-0 rounded-0 border-0 px-4">
					<Card.Body hidden={dataToUse.isNew}>
						<Row className="gy-3">
							<input type="hidden" {...formMethods.register('uuid')} defaultValue={dataToUse.uuid} />
							<input type="hidden" {...formMethods.register('isNew')} defaultValue={dataToUse.isNew.toString()} />
							<Form.Group as={Fragment} controlId="patientNumber">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.patientNumber.BANDA)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control defaultValue={dataToUse.patientNumber} readOnly={true} />
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="totalOpenBalance">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.OPEN_BALANCE)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<FormatNumberInput className="text-start" readOnly={true} value={dataToUse.totalOpenBalance} />
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="visitsCount">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.visit.PREVIOUS)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control readOnly={true} defaultValue={dataToUse.totalVisits} />
								</Col>
							</Form.Group>
							<Form.Group as={Fragment} controlId="lastVisitDate">
								<Col xs={1} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.patient.visit.LAST)}</Form.Label>
								</Col>
								<Col xs={5} className="d-flex align-items-center">
									<Form.Control readOnly={true} defaultValue={dataToUse.lastVisitDate} />
								</Col>
							</Form.Group>
						</Row>
					</Card.Body>
				</Card>
				<Tabs defaultActiveKey="generalInfo" className={`pt-2 ${renderAsModal ? 'mx-n3' : 'mx-n2_5'}`}>
					<Tab eventKey="generalInfo" title={t(uiText.patient.tabs.GENERAL_INFO)} className="px-2_5">
						<fieldset disabled={disableWrite}>
							<Card className="bh-card">
								<Card.Body>
									<Row className="gy-3">
										<Form.Group as={Fragment} controlId="name">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.fullName.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.fullName.PLACEHOLDER)}
													{...formMethods.register('name', { required: true })}
												/>
												{formMethods.formState.errors.name && (
													<span className="text-danger">{t(uiText.patient.fullName.VALIDATION)}</span>
												)}
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="gender">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.gender.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Select {...formMethods.register('gender')}>
													<option></option>
													<option value="male">{t(uiText.patient.gender.MALE)}</option>
													<option value="female">{t(uiText.patient.gender.FEMALE)}</option>
												</Form.Select>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="dateOfBirth">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.age.DATE_OF_BIRTH)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Controller
													name="dateOfBirth"
													render={({ field }) => (
														<BandaDatePicker
															disabled={isDateOfBirthDisabled}
															maxDate={endOfDay(new Date())}
															{...field}
															onChange={(selectedDate) => {
																isUserInteractingWithDateOfBirthField.current =
																	selectedDate !== null && !isDateOfBirthDisabled;
																field.onChange(selectedDate);
															}}
															selected={field.value}
														/>
													)}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="patientAge">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.patientAge.YEARS_OR_MONTHS)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													aria-label={t(uiText.patient.patientAge.YEARS)}
													type="number"
													disabled={isApproximateAgeDisabled}
													{...restOfApproximateYearsFields}
													onChange={(event) => {
														isUserInteractingWithApproximateYearsField.current = event.target.value !== '';
														onApproxYearsChange(event);
													}}
												/>
												<label className="mx-1">/</label>
												<Form.Control
													aria-label={t(uiText.patient.patientAge.MONTHS)}
													type="number"
													disabled={isApproximateAgeDisabled}
													{...restOfApproximateMonthsFields}
													onChange={(event) => {
														isUserInteractingWithApproximateMonthsField.current = event.target.value !== '';
														onApproxMonthsChange(event);
													}}
												/>
												<input type="hidden" {...formMethods.register('isApproximateDateOfBirth')} />
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="phone">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.phone.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.phone.PLACEHOLDER)}
													{...formMethods.register('phone')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="address">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.address.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.address.PLACEHOLDER)}
													{...formMethods.register('address')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="isActive">
											<Col xs={{ span: 5, offset: 1 }} className="d-flex align-items-center">
												<Form.Check
													{...formMethods.register('isActive')}
													disabled={disableDeactivate}
													label={t(uiText.patient.button.ACTIVE)}
												/>
											</Col>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>
							<Card className="bh-card">
								<Card.Header className="fw-bold h5">{t(uiText.patient.title.ADDITIONAL_INFO)}</Card.Header>
								<Card.Body>
									<Row className="gy-3">
										<Form.Group as={Fragment} controlId="localPatientNumber">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.patientNumber.LOCAL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.patientNumber.ENTER_LOCAL)}
													{...formMethods.register('localPatientNumber')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="nationalId">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.nationalId.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.nationalId.PLACEHOLDER)}
													{...formMethods.register('nationalId')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="occupation">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.occupation.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.occupation.PLACEHOLDER)}
													{...formMethods.register('occupation')}
												/>
											</Col>
										</Form.Group>
										<Col xs={6} />
										<Form.Group as={Fragment} controlId="nextOfKinName">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.nextOfKin.name.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.nextOfKin.name.PLACEHOLDER)}
													{...formMethods.register('nextOfKinName')}
												/>
											</Col>
										</Form.Group>
										<Form.Group as={Fragment} controlId="nextOfKinContact">
											<Col xs={1} className="d-flex align-items-center">
												<Form.Label column>{t(uiText.patient.nextOfKin.contact.LABEL)}</Form.Label>
											</Col>
											<Col xs={5} className="d-flex align-items-center">
												<Form.Control
													placeholder={t(uiText.patient.nextOfKin.contact.PLACEHOLDER)}
													{...formMethods.register('nextOfKinContact')}
												/>
											</Col>
										</Form.Group>
									</Row>
								</Card.Body>
							</Card>
						</fieldset>
					</Tab>
					<Tab
						eventKey="insurancePaymentInfo"
						title={t(uiText.patient.tabs.INSURANCE_AND_PAYMENT_INFO)}
						className="px-2_5"
					>
						<AdditionalInformationForVisit uuid={dataToUse.uuid} />
					</Tab>
				</Tabs>
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'm-4 ms-3'}`}>
			{disableWrite ? (
				<Col xs="auto">
					<BasicButton
						name={uiText.supplier.button.BACK}
						text={t(uiText.supplier.button.BACK)}
						variant="danger"
						icon="arrow-left"
						active={true}
						onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
					/>
				</Col>
			) : (
				<>
					<Col xs="auto">
						<BasicButton
							name={uiText.patient.button.CANCEL}
							text={t(uiText.patient.button.CANCEL)}
							variant="danger"
							icon="times"
							active={true}
							onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}
						/>
					</Col>
					<Col xs="auto" className="ms-auto">
						<input type="hidden" {...formMethods.register('submitEvent')} defaultValue={''} />
						<BHDropdownButton title={t(uiText.patient.button.SAVE)} variant="success" icon="check">
							<Dropdown.Item onClick={formMethods.handleSubmit(onSubmit)}>
								<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
								{t(uiText.patient.button.SAVE_AND_CLOSE)}
							</Dropdown.Item>
							{canSaveMany !== false ? (
								<Dropdown.Item
									onClick={(e) => {
										formMethods.setValue('submitEvent', 'saveAndNew');
										formMethods.handleSubmit(onSubmit)(e);
									}}
								>
									<FontAwesomeIcon icon="paste" className="me-2 fa-fw" />
									{t(uiText.patient.button.SAVE_AND_NEW_PATIENT)}
								</Dropdown.Item>
							) : null}
							{!renderAsModal && canCreateVisit ? (
								<Dropdown.Item
									onClick={(e) => {
										formMethods.setValue('submitEvent', 'saveAndNewVisit');
										formMethods.handleSubmit(onSubmit)(e);
									}}
								>
									<FontAwesomeIcon icon="file" className="me-2 fa-fw" />
									{t(uiText.patient.button.SAVE_AND_NEW_VISIT)}
								</Dropdown.Item>
							) : null}
						</BHDropdownButton>
					</Col>
				</>
			)}
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{inputs}</Modal.Body>
			<Modal.Footer>
				<div className="w-100">{buttons}</div>
			</Modal.Footer>
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<div className="bg-white pb-0_5 me-n2_5">
					{inputs}
					{buttons}
				</div>
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspsenseWrapper<PatientFormProps>({ loadingLabel: uiText.patient.LOADING, getTitle })(
	PatientForm,
);
