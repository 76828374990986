import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDto } from './BaseMetadata';

const uiLanguageCountrySeparator = '-';
const backendLanguageCountrySeparator = '_';

export const convertLocaleToUI = (locale?: string) => {
	return locale && locale.replace(backendLanguageCountrySeparator, uiLanguageCountrySeparator);
};

export const convertLocaleToBackend = (locale?: string): string => {
	return (locale && locale.replace(uiLanguageCountrySeparator, backendLanguageCountrySeparator)) || '';
};

export interface LanguageDto extends BaseMetadataDto {
	isBaseLanguage?: boolean | null;
	printName?: string | null;
	isSystemLanguage?: boolean | null;
	languageISO?: string | null;
	locale?: string | null;
}

export default class Language extends BaseMetadata {
	isBaseLanguage: boolean;
	isSystemLanguage: boolean;
	printName: string;
	languageISO: string;
	locale: string;

	get localeToSend() {
		return convertLocaleToBackend(this.locale);
	}

	constructor(props: Partial<LanguageDto | Language> = {}) {
		props = cloneDeep(props);
		super(props);

		this.isBaseLanguage = props.isBaseLanguage === true;
		this.isSystemLanguage = props.isSystemLanguage === true;
		this.printName = props.printName || '';
		this.languageISO = props.languageISO || '';
		this.locale = convertLocaleToUI(props.locale || '') || '';
	}
}
