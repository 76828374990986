import { ApolloProvider } from '@apollo/client';
import 'bootstrap'; /*import javascript for bootstrap plugins*/
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './components/App/App';
import Splash from './components/Splash/Splash';
import './environmentSetup';
import getGraphqlClient from './graphqlClient';
import './i18n';
import './scss/base/_base.scss';
import { exception, initialize } from './utils/analytics';
import history from './utils/History';

// Setup Google Analytics before any render
if (
	window._env_.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID &&
	window._env_.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID !== 'your-key-here'
) {
	initialize(window._env_.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
		gaOptions: { anonymizeIp: true, allowAdFeatures: false, allowAdPersonalizationSignals: false },
	});
} else {
	initialize('development', { testMode: true });
}

// Set global error reporting
window.onerror = (msg, url, line, col, error) => {
	let extra = !col ? '' : '\ncolumn: ' + col;
	extra += !error ? '' : '\nerror: ' + error;

	exception({ description: 'Error: ' + msg + '\nurl: ' + url + '\nline: ' + line + extra });
};

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
	exception({ description: e.reason.stack });
	throw new Error(e.reason.stack);
};

ReactDOM.render(
	<ApolloProvider client={getGraphqlClient()}>
		<Suspense fallback={<Splash />}>
			<Router history={history}>
				<App />
			</Router>
		</Suspense>
	</ApolloProvider>,
	document.getElementById('root'),
);

if ((module as any).hot) {
	(module as any).hot.accept();
}
