import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TableRowProps, UseRowSelectState, UseTableRowProps } from 'react-table';
import { toast } from 'react-toastify';
import { useAsync, useAsyncFn } from 'react-use';
import UserContext from '../../contexts/UserContext';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useListPageFunctionality from '../../hooks/useListPageFunctionality';
import useRefreshOnRepeatedRoute from '../../hooks/useRefreshOnRepeatedRoute';
import useService from '../../hooks/useService';
import useStateWithReset from '../../hooks/useStateWithReset';
import useTriggerUpdate from '../../hooks/useTriggerUpdate';
import { BusinessPartner } from '../../models';
import DBFilter, { Filter } from '../../models/DBFilter';
import DocumentStatus from '../../models/DocumentStatus';
import ListPageState from '../../models/ListPageState';
import { processStageUuid } from '../../models/ReferenceList';
import { PATIENT_RECEIPT } from '../../models/Report';
import { roleUuid } from '../../models/Role';
import Visit, { VisitDB } from '../../models/Visit';
import { pageUuid } from '../../services/AuthService';
import { ReportTypes } from '../../services/ReportService';
import { exception } from '../../utils/analytics';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import BHTable from '../BHTable/BHTable';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReceiptPrint from '../Reports/ReceiptPrint';
import StatusBadge from '../StatusBadge/StatusBadge';
import WorkspaceMenu from '../WorkspaceMenu/WorkspaceMenu';
import VisitForm from './VisitForm';

interface UnknownFilter<T> {
	[filterLabel: string]: Filter<T>;
}

export const visitDbProperty = {
	VISIT_DATE: 'bh_visitdate',
} as const;
const availableFilters = {
	onStatus: {
		[uiText.visit.filter.ALL]: IS_ACTIVE as unknown as Filter<VisitDB>,
		[uiText.visit.filter.COMPLETED]: DBFilter<VisitDB>()
			.nested('c_order')
			.property('docStatus')
			.equals(DocumentStatus.COMPLETED)
			.up(),
		[uiText.visit.filter.DRAFTED]: DBFilter<VisitDB>()
			.or(DBFilter<VisitDB>().nested('c_order').property('docStatus').equals(DocumentStatus.DRAFTED).up())
			.or(DBFilter<VisitDB>().nested('c_order').property('docStatus').equals(DocumentStatus.IN_PROGRESS).up()),
		[uiText.visit.filter.VOIDED]: DBFilter<VisitDB>()
			.nested('c_order')
			.property('docStatus')
			.equals(DocumentStatus.VOIDED)
			.up(),
	},
	onPatientType: {
		[uiText.visit.filter.ALL]: IS_ACTIVE as unknown as Filter<VisitDB>,
		[uiText.visit.filter.OPD]: DBFilter<VisitDB>().property('bh_patienttype').equals('O'),
		[uiText.visit.filter.IPD]: DBFilter<VisitDB>().property('bh_patienttype').equals('I'),
	},
	dateBilled: [
		uiText.visit.filter.ALL,
		uiText.visit.filter.TODAY,
		uiText.visit.filter.YESTERDAY,
		uiText.visit.filter.LAST_7_DAYS,
		uiText.visit.filter.LAST_30_DAYS,
	] as const,
	clinicians: {} as UnknownFilter<VisitDB>,
	processStage: {} as UnknownFilter<VisitDB>,
};

export type VisitLocationState = { fromSave?: boolean; patient?: BusinessPartner; toView?: boolean; uuid?: string };

export const setVisitStateForStartingNewVisitWithPatientSelected = (patient: BusinessPartner): VisitLocationState => {
	return { fromSave: true, patient };
};

const Visits = () => {
	const { t } = useTranslation();
	const { disableWrite } = useActionPrivileges(pageUuid.VISITS);
	const { metadataService, reportService, userService, visitService } = useService();
	const { state } = useLocation<VisitLocationState | undefined>();
	const { willTrigger: willTriggerFilters, triggerUpdate: triggerFilterRefresh } = useTriggerUpdate();
	const { role, user } = useContext(UserContext);

	const [searchText, setSearchText] = useState('');
	const {
		areRefreshing,
		data,
		isLoading,
		onFilterUpdate,
		refresh,
		reset,
		selectedUuid,
		tableProps: { onTableUpdate, page, pages, pageSize, pageSizeOptions, rowProperties, sorted, totalRecordCount },
		viewState: [viewState, setViewState],
	} = useListPageFunctionality<Visit, VisitDB>(
		{
			fetch: useCallback((...props) => visitService.get(...props), [visitService]),
			onError: useCallback(
				(error) => {
					if (error.response) {
						toast.error(t(uiText.visit.error.UNABLE_TO_LOAD));
					}
					exception({ description: `Visit fetch error: ${error}` });
				},
				[t],
			),
			refreshSuccessCallback: useCallback(() => toast.success(t(uiText.layout.DATA_REFRESHED)), [t]),
		},
		{
			sorted: [
				{ id: '$date(bh_visitdate)', desc: true },
				{ id: 'bh_visitdate', desc: false },
			],
			viewState: state?.fromSave || state?.toView ? ListPageState.ADD_EDIT : undefined,
			fetchDataInitially: false,
		},
	);

	// Filter states
	const [
		documentStatusFilterText,
		setDocumentStatusFilterText,
		{ reset: resetDocumentStatusFilterText, setNewInitial: setNewInitialDocumentStatusFilterText },
	] = useStateWithReset<keyof typeof availableFilters.onStatus>(uiText.visit.filter.ALL);
	const [patientTypeFilterText, setPatientTypeFilterText, { reset: resetPatientTypeFilterText }] = useStateWithReset<
		keyof typeof availableFilters.onPatientType
	>(uiText.visit.filter.ALL);
	const [dateFilterText, setDateFilterText, { reset: resetDateFilterText }] = useStateWithReset<
		(typeof availableFilters.dateBilled)[number]
	>(uiText.visit.filter.ALL);
	const [
		cliniciansFilterText,
		setCliniciansFilterText,
		{ reset: resetCliniciansFilterText, setNewInitial: setNewInitialCliniciansFilterText },
	] = useStateWithReset<keyof typeof availableFilters.clinicians>(uiText.visit.filter.ALL);
	const [
		processStageFilterText,
		setProcessStageFilterText,
		{ reset: resetProcessStageFilterText, setNewInitial: setNewInitialProcessStageFilterText },
	] = useStateWithReset<keyof typeof availableFilters.processStage>(uiText.visit.filter.ALL);

	const [{ value: countOpenDrafts = 0 }, fetchCountOpenDrafts] = useAsyncFn(
		() => visitService.getCountOpenDrafts(),
		[visitService],
	);

	// Handle searching and filtering
	useEffect(() => {
		fetchCountOpenDrafts();
		const getDateFilter = (): Filter<VisitDB> | null => {
			switch (dateFilterText) {
				case uiText.visit.filter.TODAY:
					return DBFilter<VisitDB>().property(visitDbProperty.VISIT_DATE).equals(new Date());
				case uiText.visit.filter.YESTERDAY:
					const yesterday = new Date();
					yesterday.setDate(yesterday.getDate() - 1);
					return DBFilter<VisitDB>().property(visitDbProperty.VISIT_DATE).equals(yesterday);
				case uiText.visit.filter.LAST_7_DAYS:
					const sevenDaysAgo = new Date();
					sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
					return DBFilter<VisitDB>()
						.property(visitDbProperty.VISIT_DATE)
						.isLessThanOrEqualTo(new Date())
						.property(visitDbProperty.VISIT_DATE)
						.isGreaterThanOrEqualTo(sevenDaysAgo);
				case uiText.visit.filter.LAST_30_DAYS:
					const thirtyDaysAgo = new Date();
					thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
					return DBFilter<VisitDB>()
						.property(visitDbProperty.VISIT_DATE)
						.isLessThanOrEqualTo(new Date())
						.property(visitDbProperty.VISIT_DATE)
						.isGreaterThanOrEqualTo(thirtyDaysAgo);
				default:
					return null;
			}
		};

		const statusFilter = availableFilters.onStatus[documentStatusFilterText];
		const patientTypeFilter = availableFilters.onPatientType[patientTypeFilterText];
		const dateFilter = getDateFilter();
		const cliniciansFilter = availableFilters.clinicians[cliniciansFilterText];
		const processStageFilter = availableFilters.processStage[processStageFilterText];

		let defaultFilter = DBFilter<VisitDB>().and(IS_ACTIVE as unknown as Filter<VisitDB>);
		if (documentStatusFilterText !== uiText.visit.filter.ALL) {
			defaultFilter.and(statusFilter);
		}
		if (patientTypeFilterText !== uiText.visit.filter.ALL) {
			defaultFilter.and(patientTypeFilter);
		}
		if (cliniciansFilterText !== uiText.visit.filter.ALL) {
			defaultFilter.and(cliniciansFilter);
		}
		if (processStageFilterText !== uiText.visit.filter.ALL) {
			defaultFilter.and(processStageFilter);
		}

		if (dateFilter) {
			defaultFilter.and(dateFilter);
		}

		if (searchText) {
			defaultFilter = defaultFilter.or(
				DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('name').contains(searchText).up(),
			);
			defaultFilter = defaultFilter.or(
				DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('BH_PatientID').contains(searchText).up(),
			);
			defaultFilter = defaultFilter.or(
				DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('nationalid').contains(searchText).up(),
			);
			defaultFilter = defaultFilter.or(
				DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('bh_phone').contains(searchText).up(),
			);
			defaultFilter.or(DBFilter<VisitDB>().property('documentno').contains(searchText));
		}

		onFilterUpdate(defaultFilter);
	}, [
		searchText,
		onFilterUpdate,
		documentStatusFilterText,
		patientTypeFilterText,
		dateFilterText,
		cliniciansFilterText,
		processStageFilterText,
		fetchCountOpenDrafts,
		willTriggerFilters,
	]);

	useRefreshOnRepeatedRoute(() => {
		if (viewState !== ListPageState.LIST) {
			setViewState(ListPageState.LIST);
		}
		resetDocumentStatusFilterText();
		resetPatientTypeFilterText();
		resetDateFilterText();
		resetCliniciansFilterText();
		resetProcessStageFilterText();
		triggerFilterRefresh();
		reset(false);
	});

	const { value: clinicians = [] } = useAsync(async () => (await userService.getClinicians()).results, [userService]);
	const { value: processStageList = [] } = useAsync(
		async () => await metadataService.getProcessStageList(),
		[metadataService],
	);

	useEffect(() => {
		availableFilters.clinicians = Object.assign(
			{
				[uiText.visit.filter.ALL]: IS_ACTIVE,
			},
			clinicians.reduce((filterMap, clinician) => {
				filterMap[clinician.name] = DBFilter<VisitDB>()
					.nested('ad_user::bh_clinician_user_id')
					.property('ad_user_uu')
					.equals(clinician.uuid)
					.up();
				return filterMap;
			}, {} as UnknownFilter<VisitDB>),
		);
	}, [clinicians]);
	useEffect(() => {
		availableFilters.processStage = Object.assign(
			{
				[uiText.visit.filter.ALL]: IS_ACTIVE,
			},
			processStageList.reduce((filterMap, process) => {
				filterMap[process.name || ''] = DBFilter<VisitDB>()
					.property('bh_process_stage')
					.equals(process.value || '');
				return filterMap;
			}, {} as UnknownFilter<VisitDB>),
		);
	}, [processStageList]);

	/**
	 * Get preferred visit queue based on assigned roles
	 * WARNING: risk running out of sync with iDempiere (ad_role & ad_ref_list) migrations
	 * this method maps a subset of ad_ref_list values to ad_roles uuids
	 * @returns void, only updates the value of processStageFilter
	 */
	useEffect(() => {
		const includedRoles: string[] = role.includedRoles.map((includedRole) => includedRole.uuid);

		const processStageFilterValueByRole: { [roleUuid: string]: string } = {
			[roleUuid.LAB_RADIOLOGY]: processStageUuid.LAB,
			[roleUuid.CLINICIAN_NURSE_ADVANCED]: processStageUuid.CLINICIAN,
			[roleUuid.CLINICIAN_NURSE_BASIC]: processStageUuid.CLINICIAN,
			[roleUuid.INVENTORY_PHARMACY_ADVANCED]: processStageUuid.PHARMACY,
			[roleUuid.INVENTORY_PHARMACY_BASIC]: processStageUuid.PHARMACY,
			[roleUuid.CASHIER_REGISTRATION_ADVANCED]: processStageUuid.CASHIER,
			[roleUuid.CASHIER_REGISTRATION_BASIC]: processStageUuid.CASHIER,
			[roleUuid.CASHIER_REGISTRATION_BASIC_PLUS]: processStageUuid.CASHIER,
		};
		const preferredRoles = includedRoles.filter((role: string) => role in processStageFilterValueByRole);

		if (preferredRoles.length === 1) {
			const processStageUuidToSelect = processStageFilterValueByRole[preferredRoles[0]];
			const processStageToFilterBy = processStageList.find((item) => item.uuid === processStageUuidToSelect);
			const isClinician = preferredRoles[0] === roleUuid.CLINICIAN_NURSE_BASIC;

			setNewInitialProcessStageFilterText(processStageToFilterBy?.name || uiText.visit.filter.ALL);
			setProcessStageFilterText(processStageToFilterBy?.name || uiText.visit.filter.ALL);
			setNewInitialCliniciansFilterText(isClinician ? user.name : uiText.visit.filter.ALL);
			setCliniciansFilterText(isClinician ? user.name : uiText.visit.filter.ALL);
			setNewInitialDocumentStatusFilterText(uiText.visit.filter.DRAFTED);
			setDocumentStatusFilterText(uiText.visit.filter.DRAFTED);
		}
	}, [
		processStageList,
		setCliniciansFilterText,
		setDocumentStatusFilterText,
		setNewInitialCliniciansFilterText,
		setNewInitialDocumentStatusFilterText,
		setNewInitialProcessStageFilterText,
		setProcessStageFilterText,
		user,
		role,
	]);

	const fetchOpenDrafts = () => {
		// Update the filters
		setDocumentStatusFilterText(uiText.visit.filter.DRAFTED);
		setPatientTypeFilterText(uiText.visit.filter.ALL);
		setDateFilterText(uiText.visit.filter.ALL);
		setCliniciansFilterText(uiText.visit.filter.ALL);
		setProcessStageFilterText(uiText.visit.filter.ALL);
	};

	const [{ loading: isGettingReceipt, value: printReceiptUrl = '' }, generateReceipt] = useAsyncFn(
		async (uuid: string) =>
			URL.createObjectURL(
				new Blob([await reportService.generateReportWithGivenParameterValue(PATIENT_RECEIPT, uuid, ReportTypes.PDF)], {
					type: 'application/pdf',
				}),
			),
		[reportService],
	);

	const rowPropertiesWithGenerateClick = (
		state?: UseRowSelectState<Visit>,
		rowInfo?: UseTableRowProps<Visit>,
	): TableRowProps | object => {
		if (!rowInfo) {
			return {};
		}
		return {
			onClick: (e: React.MouseEvent<HTMLTableRowElement | HTMLButtonElement>) => {
				if (e.target instanceof HTMLButtonElement && e.target.name === 'receipt') {
					generateReceipt(rowInfo.original.uuid);
				} else {
					const properties = rowProperties(state, rowInfo);
					properties.onClick && properties.onClick(e as React.MouseEvent<HTMLTableRowElement>);
				}
			},
		};
	};

	return (
		<Layout>
			{viewState === ListPageState.LIST ? (
				<>
					<Layout.Header>
						<Layout.Title
							title={t(uiText.visit.LIST)}
							showRefreshIcon={true}
							areRefreshing={areRefreshing}
							onRefresh={() => {
								fetchCountOpenDrafts();
								refresh({ resetPage: true });
							}}
						/>
						<Layout.Notification
							title={t(uiText.visit.OPEN_DRAFTS_MESSAGE)}
							count={countOpenDrafts}
							listener={fetchOpenDrafts}
						/>
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<WorkspaceMenu>
							<WorkspaceMenu.Search onSearch={setSearchText} />
							<WorkspaceMenu.Filters>
								<Form.Group controlId="billingDate">
									<Form.Label column>{t(uiText.visit.DATE)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={dateFilterText}
										onChange={(e) => setDateFilterText(e.target.value as (typeof availableFilters.dateBilled)[number])}
									>
										{availableFilters.dateBilled.map((filter) => (
											<option key={filter} value={filter}>
												{t(filter)}
											</option>
										))}
									</Form.Select>
								</Form.Group>
								<Form.Group controlId="status">
									<Form.Label column>{t(uiText.visit.STATUS)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={documentStatusFilterText}
										onChange={(e) =>
											setDocumentStatusFilterText(e.target.value as keyof typeof availableFilters.onStatus)
										}
									>
										{Object.entries(availableFilters.onStatus).map(([filter]) => (
											<option key={filter} value={filter}>
												{t(filter)}
											</option>
										))}
									</Form.Select>
								</Form.Group>
								<Form.Group controlId="clinician">
									<Form.Label column>{t(uiText.visit.CLINICIAN)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={cliniciansFilterText}
										onChange={(e) => setCliniciansFilterText(e.target.value)}
									>
										<option value={uiText.visit.filter.ALL}>{t(uiText.visit.filter.ALL)}</option>
										{Object.entries(availableFilters.clinicians)
											.filter(([clinicianFilter]) => clinicianFilter !== uiText.visit.filter.ALL)
											.map(([filter]) => (
												<option key={filter} value={filter}>
													{filter}
												</option>
											))}
									</Form.Select>
								</Form.Group>
								<Form.Group controlId="sentTo">
									<Form.Label column>{t(uiText.visit.form.patient.SENT_TO)}</Form.Label>
									<Form.Select
										className="ms-2 w-auto d-inline-block"
										value={processStageFilterText}
										onChange={(e) => setProcessStageFilterText(e.target.value)}
									>
										<option value={uiText.visit.filter.ALL}>{t(uiText.visit.filter.ALL)}</option>
										{Object.entries(availableFilters.processStage)
											.filter(([processStageFilter]) => processStageFilter !== uiText.visit.filter.ALL)
											.map(([filter]) => (
												<option key={filter} value={filter}>
													{filter}
												</option>
											))}
									</Form.Select>
								</Form.Group>
							</WorkspaceMenu.Filters>
							{!disableWrite && <WorkspaceMenu.NewButton onClick={() => setViewState(ListPageState.ADD_EDIT)} />}
						</WorkspaceMenu>
						<Row className="bg-white ms-0">
							{printReceiptUrl !== '' ? <ReceiptPrint id={'document'} url={printReceiptUrl} /> : ''}

							<BHTable<Visit, VisitDB>
								data={data}
								columns={[
									{
										id: visitDbProperty.VISIT_DATE,
										Header: () => t(uiText.visit.DATE_BILLED),
										accessor: (d) => new Visit(d).visitDateFormatted,
										sortDescFirst: true,
										sortType: 'datetime',
									},
									{
										id: 'C_BPartner.name',
										Header: () => t(uiText.visit.PATIENT_NAME),
										accessor: (d) => d.patient.name,
										disableSortBy: true,
									},
									{
										id: 'bh_patienttype',
										Header: () => t(uiText.visit.PATIENT_TYPE),
										accessor: (d) => d.patientType.name,
										disableSortBy: true,
									},
									{
										id: 'grandTotal',
										Header: () => t(uiText.visit.BILL_TOTAL),
										accessor: (d) => <div className="text-end w-75">{formatNumber(d.orders[0]?.grandTotal)}</div>,
										disableSortBy: true,
									},
									{
										id: 'docStatus',
										Header: () => t(uiText.visit.BILL_STATUS),
										accessor: (d) => <StatusBadge status={d.orders[0]?.documentStatus} />,
										disableSortBy: true,
									},
								]}
								defaultPageSize={pageSize}
								pages={pages}
								page={page}
								pageSizeOptions={pageSizeOptions}
								LoadingComponent={() => {
									return (
										<LoadSpinner
											show={isLoading || isGettingReceipt}
											title={isLoading ? t(uiText.visit.FETCHING) : t(uiText.visit.GENERATING_RECEIPT)}
										/>
									);
								}}
								onFetchData={onTableUpdate}
								getTrGroupProps={rowPropertiesWithGenerateClick}
								sortBy={sorted}
								totalRecordCount={totalRecordCount}
							/>
						</Row>
					</Layout.Body>
				</>
			) : (
				<VisitForm
					uuid={state?.uuid || selectedUuid}
					savedPatient={state?.patient}
					onFinish={() => {
						setViewState(ListPageState.LIST);
						refresh();
					}}
				/>
			)}
		</Layout>
	);
};

export default Visits;
