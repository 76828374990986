import { createContext, MutableRefObject } from 'react';

export interface FormModalContextInterface {
	dataWasSaved: (uuid?: string) => void;
	wasDataSaved: boolean;
	savedData?: string;
	// Since the visit form is finicky, we'll expose a reference just for it
	visitFormInformation: MutableRefObject<{ wasDataSaved: boolean; savedData?: string }>;
}

const FormModalContext = createContext<FormModalContextInterface>({
	dataWasSaved: () => {},
	wasDataSaved: false,
	visitFormInformation: { current: { wasDataSaved: false } },
});

export default FormModalContext;
