import Inventory, { InventoryDto } from '../models/Inventory';
import DocumentService from './DocumentService';

export default class InventoryService extends DocumentService<Inventory, InventoryDto> {
	protected entityName: string = 'inventory';
	public entityTableName: string = 'm_inventory';

	map(data: InventoryDto): Inventory {
		return new Inventory(data);
	}

	reverseMap(data: Inventory): InventoryDto {
		return {
			...data,
			inventoryLines: (data.inventoryLines || []).map((inventoryLine) => ({
				...inventoryLine,
				product: {
					...inventoryLine.product,
					storageOnHandList: [],
					attributeSet: null,
				},
				attributeSetInstance: {
					...inventoryLine.attributeSetInstance,
					guaranteeDate: undefined,
					serialNumber: null,
					lot: null,
				},
			})),
		};
	}
}
