import { differenceInDays } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import UserContext from '../../contexts/UserContext';
import useService from '../../hooks/useService';
import { StorageOnHand, StorageOnHandDB } from '../../models';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { formatNumber } from '../../utils/NumberUtil';
import { BHTableProps } from '../BHTable/BHTable';
import Layout from '../Layout/Layout';
import InventoryList from '../inventory-list/InventoryList';
import InventoryTransactionContext from '../../contexts/InventoryTransactionContext';

const StockTake = () => {
	const { t } = useTranslation();
	const { warehouseService } = useService();
	const { refresh, areRefreshing } = useContext(InventoryTransactionContext);
	const { organization } = useContext(UserContext);

	const { value: warehouses = [] } = useAsync(
		async () => (await warehouseService.getWarehouseByOrg(organization.uuid)).results,
		[warehouseService, organization],
	);
	const columns = useMemo(() => {
		let columns = [
			{
				id: 'm_product.name',
				Header: () => <div className={'React-table-header'}>{t(uiText.product.name.byOthers.LABEL)}</div>,
				accessor: (d: StorageOnHand) => d.product.name,
			},
			{
				id: 'qtyonhand',
				Header: () => <div className={'React-table-header'}>{t(uiText.product.quantity.LABEL)}</div>,
				accessor: (d: StorageOnHand) => d.quantityOnHand,
			},
		] as unknown as BHTableProps<StorageOnHand, StorageOnHandDB>['columns'];
		if (warehouses.length > 1) {
			columns.push(
				(
					[
						{
							id: 'm_locator.m_warehouse.name',
							disableSortBy: true,
							Header: () => <div className={'React-table-header'}>{t(uiText.inventory.STOREROOM)}</div>,
							accessor: (d: StorageOnHand) => d.locator.warehouse.name,
						},
					] as unknown as BHTableProps<StorageOnHand, StorageOnHandDB>['columns']
				)[0],
			);
		}
		columns = [
			...columns,
			...([
				{
					id: 'm_attributesetinstance.guaranteedate',
					Header: () => (
						<div className={'React-table-header'}>{t(uiText.product.expirationDate.LABEL_ABBREVIATION)}</div>
					),
					accessor: (d: StorageOnHand) => (
						<div className="d-flex justify-content-center">
							{!d.attributeSetInstance.guaranteeDate ? '' : formatDate(d.attributeSetInstance.guaranteeDate)}
						</div>
					),
				},
				{
					id: 'shelflife',
					// disableSortBy: true,
					Header: () => (
						<div className={'React-table-header'}>{t(uiText.product.expirationDate.DAYS_TO_EXPIRATION)}</div>
					),
					accessor: (d: StorageOnHand) => (
						<div className="d-flex justify-content-center">
							{!d.attributeSetInstance.guaranteeDate
								? ''
								: differenceInDays(d.attributeSetInstance.guaranteeDate, new Date())}
						</div>
					),
				},
				{
					id: 'purchaseDate',
					disableSortBy: true,
					Header: () => <div className={'React-table-header'}>{t(uiText.product.RECEIVED_ON)}</div>,
					accessor: (d: StorageOnHand) =>
						(d.attributeSetInstance.purchaseDate && formatDate(new Date(d.attributeSetInstance.purchaseDate))) || '',
				},
				{
					id: 'price',
					disableSortBy: true,
					Header: () => <div className={'React-table-header'}>{t(uiText.product.BUY_PRICE)}</div>,
					accessor: (d: StorageOnHand) => formatNumber(d.attributeSetInstance.purchasePrice),
				},
			] as unknown as BHTableProps<StorageOnHand, StorageOnHandDB>['columns']),
		];
		return columns;
	}, [t, warehouses]);

	return (
		<Layout>
			<Layout.Header>
				<Layout.Title
					title={t(uiText.inventory.title.LIST)}
					showRefreshIcon
					onRefresh={() => refresh()}
					areRefreshing={areRefreshing || false}
				/>
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<InventoryList columns={columns} showSearch={true} showCleanUpButton={true} />
			</Layout.Body>
		</Layout>
	);
};

export default StockTake;
