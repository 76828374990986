import { useCallback, useRef, useState } from 'react';

/**
 * A state function that also allows to reset state to the initial value passed in
 * @param initialState Initial state, just as you'd pass to {@link React.useState}
 * @returns The same stuff as from {@link React.useState}, but with a reset function to reset the state back to
 * the first thing that was passed in
 */
export default function useStateWithReset<S>(initialState: S | (() => S)) {
	const [state, setState] = useState(initialState);
	const memoizedInitialState = useRef(initialState);
	const reset = useCallback(() => {
		setState(memoizedInitialState.current);
	}, [setState]);
	const setNewInitial = useCallback((newInitialState: S | (() => S)) => {
		memoizedInitialState.current = newInitialState;
	}, []);
	return [state, setState, { reset, setNewInitial } as const] as const;
}
