import ProductCategory, { ProductCategoryDto } from '../models/ProductCategory';
import { configs } from '../utils/Configs';
import { PRODUCT_CATEGORIES } from '../utils/Constants';
import NewMetadataService from './NewMetadataService';

export default class ProductCategoryService extends NewMetadataService<ProductCategory, ProductCategoryDto> {
	getUrl = `${configs.apiUrl}/product-categories`;
	localStorageKey = PRODUCT_CATEGORIES;

	map(data: ProductCategoryDto) {
		return new ProductCategory(data);
	}

	reverseMap(data: ProductCategory): ProductCategoryDto {
		throw new Error('Method not implemented.');
	}
}
