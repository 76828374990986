import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BusinessPartner } from '../../models';
import { uiText } from '../../utils/Language';
import { getAgeDisplay } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';

const VisitInfo = () => {
	const { t } = useTranslation();
	const patient = new BusinessPartner(useWatch({ name: 'patient' }));
	return (
		<div className="info-ribbon sticky-top print__position-relative">
			{patient.uuid && !patient.isNew && (
				<Row>
					<Col xs={12}>
						<span className="info-ribbon__entity-prop font-size">
							<strong>{patient.name}</strong>
						</span>
						<span>
							(
							<span className="info-ribbon__entity-prop">
								<strong>{t(uiText.visit.info.PATIENT_NUMBER_ON_RIBBON)}</strong>
							</span>{' '}
							{patient.patientNumber || '-'})
						</span>
						<span> - {getAgeDisplay(patient)}</span>
					</Col>
					<Col>
						<span className="info-ribbon__entity-prop">
							<strong>{t(uiText.visit.info.TOTAL_VISITS)}</strong>
						</span>
						<span> {patient.totalVisits || '-'}</span>
					</Col>
					<Col>
						<span className="info-ribbon__entity-prop">
							<strong>{t(uiText.visit.info.LAST_VISIT)}</strong>
						</span>
						<span> {patient.lastVisitDate || '-'}</span>
					</Col>
					<Col>
						<span className="info-ribbon__entity-prop">
							<strong>{t(uiText.visit.info.OPEN_BALANCE)}</strong>
						</span>
						<span> {patient.totalOpenBalance !== undefined ? formatNumber(patient.totalOpenBalance) : '-'}</span>
					</Col>
				</Row>
			)}
		</div>
	);
};

export default VisitInfo;
