import { cloneDeep } from 'lodash';
import DeepPartial from '../types/DeepPartial';
import AttributeSetInstance, { AttributeSetInstanceDto } from './AttributeSetInstance';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Charge, { ChargeDto } from './Charge';
import Product, { ProductDB, ProductDto } from './Product';

export interface OrderLineDB extends BaseMetadataDB {
	lineNetAmt: number;
	m_product: ProductDB;
}

export interface OrderLineDto extends BaseMetadataDto {
	price?: number | null;
	quantity?: number | null;
	product?: ProductDto | null;
	lineNetAmount?: number | null;
	instructions?: string | null;
	charge?: ChargeDto | null;
	description?: string | null;
	attributeSetInstance?: AttributeSetInstanceDto | null;
}

/**
 * The class to handle order line information
 * @property {string} description The description
 * @property {Product} product The product for this order line, if any
 * @property {number} price The price for this item
 * @property {number} quantity How much of this item is being ordered
 * @property {number} lineNetAmount The net amount for this line
 * @property {String} expiration The expiration date for this order line
 * @property {String} instructions Any instructions that should be included in this line item
 * @property {Charge} charge The charge for this order line, if any
 */
export default class OrderLine extends BaseMetadata {
	description: string;
	product: Product;
	price?: number;
	quantity: number;
	lineNetAmount: number;
	instructions: string;
	charge: Charge;
	attributeSetInstance?: AttributeSetInstance;

	/**
	 * The default constructor for this class
	 * @param {OrderLine} entity The model to pull information from
	 */
	constructor(entity?: DeepPartial<OrderLine | OrderLineDto>) {
		entity = cloneDeep(entity || {});
		super(entity);

		this.description = entity.description || '';
		this.product = new Product(entity.product || {});
		this.price = entity.price || 0;
		this.quantity = entity.quantity || 0;
		this.lineNetAmount = entity.lineNetAmount || 0;
		this.instructions = entity.instructions || '';
		this.charge = new Charge(entity.charge || {});
		this.attributeSetInstance = entity.attributeSetInstance
			? new AttributeSetInstance(entity.attributeSetInstance)
			: undefined;
	}
}
