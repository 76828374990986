import AccountService from '../services/AccountService';
import AttributeSetInstanceService from '../services/AttributeSetInstanceService';
import AttributeSetService from '../services/AttributeSetService';
import AuthenticationService from '../services/AuthenticationService';
import AuthService from '../services/AuthService';
import BusinessPartnerGroupService from '../services/BusinessPartnerGroupService';
import BusinessPartnerService from '../services/BusinessPartnerService';
import ChargeService from '../services/ChargeService';
import ChargeTypeService from '../services/ChargeTypeService';
import ClientService from '../services/ClientService';
import CodedDiagnosisService from '../services/CodedDiagnosisService';
import ConceptService from '../services/ConceptService';
import DashboardService from '../services/DashboardService';
import DocumentStatusService from '../services/DocumentStatusService';
import DocumentTypeService from '../services/DocumentTypeService';
import EncounterService from '../services/EncounterService';
import EncounterTypeWindowService from '../services/EncounterTypeWindowService';
import InventoryService from '../services/InventoryService';
import InvoiceService from '../services/InvoiceService';
import LanguageService from '../services/LanguageService';
import MenuService from '../services/MenuService';
import MetadataService from '../services/MetadataService';
import MovementService from '../services/MovementService';
import OrderService from '../services/OrderService';
import OrganizationService from '../services/OrganizationService';
import PayerInformationFieldSuggestionService from '../services/PayerInformationFieldSuggestionService';
import PaymentService from '../services/PaymentService';
import ProcessTypeService from '../services/ProcessTypeService';
import ProductCategoryService from '../services/ProductCategoryService';
import ProductCategoryTypeService from '../services/ProductCategoryTypeService';
import ProductService from '../services/ProductService';
import ReferenceListService from '../services/ReferenceListService';
import ReportService from '../services/ReportService';
import RoleService from '../services/RoleService';
import SerialNumberControlService from '../services/SerialNumberControlService';
import ServiceService from '../services/ServiceService';
import StorageOnHandService from '../services/StorageOnHandService';
import TransactionService from '../services/TransactionService';
import UserService from '../services/UserService';
import VisitService from '../services/VisitService';
import VoidedReasonService from '../services/VoidedReasonService';
import WarehouseService from '../services/WarehouseService';
import InventoryTransactionService from '../services/InventoryTransactionService';

// These must be instantiated here so that any hooks with these as dependencies doesn't continually run
const service = {
	accountService: new AccountService(),
	attributeSetInstanceService: new AttributeSetInstanceService(),
	attributeSetService: new AttributeSetService(),
	authService: new AuthService(),
	authenticationService: new AuthenticationService(),
	businessPartnerGroupService: new BusinessPartnerGroupService(),
	businessPartnerService: new BusinessPartnerService(),
	chargeService: new ChargeService(),
	chargeTypeService: new ChargeTypeService(),
	clientService: new ClientService(),
	codedDiagnosisService: new CodedDiagnosisService(),
	conceptService: new ConceptService(),
	dashboardService: new DashboardService(),
	documentStatusService: new DocumentStatusService(),
	documentTypeService: new DocumentTypeService(),
	encounterService: new EncounterService(),
	encounterTypeWindowService: new EncounterTypeWindowService(),
	inventoryService: new InventoryService(),
	inventoryTransactionService: new InventoryTransactionService(),
	invoiceService: new InvoiceService(),
	languageService: new LanguageService(),
	menuService: new MenuService(),
	metadataService: new MetadataService(),
	movementService: new MovementService(),
	orderService: new OrderService(),
	organizationService: new OrganizationService(),
	payerInformationFieldSuggestionService: new PayerInformationFieldSuggestionService(),
	paymentService: new PaymentService(),
	processTypeService: new ProcessTypeService(),
	productCategoryService: new ProductCategoryService(),
	productCategoryTypeService: new ProductCategoryTypeService(),
	productService: new ProductService(),
	referenceListService: new ReferenceListService(),
	reportService: new ReportService(),
	roleService: new RoleService(),
	serialNumberControlService: new SerialNumberControlService(),
	serviceService: new ServiceService(),
	storageOnHandService: new StorageOnHandService(),
	transactionService: new TransactionService(),
	userService: new UserService(),
	visitService: new VisitService(),
	voidedReasonService: new VoidedReasonService(),
	warehouseService: new WarehouseService(),
} as const;

/**
 * This hook returns services to components so they don't have to be responsible for instantiating them
 * @returns An object of services
 */
export default function useService() {
	return { ...service } as const;
}
