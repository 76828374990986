import Charge, { ChargeDB, ChargeDto } from '../models/Charge';
import BaseEntityService from './BaseEntityService';

export default class ChargeService extends BaseEntityService<Charge, ChargeDto, ChargeDB> {
	public entityTableName: string = 'c_charge';
	entityName = 'charges';

	map(data: ChargeDto) {
		return new Charge(data);
	}

	reverseMap(data: Charge): ChargeDto {
		return data;
	}
}
