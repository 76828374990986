import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	M_MovementForEditingQuery,
	M_MovementSaveWithMovementLinesMutationVariables,
} from '../../graphql/__generated__/graphql';
import { uiText } from '../../utils/Language';
import { MovementFormFields } from './TransferInventoryForm';
import TransferInventoryLineItemTableRow from './TransferInventoryLineItemTableRow';

export type MovementFormMovementLineFields = {
	M_MovementLines: Array<{
		UU: string;
		M_AttributeSetInstance: {
			UU: string;
		};
		M_Product: {
			UU: string;
		};
		MovementQty: number | null;
	}>;
	addNewMovementLine: {
		M_Product: {
			UU: string;
		};
		MovementQty: number | null;
	};
	movementLineCount: number;
	areProductsWithAttributeSetInstancesDuplicated: number;
};

export const convertToFormMovementLineFields: (
	initialData?: M_MovementForEditingQuery['M_Movement'],
) => MovementFormMovementLineFields = (initialData) => {
	let formFields: Pick<MovementFormMovementLineFields, 'addNewMovementLine'> = {
		addNewMovementLine: { M_Product: { UU: '' }, MovementQty: null },
	};
	if (!initialData) {
		return {
			...formFields,
			M_MovementLines: [],
			movementLineCount: 0,
			areProductsWithAttributeSetInstancesDuplicated: 1,
		};
	}
	return {
		...formFields,
		M_MovementLines:
			initialData.M_MovementLines?.map((movementLine) => ({
				UU: movementLine.UU,
				M_AttributeSetInstance: { UU: movementLine.M_AttributeSetInstance?.UU || '' },
				M_Product: { UU: movementLine.M_Product?.UU || '' },
				MovementQty: movementLine.MovementQty,
			})) || [],
		movementLineCount: initialData.M_MovementLines?.length || 0,
		areProductsWithAttributeSetInstancesDuplicated: 1,
	};
};

export const constructMovementLinesSubmissionObject = (
	formData: MovementFormMovementLineFields,
	movementUU: string,
	fromLocatorUU?: string,
	toLocatorUU?: string,
) => {
	const movementLines: M_MovementSaveWithMovementLinesMutationVariables['M_MovementLines'] =
		formData.M_MovementLines.map((movementLine) => ({
			UU: movementLine.UU,
			M_Movement: { UU: movementUU },
			MovementQty: movementLine.MovementQty,
			M_Product: (movementLine.M_Product?.UU && { UU: movementLine.M_Product.UU }) || undefined,
			M_Locator: (fromLocatorUU && { UU: fromLocatorUU }) || undefined,
			M_LocatorTo: (toLocatorUU && { UU: toLocatorUU }) || undefined,
			M_AttributeSetInstance:
				(movementLine.M_AttributeSetInstance?.UU && { UU: movementLine.M_AttributeSetInstance.UU }) || undefined,
		}));
	return movementLines;
};

type ProductLineTransferInventoryTableProps = {
	readOnly: boolean;
};

const ProductLineTransferInventoryTable = ({ readOnly }: ProductLineTransferInventoryTableProps) => {
	const { t } = useTranslation();
	const {
		register,
		setFocus,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<MovementFormFields>();
	const { fields, append, remove } = useFieldArray<MovementFormMovementLineFields, 'M_MovementLines', 'UU'>({
		name: 'M_MovementLines',
		keyName: 'UU',
	});
	const addNewMovementLine = useWatch<MovementFormMovementLineFields, 'addNewMovementLine'>({
		name: 'addNewMovementLine',
	});
	const shouldDisplayTheDeleteColumn = !readOnly;
	const shouldFocusOnLastField = useRef(false);

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewMovementLine.M_Product.UU) {
			append({
				UU: v4(),
				M_Product: { UU: addNewMovementLine.M_Product.UU },
				M_AttributeSetInstance: { UU: '' },
				MovementQty: null,
			});
			// Reset movement quantity field
			setValue('addNewMovementLine', { M_Product: { UU: '' }, MovementQty: null });
			shouldFocusOnLastField.current = true;
		}
	}, [addNewMovementLine, fields.length, append, setValue]);
	// If there are fields and we should focus on the last one, do so
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField.current) {
			setFocus(`M_MovementLines.${fields.length - 1}.MovementQty` as 'M_MovementLines.0.MovementQty');
			shouldFocusOnLastField.current = false;
		}
	}, [fields.length, setFocus, shouldFocusOnLastField]);
	useEffect(() => {
		setValue('movementLineCount', fields.length, { shouldValidate: true });
	}, [fields.length, setValue]);

	return (
		<>
			<input
				type="hidden"
				{...register('movementLineCount', {
					valueAsNumber: true,
					validate: (value) => (getValues('submitEvent') === 'complete' ? value > 0 : true),
				})}
				defaultValue={fields.length}
			/>
			<input
				type="hidden"
				{...register('areProductsWithAttributeSetInstancesDuplicated', {
					valueAsNumber: true,
					validate: (value) => value === 0,
				})}
				defaultValue={0}
			/>
			<table className="bh-table--form">
				<thead>
					<tr>
						<th className="data-type-text">{t(uiText.transferInventory.labels.PRODUCT)}</th>
						<th className="data-type-text">{t(uiText.transferInventory.labels.BATCH)}</th>
						<th className="data-type-numeric">{t(uiText.transferInventory.labels.EXISTING_QUANTITY_IN_SOURCE)}</th>
						<th className="data-type-numeric">{t(uiText.transferInventory.labels.EXISTING_QUANTITY_IN_DESTINATION)}</th>
						<th className="data-type-numeric">{t(uiText.transferInventory.labels.QUANTITY_TO_TRANSFER)}</th>
						{shouldDisplayTheDeleteColumn && <th className="w-6">{t(uiText.transferInventory.button.DELETE)}</th>}
					</tr>
				</thead>
				<tbody>
					{fields?.map((movementLine, index) => (
						<TransferInventoryLineItemTableRow
							key={movementLine.UU}
							field={movementLine}
							index={index}
							remove={remove}
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							readOnly={readOnly}
						/>
					))}
				</tbody>
				<tbody>
					{!readOnly && (
						<TransferInventoryLineItemTableRow
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isAddRow={true}
						/>
					)}
				</tbody>
			</table>
			{errors.M_MovementLines?.some((movementLineError) => !!movementLineError?.MovementQty) && (
				<div className="text-danger">{t(uiText.transferInventory.error.MISSING_MOVEMENT_QUANTITY)}</div>
			)}
			{errors.M_MovementLines?.some((movementLineError) => !!movementLineError?.M_AttributeSetInstance?.UU) && (
				<div className="text-danger">{t(uiText.transferInventory.error.MISSING_EXPIRATION)}</div>
			)}
			{errors.movementLineCount && (
				<div className="text-danger">{t(uiText.transferInventory.error.MISSING_PRODUCT)}</div>
			)}
			{errors.areProductsWithAttributeSetInstancesDuplicated && (
				<div className="text-danger">{t(uiText.transferInventory.error.DUPLICATE_PRODUCT)}</div>
			)}
		</>
	);
};

export default ProductLineTransferInventoryTable;
