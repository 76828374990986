import { DEFAULT_PAGE_SIZE } from '../utils/Constants';

/**
 * The Paging class to help with results pagination of queries
 * @property {int} page The zero-indexed page of the results to display
 * @property {int} size The size of the result set
 */
export default class Paging {
	page: number;
	size: number;

	/**
	 * Get a paging object meant to fetch all results
	 * @type {Paging}
	 */
	static get ALL() {
		return new Paging({ page: 0, size: 1000 });
	}

	/**
	 * Get a paging object meant to fetch most results
	 * @type {Paging}
	 */
	static get MOST() {
		return new Paging({ page: 0, size: 100 });
	}

	constructor(props: Partial<Paging> = {}) {
		this.page = props.page || 0;
		this.size = props.size || DEFAULT_PAGE_SIZE;
	}
}
