import React, { lazy, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Prompt, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-use';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext, { UserContextInterface } from '../../contexts/UserContext';
import useService from '../../hooks/useService';
import { Menu } from '../../models';
import { WindowAccessLevel } from '../../models/AuthorizationResponse';
import Client, { ClientDto } from '../../models/Client';
import Organization from '../../models/Organization';
import Role from '../../models/Role';
import User from '../../models/User';
import Warehouse from '../../models/Warehouse';
import { pageUuid } from '../../services/AuthService';
import { exception, setUser as setGAUser } from '../../utils/analytics';
import {
	CODED_DIAGNOSES_PAGE,
	DIAGNOSTICS_PAGE,
	EXPENSES_PAGE,
	EXPENSE_CATEGORIES_PAGE,
	INSURERS_AND_DONORS,
	LOGIN_PAGE,
	MANAGE_USERS_PAGE,
	ORGANIZATION_PAGE,
	OTC_PAGE,
	PATIENTS_PAGE,
	PRODUCTS_PAGE,
	RECEIVE_PRODUCTS_PAGE,
	REFRESH_PAGE,
	REPORTS_PAGE,
	ROOT_PAGE,
	SERVICES_PAGE,
	SERVICE_DEBT_PAGE,
	SESSION_TIMEOUT_PAGE,
	STOCKTAKE_PAGE,
	SUPPLIERS_PAGE,
	TOS_PAGE,
	TRACK_INCOME_PAGE,
	TRANSFER_INVENTORY,
	VISITS_PAGE,
} from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { registerFAIcons } from '../../utils/RegisterFAIcons';
import CodedDiagnosis from '../CodedDiagnosis/CodedDiagnosis';
import DashboardTestIndividual from '../dashboard-test/DashboardTestIndividual';
import DashboardTestTogether from '../dashboard-test/DashboardTestTogether';
import Dashboard from '../dashboard/Dashboard';
import Diagnostics from '../diagnostics/Diagnostics';
import Expenses from '../Expense/Expenses';
import ExpenseCategory from '../ExpenseCategory/ExpenseCategory';
import TrackIncome from '../Income/TrackIncome';
import InsurersAndDonorsList from '../InsurersAndDonors/InsurersAndDonorsList';
import LoginPanel from '../LoginPanel/LoginPanel';
import SessionTimeoutPanel from '../LoginPanel/SessionTimeoutPanel';
import MainMenu from '../MainMenu/MainMenu';
import Refresh from '../MainMenu/Refresh';
import ManageUsers from '../manage-users/ManageUsers';
import NotificationContainer from '../notification-container/NotificationContainer';
import OrganizationForm from '../Organization/OrganizationForm';
import PatientList from '../Patient/PatientList';
import PharmacySalesForm from '../PharmacySales/PharmacySalesForm';
import Product from '../Product/Product';
import ReceiveProducts from '../ReceiveProduct/ReceiveProducts';
import Report from '../Reports/Report';
import Service from '../Service/Service';
import Payments from '../ServiceDebt/Payments';
import StockTake from '../StockTake/StockTake';
import Supplier from '../Supplier/Supplier';
import TermsOfService from '../TermsOfService/TermsOfService';
import TransferInventory from '../TransferInventory/TransferInventory';
import Visits from '../Visit/Visits';
import './App.scss';

// Lazily load the creator since it has some big dependencies only used there
const DashboardComponentCreator = lazy(() => import('../dashboard-test/DashboardComponentCreator'));

const emptyClient = new Client();
const emptyOrganization: Organization = new Organization();
const emptyRole = new Role();
const emptyWarehouse: Warehouse = new Warehouse();
const emptyUser = new User();
const emptyWindowAccessLevels: { [pageUuid: string]: WindowAccessLevel } = {};

export const siteMap: { [T: string]: { path: string; component: () => JSX.Element } } = {
	[pageUuid.PATIENTS]: {
		component: () => <PatientList />,
		path: PATIENTS_PAGE,
	},
	[pageUuid.SUPPLIERS]: {
		component: () => <Supplier />,
		path: SUPPLIERS_PAGE,
	},
	[pageUuid.PRODUCTS]: {
		component: () => <Product />,
		path: PRODUCTS_PAGE,
	},
	[pageUuid.SERVICES]: {
		component: () => <Service />,
		path: SERVICES_PAGE,
	},
	[pageUuid.EXPENSE_CATEGORIES]: {
		component: () => <ExpenseCategory />,
		path: EXPENSE_CATEGORIES_PAGE,
	},
	[pageUuid.VISITS]: {
		component: () => <Visits />,
		path: VISITS_PAGE,
	},
	[pageUuid.RECEIVE_PRODUCTS]: {
		component: () => <ReceiveProducts />,
		path: RECEIVE_PRODUCTS_PAGE,
	},
	[pageUuid.EXPENSES]: {
		component: () => <Expenses />,
		path: EXPENSES_PAGE,
	},
	[pageUuid.INVENTORY]: {
		component: () => <StockTake />,
		path: STOCKTAKE_PAGE,
	},
	[pageUuid.REPORTS]: {
		component: () => <Report />,
		path: REPORTS_PAGE,
	},
	[pageUuid.PAYMENTS]: {
		component: () => <Payments />,
		path: SERVICE_DEBT_PAGE,
	},
	[pageUuid.CODED_DIAGNOSIS]: {
		component: () => <CodedDiagnosis />,
		path: CODED_DIAGNOSES_PAGE,
	},
	[pageUuid.INSURERS_AND_DONORS]: {
		component: () => <InsurersAndDonorsList />,
		path: INSURERS_AND_DONORS,
	},
	[pageUuid.TRANSFER_INVENTORY]: {
		component: () => <TransferInventory />,
		path: TRANSFER_INVENTORY,
	},
	[pageUuid.DASHBOARD]: {
		component: () => <Dashboard />,
		path: '/dashboard',
	},
	[pageUuid.TRACK_INCOME]: {
		component: () => <TrackIncome />,
		path: TRACK_INCOME_PAGE,
	},
	[pageUuid.USERS]: {
		component: () => <ManageUsers />,
		path: MANAGE_USERS_PAGE,
	},
	[pageUuid.OTC]: {
		component: () => <PharmacySalesForm />,
		path: OTC_PAGE,
	},
	[pageUuid.ORGANIZATION]: {
		component: () => <OrganizationForm />,
		path: ORGANIZATION_PAGE,
	},
	[pageUuid.DIAGNOSTICS]: {
		component: () => <Diagnostics />,
		path: DIAGNOSTICS_PAGE,
	},
} as const;

function App({ mockUserContext }: { mockUserContext?: Partial<UserContextInterface> }) {
	const { t } = useTranslation();
	registerFAIcons();
	const { menuService } = useService();

	const showMenu = ![
		!!(useRouteMatch({ path: ROOT_PAGE, exact: true }) || {}).isExact,
		!!useRouteMatch(LOGIN_PAGE),
		!!useRouteMatch(SESSION_TIMEOUT_PAGE),
		!!useRouteMatch(TOS_PAGE),
	].some((pageMatch) => pageMatch);

	const [client, setClient] = useState<Client>(
		mockUserContext?.client ||
			(localStorage.getItem('app_client') && new Client(JSON.parse(localStorage.getItem('app_client')!))) ||
			emptyClient,
	);
	const [organization, setOrganization] = useState<Organization>(
		mockUserContext?.organization ||
			(localStorage.getItem('app_organization') &&
				new Organization(JSON.parse(localStorage.getItem('app_organization')!))) ||
			emptyOrganization,
	);
	const [role, setRole] = useState<Role>(
		mockUserContext?.role ||
			(localStorage.getItem('app_role') && new Role(JSON.parse(localStorage.getItem('app_role')!))) ||
			emptyRole,
	);
	const [warehouse, setWarehouse] = useState<Warehouse>(
		mockUserContext?.warehouse ||
			(localStorage.getItem('app_warehouse') && new Warehouse(JSON.parse(localStorage.getItem('app_warehouse')!))) ||
			emptyWarehouse,
	);
	const [user, setUser] = useState<User>(
		mockUserContext?.user ||
			(localStorage.getItem('app_user') && new User(JSON.parse(localStorage.getItem('app_user')!))) ||
			emptyUser,
	);
	const [windowAccessLevels, setWindowAccessLevels] = useState<{ [pageUuid: string]: WindowAccessLevel }>(
		mockUserContext?.windowAccessLevels ||
			(localStorage.getItem('app_windowAccessLevels') && JSON.parse(localStorage.getItem('app_windowAccessLevels')!)) ||
			emptyWindowAccessLevels,
	);
	const [availableClients, setAvailableClients] = useState<Client[]>(
		mockUserContext?.availableClients ||
			(
				(localStorage.getItem('app_availableClients') && JSON.parse(localStorage.getItem('app_availableClients')!)) ||
				[]
			).map((client: ClientDto) => new Client(client)),
	);

	const initialUserContext: UserContextInterface = {
		client,
		organization,
		role,
		warehouse,
		user,
		windowAccessLevels,
		availableClients,
		update: ({ client, organization, role, warehouse, user, windowAccessLevels, availableClients }) => {
			// Update state, and localStorage as well so it can be retrieved on page reload (these should not be used outside this component)
			if (client) {
				setClient(client);
				localStorage.setItem('app_client', JSON.stringify(client));
			}
			if (organization) {
				setOrganization(organization);
				localStorage.setItem('app_organization', JSON.stringify(organization));
			}
			if (role) {
				setRole(role);
				localStorage.setItem('app_role', JSON.stringify(role));
			}
			if (warehouse) {
				setWarehouse(warehouse);
				localStorage.setItem('app_warehouse', JSON.stringify(warehouse));
			}
			if (user) {
				setUser(user);
				localStorage.setItem('app_user', JSON.stringify(user));
			}
			if (windowAccessLevels) {
				setWindowAccessLevels(windowAccessLevels);
				localStorage.setItem('app_windowAccessLevels', JSON.stringify(windowAccessLevels));
			}
			if (availableClients) {
				setAvailableClients(availableClients);
				localStorage.setItem('app_availableClients', JSON.stringify(availableClients));
			}
		},
		clear: () => {
			setClient(emptyClient);
			setOrganization(emptyOrganization);
			setRole(emptyRole);
			setWarehouse(emptyWarehouse);
			setUser(emptyUser);
			setWindowAccessLevels(emptyWindowAccessLevels);
			setAvailableClients([]);

			// Clear localStorage as well
			localStorage.removeItem('app_client');
			localStorage.removeItem('app_organization');
			localStorage.removeItem('app_role');
			localStorage.removeItem('app_warehouse');
			localStorage.removeItem('app_user');
			localStorage.removeItem('app_windowAccessLevels');
			localStorage.removeItem('app_availableClients');
		},
	};

	const [isBlocking, toggleNavBlocking] = useState(false);
	let navBlockState = {
		isBlocking,
		toggleNavBlocking,
	};

	const usersUuid = user.uuid;
	useEffect(() => {
		setGAUser(usersUuid);
	}, [usersUuid]);

	const { value: menus } = useAsync(async () => {
		// If the user doesn't have a role yet (i.e. they're not logged in), don't do anything
		if (role.isNew) {
			return [];
		}
		const structuredMenus = await menuService.get(true);
		const getSubMenus = (menu: Menu, currentMenuList: Menu[]): Menu[] => {
			if (!menu.subMenus.length) {
				return currentMenuList;
			}
			currentMenuList = currentMenuList.concat(menu.subMenus);
			return menu.subMenus.reduce((menuList, subMenu) => getSubMenus(subMenu, menuList), currentMenuList);
		};
		return getSubMenus(new Menu({ subMenus: structuredMenus }), []).filter((menu) => !!menu.window?.uuid);
	}, [menuService, role.uuid]);

	return (
		<UserContext.Provider
			value={{
				...initialUserContext,
				update: mockUserContext?.update || initialUserContext.update,
				clear: mockUserContext?.clear || initialUserContext.clear,
			}}
		>
			<NavBlockingContext.Provider value={navBlockState}>
				<Container fluid>
					<Row>
						{showMenu && <MainMenu />}
						<Prompt when={isBlocking} message={t(uiText.visit.PROMPT_MESSAGE)} />
						<NotificationContainer />
						<Col>
							<Switch>
								<Route path={ROOT_PAGE} exact={true}>
									<LoginPanel />
								</Route>
								<Route path={LOGIN_PAGE}>
									<LoginPanel />
								</Route>
								<Route path={TOS_PAGE}>
									<TermsOfService />
								</Route>
								<Refresh path={REFRESH_PAGE} />
								<Route path="/dashboard-test-individual">
									<DashboardTestIndividual />
								</Route>
								<Route path="/dashboard-test-together">
									<DashboardTestTogether />
								</Route>
								<Route path="/dashboard-component-creator">
									<DashboardComponentCreator />
								</Route>
								<Route path={SESSION_TIMEOUT_PAGE}>
									<SessionTimeoutPanel />
								</Route>
								{/* <Route path={HOME_PAGE} exat component={interceptResponse(Home)} /> */}
								{menus?.map((menu) => {
									const windowUuid = menu.window?.uuid;
									if (!windowUuid || !siteMap[windowUuid]) {
										const message = 'No page to show for this window: ' + windowUuid;
										if (process.env.NODE_ENV !== 'production') {
											throw new Error(message);
										} else {
											console.error(message);
											exception({ description: message });
											return null;
										}
									}
									const Component = siteMap[windowUuid].component;
									return (
										<Route path={siteMap[windowUuid].path} key={windowUuid}>
											<Component />
										</Route>
									);
								})}
								{/*<Route component={Notfound}/>*/}
							</Switch>
						</Col>
					</Row>
				</Container>
			</NavBlockingContext.Provider>
		</UserContext.Provider>
	);
}

export default App;
