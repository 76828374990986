import Movement, { MovementDB, MovementDto } from '../models/Movement';
import { MovementLineDto } from '../models/MovementLine';
import { MOVEMENTS_URI } from '../utils/Constants';
import DocumentService from './DocumentService';

export default class MovementService extends DocumentService<Movement, MovementDto, MovementDB> {
	public entityTableName: string = 'm_movement';
	entityName = MOVEMENTS_URI;

	map(data: MovementDto) {
		return new Movement(data);
	}

	reverseMap(data: Movement): MovementDto {
		// We'll only send the movement line ASI if it has a UUID
		return {
			...data,
			movementLines: (data.movementLines || []).map(
				(movementLine): MovementLineDto => ({
					...movementLine,
					product: { ...movementLine.product, storageOnHandList: [], attributeSet: null },
					attributeSetInstance:
						(movementLine.attributeSetInstance.uuid && {
							...movementLine.attributeSetInstance,
							guaranteeDate: movementLine.attributeSetInstance.guaranteeDate?.getTime(),
							serialNumber: movementLine.attributeSetInstance.serialNumber || null,
							lot: movementLine.attributeSetInstance.lot || null,
						}) ||
						null,
					movementQuantity: movementLine.movementQuantity || 0,
				}),
			),
			movementDate: data?.movementDate?.toString(),
		};
	}
}
