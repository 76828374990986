import { sortBy } from 'lodash';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import PayerInformationField from '../../models/PayerInformationField';
import { REFERENCE_LIST_UUID_DATA_TYPE_TEXT } from '../../models/ReferenceList';
import DynamicSelect from '../dynamic-select/DynamicSelect';

type PatientPayerInformationInputProps = {
	id?: string;
	register: UseFormRegister<FieldValues>;
	payerInformationField: Partial<PayerInformationField>;
	name: string;
	defaultValue: string;
	label?: string;
	inputPlaceholder: string;
	selectPlaceholder: string;
	required?: boolean;
};

function PatientPayerInformationInput({
	id,
	register,
	payerInformationField,
	name,
	defaultValue,
	label,
	inputPlaceholder,
	selectPlaceholder,
	required,
}: PatientPayerInformationInputProps) {
	return !payerInformationField.dataType ||
		payerInformationField.dataType.uuid === REFERENCE_LIST_UUID_DATA_TYPE_TEXT ? (
		<Form.Control
			id={id}
			aria-label={label}
			className="keep-border"
			placeholder={inputPlaceholder}
			{...register(name, { required: required !== false })}
			defaultValue={defaultValue}
		/>
	) : (
		<DynamicSelect
			id={id}
			aria-label={label}
			className="keep-border"
			{...register(name, { required: required !== false })}
			defaultValue={defaultValue}
			isLoading={!payerInformationField?.values?.length}
		>
			<option value="">{selectPlaceholder}</option>
			{sortBy(payerInformationField.values, 'lineNumber').map((chargeInformationValue) => (
				<option key={chargeInformationValue.uuid} value={chargeInformationValue.name}>
					{chargeInformationValue.name}
				</option>
			))}
		</DynamicSelect>
	);
}

export default PatientPayerInformationInput;
