import { useContext } from 'react';
import { useAsync } from 'react-use';
import UserContext from '../contexts/UserContext';
import { DocumentActionValue } from '../models/DocAction';
import { DocumentStatusValue } from '../models/DocumentStatus';
import { ProcessTypeValue } from '../models/ProcessType';
import useService from './useService';

/**
 * Determine if, based on the current user's status and the current status of the entity
 * being worked with, that desired document action is available
 * @param processType The process type to help narrow the list of actions
 * @param currentDocumentStatus The current status of the document being considered
 * @param desiredDocumentAction The desired action to perform on the document
 * @returns Whether the information is being loaded and what it's value is
 * @deprecated use useGraphQLIsDocumentActionAvailable instead
 */
export default function useIsDocumentActionAvailable(
	processType: ProcessTypeValue,
	currentDocumentStatus: DocumentStatusValue,
	desiredDocumentAction: DocumentActionValue,
) {
	const { processTypeService } = useService();

	// Get the role's name so we can update this value if the user's role changes
	const { role: { name } = { name: '' } } = useContext(UserContext) || {};
	return useAsync(async () => {
		const availableDocumentActions = await processTypeService.getAvailableActionsByProcessTypeAndStatus(
			processType,
			currentDocumentStatus,
		);
		return availableDocumentActions.includes(desiredDocumentAction);
	}, [name, processType, currentDocumentStatus, desiredDocumentAction]);
}
