import { saveAs } from 'file-saver';
import { cloneDeep, sortBy } from 'lodash';
import React, { Fragment, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsync, useDeepCompareEffect } from 'react-use';
import useService from '../../hooks/useService';
import { DATETIME } from '../../models/Reference';
import ReportParameter from '../../models/ReportParameter';
import ReportParameterValue from '../../models/ReportParameterValue';
import { formatDateAndTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReportField from './ReportField';
import ReportWindow from './ReportWindow';

export type SelectedReportParameter = ReportParameter & { selectedValue?: string };

/**
 * Reports Component
 */
const reportFormat = {
	pdf: {
		NAME: 'PDF',
		VALUE: 'PDF',
	},
	excel: {
		NAME: 'Excel',
		VALUE: 'XLS',
	},
	html: {
		NAME: 'HTML',
		VALUE: 'HTML',
	},
	csv: {
		NAME: 'CSV',
		VALUE: 'CSV',
	},
} as const;

const reportFieldLabel = {
	BEGIN_DATE: 'Begin Date',
	END_DATE: 'End Date',
} as const;

const Report = () => {
	const { t } = useTranslation();

	const [selectedReportUuid, setSelectedReportUuid] = useState('');
	const [parametersWithSelectedValues, setParametersWithSelectedValues] = useState<SelectedReportParameter[]>([]);
	const [selectedValues, setSelectedValues] = useState<SelectedReportParameter[]>([]);
	const [formatType, setFormatType] = useState<(typeof reportFormat)[keyof typeof reportFormat]['VALUE']>(
		reportFormat.pdf.VALUE,
	);
	const [isProcessing, setIsProcessing] = useState(false);
	const [reportResponse, setReportResponse] = useState<Blob | undefined>();
	const [formErrors, setFormErrors] = useState([]);

	const services = useService();
	const { reportService } = services;

	const { value: reports = [] } = useAsync(
		() => reportService.getReportsThatNeedToInputBeforeBeingRun(),
		[reportService],
	);

	/**
	 * Call REST service
	 */
	const onGenerateReport = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (!validateFields()) {
			setIsProcessing(false);
			return;
		}
		setIsProcessing(true);
		reportService
			.generateReport(
				selectedReportUuid,
				parametersWithSelectedValues.map(
					(parameterWithSelectedValue): ReportParameterValue => ({
						uuid: parameterWithSelectedValue.uuid,
						value:
							parameterWithSelectedValue.selectedValue === '' ? undefined : parameterWithSelectedValue.selectedValue,
					}),
				),
				formatType,
			)
			.then((response) => {
				if (formatType === reportFormat.pdf.VALUE) {
					setReportResponse(
						new Blob([response], {
							type: 'application/pdf',
						}),
					);
				} else {
					saveAs(
						URL.createObjectURL(new Blob([response])),
						(reports.find((report) => report.uuid === selectedReportUuid) || {}).name + '.' + formatType.toLowerCase(),
					);
				}
			})
			.catch(() => {
				toast.error(t(uiText.report.generate.ERROR_MESSAGE));
			})
			.finally(() => {
				setIsProcessing(false);
			});
	};

	const onSelectReport = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedReportUuid(event.target.value);
	};

	useDeepCompareEffect(() => {
		setParametersWithSelectedValues([]);
		const reportUuid = selectedReportUuid;
		if (!reportUuid) {
			return;
		}

		// get report
		const report = reports.find((report) => report.uuid === reportUuid);
		if (!report) {
			return;
		}

		// get report parameters
		if (!report.parameters) {
			return;
		}

		const newParametersWithSelectedValues: Partial<SelectedReportParameter>[] = cloneDeep(report.parameters);
		// For any parameters that share names between reports, keep the selected values
		newParametersWithSelectedValues.forEach((parameterWithSelectedValue) => {
			parameterWithSelectedValue.selectedValue =
				(
					selectedValues.find(
						(oldParameterWithSelectedValue) => parameterWithSelectedValue.name === oldParameterWithSelectedValue.name,
					) || {}
				).selectedValue || '';
		});
		setParametersWithSelectedValues(
			sortBy(newParametersWithSelectedValues as SelectedReportParameter[], ['sequenceNumber']),
		);
	}, [selectedReportUuid, reports, selectedValues, formErrors]);

	const onParameterChange = (parameterUuid: string, selectedValue?: string) => {
		let shouldUpdateParametersWithSelectedValues = false;
		let shouldUpdateSelectedValues = true;
		selectedValue = selectedValue === '' ? undefined : selectedValue;

		const updatedParametersWithSelectedValues = cloneDeep(parametersWithSelectedValues);
		const parameterToUpdate = updatedParametersWithSelectedValues.find(
			(parameterValue) => parameterValue.uuid === parameterUuid,
		);
		if (!parameterToUpdate) {
			throw new Error(`Could not find parameter with UUID: ${parameterUuid}`);
		}

		if (parameterToUpdate.selectedValue !== selectedValue) {
			shouldUpdateParametersWithSelectedValues = true;
		}

		// check end date time parameter
		let additionalParameterToUpdate: SelectedReportParameter | undefined = undefined;
		if (
			parameterToUpdate.reference.uuid === DATETIME &&
			parameterToUpdate.name.includes(reportFieldLabel.END_DATE) &&
			!parameterToUpdate.selectedValue
		) {
			const endDate = new Date(selectedValue || '');
			endDate.setHours(23);
			endDate.setMinutes(59);
			parameterToUpdate.selectedValue = formatDateAndTime(endDate);
		} else {
			parameterToUpdate.selectedValue = selectedValue;
			if (
				parameterToUpdate.reference.uuid === DATETIME &&
				parameterToUpdate.name.includes(reportFieldLabel.BEGIN_DATE)
			) {
				const endDateParameter = updatedParametersWithSelectedValues.find((parameterValue) =>
					parameterValue.name.includes(reportFieldLabel.END_DATE),
				);
				if (endDateParameter && !endDateParameter.selectedValue) {
					const endDate = new Date(selectedValue || '');
					endDate.setHours(23);
					endDate.setMinutes(59);
					endDateParameter.selectedValue = formatDateAndTime(endDate);
					additionalParameterToUpdate = endDateParameter;
				}
			}
		}

		// Update the list of values and names so that we can maintain parameter values across reports
		const updatedSelectedValues = cloneDeep(selectedValues);
		let selectedValueToUpdate: Partial<SelectedReportParameter> | undefined = updatedSelectedValues.find(
			(selectedValue) => selectedValue.name === parameterToUpdate.name,
		);
		if (!selectedValueToUpdate) {
			selectedValueToUpdate = new ReportParameter({
				name: parameterToUpdate.name,
			});
			selectedValueToUpdate.selectedValue = parameterToUpdate.selectedValue;
			updatedSelectedValues.push(selectedValueToUpdate as SelectedReportParameter);
		} else {
			if (selectedValueToUpdate.selectedValue === selectedValue) {
				shouldUpdateSelectedValues = false;
			}
			selectedValueToUpdate.selectedValue = parameterToUpdate.selectedValue;
		}
		if (additionalParameterToUpdate) {
			let additionalSelectedValueToUpdate: Partial<SelectedReportParameter> | undefined = updatedSelectedValues.find(
				(selectedValue) => selectedValue.name === additionalParameterToUpdate!.name,
			);
			if (!additionalSelectedValueToUpdate) {
				additionalSelectedValueToUpdate = new ReportParameter({
					name: additionalParameterToUpdate.name,
				});
				additionalSelectedValueToUpdate.selectedValue = additionalParameterToUpdate.selectedValue;
				updatedSelectedValues.push(additionalSelectedValueToUpdate as SelectedReportParameter);
			} else {
				if (additionalSelectedValueToUpdate.selectedValue === selectedValue) {
					shouldUpdateSelectedValues = false;
				}
				additionalSelectedValueToUpdate.selectedValue = additionalParameterToUpdate.selectedValue;
			}
		}

		if (shouldUpdateParametersWithSelectedValues) {
			setParametersWithSelectedValues(updatedParametersWithSelectedValues);
		}
		if (shouldUpdateSelectedValues) {
			setSelectedValues(updatedSelectedValues);
		}
	};

	const validateFields = () => {
		let errors: any = [];
		parametersWithSelectedValues.forEach((parameterWithSelectedValue) => {
			if (parameterWithSelectedValue.reference.uuid === DATETIME) {
				// set an error
				if (parameterWithSelectedValue.selectedValue === '') {
					errors.push(parameterWithSelectedValue.name);
				}
			}
		});
		if (errors.length > 0) {
			setFormErrors(errors);
			return false;
		}
		return true;
	};

	return (
		<Layout>
			<Layout.Header>
				<Layout.Title title={t(uiText.report.TITLE)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				<Row className="bg-white ms-0">
					<LoadSpinner show={isProcessing} title={t(uiText.report.generate.CREATING)} />
					{reportResponse ? <ReportWindow response={reportResponse} /> : null}
					<Form autoComplete="off" className="px-0">
						<Card className="bh-card">
							<Card.Body>
								<Row className="gy-3">
									<Form.Group as={Fragment} controlId="reportSelector">
										<Col xs={2} className="d-flex align-items-center">
											<Form.Label column>{t(uiText.report.SELECT)}</Form.Label>
										</Col>
										<Col xs={7} className="d-flex align-items-center">
											<Form.Select name="name" onChange={onSelectReport}>
												<option />
												{reports.map((report) => (
													<option key={report.uuid} value={report.uuid}>
														{report.name}
													</option>
												))}
											</Form.Select>
										</Col>
									</Form.Group>
									<Col xs={3} />

									{parametersWithSelectedValues.map((parameterWithSelectedValue) => (
										<ReportField
											key={parameterWithSelectedValue.uuid}
											parameterWithSelectedValue={parameterWithSelectedValue}
											onParameterChange={onParameterChange}
											formErrors={formErrors}
										/>
									))}

									{!!selectedReportUuid && (
										<>
											<Form.Group as={Fragment} controlId="reportFormat">
												<Col xs={2} className="d-flex align-items-center">
													<Form.Label column>{t(uiText.report.FORMAT)}</Form.Label>
												</Col>
												<Col xs={7} className="d-flex align-items-center">
													<Form.Select
														name="formatType"
														onChange={(e) =>
															setFormatType(e.target.value as (typeof reportFormat)[keyof typeof reportFormat]['VALUE'])
														}
													>
														<option value={reportFormat.pdf.VALUE}>{reportFormat.pdf.NAME}</option>
														<option value={reportFormat.excel.VALUE}>{reportFormat.excel.NAME}</option>
														<option value={reportFormat.html.VALUE}>{reportFormat.html.NAME}</option>
														<option value={reportFormat.csv.VALUE}>{reportFormat.csv.NAME}</option>
													</Form.Select>
												</Col>
											</Form.Group>
											<Col xs={3} />
										</>
									)}

									<Col xs={9} className="d-flex justify-content-end pt-3">
										<Button variant="success" disabled={!selectedReportUuid} onClick={onGenerateReport}>
											{t(uiText.report.generate.GENERATE)}
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Form>
				</Row>
			</Layout.Body>
		</Layout>
	);
};

export default Report;
