import React, { Fragment } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';

const PharmacySalesDetailsEdit = () => {
	const { t } = useTranslation();

	return (
		<Card className="bh-card mb-0">
			<Card.Body>
				<Row className="gy-3">
					<Form.Group as={Fragment} controlId="visitDate">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.visit.form.DATE)}</Form.Label>
						</Col>
						<Col xs={3} className="d-flex align-items-center">
							<fieldset className="w-100">
								<Controller
									name="visitDate"
									render={({ field }) => (
										<BandaDatePicker
											dateFormat="yyyy-MM-dd h:mm aa"
											{...field}
											selected={field.value}
											timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
											showTimeInput
											maxDate={new Date()}
										/>
									)}
								/>
							</fieldset>
						</Col>
					</Form.Group>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default PharmacySalesDetailsEdit;
