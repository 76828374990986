import { useApolloClient, useLazyQuery } from '@apollo/client';
import endOfDay from 'date-fns/endOfDay';
import { useContext, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_Ref_ListTenderTypesDocument,
	C_AcctSchemaForCurrencyUseDocument,
	C_BankAccountForPaymentsDocument,
	C_BPartnerDisplayForInsurerAndDonorIncomeFragmentDoc,
	C_BPartnerForInsurerAndDonorIncomeDocument,
	C_DocTypeForFormsDocument,
	C_PaymentForInsurerDonorIncomeEditingDocument,
	C_PaymentForInsurerDonorIncomeEditingQuery,
	C_PaymentForInsurerDonorIncomeProcessingDocument,
	C_PaymentForInsurerDonorIncomeProcessingMutationVariables,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseGraphQLDocumentActionInformation from '../../hooks/useSuspenseGraphQLDocumentActionInformation';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import {
	BaseMetadataDB,
	BusinessPartnerDB,
	businessPartnerGroupInsurerAndDonorFilter,
	DBFilter,
	DocAction,
	documentBaseType,
	ReferenceListDB,
	referenceUuids,
} from '../../models';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { isEntityCompleted, isEntityDrafted, isEntityVoided } from '../../utils/StatusUtil';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookupGraphQL from '../entity-lookup/EntityLookupGraphQL';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { withFormModalSuspsenseWrapper } from '../HOCs/withFormModalSuspsenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

export type IncomeFormFields = {
	UU: string;
	C_BPartner: { UU: string };
	DateTrx: Date;
	Description: string;
	PayAmt: number | null;
	TenderTypeUU: string;
	submitEvent: '' | 'complete' | 'void';
};

type IncomeFormProps = EntityFormProperties;

const fetchReceiptDocumentTypeArguments = [documentBaseType.ARReceipt, null, null, true, false, false] as const;
const getTitle = (uuid?: string) => (uuid ? uiText.income.pageDisplay.EDIT : uiText.income.pageDisplay.ADD);
const convertToFormFields: (
	initialData?: C_PaymentForInsurerDonorIncomeEditingQuery['C_Payment'],
) => IncomeFormFields = (initialData) => {
	if (!initialData) {
		return {
			UU: v4(),
			C_BPartner: { UU: '' },
			DateTrx: new Date(),
			Description: '',
			TenderTypeUU: '',
			PayAmt: null,
			submitEvent: '',
		};
	}
	return {
		UU: initialData.UU,
		C_BPartner: { UU: initialData.C_BPartner.UU },
		DateTrx: new Date(initialData.DateTrx),
		Description: initialData.Description || '',
		TenderTypeUU: initialData.TenderType.UU,
		PayAmt: initialData.PayAmt,
		submitEvent: '',
	};
};

const IncomeForm = ({ uuid, onFinish, renderAsModal }: IncomeFormProps) => {
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);
	const { data: [paymentData, receiptDocumentType, bankAccounts, accountSchema, tenderTypes] = [] } = useSuspenseAsync(
		uuid || 'add-invoice',
		async () =>
			await Promise.all([
				uuid
					? graphqlClient.query({
							query: C_PaymentForInsurerDonorIncomeEditingDocument,
							variables: { UU: uuid },
							fetchPolicy: 'network-only',
						})
					: undefined,
				graphqlClient.query({
					query: C_DocTypeForFormsDocument,
					variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
					fetchPolicy: 'cache-first',
				}),
				graphqlClient.query({
					query: C_BankAccountForPaymentsDocument,
					variables: {
						Sort: JSON.stringify([['created', 'asc']]),
						Filter: DBFilter<BaseMetadataDB>()
							.nested('ad_org')
							.property('ad_org_uu')
							.equals(organization.uuid)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				}),
				graphqlClient.query({ query: C_AcctSchemaForCurrencyUseDocument, fetchPolicy: 'cache-first' }),
				graphqlClient.query({
					query: Ad_Ref_ListTenderTypesDocument,
					variables: {
						Filter: DBFilter<ReferenceListDB>()
							.nested('ad_reference')
							.property('ad_reference_uu')
							.equals(referenceUuids.TENDER_TYPES)
							.up()
							.toString(),
					},
					fetchPolicy: 'cache-first',
				}),
			]),
	);

	const { t } = useTranslation();
	const [dataToUse, setDataToUse] = useState(paymentData?.data.C_Payment);
	const title = dataToUse
		? isEntityCompleted(dataToUse) || isEntityVoided(dataToUse)
			? uiText.income.pageDisplay.VIEW
			: uiText.income.pageDisplay.EDIT
		: uiText.income.pageDisplay.ADD;
	const formMethods = useForm<IncomeFormFields>({
		defaultValues: convertToFormFields(dataToUse),
	});
	const isDataReadOnly = isEntityCompleted(dataToUse) || isEntityVoided(dataToUse);

	const { canVoidDocument, voidDocumentAction } = useSuspenseGraphQLDocumentActionInformation(
		documentBaseType.MaterialMovement,
		dataToUse?.DocStatus.Value,
	); //
	const { disableWrite } = useActionPrivileges(pageUuid.TRACK_INCOME);

	const { dataWasSaved, savedData } = useContext(FormModalContext);

	const reset = formMethods.reset;
	const [{ loading: areProcessingDocument }, onSubmit] = useAsyncFn<SubmitHandler<IncomeFormFields>>(
		async (data) => {
			let receiptDocumentTypeToUse = receiptDocumentType?.data?.C_DocTypeGet.Results[0];
			if (!receiptDocumentTypeToUse) {
				try {
					receiptDocumentTypeToUse = (
						await graphqlClient.query({
							query: C_DocTypeForFormsDocument,
							variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
							fetchPolicy: 'network-only',
						})
					).data.C_DocTypeGet.Results[0];
				} catch (error) {
					exception({ description: 'Could not load A/R receipt document type: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}

			let bankAccountUU = bankAccounts?.data?.C_BankAccountGet.Results.filter((bankAccount) => bankAccount.IsDefault)[0]
				?.UU;
			if (!bankAccountUU) {
				bankAccountUU = bankAccounts?.data?.C_BankAccountGet.Results[0]?.UU;
			}
			const payment: C_PaymentForInsurerDonorIncomeProcessingMutationVariables['C_Payment'] = {
				UU: data.UU,
				BH_tender_amount: data.PayAmt,
				C_BPartner: { UU: data.C_BPartner.UU },
				C_BankAccount: bankAccountUU ? { UU: bankAccountUU } : undefined,
				C_Currency: accountSchema?.data?.C_AcctSchemaGet.Results[0].C_Currency.UU
					? { UU: accountSchema.data.C_AcctSchemaGet.Results[0].C_Currency.UU }
					: undefined,
				C_DocType: { UU: receiptDocumentTypeToUse.UU },
				DateAcct: data.DateTrx.getTime(),
				DateTrx: data.DateTrx.getTime(),
				Description: data.Description || null,
				IsReceipt: receiptDocumentTypeToUse.IsSOTrx,
				PayAmt: data.PayAmt,
				TenderType: { UU: data.TenderTypeUU },
			};

			const formAction = data.submitEvent;

			let processType = formAction === 'complete' ? DocAction.COMPLETE : (voidDocumentAction as string);
			await graphqlClient.mutate({
				mutation: C_PaymentForInsurerDonorIncomeProcessingDocument,
				variables: { C_Payment: payment, C_Payment_UU: payment.UU!, DocumentAction: processType },
			});
			const newData = (
				await graphqlClient.query({
					query: C_PaymentForInsurerDonorIncomeEditingDocument,
					variables: { UU: payment.UU! },
					fetchPolicy: 'network-only',
				})
			).data.C_Payment!;

			setDataToUse(newData);
			reset(convertToFormFields(newData));
			dataWasSaved(newData.UU);
		},
		[reset, voidDocumentAction, dataWasSaved, graphqlClient, bankAccounts, receiptDocumentType, accountSchema],
	);

	useConfirmRefresh(formMethods.formState?.isDirty);

	const [onSearchInsurerOrDonor, { data: insurerOrDonorOptions, loading: areLoadingInsurersAndDonors }] = useLazyQuery(
		C_BPartnerForInsurerAndDonorIncomeDocument,
	);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form
				autoComplete="off"
				onSubmit={formMethods.handleSubmit(onSubmit)}
				className={`${renderAsModal ? '' : 'm-n3'} px-0`}
			>
				<Card.Body>
					<Form.Control {...formMethods.register('UU')} hidden />
					<fieldset disabled={disableWrite || isDataReadOnly}>
						<Form.Group as={Row} className="mb-3" controlId="transactionDate">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.payment.TRANSACTION_DATE)}</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<Controller<IncomeFormFields, 'DateTrx'>
									name="DateTrx"
									rules={{ required: true }}
									render={({ field }) => (
										<BandaDatePicker
											maxDate={endOfDay(new Date())}
											{...field}
											value={undefined}
											selected={field.value}
										/>
									)}
								/>
								{formMethods.formState.errors?.DateTrx && (
									<span className="text-danger">{t(uiText.payment.error.MISSING_TRANSACTION_DATE)}</span>
								)}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3" controlId="C_BPartner">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.nonPatientPayment.INSURER_STROKE_DONOR)}</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<EntityLookupGraphQL<IncomeFormFields, 'C_BPartner'>
									className="w-100"
									name="C_BPartner"
									rules={{ required: true }}
									isLoading={areLoadingInsurersAndDonors}
									id="C_BPartner"
									emptyLabel={t(uiText.supplier.search.EMPTY)}
									labelKey={(data) =>
										graphqlClient.readFragment({
											id: data.UU,
											fragment: C_BPartnerDisplayForInsurerAndDonorIncomeFragmentDoc,
										})?.Name || ''
									}
									placeholder={t(uiText.nonPatientPayment.SEARCH_INSURER_STROKE_DONOR)}
									promptText={t(uiText.supplier.search.SEARCHING)}
									searchText={t(uiText.supplier.search.SEARCHING)}
									options={insurerOrDonorOptions?.C_BPartnerGet.Results || []}
									onSearch={(query) =>
										onSearchInsurerOrDonor({
											variables: {
												Filter: DBFilter<BusinessPartnerDB>()
													.property('name')
													.contains(query)
													.nested('c_bp_group')
													.and(businessPartnerGroupInsurerAndDonorFilter())
													.up()
													.toString(),
											},
										})
									}
								/>
								{formMethods.formState.errors?.C_BPartner?.UU && (
									<span className="text-danger">{t(uiText.income.error.INSURER_STROKE_DONOR_REQUIRED)}</span>
								)}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3" controlId="paymentType">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.nonPatientPayment.tableHeaders.TYPE)}</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<Form.Select {...formMethods.register('TenderTypeUU', { required: true })}>
									{tenderTypes?.data?.AD_Ref_ListGet.Results.filter(
										(tenderType) => isEntityCompleted(dataToUse) || tenderType.IsActive,
									).map((tenderType) => (
										<option key={tenderType.UU} value={tenderType.UU}>
											{tenderType.Name}
										</option>
									))}
								</Form.Select>
								{formMethods.formState.errors?.TenderTypeUU && (
									<span className="text-danger">{t(uiText.payment.error.MISSING_TYPE)}</span>
								)}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3" controlId="payAmount">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.payment.PAYMENT_AMOUNT)}</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<Controller<IncomeFormFields, 'PayAmt'>
									name="PayAmt"
									rules={{ validate: (value) => value !== 0 }}
									render={({ field }) => <FormatNumberInput min={0} {...field} />}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className="mb-3" controlId="description">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.payment.description.LABEL)}</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<Form.Control
									as="textarea"
									rows={4}
									placeholder={t(uiText.service.description.PLACEHOLDER)}
									{...formMethods.register('Description')}
								/>
							</Col>
						</Form.Group>
					</fieldset>
					<Form.Control {...formMethods.register('submitEvent')} defaultValue={''} hidden />
				</Card.Body>
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'mb-5 mt-2 mx-1'}`}>
			<Col xs="auto" className="me-auto">
				<Button variant="danger" type="button" name="cancel" onClick={() => onFinish(true, savedData)}>
					{isDataReadOnly ? t(uiText.income.button.BACK) : t(uiText.income.button.CANCEL)}
				</Button>
			</Col>
			{!dataToUse || isEntityDrafted(dataToUse) ? (
				<Col xs="auto">
					<Button
						type="submit"
						variant="success"
						onClick={() => {
							formMethods.setValue('submitEvent', 'complete');
							formMethods.handleSubmit(onSubmit)();
						}}
					>
						{t(uiText.income.PROCESS)}
					</Button>
				</Col>
			) : null}
			{isEntityCompleted(dataToUse) && canVoidDocument ? (
				<Col xs="auto">
					<Button
						type="submit"
						variant="danger"
						onClick={() => {
							formMethods.setValue('submitEvent', 'void');
							formMethods.handleSubmit(onSubmit)();
						}}
					>
						{t(uiText.income.button.VOID_INCOME)}
					</Button>
				</Col>
			) : null}
			<Col xs={3} />
		</Row>
	);

	return renderAsModal ? (
		<>
			<Modal.Header closeButton>
				<Modal.Title>{t(title)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{areProcessingDocument ? <LoadSpinner inline title={t(uiText.income.message.PROCESSING)} /> : inputs}
			</Modal.Body>
			{areProcessingDocument ? null : (
				<Modal.Footer>
					<div className="w-100">{buttons}</div>
				</Modal.Footer>
			)}
		</>
	) : (
		<>
			<Layout.Header>
				<Layout.Title title={t(title)} />
				<Layout.Menu />
			</Layout.Header>
			<Layout.Body>
				{areProcessingDocument ? (
					<LoadSpinner title={t(uiText.income.message.PROCESSING)} />
				) : (
					<div className="pb-0_5 bg-white me-n2_5">
						<Card className="bh-card">
							<Card.Body>{inputs}</Card.Body>
							{buttons}
						</Card>
					</div>
				)}
			</Layout.Body>
		</>
	);
};

export default withFormModalSuspsenseWrapper<IncomeFormProps>({ loadingLabel: uiText.income.LOADING, getTitle })(
	IncomeForm,
);
