import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, forwardRef } from 'react';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';

export type PasswordInputProps = FormControlProps & React.ComponentPropsWithoutRef<'input'>;

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
	const [showPassword, setShowPassword] = useState(false);
	const { t } = useTranslation();

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<InputGroup className={'px-0'}>
			<Form.Control
				type={showPassword ? 'text' : 'password'}
				ref={ref}
				placeholder={props.placeholder ?? t(uiText.login.PASSWORD)}
				{...props}
			/>
			<InputGroup.Text>
				<FontAwesomeIcon
					icon={showPassword ? 'eye' : 'eye-slash'}
					title={showPassword ? t(uiText.login.HIDE_PASSWORD) : t(uiText.login.SHOW_PASSWORD)}
					onClick={() => toggleShowPassword()}
					fixedWidth={true}
				/>
			</InputGroup.Text>
		</InputGroup>
	);
});

export default PasswordInput;
