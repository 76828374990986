import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';

type LayoutTitleProps = {
	title: string;
} & (
	| { showRefreshIcon: true; onRefresh: () => void; areRefreshing: boolean }
	| { showRefreshIcon?: false; onRefresh?: undefined; areRefreshing?: undefined }
);

const LayoutTitle = ({ title, showRefreshIcon, onRefresh, areRefreshing }: LayoutTitleProps) => {
	const { t } = useTranslation();
	return (
		<Col className="pe-0 layout__top-bar__pg-header-panel__pg_title me-auto" xs="auto">
			{title}
			{showRefreshIcon && (
				<button
					type="button"
					className="btn ms-2"
					data-toggle="tooltip"
					data-placement="top"
					title={t(uiText.layout.REFRESH)}
					aria-label={t(uiText.layout.REFRESH)}
					onClick={onRefresh}
				>
					<FontAwesomeIcon icon="sync" spin={areRefreshing} className="layout__top-bar__pg-header-panel__pg_icon" />
				</button>
			)}
		</Col>
	);
};

export default LayoutTitle;
