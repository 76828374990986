import { createBrowserHistory } from 'history';
import CustomPrompt from '../components/CustomPrompt/CustomPrompt';

const history = createBrowserHistory({
	getUserConfirmation: (message, callback) => {
		CustomPrompt(message, callback);
	},
});

export default history;
