import React, { Fragment } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Encounter } from '../../models';
import { uiText } from '../../utils/Language';
import ObservationFormGroup from './ObservationFormGroup';
import { EncounterFormFields } from './ObservationTable';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';

type EncounterModalProps = {
	visitUuid: string;
	encounter: Encounter;
	onHandleClose: () => void;
	onSubmit: SubmitHandler<EncounterFormFields>;
};

const EncounterModal = ({ visitUuid, encounter, onHandleClose, onSubmit }: EncounterModalProps) => {
	const { t } = useTranslation();
	const formMethods = useForm<FieldValues>({ defaultValues: encounter });

	return (
		<FormProvider {...formMethods}>
			<Modal show={true} size={'xl'}>
				<Form onSubmit={formMethods.handleSubmit(onSubmit)}>
					<Modal.Header>
						{t(encounter.isNew ? uiText.visit.vitals.title.NEW : uiText.visit.vitals.title.EDIT)}
					</Modal.Header>
					<Modal.Body>
						<Row className="mb-4">
							<Form.Group as={Fragment} controlId="measurementTime">
								<Col xs={2} className="d-flex align-items-center">
									<Form.Label column>{t(uiText.visit.vitals.label.MEASUREMENT_TIME_LABEL)}</Form.Label>
								</Col>
								<Col xs={3} className="d-flex align-items-center">
									<Controller
										name="encounterDate"
										rules={{
											required: true,
										}}
										render={({ field }) => (
											<BandaDatePicker
												dateFormat="yyyy-MM-dd h:mm aa"
												{...field}
												selected={field.value}
												timeInputLabel={`${t(uiText.visit.form.TIME)}:`}
												showTimeInput
												maxDate={new Date()}
											/>
										)}
									/>
									{formMethods.formState.errors?.encounterDate && (
										<span className="text-danger">{t(uiText.visit.vitals.error.MEASURE_TIME_REQUIRED)}</span>
									)}
								</Col>
							</Form.Group>
						</Row>

						<Row className="gy-3">
							<input type="hidden" {...formMethods.register('uuid')} />
							<ObservationFormGroup visitUuid={visitUuid} displayCondensed={true} />
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={onHandleClose}>
							{t(uiText.modal.button.CANCEL)}
						</Button>
						<Button
							type="button"
							variant="success"
							className="ms-auto"
							onClick={(e) => formMethods.handleSubmit(onSubmit)(e)}
						>
							{t(uiText.modal.button.SAVE)}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</FormProvider>
	);
};

export default EncounterModal;
