import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

type BandaDatePickerProps = {
	'aria-label'?: string;
	onChange: (date: Date | null) => void;
	onlyUpdateAfterCalendarClosed?: boolean;
} & ReactDatePickerProps;
type CustomDateInputProps = FormControlProps & React.HTMLAttributes<HTMLInputElement>;

const monthFormat = new Intl.DateTimeFormat('en', {
	month: 'long',
});

const yearFormat = new Intl.DateTimeFormat('en', {
	year: 'numeric',
});

const CustomDateInput = forwardRef<HTMLInputElement, CustomDateInputProps>(
	({ onClick = () => {}, 'aria-label': ariaLabel, ...restOfInputProps }, ref) => {
		const onClickInternal = (e: React.MouseEvent<HTMLInputElement>) => {
			// React has a bug where children of disabled fieldset elements still fire click events (https://github.com/facebook/react/issues/7711)
			// This is a workaround to prevent that for this component
			if (!!(e.target as HTMLElement).closest(':disabled')) {
				return;
			}
			onClick(e);
		};
		return (
			<InputGroup>
				<Form.Control type="text" {...restOfInputProps} onClick={onClickInternal} ref={ref} aria-label={ariaLabel} />
				<InputGroup.Text onClick={onClickInternal}>
					<FontAwesomeIcon icon={faCalendarAlt} />
				</InputGroup.Text>
			</InputGroup>
		);
	},
);

const BandaDatePicker = forwardRef<DatePicker, BandaDatePickerProps>(
	({ onChange, onlyUpdateAfterCalendarClosed, ...props }, ref) => {
		const [showDayPicker, setShowDayPicker] = useState(true);
		const [showMonthPicker, setShowMonthPicker] = useState(false);
		const [showYearPicker, setShowYearPicker] = useState(false);
		const [shouldCloseOnSelect, setShouldCloseOnSelect] = useState(true);
		const mutableSelectedDate = useRef(props.selected || null);
		const mutableIsCalendarOpen = useRef(false);
		const minimumDate = useMemo(() => props.minDate || new Date(-8640000000000000), [props.minDate]);
		const maximumDate = useMemo(() => props.maxDate || new Date(8640000000000000), [props.maxDate]);

		// Ensure the calendar doesn't close in the month or year picker
		useEffect(() => {
			if (showMonthPicker || showYearPicker) {
				setShouldCloseOnSelect(false);
			} else {
				setShouldCloseOnSelect(true);
			}
		}, [showMonthPicker, showYearPicker]);
		// If we're viewing the month, make sure year is not shown
		useEffect(() => {
			if (showMonthPicker) {
				setShowYearPicker(false);
			}
		}, [showMonthPicker]);
		// If we're viewing the month, make sure others are not shown
		useEffect(() => {
			if (showDayPicker) {
				setShowMonthPicker(false);
				setShowYearPicker(false);
			}
		}, [showDayPicker]);
		// If we're viewing the month, make sure others are not shown
		useEffect(() => {
			if (showMonthPicker) {
				setShowDayPicker(false);
				setShowYearPicker(false);
			}
		}, [showMonthPicker]);
		// If we're viewing the year, make sure others are is not shown
		useEffect(() => {
			if (showYearPicker) {
				setShowDayPicker(false);
				setShowMonthPicker(false);
			}
		}, [showYearPicker]);

		const onChangeInternal = (date: Date | null, event?: React.SyntheticEvent<any, Event>) => {
			if (date !== null && date < minimumDate) {
				date = minimumDate;
			} else if (date !== null && date > maximumDate) {
				date = maximumDate;
			}
			if (showYearPicker) {
				setShowMonthPicker(true);
			} else if (showMonthPicker) {
				setShowMonthPicker(false);
			}
			mutableSelectedDate.current = date;
			if (!onlyUpdateAfterCalendarClosed || !mutableIsCalendarOpen.current) {
				onChange(date);
			}
		};

		return (
			<DatePicker
				portalId="root-portal" // react will create this id if it's not found.
				wrapperClassName="w-100"
				dateFormat="yyyy-MM-dd"
				customInput={<CustomDateInput aria-label={props['aria-label']} />}
				renderCustomHeader={
					showYearPicker
						? undefined
						: ({
								date,
								decreaseMonth,
								increaseMonth,
								decreaseYear,
								increaseYear,
								prevMonthButtonDisabled,
								nextMonthButtonDisabled,
								prevYearButtonDisabled,
								nextYearButtonDisabled,
							}) => (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<button
										type="button"
										className="react-datepicker__navigation react-datepicker__navigation--previous"
										onClick={!showMonthPicker ? decreaseMonth : decreaseYear}
										disabled={!showMonthPicker ? prevMonthButtonDisabled : prevYearButtonDisabled}
									>
										<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
											Previous Month
										</span>
									</button>
									<div className="react-datepicker__current-month">
										{!showMonthPicker && (
											<>
												<span className="cursor-pointer" onClick={() => setShowMonthPicker(true)}>
													{monthFormat.format(date)}
												</span>{' '}
											</>
										)}
										<span className="cursor-pointer" onClick={() => setShowYearPicker(true)}>
											{yearFormat.format(date)}
										</span>
									</div>
									<button
										type="button"
										className="react-datepicker__navigation react-datepicker__navigation--next"
										onClick={!showMonthPicker ? increaseMonth : increaseYear}
										disabled={!showMonthPicker ? nextMonthButtonDisabled : nextYearButtonDisabled}
									>
										<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
											Next Month
										</span>
									</button>
								</div>
							)
				}
				{...props}
				onChange={onChangeInternal}
				showMonthYearPicker={showMonthPicker}
				showYearPicker={showYearPicker}
				shouldCloseOnSelect={shouldCloseOnSelect}
				onCalendarOpen={() => (mutableIsCalendarOpen.current = true)}
				onCalendarClose={() => {
					setShowDayPicker(true);
					mutableIsCalendarOpen.current = false;
					if (onlyUpdateAfterCalendarClosed) {
						onChangeInternal(mutableSelectedDate.current);
					}
				}}
				ref={ref}
			/>
		);
	},
);

export default BandaDatePicker;
