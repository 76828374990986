import { useApolloClient } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	C_ChargeForExpenseLinesDocument,
	C_InvoiceAndC_InvoiceLinesSaveOnExpensesFormMutationVariables,
	C_InvoiceForExpenseEditingQuery,
} from '../../graphql/__generated__/graphql';
import { chargeExpenseCategoryFilter, Paging } from '../../models';
import { uiText } from '../../utils/Language';
import { ExpenseFormFields } from './ExpenseForm';
import ExpenseLineItemTableFooter from './ExpenseLineItemTableFooter';
import ExpenseLineItemTableRow, { chargeSortOrder } from './ExpenseLineItemTableRow';

type ExpenseLineItemTableProps = {
	readOnly: boolean;
};

export type ExpenseFormLineFields = {
	C_InvoiceLines: Array<{
		UU: string;
		C_Charge: { UU: string };
		Description: string | null;
		Price: number | null;
	}>;
	addInvoiceLine: {
		C_Charge: { UU: string };
		Description: string | null;
		Price: number | null;
	};
	invoiceLineCount: number;
};

export const ADD_INVOICE_LINE_ROW_NAME = 'addInvoiceLine';

export const convertToExpenseFormLineFields = (
	initialData?: C_InvoiceForExpenseEditingQuery['C_Invoice'],
): ExpenseFormLineFields => {
	let formFields: Pick<ExpenseFormLineFields, 'addInvoiceLine'> = {
		addInvoiceLine: { C_Charge: { UU: '' }, Description: null, Price: null },
	};
	if (!initialData) {
		return {
			...formFields,
			C_InvoiceLines: [],
			invoiceLineCount: 0,
		};
	}
	return {
		...formFields,
		C_InvoiceLines:
			initialData?.C_InvoiceLines?.map((invoiceLine) => ({
				UU: invoiceLine.UU,
				C_Charge: { UU: invoiceLine.C_Charge?.UU || '' },
				Description: invoiceLine.Description || null,
				Price: invoiceLine.PriceEntered,
			})) || [],
		invoiceLineCount: initialData.C_InvoiceLines?.length || 0,
	};
};

export const constructExpenseFormLinesSubmissionObject = (formData: ExpenseFormLineFields, invoiceUU: string) => {
	const invoiceLines: C_InvoiceAndC_InvoiceLinesSaveOnExpensesFormMutationVariables['C_InvoiceLines'] =
		formData.C_InvoiceLines.map((invoiceLine) => ({
			UU: invoiceLine.UU,
			C_Charge: { UU: invoiceLine.C_Charge.UU },
			C_Invoice: { UU: invoiceUU },
			Description: invoiceLine.Description,
			Price: invoiceLine.Price,
			Qty: 1,
		}));
	return invoiceLines;
};

const ExpenseLineItemTable = ({ readOnly }: ExpenseLineItemTableProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		setFocus,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<ExpenseFormFields>();
	const {
		fields = [],
		append,
		remove,
	} = useFieldArray<ExpenseFormLineFields, 'C_InvoiceLines'>({ name: 'C_InvoiceLines' });
	const addNewInvoiceLine = useWatch<ExpenseFormLineFields, 'addInvoiceLine'>({ name: 'addInvoiceLine' });
	const shouldDisplayTheDeleteColumn = !readOnly;
	const shouldFocusOnLastField = useRef(false);

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewInvoiceLine?.C_Charge?.UU) {
			append({
				UU: v4(),
				C_Charge: { UU: addNewInvoiceLine.C_Charge.UU },
				Description: null,
				Price: addNewInvoiceLine.Price,
			});
			setValue('addInvoiceLine', { C_Charge: { UU: '' }, Description: null, Price: null });
			shouldFocusOnLastField.current = true;
		}
	}, [addNewInvoiceLine, fields.length, append, setValue]);
	// If there are fields and we should focus on the last one, do so
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField.current) {
			setFocus(`C_InvoiceLines.${fields.length - 1}.Description`);
			shouldFocusOnLastField.current = false;
		}
	}, [fields.length, setFocus, shouldFocusOnLastField]);
	useEffect(() => {
		setValue('invoiceLineCount', fields.length, { shouldValidate: true });
	}, [fields.length, setValue]);

	const allExpenseCharges = graphqlClient.readQuery({
		query: C_ChargeForExpenseLinesDocument,
		variables: {
			Page: Paging.ALL.page,
			Size: Paging.ALL.size,
			Sort: chargeSortOrder,
			Filter: chargeExpenseCategoryFilter().toString(),
		},
	});

	return (
		(allExpenseCharges?.C_ChargeGet.Results.length && (
			<>
				<input
					type="hidden"
					{...register('invoiceLineCount', {
						valueAsNumber: true,
						validate: (value) => (getValues('submitEvent') === 'complete' ? value > 0 : true),
					})}
					defaultValue={fields.length}
				/>
				<table className="bh-table--form">
					<thead>
						<tr>
							<th className="data-type-text">{t(uiText.expense.lineItem.header.CATEGORY)}</th>
							<th className="data-type-text">{t(uiText.expense.lineItem.header.DESCRIPTION)}</th>
							<th className="data-type-numeric">{t(uiText.expense.lineItem.header.AMOUNT)}</th>
							{shouldDisplayTheDeleteColumn && <th className="data-type-action">{t(uiText.expense.button.DELETE)}</th>}
						</tr>
					</thead>
					<tbody>
						{fields.map((invoiceLine, index) => (
							<ExpenseLineItemTableRow
								key={invoiceLine.UU}
								field={invoiceLine}
								index={index}
								remove={remove}
								shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
								isDataReadOnly={readOnly}
								allExpenseCharges={allExpenseCharges.C_ChargeGet.Results}
							/>
						))}
					</tbody>
					<tbody>
						{!readOnly && (
							<ExpenseLineItemTableRow
								shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
								isAddRow={true}
								allExpenseCharges={allExpenseCharges.C_ChargeGet.Results}
							/>
						)}
					</tbody>
					<tbody>
						<ExpenseLineItemTableFooter shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn} />
					</tbody>
				</table>
				{errors.invoiceLineCount && <span className="text-danger">{t(uiText.expense.error.LINE_ITEM_AMOUNT)}</span>}
			</>
		)) ||
		null
	);
};

export default ExpenseLineItemTable;
