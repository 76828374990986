import Role, { RoleDB, RoleDto } from '../models/Role';
import BaseEntityService from './BaseEntityService';

export default class RoleService extends BaseEntityService<Role, RoleDto, RoleDB> {
	public entityTableName: string = 'ad_role';
	protected entityName = 'roles';

	map(data: RoleDto): Role {
		return new Role(data);
	}

	reverseMap(data: Role): RoleDto {
		return data;
	}
}
