import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';

export interface ClientConceptDB extends BaseMetadataDB {
	name: string;
}

export interface ClientConceptDto extends BaseMetadataDto {
	name?: string;
}

export default class ClientConcept extends BaseMetadata {
	name: string;

	constructor(props?: Partial<ClientConcept | ClientConceptDto>) {
		props = cloneDeep(props || {});
		super(props);

		this.name = props.name || '';
	}
}
