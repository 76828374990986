import { endOfDay } from 'date-fns';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { fieldUuid, Observation, referenceUuids } from '../../models';
import { formatDate } from '../../utils/DateUtil';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import PrintableTextArea from '../PrintableTextArea/PrintableTextArea';

export default function ObservationDisplay({ name, observation }: { name: string; observation: Observation }) {
	const { register } = useFormContext();
	return (
		<fieldset disabled={observation.field.uuid === fieldUuid.BMI} className="w-100">
			{observation.field.column?.reference?.uuid === referenceUuids.STRING ? (
				<Form.Control {...register(name)} />
			) : observation.field.column?.reference?.uuid === referenceUuids.INTEGER ||
			  observation.field.column?.reference?.uuid === referenceUuids.AMOUNT ? (
				<Form.Control step="any" min="0" type="number" {...register(name)} />
			) : observation.field.column?.reference?.uuid === referenceUuids.TEXT_LONG ? (
				<Controller name={name} render={({ field }) => <PrintableTextArea rows={6} {...field} />} />
			) : observation.field.column?.reference?.uuid === referenceUuids.DATE ? (
				<Controller
					name={name}
					render={({ field }) => (
						<BandaDatePicker
							maxDate={endOfDay(new Date())}
							{...field}
							value={(field.value && formatDate(new Date(parseInt(field.value, 10)))) || null}
							onChange={(selectedDate) => {
								let parsedDate: string | null = null;
								if (selectedDate) {
									parsedDate = selectedDate.getTime().toString();
								}
								field.onChange(parsedDate);
							}}
							selected={(field.value && new Date(parseInt(field.value, 10))) || null}
						/>
					)}
				/>
			) : null}
		</fieldset>
	);
}
