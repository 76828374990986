import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { cloneDeep } from 'lodash';
import Locator, { LocatorDto } from './Locator';
import DeepPartial from '../types/DeepPartial';

export const WarehousePropertyNames = {
	AD_ORG_UU: 'AD_Org.AD_Org_UU',
} as const;

export interface WarehouseDto extends BaseEntityDto {
	defaultWarehouse: boolean;
	locators?: LocatorDto[] | null;
}

export interface WarehouseDB extends BaseEntityDB {
	'AD_Org.AD_Org_UU': string;
	BH_DefaultWarehouse: boolean;
	M_Warehouse_UU: string;
}

export default class Warehouse extends BaseEntity {
	defaultWarehouse: boolean;
	locators: Locator[];

	constructor(entity?: DeepPartial<Warehouse | WarehouseDto>) {
		entity = cloneDeep(entity || {});
		super(entity);

		this.defaultWarehouse = entity.defaultWarehouse === undefined ? false : entity.defaultWarehouse;
		this.locators = (entity.locators || []).map((locator) => new Locator(locator));
	}
}
