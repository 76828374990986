import { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/UserContext';
import { PageUuid } from '../services/AuthService';

/**
 * Gets the privileges for the logged-in user for the specified page
 * @param pageUuid The uuid of the page to get privileges for
 */
export default function useActionPrivileges(pageUuid: PageUuid) {
	const initialActions = useContext(UserContext).windowAccessLevels[pageUuid];
	const canView = !!initialActions;
	const canWrite = initialActions?.canWrite || false;
	const canDeactivate = initialActions?.canDeactivate || false;

	const [disableWrite, setDisableWrite] = useState(!canWrite);
	const [disableView, setDisableView] = useState(!canView);
	const [disableDeactivate, setDisableDeactivate] = useState(!canDeactivate);

	useEffect(() => {
		setDisableWrite(!canWrite);
		setDisableDeactivate(!canDeactivate);
		setDisableView(!canView);
	}, [canView, canWrite, canDeactivate, pageUuid]);

	return {
		canWrite: !disableWrite,
		disableWrite,
		canView: !disableView,
		disableView,
		canDeactivate: !disableDeactivate,
		disableDeactivate,
	} as const;
}
