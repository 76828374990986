import {
	BusinessPartner,
	BusinessPartnerSpecificPayerInformation,
	Charge,
	chargeSubType,
	Encounter,
	EncounterTypeWindow,
	Observation,
	PaymentInformation,
	Visit,
} from '../../models';
import { pageUuid } from '../../services/AuthService';
import { isEntityReactivated, isEntityVoided } from '../../utils/StatusUtil';
import { VisitFormFields } from './VisitForm';

export const createNewVitalEncounter = (vitalsEncounterTypeWindow?: EncounterTypeWindow) => {
	return new Encounter({
		encounterDate: new Date(),
		encounterType: vitalsEncounterTypeWindow?.encounterType,
		observations: vitalsEncounterTypeWindow?.window?.tabs?.[0].fields
			.sort((fieldA, fieldB) =>
				fieldA.sequenceNumber < fieldB.sequenceNumber ? -1 : fieldA.sequenceNumber > fieldB.sequenceNumber ? 1 : 0,
			)
			.map((field) => new Observation({ field })),
	});
};

export const prepareVisit = (visit: Visit, encounterTypeWindows: EncounterTypeWindow[]): VisitFormFields => {
	const chiefComplaintEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.CHIEF_COMPLAINT,
	);

	const clinicalEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.CLINICAL_DETAILS,
	);

	const labDiagnosticsEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.LAB_DIAGNOSTICS_DETAILS,
	);

	const vitalsEncounterTypeWindow = encounterTypeWindows.find(
		(encounterTypeWindow) => encounterTypeWindow.window?.uuid === pageUuid.TRIAGE_DETAILS,
	);

	const clinicalDetailsEncounters = visit.encounters.filter(
		(encounter) => encounter.encounterType?.uuid === clinicalEncounterTypeWindow?.encounterType?.uuid,
	);
	const labDiagnosticsEncounters = visit.encounters.filter(
		(encounter) => encounter.encounterType?.uuid === labDiagnosticsEncounterTypeWindow?.encounterType?.uuid,
	);
	const chiefComplaintEncounter = visit.encounters.filter(
		(encounter) => encounter.encounterType?.uuid === chiefComplaintEncounterTypeWindow?.encounterType?.uuid,
	)[0];
	const vitalsEncounters = visit.encounters.filter(
		(encounter) => encounter.encounterType?.uuid === vitalsEncounterTypeWindow?.encounterType?.uuid,
	);
	const encounterDiagnoses = clinicalDetailsEncounters
		.flatMap((encounter) => encounter.encounterDiagnoses)
		.sort((encounterDiagnosisA, encounterDiagnosisB) =>
			encounterDiagnosisA.lineNo < encounterDiagnosisB.lineNo
				? -1
				: encounterDiagnosisA.lineNo > encounterDiagnosisB.lineNo
					? 1
					: 0,
		);
	const encounterDiagnostics = labDiagnosticsEncounters
		.flatMap((encounter) => encounter.encounterDiagnostics)
		.map((encounterDiagnostic) => {
			if (encounterDiagnostic.concept) {
				// We don't need some elements of the concept for the form so remove them
				encounterDiagnostic.concept.conceptExtras = [];
				encounterDiagnostic.concept.toConceptMappings = [];
				encounterDiagnostic.concept.fromConceptMappings = [];
				encounterDiagnostic.concept.conceptNames = [];
				encounterDiagnostic.concept.clientConcepts = [];
			}
			return encounterDiagnostic;
		})
		.sort((encounterDiagnosticA, encounterDiagnosticB) =>
			encounterDiagnosticA.lineNo < encounterDiagnosticB.lineNo
				? -1
				: encounterDiagnosticA.lineNo > encounterDiagnosticB.lineNo
					? 1
					: 0,
		);
	return {
		...visit,
		encounters: undefined,
		orders: visit.orders?.map((order) => ({
			...order,
			orderLines: (order.orderLines || [])
				.filter((orderLine) => !orderLine.charge || orderLine.charge.isNew)
				.map((orderLine) => {
					// We don't need the attribute sets saved to the form, so we'll remove them
					orderLine.product.attributeSet = undefined;
					orderLine.product.price = orderLine.product.price || orderLine.product.sellPrice;
					return orderLine;
				}),
		})),
		invoices: visit.invoices?.filter((invoice) => !isEntityVoided(invoice) && !isEntityReactivated(invoice)) || [],
		paymentInformationList: [
			...visit.invoices
				.filter((invoice) => !isEntityVoided(invoice) && !isEntityReactivated(invoice))
				.map((invoice) =>
					invoice.invoiceLines
						.filter(
							(invoiceLine) =>
								!!(invoiceLine.charge && !invoiceLine.charge.isNew) &&
								invoiceLine.charge.subType.value === chargeSubType.Waiver &&
								invoiceLine.lineNetAmount < 0,
						)
						.map(
							(invoiceLine) =>
								new PaymentInformation({
									...invoiceLine,
									payer: new BusinessPartner(invoice.businessPartner),
									charge: new Charge(invoiceLine.charge),
									businessPartnerSpecificPayerInformationList:
										invoiceLine.businessPartnerSpecificPayerInformationList?.map(
											(businessPartnerSpecificPayerInformation) =>
												new BusinessPartnerSpecificPayerInformation(businessPartnerSpecificPayerInformation),
										),
									amount: invoiceLine.lineNetAmount * -1,
									isPayment: 'false',
									isWaiver: 'true',
									paymentType: invoiceLine.charge?.subType,
								}),
						),
				)
				.flatMap((invoiceLines) => invoiceLines)
				.filter((invoiceLine) => !!invoiceLine),
			...visit.invoices
				.filter(
					(invoice) =>
						!isEntityVoided(invoice) &&
						!isEntityReactivated(invoice) &&
						invoice.businessPartner.uuid !== visit.patient.uuid,
				)
				.map((invoice) =>
					invoice.invoiceLines
						.filter((invoiceLine) => !!(invoiceLine.charge && !invoiceLine.charge.isNew))
						.map(
							(invoiceLine) =>
								new PaymentInformation({
									...invoiceLine,
									payer: new BusinessPartner(invoice.businessPartner),
									charge: new Charge(invoiceLine.charge),
									businessPartnerSpecificPayerInformationList:
										invoiceLine.businessPartnerSpecificPayerInformationList?.map(
											(businessPartnerSpecificPayerInformation) =>
												new BusinessPartnerSpecificPayerInformation(businessPartnerSpecificPayerInformation),
										),
									amount: invoiceLine.lineNetAmount,
									isPayment: 'false',
									isWaiver: 'false',
									paymentType: invoice.businessPartner.businessPartnerGroup?.subType,
								}),
						),
				)
				.flatMap((invoiceLines) => invoiceLines)
				.filter((invoiceLine) => !!invoiceLine),
			...visit.payments
				?.filter((payment) => !isEntityVoided(payment) && !isEntityReactivated(payment))
				.map(
					(payment) =>
						new PaymentInformation({
							...payment,
							amount: payment.tenderAmount,
							isPayment: 'true',
							isWaiver: 'false',
						}),
				),
		],
		businessPartnerPayerInformationList: [],
		isVisitReactivated: isEntityReactivated(visit.orders[0]),
		clinicalDetailsEncounters: (clinicalDetailsEncounters.length
			? clinicalDetailsEncounters
			: [new Encounter({ encounterType: clinicalEncounterTypeWindow?.encounterType })]
		).map((encounter) => ({
			...encounter,
			observations: clinicalEncounterTypeWindow?.window?.tabs?.[0].fields
				.sort((fieldA, fieldB) =>
					fieldA.sequenceNumber < fieldB.sequenceNumber ? -1 : fieldA.sequenceNumber > fieldB.sequenceNumber ? 1 : 0,
				)
				.map(
					(field) =>
						encounter.observations.find((observation) => observation.field.uuid === field.uuid) ||
						new Observation({ field }),
				),
		})),
		labDiagnosticsEncounters: (labDiagnosticsEncounters.length
			? labDiagnosticsEncounters
			: [new Encounter({ encounterType: labDiagnosticsEncounterTypeWindow?.encounterType })]
		).map((encounter) => ({
			...encounter,
			observations: labDiagnosticsEncounterTypeWindow?.window?.tabs?.[0].fields
				.sort((fieldA, fieldB) =>
					fieldA.sequenceNumber < fieldB.sequenceNumber ? -1 : fieldA.sequenceNumber > fieldB.sequenceNumber ? 1 : 0,
				)
				.map(
					(field) =>
						encounter.observations.find((observation) => observation.field.uuid === field.uuid) ||
						new Observation({ field }),
				),
		})),
		vitalsEncounters: vitalsEncounters.map((encounter) => ({
			...encounter,
			observations: vitalsEncounterTypeWindow?.window?.tabs?.[0].fields
				.sort((fieldA, fieldB) =>
					fieldA.sequenceNumber < fieldB.sequenceNumber ? -1 : fieldA.sequenceNumber > fieldB.sequenceNumber ? 1 : 0,
				)
				.map(
					(field) =>
						encounter.observations.find((observation) => observation.field.uuid === field.uuid) ||
						new Observation({ field }),
				),
		})),
		chiefComplaintEncounter: new Encounter({
			...chiefComplaintEncounter,
			encounterType: chiefComplaintEncounterTypeWindow?.encounterType,
			observations: chiefComplaintEncounterTypeWindow?.window?.tabs?.[0].fields.map(
				(field) =>
					chiefComplaintEncounter?.observations.find((observation) => observation.field.uuid === field.uuid) ||
					new Observation({ field }),
			),
		}),
		encounterDiagnoses,
		encounterDiagnostics,
	} as VisitFormFields;
};

type ObservationDisplay = ObservationIndex | ObservationGroupIndex;
export type ObservationIndex = { index: number; observation: Observation };
type ObservationGroupIndex = { index: number; observations: ObservationIndex[] };

export function isFieldGroup(observationDisplay: any): observationDisplay is ObservationGroupIndex {
	return observationDisplay?.observations?.length > 0;
}

export function getObservationFields(observations?: Observation[]) {
	return Object.values(
		observations?.reduce(
			(observationGroups, observation, observationIndex) => {
				if (observation?.field?.fieldGroup?.uuid) {
					if (!observationGroups[observation.field.fieldGroup.uuid]) {
						observationGroups[observation.field.fieldGroup.uuid] = { index: observationIndex, observations: [] };
					}
					(observationGroups[observation.field.fieldGroup.uuid] as unknown as ObservationGroupIndex).observations.push({
						index: observationIndex,
						observation,
					});
				} else {
					observationGroups[observation?.field?.uuid] = { index: observationIndex, observation };
				}
				return observationGroups;
			},
			{} as { [key: string]: ObservationDisplay },
		) || {},
	).sort((observationDisplayA, observationDisplayB) =>
		observationDisplayA.index < observationDisplayB.index
			? -1
			: observationDisplayA.index > observationDisplayB.index
				? 1
				: 0,
	);
}
