import React from 'react';
import { Badge } from 'react-bootstrap';
import { useAsync } from 'react-use';
import useService from '../../hooks/useService';
import DocumentStatus, { DocumentStatusValue } from '../../models/DocumentStatus';

type StatusBadgeProps = { status: DocumentStatusValue };

/**
 * @deprecated use GraphQLStatusBadge
 */
const StatusBadge = ({ status }: StatusBadgeProps) => {
	const { documentStatusService } = useService();

	const { value: { badgeState = '', mappedStatus = '' } = {} } = useAsync(async () => {
		const documentStatusToUse = await documentStatusService.getMappedDocumentStatusForStatusValue(status);
		let badgeState = '';
		switch (documentStatusToUse.value) {
			case DocumentStatus.COMPLETED:
				badgeState = 'success';
				break;
			case DocumentStatus.DRAFTED:
				badgeState = 'primary';
				break;
			case DocumentStatus.IN_PROGRESS:
				badgeState = 'dark';
				break;
			case DocumentStatus.VOIDED:
				badgeState = 'secondary';
				break;
			default:
				badgeState = 'info';
				break;
		}
		return {
			mappedStatus: documentStatusToUse.display,
			badgeState,
		};
	}, [documentStatusService, status]);

	return <Badge bg={badgeState}>{mappedStatus}</Badge>;
};

export default StatusBadge;
