// Make this file a module via an empty export
export {};

// window._env_ is set via the env.sh file that runs and reads any environment variables
// This is due to the fact that the dynamic environment variables are only available at
// build time, not at run time. This script allows environment variables to be read at
// run time. The window._env_ file will be set through the env-config.js fetch in the
// index.html page.
declare global {
	interface Window {
		_env_: NodeJS.Dict<string>;
	}
}

// Add any process.env properties to the window._env object (and let window._env_ override)
window._env_ = {
	...process.env,
	...window._env_,
};
