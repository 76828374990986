import { createContext } from 'react';

export interface ProductRefreshContextInterface {
	triggerUpdate: () => void;
	willTrigger: number;
}

export const ProductRefreshContext = createContext<ProductRefreshContextInterface>({
	willTrigger: 0,
	triggerUpdate: () => {},
});
