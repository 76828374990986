import { UpdateData } from '../contexts/UserContext';
import { AuthorizationResponse, Client, Role, User } from '../models';

export function transformAuthenticationDataToUserContextData(
	clients: Client[],
	authenticationData: AuthorizationResponse,
): Required<UpdateData> {
	const client = clients.find((client) => client.uuid === authenticationData.clientUuid);
	const organization = client?.organizations.find(
		(organization) => organization.uuid === authenticationData.organizationUuid,
	);
	const role = organization?.roles.find((role) => role.uuid === authenticationData.roleUuid);
	const warehouse = organization?.warehouses.find((warehouse) => warehouse.uuid === authenticationData.warehouseUuid);
	if (!client || !organization || !role || !warehouse || !authenticationData.windowAccessLevel) {
		throw Error('something is wrong with the authentication data');
	}
	if (!role.includedRoles.length && authenticationData.includedRoleUuids?.length) {
		role!.includedRoles = authenticationData.includedRoleUuids.map(
			(includedRoleUuid) => new Role({ uuid: includedRoleUuid }),
		);
	}
	return {
		client,
		organization,
		role,
		warehouse,
		user: new User({ name: authenticationData.username, uuid: authenticationData.userUuid }),
		windowAccessLevels: authenticationData.windowAccessLevel,
		availableClients: clients.length ? clients : [client],
	};
}
