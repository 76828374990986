import { useApolloClient, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	M_AttributeSetInstanceDataForMovementsFragmentDoc,
	M_ProductDataForMovementsFragment,
	M_ProductDisplayForMovementsFragmentDoc,
	M_ProductForMovementsDocument,
	M_ProductSearchForMovementsDocument,
	M_WarehouseForMovementFormDocument,
} from '../../graphql/__generated__/graphql';
import { DBFilter, ProductDB } from '../../models';
import ForeignEntityInput from '../../types/ForeignEntityInput';
import { SEARCH } from '../../utils/Constants';
import { formatDate } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import { getAttributeSetInstanceDisplay } from '../../utils/ModelUtils';
import { formatNumber } from '../../utils/NumberUtil';
import EntityLookupGraphQL from '../entity-lookup/EntityLookupGraphQL';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { MovementFormMovementLineFields } from './ProductLineTransferInventoryTable';
import { MovementFormFields } from './TransferInventoryForm';

type TransferInventoryLineItemTableRowProps = {
	field?: MovementFormMovementLineFields['M_MovementLines'][0];
	index?: number;
	remove?: UseFieldArrayReturn['remove'];
	shouldDisplayTheDeleteColumn: boolean;
	isAddRow?: boolean;
	readOnly?: boolean;
};

const TransferInventoryLineItemTableRow = ({
	field,
	index,
	remove,
	shouldDisplayTheDeleteColumn,
	isAddRow,
	readOnly,
}: TransferInventoryLineItemTableRowProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		getValues,
		setValue,
		formState: { isSubmitted },
		clearErrors,
	} = useFormContext<MovementFormMovementLineFields>();

	const fieldPrefix = isAddRow ? 'addNewMovementLine' : `M_MovementLines.${index}`;
	const selectedAttributeSetInstanceUuid: string = useWatch<
		MovementFormFields,
		'M_MovementLines.0.M_AttributeSetInstance.UU'
	>({
		name: `${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_MovementLines.0.M_AttributeSetInstance.UU',
	});
	const [showAttributeSetPickerModal, setShowAttributeSetPickerModal] = useState(false);

	// Warehouse info
	const fromWarehouseUuid: string = useWatch<MovementFormFields, 'BH_From_Warehouse.UU'>({
		name: 'BH_From_Warehouse.UU',
	});
	const toWarehouseUuid: string = useWatch<MovementFormFields, 'BH_To_Warehouse.UU'>({
		name: 'BH_To_Warehouse.UU',
	});
	const [getFromWarehouse, { data: fromWarehouse }] = useLazyQuery(M_WarehouseForMovementFormDocument, {
		fetchPolicy: 'cache-first',
	});
	const [getToWarehouse, { data: toWarehouse }] = useLazyQuery(M_WarehouseForMovementFormDocument, {
		fetchPolicy: 'cache-first',
	});
	useEffect(() => {
		if (fromWarehouseUuid && !isAddRow) {
			getFromWarehouse({ variables: { UU: fromWarehouseUuid } });
		}
	}, [getFromWarehouse, fromWarehouseUuid, isAddRow]);
	useEffect(() => {
		if (toWarehouseUuid && !isAddRow) {
			getToWarehouse({ variables: { UU: toWarehouseUuid } });
		}
	}, [getToWarehouse, toWarehouseUuid, isAddRow]);

	const fromLocator = useMemo(() => {
		const activeLocators = fromWarehouse?.M_Warehouse?.M_Locators?.filter((locator) => locator.IsActive);
		return activeLocators?.find((locator) => locator.IsDefault) || activeLocators?.[0];
	}, [fromWarehouse]);
	const toLocator = useMemo(() => {
		const activeLocators = toWarehouse?.M_Warehouse?.M_Locators?.filter((locator) => locator.IsActive);
		return activeLocators?.find((locator) => locator.IsDefault) || activeLocators?.[0];
	}, [toWarehouse]);

	const productUuid: string = useWatch<MovementFormFields, 'M_MovementLines.0.M_Product.UU'>({
		name: `${fieldPrefix}.M_Product.UU` as 'M_MovementLines.0.M_Product.UU',
	});
	const [getProduct, { data: product, loading: areLoadingProductData }] = useLazyQuery(M_ProductForMovementsDocument, {
		fetchPolicy: 'cache-first',
	});
	useEffect(() => {
		if (productUuid) {
			getProduct({ variables: { UU: productUuid } });
		}
	}, [getProduct, productUuid]);

	const [onSearchProducts, { data: products, loading: areLoadingProducts }] = useLazyQuery(
		M_ProductSearchForMovementsDocument,
		{
			fetchPolicy: 'network-only',
			variables: { Sort: JSON.stringify([['name', 'asc']]), Size: SEARCH.DEFAULT_PAGE_SIZE },
		},
	);

	// Memoize some useful pieces of information
	const selectableBatches = useMemo(
		() =>
			(readOnly
				? [
						graphqlClient.readFragment({
							id: field?.M_AttributeSetInstance.UU,
							fragment: M_AttributeSetInstanceDataForMovementsFragmentDoc,
						}),
					]
				: product?.M_Product?.M_StorageOnHandList?.filter(
						(storageOnHand) => storageOnHand.M_Locator.UU === fromLocator?.UU && storageOnHand.QtyOnHand,
					).map((storageOnHand) => storageOnHand.M_AttributeSetInstance)
			)?.filter((attributeSetInstance) => !!attributeSetInstance?.UU) as
				| Array<NonNullable<M_ProductDataForMovementsFragment['M_StorageOnHandList']>[0]['M_AttributeSetInstance']>
				| undefined,
		[product, readOnly, fromLocator, graphqlClient, field?.M_AttributeSetInstance.UU],
	);
	const selectedBatch = useMemo(
		() =>
			selectableBatches?.find((attributeSetInstance) => attributeSetInstance.UU === selectedAttributeSetInstanceUuid),
		[selectableBatches, selectedAttributeSetInstanceUuid],
	);
	// For the storage, we're only going to filter out those that aren't zero if this isn't a completed movement
	const sourceStorage = useMemo(
		() =>
			readOnly
				? []
				: product?.M_Product?.M_StorageOnHandList?.filter(
						(storageOnHand) => storageOnHand.M_Locator.UU === fromLocator?.UU && storageOnHand.QtyOnHand,
					),
		[readOnly, product, fromLocator],
	);
	const destinationStorage = useMemo(
		() =>
			readOnly
				? []
				: product?.M_Product?.M_StorageOnHandList?.filter(
						(storageOnHand) => storageOnHand.M_Locator.UU === toLocator?.UU && storageOnHand.QtyOnHand,
					),
		[readOnly, product, toLocator],
	);

	useEffect(() => {
		const movementLines = getValues('M_MovementLines');
		const doDuplicateRowsExistValue = movementLines.some(
			(movementLine) =>
				movementLines.filter(
					(movementLineDuplicate) =>
						movementLine.M_Product?.UU &&
						movementLineDuplicate.M_Product?.UU === movementLine.M_Product?.UU &&
						movementLineDuplicate.M_AttributeSetInstance?.UU === movementLine.M_AttributeSetInstance.UU,
				).length > 1,
		)
			? 1
			: 0;
		if (doDuplicateRowsExistValue !== getValues('areProductsWithAttributeSetInstancesDuplicated')) {
			setValue('areProductsWithAttributeSetInstancesDuplicated', doDuplicateRowsExistValue, {
				shouldValidate: isSubmitted,
			});
		}
	}, [fieldPrefix, isAddRow, selectableBatches, setValue, selectedAttributeSetInstanceUuid, getValues, isSubmitted]);

	// When the locator changes, make sure the currently selected ASI is available for the selected product
	useEffect(() => {
		if (!fromLocator || !productUuid || readOnly || areLoadingProductData) {
			return;
		}
		// If the ASI isn't available because of the new locator, clear it
		// Otherwise, if the selected product only has one ASI, set the ASI automatically
		if (selectedAttributeSetInstanceUuid && !selectedBatch) {
			setValue(`${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_MovementLines.0.M_AttributeSetInstance.UU', '');
			// Call clear errors in case one is displayed since a user is changing something
			clearErrors(`${fieldPrefix}.MovementQty` as 'M_MovementLines.0.MovementQty');
		} else if (selectableBatches?.length === 1) {
			setValue(
				`${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_MovementLines.0.M_AttributeSetInstance.UU',
				selectableBatches?.[0].UU,
			);
			// Call clear errors in case one is displayed since a user is changing something
			clearErrors(`${fieldPrefix}.MovementQty` as 'M_MovementLines.0.MovementQty');
		}
	}, [
		fromLocator,
		productUuid,
		selectableBatches,
		selectedAttributeSetInstanceUuid,
		setValue,
		fieldPrefix,
		readOnly,
		clearErrors,
		selectedBatch,
		areLoadingProductData,
	]);

	const doesProductHaveMultipleBatches = selectableBatches?.length || 0 > 1;
	const getQuantityForAttributeSetInstance = (
		attributeSetInstance?: ForeignEntityInput,
		storageOnHandList?: Array<{ M_AttributeSetInstance: ForeignEntityInput; QtyOnHand: number }>,
	) => {
		if (readOnly || isAddRow) {
			return 0;
		}
		return (storageOnHandList || []).reduce(
			(sum, storageOnHand) =>
				sum +
				((!attributeSetInstance || storageOnHand.M_AttributeSetInstance.UU === attributeSetInstance.UU
					? storageOnHand.QtyOnHand
					: 0) || 0),
			0,
		);
	};
	const sourceProductQuantityTotal = getQuantityForAttributeSetInstance(selectedBatch, sourceStorage);
	const destinationProductQuantityTotal = getQuantityForAttributeSetInstance(selectedBatch, destinationStorage);

	return (
		<tr>
			<td>
				<Modal show={showAttributeSetPickerModal} onHide={() => setShowAttributeSetPickerModal(false)}>
					<Modal.Header>
						<Modal.Title>
							{t(
								doesProductHaveMultipleBatches && !readOnly
									? uiText.transferInventory.labels.SELECT_BATCH
									: uiText.transferInventory.labels.VIEW_BATCH,
							)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<table className="bh-table--form">
							<thead>
								<tr>
									{doesProductHaveMultipleBatches && !readOnly && <th />}
									<th>{t(uiText.transferInventory.labels.EXPIRATION)}</th>
									<th>{t(uiText.product.RECEIVED_ON)}</th>
									<th>{t(uiText.product.BUY_PRICE)}</th>
									<th>{t(uiText.product.quantity.LABEL)}</th>
								</tr>
							</thead>
							<tbody>
								{sortBy(selectableBatches, ['guaranteeDate', 'purchaseDate', 'purchasePrice'])
									.filter(
										(attributeSetInstance) =>
											(readOnly && attributeSetInstance.UU === selectedAttributeSetInstanceUuid) || !readOnly,
									)
									.map((attributeSetInstance) => (
										<tr
											onClick={() => {
												if (doesProductHaveMultipleBatches && !readOnly) {
													setValue(
														`${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_MovementLines.0.M_AttributeSetInstance.UU',
														attributeSetInstance.UU,
													);
												}
											}}
											key={attributeSetInstance.UU}
										>
											{doesProductHaveMultipleBatches && !readOnly && (
												<td className="text-center">
													<fieldset disabled={readOnly}>
														<Form.Check
															type="radio"
															id={`${fieldPrefix}.attributeSetInstance.uuid`}
															value={attributeSetInstance.UU}
															{...register(
																`${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_MovementLines.0.M_AttributeSetInstance.UU',
																{
																	required: !isAddRow && product?.M_Product?.M_AttributeSet?.UU,
																},
															)}
															aria-label={getAttributeSetInstanceDisplay(attributeSetInstance)}
															checked={selectedAttributeSetInstanceUuid === attributeSetInstance.UU}
														/>
													</fieldset>
												</td>
											)}
											<td className="text-center">
												{attributeSetInstance.GuaranteeDate
													? formatDate(new Date(attributeSetInstance.GuaranteeDate))
													: ''}
											</td>
											<td className="text-center">
												{attributeSetInstance.PurchaseDate
													? formatDate(new Date(attributeSetInstance.PurchaseDate))
													: ''}
											</td>
											<td className="text-end pe-2">{formatNumber(attributeSetInstance.PurchasePrice || 0)}</td>
											<td className="text-end pe-2">
												{formatNumber(getQuantityForAttributeSetInstance(attributeSetInstance, sourceStorage))}
											</td>
										</tr>
									))}
							</tbody>
						</table>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={() => setShowAttributeSetPickerModal(false)}>
							{t(
								readOnly || !doesProductHaveMultipleBatches
									? uiText.transferInventory.button.CLOSE
									: uiText.transferInventory.SELECT,
							)}
						</Button>
					</Modal.Footer>
				</Modal>
				{!isAddRow && (
					<input
						type="hidden"
						{...register(`${fieldPrefix}.UU` as 'M_MovementLines.0.UU')}
						defaultValue={field?.UU || ''}
					/>
				)}
				{!isAddRow && (
					<input
						type="hidden"
						{...register(`${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_MovementLines.0.M_AttributeSetInstance.UU')}
						defaultValue={field?.M_AttributeSetInstance?.UU || ''}
					/>
				)}
				<EntityLookupGraphQL<MovementFormMovementLineFields, 'M_MovementLines.0.M_Product'>
					name={`${fieldPrefix}.M_Product` as 'M_MovementLines.0.M_Product'}
					rules={{ required: !isAddRow }}
					defaultValue={field?.M_Product}
					isLoading={areLoadingProducts}
					id={`${fieldPrefix}.M_Product`}
					delay={500}
					inputProps={{ 'aria-label': t(uiText.transferInventory.labels.PRODUCT) }}
					emptyLabel={t(uiText.product.search.EMPTY)}
					ignoreDiacritics={true}
					labelKey={(data) =>
						graphqlClient.readFragment({ id: data.UU, fragment: M_ProductDisplayForMovementsFragmentDoc })?.Name || ''
					}
					minLength={2}
					placeholder={t(uiText.product.search.PLACEHOLDER)}
					promptText={t(uiText.product.search.SEARCHING)}
					searchText={t(uiText.product.search.SEARCHING)}
					options={products?.M_ProductGet.Results || []}
					onSearch={(query) =>
						onSearchProducts({
							variables: {
								Filter: DBFilter<ProductDB>()
									.property('name')
									.contains(query)
									.nested('m_storageonhand')
									.sumOf('qtyonhand')
									.doesNotEqual(0)
									.up()
									.toString(),
							},
						})
					}
					disabled={readOnly}
				/>
			</td>
			<td>
				{!product?.M_Product?.M_AttributeSet?.UU || isAddRow ? (
					<Form.Control
						aria-label={t(uiText.transferInventory.labels.BATCH)}
						value=""
						className="text-end"
						disabled={true}
					/>
				) : selectableBatches?.length && selectableBatches.length > 0 ? (
					<InputGroup className="cursor-pointer" onClick={() => setShowAttributeSetPickerModal(true)}>
						<Form.Control
							aria-label={t(uiText.transferInventory.labels.BATCH)}
							value={
								getAttributeSetInstanceDisplay(selectedBatch) ||
								(t(uiText.transferInventory.labels.SELECT_BATCH) as string)
							}
							readOnly={true}
							aria-describedby={uiText.transferInventory.button.EDIT}
							className="cursor-pointer"
						/>
						<InputGroup.Text id={uiText.transferInventory.button.EDIT}>
							<FontAwesomeIcon icon={['fas', 'edit']} />
						</InputGroup.Text>
					</InputGroup>
				) : (
					<Form.Control
						aria-label={t(uiText.transferInventory.labels.BATCH)}
						value={t(uiText.transferInventory.NO_BATCHES_AVAILABLE) as string}
						readOnly={true}
						aria-describedby={uiText.transferInventory.button.EDIT}
					/>
				)}
			</td>
			<td>
				<FormatNumberInput
					aria-label={t(uiText.transferInventory.labels.EXISTING_QUANTITY_IN_SOURCE)}
					value={sourceProductQuantityTotal}
					readOnly={true}
				/>
			</td>
			<td>
				<FormatNumberInput
					aria-label={t(uiText.transferInventory.labels.EXISTING_QUANTITY_IN_DESTINATION)}
					value={destinationProductQuantityTotal}
					readOnly={true}
				/>
			</td>
			<td>
				<Controller<MovementFormMovementLineFields, 'M_MovementLines.0.MovementQty'>
					name={`${fieldPrefix}.MovementQty` as 'M_MovementLines.0.MovementQty'}
					defaultValue={
						getValues(`${fieldPrefix}.MovementQty` as 'M_MovementLines.0.MovementQty') || field?.MovementQty
					}
					rules={{
						required: !isAddRow,
						validate: (value) => isAddRow || ((value || 0) > 0 && (value || 0) <= sourceProductQuantityTotal),
					}}
					render={({ field }) => (
						<FormatNumberInput aria-label={t(uiText.transferInventory.labels.QUANTITY_TO_TRANSFER)} {...field} />
					)}
				/>
			</td>
			{shouldDisplayTheDeleteColumn && (
				<td className="text-center align-middle">
					{!isAddRow && (
						<button
							aria-label={t(uiText.transferInventory.button.DELETE)}
							className="btn p-0 w-100"
							tabIndex={-1}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default TransferInventoryLineItemTableRow;
