import { useCallback, useRef, useState } from 'react';

/**
 * A hook that wraps functionality to help trigger updates for hooks that are self-contained
 * (i.e. have no dependencies), yet need to refresh occasionally
 * @returns A function, willTrigger, that is meant to be included in a hook's dependency array
 * to allow it to fire again, plus a function, trigger, that can be called to trigger the update
 */
export default function useTriggerUpdate() {
	const [willTrigger, setWillTrigger] = useState(0);
	// We don't want this function to actually update each time, so we'll use a reference
	const willTriggerReference = useRef(willTrigger);
	const triggerUpdate = useCallback(() => {
		setWillTrigger(++willTriggerReference.current);
	}, []);

	return { willTrigger, triggerUpdate } as const;
}
