import axios from 'axios';
import { isArray } from 'lodash';
import Paging from '../models/Paging';
import { ProcessInformationParameter } from '../models/ProcessInformationParameter';
import Reference from '../models/Reference';
import ReferenceList from '../models/ReferenceList';
import Report, { ReportDto } from '../models/Report';
import ReportParameter from '../models/ReportParameter';
import ReportParameterValue from '../models/ReportParameterValue';
import { IS_ACTIVE } from '../utils/CommonFilters';
import { configs } from '../utils/Configs';
import BaseEntityService from './BaseEntityService';

export const ReportTypes = {
	PDF: 'PDF',
	XLS: 'XLS',
	HTML: 'HTML',
	CSV: 'CSV',
} as const;

type ReportOutput = (typeof ReportTypes)[keyof typeof ReportTypes];

export default class ReportService extends BaseEntityService<Report, ReportDto> {
	public entityTableName: string = 'ad_process';
	entityName = 'processes';
	localStorageKey = 'allUsersReports';

	/**
	 * Generates iDempiere reports
	 * @param reportUuid The UUID of the report to run
	 * @param parameterValues The values to pass to the report
	 * @param reportOutput The format to output the report in
	 * @returns The report file
	 */
	async generateReport(reportUuid: string, parameterValues: ReportParameterValue[], reportOutput: ReportOutput) {
		return this.sendGenerateReportRequest(
			reportUuid,
			await this.mapParameters(reportUuid, parameterValues),
			reportOutput,
		);
	}

	/**
	 * Runs iDempiere process with parameters
	 * @param  processUuid The UUID of the report to run
	 * @param parameterValues The values to pass to the report
	 * @returns The result of the process execution
	 */
	async runProcess(processUuid: string, parameterValues?: ReportParameterValue[]) {
		return axios.post<string>(
			`${configs.apiUrl}/${this.entityName}/run/${processUuid}`,
			await this.mapParameters(processUuid, parameterValues),
		);
	}

	private async mapParameters(
		processUuid: string,
		parameterValues?: ReportParameterValue[],
	): Promise<ProcessInformationParameter[] | undefined> {
		const processToUse = await this.getReportFromUuid(processUuid);
		let processParameters: ProcessInformationParameter[] | undefined;
		if (isArray(processToUse.parameters) && parameterValues !== undefined) {
			processParameters = processToUse.parameters.map((parameter) => {
				return {
					processParameterUuid: parameter.uuid,
					parameter: this.getValueForParameter(parameter, parameterValues),
				};
			});
		}
		return processParameters;
	}

	/**
	 * Get the report matching this UUID
	 * @param reportUuid The UUID of the report to find
	 * @returns The report to use
	 */
	private async getReportFromUuid(reportUuid: string) {
		const reports = await this.getAll();
		const reportToUse = reports.find((report) => report.uuid === reportUuid);
		if (!reportToUse) {
			throw new Error(`No report found for ID ${reportUuid}`);
		}
		return reportToUse;
	}

	/**
	 * Generate an iDempiere report where only one parameter is needed
	 * @param reportUuid The UUID of the report to run
	 * @param parameterValue The value to pass to the report
	 * @param reportOutput The format to output the report in
	 */
	async generateReportWithGivenParameterValue(reportUuid: string, parameterValue: string, reportOutput: ReportOutput) {
		const reportToUse = await this.getReportFromUuid(reportUuid);
		let reportParameter: { processParameterUuid: string; parameter: string } | undefined;
		if (isArray(reportToUse.parameters)) {
			if (reportToUse.parameters.length > 1) {
				throw new Error('This report needs values specified for more than one parameter');
			}
			reportParameter = {
				processParameterUuid: reportToUse.parameters[0].uuid,
				parameter: parameterValue,
			};
		}
		return this.sendGenerateReportRequest(
			reportToUse.uuid,
			reportParameter ? [reportParameter] : reportParameter,
			reportOutput,
		);
	}

	/**
	 * Send the request to the server
	 * @param reportUuid The report UUID to use in the call
	 * @param reportParameters The parameters to pass for this report
	 * @param reportOutput The output type of the report
	 * @returns The result of the call
	 */
	private async sendGenerateReportRequest(
		reportUuid: string,
		reportParameters: { processParameterUuid: string; parameter?: any }[] | undefined,
		reportOutput: ReportOutput,
	): Promise<Blob> {
		return axios
			.post<Blob>(
				`${configs.apiUrl}/${this.entityName}/run-and-export/${reportUuid}/${reportOutput.toUpperCase()}`,
				reportParameters,
				{
					responseType: 'blob',
				},
			)
			.then(({ data }) => data);
	}

	/**
	 * Get thevalue for the given parameter
	 * @param parameter The parameter to get a value for
	 * @param parameterValues The values to search through to match the specified parameter
	 */
	private getValueForParameter(parameter: { uuid: string; name: string }, parameterValues: ReportParameterValue[]) {
		if (!parameter) {
			throw new Error('Invalid report - no parameter');
		}
		const parameterValue = parameterValues.find((parameterValue) => parameterValue.uuid === parameter.uuid);
		if (!parameterValue) {
			throw new Error(`Parameter name mapping not defined for parameter ${parameter.name}`);
		}
		return parameterValue.value;
	}

	/**
	 * Fetch available reports
	 *
	 * @param {boolean} useCache Whether the cache should be used or not
	 * @returns {Promise<Report[]>} The available reports
	 */
	async getAll(useCache?: boolean): Promise<Report[]> {
		useCache = useCache === undefined ? true : useCache;
		let metaData: Report[] = this.getLocal();
		if (metaData.length && useCache) {
			return metaData;
		}
		metaData = await super
			.get(Paging.ALL.page, Paging.ALL.size, [], IS_ACTIVE)
			.then((response) => response && response.results)
			.catch((error) => {
				console.log(error);
				return [];
			});
		localStorage.setItem(this.localStorageKey, JSON.stringify(metaData));
		return metaData;
	}

	/**
	 * Get the data stored locally (may be an empty array if not initialized yet)
	 */
	getLocal(): Report[] {
		let metaData = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
		if (metaData && isArray(metaData) && metaData.length) {
			return metaData.map(this.map);
		}
		return [];
	}

	/**
	 * Get the available manual reports that this user has access to
	 *
	 * @returns {Promise<Report[]>} The available manual reports
	 */
	async getReportsThatNeedToInputBeforeBeingRun(): Promise<Report[]> {
		return (await this.getAll()).filter((report) => report.needsManualInput);
	}

	map(report: ReportDto) {
		if (report && isArray(report.parameters)) {
			report.parameters = report.parameters.map((parameter) => {
				return new ReportParameter({
					...parameter,
					reference: new Reference(parameter.reference),
					referenceValues: isArray(parameter.referenceValues)
						? parameter.referenceValues.map((referenceValue) => new ReferenceList(referenceValue))
						: [],
				});
			});
		}
		return new Report(report);
	}

	reverseMap(data: Report): ReportDto {
		throw new Error('Method not implemented.');
	}

	/**
	 * Determine if the user has access to any reports that need manual input before they can be run
	 * @returns {boolean} Whether the user has access to any reports that need manual input
	 */
	async doesUserHaveAccessToReportsThatNeedToInputBeforeBeingRun(): Promise<boolean> {
		return (await this.getAll()).some((report) => report.needsManualInput);
	}

	/**
	 * Determine if the user has access to the given report
	 * @param {string} reportUuid The report UUID to check for the user
	 * @returns {boolean} Whether the user has access to the specified report
	 */
	async doesUserHaveAccessToReport(reportUuid: string): Promise<boolean> {
		return (await this.getAll()).some((report) => report.uuid === reportUuid);
	}
}
