import { useApolloClient } from '@apollo/client';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import logo from '../../assets/images/b-logo.png';
import UserContext from '../../contexts/UserContext';
import { SignInDocument } from '../../graphql/__generated__/graphql';
import useService from '../../hooks/useService';
import { Role, Warehouse } from '../../models';
import AuthorizationResponse from '../../models/AuthorizationResponse';
import Client from '../../models/Client';
import { convertLocaleToBackend } from '../../models/Language';
import Organization from '../../models/Organization';
import { exception } from '../../utils/analytics';
import { transformAuthenticationDataToUserContextData } from '../../utils/AuthenticationUtil';
import {
	BANDA_HEALTH_CONTACT_PAGE,
	BANDA_HEALTH_HOME_PAGE,
	LANGUAGE_LOCALE,
	OK,
	SAVE_PASSWORD,
	SAVE_REMEMBER_ME,
	SAVE_USERNAME,
	TOS_PAGE,
	VISITS_PAGE,
} from '../../utils/Constants';
import { getErrorMessage } from '../../utils/ErrorUtil';
import { uiText } from '../../utils/Language';
import PasswordInput from '../password-input/PasswordInput';
import ChangePassword from './ChangePassword';
import './LoginPanel.scss';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

export default function LoginPanel() {
	const { t, i18n } = useTranslation();
	const {
		authService,
		encounterTypeWindowService,
		languageService,
		metadataService,
		productCategoryTypeService,
		productCategoryService,
		processTypeService,
		documentStatusService,
		reportService,
	} = useService();

	const [loginUserName, setLoginUserName] = useState(localStorage.getItem(SAVE_USERNAME) || '');
	const [loginPassword, setLoginPassword] = useState(localStorage.getItem(SAVE_PASSWORD) || '');
	const changedPassword = useRef('');
	const [rememberMe, setRememberMe] = useState(localStorage.getItem(SAVE_REMEMBER_ME) || false);
	const [onLoginErrors, setOnLoginErrors] = useState(false);
	const [clients, setClients] = useState<Client[]>([]);
	const [roles, setRoles] = useState<Role[]>([]);
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
	const [clientUuid, setClientUuid] = useState<string | undefined>();
	const [organizationUuid, setOrganizationUuid] = useState<string | undefined>('');
	const [warehouseUuid, setWarehouseUuid] = useState<string | undefined>();
	const [roleUuid, setRoleUuid] = useState<string | undefined>();
	const [errorMessage, setErrorMessage] = useState('');
	const [needsToResetPassword, setNeedsToResetPassword] = useState(false);
	const [changePasswordError, setChangePasswordError] = useState('');
	const [isLoggingIn, setIsLoggingIn] = useState(false);

	const { update } = useContext(UserContext);
	const history = useHistory();

	const graphqlClient = useApolloClient();

	const { value: languages } = useAsync(async () => (await languageService.get()).results, [languageService]);
	useEffect(() => {
		// Confirm the desired language is in the list
		let languageLocaleToPotentiallyUpdate = i18n.language;
		if (languages?.length) {
			let languageModel = languages.find(
				(availableLanguage) =>
					availableLanguage.locale === languageLocaleToPotentiallyUpdate ||
					availableLanguage.languageISO === languageLocaleToPotentiallyUpdate,
			);
			if (!languageModel) {
				languageModel = languages.find((availableLanguage) => availableLanguage.isBaseLanguage);
			}
			if (languageModel) {
				languageLocaleToPotentiallyUpdate = languageModel.locale;
			}
		}
		if (languageLocaleToPotentiallyUpdate !== i18n.language) {
			i18n.changeLanguage(languageLocaleToPotentiallyUpdate);
			localStorage.setItem(LANGUAGE_LOCALE, languageLocaleToPotentiallyUpdate);
		}
	}, [languages, i18n]);

	/**
	 * Retrieve metadata and store in the local storage. This prevents pages from repeatedly fetching the data.
	 */
	const fetchMetadata = () => {
		metadataService.getPatientTypes(false);
		metadataService.getReferrals(false);

		productCategoryTypeService.get(false);
		productCategoryService.get(false);
		processTypeService.get(false);
		documentStatusService.get(false);
		reportService.getAll(false);
		encounterTypeWindowService.get(false);
	};

	const onLogin =
		(handler: (authData: AuthorizationResponse) => void) => async (event: React.MouseEvent<HTMLButtonElement>) => {
			event.preventDefault();

			if (!validate()) {
				return;
			}

			localStorage.clear(); //clear all items for this domain
			await graphqlClient.cache.reset();

			// check remember me checkbox
			if (rememberMe) {
				localStorage.setItem(SAVE_USERNAME, loginUserName);
				localStorage.setItem(SAVE_PASSWORD, loginPassword);
				localStorage.setItem(SAVE_REMEMBER_ME, rememberMe.toString());
			}

			setOnLoginErrors(false);
			setIsLoggingIn(true);

			try {
				// Send both the REST & GraphQL request (if this is an actual login) at the same time so they run in parallel, but only work with what REST returns
				const [response] = await Promise.all([
					authService.login(
						loginUserName,
						loginPassword,
						clientUuid || '',
						roleUuid || '',
						organizationUuid || '',
						warehouseUuid || '',
						convertLocaleToBackend(i18n.language),
					),
					clientUuid && roleUuid && organizationUuid && warehouseUuid
						? graphqlClient
								.mutate({
									mutation: SignInDocument,
									variables: {
										Credentials: {
											AD_Language: convertLocaleToBackend(i18n.language),
											Username: loginUserName,
											Password: loginPassword,
											AD_Client_UU: clientUuid,
											AD_Org_UU: organizationUuid,
											AD_Role_UU: roleUuid,
											M_Warehouse_UU: warehouseUuid,
										},
									},
								})
								.catch(() => {
									exception({ description: `couldn't generate GraphQL cookie` });
								})
						: undefined,
				]);
				const authData = response.data;

				if (response.status === 200 || authData.status === OK) {
					handler(authData);
				}
				setIsLoggingIn(false);
			} catch (error: any) {
				if (error.response?.status === 401) {
					setOnLoginErrors(true);
					setErrorMessage(t(uiText.login.error.WRONG_USERNAME_PASSWORD));
				} else {
					setOnLoginErrors(true);
					setErrorMessage(error.response?.statusText);
				}
				setIsLoggingIn(false);
			}
		};

	const validate = () => {
		if (!loginUserName) {
			setOnLoginErrors(true);
			setErrorMessage(t(uiText.login.error.ENTER_USERNAME));

			return false;
		}

		if (!loginPassword) {
			setOnLoginErrors(true);
			setErrorMessage(t(uiText.login.error.ENTER_PASSWORD));

			return false;
		}

		return true;
	};

	const handleResponse = (authData: AuthorizationResponse) => {
		if (authData.needsToResetPassword) {
			setNeedsToResetPassword(authData.needsToResetPassword);
			return;
		} else {
			setNeedsToResetPassword(false);
		}
		const clients = authData.clients || [];
		// user with no client assigned
		if (!clients.length) {
			setOnLoginErrors(true);
			setErrorMessage(t(uiText.login.error.NO_CLIENTS));
		} else if (
			clients.length === 1 &&
			clients[0].organizations?.length === 1 &&
			clients[0].organizations[0].roles.length === 1 &&
			clients[0].organizations[0].warehouses.length === 1
		) {
			// user with one of everything assigned.. no need to select client, role, warehouse, organization.
			const client = new Client(clients[0]);
			setSelectedClient(client);
			// Since the token returned by REST has everything, we need to manually log in with GraphQL
			setLoginProperties(authData, client, true);
		} else {
			// show clients dropdown to choose from
			setClients(clients.map((client) => new Client(client)));
		}
	};

	const handleSelectionResponse = (authData: AuthorizationResponse) => {
		setNeedsToResetPassword(false);
		let client: Client | undefined;
		if (authData.clients?.[0]) {
			client = new Client(authData.clients[0]);
		}
		setLoginProperties(authData, client, false);
	};

	const setLoginProperties = async (
		authData: AuthorizationResponse,
		client: Client | undefined,
		loginWithGraphQL: boolean,
	) => {
		let isLoggedIn = false;
		let hasAgreedToTerms = false;
		if (authData !== undefined) {
			isLoggedIn = true;
			hasAgreedToTerms = !!authData.hasAcceptedTermsOfUse;
		}

		if (client !== undefined) {
			const contextData = transformAuthenticationDataToUserContextData(clients.length ? clients : [client], authData);
			update(contextData);

			if (loginWithGraphQL) {
				// Import the GraphQL service directly and generate a cookie asynchronously (i.e. we won't wait on it)
				try {
					await graphqlClient.mutate({
						mutation: SignInDocument,
						variables: {
							Credentials: {
								AD_Language: convertLocaleToBackend(i18n.language),
								Username: loginUserName,
								Password: changedPassword.current || loginPassword,
								AD_Client_UU: contextData.client.uuid,
								AD_Org_UU: contextData.organization.uuid,
								AD_Role_UU: contextData.role.uuid,
								M_Warehouse_UU: contextData.warehouse.uuid,
							},
						},
					});
				} catch {
					exception({ description: `couldn't generate GraphQL cookie` });
				}
			}

			// load metadata
			fetchMetadata();

			makeUserAgreeToTerms(isLoggedIn, hasAgreedToTerms);
		}
	};

	const selectClient = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const clientUuid = event.target.value;
		const client = clients.find((client) => client.uuid === clientUuid);
		setSelectedClient(client);
		setClientUuid(clientUuid);
	};

	const setSelectedClient = (client?: Client) => {
		if (client !== undefined) {
			const organizations = client.organizations;
			if (organizations !== undefined) {
				const organization = organizations[0];
				const organizationUuid = organization.uuid;

				const roles = organization.roles;
				const roleUuid = roles[0].uuid;
				const warehouses = organization.warehouses;
				const warehouseUuid = warehouses[0].uuid;

				setOrganizations(organizations);
				setRoles(roles);
				setWarehouses(warehouses);
				setOrganizationUuid(organizationUuid);
				setRoleUuid(roleUuid);
				setWarehouseUuid(warehouseUuid);
			}
		}
	};

	const makeUserAgreeToTerms = (isLoggedIn: boolean, hasAgreedToTerms: boolean) => {
		if (isLoggedIn) {
			history.push(hasAgreedToTerms ? VISITS_PAGE : TOS_PAGE);
		}
	};

	const changePassword = async (newPassword: string) => {
		try {
			const response = await authService.changePassword(
				loginUserName,
				loginPassword,
				newPassword,
				convertLocaleToBackend(i18n.language),
			);
			const authData = response.data;

			if (response.status === 200 || authData.status === OK) {
				setChangePasswordError('');
				setLoginPassword(newPassword);
				changedPassword.current = newPassword;
				handleResponse(authData);
			} else {
				setChangePasswordError(t(uiText.login.error.ERROR_OCCURRED));
			}
		} catch (error: any) {
			setChangePasswordError(getErrorMessage(error));
		}
	};

	return (
		<Row className="login">
			<Col xs={4} className="login login__sidebar d-flex flex-column justify-content-between">
				<Row>
					<Col className="login login__logo pt-4">
						<a
							href={BANDA_HEALTH_HOME_PAGE}
							target={'_blank'}
							aria-label={BANDA_HEALTH_HOME_PAGE}
							rel="noopener noreferrer"
						>
							<img src={logo} width="100px" alt="BandaHealth Logo" />
						</a>
					</Col>
				</Row>
				<Row>
					<Col>
						<h1>{t(uiText.login.WELCOME)}</h1>
						<p className="fw-light">{t(uiText.login.MOTTO)}</p>
					</Col>
				</Row>
				<Row className="login login__footer pb-2">
					<Col xs={8}>
						<small>&copy;BandaHealth {new Date().getFullYear()}</small>
					</Col>
					<Col xs={2}>
						<small>
							<a href={BANDA_HEALTH_HOME_PAGE} target={'_blank'} rel="noopener noreferrer">
								{t(uiText.login.ABOUT)}
							</a>
						</small>
					</Col>
					<Col xs={2}>
						<small>
							<a href={BANDA_HEALTH_CONTACT_PAGE} target={'_blank'} rel="noopener noreferrer">
								{t(uiText.login.CONTACT)}
							</a>
						</small>
					</Col>
				</Row>
			</Col>

			<Col xs={8}>
				<Form autoComplete="off" className="login login__form">
					<Col xs={5}>
						<Row>
							<Col className="mb-5">
								<LoadSpinner show={isLoggingIn} title={t(uiText.login.LOADING)} inline={true}></LoadSpinner>
							</Col>
						</Row>
						{needsToResetPassword ? (
							<ChangePassword onSubmit={changePassword} serverErrorMessage={changePasswordError} />
						) : (
							<>
								{clients.length === 0 ? (
									<>
										<Row>
											<Col className="login login__form__header">
												<h4>{t(uiText.login.SIGN_IN)}</h4>
												<h6>{t(uiText.login.ENTER_USERNAME_PASSWORD)}</h6>
											</Col>
										</Row>
										<Row>
											{onLoginErrors === true && (
												<Col xs={8} className="p-1 m-auto bg-danger text-center rounded text-white">
													<span id="error" data-testid="error">
														{errorMessage}
													</span>
												</Col>
											)}
										</Row>
										<Form.Group as={Row} controlId="loginUserName" className="my-2">
											<Form.Control
												onChange={(e) => setLoginUserName(e.target.value)}
												placeholder={t(uiText.login.USERNAME_OR_EMAIL)}
												value={loginUserName}
											/>
										</Form.Group>
										<Form.Group as={Row} controlId="loginPassword" className="mb-2">
											<PasswordInput onChange={(e) => setLoginPassword(e.target.value)} value={loginPassword} />
										</Form.Group>
										{(languages?.length || 0) > 1 && (
											<Form.Group as={Row} controlId="loginLanguage" className="mb-2">
												<Form.Select onChange={(e) => i18n.changeLanguage(e.target.value)} value={i18n.language}>
													{languages?.map((language) => (
														<option key={language.uuid} value={language.locale}>
															{language.printName}
														</option>
													))}
												</Form.Select>
											</Form.Group>
										)}
									</>
								) : (
									<>
										<Row className="mb-2">
											<div className="login login__form__header">
												<h5>{t(uiText.login.SELECT_CLIENT)}</h5>
											</div>
										</Row>
										<Form.Group as={Row} className="mb-2" controlId="clientId">
											<Form.Select name="clientId" onChange={selectClient} aria-label={t(uiText.login.SELECT_CLIENT)}>
												<option></option>
												{clients &&
													clients.map((client) => (
														<option key={client.uuid} value={client.uuid}>
															{client.name}
														</option>
													))}
											</Form.Select>
										</Form.Group>

										<Form.Group as={Row} className="mb-2" controlId="roleUuid">
											<Form.Select name="roleUuid" onChange={(event) => setRoleUuid(event.target.value)}>
												{(roles || []).map((role) => (
													<option key={role.uuid} value={role.uuid}>
														{role.name}
													</option>
												))}
											</Form.Select>
										</Form.Group>

										<Form.Group as={Row} className="mb-2" controlId="organizationUuid">
											<Form.Select name="organizationUuid" onChange={(e) => setOrganizationUuid(e.target.value)}>
												{organizations &&
													organizations.map((organization) => (
														<option key={organization.uuid} value={organization.uuid}>
															{organization.name}
														</option>
													))}
											</Form.Select>
										</Form.Group>

										<Form.Group as={Row} className="mb-2" controlId="warehouseUuid">
											<Form.Select name="warehouseUuid" onChange={(event) => setWarehouseUuid(event.target.value)}>
												{warehouses &&
													warehouses.map((warehouse) => (
														<option key={warehouse.uuid} value={warehouse.uuid}>
															{warehouse.name}
														</option>
													))}
											</Form.Select>
										</Form.Group>
									</>
								)}
								<Row className="mb-2 align-items-center" key="loginButton">
									<Col xs={7} className="ps-0">
										<Form.Group controlId="rememberMe">
											<Form.Check
												onChange={(e) => setRememberMe(e.target.checked)}
												defaultChecked={rememberMe === 'true' || rememberMe === true}
												value={rememberMe.toString()}
												label={t(uiText.login.REMEMBER_ME)}
											/>
										</Form.Group>
									</Col>
									<Col xs={5} className="text-end pe-0">
										<Button
											type="submit"
											variant="secondary"
											disabled={isLoggingIn}
											onClick={clients.length === 0 ? onLogin(handleResponse) : onLogin(handleSelectionResponse)}
										>
											<FontAwesomeIcon icon={faSignInAlt} className="me-1" />
											<span>{t(uiText.login.LOG_IN)}</span>
										</Button>
									</Col>
								</Row>
							</>
						)}
					</Col>
				</Form>
			</Col>
		</Row>
	);
}
