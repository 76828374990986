import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import useBillingTotals from '../../hooks/useBillingTotals';
import useService from '../../hooks/useService';
import PaymentInformation from '../../models/PaymentInformation';
import ReferenceList from '../../models/ReferenceList';
import { uiText } from '../../utils/Language';
import DynamicSelect from '../dynamic-select/DynamicSelect';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import PaymentInformationRow from '../Visit/PaymentInformationRow';
import PaymentLineItemTableFooter from '../Visit/PaymentLineItemTableFooter';

export const ADD_PAYMENT_ROW_NAME = 'addPaymentInformation';

const PharmacySalesPaymentLineItemTable = () => {
	const { t } = useTranslation();
	const { referenceListService } = useService();
	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext();
	const addPaymentInformationUuid: string = useWatch({ name: `${ADD_PAYMENT_ROW_NAME}.uuid` });
	const { fields, append, remove } = useFieldArray<FieldValues, 'paymentInformationList', 'uuid'>({
		name: 'paymentInformationList',
		keyName: 'uuid',
	});

	const { value: paymentTypes, loading: areLoadingPaymentTypes } = useAsync(
		async () => referenceListService.getTenderTypes(),
		[referenceListService],
	);
	const { orderLineTotal, paymentLineTotal } = useBillingTotals();

	// This handles adding a new payment if the user selected a type
	useEffect(() => {
		if (addPaymentInformationUuid) {
			// Add a new row
			const paymentType = paymentTypes?.find((paymentType) => paymentType.uuid === addPaymentInformationUuid);

			append(
				new PaymentInformation({
					amount: getValues(`${ADD_PAYMENT_ROW_NAME}.amount`),
					description: getValues(`${ADD_PAYMENT_ROW_NAME}.description`),
					paymentType: new ReferenceList({ ...paymentType, value: undefined }),
					isPayment: 'true',
					isWaiver: 'false',
				}),
			);
			setValue(`${ADD_PAYMENT_ROW_NAME}.uuid`, '');
			setValue(`${ADD_PAYMENT_ROW_NAME}.amount`, '');
			setValue(`${ADD_PAYMENT_ROW_NAME}.description`, '');
		}
	}, [addPaymentInformationUuid, append, setValue, paymentTypes, getValues]);
	useEffect(() => {
		setValue('paymentLineCount', fields.length);
	}, [fields.length, setValue]);
	useEffect(() => {
		setValue('billBalance', orderLineTotal - paymentLineTotal);
	}, [orderLineTotal, paymentLineTotal, setValue]);

	return (
		<>
			<input
				type="hidden"
				{...register('paymentLineCount', {
					valueAsNumber: true,
					validate: (value: number) => value > 0,
				})}
				defaultValue={fields.length}
			/>
			<input
				type="hidden"
				{...register('billBalance', {
					valueAsNumber: true,
					validate: (value: number) => value <= 0,
				})}
				defaultValue={orderLineTotal - paymentLineTotal}
			/>
			<table className="bh-table--form">
				<thead>
					<tr>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.TYPE)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.payment.table.AMOUNT_PAID)}</th>
						<th className="data-type-text">{t(uiText.visit.form.payment.table.DESCRIPTION)}</th>
						<th className="data-type-action print__d-none">{t(uiText.visit.button.DELETE)}</th>
					</tr>
				</thead>
				<tbody>
					{(fields as unknown as PaymentInformation[]).map((field, index) => (
						<PaymentInformationRow
							key={field.uuid}
							field={field}
							index={index}
							remove={remove}
							paymentTypes={paymentTypes}
							shouldDisplayTheDeleteColumn={true}
						/>
					))}
				</tbody>
				<tbody>
					<tr>
						<td>
							<DynamicSelect
								aria-label={t(uiText.visit.form.payment.table.TYPE)}
								{...register(`${ADD_PAYMENT_ROW_NAME}.uuid`)}
								defaultValue={''}
								isLoading={areLoadingPaymentTypes}
							>
								<option value=""></option>
								{(paymentTypes || []).map((paymentType) => (
									<option key={paymentType.uuid} value={paymentType.uuid}>
										{paymentType.name}
									</option>
								))}
							</DynamicSelect>
						</td>
						<td>
							<Controller
								name={`${ADD_PAYMENT_ROW_NAME}.amount`}
								render={({ field }) => (
									<FormatNumberInput aria-label={t(uiText.visit.form.payment.table.AMOUNT_PAID)} min={0} {...field} />
								)}
							/>
						</td>
						<td>
							<Form.Control {...register(`${ADD_PAYMENT_ROW_NAME}.description`)} defaultValue={''} />
						</td>
					</tr>
				</tbody>
				<tfoot>
					<PaymentLineItemTableFooter shouldDisplayTheDeleteColumn={true} />
				</tfoot>
			</table>
			{!!(errors.paymentInformationList || []).length && (
				<div className="text-danger">{t(uiText.payment.error.MISSING_AMOUNT)}</div>
			)}
			{!!errors.paymentLineCount && <div className="text-danger">{t(uiText.payment.error.PAYMENT_REQUIRED)}</div>}
			{!!errors.billBalance && <div className="text-danger">{t(uiText.payment.error.FULL_BALANCE_MUST_BE_PAID)}</div>}
		</>
	);
};

export default PharmacySalesPaymentLineItemTable;
