import { useEffect } from 'react';

type SuspensePromise<T> = Promise<T> & {
	status?: 'pending' | 'resolved' | 'error' | 'rejected';
	data?: T;
	error?: any;
};
const promises: { [uuid: string]: SuspensePromise<unknown> } = {};
const readTimeouts: { [uuid: string]: NodeJS.Timeout } = {};
// If the promise isn't retrieved after resolving/rejecting (this will happen as React re-renders the component), it
// means the component has been unmounted and we don't need to store the promise for subsequent renders
const getPromise = (uuid: string) => {
	// If we've got an active timeout, remove it since we're fetching the data and want to keep the promise stored
	// for future re-renders
	if (readTimeouts[uuid]) {
		clearTimeout(readTimeouts[uuid]);
		delete readTimeouts[uuid];
	}
	return promises[uuid];
};

/**
 * A way to try and leverage React's Suspense. Modified from this:
 * https://stackoverflow.com/questions/75288420/conditionally-returning-a-react-component-to-satisfy-a-suspense-fallback
 * @param uuid A unique identifier for this promise
 * @param fetchFunction The fetch function that can either use or disregard the unique identifier
 * @returns Data or a thrown promise that can be consumed by React's Suspense
 */
function useSuspenseAsync<T>(uuid: string, fetchFunction: (uuid: string) => SuspensePromise<T>) {
	const promise = (getPromise(uuid) as SuspensePromise<T> | undefined) || fetchFunction(uuid);
	// If the component hasn't already been unmounted before the promise resolves, this function will run clean-up
	useEffect(
		() => () => {
			// Only clean the thing up if it's been resolved
			if (promise.status === 'rejected' || promise.status === 'resolved') {
				delete promises[uuid];
			}
		},
		[promise.status, uuid],
	);

	// for any new promise
	if (!promise.status) {
		promise.status = 'pending'; // set it as pending
		promise
			.then((data) => {
				// when resolved, store the data
				promise.status = 'resolved';
				promise.data = data;
				// Set the timeout to make sure the promise is read within a second, or else remove it
				readTimeouts[uuid] = setTimeout(() => {
					delete promises[uuid];
				}, 500);
			})
			.catch((error) => {
				// when rejected store the error
				promise.status = 'rejected';
				promise.error = error;
				// Set the timeout to make sure the promise is read within a second, or else remove it
				readTimeouts[uuid] = setTimeout(() => {
					delete promises[uuid];
				}, 500);
			});
		promises[uuid] = promise;
	}

	if (promise.status === 'pending') {
		// if still pending, throw promise to Suspense
		throw promise;
	}
	if (promise.status === 'error') {
		return { error: new Error(promise.error) };
	}
	return { data: promise.data as T }; // otherwise, return resolved data
}

export default useSuspenseAsync;
