import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';
import { isNumber } from 'lodash';
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTodayWithoutTime } from '../../utils/DateUtil';
import { uiText } from '../../utils/Language';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { InitailStockFormValues } from './InitialStock';
import { ProductFormValues } from './ProductForm';

type InitialStockRowProps = {
	index?: number;
	remove?: UseFieldArrayReturn['remove'];
	isFooter?: boolean;
};

const InitialStockRow = ({ index, remove, isFooter }: InitialStockRowProps) => {
	const { t } = useTranslation();
	const { register } = useFormContext<InitailStockFormValues>();
	const needsExpirationAttributeSetInstance = useWatch<ProductFormValues, 'needsExpirationAttributeSetInstance'>({
		name: 'needsExpirationAttributeSetInstance',
	});
	const fieldPrefix = isFooter ? 'addNewInventory' : `M_InventoryLines.${index}`;

	return (
		<tr>
			<td>
				<input type="hidden" {...register(`${fieldPrefix}.UU` as 'M_InventoryLines.0.UU')} />
				<input
					type="hidden"
					{...register(`${fieldPrefix}.M_AttributeSetInstance.UU` as 'M_InventoryLines.0.M_AttributeSetInstance.UU')}
				/>
				<Controller<InitailStockFormValues, 'M_InventoryLines.0.QtyCount'>
					name={`${fieldPrefix}.QtyCount` as 'M_InventoryLines.0.QtyCount'}
					rules={{
						validate: (value) => (isFooter && !isNumber(value)) || (value || 0) > 0,
					}}
					render={({ field }) => <FormatNumberInput aria-label={t(uiText.product.quantity.LABEL)} {...field} />}
				/>
			</td>
			<td>
				<fieldset disabled={!needsExpirationAttributeSetInstance}>
					<Controller<InitailStockFormValues, 'M_InventoryLines.0.M_AttributeSetInstance.GuaranteeDate'>
						name={
							`${fieldPrefix}.M_AttributeSetInstance.GuaranteeDate` as 'M_InventoryLines.0.M_AttributeSetInstance.GuaranteeDate'
						}
						rules={{
							validate: (value) =>
								isFooter || (needsExpirationAttributeSetInstance && !!value && value > getTodayWithoutTime()),
						}}
						render={({ field }) => (
							<BandaDatePicker
								{...field}
								value={undefined}
								selected={field.value}
								aria-label={uiText.product.expirationDate.LABEL}
								minDate={startOfDay(new Date())}
								onlyUpdateAfterCalendarClosed={isFooter}
							/>
						)}
					/>
				</fieldset>
			</td>
			<td className="align-middle text-center">
				{!isFooter && (
					<button
						aria-label={t(uiText.product.button.DELETE)}
						type="button"
						className="btn p-0 w-100"
						tabIndex={-1}
						onClick={() => remove && remove(index)}
					>
						<FontAwesomeIcon icon="trash" className="border-0" />
					</button>
				)}
			</td>
		</tr>
	);
};

export default InitialStockRow;
