import React from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useBillingTotals from '../../hooks/useBillingTotals';
import { uiText } from '../../utils/Language';
import FormatNumberInput from '../format-number-input/FormatNumberInput';

type PaymentLineItemTableFooterProps = {
	shouldDisplayTheDeleteColumn: boolean;
};

const PaymentLineItemTableFooter = ({ shouldDisplayTheDeleteColumn }: PaymentLineItemTableFooterProps) => {
	const { t } = useTranslation();
	const { orderLineTotal, paymentLineTotal } = useBillingTotals();
	const visitNumber: string | null | undefined = useWatch({ name: 'documentNumber' });

	const billBalance = orderLineTotal - paymentLineTotal;

	return (
		<>
			<tr>
				<td className="align-middle text-end pe-2">
					<strong>{t(uiText.visit.BALANCE_DUE)}</strong>
				</td>
				<td>
					<FormatNumberInput
						name="payAmount"
						readOnly={true}
						value={billBalance < 0 ? 0 : billBalance}
						tabIndex={-1}
						aria-label={t(uiText.visit.BALANCE_DUE)}
					/>
				</td>
				<td className="d-none"></td>
				{shouldDisplayTheDeleteColumn ? <td className="d-none"></td> : null}
			</tr>
			<tr>
				<td className="align-middle text-end pe-2">
					<strong>{t(uiText.visit.CHANGE_DUE)}</strong>
				</td>
				<td>
					<FormatNumberInput
						name="changeDue"
						readOnly={true}
						value={billBalance < 0 ? billBalance * -1 : 0}
						tabIndex={-1}
					/>
				</td>
				<td className="align-middle text-end pe-2" colSpan={shouldDisplayTheDeleteColumn ? 2 : 1}>
					<strong>{`${t(uiText.visit.NUMBER)} ${visitNumber || '-'}`}</strong>
				</td>
			</tr>
		</>
	);
};

export default PaymentLineItemTableFooter;
