import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * This hook is a helper to run a callback when a user tries to navigate to the page they're already on
 *
 * @param refreshCallback A callback to run when the user tries to navigate to the current route
 * @returns null
 */
export default function useRefreshOnRepeatedRoute(refreshCallback: () => void) {
	const hasPageRendered = useRef(false);
	// The useLocation hook will trigger an update, even if the route doesn't change
	const { key } = useLocation();
	const lastLocationKey = useRef<string | undefined>(key);

	useEffect(() => {
		// Only execute the callback if this isn't the first render (no need to trigger a refresh on the first page load)
		// or if the key is different (we don't want to trigger a refresh if callback dependencies change)
		if (hasPageRendered.current && lastLocationKey.current !== key) {
			refreshCallback();
			lastLocationKey.current = key;
		}
		hasPageRendered.current = true;
	}, [key, refreshCallback]);

	return;
}
