import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDto } from './BaseMetadata';
import Field, { FieldDto } from './Field';

export interface TabDto extends BaseMetadataDto {
	fields?: FieldDto[] | null;
}

export default class Tab extends BaseMetadata {
	fields: Field[];

	constructor(props: Partial<TabDto | Tab> = {}) {
		props = cloneDeep(props);
		super(props);

		this.fields = (props.fields || []).map((field) => new Field(field));
	}
}
