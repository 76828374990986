import { Filter } from '../models/DBFilter';
import Sort from '../models/Sort';

export function createPagingParameters(page?: number, size?: number, multiSort?: Sort<any>[], filter?: Filter<any>) {
	const sorting = multiSort?.map((sort) => [sort.id, sort.desc ? 'DESC' : 'ASC']);
	const sortingEncoded = sorting ? encodeURIComponent(JSON.stringify(sorting)) : '';

	return `?page=${page}&size=${size}&filter=${
		filter ? encodeURIComponent(JSON.stringify(filter)) : ''
	}&sorting=${sortingEncoded}`;
}
