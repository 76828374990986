import BaseEntity, { BaseEntityDB, BaseEntityDto } from './BaseEntity';
import { cloneDeep } from 'lodash';

export interface CountryDto extends BaseEntityDto {
	countryCode: string;
}

export interface CountryDB extends BaseEntityDB {
	c_country_uu: string;
}

export default class Country extends BaseEntity {
	countryCode: string;

	constructor(props: Partial<CountryDto | Country> = {}) {
		props = cloneDeep(props);
		super(props);

		this.countryCode = props.countryCode || '';
	}
}
