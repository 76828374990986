import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';
import { useFormContext, useFieldArray, useWatch, FieldValues } from 'react-hook-form';
import { EncounterDiagnosis } from '../../models';
import DiagnosisTableRow from './DiagnosisTableRow';

type DiagnosisTableProps = {
	visitUuid: string;
	readOnly?: boolean;
};

const DiagnosisTable = ({ visitUuid, readOnly }: DiagnosisTableProps) => {
	const { t } = useTranslation();
	const { setValue, getValues } = useFormContext();
	const FIELD_PREFIX = 'encounterDiagnoses';
	const ADD_ROW_PREFIX = 'addNewDiagnosis';
	const PRIMARY_RADIO_GROUP_NAME = `${FIELD_PREFIX}Primary.${visitUuid}`;

	const { fields, append, move, remove } = useFieldArray<FieldValues, 'encounterDiagnoses', 'uuid'>({
		name: 'encounterDiagnoses',
		keyName: 'uuid',
	});

	const previousFirstRow = useRef<EncounterDiagnosis>(new EncounterDiagnosis());
	const rowBeingMoved = useRef(0);

	// This handles adding a new row if the user added a diagnosis to the special add row at the bottom
	const addDiagnosisRow = useWatch({ name: ADD_ROW_PREFIX });
	useEffect(() => {
		if (addDiagnosisRow && (addDiagnosisRow.codedDiagnosis?.uuid || addDiagnosisRow.uncodedDiagnosis)) {
			append(
				new EncounterDiagnosis({
					...addDiagnosisRow,
					lineNo: fields.length,
				}),
			);

			// Reset the fields for adding a new row
			setValue(ADD_ROW_PREFIX, new EncounterDiagnosis());
		}
	}, [addDiagnosisRow, fields.length, append, setValue, getValues]);

	// Watch the value of the primary, to trigger moving it to the top
	const selectedPrimary = useWatch({ name: PRIMARY_RADIO_GROUP_NAME });
	// This useWatch is used to trigger the useEffect below after re-rendering of the top row
	const firstRow = useWatch({ name: `${FIELD_PREFIX}.0` });
	// When a row is chosen as the primary, this takes care of moving it to the top
	useEffect(() => {
		if (selectedPrimary === undefined) {
			return;
		}
		// Note: we can ignore the lineNo, as it will get overwritten on save with the index. So,
		// the index is what really matters.
		const primaryIndex: number = parseInt(selectedPrimary);
		// If the row we are trying to move is not the first one, and the first row hasn't been
		// changed yet, then move
		if (primaryIndex && primaryIndex !== 0 && rowBeingMoved.current === 0) {
			rowBeingMoved.current = primaryIndex;
			move(primaryIndex, 0);
		} else if (rowBeingMoved.current !== 0) {
			rowBeingMoved.current = 0;
			setValue(PRIMARY_RADIO_GROUP_NAME, '0');
		}
	}, [selectedPrimary, move, firstRow, previousFirstRow, setValue, PRIMARY_RADIO_GROUP_NAME]);

	// Handles removal of a row
	const handleRemove = (index: number) => {
		remove(index);
		if (index === 0) {
			// If the first one was removed, need to ensure primary is re-set to 0 after
			// re-render. That will get handled by the useEffect hook above
			rowBeingMoved.current = -1;
		}
	};

	// If this is read-only and there are no diagnoses, hide the whole table
	if (readOnly && fields.length === 0) {
		return null;
	} else {
		return (
			<div className="table-responsive">
				<table className="table bh-table--form">
					<thead>
						<tr>
							<th className="data-type-action">{t(uiText.visit.form.patient.PRIMARY)}</th>
							<th>{t(uiText.visit.form.patient.DIAGNOSIS)}</th>
							{!readOnly && <th className="data-type-action print__d-none">{t(uiText.visit.form.product.DELETE)}</th>}
						</tr>
					</thead>
					<tbody>
						{((fields || []) as unknown as EncounterDiagnosis[]).map((diagnosis, index) => (
							<DiagnosisTableRow
								key={diagnosis.uuid}
								index={index}
								field={diagnosis}
								id={`${FIELD_PREFIX}.${index}.${visitUuid}`}
								fieldPrefix={`${FIELD_PREFIX}.${index}`}
								primaryGroupName={PRIMARY_RADIO_GROUP_NAME}
								remove={handleRemove}
								readOnly={readOnly}
							/>
						))}
						{!readOnly && (
							<DiagnosisTableRow
								isAddRow={true}
								fieldPrefix={ADD_ROW_PREFIX}
								id={`${ADD_ROW_PREFIX}.${visitUuid}`}
								readOnly={readOnly}
							/>
						)}
					</tbody>
				</table>
			</div>
		);
	}
};

export default DiagnosisTable;
