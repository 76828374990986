import ProductCategoryType from '../models/ProductCategoryType';
import { PRODUCT_CATEGORY_TYPES } from '../utils/Constants';
import NewMetadataService from './NewMetadataService';

export default class ProductCategoryTypeService extends NewMetadataService<ProductCategoryType, ProductCategoryType> {
	getUrl = `${this.metadataUrl}/product-category-types`;
	localStorageKey = PRODUCT_CATEGORY_TYPES;

	map(data: ProductCategoryType) {
		return new ProductCategoryType(data);
	}

	reverseMap(data: ProductCategoryType): ProductCategoryType {
		throw new Error('Method not implemented.');
	}
}
