import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import UserContext from '../../contexts/UserContext';
import useService from '../../hooks/useService';
import { Locator, roleUuid } from '../../models';
import OrderLine from '../../models/OrderLine';
import Product, { ProductType } from '../../models/Product';
import { uiText } from '../../utils/Language';
import EntityLookup from '../entity-lookup/EntityLookup';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { FyiModalComponent } from '../Modal/FyiModal';

type ProductLineItemTableRowProps = {
	field?: OrderLine;
	index?: number;
	remove?: UseFieldArrayReturn['remove'];
	shouldDisplayTheDeleteColumn: boolean;
	isAddRow?: boolean;
	isDataReadOnly?: boolean;
	locator?: Locator;
};

export const ADD_ORDER_LINE_ROW_NAME = 'addNewOrderLine';

const ProductLineItemTableRow = ({
	field,
	index,
	remove,
	shouldDisplayTheDeleteColumn,
	isAddRow = false,
	isDataReadOnly,
	locator,
}: ProductLineItemTableRowProps) => {
	const { t } = useTranslation();
	const { register, getValues, setValue } = useFormContext();
	const { productService } = useService();
	const [showQuantityAlert, setShowQuantityAlert] = useState(false);
	const { role } = useContext(UserContext);

	const fieldPrefix = isAddRow ? ADD_ORDER_LINE_ROW_NAME : `orders.0.orderLines.${index}`;

	const quantity: number = useWatch({ name: `${fieldPrefix}.quantity` }) || 0;
	const price: number | undefined = useWatch({ name: `${fieldPrefix}.price` }) || field?.price;
	const product = new Product(useWatch({ name: `${fieldPrefix}.product` }) || {});
	const hasUserUpdatedThePrice = useRef(price !== product.price);

	// If this is a service, the add row, or the product doesn't have any storage on hand (such as when loading an existing visit),
	// we'll just say the totalQuantity is undefined
	const totalQuantity =
		getValues(`${fieldPrefix}.product.type`) === 'S' || isAddRow || !product.storageOnHandList.length
			? undefined
			: product.storageOnHandList
					.filter((storage) => storage.locator.uuid === locator?.uuid)
					.reduce((sum, storageOnHand) => sum + storageOnHand.quantityOnHand, 0);

	const [{ value: productOptions, loading: areLoadingProducts }, onSearchProductsAndServices] = useAsyncFn(
		async (query: string) =>
			locator
				? (await productService.searchProductServices(query)).results
						.map((product) => {
							// We don't need the attribute sets saved to the form, so we'll remove them
							product.attributeSet = undefined;
							return product;
						})
						.filter((product) => {
							if (product.type === ProductType.ITEM) {
								return (
									product.storageOnHandList
										.filter((storage) => storage.locator.uuid === locator.uuid)
										.reduce((sum, storageOnHand) => sum + storageOnHand.quantityOnHand, 0) > 0
								);
							}
							return true;
						})
				: [],
		[productService, locator],
	);

	// For some reason, the product returned from the watch randomly loses it's properties after reset, so we'll store the full thing
	const [statefulProduct, setStatefulProduct] = useState(product);

	const isOrderLineNew = field?.isNew !== false;
	const reactivatedRow = getValues(`isVisitReactivated`) && !isOrderLineNew;
	useEffect(() => {
		if (statefulProduct.uuid !== product.uuid) {
			setStatefulProduct(product);
		}
	}, [product, statefulProduct.uuid]);

	// If the product changes (and this is a new field where the user hasn't changed the price), update the price
	useEffect(() => {
		if (!hasUserUpdatedThePrice.current) {
			setValue(`${fieldPrefix}.price`, statefulProduct.price);
		}
	}, [statefulProduct, setValue, fieldPrefix]);
	// Ensure the line net amount gets set correctly
	useEffect(() => {
		setValue(`${fieldPrefix}.lineNetAmount`, quantity * (price || 0));
	}, [setValue, fieldPrefix, quantity, price]);

	const isSalePriceReadOnly = useMemo(() => {
		const roleUuidsThatCannotEditSalePrice: string[] = [
			roleUuid.CASHIER_REGISTRATION_BASIC,
			roleUuid.CLINICIAN_NURSE_BASIC,
			roleUuid.INVENTORY_PHARMACY_BASIC,
			roleUuid.LAB_RADIOLOGY,
			roleUuid.MUST_HAVES,
		];
		// ! TODO Update this to check if the user is an admin instead of just not having any master/included roles
		// Filter our the roles that can't edit and, if there's nothing left, they can't edit it
		return (
			!!role.includedRoles.length &&
			!role.includedRoles
				.map((masterRole) => masterRole.uuid)
				.filter((masterRoleUuid) => !roleUuidsThatCannotEditSalePrice.includes(masterRoleUuid)).length
		);
	}, [role]);

	return (
		<tr>
			<td>
				{!isAddRow && (
					<FyiModalComponent onHide={() => setShowQuantityAlert(false)} show={showQuantityAlert}>
						{t(uiText.visit.prompt.PRODUCT_LINE_QUANTITY_EXCEEDS_INVENTORY)}
					</FyiModalComponent>
				)}
				{!isAddRow && <input type="hidden" {...register(`${fieldPrefix}.uuid`)} defaultValue={field?.uuid} />}
				<EntityLookup
					name={`${fieldPrefix}.product`}
					rules={{ required: !isAddRow }}
					defaultValue={field?.product}
					isLoading={areLoadingProducts}
					id={`${fieldPrefix}.product`}
					delay={500}
					inputProps={{ 'aria-label': t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE) }}
					emptyLabel={t(uiText.visit.product.NOT_FOUND)}
					labelKey="name"
					minLength={2}
					placeholder={t(uiText.visit.product.SEARCH)}
					promptText={t(uiText.visit.product.SEARCHING)}
					searchText={t(uiText.visit.product.SEARCHING)}
					options={productOptions || []}
					onSearch={onSearchProductsAndServices}
					disabled={isDataReadOnly || reactivatedRow}
					onChange={() => {
						// We need to know, ultimately, whether the price can be updated when the product changes
						// So, if the user has changed the price, it's value will differ from the product's
						// In that case, don't update anything
						// This field is typically set when the user is editing a visit, but this is for when
						// a visit is being viewed and the "product gets selected")
						if (price === statefulProduct.price) {
							hasUserUpdatedThePrice.current = false;
						} else {
							hasUserUpdatedThePrice.current = true;
						}
					}}
				/>
			</td>
			<td>
				<Form.Control
					aria-label={t(uiText.visit.form.product.table.INSTRUCTIONS)}
					defaultValue={getValues(`${fieldPrefix}.instructions`) || field?.instructions}
					{...register(`${fieldPrefix}.instructions`)}
				/>
			</td>
			<td>
				<FormatNumberInput
					aria-label={t(uiText.visit.form.product.table.EXISTING_QUANTITY)}
					value={totalQuantity}
					tabIndex={-1}
					readOnly={true}
					displayAndUseZeroIfEmpty={!isAddRow}
				/>
			</td>
			<td>
				<input
					type="hidden"
					{...register(`${fieldPrefix}.sellingTooMuch`, { validate: (value) => isAddRow || value === '0' })}
					defaultValue="0"
				/>
				<Controller
					name={`${fieldPrefix}.quantity`}
					defaultValue={getValues(`${fieldPrefix}.quantity`) || field?.quantity}
					rules={{
						required: !isAddRow,
						validate: (value) => isAddRow || value !== 0,
					}}
					render={({ field }) => (
						<FormatNumberInput
							aria-label={t(uiText.visit.form.product.table.QUANTITY)}
							displayAndUseZeroIfEmpty={!isAddRow}
							{...field}
							onBlur={() => {
								if (
									totalQuantity !== undefined &&
									getValues(`${fieldPrefix}.product.type`) !== 'S' &&
									getValues(`${fieldPrefix}.quantity`) > totalQuantity
								) {
									setShowQuantityAlert(true);
									setValue(`${fieldPrefix}.sellingTooMuch`, '1');
								} else {
									setValue(`${fieldPrefix}.sellingTooMuch`, '0');
								}
								field.onBlur();
							}}
						/>
					)}
				/>
			</td>
			<td>
				<Controller
					name={`${fieldPrefix}.price`}
					defaultValue={getValues(`${fieldPrefix}.price`) || field?.price}
					render={({ field }) => (
						<FormatNumberInput
							aria-label={t(uiText.visit.form.product.table.UNIT_SELL_PRICE)}
							displayAndUseZeroIfEmpty={!isAddRow}
							{...field}
							onChange={(e) => {
								hasUserUpdatedThePrice.current = true;
								field.onChange(e);
							}}
							disabled={isSalePriceReadOnly}
						/>
					)}
				/>
			</td>
			<td>
				<FormatNumberInput
					aria-label={t(uiText.visit.form.product.table.TOTAL)}
					value={quantity * (price || 0)}
					tabIndex={-1}
					readOnly={true}
				/>
			</td>
			{shouldDisplayTheDeleteColumn && !reactivatedRow && (
				<td className="print__d-none align-middle text-center">
					{!isAddRow && (
						<button
							type="button"
							aria-label={t(uiText.visit.form.product.DELETE)}
							className="btn p-0 w-100"
							tabIndex={-1}
							onClick={() => remove && remove(index)}
						>
							<FontAwesomeIcon icon="trash" />
						</button>
					)}
				</td>
			)}
		</tr>
	);
};

export default ProductLineItemTableRow;
