import { cloneDeep } from 'lodash';
import BaseMetadata, { BaseMetadataDB, BaseMetadataDto } from './BaseMetadata';
import Field, { FieldDto } from './Field';

export interface ObservationDto extends BaseMetadataDto {
	field?: FieldDto | null;
	value?: string | null;
}

export interface ObservationDB extends BaseMetadataDB {}

export default class Observation extends BaseMetadata {
	field: Field;
	value?: string;

	constructor(props: Partial<ObservationDto | Observation> = {}) {
		props = cloneDeep(props);
		super(props);

		this.field = new Field(('field' in props && props.field) || {});
		this.value = props.value || '';
	}
}
