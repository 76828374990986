import axios from 'axios';
import BaseMetadata, { BaseMetadataDto } from '../models/BaseMetadata';
import DocAction, { DocumentActionValue } from '../models/DocAction';
import { DocumentStatusValue } from '../models/DocumentStatus';
import { ProcessTypeValue, StatusActionMap } from '../models/ProcessType';
import { configs } from '../utils/Configs';
import NewMetadataService from './NewMetadataService';

const voidDocumentActions = [DocAction.VOID, DocAction.REVERSE_CORRECT, DocAction.REVERSE_ACCRUAL] as const;

export default class ProcessTypeService extends NewMetadataService {
	getUrl = `${configs.apiUrl}/status-action-map`;
	localStorageKey = 'statusActionMap';

	/**
	 * Perform a request to the specified URL with paging, sizing, and sorting
	 * @param {boolean} useCache Whether the cache should be used, if available (defaults to true)
	 * @returns {Promise}
	 */
	async getObject(useCache?: boolean) {
		useCache = useCache !== false;
		let metaData = this.getLocalObject();
		if (metaData && Object.keys(metaData || {}).length && useCache) {
			return metaData;
		}
		metaData = await axios.get<StatusActionMap>(this.getUrl).then(({ data }) => data);
		localStorage.setItem(this.localStorageKey, JSON.stringify(metaData));
		return metaData as StatusActionMap;
	}

	/**
	 * Get the data stored locally (may be undefined if not initialized yet)
	 */
	getLocalObject(): StatusActionMap | undefined {
		let metaData: StatusActionMap = JSON.parse(localStorage.getItem(this.localStorageKey) || '[]');
		if (metaData && Object.keys(metaData).length) {
			return metaData;
		}
		return undefined;
	}

	/**
	 * Get the document status access list based on the process type
	 * @param {ProcessType} processType The process type to check access for
	 * @param {String} documentStatusValue The status of the current document
	 * @returns {Promise<String[]>} The document actions that this user has access to
	 */
	getAvailableActionsByProcessTypeAndStatus = async (
		processType: ProcessTypeValue,
		documentStatusValue: DocumentStatusValue,
	): Promise<DocumentActionValue[]> => {
		const data = await this.getObject();
		if (!data || !Object.keys(data[processType] || {}).length) {
			return [];
		}
		return data[processType][documentStatusValue] || [];
	};

	/**
	 * Determine if the user can leverage the void process for this process type and current document status
	 * @param {ProcessType} processType The process type to check access for
	 * @param {String} documentStatusValue The status of the current document
	 * @returns {Promise<boolean>} Whether the user can void this document
	 */
	canVoidDocument = async (
		processType: ProcessTypeValue,
		documentStatusValue: DocumentStatusValue,
	): Promise<boolean> => {
		return !!(await this.getVoidAction(processType, documentStatusValue));
	};

	/**
	 * Get the appropriate doc action to use for voiding (it depends on the process type and it's current status)
	 * @param {ProcessType} processType The process type to check access for
	 * @param {String} documentStatusValue The status of the current document
	 * @returns {Promise<String | null>} The appropriate doc action to use for voiding
	 */
	getVoidAction = async (
		processType: ProcessTypeValue,
		documentStatusValue: DocumentStatusValue,
	): Promise<DocumentActionValue | undefined> => {
		return (await this.getAvailableActionsByProcessTypeAndStatus(processType, documentStatusValue)).filter(
			(availableDocumentAction) => (voidDocumentActions as unknown as string[]).includes(availableDocumentAction),
		)[0];
	};

	map(data: BaseMetadataDto): BaseMetadata {
		throw new Error('Method not implemented.');
	}

	reverseMap(data: BaseMetadata): BaseMetadataDto {
		throw new Error('Method not implemented.');
	}
}
