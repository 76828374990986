import { DBFilter } from '../models';
import Warehouse, { WarehouseDB, WarehouseDto, WarehousePropertyNames } from '../models/Warehouse';
import { SEARCH } from '../utils/Constants';
import BaseEntityService from './BaseEntityService';

export default class WarehouseService extends BaseEntityService<Warehouse, WarehouseDto, WarehouseDB> {
	public entityTableName: string = 'm_warehouse';
	protected entityName = 'warehouses';

	async getWarehouseByOrg(organizationUuid: string) {
		let filter = DBFilter<WarehouseDB>();
		filter
			.and(DBFilter<WarehouseDB>().property(WarehousePropertyNames.AD_ORG_UU).equals(organizationUuid))
			.and(DBFilter<WarehouseDB>().property('name').doesNotEqual('Standard'))
			.and(DBFilter<WarehouseDB>().property('isactive').equals(true));

		return this.get(SEARCH.DEFAULT_PAGE_NUMBER, SEARCH.DEFAULT_PAGE_SIZE, [], filter);
	}

	map(data: WarehouseDto): Warehouse {
		return new Warehouse(data);
	}

	reverseMap(data: Warehouse): WarehouseDto {
		throw new Error('Method not implemented.');
	}
}
