import { useApolloClient } from '@apollo/client';
import { Ad_ProcessGetForRunningReportsDocument } from '../graphql/__generated__/graphql';
import useSuspenseAsync from './useSuspenseAsync';

export default function useSuspenseGraphQLProcessAccess(processUU: string) {
	const graphqlClient = useApolloClient();
	const { data: processes } = useSuspenseAsync(
		'get-process' + processUU,
		async () =>
			(await graphqlClient.query({ query: Ad_ProcessGetForRunningReportsDocument, fetchPolicy: 'cache-first' })).data
				.AD_ProcessGet.Results,
	);
	return !!processes?.some((process) => process.UU === processUU);
}
