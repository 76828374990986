import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { uiText } from '../../utils/Language';

type VisitCompleteConfirmationModalProps = {
	cancel: () => void;
	confirm: () => void;
};

const VisitCompleteConfirmationModal = ({ cancel, confirm }: VisitCompleteConfirmationModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal show={true}>
			<Modal.Header>{t(uiText.modal.WARNING)}</Modal.Header>
			<Modal.Body>{t(uiText.visit.prompt.COMPLETE_CONFIRM_MESSAGE)}</Modal.Body>
			<Modal.Footer>
				<Button type="button" variant="danger" onClick={cancel}>
					{t(uiText.modal.button.CANCEL)}
				</Button>
				<Button type="submit" variant="success" className="ms-auto" onClick={confirm}>
					{t(uiText.modal.button.COMPLETE)}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default VisitCompleteConfirmationModal;
