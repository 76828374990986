import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import { FieldValues, DeepMap, FieldError, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { uiText } from '../../utils/Language';
import useService from '../../hooks/useService';
import LabDiagnosticsTableRow from './LabDiagnosticsTableRow';
import { Concept, ConceptClass, EncounterDiagnostic, Paging } from '../../models';
import { getConceptIsActiveForClient, getConceptLocalName } from '../../utils/ModelUtils';

const DIAGNOSTIC_STATUS_PENDING = 'P';

type LabDiagnosticsTableProps = {
	readOnly?: boolean;
};

const LabDiagnosticsTable = ({ readOnly }: LabDiagnosticsTableProps) => {
	const { t } = useTranslation();
	const { setValue, formState } = useFormContext();
	const { conceptService, referenceListService } = useService();
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const FIELD_PREFIX = 'encounterDiagnostics';
	const ADD_ROW_PREFIX = 'addNewEncounterDiagnostic';

	const { fields, append, remove } = useFieldArray<FieldValues, 'encounterDiagnostics', 'uuid'>({
		name: 'encounterDiagnostics',
		keyName: 'uuid',
	});

	const { value: conceptTestsAndPanels } = useAsync(async () => {
		const testsAndPanels = (
			await conceptService.get(
				Paging.ALL.page,
				Paging.ALL.size,
				undefined,
				conceptService.getTestsAndPanelsSearchFilter(),
			)
		).results
			.filter((concept) => getConceptIsActiveForClient(concept))
			.map((concept) => {
				// We don't need some elements for the lookup, so remove them to make the form faster
				concept.toConceptMappings = [];
				return concept;
			});

		testsAndPanels.sort((conceptA, conceptB) =>
			getConceptLocalName(conceptA).toLowerCase().localeCompare(getConceptLocalName(conceptB).toLowerCase()),
		);

		// Return a sorted list of first panels, then tests
		return [
			...testsAndPanels.filter((concept) => concept.conceptClass === ConceptClass.LAB_SET),
			...testsAndPanels.filter((concept) => concept.conceptClass === ConceptClass.TEST),
		];
	}, [conceptService]);

	const { value: diagnosticStatuses } = useAsync(
		async () => referenceListService.getDiagnosticStatuses(),
		[referenceListService],
	);

	// Handle a new diagnostic test or panel being selected in the ADD row at the bottom of the table
	const addDiagnosticRow = useWatch({ name: ADD_ROW_PREFIX });
	useEffect(() => {
		// Rather than load the concept with all its extras and mappings from the form, use the uuid
		// of the selected concept to get the object from the list returned from the server. Otherwise,
		// issues have been encountered where there were residual concept extras from one row remaining
		// on another row.
		const concept = conceptTestsAndPanels?.find((concept) => concept.uuid === addDiagnosticRow?.concept.uuid);

		if (!concept || addDiagnosticRow?.concept.isNew) {
			return;
		}

		if (concept?.conceptClass === ConceptClass.LAB_SET) {
			// If the item selected was a panel (LabSet), we need to append rows for all the tests within that panel
			conceptService
				.get(Paging.ALL.page, Paging.ALL.size, undefined, conceptService.getTestsWithinPanelFilter(concept?.uuid))
				.then((response) => {
					const encounterDiagnostics = response.results
						.filter((concept) => getConceptIsActiveForClient(concept))
						.map((concept) => {
							const simplifiedConcept = new Concept(concept);
							// React hook form tries to create fields for all the sub-elements of the concept,
							// which is not necessary and takes a really long time. So, after the initial
							// concept is created (and concept extras and/or mappings are used to set
							// the correct local name), clear those attributes
							simplifiedConcept.conceptExtras = [];
							simplifiedConcept.toConceptMappings = [];
							simplifiedConcept.fromConceptMappings = [];
							simplifiedConcept.conceptNames = [];
							simplifiedConcept.clientConcepts = [];
							return new EncounterDiagnostic({
								concept: simplifiedConcept,
								status: DIAGNOSTIC_STATUS_PENDING,
							});
						});
					append(encounterDiagnostics);
				});
		} else {
			// Just add the single test
			append(
				new EncounterDiagnostic({
					...addDiagnosticRow,
					lineNo: fields.length,
				}),
			);
		}

		// Reset the fields for adding a new row at the bottom of the table
		setValue(
			ADD_ROW_PREFIX,
			new EncounterDiagnostic({
				concept: new Concept(),
				status: DIAGNOSTIC_STATUS_PENDING,
			}),
		);
	}, [addDiagnosticRow, fields.length, append, setValue, conceptService]);

	// This recursive function goes through the React Hook Form "errors" object, looking for error messages
	const parseFormErrors = useCallback((errorObject: DeepMap<FieldValues, FieldError>, errorMessages: string[]) => {
		for (const [key, value] of Object.entries(errorObject)) {
			if (typeof value === 'object' && value !== null && key !== 'ref') {
				parseFormErrors(value, errorMessages);
			} else if (key === 'message') {
				errorMessages.push(value);
			}
		}
	}, []);

	useEffect(() => {
		if (Object.keys(formState.errors).indexOf(FIELD_PREFIX) >= 0) {
			let tempErrorMessages: string[] = [];
			parseFormErrors(formState.errors[FIELD_PREFIX], tempErrorMessages);

			// Check if the errorMessages array already contains the correct errors or not
			if (errorMessages.join('|') !== tempErrorMessages.join('|')) {
				setErrorMessages(tempErrorMessages);
			}
		} else if (errorMessages.length > 0) {
			setErrorMessages([]);
		}
	}, [formState, errorMessages, setErrorMessages, parseFormErrors]);

	return (
		<div className="table-responsive">
			<table className="table bh-table--form">
				<thead>
					<tr>
						<th>{t(uiText.visit.form.diagnostic.TEST)}</th>
						<th>{t(uiText.visit.form.diagnostic.RESULT)}</th>
						<th>{t(uiText.visit.form.diagnostic.REFERENCE_RANGE)}</th>
						<th>{t(uiText.visit.form.diagnostic.STATUS)}</th>
						{!readOnly && <th className="data-type-action print__d-none">{t(uiText.visit.button.DELETE)}</th>}
					</tr>
				</thead>
				<tbody>
					{((fields || []) as unknown as EncounterDiagnostic[]).map((diagnostic, index) => (
						<LabDiagnosticsTableRow
							key={diagnostic.uuid}
							field={diagnostic}
							fieldPrefix={`${FIELD_PREFIX}.${index}`}
							readOnly={readOnly}
							remove={remove}
							index={index}
							conceptTestsAndPanels={conceptTestsAndPanels}
							diagnosticStatuses={diagnosticStatuses}
						/>
					))}
					{!readOnly && (
						<LabDiagnosticsTableRow
							isAddRow={true}
							fieldPrefix={ADD_ROW_PREFIX}
							readOnly={readOnly}
							conceptTestsAndPanels={conceptTestsAndPanels}
							diagnosticStatuses={diagnosticStatuses}
						/>
					)}
				</tbody>
			</table>
			{errorMessages.length > 0 && (
				<div>
					{errorMessages?.map((message, index) => (
						<p key={index} className="text-danger">
							{message}
						</p>
					))}
				</div>
			)}
		</div>
	);
};

export default LabDiagnosticsTable;
