import { useEffect, useState } from 'react';

export default function ReportWindow({ response }: { response?: Blob }) {
	const [anchorElement, setAnchorElement] = useState<HTMLAnchorElement | null>(null);
	useEffect(() => {
		if (!anchorElement || !response) {
			return;
		}
		anchorElement.href = URL.createObjectURL(response);
		anchorElement.click();
	}, [response, anchorElement]);

	return (
		<a className="d-none" href="empty" ref={(element) => setAnchorElement(element)} target="_blank">
			&nbsp
		</a>
	);
}
