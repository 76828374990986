import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCalendarAlt, faCaretSquareDown, faCaretSquareUp } from '@fortawesome/free-regular-svg-icons';
import {
	faAngleRight,
	faArrowDown,
	faArrowLeft,
	faArrowsAlt,
	faArrowUp,
	faBell,
	faBookMedical,
	faCalendarDay,
	faCaretDown,
	faCaretUp,
	faChartLine,
	faChartPie,
	faCheck,
	faDolly,
	faEdit,
	faEllipsisH,
	faFile,
	faFileExport,
	faFileInvoice,
	faHandHoldingUsd,
	faHandshake,
	faMoneyBillAlt,
	faNotesMedical,
	faPaste,
	faPen,
	faPeopleCarry,
	faPills,
	faPlus,
	fas,
	faSave,
	faSearch,
	faTags,
	faTimes,
	faTrash,
	faUserMd,
	faUsers,
	faWallet,
} from '@fortawesome/free-solid-svg-icons';

export function registerFAIcons(): void {
	library.add(
		faPills,
		faUserMd,
		faNotesMedical,
		faChartLine,
		faDolly,
		faPeopleCarry,
		faChartPie,
		faHandshake,
		faTags,
		faMoneyBillAlt,
		faUsers,
		faAngleRight,
		faArrowLeft,
		faFileExport,
		faPlus,
		faBell,
		faSearch,
		fab,
		fas,
		faArrowUp,
		faArrowDown,
		faCalendarDay,
		faTrash,
		faWallet,
		faBookMedical,
		faTimes,
		faCheck,
		faPen,
		faSave,
		faFile,
		faPaste,
		faEllipsisH,
		faArrowsAlt,
		faCaretUp,
		faCaretDown,
		faCaretSquareUp,
		faCaretSquareDown,
		faHandHoldingUsd,
		faEdit,
		faCalendarAlt,
		faFileInvoice,
	);
}
