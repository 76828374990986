import { isApolloError, useApolloClient } from '@apollo/client';
import { Suspense, useContext } from 'react';
import { Button, Card, Col, Form, Image, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { v4 } from 'uuid';
import UserContext from '../../contexts/UserContext';
import {
	Ad_ImageInput,
	Ad_ImageSaveForOrganizationInformationDocument,
	Ad_OrgInfoForFacilityInformationDocument,
	Ad_OrgInfoForFacilityInformationQuery,
	Ad_OrgInfoForFacilityInformationSaveDocument,
	C_LocationInput,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import DBFilter from '../../models/DBFilter';
import { OrganizationInformationDB } from '../../models/OrganizationInformation';
import { pageUuid } from '../../services/AuthService';
import { exception } from '../../utils/analytics';
import { uiText } from '../../utils/Language';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

type OrganizationInformationFormFields = {
	UU: string;
	BH_Header: string | null;
	BH_FacilityNumber: string | null;
	BH_PaymentInformation: string | null;
	Phone: string | null;
	ReceiptFooterMsg: string | null;
	DUNS: string;
	TaxID: string;
	C_Location: {
		UU: string;
		Address1: string | null;
		Address2: string | null;
		Address3: string | null;
		Country: {
			UU: string;
		};
	};
	Logo: {
		UU: string;
	};
};

const constructOrganizationFormFields = (
	initialData: Ad_OrgInfoForFacilityInformationQuery['AD_OrgInfoGet']['Results'][0],
): OrganizationInformationFormFields => {
	return {
		UU: initialData.UU,
		BH_Header: initialData.BH_Header || null,
		BH_FacilityNumber: initialData.BH_FacilityNumber || null,
		BH_PaymentInformation: initialData.BH_PaymentInformation || null,
		Phone: initialData.Phone || null,
		ReceiptFooterMsg: initialData.ReceiptFooterMsg || null,
		DUNS: initialData.DUNS,
		TaxID: initialData.TaxID,
		C_Location: {
			UU: initialData.C_Location?.UU || v4(),
			Address1: initialData.C_Location?.Address1 || null,
			Address2: initialData.C_Location?.Address2 || null,
			Address3: initialData.C_Location?.Address3 || null,
			Country: {
				UU: initialData.C_Location?.C_Country.UU || '',
			},
		},
		Logo: {
			UU: initialData.Logo?.UU || v4(),
		},
	};
};

/**
 * Clinical Information Form
 * @constructor
 */
const OrganizationFormInternal = () => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);

	const { data } = useSuspenseAsync('organization-information', async () =>
		graphqlClient
			.query({
				query: Ad_OrgInfoForFacilityInformationDocument,
				variables: {
					Filter: DBFilter<OrganizationInformationDB>()
						.nested('ad_org')
						.property('ad_org_uu')
						.equals(organization.uuid)
						.up()
						.toString(),
				},
				fetchPolicy: 'network-only',
			})
			.then((response) => response.data.AD_OrgInfoGet.Results[0]),
	);

	const {
		formState: { isDirty },
		handleSubmit,
		register,
		reset,
	} = useForm<OrganizationInformationFormFields>({
		defaultValues: constructOrganizationFormFields(data!),
	});

	const [{ value: logoData }, uploadLogo] = useAsyncFn(
		async (selectedImage?: File): Promise<{ binaryData: string | null; name: string | null } | null> => {
			if (selectedImage) {
				const fileName = selectedImage.name;
				const imageData: string = await new Promise<string>((resolve, reject) => {
					const fileReader = new FileReader();
					fileReader.readAsDataURL(selectedImage);
					fileReader.onload = () => {
						resolve((fileReader.result as string).replace('data:', '').replace(/^.+,/, ''));
					};
					fileReader.onerror = (error) => {
						reject(error);
						toast.error(error);
					};
				});

				return { binaryData: imageData, name: fileName };
			}
			return null;
		},
		[],
		{ value: { binaryData: data!.Logo?.BinaryData || null, name: data!.Logo?.Name || null }, loading: false },
	);

	const [{ loading }, onSubmit] = useAsyncFn<SubmitHandler<OrganizationInformationFormFields>>(
		async (data) => {
			try {
				let logoInput: Ad_ImageInput | undefined = undefined;
				if (logoData) {
					logoInput = { UU: data.Logo.UU, BinaryData: logoData.binaryData, Name: logoData.name };
				}
				const location: C_LocationInput = {
					UU: data.C_Location.UU,
					Address1: data.C_Location.Address1 || null,
					Address2: data.C_Location.Address2 || null,
					Address3: data.C_Location.Address3 || null,
				};

				// save entities
				await Promise.all([
					logoInput
						? graphqlClient.mutate({
								mutation: Ad_ImageSaveForOrganizationInformationDocument,
								variables: {
									AD_Image: logoInput,
								},
							})
						: undefined,
					graphqlClient.mutate({
						mutation: Ad_OrgInfoForFacilityInformationSaveDocument,
						variables: {
							AD_OrgInfo: {
								UU: data.UU,
								BH_Header: data.BH_Header || null,
								BH_FacilityNumber: data.BH_FacilityNumber || null,
								BH_PaymentInformation: data.BH_PaymentInformation || null,
								Phone: data.Phone || null,
								ReceiptFooterMsg: data.ReceiptFooterMsg || null,
								DUNS: data.DUNS,
								TaxID: data.TaxID,
								Logo: logoInput ? { UU: logoInput.UU! } : null,
								C_Location: { UU: location.UU! },
							},
							C_Location: location,
						},
					}),
				]);

				toast.success(t(uiText.organization.success.UPDATE));
				reset({}, { keepValues: true });
			} catch (error) {
				let stringError: string = (error as any)?.toString?.();
				if (error instanceof Error && isApolloError(error)) {
					stringError = error.graphQLErrors
						.map((graphqlError) => graphqlError.message.split(' : ')[1] || graphqlError.message)
						.join(', ');
				}
				exception({ description: `Organization Information save error: ${stringError}` });
				toast.error(t(uiText.organization.error.COULD_NOT_SAVE, { error: stringError }));
			}
		},
		[graphqlClient, logoData, reset],
	);

	const { disableWrite } = useActionPrivileges(pageUuid.ORGANIZATION);

	useConfirmRefresh(isDirty);

	return (
		<Layout>
			{loading ? (
				<LoadSpinner show={true} title={t(uiText.organization.PROCESSING)} />
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(uiText.organization.facilityInformation)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						<Row className="bg-white ms-0">
							<Form onSubmit={handleSubmit(onSubmit)} className="px-0">
								<fieldset disabled={disableWrite}>
									<Card className="bh-card">
										<Card.Body>
											<Form.Group as={Row} controlId="officialName">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.officialName)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control readOnly={true} defaultValue={organization?.name} tabIndex={1} />
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="facilityNumber">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.FACILITY_NUMBER)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={2}
														placeholder={t(uiText.organization.placeholders.ENTER_FACILITY_NUMBER)}
														{...register('BH_FacilityNumber')}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="phone">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.PHONE)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={3}
														placeholder={t(uiText.organization.placeholders.ENTER_PHONE)}
														{...register('Phone')}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="address1">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.COUNTY)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={4}
														placeholder={t(uiText.organization.placeholders.ENTER_COUNTY)}
														{...register('C_Location.Address1')}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="address2">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.SUB_COUNTY)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={5}
														placeholder={t(uiText.organization.placeholders.ENTER_SUB_COUNTY)}
														{...register('C_Location.Address2')}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="address3">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.WARD)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={6}
														placeholder={t(uiText.organization.placeholders.ENTER_WARD)}
														{...register('C_Location.Address3')}
													/>
												</Col>
											</Form.Group>
											<Col xs={6} />
											<Form.Group as={Row} controlId="header">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.HEADER)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={7}
														placeholder={t(uiText.organization.placeholders.ENTER_HEADER)}
														{...register('BH_Header')}
														as="textarea"
														rows={3}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="footer">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.FOOTER)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={8}
														placeholder={t(uiText.organization.placeholders.ENTER_FOOTER)}
														{...register('ReceiptFooterMsg')}
														as="textarea"
														rows={3}
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row} controlId="paymentInformation">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.PAYMENT_INFORMATION)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={9}
														placeholder={t(uiText.organization.placeholders.ENTER_PAYMENT_INFORMATION)}
														{...register('BH_PaymentInformation')}
														as="textarea"
														rows={3}
													/>
												</Col>
											</Form.Group>
											<Col xs={6} />
											<Form.Group as={Row} controlId="logo">
												<Col xs={2} className="d-flex align-items-center gy-2">
													<Form.Label column>{t(uiText.organization.labels.UPLOAD_LOGO)}</Form.Label>
												</Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<Form.Control
														tabIndex={10}
														type={'file'}
														placeholder={t(uiText.organization.labels.UPLOAD_LOGO)}
														onChange={(e) => uploadLogo((e.target as HTMLInputElement).files?.[0])}
														accept=".jpeg,.png,.jpg"
													/>
												</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Col xs={2} className="d-flex align-items-center"></Col>
												<Col xs={5} className="d-flex align-items-center gy-2">
													<input type="hidden" {...register('Logo.UU')} />
													{logoData?.binaryData && (
														<Image
															src={`data:image/*;base64,${logoData.binaryData}`}
															alt={logoData.name || 'Logo'}
															title={logoData.name || 'Logo'}
															thumbnail
														/>
													)}
												</Col>
											</Form.Group>
										</Card.Body>
									</Card>
								</fieldset>

								<Row className="m-4 ms-3">
									<Col xs="auto" />
									<Col xs="auto" className="ms-auto">
										<Button type="submit" name={t(uiText.organization.button.SAVE)} variant="success">
											{t(uiText.organization.button.SAVE)}
										</Button>
									</Col>
								</Row>
							</Form>
						</Row>
					</Layout.Body>
				</>
			)}
		</Layout>
	);
};

export default function OrganizationForm() {
	const { t } = useTranslation();
	return (
		<Suspense fallback={<LoadSpinner show={true} title={t(uiText.organization.LOADING)} />}>
			<OrganizationFormInternal />
		</Suspense>
	);
}
