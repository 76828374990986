import AttributeSetInstance, { AttributeSetInstanceDto } from '../models/AttributeSetInstance';
import BaseEntityService from './BaseEntityService';

export default class AttributeSetInstanceService extends BaseEntityService<
	AttributeSetInstance,
	AttributeSetInstanceDto
> {
	protected entityName = 'attribute-set-instances';
	public entityTableName = 'm_attributesetinstance';

	map(data: AttributeSetInstanceDto): AttributeSetInstance {
		return new AttributeSetInstance(data);
	}

	reverseMap(data: AttributeSetInstance): AttributeSetInstanceDto {
		return {
			...data,
			guaranteeDate: data.guaranteeDate?.getTime(),
			serialNumber: data.serialNumber || null,
			lot: data.lot || null,
		};
	}
}
